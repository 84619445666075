import { RouteComponentProps, Router } from '@reach/router';
import * as React from 'react';
import { PageNotFound } from '../PageNotFound';
import { CreateItem } from './CreateItem';
import { EditItem } from './EditItem';
import { Items } from './ItemsTables/Items';
import { ViewItemBulkEdit } from './ItemsTables/ViewItemBulkEdit';
import { UploadItemBulkEdit } from './UploadItemBulkEdit';
import { UploadItems } from './UploadItems';
import { ViewItem } from './ViewItem';

export const ItemsSection = (props: RouteComponentProps) => (
  <Router primary={false}>
    <Items path="/" />
    <ViewItemBulkEdit path="bulk-edit/view/:itemBulkEditId" />
    <UploadItemBulkEdit path="bulk-edit/upload" />
    <CreateItem path="create" />
    <UploadItems path="upload" />
    <EditItem path="edit/:itemId" />
    <ViewItem path="view/:itemId" />
    <PageNotFound default={true} />
  </Router>
);
