import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { styled } from '../../styling/theme';
import { ButtonProps } from './Button';

export const iconButtonTestId = 'icon-button-test-id';

type Colours = {
  [key: string]: string;
};

type Theme = {
  colours: Colours;
};

type Props = {
  color?: keyof Theme['colours'];
  theme: Theme;
};

// Function to get nested property
const getNestedProperty = (obj: any, path: string) => {
  return path.split('.').reduce((o, p) => (o ? o[p] : null), obj);
};

const StyledIconButton = styled.button<React.ButtonHTMLAttributes<HTMLButtonElement>>`
  background-color: transparent;
  color: ${props =>
    (props.color && getNestedProperty(props.theme.colours, props.color)) ||
    props.theme.colours.primary};
  opacity: ${props => (props.disabled ? 0.6 : 1)};
  margin-right: ${props => props.theme.spacing.tiny}px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  border: none;
`;

type IconButtonProps = {
  faIcon: IconProp;
} & ButtonProps;

export const IconButton = ({ faIcon, ...buttonProps }: IconButtonProps) => (
  <StyledIconButton {...buttonProps}>
    <FontAwesomeIcon icon={faIcon} />
  </StyledIconButton>
);
