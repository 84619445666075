import * as React from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { styled } from '../../styling/theme';
import { LabelDispensingNote } from './item';

type NoteProps = {
  noteDetails: LabelDispensingNote;
  index: number;
};

const Container = styled.div<
  React.HTMLAttributes<HTMLDivElement> & { ref: (element: HTMLElement | null) => void }
>`
  border: 1px solid ${props => props.theme.colours.secondaryInactive};
  padding: ${props => props.theme.spacing.tiny}px;
  margin-bottom: ${props => props.theme.spacing.tiny}px;
  border-radius: ${props => props.theme.spacing.tiny}px;
  background-color: ${props => props.theme.colours.componentBackground};
`;

export const noteDisplayText = (viewOrder: number | null, optionDescription: string | null) =>
  `${viewOrder?.toString()} : ${optionDescription}`;

export const noteTestId = (labelDispensingNoteOptionId: number | null, index: number) =>
  `draggable:note${labelDispensingNoteOptionId}index${index}`;

export const Note = (props: NoteProps) => (
  <Draggable
    draggableId={(props.noteDetails?.labelDispensingNoteOptionsId ?? 0).toString()}
    index={props.index}
  >
    {(provided: DraggableProvided) => (
      <Container
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        data-testid={noteTestId(props.noteDetails.labelDispensingNoteOptionsId, props.index)}
      >
        {noteDisplayText(props.noteDetails.viewOrder, props.noteDetails.optionDescription)}
      </Container>
    )}
  </Draggable>
);
