import { TranslatableValidator } from '../../utils/validation/TranslatableValidator';
import { Notes } from './item';
import { TFunction } from 'i18next';
import { Locale } from '../../models/locale';

export class NotesValidator extends TranslatableValidator<Notes> {
  constructor(t: TFunction, locale: Locale) {
    super(t, locale);
    this.ruleFor('note')
      .notEmpty()
      .withMessage(t('item:validation.note.addNote'))
      .maxLength(120)
      .withMessage(t('item:validation.note.maxNoteChars'));
    this.ruleFor('initials')
      .notEmpty()
      .withMessage(t('item:validation.note.addOperator'))
      .maxLength(4)
      .withMessage(t('item:validation.note.maxOperatorChars'));
    this.ruleFor('dateOfNote')
      .notNull()
      .withMessage(t('item:validation.note.addDate'));
  }
}
