import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setApplicationInsightsUser } from '../../../applicationInsights';
import {
  ApiRequestProps,
  withApiRequest,
} from '../../../shared/higher-order-components/withApiRequest';
import { LoadingIndicator } from '../../../shared/LoadingIndicator';
import { LoginOptions } from '../Authentication';
import { AuthenticationError } from '../AuthenticationError';
import { LoginDataResponse } from './loginData';
import { getLoginData } from './loginDataApi';
import { MetadataContext } from './metadataContext';
import { PmsUsageContext } from './pmsUsageContext';
import { LoginUserResponse } from './user';
import { UserContext } from './userContext';

type ResponseProps = ApiRequestProps<LoginDataResponse, void>;
type OwnProps = {
  children?: React.ReactNode;
  redirectToLogin: (options?: LoginOptions) => void;
};

type LoginDataLoaderProps = ResponseProps & OwnProps;

const LoginDataLoaderComponent = (props: LoginDataLoaderProps) => {
  const { i18n } = useTranslation();
  const [isLoadingLanguage, setIsLoadingLanguage] = useState(false);
  const [user, setUser] = useState<LoginUserResponse>();

  useEffect(() => {
    props.makeRequest();
  }, []);

  useEffect(() => {
    if (props.response) {
      setApplicationInsightsUser(props.response.user.email);
    }
  }, [props.response && props.response.user.email]);

  useEffect(() => {
    if (props.response) {
      setIsLoadingLanguage(true);
      i18n.changeLanguage(props.response.user.locale).then(() => {
        i18n
          .loadNamespaces([
            'authenticationError',
            'serverErrors',
            'login',
            'pageNotFound',
            'site',
            'errorBoundary',
            'metadata',
            'clientErrors',
          ])
          .then(() => {
            setIsLoadingLanguage(false);
          });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.response && props.response.user.locale]);

  useEffect(() => {
    if (props.response) {
      setUser(props.response.user);
    }
  }, [props.response && props.response.user]);

  if (props.inProgress || isLoadingLanguage) {
    return <LoadingIndicator />;
  } else if (props.apiError) {
    return <AuthenticationError redirectToLogin={props.redirectToLogin} />;
  } else if (props.response != null) {
    return (
      <UserContext.Provider value={{ user: user || props.response.user, updateUser: setUser }}>
        <MetadataContext.Provider value={props.response.metadata}>
          <PmsUsageContext.Provider value={props.response.organisationPmsUsage}>
            {props.children}
          </PmsUsageContext.Provider>
        </MetadataContext.Provider>
      </UserContext.Provider>
    );
  } else {
    return <LoadingIndicator />;
  }
};

const enhance = withApiRequest<OwnProps, LoginDataResponse, void>(getLoginData);

export const LoginDataLoader = enhance(LoginDataLoaderComponent);
