import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { HollowLinkButton } from '../../../shared/buttons/LinkButton';
import { DataLoader } from '../../../shared/DataLoader';
import { RequiresOneOfPermissions } from '../../../shared/Permissions';
import { DataFetchTable } from '../../../shared/tables/Table';
import { sitesUrl } from '../../../urls';
import { PmsTypeResponse } from '../../authentication/loginData/metadata';
import { MetadataContext } from '../../authentication/loginData/metadataContext';
import { permissions } from '../../authentication/permissions';
import { PageHeading } from '../../layout/PageHeading';
import { MerlinGroup } from '../merlinGroups/merlinGroup';
import { SitesResponse } from '../site';
import { getArchivedSites, getMerlinGroups } from '../../../api/sitesApi';
import { getSiteColumns, SitesFullWidthTableContainer, sitesPageSize } from './SitesBase';

type OwnProps = RouteComponentProps & {};
type Props = OwnProps;

type SitesTableProps = {
  pmsTypes: Array<PmsTypeResponse>;
  merlinGroups: Array<MerlinGroup>;
};

const mapSitesResponseToTableData = (response: SitesResponse) => response.sites;

const SitesTableComponent = (props: SitesTableProps) => {
  const { t } = useTranslation(['site', 'metadata']);

  const columns = getSiteColumns(props, t);

  return (
    <RequiresOneOfPermissions permissions={[permissions.SuperAdmin, permissions.CentralAdmin]}>
      <Title>{t('archivedSiteList.title')}</Title>
      <PageHeading>
        <h1>{t('archivedSiteList.heading')}</h1>
        <HollowLinkButton to={sitesUrl()}>
          {t('archivedSiteList.activeSitesButton')}
        </HollowLinkButton>
      </PageHeading>
      <SitesFullWidthTableContainer>
        <DataFetchTable
          columns={columns}
          getApiData={getArchivedSites}
          pageSize={sitesPageSize}
          mapResponseToTableData={mapSitesResponseToTableData}
          emptyTableMessage={t('archivedSiteList.emptyTableMessage')}
        />
      </SitesFullWidthTableContainer>
    </RequiresOneOfPermissions>
  );
};

export const ArchivedSites = (props: Props) => (
  <MetadataContext.Consumer>
    {metadata => (
      <DataLoader apiRequest={getMerlinGroups}>
        {merlinGroups => (
          <SitesTableComponent
            pmsTypes={metadata.pmsTypes}
            merlinGroups={merlinGroups.response.merlinGroups}
          />
        )}
      </DataLoader>
    )}
  </MetadataContext.Consumer>
);
