import { RouteComponentProps } from '@reach/router';
import { flowRight } from 'lodash';
import { useContext, useState } from 'react';
import * as React from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { toShortDate } from '../../../models/dates/dateTimeStamp';
import { Id } from '../../../models/id';
import { DownloadExcelFromCsvResponseButton } from '../../../shared/buttons/DownloadExcelFromCsvResponseButton';
import {
  ApiFetchProps,
  fetchFromApiOnLoad,
} from '../../../shared/higher-order-components/fetchFromApiOnLoad';
import { ApiRequestProps } from '../../../shared/higher-order-components/withApiRequest';
import { requiresOneOfPermissions } from '../../../shared/Permissions';
import { SuccessBox } from '../../../shared/success/SuccessBox';
import { UserContext } from '../../authentication/loginData/userContext';
import { permissions } from '../../authentication/permissions';
import { PageHeading } from '../../layout/PageHeading';
import { ApproveSupplierListFormVirtual } from './ApproveSupplierListFormVirtual';
import { ReviewSupplierListResponse } from './supplierList';
import { getSupplierListReview, getSupplierListReviewDownload } from './supplierListApi';

type OwnProps = RouteComponentProps<{ supplierListId: string }>;
type Props = OwnProps & ApiFetchProps<ReviewSupplierListResponse> & ApiRequestProps<void, Id> & {};

export const ReviewSupplierListComponent = (props: Props) => {
  const { t } = useTranslation('supplierList');
  const { user } = useContext(UserContext);
  const [isApproved, setIsApproved] = useState(props.response.isApproved);

  const onApproveSuccess = () => {
    setIsApproved(true);
  };

  const getFileName = () =>
    `${t('review.fileNamePrefix')}_${props.response.supplierName}_${toShortDate(
      props.response.uploadDate,
      user.locale,
    )}`;

  return (
    <>
      <Title>{t('review.title')}</Title>
      <PageHeading>
        <h1>{t('review.heading')}</h1>
        {!isApproved && < DownloadExcelFromCsvResponseButton
          request={getSupplierListReviewDownload}
          requestParams={Number(props.supplierListId)}
          labelText={t('review.download')}
          fileName={getFileName()}
          sheetName={t('review.downloadSheetName')}
        />}
      </PageHeading>
      {isApproved && (
        <SuccessBox
          message={t('review.approvedHeading')}
          subMessage={t('review.approvedMessage')}
        />
      )}
      {props.response.isFirstListFromSupplier && <p>{t('review.firstListMessage')}</p>}
      {!isApproved && (
        <ApproveSupplierListFormVirtual
          supplierListId={props.supplierListId}
          onSuccess={onApproveSuccess}
          isFirstListFromSupplier={props.response.isFirstListFromSupplier}
          newProducts={props.response.newProducts}
          removedProducts={props.response.removedProducts}
          changedProducts={props.response.changedProducts}
        />
      )}
    </>
  );
};

const enhance = flowRight(
  requiresOneOfPermissions(permissions.SuperAdmin, permissions.CentralAdmin),
  fetchFromApiOnLoad<OwnProps, ReviewSupplierListResponse, number>(
    props => Number(props.supplierListId),
    getSupplierListReview,
  ),
);

export const ReviewSupplierList = enhance(ReviewSupplierListComponent);
