import { faClock } from '@fortawesome/free-regular-svg-icons';
import { TFunction } from 'i18next';
import { filter } from 'lodash';
import { default as React, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticatedApiRequest } from '../../../models/apiRequest';
import { ButtonGroup } from '../../../shared/buttons/Button';
import {
  useMultiSelectDropdown,
  UseMultiSelectDropdownResult,
} from '../../../shared/dropdowns/useMultiSelectDropdown';
import { DropdownOption, DropdownOptions } from '../../../shared/forms/DropdownField';
import { InfoBox } from '../../../shared/info/InfoBox';
import { MarginRightIcon } from '../../../shared/StyledIcons';
import { useFiltering, UseFilteringResult } from '../../../shared/tables/filtering/useFiltering';
import { CustomColumn, TableDataFetchRequest } from '../../../shared/tables/Table';
import { styled } from '../../../styling/theme';
import { formatNullableCurrencies } from '../../../utils/currencyUtils';
import { formatNumber } from '../../../utils/numberUtils';
import { LoginUserResponse } from '../../authentication/loginData/user';
import { UserContext } from '../../authentication/loginData/userContext';
import { ItemColumnTypes, ItemFilterOptions, ItemResponse, ItemsResponse } from '../item';
import { getItems } from '../itemsApi';
import { ItemFieldsFromPmsResponse } from '../PmsFields/pmsFields';
import { CountryCode, CountryResponse } from '../../authentication/loginData/metadata';

export const itemsPageSize = 50;

export type ItemsBaseProps = {
  itemFieldsFromPms: ItemFieldsFromPmsResponse;
};

export const SpaceBetweenButtonGroup: React.FunctionComponent = styled(ButtonGroup)`
  justify-content: space-between;
  margin-bottom: 0;
  padding-bottom: 30px;
`;

export const initialItemFilterOptions: ItemFilterOptions = {
  searchText: null,
  types: null,
  speciesIds: null,
  category1Ids: null,
  category2Ids: null,
  category3Ids: null,
  supplierIds: null,
  clientCategoryIds: null,
  supplierCode: null,
  centralPriceMax: null,
  centralPriceMin: null,
  centralMarkupPercentageMax: null,
  centralMarkupPercentageMin: null,
  isDisabled: false,
  stockWithEmptySupplier: null,
  bulkEditScheduled: null,
  discontinuedProduct: null,
  countrySpecificFilterOptions: [],
};

export const getInitialItemFilterOptionsWithCountryFilters = (
  userCountries: Array<CountryCode>,
): ItemFilterOptions => {
  return {
    ...initialItemFilterOptions,
    countrySpecificFilterOptions: userCountries.map(countryCode => ({
      countryCode,
      isEnabled: null,
    })),
  };
};

export const urlItemsToFilterItems = (
  itemFieldsFromPms: ItemFieldsFromPmsResponse,
  userCountries: Array<CountryCode>,
) => (urlState?: Partial<ItemFilterOptions>): ItemFilterOptions => {
  if (urlState === undefined) {
    return initialItemFilterOptions;
  }

  return {
    searchText: urlState.searchText ?? null,
    types: urlState.types
      ? filter(urlState.types, type => itemFieldsFromPms.typeOptions[type] != null)
      : null,
    speciesIds: urlState.speciesIds
      ? filter(urlState.speciesIds, id => itemFieldsFromPms.speciesOptions[id] != null)
      : null,
    category1Ids: urlState.category1Ids
      ? filter(urlState.category1Ids, id => itemFieldsFromPms.category1Options[id] != null)
      : null,
    category2Ids: urlState.category2Ids
      ? filter(urlState.category2Ids, id => itemFieldsFromPms.category2Options[id] != null)
      : null,
    category3Ids: urlState.category3Ids
      ? filter(urlState.category3Ids, id => itemFieldsFromPms.category3Options[id] != null)
      : null,
    supplierIds: urlState.supplierIds ?? null,
    clientCategoryIds: urlState.clientCategoryIds
      ? filter(
          urlState.clientCategoryIds,
          id => itemFieldsFromPms.clientCategoryOptions[id] != null,
        )
      : null,
    supplierCode: urlState.supplierCode ?? null,
    centralPriceMin: urlState.centralPriceMin ?? null,
    centralPriceMax: urlState.centralPriceMax ?? null,
    centralMarkupPercentageMin: urlState.centralMarkupPercentageMin ?? null,
    centralMarkupPercentageMax: urlState.centralMarkupPercentageMax ?? null,
    isDisabled: urlState?.isDisabled ?? null,
    stockWithEmptySupplier: urlState?.stockWithEmptySupplier ?? null,
    bulkEditScheduled: urlState?.bulkEditScheduled ?? null,
    discontinuedProduct: urlState?.discontinuedProduct ?? null,
    countrySpecificFilterOptions:
      urlState &&
      urlState?.countrySpecificFilterOptions &&
      urlState?.countrySpecificFilterOptions.length > 0
        ? urlState?.countrySpecificFilterOptions
        : userCountries.map(countryCode => ({
            countryCode,
            isEnabled: null,
          })),
  };
};

export const filterItemsToUrlItems = (
  filterOptions?: ItemFilterOptions,
): Partial<ItemFilterOptions> => {
  if (filterOptions === undefined) {
    return {};
  }

  return {
    searchText: filterOptions.searchText ?? undefined,
    types: filterOptions.types ?? undefined,
    speciesIds: filterOptions.speciesIds ?? undefined,
    category1Ids: filterOptions.category1Ids ?? undefined,
    category2Ids: filterOptions.category2Ids ?? undefined,
    category3Ids: filterOptions.category3Ids ?? undefined,
    supplierIds: filterOptions.supplierIds ?? undefined,
    supplierCode: filterOptions.supplierCode ?? undefined,
    clientCategoryIds: filterOptions.clientCategoryIds ?? undefined,
    centralPriceMin: filterOptions.centralPriceMin ?? undefined,
    centralPriceMax: filterOptions.centralPriceMax ?? undefined,
    centralMarkupPercentageMin: filterOptions.centralMarkupPercentageMin ?? undefined,
    centralMarkupPercentageMax: filterOptions.centralMarkupPercentageMax ?? undefined,
    isDisabled: filterOptions.isDisabled ?? undefined,
    stockWithEmptySupplier: filterOptions.stockWithEmptySupplier ?? undefined,
    bulkEditScheduled: filterOptions.bulkEditScheduled ?? undefined,
    discontinuedProduct: filterOptions.discontinuedProduct ?? undefined,
    countrySpecificFilterOptions: filterOptions.countrySpecificFilterOptions ?? undefined,
  };
};
export type ItemColumnDropdownType = DropdownOption<ItemColumnTypes>;
export type UseItemTableBaseStateResult = {
  columnDropdownOptions: DropdownOptions<ItemColumnTypes>;
} & UseFilteringResult<
  ItemFilterOptions,
  AuthenticatedApiRequest<ItemsResponse, TableDataFetchRequest<ItemColumnTypes>>
> &
  UseMultiSelectDropdownResult<ItemColumnDropdownType, ItemColumnTypes>;

export const useItemTableBaseState = (props: ItemsBaseProps): UseItemTableBaseStateResult => {
  const { t } = useTranslation('item');
  const { user } = useContext(UserContext);

  const allColumns: DropdownOptions<ItemColumnTypes> = [
    { displayText: t('itemList.columnHeaders.name'), value: 'itemName' },
    { displayText: t('itemList.columnHeaders.lastUpdated'), value: 'lastUpdated' },
    { displayText: t('itemList.columnHeaders.centralPriceGross'), value: 'centralPriceGross' },
    { displayText: t('itemList.columnHeaders.centralPriceNet'), value: 'centralPriceNet' },
    {
      displayText: t('itemList.columnHeaders.centralMarkupPercentage'),
      value: 'centralMarkupPercentage',
    },
    { displayText: t('itemList.columnHeaders.unitsPerPack'), value: 'unitsPerPack' },
    { displayText: t('itemList.columnHeaders.type'), value: 'type' },
    {
      displayText: t('itemList.columnHeaders.category1'),
      value: 'category1',
    },
    { displayText: t('itemList.columnHeaders.category2'), value: 'category2' },
    {
      displayText: t('itemList.columnHeaders.category3'),
      value: 'category3',
    },
  ];

  const columnDropdownOptions: DropdownOptions<ItemColumnTypes> = useMemo(
    () =>
      filter(allColumns, column => column.value !== 'category1' && column.value !== 'category3'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.itemFieldsFromPms, user.useGrossPrices],
  );

  const filteringResult = useFiltering({
    getData: getItems,
    initialUrlState: filterItemsToUrlItems(initialItemFilterOptions),
    urlStateToFilterOptions: urlItemsToFilterItems(props.itemFieldsFromPms, user.countries),
    filterOptionsToUrlState: filterItemsToUrlItems,
    pageName: 'items',
  });

  const multiSelectDropdownResult = useMultiSelectDropdown<ItemColumnDropdownType, ItemColumnTypes>(
    {
      validOptions: columnDropdownOptions,
      initiallySelected: columnDropdownOptions.filter(colOption =>
        [
          'itemName',
          'lastUpdated',
          'centralPriceNet',
          'centralPriceGross',
          'centralMarkupPercentage',
          'unitsPerPack',
          'category1',
        ].includes(colOption.value),
      ),
      storeInUrl: true,
    },
  );

  return { ...filteringResult, ...multiSelectDropdownResult, columnDropdownOptions };
};

export type ItemColumn = CustomColumn<ItemResponse, ItemColumnTypes>;

export const itemFlagsColumn = (t: TFunction): ItemColumn => ({
  Header: t<string>('itemList.columnHeaders.flags'),
  accessor: (row: ItemResponse) => row.flags,
  id: 'flags',
});

export const itemNameColumn = (t: TFunction): ItemColumn => ({
  Header: t<string>('itemList.columnHeaders.name'),
  accessor: (row: ItemResponse) => row.itemName,
  id: 'itemName',
});

export const itemCodeColumn = (t: TFunction): ItemColumn => ({
  Header: t<string>('itemList.columnHeaders.itemCode'),
  accessor: (row: ItemResponse) => row.itemCode,
  id: 'itemCode',
});

export const itemLastUpdatedColumn = (t: TFunction): ItemColumn => ({
  Header: t<string>('itemList.columnHeaders.lastUpdated'),
  accessor: (row: ItemResponse) => row.lastUpdated,
  id: 'lastUpdated',
});

export const itemCentralPriceNetColumn = (
  t: TFunction,
  user: LoginUserResponse,
  userCountries: Array<CountryResponse>,
): ItemColumn => ({
  Header: t<string>('itemList.columnHeaders.centralPriceNet'),
  accessor: (row: ItemResponse) =>
    formatNullableCurrencies(
      row.countrySpecificItemDetailsResponseList
        ?.filter(csid => userCountries.map(uc => uc.code).includes(csid.country.code))
        .map(csid => csid.centralPriceNet),
      user.locale,
      userCountries
        .filter(vc =>
          row.countrySpecificItemDetailsResponseList
            .map(csid => csid.country.code)
            .includes(vc.code),
        )
        .map(c => c.currency),
    ),
  id: 'centralPriceNet',
});
export const itemCentralPriceGrossColumn = (
  t: TFunction,
  user: LoginUserResponse,
  userCountries: Array<CountryResponse>,
): ItemColumn => ({
  Header: t<string>('itemList.columnHeaders.centralPriceGross'),
  accessor: (row: ItemResponse) =>
    formatNullableCurrencies(
      row.countrySpecificItemDetailsResponseList
        ?.filter(csid => userCountries.map(uc => uc.code).includes(csid.country.code))
        .map(csid => csid.centralPriceGross),
      user.locale,
      userCountries
        .filter(vc =>
          row.countrySpecificItemDetailsResponseList
            .map(csid => csid.country.code)
            .includes(vc.code),
        )
        .map(c => c.currency),
    ),
  id: 'centralPriceGross',
});

export const itemCentralMarkupPercentageColumn = (
  t: TFunction,
  user: LoginUserResponse,
): ItemColumn => ({
  Header: t<string>('itemList.columnHeaders.centralMarkupPercentage'),
  accessor: (row: ItemResponse) =>
    row.centralMarkupPercentage != null
      ? formatNumber(row.centralMarkupPercentage, user.locale)
      : '',
  id: 'centralMarkupPercentage',
});

export const itemQuantityColumn = (t: TFunction, user: LoginUserResponse): ItemColumn => ({
  Header: t<string>('itemList.columnHeaders.unitsPerPack'),
  accessor: (row: ItemResponse) =>
    row.unitsPerPack != null ? formatNumber(row.unitsPerPack, user.locale) : '',
  id: 'unitsPerPack',
});

export const itemTypeColumn = (
  t: TFunction,
  itemFields: ItemFieldsFromPmsResponse,
): ItemColumn => ({
  Header: t<string>('itemList.columnHeaders.type'),
  accessor: (row: ItemResponse) => {
    return row.type && t(itemFields.typeOptions[row.type].translationKey);
  },
  id: 'type',
});

export const itemCategory1Column = (
  t: TFunction,
  itemFields: ItemFieldsFromPmsResponse,
): ItemColumn => ({
  Header: t<string>('itemList.columnHeaders.category1'),
  accessor: (row: ItemResponse) =>
    row.category1Id && itemFields.category1Options[row.category1Id].name,
  id: 'category1',
});
export const itemCategory2Column = (
  t: TFunction,
  itemFields: ItemFieldsFromPmsResponse,
): ItemColumn => ({
  Header: t<string>('itemList.columnHeaders.category2'),
  accessor: (row: ItemResponse) =>
    row.category2Id && itemFields.category2Options[row.category2Id].name,
  id: 'category2',
});
export const itemCategory3Column = (
  t: TFunction,
  itemFields: ItemFieldsFromPmsResponse,
): ItemColumn => ({
  Header: t<string>('itemList.columnHeaders.category3'),
  accessor: (row: ItemResponse) =>
    row.category3Id && itemFields.category3Options[row.category3Id].name,
  id: 'category3',
});

type ItemsScheduledForUpdateBarProps = {
  message: string;
};
export const ItemsScheduledForUpdateInfoBar = ({ message }: ItemsScheduledForUpdateBarProps) => (
  <InfoBox
    message={
      <>
        <MarginRightIcon icon={faClock} />
        {message}
      </>
    }
  />
);
