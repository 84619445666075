import { Form, Formik, FormikState } from 'formik';
import React, { useState } from 'react';
import { Column, Container, Div, Row } from '../../../styling/Site';
import { faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton, Button } from '../../../shared/buttons/Button';
import { InputField } from '../../../shared/forms/InputField';
import { validationSchema } from '../API/HangFireSchema';
import { useApiRequest } from '../../../shared/hooks/useApiRequest';
import { deleteHangFireJob, fetchHangFireJob } from '../API/supportApi';
import { HangFireJobDetailsResponse } from '../API/HangFireJobDetailsResponse';
import { useTranslation } from 'react-i18next';
import { HangFireDetails } from './HangFireDetails';
import { GuardWrapper } from '../styles/HangFireJobStyles';

type FormValues = {
  query: string;
};
export const HangFireForm = () => {
  const { t } = useTranslation(['support']);

  const initialValues: FormValues = { query: '' };

  const { makeRequest: makeFetchRequest, inProgress: fetchInProgress } = useApiRequest(
    fetchHangFireJob,
  );
  const { makeRequest: makeCancelRquest, inProgress: cancelInProgress } = useApiRequest(
    deleteHangFireJob,
  );
  const [hangfireDetails, setHangfireDetails] = useState<HangFireJobDetailsResponse>();
  const [guard, setGuard] = useState<boolean>(false);

  const onSubmit = (values: FormValues) => {
    makeFetchRequest(parseInt(values.query)).then(result => {
      if (result) {
        setHangfireDetails(result);
      }
    });
  };

  const cancelJob = () => {
    makeCancelRquest(hangfireDetails!.hangFireJob.id).then(result => {
      if (result) {
        setHangfireDetails(result);
      }
    });
  };

  const toggleGuard = () => {
    setGuard(true);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {(props: FormikState<FormValues>) => (
        <Container>
          <Form>
            <Row>
              <Column>
                <InputField name="query" label="HangFire Query" placeholder="HangFire Query" />
                <PrimaryButton type="submit" loading={fetchInProgress}>
                  {t('hangfireJob.submit')}
                  <FontAwesomeIcon icon={faSearch} style={{ marginLeft: '5px' }} />
                </PrimaryButton>
                {(hangfireDetails?.bulkEditJob.id ?? 0) > 0 && (
                  <Button
                    backgroundColor={'#dc3545'}
                    onClick={toggleGuard}
                    style={{ marginLeft: '5px' }}
                  >
                    <FontAwesomeIcon icon={faTrash} style={{ marginLeft: '5px' }} />
                  </Button>
                )}
              </Column>
              <Column>
                <GuardWrapper>
                  {guard && (
                    <>
                      <Div>Are you sure you want to cancel this job?</Div>
                      <Div>
                        <Button
                          backgroundColor={'#dc3545'}
                          onClick={cancelJob}
                          loading={cancelInProgress}
                        >
                          Cancel Job
                          <FontAwesomeIcon icon={faTrash} style={{ marginLeft: '5px' }} />
                        </Button>
                      </Div>
                    </>
                  )}
                </GuardWrapper>
              </Column>
            </Row>
          </Form>
          {hangfireDetails && <HangFireDetails hangfireDetails={hangfireDetails} />}
        </Container>
      )}
    </Formik>
  );
};
