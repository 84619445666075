import * as React from 'react';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { AuthenticationProps } from './Authentication';
import { AuthenticationError } from './AuthenticationError';
import { LoginDataLoader } from './loginData/LoginDataLoader';

type OwnProps = AuthenticationProps & { children: React.ReactNode };

export const Login = (props: OwnProps) => {
  const {
    isAuthenticated,
    isLoggingIn,
    isLoggingOut,
    authenticationError,
    redirectToLogin,
  } = props;

  if (!isLoggingIn && !isLoggingOut && isAuthenticated && !authenticationError) {
    return <LoginDataLoader redirectToLogin={redirectToLogin}>{props.children}</LoginDataLoader>;
  } else if (authenticationError) {
    return <AuthenticationError redirectToLogin={redirectToLogin} />;
  } else {
    return <LoadingIndicator />;
  }
};
