import { ValidationErrors } from 'fluentvalidation-ts/dist/ValidationErrors';
import { Form, Formik, FormikActions, FormikProps, FormikValues } from 'formik';
import { TFunction } from 'i18next';
import { forEach } from 'lodash';
import React, { Component } from 'react';
import { FilePond } from 'react-filepond';
import { Translation } from 'react-i18next';
import { AuthenticatedApiRequest } from '../../models/apiRequest';
import { styled } from '../../styling/theme';
import { PrimaryButton } from '../buttons/Button';
import { ApiErrorBox } from '../errors/ApiErrorBox';
import { FormValidationErrorBox } from '../errors/FormValidationErrorBox';
import { ApiRequestWrapper } from '../higher-order-components/withApiRequest';
import { FilePicker, validateFile } from './FilePicker';
import { FileUploadErrorBox } from './FileUploadErrorBox';
import { FileUploadResponse } from './fileUploadResponse';

const StyledForm = styled(Form)`
  margin-top: ${props => props.theme.spacing.small}px;
`;

const StyledApiErrorBox = styled(ApiErrorBox)`
  margin-top: ${props => props.theme.spacing.small}px;
`;

const StyledFileUploadErrorBox = styled(FileUploadErrorBox)`
  margin-top: ${props => props.theme.spacing.small}px;
`;

const AdditionWarningText = styled.div`
  color: ${props => props.theme.colours.alertText};
  font-size: ${props => props.theme.typography.content.fontSize}px;
  margin-top: ${props => -props.theme.spacing.extraSmall}px;
  margin-bottom: ${props => props.theme.spacing.extraSmall}px;
`;

type Props<Values, TResponse extends FileUploadResponse> = {
  initialValues: Values;
  validate: (values: Values) => ValidationErrors<Values>;
  filePropertyName: string;
  fileFieldLabel: string;
  request: AuthenticatedApiRequest<TResponse, FormData>;
  renderAdditionalFormFields?: (props: FormikProps<Values>) => React.ReactNode;
  onSuccess: (response: TResponse) => void;
  additionalWarningText?: string;
};

type State<T> = {
  response: T | null;
};

export const fileFieldName = 'filepondFile';

export class FileUploadForm<
  Values extends object,
  TResponse extends FileUploadResponse
> extends Component<Props<Values, TResponse>, State<TResponse>> {
  filePond: FilePond | null = null;

  state: State<TResponse> = {
    response: null,
  };

  onSubmit = (
    values: FormikValues,
    formikActions: FormikActions<Values>,
    makeRequest: (params: FormData) => Promise<TResponse | null>,
  ) => {
    formikActions.setSubmitting(true);
    this.setState({ response: null });

    const filePond: FilePond = this.filePond as FilePond;
    const file = filePond.getFiles()[0];

    const formData = new FormData();
    formData.append(this.props.filePropertyName, file.file, file.filename);
    forEach(values, (value, key) => {
      if (value !== null) {
        formData.append(key, value);
      }
    });

    makeRequest(formData).then(response => {
      this.setState({ response });
      formikActions.setSubmitting(false);
      if (response && response.success) {
        this.props.onSuccess(response);
      }
    });
  };

  validateFile = (t: TFunction) => {
    const filePond: FilePond = this.filePond as FilePond;
    return validateFile(filePond, t, fileFieldName);
  };

  render() {
    return (
      <Translation ns="fileUpload">
        {t => (
          <ApiRequestWrapper<TResponse, FormData> request={this.props.request}>
            {apiProps => (
              <>
                <Formik<Values>
                  onSubmit={(values: FormikValues, formikActions: FormikActions<Values>) => {
                    this.onSubmit(values, formikActions, apiProps.makeRequest);
                  }}
                  // Need to set an initial value for the file field, otherwise formik doesn't handle errors and touched for it
                  initialValues={{ ...this.props.initialValues, [fileFieldName]: null }}
                  validate={values => {
                    const formResult = this.props.validate(values);
                    const fileResult = this.validateFile(t);
                    return {
                      ...fileResult,
                      ...formResult,
                    };
                  }}
                  render={formikBag => (
                    <StyledForm>
                      {this.props.renderAdditionalFormFields &&
                        this.props.renderAdditionalFormFields(formikBag)}
                      <FilePicker
                        allowMultiple={false}
                        fieldLabel={this.props.fileFieldLabel}
                        fieldName={fileFieldName}
                        ref={(ref: FilePond) => (this.filePond = ref)}
                      />
                      {this.props.additionalWarningText && <AdditionWarningText>{this.props.additionalWarningText}</AdditionWarningText>}                      
                      <PrimaryButton type="submit" loading={formikBag.isSubmitting}>
                        {t('labels.button')}
                      </PrimaryButton>
                      <FormValidationErrorBox
                        errors={formikBag.errors}
                        touched={formikBag.touched}
                      />
                    </StyledForm>
                  )}
                />

                {apiProps.apiError && <StyledApiErrorBox error={apiProps.apiError} />}

                {apiProps.response && !apiProps.response.success && (
                  <StyledFileUploadErrorBox fileUploadResponse={apiProps.response} />
                )}
              </>
            )}
          </ApiRequestWrapper>
        )}
      </Translation>
    );
  }
}
