import { AuthenticatedApiRequest } from '../models/apiRequest';
import { secureGet } from './apiHttpClient';

export type DashboardResponse = {
  users: Array<DashboardUserOptionsDto>;
  sites: Array<DashboardSiteOptionsDto>;
};

export type DashboardSiteOptionsDto = {
  value: number;
  displayText: string;
};

export type DashboardUserOptionsDto = {
  value: number;
  displayText: string;
};

export const fetchDashboardOptions: AuthenticatedApiRequest<DashboardResponse, void> = () =>
  secureGet(`options/dashboard`);
