import { secureGet, securePost } from '../../../api/apiHttpClient';
import { AuthenticatedApiRequest } from '../../../models/apiRequest';
import { HangFireJobDetailsResponse } from './HangFireJobDetailsResponse';
import { ItemAuditResponse } from './ItemAuditResponse';
import { PmsRequestQueueStatus } from './PmsRequestQueueStatusResponse';

export const getPmsRequestQueueStatus: AuthenticatedApiRequest<
  Array<PmsRequestQueueStatus>,
  void
> = () => secureGet(`Support/PMSRequestQueuStatus`);

export const fetchHangFireJob: AuthenticatedApiRequest<HangFireJobDetailsResponse, number> = id =>
  secureGet(`Support/FetchHangFireJobDetails/${id}`);

export const deleteHangFireJob: AuthenticatedApiRequest<HangFireJobDetailsResponse, number> = id =>
  securePost(`Support/DeleteHangFireJob`, { id });

  export const getItemAudit: AuthenticatedApiRequest<ItemAuditResponse, number> = itemId =>
  secureGet(`Support/GetItemAudit/${itemId}`);
