import { isArray, map } from 'lodash';
import * as React from 'react';
import { styled } from '../../styling/theme';

type Props = {
  errors: string | Array<string>;
};

export const errorBoxTestId = 'error-box-test-id';

const ErrorContainer = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  background-color: ${props => props.theme.colours.alertBackground};
  color: ${props => props.theme.colours.alertText};
  padding: ${props => props.theme.spacing.extraSmall}px ${props => props.theme.spacing.small}px;
`;

const ErrorHeader = styled.div`
  font-size: ${props => props.theme.typography.headings.h3fontSize}px;
  font-weight: bold;
  margin: 0;
`;

const ErrorList = styled.ul`
  margin-bottom: 0;
`;

const ErrorListItem = styled.li<{ dangerouslySetInnerHTML: { __html: string } }>`
  list-style-type: none;
`;

/**
 *
 * @param props Jira pp-932 - There is a need to display error messaged with line breaks. Use the
 *                            dangerouslySetInnerHTML to display dynamic content. As this is an in
 *                            house application displaying internal error messages, this is considered
 *                            a safe option
 * @returns
 */
export const ErrorBox = (props: Props) => {
  const [firstError, ...otherErrors] = isArray(props.errors) ? props.errors : [props.errors];
  return (
    <ErrorContainer data-testid={errorBoxTestId}>
      <ErrorHeader>{firstError}</ErrorHeader>
      {otherErrors.length > 0 && (
        <ErrorList>
          {map(otherErrors, (error, index) => (
            <ErrorListItem key={index} dangerouslySetInnerHTML={{ __html: error }} />
          ))}
        </ErrorList>
      )}
    </ErrorContainer>
  );
};
