import { RouteComponentProps } from '@reach/router';
import { flowRight } from 'lodash';
import { useState } from 'react';
import * as React from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { AlertButton, ButtonGroup, HollowButton } from '../../shared/buttons/Button';
import { DataLoader } from '../../shared/DataLoader';
import { ApiErrorBox } from '../../shared/errors/ApiErrorBox';
import {
  ApiFetchProps,
  fetchFromApiOnLoad,
} from '../../shared/higher-order-components/fetchFromApiOnLoad';
import { useApiRequest } from '../../shared/hooks/useApiRequest';
import { InfoBox } from '../../shared/info/InfoBox';
import { IfUserHasOneOfPermissions, requiresOneOfPermissions } from '../../shared/Permissions';
import { shouldShowSuccess } from '../../shared/success/SuccessBox';
import { styled } from '../../styling/theme';
import { sitesUrl } from '../../urls';
import { navigate } from '../../utils/routing';
import { MetadataContext } from '../authentication/loginData/metadataContext';
import { permissions } from '../authentication/permissions';
import { PageHeading } from '../layout/PageHeading';
import { SiteResponse } from './site';
import { SiteForm } from './SiteForm';
import {
  archiveSite,
  getMerlinGroups,
  getPmsSites,
  getSite,
  makeSiteLive,
  syncDeviations,
} from '../../api/sitesApi';

type OwnProps = RouteComponentProps<{ siteId: string }> & {};
type Props = OwnProps & ApiFetchProps<SiteResponse>;

const MarginBottomlessButtonGroup = styled(ButtonGroup)`
  margin-bottom: 0;
`;

const EditSiteComponent = (props: Props) => {
  const { t } = useTranslation('site');
  const [existingSite, setExistingSite] = useState(props.response);
  const { makeRequest, inProgress, clearError, apiError } = useApiRequest(archiveSite);
  const {
    makeRequest: makeGoLiveRequest,
    inProgress: goLiveInProgress,
    apiError: goLiveApiError,
    clearError: clearGoLiveError,
  } = useApiRequest(makeSiteLive);
  const {
    makeRequest: makeSyncDeviationsRequest,
    inProgress: syncDeviationsInProgress,
    apiError: syncDeviationsApiError,
    clearError: clearSyncDeviationsError,
  } = useApiRequest(syncDeviations);

  const onSuccess = (response: SiteResponse) => setExistingSite(response);

  const onSyncDeviationsButtonClick = () => {
    clearSyncDeviationsError();
    makeSyncDeviationsRequest({ siteId: existingSite.siteId }).then(result => {
      if (result !== null) {
        navigate(sitesUrl(), {
          state: { syncDeviations: true },
        });
      }
    });
  };

  const onGoLiveButtonClick = () => {
    clearError();
    if (window.confirm(t('editForm.goLiveConfirmation'))) {
      makeGoLiveRequest({
        siteId: existingSite.siteId,
      }).then(result => {
        if (result !== null) {
          navigate(sitesUrl(), {
            state: { success: true },
          });
        }
      });
    }
  };

  const onArchiveButtonClick = () => {
    clearGoLiveError();
    makeRequest({
      siteId: existingSite.siteId,
      siteIsArchived: existingSite.status !== 'Archived',
    }).then(result => {
      if (result) {
        onSuccess(result);
      }
    });
  };
  const hasActiveSyncDeviationsJob = existingSite.syncDeviationsJobs.some(
    job => job.status === 'Active',
  );

  return (
    <>
      <Title>
        {t('editForm.title')} - {existingSite.siteName}
      </Title>
      <PageHeading>
        <h1>
          {t('editForm.heading')} - {existingSite.siteName}
        </h1>
        <IfUserHasOneOfPermissions permissions={[permissions.SuperAdmin, permissions.CentralAdmin]}>
          <MarginBottomlessButtonGroup>
            {existingSite.status === 'Draft' && (
              <>
                <HollowButton
                  type="button"
                  onClick={onGoLiveButtonClick}
                  loading={goLiveInProgress}
                  disabled={hasActiveSyncDeviationsJob}
                >
                  {t('editForm.goLiveButton')}
                </HollowButton>
              </>
            )}
            <AlertButton
              type="button"
              onClick={onArchiveButtonClick}
              name="siteIsArchived"
              loading={inProgress}
            >
              {existingSite.status === 'Archived'
                ? t('createAndEditForm.labels.retrieveButton')
                : t('createAndEditForm.labels.archiveButton')}
            </AlertButton>
          </MarginBottomlessButtonGroup>
        </IfUserHasOneOfPermissions>
      </PageHeading>
      {apiError && <ApiErrorBox error={apiError} />}
      {goLiveApiError && <ApiErrorBox error={goLiveApiError} />}
      {syncDeviationsApiError && <ApiErrorBox error={syncDeviationsApiError} />}
      {existingSite.status === 'Draft' &&
        (hasActiveSyncDeviationsJob ? (
          <InfoBox message={t('editForm.siteHasSyncDeviationsJobScheduled')} />
        ) : (
          <InfoBox message={t('editForm.siteIsDraft')} />
        ))}
      {existingSite.status === 'GoingLive' && <InfoBox message={t('editForm.siteIsGoingLive')} />}
      <MetadataContext.Consumer>
        {metadata => (
          <DataLoader apiRequest={getPmsSites}>
            {pmsSites => (
              <DataLoader apiRequest={getMerlinGroups}>
                {merlinGroups => (
                  <SiteForm
                    pmsTypes={metadata.pmsTypes}
                    pmsSites={pmsSites.response}
                    merlinGroups={merlinGroups.response.merlinGroups}
                    existingSite={existingSite}
                    showSuccess={shouldShowSuccess()}
                    onSuccess={onSuccess}
                  />
                )}
              </DataLoader>
            )}
          </DataLoader>
        )}
      </MetadataContext.Consumer>
    </>
  );
};

const fetchFromApiOnLoadEnhancer = fetchFromApiOnLoad<OwnProps, SiteResponse, number>(
  props => Number(props.siteId),
  getSite,
);

const enhance = flowRight(
  requiresOneOfPermissions(
    permissions.SuperAdmin,
    permissions.CentralAdmin,
    permissions.SitePriceAdmin,
  ),
  fetchFromApiOnLoadEnhancer,
);

export const EditSite = enhance(EditSiteComponent);
