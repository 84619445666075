import { useContext, useEffect } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps, Column } from 'react-table';
import { toShortDate } from '../../models/dates/dateTimeStamp';
import { Link } from '../../shared/navigation/Link';
import { UserContext } from '../authentication/loginData/userContext';
import { DeviationBulkEditDetailsResponse } from '../deviations/bulkEdit/deviationBulkEdit';
import { getDeviationBulkEdits } from '../deviations/deviationsApi';
import { viewDeviationBulkEditUrl } from '../deviations/deviationsUrls';
import { SimpleTable } from '../../shared/tables/SimpleTable';
import { settings } from './Globals';
import { DashboardFilterSelection } from './models/DashboardFilterSelection';
import styled from 'styled-components';
import { useApiRequest } from '../../shared/hooks/useApiRequest';
import { ApiErrorBox } from '../../shared/errors/ApiErrorBox';

type UpcomingDeviationBulkEditsSectionProps = {
  existingFilters?: DashboardFilterSelection;
};

const SectionContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing.small}px;
`;

export const UpcomingDeviationBulkEditsSection = (
  props: UpcomingDeviationBulkEditsSectionProps,
) => {
  const { t } = useTranslation('dashboard');
  const { user } = useContext(UserContext);
  const { response, makeRequest, apiError, inProgress } = useApiRequest(getDeviationBulkEdits);
  const itmemsToDisplay = settings.upcommingItemsToDisplay;

  useEffect(() => {
    makeRequest(props.existingFilters).then();
  }, [props.existingFilters]);

  const columns: Array<Column> = [
    {
      Header: t<string>('deviationBulkEdit.upcomingSection.table.scheduledDate'),
      accessor: 'scheduledDate',
      id: 'scheduledDate',
      Cell: ({ cell }: CellProps<DeviationBulkEditDetailsResponse>) => (
        <Link to={viewDeviationBulkEditUrl(cell.row.original.deviationBulkEditId)}>
          {toShortDate(cell.value, user.locale)}
        </Link>
      ),
    },
    {
      Header: t<string>('deviationBulkEdit.upcomingSection.table.bulkEditName'),
      accessor: 'name',
      id: 'bulkEditName',
    },
    {
      Header: t<string>('deviationBulkEdit.upcomingSection.table.creatorsName'),
      accessor: 'creatorsName',
      id: 'creatorsName',
    },
    {
      Header: t<string>('deviationBulkEdit.upcomingSection.table.affectedNames'),
      accessor: 'affectedSiteNames',
      id: 'affectedNames',
      Cell: ({ cell }: CellProps<DeviationBulkEditDetailsResponse>) => (
        <span>
          {cell.value.length > 1
            ? t('deviationBulkEdit.upcomingSection.table.affectedSites', {
                sitesCount: cell.value.length,
              })
            : t('deviationBulkEdit.upcomingSection.table.affectedSite', {
                site: cell.value[0],
              })}
        </span>
      ),
    },
  ];

  return (
    /**
     * jira pp-1076 all users can see TX Bulk Edits
     */
    <SectionContainer>
      <h4>{t('deviationBulkEdit.upcomingSection.title')}</h4>

      <SimpleTable
        columns={columns}
        data={response?.deviationBulkEdits ?? []}
        itemsToDisplay={itmemsToDisplay}
        emptyTableMessage={t('deviationBulkEdit.upcomingSection.table.emptyTableMessage')}
        loading={inProgress}
      />

      {apiError && <ApiErrorBox error={apiError} />}
    </SectionContainer>
  );
};
