import { Form, FormikProps } from 'formik';
import { flowRight } from 'lodash';
import * as React from 'react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Id } from '../../../models/id';
import { PrimaryButton } from '../../../shared/buttons/Button';
import { withFormSubmit } from '../../../shared/higher-order-components/withFormSubmit';
import { requiresOneOfPermissions } from '../../../shared/Permissions';
import { styled } from '../../../styling/theme';
import { UserContext } from '../../authentication/loginData/userContext';
import { permissions } from '../../authentication/permissions';
import { SupplierProduct } from '../../items/ItemSupplierProducts/supplierProduct';
import { ApproveChangedSupplierListItems } from './ApproveChangedSupplierListItems';
import { ApproveNewSupplierListItems } from './ApproveNewSupplierListItems';
import { ApproveRemoveSupplierListItems } from './ApproveRemoveSupplierListItems';
import { ProductChangesFilters } from './select-products/ProductChangesFilters';
import { SelectAllClearAll } from './select-products/SelectAllClearAll';
import {
  getAllCodesSelected,
  getSelectedCodes,
  SelectedCodes,
} from './select-products/selectedCodes';
import { SupplierListApproval, SupplierListChangeProduct } from './supplierList';
import { approveSupplierList } from './supplierListApi';

type OwnProps = {
  supplierListId: Id;
  isFirstListFromSupplier: boolean;
  newProducts: Array<SupplierProduct>;
  removedProducts: Array<SupplierListChangeProduct>;
  changedProducts: Array<SupplierListChangeProduct>;
  onSuccess?: () => void;
};

type FormProps = {
  approvedNewProductCodes: SelectedCodes;
  approvedRemovedProductCodes: SelectedCodes;
  approvedChangedProductCodes: SelectedCodes;
};

type Props = OwnProps & FormikProps<FormProps>;

const ApproveSupplierListFormVirtualComponent = (props: Props) => {
  const { t } = useTranslation('supplierList');
  const { user } = useContext(UserContext);

  const [filteredChangedProducts, setFilteredChangedProducts] = useState(props.changedProducts);

  const MessageWithMargin = styled.p`
    margin-bottom: ${styleProps => styleProps.theme.spacing.medium}px;
  `;

  const filteredProductCodes = filteredChangedProducts.map(product => product.productCode);

  return (
    <Form>
      <h3>{t('review.new.heading')}</h3>
      {props.newProducts.length === 0 ? (
        <MessageWithMargin>{t('review.new.noNewProducts')}</MessageWithMargin>
      ) : (
        <>
          <SelectAllClearAll name="approvedNewProductCodes" />
          <ApproveNewSupplierListItems
            newProducts={props.newProducts}
            approvedNewProductCodes={props.values.approvedNewProductCodes}
            locale={user.locale}
            currency={['GBP', 'EUR']}
          />
        </>
      )}

      {!props.isFirstListFromSupplier && (
        <>
          <h3>{t('review.removed.heading')}</h3>
          {props.removedProducts.length === 0 ? (
            <MessageWithMargin>{t('review.removed.noRemovedProducts')}</MessageWithMargin>
          ) : (
            <>
              <SelectAllClearAll name="approvedRemovedProductCodes" />
              <ApproveRemoveSupplierListItems
                removedProducts={props.removedProducts}
                approvedRemovedProductCodes={props.values.approvedRemovedProductCodes}
              />
            </>
          )}

          <h3>{t('review.changed.heading')}</h3>
          {props.changedProducts.length === 0 ? (
            <MessageWithMargin>{t('review.changed.noChangedProducts')}</MessageWithMargin>
          ) : (
            <>
              <ProductChangesFilters
                name="approvedChangedProductCodes"
                changedProducts={props.changedProducts}
                onFilterProducts={setFilteredChangedProducts}
              />
              {filteredChangedProducts.length === 0 ? (
                <MessageWithMargin>{t('review.changed.noMatchingProducts')}</MessageWithMargin>
              ) : (
                <>
                  <SelectAllClearAll
                    name="approvedChangedProductCodes"
                    codes={filteredProductCodes}
                  />
                  <ApproveChangedSupplierListItems
                    changedProducts={props.changedProducts}
                    approvedChangedProductCodes={props.values.approvedChangedProductCodes}
                    locale={user.locale}
                    currency={['GBP', 'EUR']}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
      <PrimaryButton type={'submit'} loading={props.isSubmitting}>
        {t('review.approve')}
      </PrimaryButton>
    </Form>
  );
};

const enhance = flowRight(
  requiresOneOfPermissions(permissions.SuperAdmin, permissions.CentralAdmin),
  withFormSubmit<OwnProps, FormProps, void, SupplierListApproval>({
    enableReinitialize: true,
    request: approveSupplierList,
    onSubmitComplete: props => {
      if (props.onSuccess) {
        props.onSuccess();
      }
    },
    mapValuesToRequestParameters: (props: OwnProps, formModel: FormProps) => ({
      supplierListId: props.supplierListId,
      approvedChangesToProductCodes: [
        ...getSelectedCodes(formModel.approvedNewProductCodes),
        ...getSelectedCodes(formModel.approvedRemovedProductCodes),
        ...getSelectedCodes(formModel.approvedChangedProductCodes),
      ],
    }),
    mapPropsToValues: (props: OwnProps) => ({
      approvedNewProductCodes: getAllCodesSelected(props.newProducts.map(p => p.code)),
      approvedRemovedProductCodes: getAllCodesSelected(
        props.removedProducts.map(p => p.productCode),
      ),
      approvedChangedProductCodes: getAllCodesSelected(
        props.changedProducts.map(p => p.productCode),
      ),
    }),
  }),
);

export const ApproveSupplierListFormVirtual = enhance(ApproveSupplierListFormVirtualComponent);
