import { TFunction } from 'i18next';
import { Id } from '../../models/id';
import { Locale } from '../../models/locale';
import { FileUploadResponse } from '../../shared/files/fileUploadResponse';
import { PagedResponse } from '../../shared/tables/pagination/pagination';
import { TranslatableValidator } from '../../utils/validation/TranslatableValidator';
import {
  InputMaximumLength,
  TextAreaMaximumLength,
} from '../../utils/validation/validationConstants';
import { PmsCode } from '../authentication/loginData/metadata';
import { pmsInstanceCodeToPmsCodeAndMerlinGroup, PmsInstanceFilterOption } from './pms/pmsSite';
import { PracticeGroupResponse } from './practiceGroups/practiceGroup';
import { Filterable } from '../../shared/tables/filtering/useFiltering';

export type SiteStatus = 'Draft' | 'GoingLive' | 'Live' | 'Archived';
export type JobStatus = 'Active' | 'Cancelled' | 'Complete';

export type SiteColumnTypes = 'Id' | 'PmsId' | 'Name' | 'PracticeGroup';

export type ScheduledSyncJob = {
  jobId: Id;
  status: JobStatus;
  scheduledDateTime: string;
};

export type SiteResponse = {
  siteId: Id;
  siteName: string;
  sitePracticeGroups: Array<PracticeGroupResponse>;
  siteNotes: string | null;
  sitePmsTypeCode: PmsCode;
  sitePmsSiteId: number;
  merlinGroupId: number | null;
  status: SiteStatus;
  syncDeviationsJobs: Array<ScheduledSyncJob>;
};

export type GetAccessibleSiteCollectionQuery = {
  organisationGroupIds: Array<number>;
  userId: number | undefined;
};

export type SitesResponse = {
  sites: Array<SiteResponse>;
  filterOptions: SiteFilterOptions;
} & PagedResponse;

export type AccessibleSiteResponse = {
  organisationGroupId: Id;
  siteId: Id;
  siteName: string;
};
export type AccessibleSitesResponse = {
  sites: Array<AccessibleSiteResponse>;
};

export type UploadSitesResponse = {} & FileUploadResponse;

export type SiteFilterOptions = {
  searchText: string | null;
  pmsInstances: Array<PmsInstanceFilterOption> | null;
  practiceGroupsIds: Array<Id> | null;
};

export type SiteFilterFormModel = {
  searchText: string | null;
  pmsInstanceCodes: Array<string> | null;
  practiceGroupsIds: Array<Id> | null;
};

export type CreateOrEditSiteFormModel = {
  siteId: Id | null;
  siteName: string;
  sitePracticeGroups: Array<string>;
  siteNotes: string | null;
  sitePmsTypeCode: string | null;
  siteMerlinSiteId: number | null;
};

type CreateOrEditSiteCommand = {
  siteName: string;
  sitePracticeGroups: Array<string>;
  siteNotes: string | null;
  sitePmsTypeCode: PmsCode;
  sitePmsSiteId: number;
  merlinGroupId: number | null;
};

export type CreateSiteCommand = CreateOrEditSiteCommand;

export type EditSiteCommand = CreateOrEditSiteCommand & { siteId: Id };

export type GetSitessDownloadCommand = Filterable<SiteFilterOptions>;

export type ArchiveSiteCommand = {
  siteId: Id;
  siteIsArchived: boolean;
};

export type MakeSiteLiveCommand = {
  siteId: Id;
};

export type SyncDeviationsCommand = {
  siteId: Id;
};

export type CreateOrEditSiteCommandWrapper = {
  command: CreateSiteCommand | EditSiteCommand;
  createOrEditTag: 'create' | 'edit';
};

export type FetchSitesByPracticeGroupCommand = {
  PracticeGroups: Array<Id>;
  OrganisationGroups?: Array<Id>;
};

export const IsEditCommand = (
  command: CreateSiteCommand | EditSiteCommand,
  tag: 'create' | 'edit',
): command is EditSiteCommand => tag === 'edit';

export class CreateOrEditSiteFormModelValidator extends TranslatableValidator<
  CreateOrEditSiteFormModel
> {
  constructor(t: TFunction, locale: Locale) {
    super(t, locale);
    this.ruleFor('siteName')
      .notEmpty()
      .withMessage(t('site:validation.siteName.empty'))
      .when(
        formModel =>
          formModel.sitePmsTypeCode !== null &&
          pmsInstanceCodeToPmsCodeAndMerlinGroup(formModel.sitePmsTypeCode).pmsTypeCode !==
            'Merlin',
      );
    this.ruleFor('siteName')
      .maxLength(InputMaximumLength)
      .withMessage(t('site:validation.siteName.length'));
    this.ruleFor('siteNotes')
      .maxLength(TextAreaMaximumLength)
      .withMessage(t('site:validation.siteNotes.length'));
    this.ruleFor('sitePmsTypeCode')
      .notNull()
      .withMessage(t('site:validation.sitePmsTypeCode.empty'));
    this.ruleFor('siteMerlinSiteId')
      .notNull()
      .withMessage(t('site:validation.sitePmsSiteId.empty'))
      .when(
        formModel =>
          formModel.sitePmsTypeCode !== null &&
          pmsInstanceCodeToPmsCodeAndMerlinGroup(formModel.sitePmsTypeCode).pmsTypeCode ===
            'Merlin',
      );
    this.ruleFor('sitePracticeGroups')
      .must(sitePracticeGroups =>
        sitePracticeGroups.every(groupName => groupName.length <= InputMaximumLength),
      )
      .withMessage(t('site:validation.sitePracticeGroups.length'));
  }
}
