import * as React from 'react';
import { ItemAuditResponse } from '../API/ItemAuditResponse';
import { CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem } from '@coreui/react';
import { toShortDate, toShortDateTimeWithSeconds } from '../../../models/dates/dateTimeStamp';
import { UserContext } from '../../authentication/loginData/userContext';
import '@coreui/coreui/dist/css/coreui.min.css'

export interface IItemAuditProps {
    itemAuditData : ItemAuditResponse;
}

export default function ItemAudit (props: IItemAuditProps) {
  const { user } = React.useContext(UserContext);
  return (
    <div>  
    <div>   
       <div>Product Description : <b>{props.itemAuditData.itemDetails.name}</b></div>
       <div>Product Code : <b>{props.itemAuditData.itemDetails.code}</b></div>
       <br></br>
    </div>
    <div>
      <CAccordion>
        {props.itemAuditData.itemAmendments.map((change, index)=>(  
          <CAccordionItem itemKey={index} key={index}>
            <CAccordionHeader>{change.userMakingChange} {toShortDateTimeWithSeconds(change.dateOfChange, user.locale)} </CAccordionHeader>
            <CAccordionBody>
              {change.changesMade.map((individualChange, index)=>(<div key={index+'body'}>{individualChange}</div>))}
            </CAccordionBody>
          </CAccordionItem>  ))
        }
      </CAccordion>
      
      
    </div>    
    </div>
  );
}

