import { FormikProps, withFormik } from 'formik';
import { TFunction } from 'i18next';
import { flowRight, isEmpty } from 'lodash';
import * as React from 'react';
import { useContext, useState } from 'react';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { Locale } from '../../../models/locale';
import { ButtonGroup, HollowButton, PrimaryButton } from '../../../shared/buttons/Button';
import { DataLoader } from '../../../shared/DataLoader';
import { Expandable } from '../../../shared/Expandable';
import { BeValidCurrency, CurrencyField } from '../../../shared/forms/CurrencyField';
import { DropdownOption } from '../../../shared/forms/DropdownField';
import { InputField } from '../../../shared/forms/InputField';
import { MultiSelectDropdownField } from '../../../shared/forms/MultiSelectDropdownField';
import { SingleSelectDropdownField } from '../../../shared/forms/SingleSelectDropdownField';
import {
  withUserContext,
  WithUserContextProps,
} from '../../../shared/higher-order-components/withUserContext';
import { styled } from '../../../styling/theme';
import { formatNumberForFields, parseNumber } from '../../../utils/numberUtils';
import { TranslatableValidator } from '../../../utils/validation/TranslatableValidator';
import { InputMaximumLength } from '../../../utils/validation/validationConstants';
import { UserContext } from '../../authentication/loginData/userContext';
import { getSuppliers } from '../../suppliers/supplierApi';
import { ItemFilterFormModel, ItemFilterOptions } from '../item';
import {
  ItemFieldsFromPmsResponse,
  filterCategory1OptionsByType,
  itemLibraryDropdownOptions,
  itemTypeDropdownOptions,
} from '../PmsFields/pmsFields';
import { AdvancedFiltersButton, FilterAccordionBar, FilterForm } from '../../../shared/Filter';
import { CheckboxField } from '../../../shared/forms/CheckboxField';
import { MetadataContext } from '../../authentication/loginData/metadataContext';
import { CountryResponse } from '../../authentication/loginData/metadata';

type OwnProps = {
  itemFieldsFromPms: ItemFieldsFromPmsResponse;
  existingFilters?: ItemFilterOptions;
  onApply: (response: ItemFilterOptions) => void;
};
type Props = OwnProps & FormikProps<ItemFilterFormModel>;

const FlexRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const FlexCheckboxField = styled(CheckboxField)`
  flex: 1 0 25%;
`;

const FlexButtonGroup = styled(ButtonGroup)`
  flex: 1 0 100%;
  align-self: center;
  margin-bottom: 0;
  margin-top: ${props => props.theme.spacing.small}px;
`;

export const CountrySpecificFilterOptionsNamePrefix = (index: number): string =>
  `countrySpecificFilters.[${index}]`;

const ItemsFilterFormComponent = (props: Props) => {
  const { t } = useTranslation('item');
  const { user } = useContext(UserContext);
  const { countries } = useContext(MetadataContext);
  const userCountries = countries.filter(country => user.countries.includes(country.code));

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const booleanDropdownOptions: Array<DropdownOption<boolean | null>> = [
    { displayText: t('itemsFilter.options.all'), value: null },
    { displayText: t('itemsFilter.options.enable'), value: true },
    { displayText: t('itemsFilter.options.disable'), value: false },
  ];

  const clearFields = () => {
    props.setValues({
      searchText: null,
      type: null,
      species: null,
      category1: null,
      category2: null,
      category3: null,
      suppliers: null,
      clientCategory: null,
      supplierCode: null,
      centralPriceMin: null,
      centralPriceMax: null,
      centralMarkupPercentageMin: null,
      centralMarkupPercentageMax: null,
      isDisabled: false,
      stockWithEmptySupplier: null,
      bulkEditScheduled: null,
      discontinuedProduct: null,
      countrySpecificFilters: userCountries.map(country => ({
        countryCode: country.code,
        isEnabled: null,
        isDisabled: null,
        isEither: true,
      })),
    });
  };

  const onSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    props.handleSubmit(e);
    if (isEmpty(props.errors)) {
      setShowAdvancedFilters(false);
    }
  };

  const updateCountrySpecificFilter = (
    country: CountryResponse,
    isEnabled: boolean,
    isDisabled: boolean,
  ) => {
    return props.values.countrySpecificFilters.map(countryOptions =>
      countryOptions.countryCode === country.code
        ? {
            ...countryOptions,
            isEnabled,
            isDisabled,
            isEither: !(isEnabled || isDisabled),
          }
        : countryOptions,
    );
  };

  const resetCountrySpecificFilter = (country: CountryResponse) => {
    return updateCountrySpecificFilter(country, false, false);
  };

  return (
    <>
      <FilterAccordionBar
        label={t('itemsFilter.filter')}
        isOpen={showAdvancedFilters}
        onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
      />
      <FilterForm onSubmit={onSubmit} onReset={props.handleReset}>
        <FlexRowContainer>
          <InputField name="searchText" label={t('itemsFilter.searchText')} />
          <MultiSelectDropdownField
            name="type"
            label={t('itemLabels.type')}
            options={itemTypeDropdownOptions(props.itemFieldsFromPms, t)}
          />
          <MultiSelectDropdownField
            name="category1"
            label={t('itemLabels.category1')}
            options={filterCategory1OptionsByType(
              props.itemFieldsFromPms.category1Options,
              props.values.type,
            )}
          />
          <MultiSelectDropdownField
            name="category2"
            label={t('itemLabels.category2')}
            options={itemLibraryDropdownOptions(props.itemFieldsFromPms.category2Options)}
          />
        </FlexRowContainer>
        <Expandable isExpanded={showAdvancedFilters}>
          <FlexRowContainer>
            <MultiSelectDropdownField
              name="species"
              label={t('itemLabels.species')}
              options={itemLibraryDropdownOptions(props.itemFieldsFromPms.speciesOptions)}
            />
            <MultiSelectDropdownField
              name="category3"
              label={t('itemLabels.category3')}
              options={itemLibraryDropdownOptions(props.itemFieldsFromPms.category3Options)}
            />
            <MultiSelectDropdownField
              name="clientCategory"
              label={t('itemLabels.clientCategory')}
              options={itemLibraryDropdownOptions(props.itemFieldsFromPms.clientCategoryOptions)}
            />
            <DataLoader apiRequest={getSuppliers}>
              {supplierResponse => (
                <MultiSelectDropdownField
                  options={supplierResponse.response.suppliers.map(supplier => ({
                    displayText: supplier.supplierName,
                    value: supplier.supplierId,
                  }))}
                  name="suppliers"
                  label={t('itemsFilter.supplier')}
                />
              )}
            </DataLoader>

            <InputField name="supplierCode" label={t('itemsFilter.supplierCode')} />

            <CurrencyField
              name="centralPriceMin"
              label={
                user.useGrossPrices
                  ? t('itemsFilter.centralPriceGrossMin')
                  : t('itemsFilter.centralPriceNetMin')
              }
            />
            <CurrencyField
              name="centralPriceMax"
              label={
                user.useGrossPrices
                  ? t('itemsFilter.centralPriceGrossMax')
                  : t('itemsFilter.centralPriceNetMax')
              }
            />
            <InputField
              name="centralMarkupPercentageMin"
              label={t('itemsFilter.centralMarkupPercentageMin')}
              type="number"
            />
            <InputField
              name="centralMarkupPercentageMax"
              label={t('itemsFilter.centralMarkupPercentageMax')}
              type="number"
            />
            <SingleSelectDropdownField
              options={booleanDropdownOptions}
              name="isDisabled"
              label={t('itemsFilter.isDisabled')}
            />
            <SingleSelectDropdownField
              options={booleanDropdownOptions}
              name="stockWithEmptySupplier"
              label={t('itemsFilter.stockWithEmptySupplier')}
            />
            <SingleSelectDropdownField
              options={booleanDropdownOptions}
              name="bulkEditScheduled"
              label={t('itemsFilter.bulkEditScheduled')}
            />
            <SingleSelectDropdownField
              options={booleanDropdownOptions}
              name="discontinuedProduct"
              label={t('itemsFilter.discontinuedProduct')}
            />
          </FlexRowContainer>
          {userCountries.map((country, index) => (
            <React.Fragment key={country.code}>
              <h4>{t(`itemsFilter.countryFilters.${country.code}`)}</h4>
              <FlexRowContainer>
                <FlexCheckboxField
                  name={`${CountrySpecificFilterOptionsNamePrefix(index)}.isEnabled`}
                  label={t('itemsFilter.countryFilters.isEnabledForCountry')}
                  onChange={value => {
                    value
                      ? props.setValues({
                          ...props.values,
                          countrySpecificFilters: updateCountrySpecificFilter(country, true, false),
                        })
                      : props.setValues({
                          ...props.values,
                          countrySpecificFilters: resetCountrySpecificFilter(country),
                        });
                  }}
                />
                <FlexCheckboxField
                  name={`${CountrySpecificFilterOptionsNamePrefix(index)}.isDisabled`}
                  label={t('itemsFilter.countryFilters.isDisabledForCountry')}
                  onChange={value => {
                    value
                      ? props.setValues({
                          ...props.values,
                          countrySpecificFilters: updateCountrySpecificFilter(country, false, true),
                        })
                      : props.setValues({
                          ...props.values,
                          countrySpecificFilters: resetCountrySpecificFilter(country),
                        });
                  }}
                />
                <FlexCheckboxField
                  name={`${CountrySpecificFilterOptionsNamePrefix(index)}.isEither`}
                  label={t('itemsFilter.countryFilters.isEitherForCountry')}
                  disabled={
                    props.values.countrySpecificFilters.filter(
                      countryOptions => countryOptions.countryCode === country.code,
                    )[0]?.isEither ?? false
                  }
                  onChange={value => {
                    if (value) {
                      props.setValues({
                        ...props.values,
                        countrySpecificFilters: resetCountrySpecificFilter(country),
                      });
                    }
                  }}
                />
              </FlexRowContainer>
            </React.Fragment>
          ))}
        </Expandable>
        <FlexButtonGroup>
          <HollowButton
            onClick={clearFields}
            type="button"
            disabled={props.isSubmitting}
            name="clearFields"
          >
            {t('itemsFilter.clearFieldsButton')}
          </HollowButton>
          <PrimaryButton type="submit" loading={props.isSubmitting}>
            {t('itemsFilter.applyButton')}
          </PrimaryButton>
          <AdvancedFiltersButton
            onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
            type="button"
            disabled={props.isSubmitting}
          >
            {showAdvancedFilters
              ? t('itemsFilter.hideAdvancedFilters')
              : t('itemsFilter.showAdvancedFilters')}
          </AdvancedFiltersButton>
        </FlexButtonGroup>
      </FilterForm>
    </>
  );
};

const withUserContextEnhancer = withUserContext();

const withTranslationsEnhancer = withTranslation('item');

const withFormikEnhancer = withFormik<
  OwnProps & WithUserContextProps & WithTranslation,
  ItemFilterFormModel
>({
  enableReinitialize: true,
  handleSubmit: (values, { props, setSubmitting }) => {
    props.onApply({
      searchText: values.searchText,
      types: values.type,
      speciesIds: values.species,
      category1Ids: values.category1,
      category2Ids: values.category2,
      category3Ids: values.category3,
      supplierIds: values.suppliers,
      clientCategoryIds: values.clientCategory,
      supplierCode: values.supplierCode,
      centralPriceMin:
        values.centralPriceMin !== null && values.centralPriceMin !== ''
          ? parseNumber(values.centralPriceMin, props.user.locale)
          : null,
      centralPriceMax:
        values.centralPriceMax !== null && values.centralPriceMax !== ''
          ? parseNumber(values.centralPriceMax, props.user.locale)
          : null,
      centralMarkupPercentageMin:
        values.centralMarkupPercentageMin !== null && values.centralMarkupPercentageMin !== ''
          ? parseNumber(values.centralMarkupPercentageMin, props.user.locale)
          : null,
      centralMarkupPercentageMax:
        values.centralMarkupPercentageMax !== null && values.centralMarkupPercentageMax !== ''
          ? parseNumber(values.centralMarkupPercentageMax, props.user.locale)
          : null,
      isDisabled: values.isDisabled,
      stockWithEmptySupplier: values.stockWithEmptySupplier,
      bulkEditScheduled: values.bulkEditScheduled,
      discontinuedProduct: values.discontinuedProduct,
      countrySpecificFilterOptions: values.countrySpecificFilters.map(country => ({
        countryCode: country.countryCode,
        isEnabled: country.isEither ? null : country.isEnabled,
      })),
    });
    setSubmitting(false);
  },
  mapPropsToValues: props => ({
    searchText: props.existingFilters?.searchText ?? null,
    type: props.existingFilters?.types ?? null,
    species: props.existingFilters?.speciesIds ?? null,
    category1: props.existingFilters?.category1Ids ?? null,
    category2: props.existingFilters?.category2Ids ?? null,
    category3: props.existingFilters?.category3Ids ?? null,
    suppliers: props.existingFilters?.supplierIds ?? null,
    clientCategory: props.existingFilters?.clientCategoryIds ?? null,
    supplierCode: props.existingFilters?.supplierCode ?? null,
    centralPriceMax:
      props.existingFilters?.centralPriceMax == null
        ? null
        : formatNumberForFields(props.existingFilters.centralPriceMax, props.user.locale),
    centralPriceMin:
      props.existingFilters?.centralPriceMin == null
        ? null
        : formatNumberForFields(props.existingFilters.centralPriceMin, props.user.locale),
    centralMarkupPercentageMax:
      props.existingFilters?.centralMarkupPercentageMax == null
        ? null
        : formatNumberForFields(
            props.existingFilters.centralMarkupPercentageMax,
            props.user.locale,
          ),
    centralMarkupPercentageMin:
      props.existingFilters?.centralMarkupPercentageMin == null
        ? null
        : formatNumberForFields(
            props.existingFilters.centralMarkupPercentageMin,
            props.user.locale,
          ),
    isDisabled: props.existingFilters?.isDisabled ?? null,
    stockWithEmptySupplier: props.existingFilters?.stockWithEmptySupplier ?? null,
    bulkEditScheduled: props.existingFilters?.bulkEditScheduled ?? null,
    discontinuedProduct: props.existingFilters?.discontinuedProduct ?? null,
    countrySpecificFilters:
      props.existingFilters?.countrySpecificFilterOptions?.map(countryOptions => ({
        countryCode: countryOptions.countryCode,
        isEnabled: countryOptions.isEnabled ?? false,
        isDisabled: countryOptions.isEnabled != null ? !countryOptions.isEnabled : false,
        isEither: countryOptions.isEnabled == null,
      })) ?? [],
  }),
  validate: (values, props) => {
    const validator = new ItemFilterFormValidator(props.t, props.user.locale);
    return validator.validate(values);
  },
});

const enhance = flowRight(withUserContextEnhancer, withTranslationsEnhancer, withFormikEnhancer);

export const ItemsFilterForm: React.ComponentType<OwnProps> = enhance(ItemsFilterFormComponent);

class ItemFilterFormValidator extends TranslatableValidator<ItemFilterFormModel> {
  constructor(t: TFunction, locale: Locale) {
    super(t, locale);
    this.ruleFor('searchText')
      .maxLength(InputMaximumLength)
      .withMessage(t('itemsFilter.validation.searchText.length'));
    this.ruleFor('centralPriceMin')
      .must(minPrice => minPrice == null || minPrice === '' || BeValidCurrency(minPrice, locale))
      .withMessage(t('itemsFilter.validation.centralPriceMin.validCurrency'));
    this.ruleFor('centralPriceMax')
      .must(maxPrice => maxPrice == null || maxPrice === '' || BeValidCurrency(maxPrice, locale))
      .withMessage(t('itemsFilter.validation.centralPriceMax.validCurrency'))
      .must(
        (maxPrice, model) =>
          maxPrice == null ||
          maxPrice === '' ||
          model.centralPriceMin == null ||
          model.centralPriceMin === '' ||
          parseNumber(maxPrice, locale) >= parseNumber(model.centralPriceMin, locale),
      )
      .withMessage(t('itemsFilter.validation.centralPriceMax.greaterThanMin'));

    this.ruleFor('centralMarkupPercentageMax')
      .must(
        (maxPrice, model) =>
          maxPrice == null ||
          maxPrice === '' ||
          model.centralMarkupPercentageMin == null ||
          model.centralMarkupPercentageMin === '' ||
          parseNumber(model.centralMarkupPercentageMin, locale) <= parseNumber(maxPrice, locale),
      )
      .withMessage(t('itemsFilter.validation.centralMarkupPercentageMax.greaterThanMin'));
  }
}
