import { Id } from '../../../models/id';
import { supplierListUrl } from '../supplierUrls';

export const uploadSupplierListUrl = () => `${supplierListUrl()}/upload`;

export const getSupplierListItemsDownloadUrl = () =>
  `${supplierListUrl()}/getSupplierListItemsDownload`;

export const reviewSupplierListUrl = (supplierListId: Id) =>
  `${supplierListUrl()}/review/${supplierListId}`;
