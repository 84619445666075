import styled from 'styled-components';

export const NotesEditContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.colours.border};
  border-radius: ${props => props.theme.spacing.extraTiny}px;
  color: black;
`;
export const DateAndOperatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
`;
export const NewNoteContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
  align-items: baseline;
`;
export const NoteContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const DivWithMargin = styled.div`
  margin: 10px;
`;
export const ButtonDiv = styled(DivWithMargin)`
  & button {
    width: 100%;
    height: 100%;
    border-radius: ${props => props.theme.spacing.extraTiny}px;
  }
`;
export const DateDiv = styled(DivWithMargin)`
  width: 100%;
  display: flex;
  flex-direction: column;
  & .react-datepicker-wrapper input {
    width: 130px;
    height: 35px;
    border: 1px solid ${props => props.theme.colours.border};
    border-radius: ${props => props.theme.spacing.extraTiny}px;
  }
`;
export const OperatorDiv = styled(DivWithMargin)`
  display: flex;
  flex-direction: column;
`;
export const SpacerDiv = styled(DivWithMargin)`
  width: 100%;
`;
export const StandardLabel = styled.label`
  width: 40px;
  height: 35px;
  color: ${props => props.theme.colours.infoText};
`;
export const StandardInput = styled.input<React.InputHTMLAttributes<HTMLInputElement>>`
  width: 40px;
  height: 35px;
  border: 1px solid ${props => props.theme.colours.border};
  border-radius: ${props => props.theme.spacing.extraTiny}px;
`;
export const LongInput = styled.input<React.InputHTMLAttributes<HTMLInputElement>>`
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
  height: 35px;
  border: 1px solid ${props => props.theme.colours.border};
  border-radius: ${props => props.theme.spacing.extraTiny}px;
`;
export const MainTable = styled.table`
  width: 100%;
`;
export const TableHeader = styled.thead`
  background-color: gray;
`;
export const TableBody = styled.tbody`
  background-color: white;
`;
export const CentreAlignCell = styled.td`
  text-align: center;
`;
export const NotesCell = styled.td`
  word-break: break-word;
`;
export const ErrorDiv = styled.div`
  background-color: #fcd5cd;
  color: #ee2b4e;
  border-color: #ee2b4e;
  border-radius: 8px;
  border: 1px solid;
  line-height: 1.8;
  text-align: center;
`;
