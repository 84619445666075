import Oidc, { User, UserManager, UserManagerSettings } from 'oidc-client';
import * as React from 'react';
import { removeApplicationInsightUser } from '../../applicationInsights';
import { navigate } from '../../utils/routing';
import { AccessToken } from './accessToken';
import { AuthenticationContext, AuthenticationContextValue } from './authenticationContext';
import {
  loggedOutUrl,
  oidc_config,
  signInRedirectUrl,
  silentTokenRefreshUrl,
} from './authenticationUrls';

export const authenticationConfig: UserManagerSettings = {
  metadata: {
    issuer: oidc_config.issuer,
    authorization_endpoint: oidc_config.authorization_endpoint,
    token_endpoint: oidc_config.token_endpoint,
    end_session_endpoint: oidc_config.end_session_endpoint,
  },
  authority: oidc_config.authority,
  client_id: oidc_config.client_id,
  redirect_uri: window.location.origin,
  response_type: 'token',
  scope: 'openid email 464ec399-4ba0-475e-9ab8-33547fed645e/user-access',
  silent_redirect_uri: window.location.origin + silentTokenRefreshUrl,
};

const isSignInRedirectCallback = () => window.location.pathname === signInRedirectUrl;

const userKey = 'oidc.user';
const loginStateKey = 'isLoggingIn';

type State = {
  user: User | null | undefined;
  authenticationError: Error | null;
  isLoggingIn: boolean;
  isLoggingOut: boolean;
};

export type AuthenticationProps = {
  isAuthenticated: boolean;
  authenticationError: Error | null;
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  redirectToLogin: () => void;
};

type OwnProps = {
  children: (authentication: AuthenticationProps) => React.ReactNode;
};

export type LoginOptions = { forceLoginPrompt?: boolean };

export class Authentication extends React.Component<OwnProps, State> {
  userManager = new UserManager(authenticationConfig);
  state = {
    user: null,
    authenticationError: null,
    isLoggingIn: isSignInRedirectCallback(),
    isLoggingOut: false,
  };

  async componentDidMount() {
    // Retrieve tokens and state from shared storage
    const storedUser = JSON.parse(localStorage.getItem(userKey) as string);

    if (storedUser) {
      const currentUser = new Oidc.User(storedUser);
      if (currentUser.expired) {
        await this.userManager.removeUser();
      } else {
        await this.userManager.storeUser(currentUser);
      }
    }
    const loggedInUser = await this.userManager.getUser();

    if (loggedInUser) {
      this.setState(
        {
          user: loggedInUser,
          authenticationError: null,
          isLoggingIn: false,
          isLoggingOut: false,
        },
        this.redirectToOriginalPageAfterSignIn,
      );
    } else {
      const waitingForCallBack = sessionStorage.getItem(loginStateKey);
      if (waitingForCallBack === 'true') {
        this.handleSignInRedirectCallback();
      } else {
        this.redirectToLogin();
      }
    }
    this.userManager.events.addUserLoaded(user => {
      localStorage.setItem(userKey, JSON.stringify(user));
    });
  }

  componentWillUnmount() {
    this.userManager.events.removeUserSignedOut(this.handleUserSignedOut);
  }

  handleSignInRedirectCallback = () => {
    sessionStorage.setItem(loginStateKey, 'false');
    this.userManager
      .signinRedirectCallback()
      .then((user: User) => {
        this.userManager.storeUser(user);
        this.setState(
          {
            user,
            authenticationError: null,
            isLoggingIn: false,
            isLoggingOut: false,
          },
          this.redirectToOriginalPageAfterSignIn,
        );
      })
      .catch(error => {
        // tslint:disable-next-line:no-console
        // console.error('Error handling sign-in redirect:', error);
        this.setState(
          {
            user: null,
            authenticationError: error,
            isLoggingIn: false,
            isLoggingOut: false,
          },
          this.redirectToOriginalPageAfterSignIn,
        );
      });
  };

  redirectToLogin = (options: LoginOptions = {}) => {
    this.setState({ isLoggingIn: true });
    sessionStorage.setItem(loginStateKey, 'true');
    const signInArgs = { ...(options.forceLoginPrompt ? { prompt: 'login' } : undefined) };
    this.userManager
      .signinRedirect(signInArgs)
      .then(() => {
        this.setState({ isLoggingIn: true });
      })
      .catch(error => {
        sessionStorage.setItem(loginStateKey, 'false');
        // tslint:disable-next-line:no-console
        // console.error('Error handling redirecting to login:', error);
        this.setState({
          user: null,
          authenticationError: error,
          isLoggingIn: false,
          isLoggingOut: false,
        });
      });
  };

  redirectToOriginalPageAfterSignIn = () => {};

  logout = () => {
    removeApplicationInsightUser();
    localStorage.removeItem(userKey);
    this.setState(
      {
        user: null,
        authenticationError: null,
        isLoggingIn: false,
        isLoggingOut: true,
      },
      this.redirectToOriginalPageAfterSignIn,
    );

    this.userManager.signoutRedirect();
  };

  getAccessToken = async (): Promise<AccessToken> => {
    try {
      let user = await this.userManager.getUser();

      if (user == null || user.expired) {
        // console.log('Access token expired, initiating silent renewal'); // tslint:disable-line:no-console
        user = await this.userManager.signinSilent();
      }

      return user.access_token;
    } catch (error) {
      // console.error('Error obtaining access token', error); // tslint:disable-line:no-console
      this.redirectToLogin();
      throw error;
    }
  };

  handleUserSignedOut = () => {
    navigate(loggedOutUrl);
  };

  authenticationContextValue: AuthenticationContextValue = {
    getAccessToken: this.getAccessToken,
    logout: this.logout,
  };

  render() {
    return (
      <AuthenticationContext.Provider value={this.authenticationContextValue}>
        {this.props.children({
          ...this.state,
          isAuthenticated: this.state.user != null,
          redirectToLogin: this.redirectToLogin,
        })}
      </AuthenticationContext.Provider>
    );
  }
}
