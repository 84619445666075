import React from 'react';
import { PmsRequestQueueStatus } from '../API/PmsRequestQueueStatusResponse';
import { Div } from '../../../styling/Site';

type PmsQueueDetailProps = {
  queueStatus: Array<PmsRequestQueueStatus>;
};

const PmsQueueDetails = (props: PmsQueueDetailProps) => {
  return (
    <Div $paddingTop="25" $paddingBottom="25">
      <table>
        {props.queueStatus.map(m => (
          <tr>
            <td style={{ width: '100px' }}>{m.status}</td>
            <td>{new Intl.NumberFormat('en-GB').format(m.count)}</td>
          </tr>
        ))}
      </table>
    </Div>
  );
};

export default PmsQueueDetails;
