import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import JSONPretty from 'react-json-pretty';
import { isJson } from '../../utils/jsonUtils';
import { Accordion, AccordionChildrenProps } from '../Accordion';

type Props = {
  value: string;
};

const AccordionClickableArea = (accordionProps: AccordionChildrenProps) => {
  const { t } = useTranslation('component');
  return accordionProps.isOpen ? (
    <span>
      {t('expandableJson.hide')} <FontAwesomeIcon icon={faAngleUp} />
    </span>
  ) : (
    <span>
      {t('expandableJson.expand')} <FontAwesomeIcon icon={faAngleDown} />
    </span>
  );
};

export const ExpandableJson = (props: Props) => (
  <Accordion headerComponent={AccordionClickableArea} initiallyOpen={true}>
    <JSONPretty data={props.value} />
  </Accordion>
);

export const PrettyJson = (props: Props) =>
  isJson(props.value) ? <ExpandableJson value={props.value} /> : <>{props.value}</>;
