import Axios, { AxiosRequestConfig, Canceler } from 'axios';
import { AccessToken } from '../feature/authentication/accessToken';
import { CancellablePromise, makeCancellablePromise } from '../models/cancellablePromise';
import {
  ApiError,
  ApiErrorJson,
  apiTimeoutError,
  defaultApiError,
  toApiError,
} from '../models/error';

export const client = Axios.create({
  baseURL: '/api',
  timeout: 60_000,
  headers: {
    'Cache-Control': 'no-cache, no-store',
    Pragma: 'no-cache',
  },
});

// tslint:disable-next-line:no-any
const getApiError = (error: any): ApiError => {
  if (error.response && error.response.data) {
    return toApiError(error.response.data as ApiErrorJson);
  } else {
    return defaultApiError();
  }
};

client.interceptors.response.use(
  config => config,
  error => {
    if (error.code === 'ECONNABORTED') {
      return Promise.reject(apiTimeoutError());
    }
    if (error.response && error.response.data && error.response.data.pacmanHandleAsNonError) {
      return error.response;
    }
    const apiError = getApiError(error);
    if (!['production', 'test'].includes(process.env.NODE_ENV)) {
      if (error.config) {
        // tslint:disable-next-line:no-console
        console.error(
          `Got error response from ${error.config.method} ${error.config.url}`,
          apiError,
        );
      } else {
        // tslint:disable-next-line:no-console
        console.error(`Got error response from api:`, error, apiError);
      }
    }
    return Promise.reject(apiError);
  },
);

const get = <TResponse>(
  url: string,
  accessToken: AccessToken,
  params: object | null = null,
): CancellablePromise<TResponse> => {
  let cancel: Canceler = () => undefined;

  const config: AxiosRequestConfig = {
    cancelToken: new Axios.CancelToken(canceler => {
      cancel = canceler;
    }),
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    timeout: 180_000,
  };

  if (params) {
    config.params = params;
  }

  const responsePromise = client.get(url, config).then(response => response.data);
  return makeCancellablePromise(responsePromise, cancel);
};

export const secureGet = <TResponse, TRequestParams>(url: string, params: object | null = null) => (
  accessToken: AccessToken,
): CancellablePromise<TResponse> => get<TResponse>(url, accessToken, params);

const post = <TResponse>(
  url: string,
  accessToken: AccessToken,
  data: object,
): CancellablePromise<TResponse> => {
  let cancel: Canceler = () => undefined;

  const config: AxiosRequestConfig = {
    cancelToken: new Axios.CancelToken(canceler => {
      cancel = canceler;
    }),
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    timeout: 180_000,
  };

  const responsePromise = client.post(url, data, config).then(response => {
    return response.data;
  });
  return makeCancellablePromise(responsePromise, cancel);
};

const patch = <TResponse>(
  url: string,
  accessToken: AccessToken,
  data: object,
): CancellablePromise<TResponse> => {
  let cancel: Canceler = () => undefined;

  const config: AxiosRequestConfig = {
    cancelToken: new Axios.CancelToken(canceler => {
      cancel = canceler;
    }),
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    timeout: 180_000,
  };

  const responsePromise = client.patch(url, data, config).then(response => {
    return response.data;
  });
  return makeCancellablePromise(responsePromise, cancel);
};

export const securePost = <TResponse, TParams extends object>(url: string, data: TParams) => (
  accessToken: AccessToken,
): CancellablePromise<TResponse> => post(url, accessToken, data);

const postFile = <TResponse>(
  url: string,
  accessToken: AccessToken,
  data: FormData,
): CancellablePromise<TResponse> => {
  let cancel: Canceler = () => undefined;

  const config: AxiosRequestConfig = {
    cancelToken: new Axios.CancelToken(canceler => {
      cancel = canceler;
    }),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data',
    },
    timeout: 180_000,
  };

  const responsePromise = client.post(url, data, config).then(response => {
    return response.data;
  });
  return makeCancellablePromise(responsePromise, cancel);
};

export const securePostFile = <TResponse>(url: string, data: FormData) => (
  accessToken: AccessToken,
): CancellablePromise<TResponse> => postFile(url, accessToken, data);

export const securePatch = <TResponse, TParams extends object>(url: string, data: TParams) => (
  accessToken: AccessToken,
): CancellablePromise<TResponse> => patch(url, accessToken, data);

export const getHttpClient = () => client;
