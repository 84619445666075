import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../feature/authentication/loginData/userContext';
import { styled } from '../../../styling/theme';
import { formatNumber } from '../../../utils/numberUtils';
import { IconButton } from '../../buttons/IconButton';

export const paginationControlsTestId = 'pagination-controls-test-id';

type PaginationControlsProps = {
  noOfItemsToDisplay: number;
  itemCount: number;
  pageOptions: Array<number>;
  pageIndex: number;
  pageCount: number;
  canNextPage: boolean;
  canPreviousPage: boolean;
  nextPage: () => void;
  previousPage: () => void;
  gotoPage: (page: number) => void;
};

const PaginationContainer = styled.div<
  React.HTMLAttributes<HTMLDivElement> & { atTop?: boolean; atBottom?: boolean }
>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-weight: normal;
  background-color: ${props => props.theme.colours.componentBackground};
  border-collapse: collapse;
  border: 1px solid ${props => props.theme.colours.border};
  padding: ${props => props.theme.spacing.tiny}px;

  border-bottom: ${props => props.atTop && `none`};
  border-top: ${props => props.atBottom && `none`};
`;

const PageDetails = styled.div`
  margin-right: ${props => props.theme.spacing.extraSmall}px;
  min-height: ${props => props.theme.typography.content.lineHeight}em;
`;

export const SimplePaginator = (props: PaginationControlsProps) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation('component');

  const startItem = props.pageIndex * props.noOfItemsToDisplay + 1;
  const endItem = startItem + props.noOfItemsToDisplay - 1;
  const totalItems = props.itemCount;
  const firstPage = () => props.gotoPage(0);
  const lastPage = () => props.gotoPage(props.pageCount - 1);
  const startIndex = formatNumber(startItem, user.locale);
  const endIndex = formatNumber(endItem > totalItems ? totalItems : endItem, user.locale);

  return (
    <PaginationContainer>
      <PageDetails>
        {t('paginationControls.pageDetails', {
          startIndex,
          endIndex,
          totalItems: formatNumber(props.itemCount, user.locale),
        })}
      </PageDetails>
      <IconButton
        aria-label={t('component:paginationControls.firstPage')}
        onClick={firstPage}
        disabled={!props.canPreviousPage}
        //   aria-disabled={props.loading}
        faIcon={faAngleDoubleLeft}
      />
      <IconButton
        aria-label={t('component:paginationControls.previousPage')}
        onClick={props.previousPage}
        disabled={!props.canPreviousPage}
        //   aria-disabled={props.loading}
        faIcon={faAngleLeft}
      />
      <IconButton
        aria-label={t('component:paginationControls.nextPage')}
        onClick={props.nextPage}
        disabled={!props.canNextPage}
        //   aria-disabled={props.loading}
        faIcon={faAngleRight}
      />
      <IconButton
        aria-label={t('component:paginationControls.lastPage')}
        onClick={lastPage}
        disabled={!props.canNextPage}
        //   aria-disabled={props.loading}
        faIcon={faAngleDoubleRight}
      />
    </PaginationContainer>
  );
};
