import { secureGet, securePost, securePatch } from '../../api/apiHttpClient';
import { AuthenticatedApiRequest } from '../../models/apiRequest';
import { Id } from '../../models/id';
import { PagedRequest } from '../../shared/tables/pagination/pagination';
import { GetPmsRequestErrorResponse, GetPmsRequestErrorsResponse } from './pmsRequestError';

export const getPmsRequestErrors: AuthenticatedApiRequest<
  GetPmsRequestErrorsResponse,
  PagedRequest
> = pageRequest => securePost('pms-request-errors', pageRequest);

export const getPmsRequestErrorsForItemBulkEdit = (
  itemBulkEditId: Id,
): AuthenticatedApiRequest<GetPmsRequestErrorsResponse, PagedRequest> => pageRequest =>
  securePost('pms-request-errors/item-bulk-edit', { ...pageRequest, itemBulkEditId });

export const getPmsRequestErrorsForDeviationBulkEdit = (
  deviationBulkEditId: Id,
): AuthenticatedApiRequest<GetPmsRequestErrorsResponse, PagedRequest> => pageRequest =>
  securePost('pms-request-errors/deviation-bulk-edit', { ...pageRequest, deviationBulkEditId });

export const getPmsRequestErrorsForTreatmentTypeDeviationBulkEdit = (
  treatmentTypeDeviationBulkEditId: Id,
): AuthenticatedApiRequest<GetPmsRequestErrorsResponse, PagedRequest> => pageRequest =>
  securePost('pms-request-errors/treatment-type-deviation-bulk-edit', {
    ...pageRequest,
    treatmentTypeDeviationBulkEditId,
  });

export const getPmsRequestError: AuthenticatedApiRequest<
  GetPmsRequestErrorResponse,
  Id
> = pmsRequestErrorId => secureGet(`pms-request-errors/${pmsRequestErrorId}`);

export const clearPmsRequestError: AuthenticatedApiRequest<void, Id> = (pmsRequestErrorId: Id) =>
  securePost(`pms-request-errors/${pmsRequestErrorId}/clear`, {});

export const clearPmsRequestErrors: AuthenticatedApiRequest<void, {}> = () =>
  securePatch(`pms-request-errors/clear`, {});
