import { map } from 'lodash';
import React, { useState } from 'react';
import { styled } from '../../styling/theme';

type TabHeadingProps = {
  active: boolean;
  title: string;
  onSelect: () => void;
  testId?: string;
};

export const activeTabHeaderTestId = `active-tab-header`;

const TabHeading = ({ active, title, onSelect, testId }: TabHeadingProps) => (
  <TabHeadingContainer
    active={active}
    onClick={onSelect}
    data-testid={active ? activeTabHeaderTestId : testId || null}
  >
    <TabTitle>{title}</TabTitle>
  </TabHeadingContainer>
);

const TabHeadingContainer = styled.div<React.HTMLAttributes<HTMLDivElement> & { active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: ${props => props.theme.spacing.extraSmall}px ${props => props.theme.spacing.small}px;
  border: solid 1px ${props => props.theme.colours.border};
  border-bottom: ${props => (props.active ? 'transparent' : 'default')};
  border-radius: ${props => props.theme.spacing.tiny}px ${props => props.theme.spacing.tiny}px 0 0;
  background-color: ${props =>
    props.active ? props.theme.colours.componentBackground : props.theme.colours.border};
  cursor: ${props => (props.active ? 'default' : 'pointer')};
  user-select: none;
  margin-right: -1px;
  margin-bottom: -1px;

  &:hover {
    background-color: ${props => props.theme.colours.componentBackground};
  }
`;

const TabTitle = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export type Tab = {
  title: string;
  content: React.ReactNode;
};

type TabsProps = {
  tabs: Array<Tab>;
};

export const Tabs = ({ tabs }: TabsProps) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  return (
    <>
      <TabHeadings>
        {map(tabs, (tab, tabIndex) => (
          <TabHeading
            active={tabIndex === selectedTabIndex}
            title={tab.title}
            onSelect={() => setSelectedTabIndex(tabIndex)}
            key={tabIndex}
          />
        ))}
      </TabHeadings>
      <TabContent>{tabs[selectedTabIndex].content}</TabContent>
    </>
  );
};

export const TabHeadings = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TabContent = styled.div`
  padding: ${props => props.theme.spacing.small}px;
  border: solid 1px ${props => props.theme.colours.border};
  border-radius: 0 ${props => props.theme.spacing.extraTiny}px
    ${props => props.theme.spacing.extraTiny}px ${props => props.theme.spacing.extraTiny}px;
  background-color: ${props => props.theme.colours.componentBackground};
  margin-bottom: ${props => props.theme.spacing.extraSmall}px;
`;
