import { faChevronDown, faChevronUp, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { styled } from '../../../styling/theme';

type SortingIndicatorProps = {
  isSorted: boolean;
  isDescending?: boolean;
  className?: string;
};

type FontAwesomeIconWithPropsProps = {
  isActive: boolean;
  faIcon: IconDefinition;
};

const StyledContainer = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  vertical-align: middle;
`;

const StyledIconContainer = styled.div<
  React.HTMLAttributes<HTMLDivElement> & { isActive: boolean }
>`
  color: ${props =>
    props.isActive ? props.theme.colours.secondary : props.theme.colours.secondaryInactive};
  font-size: ${props => props.theme.typography.subtext.fontSize}px;
  line-height: 1;
`;

const FontAwesomeIconWithProps = (props: FontAwesomeIconWithPropsProps) => (
  <StyledIconContainer isActive={props.isActive}>
    <FontAwesomeIcon icon={props.faIcon} />
  </StyledIconContainer>
);

export const SortingIndicator = (props: SortingIndicatorProps) => (
  <StyledContainer className={props.className}>
    <FontAwesomeIconWithProps
      faIcon={faChevronUp}
      isActive={props.isSorted && !props.isDescending}
    />
    <FontAwesomeIconWithProps
      faIcon={faChevronDown}
      isActive={props.isSorted && !!props.isDescending}
    />
  </StyledContainer>
);
