import Downshift, {
  ControllerStateAndHelpers,
  DownshiftState,
  StateChangeOptions,
} from 'downshift';
import { FormikHandlers, FormikProps } from 'formik';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled, theme } from '../../styling/theme';
import {
  DropdownItem,
  DropdownItemWidth,
  DropdownMenu,
  DropdownMenuContainer,
} from '../dropdowns/DropdownComponents';
import {
  dropdownItemTestId,
  getDisplayTextForDropdownValueOrUndefined,
} from '../dropdowns/dropdownHelpers';
import { BaseInputProps, BaseInputStyling } from './BaseInputStyling';
import { DropdownOption } from './DropdownField';
import {
  ErrorMessageMarginProps,
  FormField,
  FormFieldLabel,
  FormFieldProps,
  WithErrorMessageMargin,
} from './FormField';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '../buttons/IconButton';

export const singleSelectDeselectOption: DropdownOption<string | number | null> = {
  displayText: '----',
  value: null,
};

export const singleSelectDropdownFieldInputTestId = <TFieldName extends unknown>(
  fieldName: TFieldName,
) => `singleSelectDropdownField:${fieldName}`;

type OptionType = DropdownOption<string | number | boolean | null>;

type SingleSelectDropdownProps = {} & BaseInputProps;

/**
 * Jira - pp-997
 * Add the functon stub to the component props
 */
type SingleSelectComponentProps = {
  name: string;
  hideLabel?: boolean;
  label?: string;
  onChange: FormikHandlers['handleChange'];
  onBlur: FormikHandlers['handleBlur'];
  disabled?: boolean;
  options: Array<OptionType>;
  value: string | number;
  setFieldValue: (value: unknown) => void;
  setFieldTouched: () => void;
  deselectable?: boolean;
  testId?: string;
  onInputValueChange?: (value: string) => void;
  showCopyToClipboardButton?: boolean;
} & BaseInputProps;

type SingleSelectDropdownFieldProps<TFormModel> = {
  options: Array<OptionType> | ((form: FormikProps<TFormModel>) => Array<OptionType>);
  disabled?: boolean;
  deselectable?: boolean;
  testId?: string;
  showCopyToClipboardButton?: boolean;
} & FormFieldProps<TFormModel>;

export const StyledSingleSelectDropdown = styled.div<ErrorMessageMarginProps>`
  ${WithErrorMessageMargin};
`;

const StyledFormFieldLabel = styled(FormFieldLabel)`
  margin-bottom: ${props => props.theme.spacing.extraSmall}px;
`;

export const StyledInput = styled.input<SingleSelectDropdownProps>`
  ${BaseInputStyling};
  &::placeholder {
    color: inherit;
  }
`;

const SingleSelectComponent = (props: SingleSelectComponentProps) => {
  const { t } = useTranslation('component');
  const [menuLeftOffset, setMenuLeftOffset] = useState<number | undefined>(undefined);
  const menuContainerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const deselectOptionOrEmpty: Array<OptionType> = props.deselectable
    ? [singleSelectDeselectOption]
    : [];
  const options = deselectOptionOrEmpty.concat(props.options);

  useEffect(() => {
    if (
      props.value &&
      getDisplayTextForDropdownValueOrUndefined(options, props.value) === undefined
    ) {
      props.setFieldValue(null);
      props.setFieldTouched();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, props.value]);

  useEffect(() => {
    if (
      menuContainerRef.current &&
      menuContainerRef.current.getBoundingClientRect().right + DropdownItemWidth > window.innerWidth
    ) {
      setMenuLeftOffset(DropdownItemWidth - menuContainerRef.current.getBoundingClientRect().width);
    } else {
      setMenuLeftOffset(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilteredOptions = (inputValue: string | null) =>
    inputValue
      ? options.filter(option =>
          option.displayText.toLowerCase().includes(inputValue.toLowerCase()),
        )
      : options;

  const itemToString = (item: OptionType | null) => (item ? item.displayText : '');

  const stateReducer = (
    state: DownshiftState<OptionType>,
    changes: StateChangeOptions<OptionType>,
  ): Partial<StateChangeOptions<OptionType>> => {
    switch (changes.type) {
      // This does not trigger on mouseup in the input, only after hovering the menu then clicking elsewhere
      case Downshift.stateChangeTypes.mouseUp:
      case Downshift.stateChangeTypes.blurInput:
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          inputValue: '',
        };
      default:
        return changes;
    }
  };

  const handleSelection = (
    selection: OptionType | null,
    downshift: ControllerStateAndHelpers<OptionType>,
  ) => {
    if (selection !== undefined && selection !== null) {
      if (selection.value !== props.value) {
        props.setFieldValue(selection.value);
      }
    }
  };

  const inputClick = (downshift: ControllerStateAndHelpers<OptionType>) => () => {
    if (!props.disabled) {
      downshift.toggleMenu();
    }
  };

  const handleBlur = (e: React.FocusEvent) => props.onBlur(e);

  /**
   * Jira - pp-997
   * Add a function to get the value from the input as you type,
   * using the inputValue prop provided by Downshift.
   */
  const handleInputValueChange = (inputValue: string) => {
    if (props.onInputValueChange) {
      props.onInputValueChange(inputValue);
    }
  };

  const copyToClipboard = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const placeholderValue = inputRef.current?.placeholder;
    if (placeholderValue) {
      navigator.clipboard.writeText(placeholderValue);
    }
  };

  /**
   * Jira - pp-997
   * Add the prop and the callback to the main downshift function
   */
  return (
    <Downshift
      itemToString={itemToString}
      onSelect={handleSelection}
      stateReducer={stateReducer}
      onInputValueChange={handleInputValueChange}
    >
      {downshift => (
        <StyledSingleSelectDropdown
          {...downshift.getRootProps()}
          showingError={props.invalid}
          bulkVariant={props.bulkVariant}
          onBlur={handleBlur}
        >
          {!props.hideLabel && (
            <StyledFormFieldLabel {...downshift.getLabelProps()} showingError={props.invalid}>
              {props.label}
              {props.showCopyToClipboardButton && (
                <IconButton
                  onClick={copyToClipboard}
                  type="button"
                  faIcon={faCopy}
                  color="buttons.secondaryHighlight"
                  title="Copy to clipboard"
                />
              )}
            </StyledFormFieldLabel>
          )}
          <StyledInput
            {...downshift.getInputProps()}
            placeholder={
              getDisplayTextForDropdownValueOrUndefined(options, props.value) ||
              t('singleSelectDropdownField.select')
            }
            name={props.name}
            disabled={props.disabled}
            invalid={props.invalid}
            onClick={inputClick(downshift)}
            bulkVariant={props.bulkVariant}
            data-testid={props.testId ?? singleSelectDropdownFieldInputTestId(props.name)}
            aria-label={props.hideLabel ? props.label : undefined}
            autoComplete="off"
            ref={inputRef}
          />
          <DropdownMenuContainer ref={menuContainerRef}>
            {downshift.isOpen ? (
              <DropdownMenu
                {...downshift.getMenuProps({
                  disabled: props.disabled,
                })}
                menuLeftOffset={menuLeftOffset}
              >
                {getFilteredOptions(downshift.inputValue).map((item: OptionType, index: number) => (
                  // tslint:disable-next-line:jsx-key Provided by getItemProps
                  <DropdownItem
                    {...downshift.getItemProps({
                      item,
                      index,
                      key: `${item.value}` || 'null',
                      isSelected: item.value === props.value,
                    })}
                    isHighlighted={downshift.highlightedIndex === index}
                    data-testid={dropdownItemTestId(props.name, item.value)}
                  >
                    {downshift.itemToString(item)}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            ) : null}
          </DropdownMenuContainer>
        </StyledSingleSelectDropdown>
      )}
    </Downshift>
  );
};

/**
 * This should be used with formik. For use outside of formik use DropdownField
 *
 * Jira - pp-997
 * Add the prop and the callback to the main downshift function
 */
export const SingleSelectDropdownField = <TFormModel extends unknown>(
  props: SingleSelectDropdownFieldProps<TFormModel>,
) => {
  const { options, disabled, label, ...formFieldProps } = props;

  return (
    <FormField {...formFieldProps}>
      {({ field, form, invalid }) => {
        const setFieldValue = (value: unknown) => {
          if (props.onChange) {
            props.onChange(value as TFormModel, form);
          }
          form.setFieldValue(field.name, value);
        };
        const setFieldTouched = () => form.setFieldTouched(field.name);

        return (
          <SingleSelectComponent
            {...field}
            label={props.label}
            invalid={invalid}
            disabled={props.disabled}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            options={typeof props.options === 'function' ? props.options(form) : props.options}
            bulkVariant={props.bulkVariant}
            hideLabel={props.hideLabel}
            deselectable={props.deselectable}
            testId={props.testId}
            onInputValueChange={props.onInputValueChange}
            showCopyToClipboardButton={props.showCopyToClipboardButton}
          />
        );
      }}
    </FormField>
  );
};
