export type ComparisonType =
  | 'Less'
  | 'LessOrEqual'
  | 'Greater'
  | 'GreaterOrEqual'
  | 'Equal'
  | 'NotEqual'
  | 'Everything';

export const checkboxesToComparisonType = (
  above: boolean | null,
  same: boolean | null,
  below: boolean | null,
): ComparisonType | null => {
  if (!above && !same && below) {
    return 'Less';
  }
  if (!above && same && below) {
    return 'LessOrEqual';
  }
  if (above && !same && !below) {
    return 'Greater';
  }
  if (above && same && !below) {
    return 'GreaterOrEqual';
  }
  if (!above && same && !below) {
    return 'Equal';
  }
  if (above && !same && below) {
    return 'NotEqual';
  }
  if (above && same && below) {
    return 'Everything';
  }
  return null;
};

export const comparisonIsGreaterThan = (comparisonType: ComparisonType | null): boolean =>
  comparisonType === 'Greater' ||
  comparisonType === 'GreaterOrEqual' ||
  comparisonType === 'NotEqual' ||
  comparisonType === 'Everything';

export const comparisonIsEqualTo = (comparisonType: ComparisonType | null): boolean =>
  comparisonType === 'Equal' ||
  comparisonType === 'LessOrEqual' ||
  comparisonType === 'GreaterOrEqual' ||
  comparisonType === 'Everything';

export const comparisonIsLessThan = (comparisonType: ComparisonType | null): boolean =>
  comparisonType === 'Less' ||
  comparisonType === 'LessOrEqual' ||
  comparisonType === 'NotEqual' ||
  comparisonType === 'Everything';
