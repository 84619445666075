import { secureGet, securePost } from '../../api/apiHttpClient';
import { AuthenticatedApiRequest } from '../../models/apiRequest';
import { Id } from '../../models/id';
import { SuppliersFromPmsResponse } from './pms/suppliersFromPms';
import { EditSupplierCommandWrapper, SupplierResponse, SuppliersResponse } from './supplier';

export const getSupplier: AuthenticatedApiRequest<SupplierResponse, Id> = supplierId =>
  secureGet(`suppliers/${supplierId}`);

export const getSuppliers: AuthenticatedApiRequest<SuppliersResponse, void> = () =>
  secureGet('suppliers');

export const editSupplier: AuthenticatedApiRequest<
  SupplierResponse,
  EditSupplierCommandWrapper
> = supplier => securePost(`suppliers/edit`, supplier);
export const getSuppliersFromPms: AuthenticatedApiRequest<SuppliersFromPmsResponse, void> = () =>
  secureGet('suppliers/pms');

export const archiveSupplier: AuthenticatedApiRequest<SupplierResponse, Id> = supplierId =>
  securePost(`suppliers/archive/${supplierId}`, {});
export const syncSuppliersFromPms: AuthenticatedApiRequest<void, void> = () =>
  securePost(`suppliers/sync`, {});
