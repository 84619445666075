import * as React from 'react';
import { styled } from '../../styling/theme';

type Props = {
  message: string | React.ReactNode;
  subMessage?: string;
  noMinHeight?: boolean;
  testId?: string;
};

export const infoBoxTestId = 'info-box-test-id';

const InfoHeightPlaceholder = styled.div<
  React.HTMLAttributes<HTMLDivElement> & { noMinHeight?: boolean }
>`
  margin-top: ${props => props.theme.spacing.small}px;
  margin-bottom: ${props => props.theme.spacing.small}px;
  min-height: ${props => (props.noMinHeight ? 0 : 90)}px;
`;

const InfoContainer = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  background-color: ${props => props.theme.colours.infoBackground};
  color: ${props => props.theme.colours.infoText};
  padding: ${props => props.theme.spacing.extraSmall}px ${props => props.theme.spacing.small}px;
`;

const InfoMessage = styled.div`
  font-size: ${props => props.theme.typography.headings.h3fontSize};
  font-weight: bold;
  margin: 0;
`;

const InfoSubMessage = styled.div`
  margin-bottom: 0;
`;

export const InfoBox = (props: Props) => {
  return (
    <InfoHeightPlaceholder noMinHeight={props.noMinHeight}>
      <InfoContainer data-testid={props.testId ?? infoBoxTestId}>
        <InfoMessage>{props.message}</InfoMessage>
        {props.subMessage && <InfoSubMessage>{props.subMessage}</InfoSubMessage>}
      </InfoContainer>
    </InfoHeightPlaceholder>
  );
};
