import * as React from 'react';
import { FixedSizeList } from 'react-window';
import { styled } from '../../../styling/theme';
import { getSelectedCodes, SelectedCodes } from './select-products/selectedCodes';
import { SupplierListChangeProduct } from './supplierList';
import './supplierlistitems.css';

type ApproveRemoveSupplierListItemsProps = {
  removedProducts: Array<SupplierListChangeProduct>;
  approvedRemovedProductCodes: SelectedCodes;
};
const DisplayValue = styled.span`
  padding-left: 10px;
`;

export const ApproveRemoveSupplierListItems = (props: ApproveRemoveSupplierListItemsProps) => {
  const selectedItems = getSelectedCodes(props.approvedRemovedProductCodes);
  const selectedRemovedItems = props.removedProducts.filter((value, index) => {
    return selectedItems.includes(value.productCode);
  });
  const [change, setChange] = React.useState(false);
  const toDisplayValue = (value: string | number | null | undefined) =>
    value ? value.toString() : '';

  const handleInputChange = (index: number) => {
    props.approvedRemovedProductCodes[selectedRemovedItems[index].productCode] = !props
      .approvedRemovedProductCodes[selectedRemovedItems[index].productCode];
    if (index > -1) {
      selectedRemovedItems.splice(index, 1);
    }
    setChange(!change);
  };

  const displayRow = ({ index, style }: { index: number; style: React.CSSProperties }) => {
    if (props.approvedRemovedProductCodes[selectedRemovedItems[index].productCode]) {
      return (
        <div style={style}>
          <input
            type="checkbox"
            checked={props.approvedRemovedProductCodes[selectedRemovedItems[index].productCode]}
            onChange={() => {
              handleInputChange(index);
            }}
          />
          <DisplayValue>{toDisplayValue(selectedRemovedItems[index].productCode)}</DisplayValue>
          <DisplayValue>{toDisplayValue(selectedRemovedItems[index].oldName)}</DisplayValue>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <FixedSizeList
        className="virtualsupplierlist"
        height={250}
        itemCount={selectedRemovedItems.length}
        itemSize={25}
        width={'100%'}
      >
        {displayRow}
      </FixedSizeList>
    </>
  );
};
