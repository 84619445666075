import { map } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ApiError } from '../../models/error';
import { ErrorBox } from './ErrorBox';

type Props = {
  error: ApiError;
  className?: string;
};

export const apiErrorBoxTestId = 'api-error-box-test-id';

export const ApiErrorBox = (props: Props) => {
  const { t } = useTranslation(['serverErrors', 'serverValidation']);
  return (
    <div data-testid={apiErrorBoxTestId} className={props.className}>
      <ErrorBox
        errors={map(props.error.userVisibleMessages, error => t(error.key, error.params))}
      />
    </div>
  );
};
