import React, { useContext } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../authentication/loginData/userContext';
import { IfUserHasPermission, RequiresOneOfPermissions } from '../../../../shared/Permissions';
import { Title } from 'react-head';
import { getLocalNow } from '../../../../models/dates/now';
import { uploadTreatmentTypeDeviationBulkEdit } from '../../deviationsApi';
import { assertIsDefined } from '../../../../utils/assertIsDefined';
import { InputField } from '../../../../shared/forms/InputField';
import { CheckboxField } from '../../../../shared/forms/CheckboxField';
import { DateField } from '../../../../shared/forms/DateField';
import { FileUploadForm } from '../../../../shared/files/FileUploadForm';
import {
  UploadTreatmentTypeDeviationResponse,
  uploadTreatmentTypeDeviationsPermissions,
} from './treatmentTypeDeviationBulkEdit';
import { viewTreatmentTypeDeviationBulkEditUrl } from '../treatmentTypeDeviationsUrls';
import { TranslatableValidator } from '../../../../utils/validation/TranslatableValidator';
import { TFunction } from 'i18next';
import { Locale } from '../../../../models/locale';
import { InputMaximumLength } from '../../../../utils/validation/validationConstants';
import { BeTodayOrLater } from '../../../../models/dates/dateTimeStamp';
import { permissions } from '../../../authentication/permissions';
import { HelpText } from '../../DeviationsBase';

type UploadTreatmentTypeDeviationFormModel = {
  name: string | null;
  runImmediately: boolean;
  scheduledDateTime: string;
  forceUpload: boolean;
};

class Validator extends TranslatableValidator<UploadTreatmentTypeDeviationFormModel> {
  constructor(t: TFunction, locale: Locale) {
    super(t, locale);
    this.ruleFor('name')
      .notNull()
      .withMessage(t('deviations:validation.bulkEditName.empty'))
      .notEmpty()
      .withMessage(t('deviations:validation.bulkEditName.empty'))
      .maxLength(InputMaximumLength)
      .withMessage(t('deviation:validation.bulkEditName.length'));
    this.ruleFor('scheduledDateTime')
      .must(date => BeTodayOrLater(date, locale))
      .withMessage(t('deviation:validation.scheduledDate.invalid'))
      .when(form => !form.runImmediately);
  }
}

export const UploadTreatmentTypeDeviationBulkEdit = (props: RouteComponentProps) => {
  const { t } = useTranslation('treatmentTypeDeviations');
  const { user } = useContext(UserContext);

  return (
    <RequiresOneOfPermissions permissions={uploadTreatmentTypeDeviationsPermissions}>
      <Title>{t('uploadBulkEdit.title')}</Title>
      <h1>{t('uploadBulkEdit.heading')}</h1>
      <FileUploadForm<UploadTreatmentTypeDeviationFormModel, UploadTreatmentTypeDeviationResponse>
        initialValues={{
          name: null,
          runImmediately: false,
          scheduledDateTime: getLocalNow(user.locale).toISODate(),
          forceUpload: false,
        }}
        validate={values => {
          const validator = new Validator(t, user.locale);
          return validator.validate(values);
        }}
        filePropertyName="file"
        fileFieldLabel={t('uploadBulkEdit.file')}
        request={uploadTreatmentTypeDeviationBulkEdit}
        onSuccess={response => {
          assertIsDefined(props.navigate)(
            viewTreatmentTypeDeviationBulkEditUrl(
              assertIsDefined(response.treatmentTypeDeviationBulkEditId),
            ),
          );
        }}
        additionalWarningText={t('uploadBulkEdit.processingWarning')}
        renderAdditionalFormFields={formikProps => (
          <>
            <CheckboxField name="runImmediately" label={t('uploadBulkEdit.runImmediately')} />
            <InputField name="name" label={t('uploadBulkEdit.bulkEditName')} />
            <IfUserHasPermission permission={permissions.SuperAdmin}>
              <CheckboxField
                name="forceUpload"
                label={t('uploadBulkEdit.forceUpload')}
                infoText={t('uploadBulkEdit.forceUploadMessage')}
              />
            </IfUserHasPermission>
            {!formikProps.values.runImmediately && (
              <DateField
                name="scheduledDateTime"
                label={t('uploadBulkEdit.scheduledDate.label')}
                infoText={t('uploadBulkEdit.scheduledDate.infoText')}
              />
            )}
          </>
        )}
      />
    </RequiresOneOfPermissions>
  );
};
