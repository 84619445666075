import { RouteComponentProps, Router } from '@reach/router';
import * as React from 'react';
import { PageNotFound } from '../PageNotFound';
import { PmsQueueStatus } from './PmsQueueStatus';
import { Support } from './Support';
import { HangfireJob } from './HangfireJob';
import { ItemHistory } from './ItemHistory';

export const SupportSection = (props: RouteComponentProps) => (
  <Router primary={false}>
    <Support path="/" />
    <PmsQueueStatus path="/status" />
    <HangfireJob path="/hangfire-job" />
    <ItemHistory path="/itemHistory/:itemId" />
    <PageNotFound default={true} />
  </Router>
);
