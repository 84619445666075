import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ControllerStateAndHelpers } from 'downshift';
import * as React from 'react';
import {
  DownshiftChildrenContainer,
  DropdownItem,
  DropdownMenu,
  DropdownMenuContainer,
} from './DropdownComponents';
import {
  MultiSelectDropdownBase,
  MultiSelectDropdownBaseChildrenProps,
} from './MultiSelectDropdownBase';

export type MultiSelectDropdownProps<TItem> = {
  label: string;
  options: Array<TItem>;
  itemToString: (item: TItem | null) => string;
  onOptionSelect: (selectedItems: Array<TItem>) => void;
  initiallySelectedOptions?: Array<TItem>;
  renderToggleButton: (
    toggleProps: MultiSelectDropdownProps<TItem>,
    downshift: ControllerStateAndHelpers<TItem>,
  ) => JSX.Element | string;
};

export const MultiSelectDropdown = <TItem extends { value: string | number }>(
  props: MultiSelectDropdownProps<TItem>,
) => (
  <MultiSelectDropdownBase<TItem>
    itemToString={props.itemToString}
    onSelect={props.onOptionSelect}
    initiallySelectedItems={props.initiallySelectedOptions}
  >
    {(downshift: MultiSelectDropdownBaseChildrenProps<TItem>) => (
      <DownshiftChildrenContainer {...downshift.getRootProps()} role={undefined}>
        {props.renderToggleButton(props, downshift)}
        <DropdownMenuContainer>
          {downshift.isOpen ? (
            <DropdownMenu {...downshift.getMenuProps()}>
              {props.options.map((item, index) => {
                const isSelected = downshift.selectedItems.some(
                  selectedItem => selectedItem.value === item.value,
                );
                return (
                  // tslint:disable-next-line:jsx-key Provided by getItemProps
                  <DropdownItem
                    {...downshift.getItemProps({
                      item,
                      index,
                      key: item.value,
                      isSelected,
                    })}
                    isHighlighted={downshift.highlightedIndex === index}
                  >
                    {downshift.itemToString(item)}
                    {isSelected && <FontAwesomeIcon icon={faCheck} />}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          ) : null}
        </DropdownMenuContainer>
      </DownshiftChildrenContainer>
    )}
  </MultiSelectDropdownBase>
);
