import * as React from 'react';
import { TFunction } from 'i18next';
import { round } from 'lodash';
import { CellProps } from 'react-table';
import { ControlledInput } from '../../../shared/forms/ControlledInput';
import { CurrencyField } from '../../../shared/forms/CurrencyField';
import { CurrencyInput } from '../../../shared/forms/CurrencyInput';
import { PercentField } from '../../../shared/forms/PercentField';
import { PercentInput } from '../../../shared/forms/PercentInput';
import { CustomColumn } from '../../../shared/tables/Table';
import { TupleKeyDictionary } from '../../../utils/TupleKeyDictionary';
import { LoginUserResponse, userHasOneOfPermissions } from '../../authentication/loginData/user';
import { PracticeGroupResponse } from '../../sites/practiceGroups/practiceGroup';
import { DeviationColumnTypes, DeviationResponse } from '../deviation';
import {
  deviatedDispenseFeeColumn,
  deviatedPriceColumn,
  dispenseFeeDeviationPercentValueColumn,
  displayCalculatedValueOrSpan,
  itemCodeColumn,
  itemNameColumn,
  priceDeviationPercentValueColumn,
} from '../DeviationColumns';
import {
  getCurrencyForCountry,
  isItemSpecificDispenseFeeDeviation,
  isItemSpecificPriceDeviation,
  onDeviatedDispenseFeeChange,
  onDeviatedPriceChange,
  onPercentageDispenseFeeDeviationChange,
  onPercentagePriceDeviationChange,
  practiceGroupHasServiceTypeSubRow,
  practiceGroupHasSubRowWithDiscount,
  practiceGroupHasSubRowWithOverwritePrice,
  practiceGroupHasSubRowWithZeroDispenseFee,
  practiceGroupHasSubRowWithZeroPrice,
  rowHasOverwritePrice,
  rowHasZeroPriceDispenseFee,
  rowHasZeroPricePrice,
  rowIsDiscount,
  rowItemIsServiceType,
} from '../DeviationsBase';
import {
  editCreateDeviationBulkEditPermissions,
  EditDeviationBulkEditFormModel,
  getDeviationFieldByFieldName,
  getDeviationFieldPrefix,
} from './deviationBulkEdit';
import {
  deviationBulkEditValueHasChanged,
  deviationExpandedColumn,
  deviationHeaderValueHasChanged,
  getDeviationHeaderFieldProps,
  getGroupedDeviationRowDispenseFeePercentageTestId,
  getGroupedDeviationRowPricePercentageTestId,
  groupedActionsColumn,
  groupedCentralItemPriceColumn,
  GroupedDeviationResponseRow,
  groupedIsHiddenColumn,
  groupedPracticeGroupOrSiteNameColumn,
  groupedCentralItemDispenseFeeColumn,
  groupedWarningsColumn,
  groupedPriceCodeSpecificColumn,
  groupedDispenseFeeCodeSpecificColumn,
} from './SharedDeviationBulkEditColumns';
import { styled } from '../../../styling/theme';
import { LeftSymbolInput, RightSymbolInput } from '../../../shared/forms/SymbolInput';
import { FormikProps, getIn } from 'formik';
import { formatNumberForFields, parseNumber } from '../../../utils/numberUtils';
import { ById, Id } from '../../../models/id';
import { CountryResponse } from '../../authentication/loginData/metadata';
import { getDeviatedValue } from './ViewEditDeviationBulkEditShared';
import { ItemLibrary } from '../../items/PmsFields/pmsFields';

const DeviatedCurrencyField = styled(CurrencyField)<{ highlightAsItemSpecificDeviation: boolean }>`
  ${LeftSymbolInput} {
    color: 'default';
  }
`;

const DeviatedPercentField = styled(PercentField)<{ highlightAsItemSpecificDeviation: boolean }>`
  ${RightSymbolInput} {
    color: 'default';
  }
`;

const MAX_DECIMAL_PLACES = 4;

export const getCreateDeviationBulkEditColumns = (
  t: TFunction,
  user: LoginUserResponse,
  scheduledBulkEditIdBySiteIdThenItemId: TupleKeyDictionary<number, number, number> | null,
  practiceGroupsById: { [practiceGroupId: number]: PracticeGroupResponse },
  countries: Array<CountryResponse>,
  discountCategoryId: number,
): Array<CustomColumn<GroupedDeviationResponseRow, DeviationColumnTypes>> => [
  deviationExpandedColumn(),
  groupedPracticeGroupOrSiteNameColumn(
    t,
    scheduledBulkEditIdBySiteIdThenItemId,
    practiceGroupsById,
  ),
  itemNameColumn(t),
  itemCodeColumn(t),
  groupedIsHiddenColumn(t, scheduledBulkEditIdBySiteIdThenItemId, user),
  groupedCentralItemPriceColumn(t, user, countries),
  {
    ...deviatedPriceColumn(t, user, countries),
    Cell: ({ cell, row, formikProps }: CellProps<GroupedDeviationResponseRow>) => {
      if (row.canExpand) {
        const { fieldValue, updateSubRowFields } = getDeviationHeaderFieldProps(
          row,
          formikProps,
          user,
          'deviatedPrice',
          onDeviatedPriceChange,
          deviation =>
            user.useGrossPrices ? deviation.centralItemPriceGross : deviation.centralItemPriceNet,
        );
        return (
          <ControlledInput initialValue={fieldValue}>
            {({ value, onChange }) => (
              <CurrencyInput
                value={value}
                onChange={onChange}
                bulkVariant={true}
                onBlurIfChange={updateSubRowFields}
                disabled={
                  !userHasOneOfPermissions(
                    user,
                    editCreateDeviationBulkEditPermissions.deviatePrices,
                  ) ||
                  practiceGroupHasSubRowWithOverwritePrice(row) ||
                  practiceGroupHasSubRowWithDiscount(row, discountCategoryId) ||
                  practiceGroupHasSubRowWithZeroPrice(row)
                }
                isRightAligned={true}
                warning={deviationHeaderValueHasChanged(formikProps, row, 'deviatedPrice', value)}
                currency={getCurrencyForCountry(
                  row.subRows[0].original.siteCountry,
                  user,
                  countries,
                )}
              />
            )}
          </ControlledInput>
        );
      } else {
        if (
          scheduledBulkEditIdBySiteIdThenItemId &&
          scheduledBulkEditIdBySiteIdThenItemId.get(row.original.siteId, row.original.itemId)
        ) {
          return displayCalculatedValueOrSpan(
            cell.value,
            row.original.priceDeviationIsSetByPercent,
            true,
            row.original.deviationId != null || row.original.category1Id == discountCategoryId,
          );
        } else {
          const valueHasChanged = deviationBulkEditValueHasChanged(
            formikProps,
            row,
            'deviatedPrice',
          );

          return (
            <DeviatedCurrencyField
              highlightAsItemSpecificDeviation={
                isItemSpecificPriceDeviation(row.original) && !valueHasChanged
              }
              bulkVariant={true}
              fastVariant={true}
              hideLabel={true}
              name={`${getDeviationFieldPrefix(row)}.deviatedPrice`}
              label={t('columnHeaders.deviatedPrice')}
              useDebouncing={true}
              onChange={onDeviatedPriceChange(
                row.original.siteId,
                row.original.itemId,
                user.useGrossPrices
                  ? row.original.centralItemPriceGross
                  : row.original.centralItemPriceNet,
                user,
                row.original.itemType,
              )}
              isRightAligned={true}
              disabled={
                !userHasOneOfPermissions(
                  user,
                  editCreateDeviationBulkEditPermissions.deviatePrices,
                ) ||
                rowHasOverwritePrice(row) ||
                rowHasZeroPricePrice(row) ||
                rowIsDiscount(row, discountCategoryId)
              }
              warning={valueHasChanged}
              currency={getCurrencyForCountry(row.original.siteCountry, user, countries)}
            />
          );
        }
      }
    },
  },
  {
    ...priceDeviationPercentValueColumn(t, user),
    Cell: ({ cell, row, formikProps }: CellProps<GroupedDeviationResponseRow>) => {
      if (row.canExpand) {
        const { fieldValue, updateSubRowFields } = getDeviationHeaderFieldProps(
          row,
          formikProps,
          user,
          'percentagePriceDeviation',
          onPercentagePriceDeviationChange,
          deviation =>
            user.useGrossPrices ? deviation.centralItemPriceGross : deviation.centralItemPriceNet,
        );
        return (
          <ControlledInput initialValue={fieldValue}>
            {({ value, onChange }) => (
              <PercentInput
                data-testid={getGroupedDeviationRowPricePercentageTestId(
                  row.original.practiceGroupId,
                  row.original.itemId,
                )}
                value={value}
                onChange={onChange}
                bulkVariant={true}
                onBlurIfChange={updateSubRowFields}
                isRightAligned={true}
                disabled={
                  !userHasOneOfPermissions(
                    user,
                    editCreateDeviationBulkEditPermissions.deviatePrices,
                  ) ||
                  practiceGroupHasSubRowWithOverwritePrice(row) ||
                  practiceGroupHasSubRowWithDiscount(row, discountCategoryId) ||
                  practiceGroupHasSubRowWithZeroPrice(row)
                }
                warning={deviationHeaderValueHasChanged(
                  formikProps,
                  row,
                  'percentagePriceDeviation',
                  value,
                )}
              />
            )}
          </ControlledInput>
        );
      } else {
        if (
          scheduledBulkEditIdBySiteIdThenItemId &&
          scheduledBulkEditIdBySiteIdThenItemId.get(row.original.siteId, row.original.itemId)
        ) {
          return cell.value === null ? (
            <span />
          ) : (
            displayCalculatedValueOrSpan(
              round(cell.value, MAX_DECIMAL_PLACES) + '%',
              !row.original.priceDeviationIsSetByPercent,
              true,
              row.original.deviationId != null || row.original.category1Id == discountCategoryId,
            )
          );
        } else {
          const valueHasChanged = deviationBulkEditValueHasChanged(
            formikProps,
            row,
            'percentagePriceDeviation',
          );

          return (
            <DeviatedPercentField
              highlightAsItemSpecificDeviation={
                isItemSpecificPriceDeviation(row.original) && !valueHasChanged
              }
              bulkVariant={true}
              hideLabel={true}
              name={`${getDeviationFieldPrefix(row)}.percentagePriceDeviation`}
              testId={`${getDeviationFieldPrefix(row)}.percentagePriceDeviation`}
              label={t('columnHeaders.priceDeviationPercentValue')}
              useDebouncing={true}
              onChange={onPercentagePriceDeviationChange(
                row.original.siteId,
                row.original.itemId,
                user.useGrossPrices
                  ? row.original.centralItemPriceGross
                  : row.original.centralItemPriceNet,
                user,
                row.original.itemType,
              )}
              isRightAligned={true}
              disabled={
                !userHasOneOfPermissions(
                  user,
                  editCreateDeviationBulkEditPermissions.deviatePrices,
                ) ||
                rowHasOverwritePrice(row) ||
                rowIsDiscount(row, discountCategoryId) ||
                rowHasZeroPricePrice(row)
              }
              warning={valueHasChanged}
            />
          );
        }
      }
    },
  },
  groupedPriceCodeSpecificColumn(
    t,
    scheduledBulkEditIdBySiteIdThenItemId,
    user,
    discountCategoryId,
  ),
  groupedCentralItemDispenseFeeColumn(t, user, countries),
  {
    ...deviatedDispenseFeeColumn(t, user, countries),
    Cell: ({ cell, row, formikProps }: CellProps<GroupedDeviationResponseRow>) => {
      if (row.canExpand) {
        const { fieldValue, updateSubRowFields } = getDeviationHeaderFieldProps(
          row,
          formikProps,
          user,
          'deviatedDispenseFee',
          onDeviatedDispenseFeeChange,
          deviation =>
            user.useGrossPrices
              ? deviation.centralItemDispenseFeeGross
              : deviation.centralItemDispenseFeeNet,
        );
        return (
          <ControlledInput initialValue={fieldValue}>
            {({ value, onChange }) => (
              <CurrencyInput
                value={value}
                onChange={e => {
                  if (formikProps) {
                    formikProps.setFieldTouched(
                      `deviations.${row.original.siteId}.${row.original.itemId}.percentageDispenseFeeDeviation`,
                      false,
                    );
                  }
                  onChange(e);
                }}
                bulkVariant={true}
                onBlurIfChange={updateSubRowFields}
                isRightAligned={true}
                disabled={
                  !userHasOneOfPermissions(
                    user,
                    editCreateDeviationBulkEditPermissions.deviatePrices,
                  ) ||
                  practiceGroupHasSubRowWithOverwritePrice(row) ||
                  practiceGroupHasSubRowWithZeroDispenseFee(row) ||
                  practiceGroupHasServiceTypeSubRow(row) ||
                  practiceGroupHasSubRowWithDiscount(row, discountCategoryId)
                }
                warning={deviationHeaderValueHasChanged(
                  formikProps,
                  row,
                  'deviatedDispenseFee',
                  value,
                )}
                currency={getCurrencyForCountry(
                  row.subRows[0].original.siteCountry,
                  user,
                  countries,
                )}
              />
            )}
          </ControlledInput>
        );
      } else {
        if (
          scheduledBulkEditIdBySiteIdThenItemId &&
          scheduledBulkEditIdBySiteIdThenItemId.get(row.original.siteId, row.original.itemId)
        ) {
          return displayCalculatedValueOrSpan(
            cell.value,
            row.original.dispenseFeeDeviationIsSetByPercent,
            true,
            row.original.deviationId != null || row.original.category1Id == discountCategoryId,
          );
        } else {
          const valueHasChanged = deviationBulkEditValueHasChanged(
            formikProps,
            row,
            'deviatedDispenseFee',
          );

          return (
            <DeviatedCurrencyField
              highlightAsItemSpecificDeviation={
                isItemSpecificDispenseFeeDeviation(row.original) && !valueHasChanged
              }
              bulkVariant={true}
              fastVariant={true}
              hideLabel={true}
              name={`${getDeviationFieldPrefix(row)}.deviatedDispenseFee`}
              label={t('columnHeaders.deviatedDispenseFee')}
              useDebouncing={true}
              onChange={onDeviatedDispenseFeeChange(
                row.original.siteId,
                row.original.itemId,
                user.useGrossPrices
                  ? row.original.centralItemDispenseFeeGross
                  : row.original.centralItemDispenseFeeNet,
                user,
              )}
              isRightAligned={true}
              disabled={
                !userHasOneOfPermissions(
                  user,
                  editCreateDeviationBulkEditPermissions.deviatePrices,
                ) ||
                rowHasOverwritePrice(row) ||
                rowItemIsServiceType(row) ||
                rowHasZeroPriceDispenseFee(row) ||
                rowIsDiscount(row, discountCategoryId)
              }
              warning={valueHasChanged}
              currency={getCurrencyForCountry(row.original.siteCountry, user, countries)}
            />
          );
        }
      }
    },
  },
  {
    ...dispenseFeeDeviationPercentValueColumn(t, user),
    Cell: ({ cell, row, formikProps }: CellProps<GroupedDeviationResponseRow>) => {
      if (row.canExpand) {
        const { fieldValue, updateSubRowFields } = getDeviationHeaderFieldProps(
          row,
          formikProps,
          user,
          'percentageDispenseFeeDeviation',
          onPercentageDispenseFeeDeviationChange,
          deviation =>
            user.useGrossPrices
              ? deviation.centralItemDispenseFeeGross
              : deviation.centralItemDispenseFeeNet,
        );
        return (
          <ControlledInput initialValue={fieldValue}>
            {({ value, onChange }) => (
              <PercentInput
                data-testid={getGroupedDeviationRowDispenseFeePercentageTestId(
                  row.original.practiceGroupId,
                  row.original.itemId,
                )}
                value={value}
                onBlur={() => {}}
                onChange={e => {
                  if (formikProps) {
                    formikProps.setFieldTouched(
                      `deviations.${row.original.siteId}.${row.original.itemId}.percentageDispenseFeeDeviation`,
                      true,
                    );
                  }
                  onChange(e);
                }}
                bulkVariant={true}
                onBlurIfChange={updateSubRowFields}
                isRightAligned={true}
                disabled={
                  !userHasOneOfPermissions(
                    user,
                    editCreateDeviationBulkEditPermissions.deviatePrices,
                  ) ||
                  practiceGroupHasSubRowWithOverwritePrice(row) ||
                  practiceGroupHasServiceTypeSubRow(row) ||
                  practiceGroupHasSubRowWithZeroDispenseFee(row) ||
                  practiceGroupHasSubRowWithDiscount(row, discountCategoryId)
                }
                warning={deviationHeaderValueHasChanged(
                  formikProps,
                  row,
                  'percentageDispenseFeeDeviation',
                  value,
                )}
              />
            )}
          </ControlledInput>
        );
      } else {
        if (
          scheduledBulkEditIdBySiteIdThenItemId &&
          scheduledBulkEditIdBySiteIdThenItemId.get(row.original.siteId, row.original.itemId)
        ) {
          return cell.value === null ? (
            <span />
          ) : (
            displayCalculatedValueOrSpan(
              round(cell.value, MAX_DECIMAL_PLACES) + '%',
              !row.original.dispenseFeeDeviationIsSetByPercent,
              true,
              row.original.deviationId != null || row.original.category1Id == discountCategoryId,
            )
          );
        } else {
          const valueHasChanged = deviationBulkEditValueHasChanged(
            formikProps,
            row,
            'percentageDispenseFeeDeviation',
          );

          return (
            <DeviatedPercentField
              highlightAsItemSpecificDeviation={
                isItemSpecificDispenseFeeDeviation(row.original) && !valueHasChanged
              }
              bulkVariant={true}
              fastVariant={true}
              hideLabel={true}
              name={`${getDeviationFieldPrefix(row)}.percentageDispenseFeeDeviation`}
              testId={`${getDeviationFieldPrefix(row)}.percentageDispenseFeeDeviation`}
              label={t('columnHeaders.dispenseFeeDeviationPercentValue')}
              useDebouncing={true}
              onChange={onPercentageDispenseFeeDeviationChange(
                row.original.siteId,
                row.original.itemId,
                user.useGrossPrices
                  ? row.original.centralItemDispenseFeeGross
                  : row.original.centralItemDispenseFeeNet,
                user,
              )}
              isRightAligned={true}
              disabled={
                !userHasOneOfPermissions(
                  user,
                  editCreateDeviationBulkEditPermissions.deviatePrices,
                ) ||
                rowHasOverwritePrice(row) ||
                rowItemIsServiceType(row) ||
                rowHasZeroPriceDispenseFee(row) ||
                rowIsDiscount(row, discountCategoryId)
              }
              warning={valueHasChanged}
            />
          );
        }
      }
    },
  },
  groupedDispenseFeeCodeSpecificColumn(
    t,
    scheduledBulkEditIdBySiteIdThenItemId,
    user,
    discountCategoryId,
  ),
  groupedActionsColumn(t),
  groupedWarningsColumn(t),
];

export const getDeviationGroupedRowSetToDefaultButtonTestId = (
  practiceGroupId: Id | undefined,
  itemId: Id | undefined,
) => `grouped-row-${practiceGroupId}-${itemId}-set-to-default-button`;

const getDeviationPriceLocal = (
  codeSpecific: Boolean,
  formikProps: FormikProps<EditDeviationBulkEditFormModel>,
  useGrossPrices: Boolean,
  deviation: DeviationResponse,
) => {
  let deviatedPrice: number;
  if (codeSpecific) {
    const currentDeviationPrice = Number.parseFloat(
      formikProps.values.deviations[deviation.siteId][deviation.itemId].deviatedPrice,
    );
    const originalDeviationPrice = useGrossPrices
      ? deviation.localItemPriceGross
      : deviation.localItemPriceNet;
    if (currentDeviationPrice !== originalDeviationPrice) {
      deviatedPrice = currentDeviationPrice;
    } else {
      deviatedPrice = originalDeviationPrice;
    }
  } else {
    deviatedPrice = useGrossPrices ? deviation.defaultPriceGross : deviation.defaultPriceNet;
  }
  return deviatedPrice;
};

const getDeviationPricePercentage = (
  codeSpecific: Boolean,
  formikProps: FormikProps<EditDeviationBulkEditFormModel>,
  deviation: DeviationResponse,
) => {
  let deviatedPercentage: number;
  if (codeSpecific) {
    const percentage =
      formikProps.values.deviations[deviation.siteId][deviation.itemId].percentagePriceDeviation;
    const currentDeviationPercentage = percentage == null ? 0 : Number.parseFloat(percentage);
    const originalDeviationPercentage = deviation.priceDeviationPercentValue;
    if (currentDeviationPercentage !== originalDeviationPercentage) {
      deviatedPercentage = currentDeviationPercentage;
    } else {
      deviatedPercentage = originalDeviationPercentage;
    }
  } else {
    deviatedPercentage = deviation.defaultPriceDeviationPercentValue;
  }
  return deviatedPercentage;
};

const getAbsolutePriceDeviation = (
  codeSpecific: Boolean,
  formikProps: FormikProps<EditDeviationBulkEditFormModel>,
  useGrossPrices: Boolean,
  deviation: DeviationResponse,
) => {
  let deviatedAbsolutePrice: number;
  if (codeSpecific) {
    const deviatedPrice = Number.parseFloat(
      formikProps.values.deviations[deviation.siteId][deviation.itemId].deviatedPrice,
    );
    const centralPrice = useGrossPrices
      ? deviation.centralItemPriceGross
      : deviation.centralItemPriceNet;

    const currentDeviationPrice = deviatedPrice - centralPrice;
    const originalDeviationPrice = useGrossPrices
      ? deviation.priceDeviationAbsoluteValueGross
      : deviation.priceDeviationAbsoluteValueNet;
    if (currentDeviationPrice !== originalDeviationPrice) {
      deviatedAbsolutePrice = currentDeviationPrice;
    } else {
      deviatedAbsolutePrice = originalDeviationPrice;
    }
  } else {
    deviatedAbsolutePrice = useGrossPrices
      ? deviation.defaultPriceDeviationAbsoluteValueGross
      : deviation.defaultPriceDeviationAbsoluteValueNet;
  }
  return deviatedAbsolutePrice;
};
const hasPercentageDispenseFeeDeviationChanged = (
  formikProps: FormikProps<EditDeviationBulkEditFormModel>,
  deviation: DeviationResponse,
) => {
  const x =
    formikProps.values.deviations[deviation.siteId][deviation.itemId]
      .percentageDispenseFeeDeviation;
  const valueEntered = x ? Number.parseFloat(x).toFixed(MAX_DECIMAL_PLACES) : '0';

  const originalDeviation = deviation.dispenseFeeDeviationPercentValue
    ? deviation.dispenseFeeDeviationPercentValue.toFixed(MAX_DECIMAL_PLACES)
    : 0;
  if (valueEntered == '0') {
    return false;
  } else {
    return valueEntered != originalDeviation;
  }
};
const hasPercentagePriceDeviationChanged = (
  formikProps: FormikProps<EditDeviationBulkEditFormModel>,
  deviation: DeviationResponse,
) => {
  const x =
    formikProps.values.deviations[deviation.siteId][deviation.itemId].percentagePriceDeviation;
  const valueEntered = x ? Number.parseFloat(x).toFixed(MAX_DECIMAL_PLACES) : '0';

  const originalDeviation = deviation.priceDeviationPercentValue
    ? deviation.priceDeviationPercentValue.toFixed(MAX_DECIMAL_PLACES)
    : 0;
  if (valueEntered == '0') {
    return false;
  } else {
    return valueEntered != originalDeviation;
  }
};
export const setRowPriceFields = (
  formikProps: FormikProps<EditDeviationBulkEditFormModel>,
  deviation: DeviationResponse,
  user: LoginUserResponse,
  codeSpecific: Boolean,
) => {
  const fieldByFieldName = getDeviationFieldByFieldName(deviation.siteId, deviation.itemId);
  let deviatedPrice = formatNumberForFields(
    getDeviationPriceLocal(codeSpecific, formikProps, user.useGrossPrices, deviation),
    user.locale,
  );
  let deviatedPercentage: string | null;
  let deviatedAbsolutedValue: string | null;

  if (codeSpecific) {
    if (hasPercentagePriceDeviationChanged(formikProps, deviation)) {
      const percentageValue = getDeviationPricePercentage(codeSpecific, formikProps, deviation);
      deviatedPercentage = formatNumberForFields(percentageValue, user.locale);
      deviatedAbsolutedValue = null;
      deviatedPrice = formatNumberForFields(
        getDeviatedValue(
          user.useGrossPrices ? deviation.centralItemPriceGross : deviation.centralItemPriceNet,
          parseNumber(percentageValue, user.locale),
          null,
          true,
        ),
        user.locale,
      );
    } else {
      deviatedAbsolutedValue = formatNumberForFields(
        getAbsolutePriceDeviation(codeSpecific, formikProps, user.useGrossPrices, deviation),
        user.locale,
      );
      deviatedPercentage = null;
    }
  } else {
    // treatment type only ever uses percentage calculations
    deviatedAbsolutedValue = null;
    deviatedPercentage = formatNumberForFields(
      getDeviationPricePercentage(codeSpecific, formikProps, deviation),
      user.locale,
    );
  }

  formikProps.setFieldValue(fieldByFieldName('deviatedPrice'), deviatedPrice);
  formikProps.setFieldValue(fieldByFieldName('percentagePriceDeviation'), deviatedPercentage);
  formikProps.setFieldValue(fieldByFieldName('absolutePriceDeviation'), deviatedAbsolutedValue);
};

const getDeviationDispenseFeeLocal = (
  codeSpecific: Boolean,
  formikProps: FormikProps<EditDeviationBulkEditFormModel>,
  useGrossPrices: Boolean,
  deviation: DeviationResponse,
) => {
  let deviatedValue: number;
  if (codeSpecific) {
    const currentDeviatedValue = Number.parseFloat(
      formikProps.values.deviations[deviation.siteId][deviation.itemId].deviatedDispenseFee,
    );
    const originalDeviatedValue = useGrossPrices
      ? deviation.localItemDispenseFeeGross
      : deviation.localItemDispenseFeeNet;
    if (currentDeviatedValue !== originalDeviatedValue) {
      deviatedValue = currentDeviatedValue;
    } else {
      deviatedValue = originalDeviatedValue;
    }
  } else {
    deviatedValue = useGrossPrices
      ? deviation.defaultDispenseFeeGross
      : deviation.defaultDispenseFeeNet;
  }
  return deviatedValue;
};

const getDeviationDispenseFeePercentage = (
  codeSpecific: Boolean,
  formikProps: FormikProps<EditDeviationBulkEditFormModel>,
  deviation: DeviationResponse,
) => {
  let deviatedValue: number;
  if (codeSpecific) {
    const value =
      formikProps.values.deviations[deviation.siteId][deviation.itemId]
        .percentageDispenseFeeDeviation;
    const currentDeviatedValue = value == null ? 0 : Number.parseFloat(value);
    const originalDeviatedValue = deviation.dispenseFeeDeviationPercentValue;
    if (currentDeviatedValue !== originalDeviatedValue) {
      deviatedValue = currentDeviatedValue;
    } else {
      deviatedValue = originalDeviatedValue;
    }
  } else {
    deviatedValue = deviation.defaultDispenseFeeDeviationPercentValue;
  }
  return deviatedValue;
};
const getAbsoluteDispenseFeeDeviation = (
  codeSpecific: Boolean,
  formikProps: FormikProps<EditDeviationBulkEditFormModel>,
  useGrossPrices: Boolean,
  deviation: DeviationResponse,
) => {
  let deviatedValue: number;
  if (codeSpecific) {
    const deviatedDispenseFee = Number.parseFloat(
      formikProps.values.deviations[deviation.siteId][deviation.itemId].deviatedDispenseFee,
    );
    const centralDispenseFee = useGrossPrices
      ? deviation.centralItemDispenseFeeGross
      : deviation.centralItemDispenseFeeNet;
    const currentDeviatedValue = deviatedDispenseFee - centralDispenseFee;
    const originalDeviatedValue = useGrossPrices
      ? deviation.dispenseFeeDeviationAbsoluteValueGross
      : deviation.dispenseFeeDeviationAbsoluteValueNet;
    if (currentDeviatedValue !== originalDeviatedValue) {
      deviatedValue = currentDeviatedValue;
    } else {
      deviatedValue = originalDeviatedValue;
    }
  } else {
    deviatedValue = useGrossPrices
      ? deviation.defaultDispenseFeeDeviationAbsoluteValueGross
      : deviation.defaultDispenseFeeDeviationAbsoluteValueNet;
  }
  return deviatedValue;
};

export const setRowDispenseFeeFields = (
  formikProps: FormikProps<EditDeviationBulkEditFormModel>,
  deviation: DeviationResponse,
  user: LoginUserResponse,
  codeSpecific: Boolean,
) => {
  const fieldByFieldName = getDeviationFieldByFieldName(deviation.siteId, deviation.itemId);
  let deviatedDispenseFee = formatNumberForFields(
    getDeviationDispenseFeeLocal(codeSpecific, formikProps, user.useGrossPrices, deviation),
    user.locale,
  );
  let percentageDispenseFeeDeviation: string | null;
  let deviatedAbsolutedValue: string | null;

  if (codeSpecific) {
    if (hasPercentageDispenseFeeDeviationChanged(formikProps, deviation)) {
      const percentageValue = getDeviationDispenseFeePercentage(
        codeSpecific,
        formikProps,
        deviation,
      );
      percentageDispenseFeeDeviation = formatNumberForFields(percentageValue, user.locale);
      deviatedAbsolutedValue = null;
      deviatedDispenseFee = formatNumberForFields(
        getDeviatedValue(
          user.useGrossPrices
            ? deviation.centralItemDispenseFeeGross
            : deviation.centralItemDispenseFeeNet,
          parseNumber(percentageValue, user.locale),
          null,
          true,
        ),
        user.locale,
      );
    } else {
      deviatedAbsolutedValue = formatNumberForFields(
        getAbsoluteDispenseFeeDeviation(codeSpecific, formikProps, user.useGrossPrices, deviation),
        user.locale,
      );
      percentageDispenseFeeDeviation = null;
    }
  } else {
    // treatment type only ever uses percentage calculations
    deviatedAbsolutedValue = null;
    percentageDispenseFeeDeviation = formatNumberForFields(
      getDeviationDispenseFeePercentage(codeSpecific, formikProps, deviation),
      user.locale,
    );
  }
  formikProps.setFieldValue(fieldByFieldName('deviatedDispenseFee'), deviatedDispenseFee);
  formikProps.setFieldValue(
    fieldByFieldName('percentageDispenseFeeDeviation'),
    percentageDispenseFeeDeviation,
  );
  formikProps.setFieldValue(
    fieldByFieldName('absoluteDispenseFeeDeviation'),
    deviatedAbsolutedValue,
  );
};
