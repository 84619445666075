import { Form, Formik, FormikActions } from 'formik';
import { isEmpty, map, round } from 'lodash';
import * as React from 'react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, PrimaryButton } from '../../shared/buttons/Button';
import { HollowLinkButton } from '../../shared/buttons/LinkButton';
import { ApiErrorBox } from '../../shared/errors/ApiErrorBox';
import { FormValidationErrorBox } from '../../shared/errors/FormValidationErrorBox';
import { FormFieldGroupsWrapper } from '../../shared/forms/FormContainers';
import { useApiRequest } from '../../shared/hooks/useApiRequest';
import { SuccessBox } from '../../shared/success/SuccessBox';
import { itemsUrl } from '../../urls';
import { assertIsDefined } from '../../utils/assertIsDefined';
import { parseNumber } from '../../utils/numberUtils';
import { navigate } from '../../utils/routing';
import { LoginUserResponse } from '../authentication/loginData/user';
import { UserContext } from '../authentication/loginData/userContext';
import { BarcodesEdit } from './BarcodesEdit';
import {
  CountrySpecificItemDetailsFormModel,
  CountrySpecificItemDetailsResponse,
  CreateOrEditItemCommandWrapper,
  CreateOrEditItemCountrySpecificItemDetails,
  CreateOrEditItemFormModel,
  CreateOrEditItemFormModelValidator,
  ItemResponse,
} from './item';
import { ItemInformationGroup } from './ItemInformationGroup';
import { createOrEditItem } from './itemsApi';
import { getSupplierProductForCountry } from './ItemSupplierProducts/SupplierAndSupplierProductFieldsLoader';
import { viewItemUrl } from './itemUrls';
import { ItemFieldsFromPmsResponse, itemPriceField } from './PmsFields/pmsFields';
import { PricingDetailsGroup } from './PricingDetailsGroup';
import { AdditionalTreatmentDetailsGroup, TreatmentDetailsGroup } from './TreatmentDetailsGroup';
import { CountryDetailsGroup } from './CountryDetailsGroup';
import { MetadataContext } from '../authentication/loginData/metadataContext';
import {
  NewOrExistingSupplierProductFormModel,
  SupplierProductCommand,
} from './ItemSupplierProducts/supplierProduct';
import { CountryCode } from '../authentication/loginData/metadata';

type OwnProps = {
  itemFieldsFromPms: ItemFieldsFromPmsResponse;
  existingItem?: ItemResponse;
  showSuccess?: boolean;
  onSuccess?: (response: ItemResponse) => void;
};
type Props = OwnProps;

export const ItemForm = (props: Props) => {
  const [isServiceType, setIsServiceType] = useState(false);
  React.useEffect(() => {
    if (props.existingItem?.type === 'Service') {
      setIsServiceType(true);
    }
  }, [props.existingItem]);
  const { user } = useContext(UserContext);
  const { countries } = useContext(MetadataContext);
  const { t } = useTranslation(['item', 'metadata', 'libraries']);
  const { makeRequest, inProgress, apiError } = useApiRequest(createOrEditItem);

  const userCountries = countries.filter(country => user.countries.includes(country.code));

  const onSubmit = (
    values: CreateOrEditItemFormModel,
    formikActions: FormikActions<CreateOrEditItemFormModel>,
  ) => {
    if (values) {
      makeRequest(
        mapValuesToRequestParameters(props, values, user, props.itemFieldsFromPms, isServiceType),
      )
        .then(response => {
          if (response) {
            if (props.onSuccess) {
              props.onSuccess(response);
            }
            navigate(viewItemUrl(response.itemId), {
              state: { success: true },
            });
          }
        })
        .finally(() => formikActions.setSubmitting(false));
    } else {
      formikActions.setSubmitting(false);
    }
  };

  return (
    <Formik<CreateOrEditItemFormModel>
      enableReinitialize={true}
      initialValues={mapPropsToValues(props, user)}
      onSubmit={onSubmit}
      validate={values => {
        const priceField =
          values.type != null
            ? itemPriceField(values.type, values.category1Id, props.itemFieldsFromPms)
            : null;
        const validator = new CreateOrEditItemFormModelValidator(
          t,
          user.locale,
          props.itemFieldsFromPms.organisationPmsUsage,
          values.type != null ? props.itemFieldsFromPms.typeOptions[values.type] : null,
          priceField,
          isServiceType,
        );
        return validator.validate(values);
      }}
    >
      {formikProps => {
        const itemType =
          formikProps.values.type != null
            ? props.itemFieldsFromPms.typeOptions[formikProps.values.type]
            : null;

        const priceField =
          itemType != null
            ? itemPriceField(itemType.code, formikProps.values.category1Id, props.itemFieldsFromPms)
            : null;

        return (
          <Form>
            <FormFieldGroupsWrapper minWidthInPx={500}>
              <ItemInformationGroup
                itemFieldsFromPms={props.itemFieldsFromPms}
                existingItem={props.existingItem}
                formikProps={formikProps}
                setIsServiceType={setIsServiceType}
              />
              <PricingDetailsGroup
                itemFieldsFromPms={props.itemFieldsFromPms}
                existingItem={props.existingItem}
                formikProps={formikProps}
                isServiceType={isServiceType}
                priceField={priceField}
                userCountries={userCountries}
              />
              {userCountries.map((country, index) => (
                <CountryDetailsGroup
                  key={index}
                  country={country}
                  itemFieldsFromPms={props.itemFieldsFromPms}
                  existingItem={props.existingItem}
                  formikProps={formikProps}
                  priceField={priceField}
                  isServiceType={isServiceType}
                  index={index}
                />
              ))}
              <TreatmentDetailsGroup
                itemFieldsFromPms={props.itemFieldsFromPms}
                existingItem={props.existingItem}
                formikProps={formikProps}
                isServiceType={isServiceType}
              />
              <AdditionalTreatmentDetailsGroup
                existingItem={props.existingItem}
                isServiceType={isServiceType}
                formikProps={formikProps}
                locale={user.locale}
              />
            </FormFieldGroupsWrapper>
            <BarcodesEdit barcodes={formikProps.values.barcode ?? undefined} />
            <ButtonGroup>
              <PrimaryButton
                type="submit"
                loading={formikProps.isSubmitting}
                disabled={formikProps.isSubmitting || inProgress}
              >
                {t('createAndEditForm.labels.submitButton')}
              </PrimaryButton>
              <HollowLinkButton
                to={props.existingItem ? viewItemUrl(props.existingItem.itemId) : itemsUrl()}
              >
                {t('createAndEditForm.labels.cancelButton')}
              </HollowLinkButton>
            </ButtonGroup>
            <FormValidationErrorBox errors={formikProps.errors} touched={formikProps.touched} />
            {apiError && <ApiErrorBox error={apiError} />}
            <SuccessBox
              message={t('createAndEditForm.success')}
              showSuccess={
                !!props.showSuccess &&
                !formikProps.isSubmitting &&
                !apiError &&
                isEmpty(formikProps.touched)
              }
            />
          </Form>
        );
      }}
    </Formik>
  );
};

const getRoundedStringValue = (value: number | null | undefined) =>
  value == null ? '' : String(round(value, 4));

const mapPropsToValues = (props: OwnProps, user: LoginUserResponse): CreateOrEditItemFormModel => ({
  noteDetails: props.existingItem ? props.existingItem.noteDetails : null,
  discountCategoryEnabled: props.existingItem ? props.existingItem.discountCategoryEnabled : false,
  discountCategory: props.existingItem ? props.existingItem.discountCategory : null,
  sendTo: props.existingItem ? props.existingItem.sendTo : null,
  preventHide: props.existingItem ? props.existingItem.preventHide : false,
  wormer: props.existingItem ? props.existingItem.wormer : false,
  flea: props.existingItem ? props.existingItem.flea : false,
  repeatPrescription: props.existingItem ? props.existingItem.repeatPrescription : false,
  labelAdditionalInfo: props.existingItem ? props.existingItem.labelAdditionalInfo : '',
  itemName: props.existingItem ? props.existingItem.itemName : '',
  itemCode: props.existingItem ? props.existingItem.itemCode : '',
  type: props.existingItem ? props.existingItem.type : null,
  speciesId: props.existingItem ? props.existingItem.speciesId : null,
  category1Id: props.existingItem ? props.existingItem.category1Id : null,
  category2Id: props.existingItem ? props.existingItem.category2Id : null,
  unitsPerPack:
    props.existingItem && props.existingItem.unitsPerPack != null
      ? String(props.existingItem.unitsPerPack)
      : '',

  // If the user has access to UK and Ireland, and has gross prices selected, the tax rate used to convert back to net will be the UK rate
  dispenseFee: getRoundedStringValue(
    user.useGrossPrices
      ? props.existingItem?.countrySpecificItemDetailsResponseList?.[0].dispenseFeeGross
      : props.existingItem?.dispenseFeeNet,
  ),
  minimumPrice: getRoundedStringValue(
    user.useGrossPrices
      ? props.existingItem?.countrySpecificItemDetailsResponseList?.[0].minimumPriceGross
      : props.existingItem?.minimumPriceNet,
  ),
  requestLabel: props.existingItem ? props.existingItem.requestLabel : false,
  labelCalcQuantity: props.existingItem ? props.existingItem.labelCalcQuantity : false,
  barcode: props.existingItem ? props.existingItem.barcode : null,

  shouldAlterSex: props.existingItem ? props.existingItem.shouldAlterSex : false,
  internalInformation: props.existingItem ? props.existingItem.internalInformation : null,
  notes: props.existingItem ? props.existingItem.notes : null,

  wholepackPriceEnabled: props.existingItem ? props.existingItem.wholepackPriceEnabled : false,
  wholepackExcludeDispFee: props.existingItem ? props.existingItem.wholepackExcludeDispFee : false,
  isMultiDispensingFee: props.existingItem ? props.existingItem.isMultiDispensingFee : false,

  recommendedPrice: getRoundedStringValue(
    user.useGrossPrices
      ? props.existingItem?.countrySpecificItemDetailsResponseList?.[0].centralPriceGross
      : props.existingItem?.recommendedPriceNet,
  ),
  centralMarkupPercentage:
    props.existingItem && props.existingItem.centralMarkupPercentage != null
      ? String(props.existingItem.centralMarkupPercentage)
      : '',
  percentageDiscount:
    props.existingItem && props.existingItem.percentageDiscount != null
      ? String(props.existingItem.percentageDiscount)
      : '',
  absoluteDiscount: getRoundedStringValue(
    user.useGrossPrices
      ? props.existingItem?.countrySpecificItemDetailsResponseList?.[0].absoluteDiscountGross
      : props.existingItem?.absoluteDiscountNet,
  ),
  netNetDiscount:
    props.existingItem != null ? getRoundedStringValue(props.existingItem.netNetDiscount) : '',
  netNetNetDiscount:
    props.existingItem != null ? getRoundedStringValue(props.existingItem.netNetNetDiscount) : '',
  isBatchRequest: props.existingItem ? props.existingItem.isBatchRequest : false,
  isDisabled: props.existingItem ? props.existingItem.isDisabled : false,

  category3Id: props.existingItem ? props.existingItem.category3Id : null,
  clientCategoryId: props.existingItem ? props.existingItem.clientCategoryId : null,
  shouldPromptChipDetails: props.existingItem ? props.existingItem.shouldPromptChipDetails : false,
  shouldRequestQuantity: props.existingItem ? props.existingItem.shouldRequestQuantity : false,
  isHiddenForAllPractices: props.existingItem ? props.existingItem.isHiddenForAllPractices : false,
  shouldSellAtCost: props.existingItem ? props.existingItem.shouldSellAtCost : false,
  hcpGroupId: props.existingItem ? props.existingItem.hcpGroupId : null,
  hcpMonthlyMultiplier:
    props.existingItem && props.existingItem.hcpMonthlyMultiplier != null
      ? String(props.existingItem.hcpMonthlyMultiplier)
      : '',
  manufacturerId: props.existingItem ? props.existingItem.manufacturerId : null,
  costTypeId: props.existingItem ? props.existingItem.costTypeId ?? 1 : 1,
  documentTemplateId: props.existingItem ? props.existingItem.documentTemplateId : null,
  labelDispensingNote: props.existingItem ? props.existingItem.labelDispensingNote : null,

  reminderEnabled: props.existingItem ? props.existingItem.reminderEnabled : null,
  reminderTypeId: props.existingItem ? props.existingItem.reminderTypeId : null,
  reminderLength: props.existingItem ? props.existingItem.reminderLength : null,
  reminderDelta: props.existingItem ? props.existingItem.reminderDelta : null,

  countrySpecificItemDetailsList: props.existingItem?.countrySpecificItemDetailsResponseList
    ? mapCountrySpecificPropsToValues(
        props.existingItem.countrySpecificItemDetailsResponseList,
        user.countries,
        user,
      )
    : emptyCountrySpecificDetails(user.countries),
});

const mapCountrySpecificPropsToValues = (
  countrySpecificItemDetails: Array<CountrySpecificItemDetailsResponse>,
  userCountries: Array<CountryCode>,
  user: LoginUserResponse,
): Array<CountrySpecificItemDetailsFormModel> => {
  return map(userCountries, countryCode => {
    const supplierProductDetailsForCountry = getSupplierProductForCountry(
      countrySpecificItemDetails,
      countryCode,
    );
    const countrySpecificDetails =
      countrySpecificItemDetails.find(c => c.country.code === countryCode) ?? null;
    return {
      countryCode,
      supplierProductDetails: {
        supplierId: supplierProductDetailsForCountry?.supplierId ?? null,
        manuallyEnterProduct: false,
        supplierProductId: supplierProductDetailsForCountry?.supplierProductId ?? null,
        supplierCode: null,
        listPrice: (
          (user.useGrossPrices
            ? supplierProductDetailsForCountry?.listPriceGross
            : supplierProductDetailsForCountry?.listPrice) ?? ''
        ).toString(),
      },
      legalCategoryId: countrySpecificDetails?.legalCategoryId || null,
      taxRateId: countrySpecificDetails?.taxRateId || null,
      isEnabledForCountry: countrySpecificDetails?.isEnabledForCountry || false,
      overwrite: countrySpecificDetails?.overwrite || false,
      overwriteAmount: getRoundedStringValue(countrySpecificDetails?.overwriteAmount || null),
    };
  });
};

export const emptyCountrySpecificDetails = (
  userCountries: Array<CountryCode>,
): Array<CountrySpecificItemDetailsFormModel> => {
  return map(userCountries, countryCode => {
    return {
      countryCode,
      isEnabledForCountry: true,
      legalCategoryId: null,
      taxRateId: null,
      overwrite: false,
      overwriteAmount: null,
      supplierProductDetails: {
        supplierId: null,
        manuallyEnterProduct: false,
        supplierProductId: null,
        supplierCode: null,
        listPrice: null,
      },
    };
  });
};

const mapValuesToRequestParameters = (
  props: OwnProps,
  formModel: CreateOrEditItemFormModel,
  user: LoginUserResponse,
  pmsFields: ItemFieldsFromPmsResponse,
  isServiceType: boolean,
): CreateOrEditItemCommandWrapper => {
  const itemType = pmsFields.typeOptions[assertIsDefined(formModel.type)];
  const priceField = itemPriceField(itemType.code, formModel.category1Id, pmsFields);

  const { ...commandValuesFromFormModel } = formModel;

  return {
    command: {
      ...commandValuesFromFormModel,
      noteDetails: formModel.noteDetails ? formModel.noteDetails : [],
      discountCategoryEnabled: formModel.discountCategoryEnabled
        ? formModel.discountCategoryEnabled
        : false,
      discountCategory: formModel.discountCategory ? formModel.discountCategory : [],
      sendTo: formModel.sendTo ? formModel.sendTo : [],
      costTypeId: formModel.costTypeId ? formModel.costTypeId : 1,
      labelDispensingNote: formModel.labelDispensingNote ? formModel.labelDispensingNote : [],
      flea: formModel.flea ? formModel.flea : false,
      wormer: formModel.wormer ? formModel.wormer : false,
      preventHide: formModel.preventHide ? formModel.preventHide : false,
      repeatPrescription: formModel.repeatPrescription ? formModel.repeatPrescription : false,
      labelAdditionalInfo: formModel.labelAdditionalInfo ? formModel.labelAdditionalInfo : '.',
      speciesId: formModel.speciesId === 0 ? null : formModel.speciesId,
      type: assertIsDefined(formModel.type),
      itemId: props.existingItem ? props.existingItem.itemId : undefined,
      category2Id: assertIsDefined(formModel.category2Id),
      minimumPrice:
        formModel.minimumPrice !== null && formModel.minimumPrice !== ''
          ? parseNumber(formModel.minimumPrice, user.locale)
          : null,
      recommendedPrice:
        priceField === 'RecommendedPrice'
          ? parseNumber(formModel.recommendedPrice, user.locale)
          : null,
      centralMarkupPercentage:
        priceField === 'CentralMarkupPercentage'
          ? parseNumber(formModel.centralMarkupPercentage, user.locale)
          : null,
      percentageDiscount:
        priceField === 'Discount' ? parseNumber(formModel.percentageDiscount, user.locale) : null,
      absoluteDiscount:
        priceField === 'Discount' ? parseNumber(formModel.absoluteDiscount, user.locale) : null,
      unitsPerPack:
        formModel.unitsPerPack != null && formModel.unitsPerPack !== ''
          ? parseNumber(formModel.unitsPerPack, user.locale)
          : null,
      dispenseFee: formModel.dispenseFee ? parseNumber(formModel.dispenseFee, user.locale) : null,

      netNetDiscount: formModel.netNetDiscount
        ? parseNumber(formModel.netNetDiscount, user.locale)
        : null,
      netNetNetDiscount: formModel.netNetNetDiscount
        ? parseNumber(formModel.netNetNetDiscount, user.locale)
        : null,

      barcode: formModel.barcode ? formModel.barcode : null,
      internalInformation: formModel.internalInformation ? formModel.internalInformation : null,
      notes: formModel.notes ? formModel.notes : null,
      hcpMonthlyMultiplier: formModel.hcpMonthlyMultiplier
        ? parseNumber(formModel.hcpMonthlyMultiplier, user.locale)
        : null,
      countrySpecificItemDetailsList: formModel.countrySpecificItemDetailsList
        ? mapCountrySpecificValuesToRequestParameters(
            formModel.countrySpecificItemDetailsList,
            user,
            isServiceType,
          )
        : [],
    },
    createOrEditTag: props.existingItem ? 'edit' : 'create',
  };
};

const mapCountrySpecificValuesToRequestParameters = (
  countrySpecificItemDetails: Array<CountrySpecificItemDetailsFormModel>,
  user: LoginUserResponse,
  isServiceType: boolean,
): Array<CreateOrEditItemCountrySpecificItemDetails> => {
  return countrySpecificItemDetails
    .filter(csid => csid.isEnabledForCountry)
    .map((csid, index) => ({
      countryCode: csid.countryCode,
      supplierProduct: csid.supplierProductDetails
        ? mapSupplierProductValuesToRequestParameters(
            csid.supplierProductDetails,
            user,
            isServiceType,
          )
        : null,
      taxRateId: assertIsDefined(csid.taxRateId),
      legalCategoryId: csid.legalCategoryId,
      isEnabledForCountry: csid.isEnabledForCountry,
      overwrite: csid.overwrite,
      overwriteAmount: parseNumber(csid.overwriteAmount || '0', user.locale),
    }));
};

const mapSupplierProductValuesToRequestParameters = (
  supplierProductDetails: NewOrExistingSupplierProductFormModel,
  user: LoginUserResponse,
  isServiceType: boolean,
): SupplierProductCommand | null => {
  return supplierProductDetails.manuallyEnterProduct
    ? {
        supplierId: assertIsDefined(supplierProductDetails.supplierId),
        supplierCode: assertIsDefined(supplierProductDetails.supplierCode),
        listPrice: parseNumber(assertIsDefined(supplierProductDetails.listPrice), user.locale),
      }
    : isServiceType && supplierProductDetails.supplierProductId == null
    ? null
    : {
        supplierProductId: assertIsDefined(supplierProductDetails.supplierProductId),
        listPrice: parseNumber(assertIsDefined(supplierProductDetails.listPrice), user.locale),
      };
};
