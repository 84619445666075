import { LocationProvider, RouteComponentProps, Router } from '@reach/router';
import React, { Suspense } from 'react';
import { HeadProvider, Title } from 'react-head';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MantineProvider } from '@mantine/core';
import { ThemeProvider } from 'styled-components/macro';
import { QueryParamProvider } from 'use-query-params';
import { Authentication } from './feature/authentication/Authentication';
import { loggedOutUrl, silentTokenRefreshUrl } from './feature/authentication/authenticationUrls';
import { LoggedOut } from './feature/authentication/LoggedOut';
import { Login } from './feature/authentication/Login';
import { permissions } from './feature/authentication/permissions';
import { SilentTokenRefresh } from './feature/authentication/SilentTokenRefresh';
import { Dashboard } from './feature/dashboard/Dashboard';
import { DeviationsSection } from './feature/deviations/DeviationsSection';
import { FaqSection } from './feature/faq/FaqSection';
import { OrganisationsSection } from './feature/groups/OrganisationsSection';
import { ItemsSection } from './feature/items/ItemsSection';
import { Layout } from './feature/layout/Layout';
import { PageNotFound } from './feature/PageNotFound';
import { PmsRequestErrorsSection } from './feature/pmsRequestErrors/PmsRequestErrorsSection';
import { SitesSection } from './feature/sites/SitesSection';
import { SuppliersSection } from './feature/suppliers/SuppliersSection';
import { UsersSection } from './feature/users/UsersSection';
import { Notification } from './Notification/Notification';
import { ErrorBoundary } from './shared/errors/ErrorBoundary';
import { RequiresOneOfPermissions } from './shared/Permissions';
import { GlobalStyling } from './styling/GlobalStyling';
import { theme } from './styling/theme';
import { appHistory, pushReplaceHistoryForUseQueryParams } from './utils/routing';
import { TreatmentTypeDeviationsSection } from './feature/deviations/treatmentTypeDeviations/TreatmentTypeDeviationsSection';
import { SupportSection } from './feature/support/SupportSection';
import { ResetFailedItemsSection } from './feature/items/ResetFailedItemsSection';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DeleteSupplierItemsSection } from './feature/items/DeleteSupplierItemsSection';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
    },
  },
});

const AppContainer = (props: { children: React.ReactElement }) => (
  <React.StrictMode>
    <QueryParamProvider history={pushReplaceHistoryForUseQueryParams}>
      <QueryClientProvider client={queryClient}>
        <MantineProvider withGlobalStyles={true} withNormalizeCSS={true}>
          <ThemeProvider theme={theme}>
            <>
              <GlobalStyling />
              <Suspense fallback={<></>}>
                <ErrorBoundary>
                  <LocationProvider history={appHistory}>{props.children}</LocationProvider>
                </ErrorBoundary>
              </Suspense>
            </>
          </ThemeProvider>
        </MantineProvider>
      </QueryClientProvider>
    </QueryParamProvider>
  </React.StrictMode>
);

const AuthenticatedRoutes = (props: RouteComponentProps) => (
  <>
    <Authentication>
      {authentication => (
        <Login {...authentication}>
          <Layout>
            <Router primary={false}>
              <Dashboard path="/" />
              <SuppliersSection path="/suppliers/*" />
              <ItemsSection path="/items/*" />
              <DeviationsSection path="/deviations/*" />
              <TreatmentTypeDeviationsSection path="/treatment-type-deviations/*" />
              <OrganisationsSection path="/organisations/*" />
              <SitesSection path="/sites/*" />
              <UsersSection path="/users/*" />
              <DeleteSupplierItemsSection path="/delete-supplier-items/*" />
              <ResetFailedItemsSection path="/reset-failed-items/*" />
              <FaqSection path="/faq/*" />
              <PmsRequestErrorsSection path="/pms-errors/*" />
              <SupportSection path="/support/*" />
              <PageNotFound default={true} />
            </Router>
          </Layout>
          <RequiresOneOfPermissions
            permissions={[permissions.SuperAdmin, permissions.CentralAdmin]}
          >
            <Notification />
          </RequiresOneOfPermissions>
        </Login>
      )}
    </Authentication>
  </>
);

const App: React.FC = () => {
  return (
    <HeadProvider>
      <Title>Pricing Tool</Title>
      <AppContainer>
        <Router primary={true}>
          <AuthenticatedRoutes path="/*" />
          <SilentTokenRefresh path={silentTokenRefreshUrl} />
          <LoggedOut path={loggedOutUrl} />
        </Router>
      </AppContainer>
      <ToastContainer />
    </HeadProvider>
  );
};

export default App;
