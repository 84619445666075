import { omit } from 'lodash';
import { secureGet, securePost } from '../../api/apiHttpClient';
import { AuthenticatedApiRequest } from '../../models/apiRequest';
import {
  AccessibleOrganisationGroupResponse,
  CreateOrEditOrganisationGroupCommandWrapper,
  CreateOrganisationGroupCommand,
  EditOrganisationGroupCommand,
  GetAccessibleOrganisationGroupsCollectionQuery,
  IsEditOrganisationGroupCommand,
  OrganisationGroupResponse,
  OrganisationGroupsResponse,
} from './organisationGroup';

export const getOrganisationGroup: AuthenticatedApiRequest<
  OrganisationGroupResponse,
  number
> = organisationId => secureGet(`organisations/${organisationId}`);

export const getAccessibleOrganisationGroups: AuthenticatedApiRequest<
  AccessibleOrganisationGroupResponse,
  GetAccessibleOrganisationGroupsCollectionQuery
> = GetAccessibleOrganisationGroupsCollectionQuery =>
  securePost(`organisations/accessible`, GetAccessibleOrganisationGroupsCollectionQuery);

export const getOrganisationGroups: AuthenticatedApiRequest<
  OrganisationGroupsResponse,
  void
> = () => secureGet('organisations');

export const createOrganisationGroup: AuthenticatedApiRequest<
  OrganisationGroupResponse,
  CreateOrganisationGroupCommand
> = organisation => securePost(`organisations/create`, organisation);

export const editOrganisationGroup: AuthenticatedApiRequest<
  OrganisationGroupResponse,
  EditOrganisationGroupCommand
> = organisation => securePost(`organisations/edit`, organisation);

export const createOrEditOrganisationGroup: AuthenticatedApiRequest<
  OrganisationGroupResponse,
  CreateOrEditOrganisationGroupCommandWrapper
> = organisationCommand => {
  const createOrEditCommand = organisationCommand.command;
  if (IsEditOrganisationGroupCommand(createOrEditCommand, organisationCommand.createOrEditTag)) {
    return editOrganisationGroup(createOrEditCommand);
  } else {
    const createCommand = omit(createOrEditCommand, [
      'organisationId',
    ]) as CreateOrganisationGroupCommand;
    return createOrganisationGroup(createCommand);
  }
};
