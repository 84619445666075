import { every, includes, isString, some } from 'lodash';
import { Id } from '../../../models/id';
import { Permission } from '../permissions';
import { CountryCode } from './metadata';
import { OrganisationDto } from './organisationDto';

export type LoginUserResponse = {
  name: string;
  email: string;
  useGrossPrices: boolean;
  locale: string;
  currency: string;
  organisationGroupId: Id;
  organisationGroupName: string;
  permissions: Array<Permission>;
  userOrganisations: Array<OrganisationDto>;
  countries: Array<CountryCode>;
};

export const userHasPermission = (
  user: LoginUserResponse,
  permission: Permission | Array<Permission>,
) =>
  isString(permission)
    ? includes(user.permissions, permission)
    : every(permission, p => includes(user.permissions, p));

export const userHasOneOfPermissions = (user: LoginUserResponse, permissions: Array<Permission>) =>
  some(permissions, permission => includes(user.permissions, permission));
