import { find } from 'lodash';
import { CurrencyCode, Locale } from '../models/locale';
import { formatNumber } from './numberUtils';

const currencyFormatCache: {
  [cacheKey: string]: Intl.NumberFormat;
} = {};

const currencySymbolCache: {
  [cacheKey: string]: string;
} = {};

type CurrencyFormatOptions = {
  locale: Locale;
  currency: CurrencyCode;
  decimalPlaces: number;
};

// Creating Intl.NumberFormat is an expensive operation, so we want to cache and reuse instances
const getCurrencyFormat = (options: CurrencyFormatOptions): Intl.NumberFormat => {
  const cacheKey = `locale:${options.locale}-currency:${options.currency}-decimals:${options.decimalPlaces}`;
  return (
    currencyFormatCache[cacheKey] ||
    (currencyFormatCache[cacheKey] = new Intl.NumberFormat(options.locale, {
      currency: options.currency,
      style: 'currency',
      maximumFractionDigits: options.decimalPlaces,
      minimumFractionDigits: options.decimalPlaces,
    }))
  );
};

export const formatMultipleCurrencyValues = (
  values: Array<number>,
  locale: Locale,
  currency: CurrencyCode | Array<CurrencyCode>,
  decimalPlaces: number = 4,
): string => {
  if (Array.isArray(currency)) {
    return values
      .map((v, index) => formatCurrency(v, locale, currency[index], decimalPlaces))
      .join(' / ');
  } else {
    return values.map(v => formatCurrency(v, locale, currency, decimalPlaces)).join(' / ');
  }
};

export const formatCurrency = (
  value: number,
  locale: Locale,
  currency: CurrencyCode | Array<CurrencyCode>,
  decimalPlaces: number = 4,
): string => {
  if (Array.isArray(currency)) {
    switch (currency.length) {
      case 0:
        return formatNumber(value, locale, decimalPlaces);
      case 1:
        return formatValueWithSingleCurrencySymbol(value, locale, currency[0], decimalPlaces);
      default:
        return formatValueWithMultipleCurrencySymbols(value, locale, currency, decimalPlaces);
    }
  } else {
    return formatValueWithSingleCurrencySymbol(value, locale, currency, decimalPlaces);
  }
};

const formatValueWithSingleCurrencySymbol = (
  value: number,
  locale: Locale,
  currency: CurrencyCode,
  decimalPlaces: number,
): string => {
  const formatter = getCurrencyFormat({ locale, currency, decimalPlaces });
  return formatter.format(value);
};

const formatValueWithMultipleCurrencySymbols = (
  value: number,
  locale: Locale,
  currency: Array<CurrencyCode>,
  decimalPlaces: number,
) => {
  const currencySymbols = getCombinedCurrencySymbols(locale, currency);
  const formattedValue = formatNumber(value, locale, decimalPlaces);
  return `${currencySymbols} ${formattedValue}`;
};

export const formatNullableCurrency = (
  value: number | null | undefined,
  locale: Locale,
  currency: CurrencyCode,
): string => (value == null ? '' : formatCurrency(value, locale, currency));

export const formatNullableCurrencies = (
  values: Array<number | null | undefined> | undefined,
  locale: Locale,
  currencies: Array<CurrencyCode>,
): string =>
  values == null
    ? ''
    : values.length === 1
    ? formatNullableCurrency(values[0], locale, currencies[0])
    : values
        .map((v, index) => formatNullableCurrency(v, locale, currencies[index]))
        .filter(v => v !== '')
        .join(' / ');

export const getCombinedCurrencySymbols = (locale: Locale, currencies: Array<CurrencyCode>) =>
  currencies.map(currencyCode => getCurrencySymbol(locale, currencyCode)).join('/');

export const getCurrencySymbol = (locale: Locale, currency: CurrencyCode): string => {
  const cacheKey = `locale:${locale}-currency:${currency}`;
  if (currencySymbolCache[cacheKey]) {
    return currencySymbolCache[cacheKey];
  } else {
    const formatter = getCurrencyFormat({ locale, currency, decimalPlaces: 0 });
    const currencyPart = find(formatter.formatToParts(0), part => part.type === 'currency');
    const symbol = currencyPart ? currencyPart.value : '';
    currencySymbolCache[cacheKey] = symbol;
    return symbol;
  }
};
