import * as React from 'react';
import { useState } from 'react';
import { styled } from '../styling/theme';
import { Expandable } from './Expandable';

type AccordionProps = {
  children: React.ReactNode | ((childrenProps: AccordionChildrenProps) => React.ReactNode);
  initiallyOpen?: boolean;
  className?: string;
  headerComponent: React.ReactNode | ((childrenProps: AccordionChildrenProps) => React.ReactNode);
};

export type AccordionChildrenProps = {
  toggleAccordion: () => void;
  isOpen: boolean;
};

export const accordionContentTestId = 'accordion-content-test-id';

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.theme.spacing.small}px;
  width: 100%;
`;

const AccordionBar = styled.button<React.ButtonHTMLAttributes<HTMLButtonElement>>`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
`;

const AccordionContent = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  flex: 1;
  background-color: ${props => props.theme.colours.componentBackground};
  transition: all 0.35s ease-in-out;

  padding: ${props => props.theme.spacing.small}px;
  border: 1px solid ${props => props.theme.colours.border};
`;

export const Accordion = (props: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(props.initiallyOpen || false);
  const toggleAccordion = () => setIsOpen(!isOpen);

  return (
    <AccordionContainer>
      <AccordionBar onClick={toggleAccordion}>
        {typeof props.headerComponent === 'function'
          ? props.headerComponent({ toggleAccordion, isOpen })
          : props.headerComponent}
      </AccordionBar>
      <Expandable isExpanded={isOpen}>
        <AccordionContent data-testid={accordionContentTestId}>
          {typeof props.children === 'function'
            ? props.children({ toggleAccordion, isOpen })
            : props.children}
        </AccordionContent>
      </Expandable>
    </AccordionContainer>
  );
};
