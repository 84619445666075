import { RouteComponentProps } from '@reach/router';
import { keyBy, orderBy } from 'lodash';
import * as React from 'react';
import { useContext, useState } from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { ById } from '../../../models/id';
import { ButtonGroup } from '../../../shared/buttons/Button';
import { HollowLinkButton } from '../../../shared/buttons/LinkButton';
import { DataLoader, DataLoaderWithParams } from '../../../shared/DataLoader';
import { Link } from '../../../shared/navigation/Link';
import {
  HasOneOfPermissions,
  IfUserHasOneOfPermissions,
  RequiresOneOfPermissions,
} from '../../../shared/Permissions';
import { shouldShowSuccess, SuccessBox } from '../../../shared/success/SuccessBox';
import { DataFetchTable } from '../../../shared/tables/Table';
import { styled } from '../../../styling/theme';
import { PmsTypeResponse } from '../../authentication/loginData/metadata';
import { MetadataContext } from '../../authentication/loginData/metadataContext';
import { PmsUsageContext } from '../../authentication/loginData/pmsUsageContext';
import { UserContext } from '../../authentication/loginData/userContext';
import { permissions } from '../../authentication/permissions';
import { PageHeading } from '../../layout/PageHeading';
import { MerlinGroup } from '../merlinGroups/merlinGroup';
import { getPmsInstancesByCode, PmsInstanceByCode } from '../pms/pmsSite';
import { PracticeGroupResponse } from '../practiceGroups/practiceGroup';
import { getAllPracticeGroups } from '../practiceGroups/practiceGroupsApi';
import { GetSitessDownloadCommand, SiteColumnTypes, SitesResponse } from '../site';
import { getMerlinGroups, getSitesDownload } from '../../../api/sitesApi';
import { archivedSitesUrl, createSiteUrl, uploadSitesUrl } from '../siteUrls';
import {
  getSiteColumns,
  SitesFullWidthTableContainer,
  sitesPageSize,
  useSiteTableBaseState,
} from './SitesBase';
import { SitesFilterForm } from './SitesFilterForm';
import { DownloadExcelFromCsvResponseButton } from '../../../shared/buttons/DownloadExcelFromCsvResponseButton';
import { SortResponse } from '../../../shared/tables/sorting/sorting';
import { appendDateTimeToString } from '../../../utils/stringUtils';

type OwnProps = RouteComponentProps & {};
type Props = OwnProps;

type SitesTableProps = {
  pmsTypes: Array<PmsTypeResponse>;
  merlinGroups: Array<MerlinGroup>;
  pmsInstancesByCode: PmsInstanceByCode;
  practiceGroupsById: ById<PracticeGroupResponse>;
};

const StyledLinkDiv = styled.div`
  margin-top: ${props => `${props.theme.spacing.small}px`};
`;

const MarginBottomlessButtonGroup = styled(ButtonGroup)`
  margin-bottom: 0;
`;

const StyledSuccessBox = styled(SuccessBox)`
  margin-bottom: ${props => props.theme.spacing.small}px;
`;

const mapSitesResponseToTableData = (response: SitesResponse) => response.sites;

const SitesTableComponent = (props: SitesTableProps) => {
  const { t } = useTranslation(['site', 'metadata']);
  const { user } = useContext(UserContext);
  const {
    getFilteredData,
    onFilteredResponse,
    filterOptions,
    setFilterOptions,
  } = useSiteTableBaseState({
    pmsInstancesByCode: props.pmsInstancesByCode,
    practiceGroupsById: props.practiceGroupsById,
  });

  const columns = getSiteColumns(props, t);

  const [sortResponse, setSortResponse] = useState<SortResponse<SiteColumnTypes>>({});

  const getSitessDownloadCommand: GetSitessDownloadCommand = {
    filterOptions,
    ...sortResponse,
  };

  return (
    <RequiresOneOfPermissions
      permissions={[permissions.SuperAdmin, permissions.CentralView, permissions.CentralAdmin]}
    >
      <Title>{t('siteList.title')}</Title>
      <PageHeading>
        <h1>{t('siteList.heading')}</h1>
        <IfUserHasOneOfPermissions permissions={[permissions.SuperAdmin, permissions.CentralAdmin]}>
          <MarginBottomlessButtonGroup>
            <HollowLinkButton to={createSiteUrl()}>{t('siteList.createButton')}</HollowLinkButton>
            <HollowLinkButton to={uploadSitesUrl()}>{t('siteList.uploadButton')}</HollowLinkButton>
            <DownloadExcelFromCsvResponseButton
              request={getSitesDownload}
              requestParams={getSitessDownloadCommand}
              labelText={t('download.downloadButton')}
              fileName={`${user.organisationGroupName}_${appendDateTimeToString(
                t('download.excel.file'),
              )}`}
              sheetName={t('download.excel.sheet')}
            />
          </MarginBottomlessButtonGroup>
        </IfUserHasOneOfPermissions>
      </PageHeading>
      {shouldShowSuccess() && (
        <StyledSuccessBox noMinHeight={true} message={t('siteList.successfulGoLive')} />
      )}
      {!!window.history.state?.syncDeviations && (
        <StyledSuccessBox noMinHeight={true} message={t('siteList.syncDeviationsJobCreated')} />
      )}
      <SitesFilterForm
        pmsInstancesByCode={props.pmsInstancesByCode}
        practiceGroupsById={props.practiceGroupsById}
        existingFilters={filterOptions}
        onApply={setFilterOptions}
      />
      <SitesFullWidthTableContainer>
        <DataFetchTable
          columns={columns}
          getApiData={getFilteredData}
          pageSize={sitesPageSize}
          mapResponseToTableData={mapSitesResponseToTableData}
          onDataFetchSuccess={onFilteredResponse}
          emptyTableMessage={t('siteList.emptyTableMessage')}
        />
      </SitesFullWidthTableContainer>
      <HasOneOfPermissions permissions={[permissions.SuperAdmin, permissions.CentralAdmin]}>
        {hasPermission => {
          if (hasPermission) {
            return (
              <StyledLinkDiv>
                <Link to={archivedSitesUrl()}>{t('siteList.archivedSitesButton')}</Link>
              </StyledLinkDiv>
            );
          }
        }}
      </HasOneOfPermissions>
    </RequiresOneOfPermissions>
  );
};

export const Sites = (props: Props) => {
  const { t } = useTranslation('metadata');
  const { user } = useContext(UserContext);
  const pmsUsage = useContext(PmsUsageContext);
  const metadata = useContext(MetadataContext);

  return (
    <DataLoader apiRequest={getMerlinGroups}>
      {merlinGroupsRequest => (
        <DataLoaderWithParams
          apiRequest={getAllPracticeGroups}
          getParams={() => user.organisationGroupId}
        >
          {practiceGroupsRequest => {
            const merlinGroups = merlinGroupsRequest.response.merlinGroups;

            const pmsInstancesByCode = getPmsInstancesByCode(
              merlinGroups,
              metadata.pmsTypes,
              pmsUsage,
              t,
            );

            const practiceGroupsById = keyBy(
              orderBy(practiceGroupsRequest.response.practiceGroups, 'name'),
              'practiceGroupId',
            );

            return (
              <SitesTableComponent
                pmsTypes={metadata.pmsTypes}
                merlinGroups={merlinGroups}
                pmsInstancesByCode={pmsInstancesByCode}
                practiceGroupsById={practiceGroupsById}
              />
            );
          }}
        </DataLoaderWithParams>
      )}
    </DataLoader>
  );
};
