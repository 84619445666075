import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import * as React from 'react';
import { styled } from '../styling/theme';

export type IconMapping<T extends string> = Record<T, { icon: IconDefinition; messageKey: string }>;

export const getIconRowIconTestId = (prefix: string, iconName: string) => `${prefix}-${iconName}`;

type IconRowProps<T extends string> = {
  testId: string;
  ariaLabel?: string;
  iconsFromNamesMapping: IconMapping<T>;
  iconNames: Array<T>;
  t: TFunction;
};

export const IconRow = <T extends string>({
  testId,
  ariaLabel,
  iconsFromNamesMapping,
  iconNames,
  t,
}: IconRowProps<T>) => {
  return (
    <IconRowContainer data-testid={testId} aria-label={ariaLabel || 'icon row'}>
      {iconNames &&
        iconNames.map((iconName, index) => {
          const icon = iconsFromNamesMapping[iconName].icon;
          const hoverMessageKey = iconsFromNamesMapping[iconName].messageKey;
          return (
            <IconContainer
              key={index}
              data-testid={getIconRowIconTestId(testId, iconName)}
              aria-label={`${ariaLabel} - ${iconName}` || `icon ${iconName}`}
            >
              <FontAwesomeIcon icon={icon} title={t(hoverMessageKey)} />
            </IconContainer>
          );
        })}
    </IconRowContainer>
  );
};

const IconRowContainer = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  display: flex;
  flex-direction: row;
`;

const IconContainer = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  color: ${props => props.theme.colours.alertText};

  &:not(:last-of-type) {
    margin-right: ${props => props.theme.spacing.tiny}px;
  }
`;
