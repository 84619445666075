import * as React from 'react';
import { FieldArray, FormikErrors } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { PrimaryButton } from '../../shared/buttons/Button';
import { useTranslation } from 'react-i18next';
import { Notes } from './item';
import { forEach, isDate } from 'lodash';
import {
  NotesCell,
  CentreAlignCell,
  NewNoteContainer,
  ErrorDiv,
  TableBody,
  ButtonDiv,
  NoteContainer,
  MainTable,
  TableHeader,
  DateAndOperatorContainer,
  SpacerDiv,
  StandardLabel,
  LongInput,
  NotesEditContainer,
  DateDiv,
  OperatorDiv,
  StandardInput,
} from './NotesEdit.styles';

type NotesEditProps = {
  currentNotes: Array<Notes>;
  locale: string;
};

type DisplayCurrentNotesProps = {
  currentNotes: Array<Notes>;
  locale: string;
  remove: removeNotesFunction;
};
const DisplayCurrentNotesRows = (props: DisplayCurrentNotesProps) => {
  const { t } = useTranslation('item');
  return (
    <TableBody>
      {props.currentNotes.map((currentNote, index) => (
        <tr key={'currentNotes' + index}>
          <CentreAlignCell>
            {new Date(currentNote.dateOfNote)
              ? new Date(currentNote.dateOfNote).toLocaleDateString(props.locale)
              : currentNote.dateOfNote}
          </CentreAlignCell>
          <NotesCell>{currentNote.note}</NotesCell>
          <CentreAlignCell>{currentNote.initials}</CentreAlignCell>
        </tr>
      ))}
    </TableBody>
  );
};
const DisplayCurrentNote = (props: DisplayCurrentNotesProps) => {
  const { t } = useTranslation('item');
  return (
    <NoteContainer>
      <MainTable>
        <TableHeader>
          <tr>
            <th>{t('itemLabels.date')}</th>
            <th>{t('itemLabels.note')}</th>
            <th>{t('itemLabels.operator')}</th>
          </tr>
        </TableHeader>
        <DisplayCurrentNotesRows
          currentNotes={props.currentNotes}
          locale={props.locale}
          remove={props.remove}
        />
      </MainTable>
    </NoteContainer>
  );
};

type EnterNewNotesProps = {
  insert: insertNotesFunction;
  locale: string;
};
const EnterNewNote = (props: EnterNewNotesProps) => {
  const { t } = useTranslation('item');
  const [startDate, setStartDate] = React.useState(new Date());
  const [operator, setOperator] = React.useState<string>('');
  const [noteText, setNoteText] = React.useState<string>('');
  const handleOnClick = () => {
    props.insert({ dateOfNote: startDate, note: noteText, initials: operator });
  };
  return (
    <>
      <DateAndOperatorContainer>
        <DateDiv>
          <StandardLabel>{t('itemLabels.date')}</StandardLabel>
          <DatePicker
            value={startDate.toLocaleDateString(props.locale)}
            dateFormat={t('itemLabels.dateformat')}
            selected={startDate}
            onChange={(date: Date) => setStartDate(date)}
          />
        </DateDiv>
        <OperatorDiv>
          <StandardLabel>{t('itemLabels.operator')}</StandardLabel>
          <StandardInput
            onChange={e => {
              setOperator(e.target.value);
            }}
          />
        </OperatorDiv>
        <SpacerDiv />
        <ButtonDiv>

        </ButtonDiv>
      </DateAndOperatorContainer>
      <NewNoteContainer>
        <StandardLabel>{t('itemLabels.note')}</StandardLabel>
        <LongInput
          onChange={e => {
            setNoteText(e.target.value);
          }}
        />
        <PrimaryButton type="button" onClick={handleOnClick}>
          {t('itemLabels.addNote')}
        </PrimaryButton>
      </NewNoteContainer>
    </>
  );
};

const Errors = (props: { errorMessage: Array<string> }) => {
  return (
    <ErrorDiv>
      <ul>
        {props.errorMessage.map(message => {
          return <li>{message}</li>;
        })}
      </ul>
    </ErrorDiv>
  );
};
type errorNotes = {
  noteDetails: Array<{
    dateofNote: string | undefined;
    note: string | undefined;
    initials: string | undefined;
  }>;
};
const ArrayErrorMessage = (props: { errors: FormikErrors<errorNotes> }) => {
  const message: Array<string> = [];
  forEach(props.errors.noteDetails, noteDetails => {
    noteDetails?.note && message.push(noteDetails.note);
    noteDetails?.dateofNote && message.push(noteDetails.dateofNote);
    noteDetails?.initials && message.push(noteDetails.initials);
  });
  if (message.length > 0) {
    return <Errors errorMessage={message} />;
  } else {
    return <></>;
  }
};
export type insertNotesFunction = (value: Notes) => void;
export type removeNotesFunction = <T>(index: number) => void;
export const NotesEdit = (props: NotesEditProps) => {
  return (
    <FieldArray
      name="noteDetails"
      render={arrayHelpers => (
        <NotesEditContainer>
          <EnterNewNote insert={arrayHelpers.push} locale={props.locale} />
          <ArrayErrorMessage errors={arrayHelpers.form.errors} />
          <DisplayCurrentNote
            currentNotes={props.currentNotes}
            locale={props.locale}
            remove={arrayHelpers.remove}
          />
        </NotesEditContainer>
      )}
    />
  );
};
