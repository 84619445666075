import * as React from 'react';
import { styled } from '../../styling/theme';
import { FormField, FormFieldProps } from './FormField';
import { CurrencyCode } from '../../models/locale';
import { useContext } from 'react';
import { UserContext } from '../../feature/authentication/loginData/userContext';
import { formatCurrency } from '../../utils/currencyUtils';
import { formatNumber } from '../../utils/numberUtils';
import { BaseInputStyling } from './BaseInputStyling';

export const readOnlyFieldTestId = 'read-only-field';

export type InputFieldProps<TField = string | number> = (
  | { type: 'currency'; currency?: CurrencyCode | Array<CurrencyCode> }
  | { type: 'text' | 'number' }
) &
  FormFieldProps<TField>;

export const ReadOnlyField = (props: InputFieldProps) => {
  const { user } = useContext(UserContext);

  return (
    <FormField {...props}>
      {formFieldChildProps => {
        const value = formFieldChildProps.field.value;
        let displayValue: React.ReactNode = '\u00A0'; // initialise with non-breaking space - so that the correct height is set when there is no value.

        if (value) {
          if (props.type === 'currency') {
            displayValue = formatCurrency(value, user.locale, props.currency ?? user.currency);
          } else if (props.type === 'text') {
            displayValue = value.toString();
          } else if (props.type === 'number') {
            displayValue = formatNumber(value, user.locale);
          }
        }

        return (
          <ReadOnlyValue data-testid={readOnlyFieldTestId} aria-label={props.label}>
            {displayValue}
          </ReadOnlyValue>
        );
      }}
    </FormField>
  );
};

export const ReadOnlyValue = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  ${BaseInputStyling};
  background-color: ${props => props.theme.colours.appBackground};
`;
