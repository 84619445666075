import { Form, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, PrimaryButton } from '../../shared/buttons/Button';
import { HollowLinkButton } from '../../shared/buttons/LinkButton';
import { FormValidationErrorBox } from '../../shared/errors/FormValidationErrorBox';
import {
  CreatableMultiSelectDropdownField,
  CreatableMultiSelectOptionType,
} from '../../shared/forms/CreatableMultiSelectDropdownField';
import { SingleSelectDropdownField } from '../../shared/forms/SingleSelectDropdownField';
import { TextAreaField } from '../../shared/forms/TextAreaField';
import { ApiRequestProps } from '../../shared/higher-order-components/withApiRequest';
import { withFormSubmit } from '../../shared/higher-order-components/withFormSubmit';
import { useApiRequest } from '../../shared/hooks/useApiRequest';
import { InfoBox } from '../../shared/info/InfoBox';
import { SuccessBox } from '../../shared/success/SuccessBox';
import { sitesUrl } from '../../urls';
import { assertIsDefined } from '../../utils/assertIsDefined';
import { navigate } from '../../utils/routing';
import { PmsTypeResponse } from '../authentication/loginData/metadata';
import { PmsUsageContext } from '../authentication/loginData/pmsUsageContext';
import { UserContext } from '../authentication/loginData/userContext';
import { MerlinGroup } from './merlinGroups/merlinGroup';
import {
  getPmsInstanceOptions,
  merlinSiteDropdownOptions,
  pmsCodeAndMerlinGroupToPmsInstanceCode,
  pmsInstanceCodeToPmsCodeAndMerlinGroup,
  PmsSitesResponse,
} from './pms/pmsSite';
import { getAllPracticeGroups } from './practiceGroups/practiceGroupsApi';
import {
  CreateOrEditSiteCommandWrapper,
  CreateOrEditSiteFormModel,
  CreateOrEditSiteFormModelValidator,
  SiteResponse,
} from './site';
import { createOrEditSite } from '../../api/sitesApi';
import { editSiteUrl } from './siteUrls';

type OwnProps = {
  pmsTypes: Array<PmsTypeResponse>;
  pmsSites: PmsSitesResponse;
  merlinGroups: Array<MerlinGroup>;
  existingSite?: SiteResponse;
  showSuccess?: boolean;
  onSuccess?: (response: SiteResponse) => void;
};
type Props = OwnProps &
  ApiRequestProps<SiteResponse, CreateOrEditSiteCommandWrapper> &
  FormikProps<CreateOrEditSiteFormModel>;

const SiteFormComponent = (props: Props) => {
  const { t } = useTranslation(['site', 'metadata']);
  const { user } = useContext(UserContext);
  const pmsUsage = useContext(PmsUsageContext);
  const practiceGroupsRequest = useApiRequest(getAllPracticeGroups);
  const [practiceGroupOptions, setPracticeGroupOptions] = useState<
    Array<CreatableMultiSelectOptionType>
  >([]);

  useEffect(() => {
    setPracticeGroupOptions([
      { label: t('createAndEditForm.labels.practiceGroupsLoading'), value: '' },
    ]);

    practiceGroupsRequest.makeRequest(user.organisationGroupId).then(response => {
      const options = response
        ? response.practiceGroups.map(practiceGroup => ({
            label: practiceGroup.name,
            value: practiceGroup.name,
          }))
        : [];
      setPracticeGroupOptions(options);
    });
  }, []);

  const pmsInstanceOptions = useMemo(
    () => getPmsInstanceOptions(props.merlinGroups, props.pmsTypes, pmsUsage, t),
    [props.merlinGroups, props.pmsTypes],
  );

  const { pmsTypeCode, merlinGroupId } = props.values.sitePmsTypeCode
    ? pmsInstanceCodeToPmsCodeAndMerlinGroup(props.values.sitePmsTypeCode)
    : { pmsTypeCode: null, merlinGroupId: null };

  const hasChangesToConnection = () => {
    const formValues = props.values;
    const originalValues = assertIsDefined(props.existingSite);

    return (
      originalValues.sitePmsTypeCode !== pmsTypeCode ||
      originalValues.merlinGroupId !== merlinGroupId ||
      originalValues.sitePmsSiteId !== formValues.siteMerlinSiteId
    );
  };

  return (
    <Form>
      <SingleSelectDropdownField
        name="sitePmsTypeCode"
        label={t('createAndEditForm.labels.pmsType')}
        options={pmsInstanceOptions}
      />
      <>
        <SingleSelectDropdownField
          name="siteMerlinSiteId"
          label={t('createAndEditForm.labels.name')}
          options={merlinSiteDropdownOptions(props.pmsSites.merlinSites, merlinGroupId)}
        />
      </>
      <CreatableMultiSelectDropdownField
        name="sitePracticeGroups"
        label={t('createAndEditForm.labels.practiceGroups')}
        options={practiceGroupOptions}
        disabled={practiceGroupsRequest.inProgress}
        placeholder={
          practiceGroupsRequest.inProgress
            ? t('createAndEditForm.labels.practiceGroupsLoading')
            : undefined
        }
      />
      <TextAreaField name="siteNotes" label={t('createAndEditForm.labels.notes')} />
      {props.existingSite && props.existingSite.status === 'Live' && hasChangesToConnection() && (
        <InfoBox message={t('editForm.changedConnectionDetails')} />
      )}
      <ButtonGroup>
        <PrimaryButton type="submit" loading={props.isSubmitting}>
          {t('createAndEditForm.labels.saveButton')}
        </PrimaryButton>
        <HollowLinkButton to={sitesUrl()}>
          {t('createAndEditForm.labels.cancelButton')}
        </HollowLinkButton>
      </ButtonGroup>
      <FormValidationErrorBox errors={props.errors} touched={props.touched} />
      <SuccessBox
        message={t('createAndEditForm.success')}
        showSuccess={
          !!props.showSuccess && !props.isSubmitting && !props.apiError && isEmpty(props.touched)
        }
      />
    </Form>
  );
};

const enhance = withFormSubmit<
  OwnProps,
  CreateOrEditSiteFormModel,
  SiteResponse,
  CreateOrEditSiteCommandWrapper
>({
  enableReinitialize: true,
  request: createOrEditSite,
  onSubmitComplete: (props, response) => {
    if (props.onSuccess) {
      props.onSuccess(response);
    }
    navigate(editSiteUrl(response.siteId), {
      state: { success: true },
    });
  },

  mapPropsToValues: props => ({
    siteId: props.existingSite ? props.existingSite.siteId : null,
    siteName: props.existingSite ? props.existingSite.siteName : '',
    sitePracticeGroups: props.existingSite
      ? props.existingSite.sitePracticeGroups.map(spg => spg.name)
      : [],
    siteNotes: props.existingSite ? props.existingSite.siteNotes : '',
    sitePmsTypeCode: props.existingSite
      ? pmsCodeAndMerlinGroupToPmsInstanceCode(
          props.existingSite.sitePmsTypeCode,
          props.existingSite.merlinGroupId,
        )
      : null,
    siteMerlinSiteId: props.existingSite ? props.existingSite.sitePmsSiteId : null,
  }),
  mapValuesToRequestParameters: (
    props: OwnProps,
    formModel: CreateOrEditSiteFormModel,
  ): CreateOrEditSiteCommandWrapper => {
    const { pmsTypeCode, merlinGroupId } = pmsInstanceCodeToPmsCodeAndMerlinGroup(
      assertIsDefined(formModel.sitePmsTypeCode),
    );

    const sitePmsSiteId = Number(assertIsDefined(formModel.siteMerlinSiteId));

    return {
      command: {
        ...{ siteId: props.existingSite ? props.existingSite.siteId : undefined },
        siteName: assertIsDefined(
          props.pmsSites.merlinSites.find(
            merlinSite =>
              merlinSite.merlinGroupId === merlinGroupId && merlinSite.id === sitePmsSiteId,
          ),
        ).name,
        sitePracticeGroups: formModel.sitePracticeGroups ? formModel.sitePracticeGroups : [],
        siteNotes: formModel.siteNotes ? formModel.siteNotes : null,
        sitePmsTypeCode: pmsTypeCode,
        merlinGroupId,
        sitePmsSiteId,
      },
      createOrEditTag: props.existingSite ? 'edit' : 'create',
    };
  },
  validationConfig: {
    translationNamespace: 'site',
    validator: CreateOrEditSiteFormModelValidator,
  },
});

export const SiteForm: React.FunctionComponent<OwnProps> = enhance(SiteFormComponent);
