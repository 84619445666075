import { isEqual } from 'lodash';
import * as React from 'react';

export type PropsToParamsMapper<TProps, TParams> = (props: TProps) => TParams;
export type RequestProducer<TProps, TParams> = (props: TProps, params: TParams) => void;

// Performs a request when the component is first loaded and whenever the props that the request relies on change.
//
// Takes:
// -  propsToParamsMapper: This is a function that takes the props of the wrapped component and outputs the params for
//      the request
// -  requestProducer: This is a function that takes the props of the wrapped component and the params returned from the
//      props to params mapper function and performs the request
export const makeRequestOnPropsChange = <TOwnProps, TParams>(
  propsToParamsMapper: PropsToParamsMapper<TOwnProps, TParams>,
  requestProducer: RequestProducer<TOwnProps, TParams>,
) => (WrappedComponent: React.ComponentType<TOwnProps>) =>
  class MakeRequestOnPropsChange extends React.Component<TOwnProps> {
    componentDidMount() {
      requestProducer(this.props, propsToParamsMapper(this.props));
    }

    componentDidUpdate(previousProps: TOwnProps) {
      const prevParams = propsToParamsMapper(previousProps);
      const newParams = propsToParamsMapper(this.props);
      if (!isEqual(prevParams, newParams)) {
        requestProducer(this.props, newParams);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
