import { styled, theme } from '../../styling/theme';

export const DownshiftChildrenContainer = styled.div`
  width: fit-content;
`;

export const DropdownMenuContainer = styled.div<
  React.HTMLAttributes<HTMLDivElement> & React.RefAttributes<HTMLDivElement>
>`
  position: relative;
`;

export const dropdownMenuBorderWidthPixels = 1;

export const DropdownMenu = styled.ul<{ menuLeftOffset?: number }>`
  overflow-y: auto;
  max-height: 24em;
  padding: 0;
  margin-top: 0;
  position: absolute;
  border: ${dropdownMenuBorderWidthPixels}px solid ${props => props.theme.colours.border};
  border-radius: ${props => props.theme.spacing.extraTiny}px;
  background-color: ${props => props.theme.colours.componentBackground};
  z-index: 2;

  left: ${props => props.menuLeftOffset !== undefined && -props.menuLeftOffset}px;
`;

type DropdownItemProps = {
  isSelected?: boolean;
  isHighlighted?: boolean;
};

export const DropdownItemWidth = theme.formInputWidth.medium;

export const DropdownItem = styled.li<DropdownItemProps>`
  overflow-x: hidden;
  color: ${props => props.theme.colours.secondary};
  background-color: ${props =>
    props.isSelected
      ? props.theme.colours.secondaryInactive
      : props.theme.colours.componentBackground};
  border: 2px solid
    ${props => (props.isHighlighted ? props.theme.colours.secondaryHighlight : 'transparent')};
  padding: ${props => props.theme.spacing.tiny}px;
  width: ${DropdownItemWidth}px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MultiSelectSelection = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.colours.secondaryHighlight};
  font-size: ${props => props.theme.typography.subtext.fontSize}px;
  color: ${props => props.theme.colours.componentBackground};
  border-radius: ${props => props.theme.spacing.tiny}px;
  margin-right: ${props => props.theme.spacing.tiny}px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 ${props => props.theme.spacing.tiny}px;
  align-content: center;
`;

export const MultiSelectRemoveSelectedButton = styled.button<
  React.ButtonHTMLAttributes<HTMLButtonElement>
>`
  cursor: pointer;
  border: none;
  background-color: inherit;
  color: ${props => props.theme.colours.componentBackground};
  padding-left: ${props => props.theme.spacing.tiny}px;

  &:disabled {
    cursor: default;
    opacity: 0.6;
  }
`;
