import { RouteComponentProps, Router } from '@reach/router';
import * as React from 'react';
import { PageNotFound } from '../PageNotFound';
import { EditSupplier } from './EditSupplier';
import { SupplierListSection } from './supplier-list/SupplierListSection';
import { ViewSuppliersList } from './ViewSuppliersList';

export const SuppliersSection = (props: RouteComponentProps) => (
  <Router primary={false}>
    <ViewSuppliersList path="/" />
    <EditSupplier path="edit/:supplierId" />
    <SupplierListSection path={'/list/*'} />
    <PageNotFound default={true} />
  </Router>
);
