import { TranslatableValidator } from '../../utils/validation/TranslatableValidator';
import { TFunction } from 'i18next';
import { Locale } from '../../models/locale';
import { BarcodeDetails } from './item';
import { InputMaximumLength } from '../../utils/validation/validationConstants';

export class BarcodeValidator extends TranslatableValidator<BarcodeDetails> {
  constructor(t: TFunction, locale: Locale) {
    super(t, locale);
    this.ruleFor('barcode')
      .maxLength(InputMaximumLength)
      .withMessage(t('item:validation.barcode.length'));
    this.ruleFor('barcode')
      .notNull()
      .withMessage(t('item:validation.barcode.empty'));
    this.ruleFor('barcode')
      .notEmpty()
      .withMessage(t('item:validation.barcode.empty'));
    this.ruleFor('manufacturer')
      .notNull()
      .withMessage(t('item:validation.barcode.manufacturerempty'))
      .unless(x => x.barcode === null || x.barcode === '');
    this.ruleFor('manufacturer')
      .notEmpty()
      .withMessage(t('item:validation.barcode.manufacturerempty'))
      .unless(x => x.barcode === null || x.barcode === '');
    this.ruleFor('manufacturer')
      .maxLength(InputMaximumLength)
      .withMessage(t('item:validation.barcode.manufacturerlength'));
  }
}
