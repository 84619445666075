import { faCity, faUser } from '@fortawesome/free-solid-svg-icons';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { ValueType } from 'react-select';
import { SecondaryLinkStyledButton } from '../../../shared/buttons/Button';
import { useApiRequest } from '../../../shared/hooks/useApiRequest';
import { styled } from '../../../styling/theme';
import { AuthenticationContext } from '../../authentication/authenticationContext';
import { OrganisationDto } from '../../authentication/loginData/organisationDto';
import { UserContext } from '../../authentication/loginData/userContext';
import { UpdateOrganisationIdCommand } from '../../users/user';
import { setUserPriceMode, UpdateOrganisation } from '../../users/usersApi';
import { NavIcon } from './NavIcon';
import { UserPriceToggle } from './UserPriceToggle';

const UserInfoContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 100%;
`;

const UserOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const UserOrgContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${props => props.theme.spacing.extraSmall}px;
`;
const UserNameContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${props => props.theme.spacing.extraSmall}px;
`;

const OrgName = styled.div`
  color: ${props => props.theme.colours.secondary};
`;
const OrgDropContainer = styled.div`
  color: ${props => props.theme.colours.secondary};
  width: 200px;
`;

const Name = styled.div`
  color: ${props => props.theme.colours.secondary};
`;

const Icon = styled(NavIcon)`
  color: ${props => props.theme.colours.secondary};
`;

const Logout = styled(SecondaryLinkStyledButton)<React.ButtonHTMLAttributes<HTMLButtonElement>>`
  font-size: 16px;
  font-weight: normal;
  color: ${props => props.theme.colours.secondaryHighlight};

  &:hover {
    color: ${props => props.theme.colours.secondary};
  }
`;

export const UserInfo = () => {
  const { makeRequest, inProgress } = useApiRequest(UpdateOrganisation);
  const { t } = useTranslation('layout');
  const authentication = useContext(AuthenticationContext);
  const { user, updateUser } = useContext(UserContext);
  type MyOptionType = { label: string; value: number };
  const userOrganisationOptions = (): Array<MyOptionType> => {
    const options: Array<MyOptionType> = user.userOrganisations.map(x => ({
      label: x.name,
      value: x.id,
    }));
    return options;
  };
  const currentUserOrg = (): MyOptionType => {
    const currentOrg = user.userOrganisations.filter(x => x.id === user.organisationGroupId)[0];
    return { label: currentOrg.name, value: currentOrg.id };
  };
  const handelOrganisationSelection = (selectedOrganisation: MyOptionType | null) => {
    if (selectedOrganisation) {
      makeRequest({ organisationId: selectedOrganisation.value }).then(result => {
        if (result) {
          updateUser(result.user);
          const location = window.location.pathname + window.location.hash;
          if (location == '/deviations') {
            localStorage.removeItem('filterOptions-deviations');
            window.location.href = location;
          } else {
            window.location.reload();
          }
        }
      });
    }
  };

  return (
    <UserInfoContainer>
      <UserOptionsContainer>
        <UserNameContainer>
          <Icon icon={faUser} />
          <Name>{user.name}</Name>
        </UserNameContainer>
        <UserOrgContainer>
          <OrgName>
            <Icon icon={faCity} />
          </OrgName>
          <OrgDropContainer>
            <Select
              options={userOrganisationOptions()}
              onChange={value => handelOrganisationSelection(value)}
              defaultValue={currentUserOrg()}
            />
          </OrgDropContainer>
        </UserOrgContainer>
        <Logout onClick={authentication.logout}>{t('logout')}</Logout>
      </UserOptionsContainer>
      <UserPriceToggle />
    </UserInfoContainer>
  );
};
