import { ErrorMessage, FastField, Field, FieldProps, FormikProps, getIn } from 'formik';
import * as React from 'react';
import { css, styled } from '../../styling/theme';
import { InputSize } from './BaseInputStyling';

export const getFieldErrorMessageTestId = (fieldName: string) => `field-error:${fieldName}`;

export type FormFieldChildProps<T> = FieldProps<T> & { valid: boolean; invalid: boolean };

export type LabelPosition = 'top' | 'right';

type AllFormFieldProps<TField> = {
  children: (props: FormFieldChildProps<TField>) => React.ReactNode;
  labelPosition?: LabelPosition;
} & FormFieldProps<TField>;

export const inputTestId = <TForm extends unknown>(fieldName: keyof TForm) =>
  `inputField:${fieldName.toString()}`;

/**
 * Jira - pp-997
 * Add the functon stub to the component props
 */
export type FormFieldProps<TField = unknown> = {
  name: string;
  label?: string;
  infoText?: string;
  className?: string;
  fastVariant?: boolean;
  hideLabel?: boolean;
  bulkVariant?: boolean;
  inputSize?: InputSize;
  useDebouncing?: boolean;
  placeholder?: string;
  onChange?: (value: TField, form: FormikProps<unknown>) => void;
  onInputValueChange?: (value: string) => void;
};
export const FormFieldContainer = styled.div<
  React.HTMLAttributes<HTMLDivElement> & { inputSize?: InputSize; bulkVariant?: boolean }
>`
  max-width: ${props => props.theme.formInputWidth.max}px;
  display: flex;
  flex-direction: column;
  margin-bottom: ${props =>
    props.bulkVariant || props.inputSize === 'small' ? 0 : props.theme.spacing.tiny}px;
  margin-right: ${props =>
    props.bulkVariant || props.inputSize === 'small' ? 0 : props.theme.spacing.extraSmall}px;
`;

export type ErrorMessageMarginProps = { showingError?: boolean; bulkVariant?: boolean };
export const WithErrorMessageMargin = css<ErrorMessageMarginProps>`
  margin-bottom: ${props =>
    props.bulkVariant
      ? 0
      : props.theme.spacing.extraSmall +
        (props.showingError
          ? 0
          : props.theme.spacing.extraSmall +
            props.theme.typography.subtext
              .fontSize)}px; // Leave space for the error message and its margin
`;

export type LabelProps = { labelPosition?: LabelPosition } & ErrorMessageMarginProps &
  React.LabelHTMLAttributes<HTMLLabelElement>;
export const FormFieldLabel = styled.label<LabelProps>`
  display: flex;
  flex-direction: ${props => (props.labelPosition === 'top' ? 'column' : 'row')};
  color: ${props => props.theme.colours.secondary};
  size: ${props => props.theme.typography.headings.h6fontSize}px;
  ${WithErrorMessageMargin};
`;

const FormFieldLabelText = styled.div<
  React.HTMLAttributes<HTMLDivElement> & { labelPosition: LabelPosition }
>`
  margin-bottom: ${props => (props.labelPosition === 'top' ? props.theme.spacing.extraSmall : 0)}px;
  margin-left: ${props => (props.labelPosition === 'right' ? props.theme.spacing.extraSmall : 0)}px;
`;

const FormFieldLabelInfoText = styled.div`
  color: ${props => props.theme.colours.infoText};
  font-style: italic;
  font-size: ${props => props.theme.typography.subtext.fontSize}px;
  margin-top: ${props => -props.theme.spacing.extraSmall}px;
  margin-bottom: ${props => props.theme.spacing.extraSmall}px;
`;

export const SmallErrorMessage = styled.div<
  React.HTMLAttributes<HTMLDivElement> & ErrorMessageMarginProps
>`
  line-height: 1em;
  font-size: ${props => props.theme.typography.subtext.fontSize}px;
  margin-bottom: ${props => (props.bulkVariant ? 0 : props.theme.spacing.extraSmall)}px;
  color: #dc3545;
`;

export const FormField = <TField extends unknown>(props: AllFormFieldProps<TField>) => {
  const FieldComponent = props.fastVariant ? FastField : Field;

  return (
    <FormFieldContainer
      className={props.className}
      bulkVariant={props.bulkVariant}
      inputSize={props.inputSize}
    >
      <FieldComponent name={props.name}>
        {({ form, field }: FieldProps) => {
          const touched = getIn(form.touched, props.name);
          const error = getIn(form.errors, props.name);
          const invalid = touched && error;
          const valid = touched && !error;

          const children = <>{props.children({ form, field, valid, invalid })}</>;

          if (props.label === undefined) {
            return children;
          }

          const labelPosition = props.labelPosition || 'top';

          return (
            <FormFieldLabel
              showingError={invalid}
              bulkVariant={props.bulkVariant}
              labelPosition={labelPosition}
            >
              {labelPosition === 'right' ? children : null}

              {!props.hideLabel && (
                <FormFieldLabelText labelPosition={labelPosition}>{props.label}</FormFieldLabelText>
              )}
              {props.infoText && <FormFieldLabelInfoText>{props.infoText}</FormFieldLabelInfoText>}

              {labelPosition === 'top' ? children : null}
            </FormFieldLabel>
          );
        }}
      </FieldComponent>
      <ErrorMessage name={props.name}>
        {error => (
          <SmallErrorMessage
            bulkVariant={props.bulkVariant}
            data-testid={getFieldErrorMessageTestId(props.name)}
          >
            {error}
          </SmallErrorMessage>
        )}
      </ErrorMessage>
    </FormFieldContainer>
  );
};
