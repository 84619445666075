import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteComponentProps } from '@reach/router';
import { TFunction } from 'i18next';
import * as React from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import Select, { ValueType } from 'react-select';
import { PrimaryButton } from '../../../shared/buttons/Button';
import { DataLoader } from '../../../shared/DataLoader';
import { useApiRequest } from '../../../shared/hooks/useApiRequest';
import { RequiresOneOfPermissions } from '../../../shared/Permissions';
import { styled } from '../../../styling/theme';
import { ExcelDownload } from '../../../utils/ExcelDownload';
import { permissions } from '../../authentication/permissions';
import { SupplierForSupplierList } from './supplierList';
import {
  getSupplierListReviewAuthenticatedApiRequest,
  getSuppliersForSupplierList,
} from './supplierListApi';
import { appendDateTimeToString } from '../../../utils/stringUtils';

const InfoTextContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing.extraSmall}px;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.colours.primary};
  font-size: ${props => props.theme.spacing.medium}px;
  margin-right: ${props => props.theme.spacing.extraSmall}px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
`;

const Text = styled.p`
  margin: 0;
`;

const DownloadExcelButton = styled(PrimaryButton)`
  margin-top: ${props => props.theme.spacing.small}px;
  margin-bottom: ${props => props.theme.spacing.tiny}px;
`;

const InfoText = (props: { t: TFunction }) => (
  <InfoTextContainer>
    <StyledIcon icon={faInfoCircle} />
    <TextContainer>
      <Text>{props.t('getSupplierItems.infoText')}</Text>
      <Text>{props.t('getSupplierItems.infoTextOptional')}</Text>
    </TextContainer>
  </InfoTextContainer>
);

type MyOptionType = { label: string; value: number };

export const SupplierListItemsDownload = (props: RouteComponentProps) => {
  const [selectedSupplierId, setselectedSupplierId] = React.useState(0);
  const [selectedSupplierName, setselectedSupplierName] = React.useState('NoneSelected');
  const { t } = useTranslation('supplierList');

  const supplierOptions = (supplierList: Array<SupplierForSupplierList>): Array<MyOptionType> =>
    supplierList.map(x => ({
      value: x.supplierId,
      label: x.supplierName,
    }));

  const HandleSelectedNotes = (dropDown: ValueType<MyOptionType, false>) => {
    setselectedSupplierId(dropDown?.value ?? 0);
    setselectedSupplierName(dropDown?.label ?? '');
  };
  const getSupplierList = useApiRequest(getSupplierListReviewAuthenticatedApiRequest);

  const downloadSupplierList = (supplier: number) => {
    const response = getSupplierList.makeRequest(supplier);
    response.then(data => {
      if (data) {
        const headers: Array<string | number> = ['Item Name', 'Supplier Code', 'Price'];
        const listItems = data.suppliers.map(x => [x.listItemName, x.supplierCode, x.price]);
        listItems.unshift(headers);

        const fileName = appendDateTimeToString(`${selectedSupplierName}_ListItems`);

        ExcelDownload(listItems, `${fileName}.xlsx`, selectedSupplierName);
      }
    });
  };

  return (
    <RequiresOneOfPermissions permissions={[permissions.SuperAdmin, permissions.CentralAdmin]}>
      <form>
        <DataLoader apiRequest={getSuppliersForSupplierList}>
          {supplierProps => (
            <>
              <Title>{t('getSupplierItems.title')}</Title>
              <h1>{t('getSupplierItems.heading')}</h1>
              <InfoText t={t} />
              <Select
                isSearchable={true}
                isMulti={false}
                options={supplierOptions(supplierProps.response.suppliers)}
                onChange={HandleSelectedNotes}
              />
            </>
          )}
        </DataLoader>
        <DownloadExcelButton
          type="button"
          onClick={() => {
            downloadSupplierList(selectedSupplierId);
          }}
        >
          {t('getSupplierItems.downloadButton')}
        </DownloadExcelButton>
      </form>
    </RequiresOneOfPermissions>
  );
};
