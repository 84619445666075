import * as React from 'react';
import { useContext } from 'react';
import { ToggleButton } from '../../../shared/buttons/ToggleButton';
import { useApiRequest } from '../../../shared/hooks/useApiRequest';
import { styled } from '../../../styling/theme';
import { UserContext } from '../../authentication/loginData/userContext';
import { setUserPriceMode } from '../../users/usersApi';

const ToggleButtonContainer = styled.div`
  margin-left: ${props => props.theme.spacing.small}px;
`;

export const UserPriceToggle = () => {
  const { user, updateUser } = useContext(UserContext);
  const { makeRequest, inProgress } = useApiRequest(setUserPriceMode);
  const onChangeUserPriceMode = (useGrossPrices: boolean) => {
    makeRequest({ useGrossPrices }).then(result => {
      if (result) {
        updateUser(result);
      }
    });
  };
  return (
    <ToggleButtonContainer>
      <ToggleButton
        size="tiny"
        state={user.useGrossPrices}
        onChangeState={onChangeUserPriceMode}
        offButtonText="Net price"
        onButtonText="Gross price"
        loading={inProgress}
      />
    </ToggleButtonContainer>
  );
};
