import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { trackError } from '../../applicationInsights';
import { styled } from '../../styling/theme';

type ErrorBoundaryState = {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
};

type ErrorBoundaryProps = WithTranslation & {};

// This should mimic the PageContent in Layout.tsx in a simple format
const PageContent = styled.div`
  padding: ${props => props.theme.spacing.large}px;
  width: 100%;
  height: 100vh;
  background-color: ${props => props.theme.colours.appBackground};
`;

const ErrorBoundaryHeader = styled.h1`
  color: ${props => props.theme.colours.primaryHighlight};
`;

const ErrorBoundaryMessage = styled.p`
  color: ${props => props.theme.colours.primary};
  font-size: ${props => props.theme.typography.headings.h4fontSize}px;
`;

const ErrorBoundaryDetails = styled.details`
  color: ${props => props.theme.colours.primary};
  white-space: pre-wrap;
`;

class ErrorBoundaryComponent extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    trackError(error, errorInfo);
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <PageContent>
          <ErrorBoundaryHeader>
            {this.props.t(['header', 'Something went wrong'])}
          </ErrorBoundaryHeader>
          <ErrorBoundaryMessage>
            {this.props.t(['refreshMessage', 'Please refresh and try again.'])}
          </ErrorBoundaryMessage>
          <ErrorBoundaryMessage>
            {this.props.t([
              'continualErrorMessage',
              "If it continues to occur, please contact support. We've captured some details below.",
            ])}
          </ErrorBoundaryMessage>
          <ErrorBoundaryDetails>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </ErrorBoundaryDetails>
        </PageContent>
      );
    }

    return this.props.children;
  }
}

export const ErrorBoundary = withTranslation('errorBoundary')(ErrorBoundaryComponent);
