import { Button, Modal } from 'flowbite-react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { ObsoleteSupplierProduct } from '../DeleteSupplierItems.types';
import { useTranslation } from 'react-i18next';

type DeleteConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  item: ObsoleteSupplierProduct | null;
};

export const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  item,
}) => {
  const { t } = useTranslation('item');

  return (
    <Modal dismissible={true} show={isOpen} size="md" onClose={onClose} popup={true}>
      <Modal.Body>
        <div className="text-center">
          <HiOutlineExclamationCircle className="mx-auto mb-4 mt-2 h-14 w-14 text-gray-400 dark:text-gray-200" />
          <h3 className="mb-2 text-lg font-normal text-gray-500 dark:text-gray-400">
            {t('deleteSupplierItems.modal.copy')}
          </h3>
          <p className="mb-4">
            {item?.name} [code: {item?.code}, id: {item?.id}]
          </p>
          <div className="flex justify-center gap-4">
            <Button color="failure" onClick={onConfirm}>
              {t('deleteSupplierItems.modal.buttons.confirm')}
            </Button>
            <Button color="gray" onClick={onClose}>
              {t('deleteSupplierItems.modal.buttons.cancel')}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
