/**
 * Extract unique item-codes from a string, removing whitespace and commas.
 * (Item-codes must be separated by whitespace or commas.)
 *
 * @example
 * getItemCodesFromString('ITEMCODE1, ITEMCODE2')
 * // returns ['ITEMCODE1', 'ITEMCODE2']
 */
export const getItemCodesFromString = (str: string): Array<string> => {
  const codes = str.split(/[\s,]+/).filter(Boolean); // Filter to remove empty strings
  return Array.from(new Set(codes));
};

/**
 * Validate a string of item-codes, separated by commas, whitespace or a combination of both.
 * Valid item-codes are between 2-25 characters in length, and may contain the characters:
 * A-Z, a-z, 0-9, ., % (percent), + (plus) and . (period).
 *
 * @example
 * isValidItemCodesString('ITEMCODE1, ITEMCODE2') // true
 * isValidItemCodesString('ITEMCODE1 ITEMCODE2') // true
 * isValidItemCodesString('ITEMCODE1&, ITEMCODE2, ') // false, as '&' is not valid
 */
export const isValidItemCodesString = (str: string): boolean => {
  const itemCodes = str.split(/[\s,]+/).filter(Boolean); // Filter to remove empty strings
  const matcher = /^[A-Za-z0-9\.\%\+]{2,25}$/;

  return itemCodes.length > 0 && itemCodes.every(code => matcher.test(code));
};
