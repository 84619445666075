import { RouteComponentProps } from '@reach/router';
import { useMemo } from 'react';
import { useCallback, useContext, useEffect } from 'react';
import * as React from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { HollowLinkButton } from '../../shared/buttons/LinkButton';
import { ApiErrorBox } from '../../shared/errors/ApiErrorBox';
import { FieldAndValue } from '../../shared/FieldAndValue';
import { useApiRequest } from '../../shared/hooks/useApiRequest';
import { RequiresOneOfPermissions } from '../../shared/Permissions';
import { DataFetchTable } from '../../shared/tables/Table';
import { UserContext } from '../authentication/loginData/userContext';
import { getTreatmentTypeDeviationBulkEdit } from '../deviations/deviationsApi';
import { viewDeviationBulkEditUrl } from '../deviations/deviationsUrls';
import { PageHeading } from '../layout/PageHeading';
import { getPmsRequestErrorsForTreatmentTypeDeviationBulkEdit } from './pmsRequestErrorApi';
import {
  mapPmsErrorsResponseToTableData,
  pmsRequestErrorsColumns,
} from './pmsRequestErrorTableShared';
import { createEditTreatmentTypeDeviationsPermissions } from '../deviations/treatmentTypeDeviations/bulkEdit/treatmentTypeDeviationBulkEdit';

type Props = RouteComponentProps<{ treatmentTypeDeviationBulkEditId: string }>;

export const ViewPmsRequestErrorsForTreatmentTypeDeviationBulkEdit = (props: Props) => {
  const { t } = useTranslation('pmsRequestError');
  const { user } = useContext(UserContext);
  const { makeRequest, response, apiError } = useApiRequest(
    getTreatmentTypeDeviationBulkEdit(Number(props.treatmentTypeDeviationBulkEditId)),
  );

  useEffect(() => {
    makeRequest({ pageNumber: 1, pageSize: 1 }).then();
  }, []);

  const columns = useMemo(() => pmsRequestErrorsColumns(t, user), [t, user]);
  const getErrors = useCallback(
    getPmsRequestErrorsForTreatmentTypeDeviationBulkEdit(
      Number(props.treatmentTypeDeviationBulkEditId),
    ),
    [props.treatmentTypeDeviationBulkEditId],
  );

  return (
    <>
      <Title>{t('viewForTreatmentTypeDeviationBulkEdit.title')}</Title>
      <PageHeading>
        <h1>{t('viewForTreatmentTypeDeviationBulkEdit.heading')}</h1>
        <HollowLinkButton
          to={viewDeviationBulkEditUrl(Number(props.treatmentTypeDeviationBulkEditId))}
        >
          {t('viewForTreatmentTypeDeviationBulkEdit.viewBulkEditButton')}
        </HollowLinkButton>
      </PageHeading>
      {response && (
        <>
          <FieldAndValue
            fieldName={t('viewForTreatmentTypeDeviationBulkEdit.scheduledDate')}
            value={response.scheduledDate}
            type={'date'}
          />
          <FieldAndValue
            fieldName={t('viewForTreatmentTypeDeviationBulkEdit.name')}
            value={response.name}
            type={'text'}
          />
        </>
      )}
      {apiError && <ApiErrorBox error={apiError} />}
      <RequiresOneOfPermissions permissions={createEditTreatmentTypeDeviationsPermissions}>
        <DataFetchTable
          columns={columns}
          pageSize={10}
          getApiData={getErrors}
          mapResponseToTableData={mapPmsErrorsResponseToTableData}
          emptyTableMessage={t('viewErrors.emptyTableMessage')}
        />
      </RequiresOneOfPermissions>
    </>
  );
};
