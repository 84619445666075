import React from 'react';
import { CheckboxField } from '../../../shared/forms/CheckboxField';
import {
  FormFieldGroupContainer,
  FormGroupTitle,
  FormFieldGroup,
} from '../../../shared/forms/FormContainers';
import { InputField } from '../../../shared/forms/InputField';
import { useTranslation } from 'react-i18next';
import { LoginUserResponse } from '../../authentication/loginData/user';
import { StyledCheckboxGroup } from '../styles/UserStyles';

type OwnProps = {
  currentUser: LoginUserResponse;
};

type Props = OwnProps;

export const UserDetails = (props: Props) => {
  const { t } = useTranslation(['user']);

  return (
    <FormFieldGroupContainer>
      <FormGroupTitle>{t('createOrEditForm.headings.userDetails')}</FormGroupTitle>
      <FormFieldGroup>
        <InputField name="email" label={t('createOrEditForm.labels.email')} />

        <StyledCheckboxGroup>
          <CheckboxField
            name="useGrossPrices"
            label={t('createOrEditForm.labels.useGrossPrices')}
          />
        </StyledCheckboxGroup>
      </FormFieldGroup>
    </FormFieldGroupContainer>
  );
};
