import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from '../../shared/tabs/Tabs';
import { userHasOneOfPermissions } from '../authentication/loginData/user';
import { UserContext } from '../authentication/loginData/userContext';
import { permissions } from '../authentication/permissions';
import { ViewPmsRequestErrorsTable } from '../pmsRequestErrors/ViewPmsRequestErrorsTable';
import { RecentDeviationBulkEditsDashboardSection } from './RecentDeviationBulkEditsDashboardSection';
import { RecentItemBulkEditsSection } from './RecentItemBulkEditsSection';
import { UpcomingDeviationBulkEditsSection } from './UpcomingDeviationBulkEditsSection';
import { UpcomingTreatmentTypeDeviationBulkEditsSection } from './UpcomingTreatmentTypeDeviationBulkEditsSection';
import { RecentTreatmentTypeDeviationBulkEditsDashboardSection } from './RecentTreatmentTypeDeviationBulkEditsSection';
import { DashboardFilterSelection } from './models/DashboardFilterSelection';
import { UpcomingItemBulkEditsSection } from './UpcomingItemBulkEditsSection';

// Inside ItemBulkEdits component
const ItemBulkEdits = ({ existingFilters }: TabsSectionProps) => (
  <>
    <UpcomingItemBulkEditsSection existingFilters={existingFilters} />
    <RecentItemBulkEditsSection existingFilters={existingFilters} />
  </>
);

const DeviationBulkEdits = ({ existingFilters }: TabsSectionProps) => (
  <>
    <UpcomingDeviationBulkEditsSection existingFilters={existingFilters} />
    <RecentDeviationBulkEditsDashboardSection existingFilters={existingFilters} />
  </>
);

const TreatmentTypeDeviationBulkEdits = ({ existingFilters }: TabsSectionProps) => (
  <>
    <UpcomingTreatmentTypeDeviationBulkEditsSection existingFilters={existingFilters} />
    <RecentTreatmentTypeDeviationBulkEditsDashboardSection existingFilters={existingFilters} />
  </>
);

type TabsSectionProps = {
  existingFilters?: DashboardFilterSelection;
};

export const TabsSection = (props: TabsSectionProps) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation('dashboard');

  const getTabs = (): Array<Tab> => {
    const tabs = [];
    tabs.push({
      title: t('itemBulkEdit.tabTitle'),
      content: <ItemBulkEdits existingFilters={props.existingFilters} />,
    });

    if (userHasOneOfPermissions(user, [permissions.SuperAdmin, permissions.CentralAdmin])) {
      tabs.push({
        title: t('pmsErrors.tabTitle'),
        content: <ViewPmsRequestErrorsTable />,
      });
    }

    /**
     * jira pp-1076 all users can see Central, deviation and tx Edits
     */
    tabs.push({
      title: t('deviationBulkEdit.tabTitle'),
      content: <DeviationBulkEdits existingFilters={props.existingFilters} />,
    });
    tabs.push({
      title: t('treatmentTypeDeviationBulkEdit.tabTitle'),
      content: <TreatmentTypeDeviationBulkEdits existingFilters={props.existingFilters} />,
    });

    return tabs;
  };

  return <Tabs tabs={getTabs()} />;
};
