import React from 'react';
import { useTranslation } from 'react-i18next';
import { shouldShowSuccess, SuccessBox } from '../../shared/success/SuccessBox';

const shouldShowSuccessfulCancellation = (): boolean => !!window.history.state?.cancelled;

const shouldShowSuccessfulErrorClearing = (): boolean =>
  !!window.history.state?.pmsRequestErrorCleared;

const shouldShowSuccessfulDeviationCreation = (): boolean =>
  !!window.history.state?.successfulDeviationBulkEdit;

const shouldShowSuccessfulDeviationCancellation = (): boolean =>
  !!window.history.state?.cancelledDeviationBulkEdit;

const shouldShowSuccessfulTreatmentTypeDeviationCreation = (): boolean =>
  !!window.history.state?.successfulTreatmentTypeDeviationBulkEdit;

const shouldShowSuccessfulTreatmentTypeDeviationCancellation = (): boolean =>
  !!window.history.state?.cancelledTreatmentTypeDeviationBulkEdit;

export const SuccessBoxesSection = () => {
  const { t } = useTranslation('dashboard');

  return (
    <>
      <SuccessBox
        message={t('successfulBulkEdit')}
        showSuccess={shouldShowSuccess()}
        noMinHeight={true}
      />
      <SuccessBox
        message={t('successfulBulkEditCancellation')}
        showSuccess={shouldShowSuccessfulCancellation()}
        noMinHeight={true}
      />
      <SuccessBox
        message={t('successfulPmsRequestErrorClearing')}
        showSuccess={shouldShowSuccessfulErrorClearing()}
        noMinHeight={true}
      />
      <SuccessBox
        message={t('successfulDeviationBulkEdit')}
        showSuccess={shouldShowSuccessfulDeviationCreation()}
        noMinHeight={true}
      />
      <SuccessBox
        message={t('successfulDeviationBulkEditCancellation')}
        showSuccess={shouldShowSuccessfulDeviationCancellation()}
        noMinHeight={true}
      />
      <SuccessBox
        message={t('successfulTreatmentTypeDeviationBulkEdit')}
        showSuccess={shouldShowSuccessfulTreatmentTypeDeviationCreation()}
        noMinHeight={true}
      />
      <SuccessBox
        message={t('successfulTreatmentTypeDeviationBulkEditCancellation')}
        showSuccess={shouldShowSuccessfulTreatmentTypeDeviationCancellation()}
        noMinHeight={true}
      />
    </>
  );
};
