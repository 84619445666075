import * as React from 'react';
import { ChangeEvent } from 'react';
import { styled } from '../../styling/theme';
import { useDebouncedFormikField } from '../hooks/useDebouncedFormikField';
import { BaseInputProps, BaseInputStyling } from './BaseInputStyling';
import { FormField, FormFieldChildProps, FormFieldProps, inputTestId } from './FormField';

type InputProps = {} & BaseInputProps & React.InputHTMLAttributes<HTMLInputElement>;

export type InputFieldProps<TField = string> = {
  type?: string;
  disabled?: boolean;
  autoComplete?: boolean;
  placeholder?: string;
} & FormFieldProps<TField>;

export const Input = styled.input<InputProps>`
  ${BaseInputStyling};
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const InnerInput = ({
  field,
  form,
  valid,
  invalid,
  ...inputFieldProps
}: FormFieldChildProps<string> & InputFieldProps) => {
  const { value, setValue, flushDebounce } = useDebouncedFormikField({
    field,
    form,
    useDebouncing: inputFieldProps.useDebouncing,
  });

  const onChange = (event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value);

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    flushDebounce();
    field.onBlur(e);
  };

  return (
    <Input
      {...field}
      value={value || ''}
      valid={valid}
      invalid={invalid}
      autoComplete={inputFieldProps.autoComplete ? 'on' : 'off'}
      disabled={inputFieldProps.disabled}
      type={inputFieldProps.type}
      data-testid={inputTestId(field.name)}
      aria-label={inputFieldProps.label}
      bulkVariant={inputFieldProps.bulkVariant}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={inputFieldProps.placeholder}
    />
  );
};

export const InputField = (props: InputFieldProps) => {
  const { type, disabled, autoComplete, ...formFieldProps } = props;

  return (
    <FormField {...formFieldProps}>
      {formFieldChildProps => <InnerInput {...formFieldChildProps} {...props} />}
    </FormField>
  );
};
