import { useContext, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ToastOptions, toast } from 'react-toastify';
import { zodResolver } from '@hookform/resolvers/zod';
import { RequiresOneOfPermissions } from '../../../shared/Permissions';
import { permissions } from '../../authentication/permissions';
import { AuthenticationContext } from '../../authentication/authenticationContext';
import { getItemCodesFromString } from './ResetFailedItems.utils';
import { getResetCreationRequest, patchResetCreationRequest } from '../itemsApi';
import { defaultValues, schema } from './ResetFailedItems.schema';
import { useTranslation } from 'react-i18next';
import { ApiError } from '../../../models/error';
import { RouteComponentProps } from '@reach/router';
import { ResetCreationRequestForm } from './ResetFailedItems.types';

const toastOptions: ToastOptions = {
  position: 'bottom-center',
  autoClose: 4000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: 'colored',
};

const setSuccessToast = (message: string) => {
  toast.success(message, toastOptions);
};

const setErrorToast = (message: string) => {
  toast.error(message, toastOptions);
};

export const ResetFailedItems = (props: RouteComponentProps): React.ReactElement => {
  const authenticationContext = useContext(AuthenticationContext);
  const { t } = useTranslation('item');
  const [itemCodes, setItemCodes] = useState<Array<string>>([]);
  const [selectedItemIds, setSelectedItemIds] = useState<Array<number>>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const selectAllCheckboxRef = useRef<HTMLInputElement>(null);

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    watch,
  } = useForm<ResetCreationRequestForm>({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const searchInput = watch('codes');

  // Query to get items
  const { data, refetch: refetchItems, isFetching: isFetchingItems } = useQuery(
    ['getResetCreationRequest', JSON.stringify(itemCodes)],
    {
      queryFn: async ({ signal }) => {
        const bearerToken = await authenticationContext.getAccessToken();
        return getResetCreationRequest(itemCodes, bearerToken, signal);
      },
      enabled: itemCodes.length > 0,
      staleTime: Infinity,

      // Axios interceptor converts all errors to ApiError (see apiHttpClient.ts)
      onError: (error: ApiError) => {
        setErrorToast(error.message);
      },
    },
  );

  // Mutation to patch items
  const { mutate: patchItems, isLoading: isPatchingItems } = useMutation({
    mutationFn: async () => {
      const bearerToken = await authenticationContext.getAccessToken();
      return patchResetCreationRequest(selectedItemIds, bearerToken);
    },
    onSuccess() {
      setSelectedItemIds([]);
      refetchItems();
      setSuccessToast(t('resetFailedItems.toast.success'));
    },
    onError(error: ApiError) {
      setErrorToast(error.message);
    },
  });

  useEffect(() => {
    if (!data) return;

    if (isAllChecked) {
      setSelectedItemIds(data.map(item => item.id));
    } else {
      setSelectedItemIds([]);
    }
  }, [data, isAllChecked]);

  // sets parent checkbox to 'indeterminate' if some but not all children are selected
  useEffect(() => {
    if (!data) return;

    if (selectAllCheckboxRef.current) {
      selectAllCheckboxRef.current.indeterminate =
        selectedItemIds.length > 0 && selectedItemIds.length < data.length;
    }
  }, [data, selectedItemIds]);

  const handleRowClick = (itemId: number) => {
    const updatedSelectedItemIds = selectedItemIds.includes(itemId)
      ? selectedItemIds.filter(id => id !== itemId)
      : [...selectedItemIds, itemId];

    setSelectedItemIds(updatedSelectedItemIds);
  };

  const onSubmit: SubmitHandler<ResetCreationRequestForm> = ({ codes }) => {
    setItemCodes(getItemCodesFromString(codes));
  };

  return (
    <RequiresOneOfPermissions permissions={[permissions.IVCAdmin]}>
      <div>
        {/* HEADING AND FORM DESCRIPTION */}
        <h1 className="mb-8">{t('resetFailedItems.heading')}</h1>
        <p className="mb-8 text-base">{t('resetFailedItems.copy')}</p>
      </div>

      <div className="flex gap-4">
        {/* SEARCH */}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="self-start bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-3/12"
        >
          <div className="mb-4">
            <label htmlFor="codes" className="mb-3">
              {t('resetFailedItems.form.label')}
            </label>
            <input
              {...register('codes')}
              className="shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            {/* ERROR MESSAGE */}
            {formErrors.codes && (
              <p
                className="p-2 mb-3 mt-3 text-sm text-yellow-800 rounded-lg bg-yellow-50 font-medium"
                role="alert"
              >
                {formErrors.codes.message}
              </p>
            )}
          </div>

          {/* SEARCH BUTTON */}
          <input
            type="submit"
            disabled={!searchInput || isFetchingItems || isPatchingItems}
            value={t('resetFailedItems.buttons.search')}
            className={`${
              !searchInput || isFetchingItems || isPatchingItems
                ? 'bg-blue-400 cursor-not-allowed'
                : 'bg-blue-500 hover:bg-blue-700'
            }  py-2 px-4 rounded focus:outline-none focus:shadow-outline  font-bold text-white`}
          />
        </form>
        {/* TABLE OF ITEMS */}
        {!!data && data.length > 0 && (
          <div className="flex-grow-1">
            <div className="relative overflow-auto shadow-md mb-4" style={{ maxHeight: '50vh' }}>
              <table className="w-full text-left rtl:text-right text-gray-500 mb-4">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 sticky top-0">
                  <tr>
                    <th scope="col" className="p-4">
                      <div className="flex items-center">
                        <label htmlFor="checkbox-all" className="sr-only">
                          {t('resetFailedItems.table.checkAll')}
                        </label>
                        <input
                          ref={selectAllCheckboxRef}
                          id="checkbox-all"
                          type="checkbox"
                          checked={selectedItemIds.length === data.length}
                          onChange={() => setIsAllChecked(!isAllChecked)}
                          disabled={isPatchingItems}
                          className={
                            'w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 '
                          }
                        />
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-2">
                      {t('resetFailedItems.table.columns.itemCode')}
                    </th>
                    <th scope="col" className="px-6 py-2">
                      {t('resetFailedItems.table.columns.name')}
                    </th>
                    <th scope="col" className="px-6 py-2">
                      {t('resetFailedItems.table.columns.sentToMerlin')}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {data.map(item => (
                    <tr
                      key={item.id}
                      id={`row-${item.id}`}
                      onClick={() => {
                        if (isPatchingItems) return;

                        return handleRowClick(item.id);
                      }}
                      // highlight any selected rows, else alternate row colours
                      className={`${
                        selectedItemIds.includes(item.id)
                          ? 'bg-green-500 text-white'
                          : 'odd:bg-gray-100 even:bg-white'
                      } border-b cursor-pointer`}
                    >
                      <td className="w-4 px-4 py-2">
                        <div className="flex items-center">
                          <label htmlFor="row-checkbox" className="sr-only">
                            {t('resetFailedItems.table.checkbox')}
                          </label>
                          <input
                            id="row-checkbox"
                            type="checkbox"
                            checked={selectedItemIds.includes(item.id)}
                            onChange={e => e.stopPropagation()}
                            disabled={isPatchingItems}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                          />
                        </div>
                      </td>
                      <th
                        scope="row"
                        className={`px-6 py-2 font-medium whitespace-nowrap ${
                          selectedItemIds.includes(item.id) ? 'text-white' : 'text-gray-900'
                        }`}
                      >
                        {item.code}
                      </th>
                      <td className="px-6 py-2>">{item.name}</td>
                      <td className="px-6 py-2">
                        {item.hasHadMerlinCreationRequestCreated
                          ? t('resetFailedItems.table.yes')
                          : t('resetFailedItems.table.no')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <button
              onClick={() => patchItems()}
              className={`${
                selectedItemIds.length === 0 || isPatchingItems
                  ? 'bg-blue-400 cursor-not-allowed' // Disabled state
                  : 'bg-blue-500 hover:bg-blue-700'
              } text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
            >
              {t('resetFailedItems.buttons.reset')}
            </button>
          </div>
        )}

        {/* NO-ITEMS FOUND MESSAGE */}
        {data && data.length === 0 && (
          <p className="mt-3 font-bold text-xl">{t('resetFailedItems.noItemsFound')}</p>
        )}
      </div>
    </RequiresOneOfPermissions>
  );
};
