import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionChildrenProps } from '../../shared/Accordion';
import { styled } from '../../styling/theme';
import { UserContext } from '../authentication/loginData/userContext';
import { PageHeading } from '../layout/PageHeading';

type OwnProps = RouteComponentProps & {};
type Props = OwnProps;

const QuestionCellContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: ${styleProps => `1px solid ${styleProps.theme.colours.border}`};
  background-color: ${styleProps => styleProps.theme.colours.componentBackground};
  padding: ${styleProps => styleProps.theme.spacing.extraSmall}px;
`;

export const questionTranslationKeys: Array<string> = [
  'whatIsPacMan',
  'howDoIUsePacMan',
  'bulkCodeChanges',
  'cantSeeComposites',
  'pacManAccess',
  'cantSeeSites',
  'changesInMerlin',
  'problemContact',
  'bugContact',
  'developmentRequest',
];

export const Faq = (props: Props) => {
  const { t } = useTranslation(['faq']);
  const { user } = React.useContext(UserContext);
  const QuestionComponent = (translationKey: string) => (
    accordionProps: AccordionChildrenProps,
  ) => (
    <QuestionCellContainer>
      <span>{t(`questionKeys.${user.organisationGroupId}.${translationKey}.question`) + ' '}</span>
      <FontAwesomeIcon icon={accordionProps.isOpen ? faChevronUp : faChevronDown} />
    </QuestionCellContainer>
  );

  return (
    <>
      <Title>{t('title')}</Title>
      <PageHeading>
        <h1>{t('heading')}</h1>
      </PageHeading>
      {questionTranslationKeys.map(translationKey => (
        <Accordion headerComponent={QuestionComponent(translationKey)} key={translationKey}>
          {/*
              We need to use dangerouslySetInnerHTML here to allow rendering of
              HTML from the translation JSON files (e.g. for links). This is
              safe from XSS attacks because the text is static, rather than
              being dynamically generated from user input (e.g. from a form).
          */}
          <div
            dangerouslySetInnerHTML={{
              __html: t(`questionKeys.${user.organisationGroupId}.${translationKey}.answer`),
            }}
          />
        </Accordion>
      ))}
    </>
  );
};
