import * as React from 'react';
import { FormikProps, withFormik } from 'formik';
import { TFunction } from 'i18next';
import { flowRight, isEmpty } from 'lodash';
import { useContext, useState } from 'react';
import { WithTranslation, useTranslation, withTranslation } from 'react-i18next';
import { Locale } from '../../models/locale';
import { AdvancedFiltersButton, FilterAccordionBar } from '../../shared/Filter';
import { ButtonGroup, HollowButton, PrimaryButton } from '../../shared/buttons/Button';
import { InputField } from '../../shared/forms/InputField';
import {
  WithUserContextProps,
  withUserContext,
} from '../../shared/higher-order-components/withUserContext';
import { styled } from '../../styling/theme';
import { TranslatableValidator } from '../../utils/validation/TranslatableValidator';
import { UserContext } from '../authentication/loginData/userContext';
import { DashboardFilterSelection } from './models/DashboardFilterSelection';
import { Expandable } from '../../shared/Expandable';
import { DateField } from '../../shared/forms/DateField';
import { DashboardResponse } from '../../api/dashboardApi';
import { MultiSelectDropdownField } from '../../shared/forms/MultiSelectDropdownField';

type OwnProps = {
  existingFilters?: DashboardFilterSelection;
  setFilter: (data: DashboardFilterSelection) => void;
  dashboardOptions: DashboardResponse;
};
type Props = OwnProps & FormikProps<DashboardFilterSelection>;

const FlexRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const FlexButtonGroup = styled(ButtonGroup)`
  flex: 1 0 100%;
  align-self: center;
  margin-bottom: 0;
  margin-top: ${props => props.theme.spacing.small}px;
`;

export const FilterForm = styled.form<
  React.FormHTMLAttributes<HTMLFormElement> & {
    onSubmit: (...args: any) => any;
    onReset: (...args: any) => any;
  }
>``;

export const FilterFormBody = styled.div`
  padding: ${props => props.theme.spacing.small}px;
  margin-bottom: ${props => props.theme.spacing.small}px;
  background-color: ${props => props.theme.colours.componentBackground};
`;

const DashboardFilterFormComponent = (props: Props) => {
  const { t } = useTranslation('dashboard');
  const { user } = useContext(UserContext);

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const clearFields = () => {
    const dashboardFilterSelection: DashboardFilterSelection = {
      filename: null,
      scheduledDate: null,
      sites: null,
      users: null,
    };

    props.setValues(dashboardFilterSelection);
    props.setFilter(dashboardFilterSelection);
  };

  const onSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    props.handleSubmit(e);
    if (isEmpty(props.errors)) {
      setShowAdvancedFilters(false);
    }
  };

  return (
    <>
      <FilterAccordionBar
        label={t('filter.title')}
        isOpen={showAdvancedFilters}
        onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
      />
      <FilterForm onSubmit={onSubmit} onReset={props.handleReset}>
        <Expandable isExpanded={showAdvancedFilters}>
          <FilterFormBody>
            <FlexRowContainer>
              <DateField name="scheduledDate" label={t('filter.scheduledDate')} />
              <InputField
                name="filename"
                label={t('filter.filename')}
                placeholder={t('filter.filename')}
              />
              <MultiSelectDropdownField
                name="users"
                label={t('filter.createdBy')}
                options={props.dashboardOptions.users}
              />
              <MultiSelectDropdownField
                name="sites"
                label={t('filter.sites')}
                options={props.dashboardOptions.sites}
              />
            </FlexRowContainer>
            <FlexButtonGroup>
              <HollowButton
                onClick={clearFields}
                type="button"
                disabled={props.isSubmitting}
                name="clearFields"
              >
                {t('filter.clearFieldsButton')}
              </HollowButton>
              <PrimaryButton type="submit" loading={props.isSubmitting}>
                {t('filter.applyButton')}
              </PrimaryButton>
              <AdvancedFiltersButton
                onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
                type="button"
                disabled={props.isSubmitting}
              >
                {showAdvancedFilters
                  ? t('filter.hideAdvancedFilters')
                  : t('filter.showAdvancedFilters')}
              </AdvancedFiltersButton>
            </FlexButtonGroup>
          </FilterFormBody>
        </Expandable>
      </FilterForm>
    </>
  );
};

const withUserContextEnhancer = withUserContext();

const withTranslationsEnhancer = withTranslation('item');

const withFormikEnhancer = withFormik<
  OwnProps & WithUserContextProps & WithTranslation,
  DashboardFilterSelection
>({
  enableReinitialize: true,
  handleSubmit: (values, { props, setSubmitting }) => {
    props.setFilter({
      filename: values.filename,
      scheduledDate: values.scheduledDate,
      users: values.users,
      sites: values.sites,
    });
    setSubmitting(false);
  },
  mapPropsToValues: props => ({
    filename: props.existingFilters?.filename ?? null,
    scheduledDate: props.existingFilters?.scheduledDate ?? null,
    sites: props.existingFilters?.sites ?? null,
    users: props.existingFilters?.users ?? null,
  }),
  validate: (values, props) => {
    const validator = new ItemFilterFormValidator(props.t, props.user.locale);
    return validator.validate(values);
  },
});

const enhance = flowRight(withUserContextEnhancer, withTranslationsEnhancer, withFormikEnhancer);

export const DashboadFilterForm: React.ComponentType<OwnProps> = enhance(
  DashboardFilterFormComponent,
);

class ItemFilterFormValidator extends TranslatableValidator<DashboardFilterSelection> {
  constructor(t: TFunction, locale: Locale) {
    super(t, locale);
    this.ruleFor('filename')
      .maxLength(200)
      .withMessage(t('DashboadFilter.validation.searchText.length'));
  }
}
