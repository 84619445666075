import styled from 'styled-components';
import { CheckboxField } from '../../../shared/forms/CheckboxField';

const StyledCheckboxGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledCheckboxField = styled(CheckboxField)`
  display: flex;
  flex: 0 1 200px;
`;

const StyledHeaderDiv: React.ComponentType = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledContainer: React.ComponentType = styled.div`
  padding: ${props => props.theme.spacing.small}px;
  margin-top: ${props => props.theme.spacing.small}px;
  margin-bottom: ${props => props.theme.spacing.small}px;
  width: 100%;
  background-color: ${props => props.theme.colours.componentBackground};
`;

const SiteSpinnerWrapper = styled.div`
  position: relative;
`;

const SiteSpinner = styled.div`
  position: absolute;
  left: 60px;
`;

export {
  StyledCheckboxField,
  StyledCheckboxGroup,
  StyledHeaderDiv,
  StyledContainer,
  SiteSpinnerWrapper,
  SiteSpinner,
};
