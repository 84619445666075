import React from 'react';
import {
  FormFieldGroupContainer,
  FormGroupTitle,
  FormFieldGroup,
} from '../../../shared/forms/FormContainers';
import { useTranslation } from 'react-i18next';
import { LoginUserResponse, userHasOneOfPermissions } from '../../authentication/loginData/user';
import { permissions } from '../../authentication/permissions';
import { StyledCheckboxField, StyledCheckboxGroup } from '../styles/UserStyles';

type OwnProps = {
  currentUser: LoginUserResponse;
};

type Props = OwnProps;

export const UserRoles = (props: Props) => {
  const { currentUser } = props;
  const { t } = useTranslation(['user']);

  return (
    <FormFieldGroupContainer>
      <FormGroupTitle>{t('createOrEditForm.headings.roles')}</FormGroupTitle>
      <FormFieldGroup>
        <h4>{t('createOrEditForm.headings.permissions.central')}</h4>
        <StyledCheckboxGroup>
          <StyledCheckboxField
            name="userIsCentralUserAdmin"
            label={t('createOrEditForm.labels.userIsCentralUserAdmin')}
            disabled={
              !userHasOneOfPermissions(currentUser, [
                permissions.CentralUserAdmin,
                permissions.SuperAdmin,
              ])
            }
          />
          <StyledCheckboxField
            name="userIsCentralAdmin"
            label={t('createOrEditForm.labels.userIsCentralAdmin')}
            disabled={
              !userHasOneOfPermissions(currentUser, [
                permissions.CentralAdmin,
                permissions.SuperAdmin,
              ])
            }
          />
          <StyledCheckboxField
            name="userIsCentralView"
            label={t('createOrEditForm.labels.userIsCentralView')}
            disabled={
              !userHasOneOfPermissions(currentUser, [
                permissions.CentralView,
                permissions.SuperAdmin,
              ])
            }
          />
        </StyledCheckboxGroup>

        <h4>{t('createOrEditForm.headings.permissions.site')}</h4>
        <StyledCheckboxGroup>
          <StyledCheckboxField
            name="userIsSiteUserAdmin"
            label={t('createOrEditForm.labels.userIsSiteUserAdmin')}
            disabled={
              !userHasOneOfPermissions(currentUser, [
                permissions.SiteUserAdmin,
                permissions.SuperAdmin,
              ])
            }
          />
          <StyledCheckboxField
            name="userIsSitePriceAdmin"
            label={t('createOrEditForm.labels.userIsSitePriceAdmin')}
            disabled={
              !userHasOneOfPermissions(currentUser, [
                permissions.SitePriceAdmin,
                permissions.SuperAdmin,
              ])
            }
          />
          <StyledCheckboxField
            name="userIsSiteVisibilityAdmin"
            label={t('createOrEditForm.labels.userIsSiteVisibilityAdmin')}
            disabled={
              !userHasOneOfPermissions(currentUser, [
                permissions.SuperAdmin,
                permissions.SiteVisibilityAdmin,
              ])
            }
          />
        </StyledCheckboxGroup>

        <h4>{t('createOrEditForm.headings.permissions.deviations')}</h4>
        <StyledCheckboxGroup>
          <StyledCheckboxField
            name="userIsDeviationBulkUploadAdmin"
            label={t('createOrEditForm.labels.userIsDeviationBulkUploadAdmin')}
            disabled={!userHasOneOfPermissions(currentUser, [permissions.SuperAdmin])}
          />
        </StyledCheckboxGroup>

        <h4>{t('createOrEditForm.headings.permissions.super')}</h4>
        <StyledCheckboxGroup>
          <StyledCheckboxField
            name="userIsSuperAdmin"
            label={t('createOrEditForm.labels.userIsSuperAdmin')}
            disabled={!userHasOneOfPermissions(currentUser, [permissions.SuperAdmin])}
          />
        </StyledCheckboxGroup>
      </FormFieldGroup>
    </FormFieldGroupContainer>
  );
};
