import { FieldProps, FormikProps } from 'formik';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export type UseDebouncedFormikFieldConfig<TField> = FieldProps<TField> & {
  useDebouncing?: boolean;
  debouncingDelay?: number;
  onChange?: (value: TField, form: FormikProps<unknown>) => void;
};

type UseDebouncedFormikFieldResults<TField> = {
  value: TField;
  setValue: (value: TField) => void;
  cancelDebounce: () => void;
  flushDebounce: () => void;
};

export const useDebouncedFormikField = <TField>(
  config: UseDebouncedFormikFieldConfig<TField>,
): UseDebouncedFormikFieldResults<TField> => {
  const [inputValue, setInputValue] = useState<TField>(config.field.value);
  useEffect(() => {
    setInputValue(config.field.value);
  }, [config.field.value]);

  const setFieldValue = (value: TField) => {
    config.form.setFieldValue(config.field.name, value);
    if (config.onChange) {
      config.onChange(value, config.form);
    }
  };
  const setFieldValueDebounced = useCallback(
    debounce(setFieldValue, config.useDebouncing ? config.debouncingDelay || 1000 : 0),
    [],
  );
  const setValue = (value: TField) => {
    setInputValue(value);
    setFieldValueDebounced(value);
  };

  const cancelDebounce = setFieldValueDebounced.cancel;
  const flushDebounce = setFieldValueDebounced.flush;

  return { value: inputValue, setValue, cancelDebounce, flushDebounce };
};
