import * as React from 'react';
import { InputHTMLAttributes } from 'react';
import { useContext, useRef } from 'react';
import { UserContext } from '../../feature/authentication/loginData/userContext';
import { CurrencyCode } from '../../models/locale';
import { getCombinedCurrencySymbols, getCurrencySymbol } from '../../utils/currencyUtils';
import { BaseInputProps } from './BaseInputStyling';
import { LeftSymbolInput, LeftSymbolPanel, SymbolInputContainer } from './SymbolInput';

export type Props = BaseInputProps &
  InputHTMLAttributes<HTMLInputElement> & {
    currency?: CurrencyCode | Array<CurrencyCode>;
    isRightAligned?: boolean;
    onBlurIfChange?: React.FocusEventHandler<HTMLInputElement>;
  };

export const CurrencyInput = ({
  value,
  currency,
  onBlur,
  onFocus,
  onBlurIfChange,
  ...props
}: Props) => {
  const { user } = useContext(UserContext);
  const currentVisitRef = useRef<null | string>(null);

  const onComponentFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    currentVisitRef.current = event.target.value;
    onFocus?.(event);
  };

  const onComponentBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value !== currentVisitRef.current) {
      onBlurIfChange?.(event);
    }
    onBlur?.(event);
    currentVisitRef.current = null;
  };

  return (
    <SymbolInputContainer bulkVariant={props.bulkVariant}>
      <LeftSymbolPanel
        bulkVariant={props.bulkVariant}
        disabled={props.disabled}
        invalid={props.invalid}
        warning={props.warning}
        inputSize={props.inputSize}
      >
        <span>
          {Array.isArray(currency)
            ? getCombinedCurrencySymbols(user.locale, currency)
            : getCurrencySymbol(user.locale, currency ?? user.currency)}
        </span>
      </LeftSymbolPanel>
      <LeftSymbolInput
        {...props}
        onFocus={onComponentFocus}
        onBlur={onComponentBlur}
        value={value || ''}
        isRightAligned={props.isRightAligned}
        autoComplete={'off'}
      />
    </SymbolInputContainer>
  );
};
