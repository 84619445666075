import { RouteComponentProps } from '@reach/router';
import { TFunction } from 'i18next';
import { useContext } from 'react';
import * as React from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { BeTodayOrLater } from '../../../models/dates/dateTimeStamp';
import { getLocalNow } from '../../../models/dates/now';
import { Locale } from '../../../models/locale';
import { FileUploadForm } from '../../../shared/files/FileUploadForm';
import { CheckboxField } from '../../../shared/forms/CheckboxField';
import { DateField } from '../../../shared/forms/DateField';
import { InputField } from '../../../shared/forms/InputField';
import { IfUserHasPermission, RequiresOneOfPermissions } from '../../../shared/Permissions';
import { assertIsDefined } from '../../../utils/assertIsDefined';
import { TranslatableValidator } from '../../../utils/validation/TranslatableValidator';
import { InputMaximumLength } from '../../../utils/validation/validationConstants';
import { UserContext } from '../../authentication/loginData/userContext';
import { permissions } from '../../authentication/permissions';
import { uploadDeviationBulkEdit } from '../deviationsApi';
import { viewDeviationBulkEditUrl } from '../deviationsUrls';
import { UploadDeviationResponse } from './deviationBulkEdit';

export type UploadDeviationFormModel = {
  name: string | null;
  runImmediately: boolean;
  scheduledDateTime: string;
  forceUpload: boolean;
};

class Validator extends TranslatableValidator<UploadDeviationFormModel> {
  constructor(t: TFunction, locale: Locale) {
    super(t, locale);
    this.ruleFor('name')
      .maxLength(InputMaximumLength)
      .withMessage(t('deviation:validation.bulkEditName.length'));
    this.ruleFor('scheduledDateTime')
      .must(date => BeTodayOrLater(date, locale))
      .withMessage(t('deviation:validation.scheduledDate.invalid'))
      .when(form => !form.runImmediately);
  }
}

export const UploadDeviationBulkEdit = (props: RouteComponentProps) => {
  const { t } = useTranslation('deviations');
  const { user } = useContext(UserContext);

  return (
    <RequiresOneOfPermissions
      permissions={[permissions.SuperAdmin, permissions.DeviationBulkUploadAdmin]}
    >
      <Title>{t('uploadBulkEdit.title')}</Title>
      <h1>{t('uploadBulkEdit.heading')}</h1>
      <FileUploadForm<UploadDeviationFormModel, UploadDeviationResponse>
        initialValues={{
          name: null,
          runImmediately: false,
          scheduledDateTime: getLocalNow(user.locale).toISODate(),
          forceUpload: false,
        }}
        validate={values => {
          const validator = new Validator(t, user.locale);
          return validator.validate(values);
        }}
        filePropertyName="file"
        fileFieldLabel={t('uploadBulkEdit.file')}
        request={uploadDeviationBulkEdit}
        onSuccess={response => {
          assertIsDefined(props.navigate)(
            viewDeviationBulkEditUrl(assertIsDefined(response.deviationBulkEditId)),
          );
        }}
        additionalWarningText={t('uploadBulkEdit.processingWarning')}
        renderAdditionalFormFields={formikProps => (
          <>
            <CheckboxField
              name="runImmediately"
              label={t('uploadBulkEdit.runImmediately.label')}
              infoText={t('uploadBulkEdit.runImmediately.infoText')}
            />

            <InputField
              name="name"
              label={t('uploadBulkEdit.bulkEditName')}
              placeholder={t('uploadBulkEdit.optionalDesciption')}
            />

            <IfUserHasPermission permission={permissions.SuperAdmin}>
              <CheckboxField
                name="forceUpload"
                label={t('uploadBulkEdit.forceUpload')}
                infoText={t('uploadBulkEdit.forceUploadMessage')}
              />
            </IfUserHasPermission>
            {!formikProps.values.runImmediately && (
              <DateField
                name="scheduledDateTime"
                label={t('uploadBulkEdit.scheduledDate.label')}
                infoText={t('uploadBulkEdit.scheduledDate.infoText')}
              />
            )}
          </>
        )}
      />
    </RequiresOneOfPermissions>
  );
};
