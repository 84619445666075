import { FormikActions, FormikBag, FormikProps, FormikValues, withFormik } from 'formik';
import { TFunction } from 'i18next';
import { flowRight, isEmpty } from 'lodash';
import * as React from 'react';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { AuthenticatedApiRequest } from '../../models/apiRequest';
import { ApiError } from '../../models/error';
import { Locale } from '../../models/locale';
import { TranslatableValidator } from '../../utils/validation/TranslatableValidator';
import { ApiErrorBox } from '../errors/ApiErrorBox';
import { ApiRequestProps, withApiRequest } from './withApiRequest';
import { withUserContext, WithUserContextProps } from './withUserContext';

type ValidationConfig<TFormObject> = {
  validator: new (t: TFunction, locale: Locale) => TranslatableValidator<TFormObject>;
  translationNamespace: string;
};

export type FormSubmitConfig<TOwnProps, TFormObject, TResponse, TRequestParams> = {
  request: AuthenticatedApiRequest<TResponse, TRequestParams>;
  onSubmitComplete: (
    props: TOwnProps,
    response: TResponse,
    formikActions: FormikActions<TFormObject>,
  ) => void;
  mapPropsToValues?: (props: TOwnProps & WithUserContextProps) => TFormObject;
  mapValuesToRequestParameters: (
    props: TOwnProps & WithUserContextProps,
    formModel: TFormObject,
  ) => TRequestParams;
  validationConfig?: ValidationConfig<TFormObject>;
  enableReinitialize?: boolean;
};

// This will setup Formik for the wrapped component as well as taking in where to submit the form to and what to do when
// submission is complete
//
// Takes a config object with the following properties:
// -  request: The API request to use to submit the form values to
// -  onSubmitComplete: A function that takes the wrapped components OwnProps and the response from the API that will be
//      called when the form is submitted successfully
// -  mapPropsToValues: Optional - Passed through to withFormik,
//      see https://jaredpalmer.com/formik/docs/api/withformik#mappropstovalues-props-props-values for more details
// -  mapValuesToRequestParameters: - A function that translates the form model into the request params object
//      useful for parsing numeric inputs, or converting empty optional fields to null values
// -  validationConfig: Optional - If define takes a validator, which should be a class that extends TranslatableValidator
//      and a translationNamespace where the namespace to be used for the validation error translations is defined
export const withFormSubmit = <
  TOwnProps,
  TFormObject extends FormikValues,
  TResponse,
  TRequestParams
>(
  config: FormSubmitConfig<TOwnProps, TFormObject, TResponse, TRequestParams>,
) => (
  WrappedComponent: React.ComponentType<
    TOwnProps & ApiRequestProps<TResponse, TRequestParams> & FormikProps<TFormObject>
  >,
) => {
  class FormWrapper extends Component<
    TOwnProps & ApiRequestProps<TResponse, TRequestParams> & FormikProps<TFormObject>
  > {
    render() {
      const apiError: ApiError | null = this.props.apiError;

      return (
        <>
          <WrappedComponent {...this.props} />
          {apiError && <ApiErrorBox error={apiError} />}
        </>
      );
    }
  }

  const withApiRequestEnhancer = withApiRequest<TOwnProps, TResponse, TRequestParams>(
    config.request,
  );

  const withUserContextEnhancer = withUserContext<
    TOwnProps & ApiRequestProps<TResponse, TRequestParams>
  >();

  const withTranslationsEnhancer = withTranslation(
    config.validationConfig ? config.validationConfig.translationNamespace : undefined,
  );

  type OwnFormikProps = TOwnProps &
    ApiRequestProps<TResponse, TRequestParams> &
    WithTranslation &
    WithUserContextProps;
  const withFormikEnhancer = withFormik<OwnFormikProps, TFormObject>({
    mapPropsToValues: config.mapPropsToValues,
    handleSubmit: (values, formikBag: FormikBag<OwnFormikProps, TFormObject>) => {
      formikBag.props
        .makeRequest(config.mapValuesToRequestParameters(formikBag.props, values))
        .then(response => {
          if (response) {
            config.onSubmitComplete(formikBag.props, response, formikBag);
          }
        })
        .finally(() => formikBag.setSubmitting(false));
    },
    enableReinitialize: config.enableReinitialize,
    validate: (values, props) => {
      if (config.validationConfig) {
        const validator = new config.validationConfig.validator(props.t, props.user.locale);
        const validationErrors = validator.validate(values);
        if (!isEmpty(validationErrors)) {
          // tslint:disable-next-line:no-console this makes it easier to debug failing tests
          console.warn('Validation errors:', validationErrors);
        }
        return validationErrors;
      }
    },
  });

  const enhance = flowRight(
    withApiRequestEnhancer,
    withUserContextEnhancer,
    withTranslationsEnhancer,
    withFormikEnhancer,
  );

  return enhance(FormWrapper);
};
