import { RouteComponentProps } from '@reach/router';
import { keyBy, orderBy } from 'lodash';
import * as React from 'react';
import { useContext } from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { ById } from '../../models/id';
import { HollowLinkButton } from '../../shared/buttons/LinkButton';
import { DataLoaderWithParams } from '../../shared/DataLoader';
import { HasOneOfPermissions, RequiresOneOfPermissions } from '../../shared/Permissions';
import { DataFetchTable } from '../../shared/tables/Table';
import { styled } from '../../styling/theme';
import { UserContext } from '../authentication/loginData/userContext';
import { permissions } from '../authentication/permissions';
import { PageHeading } from '../layout/PageHeading';
import { PracticeGroupResponse } from '../sites/practiceGroups/practiceGroup';
import { getAllPracticeGroups } from '../sites/practiceGroups/practiceGroupsApi';
import { AccessibleSiteResponse, GetAccessibleSiteCollectionQuery } from '../sites/site';
import { getAccessibleSites } from '../../api/sitesApi';
import { UsersResponse } from './user';
import { getUserColumns, usersPageSize, useUserTableBaseState } from './UsersBase';
import { UsersFilterForm } from './UsersFilterForm';
import { createUserUrl } from './userUrls';

type OwnProps = RouteComponentProps & {};
type Props = OwnProps;

type UsersTableProps = {
  sitesById: ById<AccessibleSiteResponse>;
  practiceGroupsById: ById<PracticeGroupResponse>;
};

const FullWidthTableContainer = styled.div`
  min-width: 750px;
`;

const mapUsersResponseToTableData = (response: UsersResponse) => response.users;

const UsersComponent = (props: UsersTableProps) => {
  const { t } = useTranslation(['user', 'metadata']);

  const {
    getFilteredData,
    onFilteredResponse,
    filterOptions,
    setFilterOptions,
  } = useUserTableBaseState({
    sitesById: props.sitesById,
    practiceGroupsById: props.practiceGroupsById,
  });

  const columns = getUserColumns(t);

  return (
    <RequiresOneOfPermissions
      permissions={[
        permissions.SuperAdmin,
        permissions.CentralUserAdmin,
        permissions.SiteUserAdmin,
      ]}
    >
      <Title>{t('userList.title')}</Title>
      <PageHeading>
        <h1>{t('userList.heading')}</h1>
        <HasOneOfPermissions
          permissions={[
            permissions.SuperAdmin,
            permissions.CentralUserAdmin,
            permissions.SiteUserAdmin,
          ]}
        >
          {hasPermission => {
            if (hasPermission) {
              return (
                <HollowLinkButton to={createUserUrl()}>
                  {t('userList.createButton')}
                </HollowLinkButton>
              );
            }
          }}
        </HasOneOfPermissions>
      </PageHeading>
      <UsersFilterForm
        sitesById={props.sitesById}
        practiceGroupsById={props.practiceGroupsById}
        existingFilters={filterOptions}
        onApply={setFilterOptions}
      />
      <FullWidthTableContainer>
        <DataFetchTable
          columns={columns}
          getApiData={getFilteredData}
          pageSize={usersPageSize}
          mapResponseToTableData={mapUsersResponseToTableData}
          onDataFetchSuccess={onFilteredResponse}
          emptyTableMessage={t('userList.emptyTableMessage')}
        />
      </FullWidthTableContainer>
    </RequiresOneOfPermissions>
  );
};

export const Users = (props: Props) => {
  const { user } = useContext(UserContext);

  return (
    <DataLoaderWithParams
      apiRequest={getAccessibleSites}
      getParams={() => {
        return {
          organisationGroupIds: user.userOrganisations.map(x => x.id),
        } as GetAccessibleSiteCollectionQuery;
      }}
    >
      {sitesRequest => (
        <DataLoaderWithParams
          apiRequest={getAllPracticeGroups}
          getParams={() => user.organisationGroupId}
        >
          {practiceGroupsRequest => {
            const sitesById = keyBy(orderBy(sitesRequest.response.sites, 'siteName'), 'siteId');
            const practiceGroupsById = keyBy(
              orderBy(practiceGroupsRequest.response.practiceGroups, 'name'),
              'practiceGroupId',
            );
            return <UsersComponent sitesById={sitesById} practiceGroupsById={practiceGroupsById} />;
          }}
        </DataLoaderWithParams>
      )}
    </DataLoaderWithParams>
  );
};
