import { PushReplaceHistory } from 'use-query-params';
import {
  createHistoryWithNavigationConfirmation,
  withSuppressNavigationWarningState,
} from './historyWithNavigationConfirmation';

export const navigationConfirmationHistory = createHistoryWithNavigationConfirmation();

export const appHistory = navigationConfirmationHistory.history;

export const navigate = (
  to: string,
  options: { suppressUserConfirmation?: boolean; replace?: boolean; state?: unknown } = {},
) => {
  navigationConfirmationHistory.history.navigate(to, {
    state: withSuppressNavigationWarningState(
      options.state || {},
      options.suppressUserConfirmation != null ? options.suppressUserConfirmation : false,
    ),
    replace: options && options.replace,
  });
};

// Adapted from https://github.com/pbeshai/use-query-params/blob/c5fa7c48572b5473f24753e20b57c92ee6c2de79/src/QueryParamProvider.tsx#L58
// to support hooking into suppressNavigationWarningState. As the package doesn't support passing our own state,
// the by product is suppressing navigation warnings for all Use-Query-Param navigations
export const pushReplaceHistoryForUseQueryParams: PushReplaceHistory = {
  replace: (location: Location) => {
    navigate(`${location.protocol}//${location.host}${location.pathname}${location.search}`, {
      replace: true,
      suppressUserConfirmation: true,
      state: window.history.state,
    });
  },
  push: (location: Location) => {
    navigate(`${location.protocol}//${location.host}${location.pathname}${location.search}`, {
      replace: false,
      suppressUserConfirmation: true,
      state: window.history.state,
    });
  },
};
