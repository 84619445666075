import { RouteComponentProps } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useState } from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DeleteConfirmationModal } from './components/DeleteItemConfirmationModal';
import { DeleteSupplierProductForm, ObsoleteSupplierProduct } from './DeleteSupplierItems.types';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  deleteSupplierProductRequest,
  getSupplierProductNotOnCurrentSupplierList,
} from '../../../items/ItemSupplierProducts/itemSupplierProductsApi';
import { AuthenticationContext } from '../../../authentication/authenticationContext';
import { ToastOptions, toast } from 'react-toastify';
import { ApiError } from '../../../../models/error';
import { defaultValues, schema } from './DeleteSupplierItems.schema';

const toastOptions: ToastOptions = {
  position: 'bottom-center',
  autoClose: 4000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: 'colored',
};

const setSuccessToast = (message: string) => {
  toast.success(message, toastOptions);
};

const setErrorToast = (message: string) => {
  toast.error(message, toastOptions);
};

export const DeleteSupplierItems = (props: RouteComponentProps): React.ReactElement => {
  const [itemCode, setItemCode] = useState<string>('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<ObsoleteSupplierProduct | null>(null);

  const authenticationContext = useContext(AuthenticationContext);
  const { t } = useTranslation('item');

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    watch,
  } = useForm<DeleteSupplierProductForm>({
    defaultValues,
    resolver: zodResolver(schema), // validation schema
  });

  const searchInput = watch('code');

  // Query to get items
  const { data, refetch: refetchItems, isFetching: isFetchingItems } = useQuery(
    ['getSupplierProductNotOnCurrentSupplierList', JSON.stringify(itemCode)],
    {
      queryFn: async ({ signal }) => {
        const bearerToken = await authenticationContext.getAccessToken();
        return getSupplierProductNotOnCurrentSupplierList(itemCode, bearerToken, signal);
      },
      enabled: itemCode.length > 0,
      staleTime: Infinity,

      // Axios interceptor will convert all errors to ApiError inside apiHttpClient.ts
      onError: (error: ApiError) => {
        setErrorToast(error.message);
      },
    },
  );

  // Mutation to delete an item
  const { mutate: deleteItem, isLoading: isDeletingItem } = useMutation({
    mutationFn: async () => {
      if (itemToDelete === null) {
        throw new Error(t('deleteSupplierItems.error.itemToDeleteNull'));
      }
      const bearerToken = await authenticationContext.getAccessToken();
      return deleteSupplierProductRequest(itemToDelete.id, bearerToken);
    },
    onSuccess() {
      setItemToDelete(null);
      refetchItems();
      setSuccessToast(t('deleteSupplierItems.toast.success'));
    },
    onError(error: ApiError) {
      setErrorToast(error.message);
    },
  });

  const onSubmit: SubmitHandler<DeleteSupplierProductForm> = ({ code }) => {
    setItemCode(code);
  };

  const handleDeleteClick = (item: ObsoleteSupplierProduct) => {
    setItemToDelete(item);
    setIsDeleteModalOpen(true);
  };

  return (
    <>
      <div>
        {/* HEADING AND FORM DESCRIPTION */}
        <h1 className="mb-8">{t('deleteSupplierItems.heading')}</h1>
        <p className="mb-8">{t('deleteSupplierItems.copy')}</p>
      </div>

      <div className="flex gap-4">
        {/* SEARCH */}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="self-start bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-3/12"
        >
          <div className="mb-4">
            <label htmlFor="codes" className="mb-3">
              {t('deleteSupplierItems.form.label')}
            </label>
            <input
              {...register('code')}
              className="shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            {/* ERROR MESSAGE */}
            {formErrors.code && (
              <p
                className="p-2 mb-3 mt-3 text-sm text-yellow-800 rounded-lg bg-yellow-50 font-medium"
                role="alert"
              >
                {formErrors.code.message}
              </p>
            )}
          </div>

          <input
            type="submit"
            disabled={!searchInput || isFetchingItems || isDeletingItem}
            value={t('deleteSupplierItems.buttons.search')}
            className={`${
              !searchInput || isFetchingItems || isDeletingItem
                ? 'bg-blue-400 cursor-not-allowed'
                : 'bg-blue-500 hover:bg-blue-700'
            }  py-2 px-4 rounded focus:outline-none focus:shadow-outline  font-bold text-white`}
          />
        </form>

        {/* TABLE OF ITEMS */}
        {!!data && data.length > 0 && (
          <div className="flex-grow-1">
            <div className="relative overflow-auto shadow-md mb-4" style={{ maxHeight: '50vh' }}>
              <table className="w-full text-left rtl:text-right text-gray-500 mb-4">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 sticky top-0">
                  <tr>
                    <th scope="col" className="px-6 py-2">
                      {t('deleteSupplierItems.table.columns.name')}
                    </th>
                    <th scope="col" className="px-6 py-2">
                      {t('deleteSupplierItems.table.columns.itemCode')}
                    </th>
                    <th scope="col" className="px-6 py-2">
                      {t('deleteSupplierItems.table.columns.supplier')}
                    </th>
                    <th scope="col" className="px-6 py-2 w-40">
                      {t('deleteSupplierItems.table.columns.createdBySupplierList')}
                    </th>
                    <th scope="col" className="px-6 py-2 w-40">
                      {t('deleteSupplierItems.table.columns.isOnCurrentSupplierList')}
                    </th>
                    <th scope="col" className="px-6 py-2">
                      {t('deleteSupplierItems.table.columns.delete')}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {data.map(item => (
                    <tr
                      key={item.id}
                      id={`row-${item.id}`}
                      // highlight any selected rows, else alternate row colours
                      className="odd:bg-gray-100 even:bg-white border-b"
                    >
                      <td className="px-6 py-2>">{item.name}</td>
                      <th scope="row" className={`px-6 py-2 font-medium whitespace- text-gray-900`}>
                        {item.code}
                      </th>
                      <td className="px-6 py-2>">{item.supplier}</td>
                      <td className="px-6 py-2">
                        {item.createdBySupplierList
                          ? t('deleteSupplierItems.table.yes')
                          : t('deleteSupplierItems.table.no')}
                      </td>
                      <td className="px-6 py-2">
                        {item.isOnCurrentSupplierList
                          ? t('deleteSupplierItems.table.yes')
                          : t('deleteSupplierItems.table.no')}
                      </td>
                      <td>
                        <div className="flex justify-center">
                          <button
                            disabled={isDeletingItem}
                            aria-label={t('deleteSupplierItems.buttons.deleteButtonLabel')}
                            onClick={() => handleDeleteClick(item)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {isDeleteModalOpen && (
              <DeleteConfirmationModal
                isOpen={isDeleteModalOpen}
                onClose={() => {
                  setIsDeleteModalOpen(false);
                  setItemToDelete(null);
                }}
                onConfirm={() => {
                  if (itemToDelete !== null) {
                    deleteItem();
                    setIsDeleteModalOpen(false);
                  }
                }}
                item={itemToDelete}
              />
            )}
          </div>
        )}

        {/* NO-ITEMS FOUND MESSAGE */}
        {data && data.length === 0 && (
          <p className="mt-3 font-bold text-xl">{t('deleteSupplierItems.error.noItemsFound')}</p>
        )}
      </div>
    </>
  );
};
