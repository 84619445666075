import { ThemeProps } from 'styled-components';
import { css, styled, Theme } from '../../styling/theme';
import { BaseInputProps, BaseInputStyling } from './BaseInputStyling';

export type SymbolInputProps = BaseInputProps & {
  isRightAligned?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

export type SymbolInputContainerProps = React.HTMLAttributes<HTMLInputElement> & BaseInputProps;

export type SymbolPanelProps = { disabled?: boolean } & BaseInputProps &
  React.HTMLAttributes<HTMLDivElement>;

const symbolPanelWidth = 45;
const symbolPanelWidthBulkVariant = 30;

const getSymbolPanelWidth = (props: SymbolPanelProps): number => {
  if (props.bulkVariant) {
    return symbolPanelWidthBulkVariant;
  } else {
    return props.inputSize === 'small' ? symbolPanelWidthBulkVariant : symbolPanelWidth;
  }
};

const BaseSymbolPanelStyling = css<SymbolPanelProps>`
  ${BaseInputStyling};
  background-color: ${props => props.theme.colours.secondaryInactive};
  min-width: ${props => getSymbolPanelWidth(props)}px;
  width: auto;
  flex: 0 0 auto;
  font-weight: bold;
  display: flex;
  justify-content: center;

  ${props => {
    if (props.disabled) {
      return css`
        color: ${props.theme.colours.appBackground};
        border-color: ${props.theme.colours.appBackground};
        border-style: ${props.bulkVariant && 'dashed'};
      `;
    }
  }}
`;

export const LeftSymbolPanel = styled.div<SymbolPanelProps>`
  ${BaseSymbolPanelStyling};
  border-right: ${props => (props.bulkVariant ? 'default' : 'none')};
  border-radius: ${props => props.theme.spacing.tiny}px 0 0 ${props => props.theme.spacing.tiny}px;
`;

export const RightSymbolPanel = styled.div<SymbolPanelProps>`
  ${BaseSymbolPanelStyling};
  border-left: ${props => (props.bulkVariant ? 'default' : 'none')};
  border-radius: 0 ${props => props.theme.spacing.tiny}px ${props => props.theme.spacing.tiny}px 0;
`;

const getSymbolInputWidth = (props: BaseInputProps & ThemeProps<Theme>) => {
  if (props.bulkVariant) {
    return '100%';
  } else {
    return `${
      props.inputSize === 'small'
        ? props.theme.formInputWidth.small - getSymbolPanelWidth(props)
        : props.theme.formInputWidth.medium - getSymbolPanelWidth(props)
    }px`;
  }
};

const SymbolInputStyling = css<SymbolInputProps>`
  ${BaseInputStyling};
  max-width: 100%;
  flex-grow: 1;
  width: ${props => getSymbolInputWidth(props)};
  text-align: ${props => (props.isRightAligned ? 'right' : 'left')};
`;

export const LeftSymbolInput = styled.input<SymbolInputProps>`
  ${SymbolInputStyling};
  border-radius: 0 ${props => props.theme.spacing.tiny}px ${props => props.theme.spacing.tiny}px 0;
}
`;

export const RightSymbolInput = styled.input<SymbolInputProps>`
  ${SymbolInputStyling};
  border-radius: ${props => props.theme.spacing.tiny}px 0 0 ${props => props.theme.spacing.tiny}px;
}
`;

export const SymbolInputContainer = styled.div<SymbolInputContainerProps>`
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  max-width: ${props => props.theme.formInputWidth.max}px;

  &:focus-within > ${LeftSymbolPanel}, &:focus-within > ${RightSymbolPanel} {
    color: ${props => props.theme.colours.secondary};
    border-style: ${props => props.bulkVariant && 'dashed'};
    border-color: ${props => props.theme.colours.secondary};
    background-color: ${props => props.theme.colours.componentBackground};
  }
`;
