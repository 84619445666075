import { Form, FormikProps } from 'formik';
import { isEmpty, map } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../shared/buttons/Button';
import { FormValidationErrorBox } from '../../shared/errors/FormValidationErrorBox';
import { InputField } from '../../shared/forms/InputField';
import { SingleSelectDropdownField } from '../../shared/forms/SingleSelectDropdownField';
import { ApiRequestProps } from '../../shared/higher-order-components/withApiRequest';
import { withFormSubmit } from '../../shared/higher-order-components/withFormSubmit';
import { SuccessBox } from '../../shared/success/SuccessBox';
import { navigate } from '../../utils/routing';
import { CountryResponse } from '../authentication/loginData/metadata';
import {
  CreateOrEditOrganisationGroupCommandWrapper,
  CreateOrEditOrganisationGroupFormModel,
  CreateOrEditOrganisationGroupFormModelValidator,
  OrganisationGroupResponse,
} from './organisationGroup';
import { createOrEditOrganisationGroup } from './organisationGroupsApi';
import { editOrganisationUrl } from './organisationGroupUrls';

type OwnProps = {
  countries: Array<CountryResponse>;
  existingOrganisationGroup?: OrganisationGroupResponse;
  showSuccess?: boolean;
  onSuccess?: (response: OrganisationGroupResponse) => void;
};
type Props = OwnProps &
  ApiRequestProps<OrganisationGroupResponse, CreateOrEditOrganisationGroupCommandWrapper> &
  FormikProps<CreateOrEditOrganisationGroupFormModel>;

const OrganisationGroupFormComponent = (props: Props) => {
  const { t } = useTranslation(['organisation', 'metadata']);

  const countryOptions = map(props.countries, country => ({
    displayText: t(`metadata:${country.translationKey}`),
    value: country.code,
  }));

  return (
    <Form>
      <InputField name="organisationName" label={t('createAndEditForm.labels.name')} />
      <SingleSelectDropdownField
        name="organisationCountryCode"
        label={t('createAndEditForm.labels.country')}
        options={countryOptions}
      />
      <InputField
        name="azureAdTenantName"
        label={t('createAndEditForm.labels.azureAdTenantName')}
        infoText={t('createAndEditForm.infoText.azureAdTenantName')}
      />
      <InputField
        name="merlinConnectionName"
        label={t('createAndEditForm.labels.merlinConnectionName')}
        infoText={t('createAndEditForm.infoText.merlinConnectionName')}
      />
      <PrimaryButton type="submit" disabled={props.isSubmitting}>
        {t('createAndEditForm.labels.button')}
      </PrimaryButton>
      <FormValidationErrorBox errors={props.errors} touched={props.touched} />
      <SuccessBox
        message={t('createAndEditForm.success')}
        showSuccess={
          !!props.showSuccess && !props.isSubmitting && !props.apiError && isEmpty(props.touched)
        }
      />
    </Form>
  );
};

const enhance = withFormSubmit<
  OwnProps,
  CreateOrEditOrganisationGroupFormModel,
  OrganisationGroupResponse,
  CreateOrEditOrganisationGroupCommandWrapper
>({
  enableReinitialize: true,
  request: createOrEditOrganisationGroup,
  onSubmitComplete: (props, response) => {
    if (props.onSuccess) {
      props.onSuccess(response);
    }
    navigate(editOrganisationUrl(response.organisationId), {
      state: { success: true },
    });
  },
  mapPropsToValues: props => ({
    organisationId: props.existingOrganisationGroup
      ? props.existingOrganisationGroup.organisationId
      : null,
    organisationName: props.existingOrganisationGroup
      ? props.existingOrganisationGroup.organisationName
      : '',
    organisationCountryCode: props.existingOrganisationGroup
      ? props.existingOrganisationGroup.organisationCountryCode
      : '',
    merlinConnectionName: props.existingOrganisationGroup
      ? props.existingOrganisationGroup.merlinConnectionName
      : '',
    azureAdTenantName: props.existingOrganisationGroup
      ? props.existingOrganisationGroup.azureAdTenantName
      : '',
  }),
  mapValuesToRequestParameters: (props, formModel) => ({
    command: {
      ...formModel,
      merlinConnectionName:
        formModel.merlinConnectionName === '' ? null : formModel.merlinConnectionName,
    },
    createOrEditTag: props.existingOrganisationGroup ? 'edit' : 'create',
  }),
  validationConfig: {
    translationNamespace: 'organisation',
    validator: CreateOrEditOrganisationGroupFormModelValidator,
  },
});

export const OrganisationGroupForm: React.FunctionComponent<OwnProps> = enhance(
  OrganisationGroupFormComponent,
);
