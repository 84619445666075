import * as React from 'react';
import { FixedSizeList } from 'react-window';
import { CurrencyCode } from '../../../models/locale';
import { styled } from '../../../styling/theme';
import { formatCurrency } from '../../../utils/currencyUtils';
import { SupplierProduct } from '../../items/ItemSupplierProducts/supplierProduct';
import { getSelectedCodes, SelectedCodes } from './select-products/selectedCodes';
import './supplierlistitems.css';

type ApproveNewSupplierListItemsProps = {
  newProducts: Array<SupplierProduct>;
  approvedNewProductCodes: SelectedCodes;
  locale: string;
  currency: CurrencyCode | Array<CurrencyCode>;
};

const DisplayValue = styled.span`
  padding-left: 10px;
`;
export const ApproveNewSupplierListItems = (props: ApproveNewSupplierListItemsProps) => {
  const selectedItems = getSelectedCodes(props.approvedNewProductCodes);
  const selectedNewItems = props.newProducts.filter((value, index) => {
    return selectedItems.includes(value.code);
  });
  const [change, setChange] = React.useState(false);
  const formatPrice = (price: number | undefined): string =>
    formatCurrency(price ? price : 0, props.locale, props.currency);
  const toDisplayValue = (value: string | number | null | undefined) =>
    value ? value.toString() : '';

  const handleInputChange = (index: number) => {
    props.approvedNewProductCodes[selectedNewItems[index].code] = !props.approvedNewProductCodes[
      selectedNewItems[index].code
    ];

    if (index > -1) {
      selectedNewItems.splice(index, 1);
    }

    setChange(!change);
  };

  const displayRow = ({ index, style }: { index: number; style: React.CSSProperties }) => {
    if (props.approvedNewProductCodes[selectedNewItems[index].code]) {
      return (
        <div style={style}>
          <input
            type="checkbox"
            checked={props.approvedNewProductCodes[selectedNewItems[index].code]}
            onChange={() => {
              handleInputChange(index);
            }}
          />
          <DisplayValue>{toDisplayValue(selectedNewItems[index].code)}</DisplayValue>
          <DisplayValue>{toDisplayValue(selectedNewItems[index].name)}</DisplayValue>
          <DisplayValue>{toDisplayValue(selectedNewItems[index].quantity)}</DisplayValue>
          <DisplayValue>{formatPrice(selectedNewItems[index].price)}</DisplayValue>
        </div>
      );
    } else {
      // increment offset
      return <></>;
    }
  };

  return (
    <>
      <FixedSizeList
        className="virtualsupplierlist"
        height={250}
        itemCount={selectedNewItems.length}
        itemSize={25}
        width={'100%'}
      >
        {displayRow}
      </FixedSizeList>
    </>
  );
};
