import { FormikProps } from 'formik';
import { sortBy } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxField } from '../../shared/forms/CheckboxField';
import {
  FormFieldGroup,
  FormFieldGroupContainer,
  FormFieldSubSection,
  FormGroupTitle,
} from '../../shared/forms/FormContainers';
import { InputField } from '../../shared/forms/InputField';
import { MultiSelectDropdownField } from '../../shared/forms/MultiSelectDropdownField';
import { SingleSelectDropdownField } from '../../shared/forms/SingleSelectDropdownField';
import { TextAreaField } from '../../shared/forms/TextAreaField';
import { DispensingLabelEdit } from './DispensinglabelEdit';
import { CreateOrEditItemFormModel, ItemResponse } from './item';
import { NotesEdit } from './NotesEdit';
import {
  ItemFieldsFromPmsResponse,
  itemLibraryDropdownOptions,
  reminderDeltaOptions,
} from './PmsFields/pmsFields';
import { ReminderTypeGroup } from './ReminderTypeGroup';

type TreatmentDetailsGroupProps = {
  itemFieldsFromPms: ItemFieldsFromPmsResponse;
  existingItem?: ItemResponse;
  formikProps: FormikProps<CreateOrEditItemFormModel>;
  isServiceType: boolean;
};

export const TreatmentDetailsGroup = (props: TreatmentDetailsGroupProps) => {
  const { t } = useTranslation('item');

  return (
    <FormFieldGroupContainer>
      <FormGroupTitle>{t('itemLabels.treatmentDetailsGroupTitle')}</FormGroupTitle>
      <FormFieldGroup>
        <SingleSelectDropdownField
          name="hcpGroupId"
          label={t('createAndEditForm.labels.hcpGroup')}
          options={itemLibraryDropdownOptions(props.itemFieldsFromPms.hcpGroupOptions)}
          onChange={(value, form) => {
            if (value === null) {
              form.setFieldValue('hcpMonthlyMultiplier', null);
            }
          }}
          deselectable={true}
        />
        {props.formikProps.values.hcpGroupId !== null && (
          <InputField
            name="hcpMonthlyMultiplier"
            label={t('createAndEditForm.labels.hcpMonthlyMultiplier')}
            type="number"
          />
        )}
        <SingleSelectDropdownField
          name="speciesId"
          label={t('itemLabels.species')}
          options={itemLibraryDropdownOptions(props.itemFieldsFromPms.speciesOptions)}
          deselectable={true}
        />
        <SingleSelectDropdownField
          name="clientCategoryId"
          label={t('itemLabels.clientCategory')}
          options={itemLibraryDropdownOptions(props.itemFieldsFromPms.clientCategoryOptions)}
        />
        <CheckboxField
          name="requestLabel"
          label={t('itemLabels.labelRequired')}
          labelPosition={'right'}
        />
        {props.formikProps.values.requestLabel && (
          <FormFieldSubSection>
            <CheckboxField
              name="labelCalcQuantity"
              label={t('itemLabels.labelCalcQuantity')}
              labelPosition={'right'}
            />
            <TextAreaField name="labelAdditionalInfo" label={t('itemLabels.labelAdditionalInfo')} />
            <DispensingLabelEdit
              availableNotes={props.itemFieldsFromPms.dispensingLabelOptions}
              itemsNotes={
                props.existingItem?.labelDispensingNote == null
                  ? []
                  : sortBy(
                      props.existingItem.labelDispensingNote.filter(note => note.viewOrder !== 0),
                      note => note.viewOrder,
                    )
              }
            />
          </FormFieldSubSection>
        )}

        <SingleSelectDropdownField
          name="documentTemplateId"
          label={t('itemLabels.documentTemplate')}
          options={itemLibraryDropdownOptions(props.itemFieldsFromPms.documentTemplateOptions)}
          deselectable={true}
        />

        <TextAreaField
          name="internalInformation"
          label={t('createAndEditForm.labels.internalInformation')}
        />

        <ReminderTypeGroup
          reminderDeltaOptions={reminderDeltaOptions}
          reminderTypeOptions={props.itemFieldsFromPms.reminderTypeOptions}
          formikProps={props.formikProps}
        />

        {!props.isServiceType && (
          <SingleSelectDropdownField
            name="manufacturerId"
            label={t('createAndEditForm.labels.manufacturer')}
            options={itemLibraryDropdownOptions(props.itemFieldsFromPms.manufacturerOptions)}
            deselectable={true}
          />
        )}

        <MultiSelectDropdownField
          name="sendTo"
          label={t('createAndEditForm.labels.sendTo')}
          options={itemLibraryDropdownOptions(props.itemFieldsFromPms.sendToOptions)}
        />

        <TextAreaField name="notes" label={t('createAndEditForm.labels.notes')} />
      </FormFieldGroup>
    </FormFieldGroupContainer>
  );
};

type AdditionalTreatmentDetailsGroupProps = {
  existingItem?: ItemResponse;
  isServiceType: boolean;
  formikProps: FormikProps<CreateOrEditItemFormModel>;
  locale: string
};

export const AdditionalTreatmentDetailsGroup = (props: AdditionalTreatmentDetailsGroupProps) => {
  const { t } = useTranslation('item');

  return (
    <FormFieldGroupContainer>
      <FormGroupTitle />
      <FormFieldGroup>
        <CheckboxField
          name="preventHide"
          label={t('itemLabels.preventHide')}
          labelPosition={'right'}
        />
        <CheckboxField
          name="wholepackPriceEnabled"
          label={t('itemLabels.wholepackPriceEnabled')}
          labelPosition={'right'}
        />

        <CheckboxField
          name="wholepackExcludeDispFee"
          label={t('itemLabels.wholepackExcludeDispFee')}
          labelPosition={'right'}
        />

        <CheckboxField
          name="isMultiDispensingFee"
          label={t('itemLabels.isMultiDispensingFee')}
          labelPosition={'right'}
        />
        {!props.existingItem && (
          <CheckboxField
            name="isHiddenForAllPractices"
            label={t('itemLabels.isHiddenForAllPractices')}
            labelPosition={'right'}
          />
        )}
        <CheckboxField name="flea" label={t('itemLabels.flea')} labelPosition={'right'} />
        <CheckboxField
          name="shouldAlterSex"
          label={t('itemLabels.shouldAlterSex')}
          labelPosition={'right'}
        />
        <CheckboxField name="wormer" label={t('itemLabels.wormer')} labelPosition={'right'} />
        {!props.isServiceType && (
          <CheckboxField
            name="shouldSellAtCost"
            label={t('itemLabels.shouldSellAtCost')}
            labelPosition={'right'}
          />
        )}
        <CheckboxField
          name="shouldRequestQuantity"
          label={t('itemLabels.shouldRequestQuantity')}
          labelPosition={'right'}
        />
        <CheckboxField
          name="isBatchRequest"
          label={t('itemLabels.isBatchRequest')}
          labelPosition={'right'}
        />
        <CheckboxField
          name="shouldPromptChipDetails"
          label={t('itemLabels.shouldPromptChipDetails')}
          labelPosition={'right'}
        />
        {!props.isServiceType && (
          <CheckboxField
            name="repeatPrescription"
            label={t('itemLabels.repeatPrescription')}
            labelPosition={'right'}
          />
        )}
        {props.existingItem && (
          <CheckboxField
            name="isDisabled"
            label={t('itemLabels.isDisabled')}
            labelPosition={'right'}
          />
        )}
        <NotesEdit currentNotes={props.formikProps.values.noteDetails ?? []} locale={props.locale} />
      </FormFieldGroup>
    </FormFieldGroupContainer>
  );
};
