export type Url = string;

export const homeUrl = (): Url => '/';
const homeUrlSlash = (path: string): Url => homeUrl() + path;

export const dashboardUrl = homeUrl;

export const suppliersUrl = () => homeUrlSlash('suppliers');

export const itemsUrl = (queryParams: string = '') => homeUrlSlash(`items${queryParams}`);

export const deviationsUrl = (queryParams: string = '') => homeUrlSlash(`deviations${queryParams}`);

export const treatmentTypeDeviationsUrl = (queryParams: string = '') =>
  homeUrlSlash(`treatment-type-deviations${queryParams}`);

export const itemHIstory = (queryParams: number = 0) =>
  homeUrlSlash(`support/itemHistory/${queryParams}`);

export const organisationsUrl = () => homeUrlSlash('organisations');

export const sitesUrl = () => homeUrlSlash('sites');

export const usersUrl = () => homeUrlSlash('users');

export const faqUrl = () => homeUrlSlash('faq');

export const pmsRequestErrorsUrl = () => homeUrlSlash('pms-errors');

export const supportUrl = (queryParams: string = '') => homeUrlSlash(`support/${queryParams}`);

export const resetFailedItemsUrl = () => homeUrlSlash(`reset-failed-items`);

export const deleteSupplierItemsUrl = () => homeUrlSlash(`delete-supplier-items`);
