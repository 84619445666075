import * as React from 'react';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import {
  userHasOneOfPermissions,
  userHasPermission,
} from '../feature/authentication/loginData/user';
import { UserContext } from '../feature/authentication/loginData/userContext';
import { Permission } from '../feature/authentication/permissions';
import { ErrorBox } from './errors/ErrorBox';

type HasPermissionProps = {
  permission: Permission | Array<Permission>;
  children: (hasPermission: boolean) => React.ReactNode;
};

type HasOneOfPermissionsProps = {
  permissions: Array<Permission>;
  children: (hasPermission: boolean) => React.ReactNode;
};

export const HasPermission = ({ permission, children }: HasPermissionProps) => (
  <UserContext.Consumer>
    {({ user }) => children(userHasPermission(user, permission))}
  </UserContext.Consumer>
);

export const HasOneOfPermissions = ({ permissions, children }: HasOneOfPermissionsProps) => (
  <UserContext.Consumer>
    {({ user }) => children(userHasOneOfPermissions(user, permissions))}
  </UserContext.Consumer>
);

const NoPermissionError = () => {
  const { t } = useTranslation('clientErrors');
  return <ErrorBox errors={[t('noPermission')]} />;
};

type PermissionProps = {
  permission: Permission | Array<Permission>;
  children: React.ReactNode;
};

type OneOfPermissionsProps = {
  permissions: Array<Permission>;
  children: React.ReactNode;
};

export const IfUserHasPermission = ({ permission, children }: PermissionProps) => (
  <HasPermission permission={permission}>
    {hasPermission => (hasPermission ? children : null)}
  </HasPermission>
);

export const IfUserHasOneOfPermissions = ({ permissions, children }: OneOfPermissionsProps) => (
  <HasOneOfPermissions permissions={permissions}>
    {hasPermission => (hasPermission ? children : null)}
  </HasOneOfPermissions>
);

export const RequiresPermission = ({ permission, children }: PermissionProps) => (
  <HasPermission permission={permission}>
    {hasPermission => (hasPermission ? children : <NoPermissionError />)}
  </HasPermission>
);

export const RequiresOneOfPermissions = ({ permissions, children }: OneOfPermissionsProps) => (
  <HasOneOfPermissions permissions={permissions}>
    {hasPermission => (hasPermission ? children : <NoPermissionError />)}
  </HasOneOfPermissions>
);

export const requiresPermission = <OP extends object>(...permissions: Array<Permission>) => (
  OriginalComponent: ComponentType<OP>,
): ComponentType<OP> => (props: OP) => (
  <RequiresPermission permission={permissions}>
    <OriginalComponent {...props} />
  </RequiresPermission>
);

export const requiresOneOfPermissions = <OP extends object>(...permissions: Array<Permission>) => (
  OriginalComponent: ComponentType<OP>,
): ComponentType<OP> => (props: OP) => (
  <RequiresOneOfPermissions permissions={permissions}>
    <OriginalComponent {...props} />
  </RequiresOneOfPermissions>
);
