import { Field, FieldProps } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '../../../../shared/forms/Checkbox';
import { InputFieldProps } from '../../../../shared/forms/InputField';
import { useDebouncedFormikField } from '../../../../shared/hooks/useDebouncedFormikField';
import { styled } from '../../../../styling/theme';
import { SupplierListChangeProduct } from '../supplierList';
import { getCodesWithValue, SelectedCodes } from './selectedCodes';

type OwnProps = {
  changedProducts: Array<SupplierListChangeProduct>;
  onFilterProducts: (products: Array<SupplierListChangeProduct>) => void;
};

type InnerProps = {
  showPriceIncreases: boolean;
  setShowPriceIncreases: (value: boolean) => void;
  showPriceDecreases: boolean;
  setShowPriceDecreases: (value: boolean) => void;
  showOtherChanges: boolean;
  setShowOtherChanges: (value: boolean) => void;
} & FieldProps;

export const priceIncreasesCheckboxTestId = 'priceIncreases';
export const priceDecreasesCheckboxTestId = 'priceDecreases';
export const otherChangesCheckboxTestId = 'otherChanges';

const Filters = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${props => props.theme.spacing.small}px;
`;

const FilterLabel = styled.label`
  display: flex;
  flex-direction: row;
  color: ${props => props.theme.colours.secondary};
  size: ${props => props.theme.typography.headings.h6fontSize}px;
  margin-right: ${props => props.theme.spacing.small}px;

  input {
    margin-right: ${props => props.theme.spacing.tiny}px;
  }
`;

export const InnerProductChangesFilters = (props: OwnProps & InnerProps) => {
  const { t } = useTranslation('supplierList');

  const { value, setValue } = useDebouncedFormikField<SelectedCodes>({
    field: props.field,
    form: props.form,
    useDebouncing: false,
  });

  const priceIncreaseProducts = () =>
    props.changedProducts.filter(
      product => product.newPrice && product.newPrice > product.oldPrice,
    );
  const priceDecreaseProducts = () =>
    props.changedProducts.filter(
      product => product.newPrice && product.newPrice < product.oldPrice,
    );
  const otherChangeProducts = () =>
    props.changedProducts.filter(
      product => !product.newPrice || product.newPrice === product.oldPrice,
    );

  const getFilteredProducts = (
    priceIncreases: boolean,
    priceDecreases: boolean,
    otherChanges: boolean,
  ) => [
    ...(priceIncreases ? priceIncreaseProducts() : []),
    ...(priceDecreases ? priceDecreaseProducts() : []),
    ...(otherChanges ? otherChangeProducts() : []),
  ];

  const onShowPriceIncreasesChange = (selected: boolean) => {
    props.setShowPriceIncreases(selected);
    props.onFilterProducts(
      getFilteredProducts(selected, props.showPriceDecreases, props.showOtherChanges),
    );

    const toggledCodes = priceIncreaseProducts().map(product => product.productCode);
    setValue(getCodesWithValue(value, toggledCodes, selected));
  };

  const onShowPriceDecreasesChange = (selected: boolean) => {
    props.setShowPriceDecreases(selected);
    props.onFilterProducts(
      getFilteredProducts(props.showPriceIncreases, selected, props.showOtherChanges),
    );

    const toggledCodes = priceDecreaseProducts().map(product => product.productCode);
    setValue(getCodesWithValue(value, toggledCodes, selected));
  };

  const onShowOtherChangesChange = (selected: boolean) => {
    props.setShowOtherChanges(selected);
    props.onFilterProducts(
      getFilteredProducts(props.showPriceIncreases, props.showPriceDecreases, selected),
    );

    const toggledCodes = otherChangeProducts().map(product => product.productCode);
    setValue(getCodesWithValue(value, toggledCodes, selected));
  };

  return (
    <Filters>
      <FilterLabel>
        <Checkbox
          checked={props.showPriceIncreases}
          onChange={onShowPriceIncreasesChange}
          data-testid={priceIncreasesCheckboxTestId}
        />
        {t('review.changed.filters.priceIncreases')}
      </FilterLabel>
      <FilterLabel>
        <Checkbox
          checked={props.showPriceDecreases}
          onChange={onShowPriceDecreasesChange}
          data-testid={priceDecreasesCheckboxTestId}
        />
        {t('review.changed.filters.priceDecreases')}
      </FilterLabel>
      <FilterLabel>
        <Checkbox
          checked={props.showOtherChanges}
          onChange={onShowOtherChangesChange}
          data-testid={otherChangesCheckboxTestId}
        />
        {t('review.changed.filters.otherChanges')}
      </FilterLabel>
    </Filters>
  );
};

export const ProductChangesFilters = (props: OwnProps & InputFieldProps) => {
  const [showPriceIncreases, setShowPriceIncreases] = useState(true);
  const [showPriceDecreases, setShowPriceDecreases] = useState(true);
  const [showOtherChanges, setShowOtherChanges] = useState(true);

  return (
    <Field
      name={props.name}
      component={({ form, field }: FieldProps) => (
        <InnerProductChangesFilters
          showPriceIncreases={showPriceIncreases}
          setShowPriceIncreases={setShowPriceIncreases}
          showPriceDecreases={showPriceDecreases}
          setShowPriceDecreases={setShowPriceDecreases}
          showOtherChanges={showOtherChanges}
          setShowOtherChanges={setShowOtherChanges}
          form={form}
          field={field}
          {...props}
        />
      )}
    />
  );
};
