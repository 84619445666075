import { round } from 'lodash';
import * as React from 'react';
import { ChangeEvent } from 'react';
import { CurrencyCode, Locale } from '../../models/locale';
import { parseNumber } from '../../utils/numberUtils';
import { useDebouncedFormikField } from '../hooks/useDebouncedFormikField';
import { CurrencyInput } from './CurrencyInput';
import { FormField, FormFieldChildProps, FormFieldProps } from './FormField';

export type CurrencyFieldProps = {
  disabled?: boolean;
  warning?: boolean;
  autoFocus?: boolean;
  currency?: CurrencyCode | Array<CurrencyCode>;
  isRightAligned?: boolean;
  testId?: string;
} & FormFieldProps<string>;

const InnerCurrencyInput = ({
  field,
  form,
  valid,
  invalid,
  warning,
  currency,
  isRightAligned,
  ...currencyFieldProps
}: FormFieldChildProps<string> & CurrencyFieldProps) => {
  const { value, setValue, flushDebounce } = useDebouncedFormikField({
    field,
    form,
    useDebouncing: currencyFieldProps.useDebouncing,
    onChange: currencyFieldProps.onChange,
  });
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    flushDebounce();
    field.onBlur(e);
  };

  return (
    <CurrencyInput
      {...field}
      value={value || ''}
      valid={valid}
      invalid={invalid}
      warning={warning}
      disabled={currencyFieldProps.disabled}
      aria-label={currencyFieldProps.label}
      bulkVariant={currencyFieldProps.bulkVariant}
      inputSize={currencyFieldProps.inputSize}
      currency={currency}
      onChange={onChange}
      onBlur={onBlur}
      isRightAligned={isRightAligned}
      data-testid={currencyFieldProps.testId}
    />
  );
};

export const CurrencyField = (props: CurrencyFieldProps) => {
  const { disabled, warning, autoFocus, currency, isRightAligned, ...formFieldProps } = props;

  return (
    <FormField {...formFieldProps}>
      {formFieldChildProps => <InnerCurrencyInput {...formFieldChildProps} {...props} />}
    </FormField>
  );
};

export const BeValidCurrency = (value: string | number, locale: Locale, minValue: number = 0) => {
  const parsedNumber = parseNumber(value, locale);
  const isANumber = !Number.isNaN(parsedNumber);
  const hasMax4DecimalPlaces = round(parsedNumber, 4) === parsedNumber;
  const isGreaterThanMinValue = parsedNumber >= minValue;
  const isLessThanMaxDecimal = parsedNumber < 1e13; // Numbers start to round incorrectly past here

  return isANumber && hasMax4DecimalPlaces && isGreaterThanMinValue && isLessThanMaxDecimal;
};

export const BeLessThan = (value: string | number, maxValue: number, locale: Locale) => {
  const parsedNumber = parseNumber(value, locale);

  return parsedNumber < maxValue;
};
