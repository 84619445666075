import * as React from 'react';
import { ChangeEvent } from 'react';
import { Locale } from '../../models/locale';
import { BeValidDecimal } from '../../utils/validation/numberValidation';
import { useDebouncedFormikField } from '../hooks/useDebouncedFormikField';
import { FormField, FormFieldChildProps, FormFieldProps } from './FormField';
import { PercentInput } from './PercentInput';

export type PercentFieldProps = {
  disabled?: boolean;
  warning?: boolean;
  isRightAligned?: boolean;
  testId?: string;
} & FormFieldProps<string>;

const InnerPercentInput = ({
  field,
  form,
  valid,
  invalid,
  warning,
  isRightAligned,
  testId,
  ...percentFieldProps
}: FormFieldChildProps<string> & PercentFieldProps) => {
  const { value, setValue, flushDebounce } = useDebouncedFormikField({
    field,
    form,
    useDebouncing: percentFieldProps.useDebouncing,
    onChange: percentFieldProps.onChange,
  });

  const onChange = (event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value);

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    flushDebounce();
    field.onBlur(e);
  };

  return (
    <PercentInput
      {...field}
      data-testid={testId}
      value={value || ''}
      valid={valid}
      invalid={invalid}
      warning={warning}
      disabled={percentFieldProps.disabled}
      aria-label={percentFieldProps.label}
      bulkVariant={percentFieldProps.bulkVariant}
      inputSize={percentFieldProps.inputSize}
      onChange={onChange}
      onBlur={onBlur}
      isRightAligned={isRightAligned}
    />
  );
};

export const PercentField = (props: PercentFieldProps) => {
  const { disabled, ...formFieldProps } = props;

  return (
    <FormField {...formFieldProps}>
      {formFieldChildProps => <InnerPercentInput {...formFieldChildProps} {...props} />}
    </FormField>
  );
};

export const BeValidPercent = (value: string, locale: Locale, maximumDecimalPlaces: number = 4) =>
  BeValidDecimal(value, locale, maximumDecimalPlaces);
