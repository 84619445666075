import { noop } from 'lodash';
import * as React from 'react';
import { useMemo } from 'react';
import { AuthenticatedApiRequest } from '../models/apiRequest';
import { ApiFetchProps, fetchFromApiOnLoad } from './higher-order-components/fetchFromApiOnLoad';

type WithChildren<TResponse> = {
  children: (props: ApiFetchProps<TResponse>) => React.ReactNode;
};

export type ComponentProps<TResponse> = ApiFetchProps<TResponse> & WithChildren<TResponse>;

const DataLoaderComponent = <T extends unknown>(props: ComponentProps<T>) => (
  <>{props.children(props)}</>
);

type OwnProps<TResponse, TRequestParams> = {
  apiRequest: AuthenticatedApiRequest<TResponse, TRequestParams>;
};

type ParamsProps<TRequestParams> = {
  getParams: () => TRequestParams;
};

type PropsWithParams<TResponse, TRequestParams> = WithChildren<TResponse> &
  OwnProps<TResponse, TRequestParams> &
  ParamsProps<TRequestParams>;

export const DataLoaderWithParams = <TResponse, TRequestParams>(
  props: PropsWithParams<TResponse, TRequestParams>,
) => {
  const Wrapper: React.FunctionComponent<WithChildren<TResponse>> = useMemo(
    () =>
      fetchFromApiOnLoad<WithChildren<TResponse>, TResponse, TRequestParams>(
        props.getParams,
        props.apiRequest,
      )(DataLoaderComponent),
    [props.apiRequest],
  );

  return <Wrapper>{data => props.children(data)}</Wrapper>;
};

type Props<TResponse> = WithChildren<TResponse> & OwnProps<TResponse, void>;

export const DataLoader = <TResponse,>(props: Props<TResponse>) =>
  DataLoaderWithParams<TResponse, void>({ ...props, getParams: noop });
