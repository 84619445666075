import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ValueType } from 'react-select';
import Select from 'react-select';
import styled from 'styled-components';

const PageSizeContainer = styled.div`
  margin-bottom: ${styleProps => styleProps.theme.spacing.small}px;
  width: ${styleProps => styleProps.theme.formInputWidth.small}px;
  float: right;
`;

const StyledSelect = styled(Select)`
  z-index: 2;
`;

type PageSizeProps = {
  onChange: (pageSize: number) => void;
  initalValue?: number;
};
type PageSizeType = {
  value: number;
  label: string;
};

const options: Array<PageSizeType> = [
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 200, label: '200' },
];

export const PageSize = (props: PageSizeProps) => {
  const { t } = useTranslation('component');
  const defaultValue = options[2];
  let initalValue: PageSizeType;
  if (props.initalValue) {
    initalValue = options.filter(x => x.value == props.initalValue)[0];
  } else {
    initalValue = defaultValue;
  }
  const [selectedValue, setSelectedValue] = React.useState(initalValue);

  const handleChange = (option: ValueType<PageSizeType, false>) => {
    setSelectedValue(option ? option : initalValue);
    props.onChange(option?.value ? option.value : initalValue.value);
  };
  return (
    <PageSizeContainer>
      <h5>{t('paginationControls.pageSize')}</h5>
      <StyledSelect value={selectedValue} options={options} onChange={handleChange} />
    </PageSizeContainer>
  );
};
