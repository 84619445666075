import { RouteComponentProps } from '@reach/router';
import { flowRight } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import {
  ApiFetchProps,
  fetchFromApiOnLoad,
} from '../../shared/higher-order-components/fetchFromApiOnLoad';
import { requiresPermission } from '../../shared/Permissions';
import { shouldShowSuccess } from '../../shared/success/SuccessBox';
import { MetadataContext } from '../authentication/loginData/metadataContext';
import { permissions } from '../authentication/permissions';
import { OrganisationGroupResponse } from './organisationGroup';
import { OrganisationGroupForm } from './OrganisationGroupForm';
import { getOrganisationGroup } from './organisationGroupsApi';

type OwnProps = RouteComponentProps<{ organisationId: string }> & {};
type Props = OwnProps & ApiFetchProps<OrganisationGroupResponse>;

const EditOrganisationGroupComponent = (props: Props) => {
  const { t } = useTranslation('organisation');
  const [existingOrg, setExistingOrg] = useState(props.response);

  const onSuccess = (response: OrganisationGroupResponse) => setExistingOrg(response);

  return (
    <>
      <Title>{t('editForm.title')}</Title>
      <h1>{t('editForm.heading')}</h1>
      <MetadataContext.Consumer>
        {metadata => (
          <OrganisationGroupForm
            countries={metadata.countries}
            existingOrganisationGroup={existingOrg}
            showSuccess={shouldShowSuccess()}
            onSuccess={onSuccess}
          />
        )}
      </MetadataContext.Consumer>
    </>
  );
};

const fetchFromApiEnhancer = fetchFromApiOnLoad<OwnProps, OrganisationGroupResponse, number>(
  props => Number(props.organisationId),
  getOrganisationGroup,
);

const enhance = flowRight(requiresPermission(permissions.SuperAdmin), fetchFromApiEnhancer);

export const EditOrganisationGroup = enhance(EditOrganisationGroupComponent);
