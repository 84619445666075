import * as React from 'react';
import { styled } from '../../styling/theme';

export const PageHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.spacing.medium}px;
  button {
    margin-bottom: ${props => props.theme.spacing.tiny}px;
  }
`;

export const PageHeading = (props: { children: React.ReactNode }) => (
  <PageHeadingContainer>{props.children}</PageHeadingContainer>
);
