import { useFiltering, UseFilteringResult } from '../../../shared/tables/filtering/useFiltering';
import { getTreatmentTypeDeviations } from '../deviationsApi';
import { AuthenticatedApiRequest } from '../../../models/apiRequest';
import { TableDataFetchRequest } from '../../../shared/tables/Table';
import {
  TreatmentTypeDeviationColumnTypes,
  TreatmentTypeDeviationFilterOptions,
  TreatmentTypeDeviationsResponse,
} from './treatmentTypeDeviations';
import { ItemFieldsFromPmsResponse } from '../../items/PmsFields/pmsFields';
import { filter } from 'lodash';
import { TreatmentTypeDeviationsBaseProps } from './TreatmentTypeDeviationsBase';

export const initialTreatmentTypeDeviationFilterOptions: TreatmentTypeDeviationFilterOptions = {
  siteIds: null,
  practiceGroupIds: null,
  treatmentTypeIds: null,
  centralPrice: null,
  dispenseFee: null,
};

export const filterToUrl = (
  filterOptions?: TreatmentTypeDeviationFilterOptions,
): Partial<TreatmentTypeDeviationFilterOptions> => {
  if (filterOptions === undefined) {
    return {};
  }
  return {
    siteIds: filterOptions.siteIds ?? undefined,
    practiceGroupIds: filterOptions.practiceGroupIds ?? undefined,
    treatmentTypeIds: filterOptions.treatmentTypeIds ?? undefined,
    centralPrice: filterOptions.centralPrice ?? undefined,
    dispenseFee: filterOptions.dispenseFee ?? undefined,
  };
};

export const urlToFilter = (itemFieldsFromPms: ItemFieldsFromPmsResponse) => (
  urlState?: Partial<TreatmentTypeDeviationFilterOptions>,
): TreatmentTypeDeviationFilterOptions => {
  if (urlState === undefined) {
    return initialTreatmentTypeDeviationFilterOptions;
  }
  return {
    siteIds: urlState.siteIds ?? null,
    practiceGroupIds: urlState.practiceGroupIds ?? null,
    treatmentTypeIds: urlState.treatmentTypeIds
      ? filter(urlState.treatmentTypeIds, id => itemFieldsFromPms.category1Options[id] != null)
      : null,
    centralPrice: urlState.centralPrice ?? null,
    dispenseFee: urlState.dispenseFee ?? null,
  };
};

type UseTreatmentTypeDeviationTableStateResult = UseFilteringResult<
  TreatmentTypeDeviationFilterOptions,
  AuthenticatedApiRequest<
    TreatmentTypeDeviationsResponse,
    TableDataFetchRequest<TreatmentTypeDeviationColumnTypes>
  >
>;

export const useTreatmentTypeDeviationTableState = (
  props: TreatmentTypeDeviationsBaseProps,
): UseTreatmentTypeDeviationTableStateResult =>
  useFiltering({
    getData: getTreatmentTypeDeviations,
    initialUrlState: filterToUrl(initialTreatmentTypeDeviationFilterOptions),
    urlStateToFilterOptions: urlToFilter(props.itemFieldsFromPms),
    filterOptionsToUrlState: filterToUrl,
    pageName: 'treatment-type-deviations',
  });
