import * as React from 'react';
import { ChangeEvent } from 'react';
import { styled } from '../../styling/theme';
import { useDebouncedFormikField } from '../hooks/useDebouncedFormikField';
import { BaseInputProps, BaseInputStyling } from './BaseInputStyling';
import { FormField, FormFieldChildProps, FormFieldProps } from './FormField';

type TextAreaProps = {} & BaseInputProps & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

type TextAreaFieldProps = {
  disabled?: boolean;
  rows?: number;
} & FormFieldProps<string>;

export const TextArea = styled.textarea<TextAreaProps>`
  ${BaseInputStyling};
  resize: none;
`;

const InnerTextArea = ({
  field,
  form,
  valid,
  invalid,
  ...textAreaFieldProps
}: FormFieldChildProps<string> & TextAreaFieldProps) => {
  const { value, setValue, flushDebounce } = useDebouncedFormikField({
    field,
    form,
    useDebouncing: textAreaFieldProps.useDebouncing,
  });

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => setValue(event.target.value);

  const onBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    flushDebounce();
    field.onBlur(e);
  };

  return (
    <TextArea
      {...field}
      value={value || ''}
      valid={valid}
      invalid={invalid}
      disabled={textAreaFieldProps.disabled}
      rows={textAreaFieldProps.rows || 3}
      aria-label={textAreaFieldProps.label}
      bulkVariant={textAreaFieldProps.bulkVariant}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export const TextAreaField = (props: TextAreaFieldProps) => {
  const { rows, disabled, ...formFieldProps } = props;
  return (
    <FormField {...formFieldProps}>
      {formFieldChildProps => <InnerTextArea {...formFieldChildProps} {...props} />}
    </FormField>
  );
};
