import React, { ChangeEvent, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, usePagination, useTable } from 'react-table';
import styled, { ThemeContext } from 'styled-components';
import {
  LoadingOverlay,
  StyledHeader,
  StyledRow,
  StyledSortIndicator,
  StyledTable,
  StyledTd,
  StyledTh,
  TableContainer,
} from '../../styling/TableStyles';
import { Theme } from '../../styling/theme';
import { LoadingSpinner } from '../buttons/Button';
import { InfoBox } from '../info/InfoBox';
import { SimplePaginator } from './pagination/SimplePaginator';
import { DropdownOption } from '../forms/DropdownField';
import { BaseInputStyling } from '../forms/BaseInputStyling';
import { map } from 'lodash';
import { SiteSettings, UserContext } from '../../feature/authentication/loginData/userContext';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

const StyledSelect = styled.select<React.InputHTMLAttributes<HTMLSelectElement>>`
  ${BaseInputStyling};
  width: 100px;
  margin-bottom: 10px;
`;

interface SimpleTableProps<T extends object> {
  columns: Array<Column<T>>;
  data: Array<T>;
  itemsToDisplay?: number;
  loading?: boolean;
  allowPagination?: boolean;
  emptyTableMessage: string;
}

const booleanDropdownOptions: Array<DropdownOption<number>> = [
  { displayText: '10', value: 10 },
  { displayText: '20', value: 20 },
  { displayText: '30', value: 30 },
  { displayText: '50', value: 50 },
  { displayText: '100', value: 100 },
];

export const SimpleTable = ({
  columns,
  data,
  loading,
  allowPagination,
  emptyTableMessage,
}: SimpleTableProps<object>) => {
  const { t } = useTranslation('component');
  const { user, updateUser } = useContext(UserContext);
  const themeContext: Theme = useContext(ThemeContext);
  const numberOfItemsToDisplay = user.siteSettings?.dashBoard?.centralEdits.pageSize ?? 10;
  const isLoading = loading ?? false;
  const showPaginationControls = allowPagination ?? true;

  /**
   * Set the total number of items per page for the table.
   * (If the user has changed this previously, this will use the value stored in the siteSettings prop of the User context.)
   */
  useEffect(() => {
    setPageSize(numberOfItemsToDisplay);
  }, [JSON.stringify(user.siteSettings?.dashBoard)]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    prepareRow,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
    },
    usePagination,
  );

  const { pageIndex } = state;

  if (isLoading === false && data.length === 0 && emptyTableMessage) {
    return <InfoBox message={emptyTableMessage} />;
  }

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(event.target.value);

    const updatedSiteSettings: SiteSettings['siteSettings'] = {
      ...user.siteSettings,
      dashBoard: { centralEdits: { pageSize: value } },
    };

    updateUser({ ...user, siteSettings: updatedSiteSettings });
  };

  return (
    <>
      <Container>
        {showPaginationControls && (
          <StyledSelect value={numberOfItemsToDisplay} onChange={handleSelectChange}>
            {map(booleanDropdownOptions, option => (
              <option key={option.value} value={option.value}>
                {option.displayText}
              </option>
            ))}
          </StyledSelect>
        )}
      </Container>

      <TableContainer>
        <LoadingOverlay showLoadingOverlay={isLoading}>
          <LoadingSpinner colour={themeContext.colours.primary} />
        </LoadingOverlay>
        <StyledTable {...getTableProps()}>
          <StyledHeader>
            {// Loop over the header rows
            headerGroups.map(headerGroup => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {// Loop over the headers in each row
                headerGroup.headers.map(column => (
                  // Apply the header cell props
                  <StyledTh
                    {...column.getHeaderProps()}
                    title={column.canSort ? t('table.toggleSort') : undefined}
                  >
                    {column.render('Header')}
                    {column.canSort && (
                      <StyledSortIndicator
                        isSorted={column.isSorted}
                        isDescending={column.isSortedDesc}
                      />
                    )}
                  </StyledTh>
                ))}
              </tr>
            ))}
          </StyledHeader>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {// Loop over the table rows
            page.map(row => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <StyledRow {...row.getRowProps()}>
                  {// Loop over the rows cells
                  row.cells.map(cell => {
                    // Apply the cell props
                    return (
                      <StyledTd {...cell.getCellProps()}>
                        {// Render the cell contents
                        cell.render('Cell')}
                      </StyledTd>
                    );
                  })}
                </StyledRow>
              );
            })}
          </tbody>
        </StyledTable>
      </TableContainer>

      {showPaginationControls && (
        <SimplePaginator
          itemCount={data.length}
          noOfItemsToDisplay={numberOfItemsToDisplay}
          pageOptions={pageOptions}
          pageIndex={pageIndex}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          previousPage={previousPage}
          gotoPage={gotoPage}
          pageCount={pageCount}
        />
      )}
    </>
  );
};
