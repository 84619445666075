import * as React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps, Column } from 'react-table';
import { toShortDate } from '../../models/dates/dateTimeStamp';
import { ApiErrorBox } from '../../shared/errors/ApiErrorBox';
import { useApiRequest } from '../../shared/hooks/useApiRequest';
import { Link } from '../../shared/navigation/Link';
import { IfUserHasOneOfPermissions } from '../../shared/Permissions';
import { UserContext } from '../authentication/loginData/userContext';
import { permissions } from '../authentication/permissions';
import { ItemBulkEditJobDetails } from '../items/itemBulkEdit';
import { getRecentItemBulkEdits } from '../items/itemsApi';
import { viewItemBulkEditUrl } from '../items/itemUrls';
import { viewPmsRequestErrorsForItemBulkEditUrl } from '../pmsRequestErrors/pmsRequestErrorUrls';
import { MarginRightFalseIcon, TrueIcon } from '../../shared/StyledIcons';
import { SimpleTable } from '../../shared/tables/SimpleTable';
import { settings } from './Globals';
import { DashboardFilterSelection } from './models/DashboardFilterSelection';

type RecentItemBulkEditsSectionProps = {
  existingFilters?: DashboardFilterSelection;
};

export const RecentItemBulkEditsSection = (props: RecentItemBulkEditsSectionProps) => {
  const { t } = useTranslation('dashboard');
  const { user } = useContext(UserContext);
  const { response, makeRequest, apiError, inProgress } = useApiRequest(getRecentItemBulkEdits);
  const itmemsToDisplay = settings.recentItemsToDisplay;

  useEffect(() => {
    makeRequest(props.existingFilters).then();
  }, [props.existingFilters]);

  const columns: Array<Column> = [
    {
      Header: t<string>('itemBulkEdit.recentSection.table.scheduledDate'),
      accessor: 'scheduledDate',
      id: 'scheduledDate',
      Cell: ({ cell }: CellProps<ItemBulkEditJobDetails>) => (
        <Link to={viewItemBulkEditUrl(cell.row.original.itemBulkEditId)}>
          {toShortDate(cell.value, user.locale)}
        </Link>
      ),
    },
    {
      Header: t<string>('itemBulkEdit.recentSection.table.name'),
      accessor: 'name',
      id: 'name',
    },
    {
      Header: t<string>('deviationBulkEdit.upcomingSection.table.creatorsName'),
      accessor: 'creatorsName',
      id: 'creatorsName',
    },
    {
      Header: t<string>('itemBulkEdit.recentSection.table.status'),
      accessor: 'hasErrored',
      id: 'hasErrored',
      Cell: ({ cell }: CellProps<ItemBulkEditJobDetails>) => {
        if (cell.row.original.hasErrored) {
          return (
            <span>
              <MarginRightFalseIcon />
              <IfUserHasOneOfPermissions
                permissions={[permissions.SuperAdmin, permissions.CentralAdmin]}
              >
                <Link to={viewPmsRequestErrorsForItemBulkEditUrl(cell.row.original.itemBulkEditId)}>
                  {t('itemBulkEdit.recentSection.table.viewError')}
                </Link>
              </IfUserHasOneOfPermissions>
            </span>
          );
        }
        if (cell.row.original.allComplete) {
          return (
            <span>
              <TrueIcon />
            </span>
          );
        }
        return <span>{t('itemBulkEdit.recentSection.table.inProgress')}</span>;
      },
    },
  ];

  return (
    /**
     * jira pp-1076 all users can see Central Edits
     */
    <>
      <h4>{t('itemBulkEdit.recentSection.title')}</h4>
      <SimpleTable
        columns={columns}
        data={response?.itemBulkEditJobs ?? []}
        loading={inProgress}
        emptyTableMessage={t('itemBulkEdit.recentSection.table.emptyTableMessage')}
        itemsToDisplay={itmemsToDisplay}
      />
      {apiError && <ApiErrorBox error={apiError} />}
    </>
  );
};
