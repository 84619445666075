import * as React from 'react';
import { css, styled } from '../../styling/theme';
import { buttonBorderWidth, ButtonProps, PrimaryButton } from './Button';

type SelectableButtonProps = ButtonProps & {
  selected: boolean;
  children: React.ReactNode;
};

const SelectableButton = ({ selected, children, ...rest }: SelectableButtonProps) => (
  <StyledPrimaryButton {...rest} selected={selected} data-testid={`selectable-button-${selected}`}>
    {children}
  </StyledPrimaryButton>
);

const StyledPrimaryButton = styled(PrimaryButton)<{ selected: boolean }>`
  ${props => {
    return props.selected
      ? css`
          color: ${props.theme.colours.buttons.toggle.selectedTextColour};
          background-color: ${props.theme.colours.buttons.toggle.selected};
          cursor: default;

          &:enabled {
            &:hover {
              color: ${props.theme.colours.buttons.toggle.selectedTextColour};
              background-color: ${props.theme.colours.buttons.toggle.selected};
            }

            &:focus {
              background-color: ${props.theme.colours.buttons.toggle.selectedHighlight};
            }
          }
        `
      : css`
          color: ${props.theme.colours.buttons.toggle.unselectedTextColour};
          background-color: ${props.theme.colours.buttons.toggle.unselected};
          border: ${buttonBorderWidth}px solid ${props.theme.colours.buttons.toggle.unselected};

          &:enabled {
            &:hover,
            &:focus {
              background-color: ${props.theme.colours.buttons.toggle.unselectedHighlight};
            }
          }
        `;
  }}
`;

const LeftButton = styled(SelectableButton)`
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const RightButton = styled(SelectableButton)`
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

type Props = ButtonProps & {
  state: boolean;
  onChangeState: (newState: boolean) => void;
  offButtonText: string;
  onButtonText: string;
};

const ToggleButtonContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export const ToggleButton = (props: Props) => {
  const { state, onChangeState, offButtonText, onButtonText, ...rest } = props;
  const setState = (newState: boolean) => () => {
    if (state !== newState) {
      onChangeState(newState);
    }
  };

  return (
    <ToggleButtonContainer>
      <LeftButton selected={!state} onClick={setState(false)} {...rest}>
        {offButtonText}
      </LeftButton>
      <RightButton selected={state} onClick={setState(true)} {...rest}>
        {onButtonText}
      </RightButton>
    </ToggleButtonContainer>
  );
};
