import { ValidationErrors } from 'fluentvalidation-ts/dist/ValidationErrors';
import { Form, Formik, FormikActions, FormikProps } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticatedApiRequest } from '../../models/apiRequest';
import { styled } from '../../styling/theme';
import { PrimaryButton } from '../buttons/Button';
import { ApiErrorBox } from '../errors/ApiErrorBox';
import { FormValidationErrorBox } from '../errors/FormValidationErrorBox';
import { useApiRequest } from '../hooks/useApiRequest';
import { InfoBox } from '../info/InfoBox';
import {
  DataFetchTable,
  DataFetchTableProps,
  Table,
  TableDataFetchResponse,
  TableProps,
} from './Table';

type FormikTableProps<
  TFormModel,
  TApiData extends TableDataFetchResponse<TColumnNames>,
  TRowData extends object,
  TSubmitParams,
  TSubmitResponse,
  TColumnNames extends string = string
> = {
  disabled?: boolean;
  validate: (values: TFormModel) => ValidationErrors<TFormModel>;
  enableReinitialize: boolean;
  apiDataToFormModel: (response: TApiData | null) => TFormModel;
  submitRequest: AuthenticatedApiRequest<TSubmitResponse, TSubmitParams>;
  mapValuesToRequestParameters: (formModel: TFormModel) => TSubmitParams;
  onSubmitComplete?: (
    response: TSubmitResponse,
    formikActions: FormikActions<TFormModel | null>,
  ) => void;
  additionalFormFields?: (formikBag: FormikProps<TFormModel | null>) => React.ReactNode;
  disableFormOnSuccess?: boolean;
  formValidationErrorMessage?: string;
  additionalWarningText?: string;
} & DataFetchTableProps<TApiData, TRowData, TColumnNames, TFormModel>;

const StyledSubmitButton = styled(PrimaryButton)`
  margin-top: ${props => props.theme.spacing.small}px;
  margin-bottom: ${props => props.theme.spacing.tiny}px;
`;

const StyledAdditionalChildrenContainer = styled.div`
  margin-top: ${props => props.theme.spacing.small}px;
`;

const AdditionWarningText = styled.div`
  color: ${props => props.theme.colours.alertText};
  font-size: ${props => props.theme.typography.content.fontSize}px;
  margin-top: ${props => -props.theme.spacing.extraSmall}px;
  margin-bottom: ${props => props.theme.spacing.extraSmall}px;
`;

export const FormikTable = <
  TFormModel,
  TApiData extends TableDataFetchResponse<TColumnNames>,
  TRowData extends object,
  TSubmitParams,
  TSubmitResponse,
  TColumnNames extends string = string
>(
  props: FormikTableProps<
    TFormModel,
    TApiData,
    TRowData,
    TSubmitParams,
    TSubmitResponse,
    TColumnNames
  >,
) => {
  const { t } = useTranslation('component');
  const [tableData, setTableData] = useState<TApiData | null>(null);
  const { apiError, makeRequest } = useApiRequest(props.submitRequest);

  const [shouldDisableForm, setShouldDisableForm] = useState<boolean>(false);

  const onDataFetchSuccess = (apiResponse: TApiData) => {
    setTableData(apiResponse);
    if (props.onDataFetchSuccess) {
      props.onDataFetchSuccess(apiResponse);
    }
  };

  return (
    <Formik<TFormModel | null>
      enableReinitialize={props.enableReinitialize}
      initialValues={props.apiDataToFormModel(tableData)}
      onSubmit={(values: TFormModel | null, formikActions: FormikActions<TFormModel | null>) => {
        if (values) {
          makeRequest(props.mapValuesToRequestParameters(values))
            .then(result => {
              if (result && props.disableFormOnSuccess) {
                setShouldDisableForm(true);
              }

              if (result && props.onSubmitComplete) {
                props.onSubmitComplete(result, formikActions);
              }
            })
            .finally(() => formikActions.setSubmitting(false));
        } else {
          formikActions.setSubmitting(false);
        }
      }}
      validate={values => (values ? props.validate(values) : [])}
      render={formikProps => (
        <Form>
          <DataFetchTable
            {...props}
            formikProps={formikProps}
            onDataFetchSuccess={onDataFetchSuccess}
            shouldRestrictFetching={props.shouldRestrictFetching || shouldDisableForm}
          />

          {props.additionalFormFields && (
            <StyledAdditionalChildrenContainer>
              {props.additionalFormFields(formikProps)}
            </StyledAdditionalChildrenContainer>
          )}

          {formikProps.errors && formikProps.touched && (
            <FormValidationErrorBox
              errors={formikProps.errors}
              touched={formikProps.touched}
              errorMessage={props.formValidationErrorMessage}
            />
          )}
          {props.additionalWarningText && (<AdditionWarningText>{props.additionalWarningText}</AdditionWarningText>)}
          <StyledSubmitButton
            type="submit"
            loading={formikProps.isSubmitting}
            disabled={props.disabled || shouldDisableForm}
          >
            {t('formikTable.submit')}
          </StyledSubmitButton>

          {apiError && <ApiErrorBox error={apiError} />}
        </Form>
      )}
    />
  );
};

type FormikBasicTableProps<
  TFormModel,
  TRowData extends object,
  TColumnNames extends string,
  TSubmitParams,
  TSubmitResponse
> = {
  disabled?: boolean;
  validate: (values: TFormModel) => ValidationErrors<TFormModel>;
  enableReinitialize: boolean;
  submitRequest: AuthenticatedApiRequest<TSubmitResponse, TSubmitParams>;
  mapValuesToRequestParameters: (formModel: TFormModel) => TSubmitParams;
  onSubmitComplete?: (
    response: TSubmitResponse,
    formikActions: FormikActions<TFormModel | null>,
  ) => void;
  additionalFormFields?: (formikBag: FormikProps<TFormModel | null>) => React.ReactNode;
  initialFormValues: TFormModel;
  formValidationErrorMessage?: string;
} & TableProps<TRowData, TColumnNames, TFormModel>;

// Like a FormikTable, but doesn't need to fetch anything from the api
export const FormikBasicTable = <
  TFormModel,
  TRowData extends object,
  TColumnNames extends string,
  TSubmitParams,
  TSubmitResponse
>(
  props: FormikBasicTableProps<TFormModel, TRowData, TColumnNames, TSubmitParams, TSubmitResponse>,
) => {
  const { t } = useTranslation('component');
  const { apiError, makeRequest } = useApiRequest(props.submitRequest);

  return (
    <Formik<TFormModel | null>
      enableReinitialize={props.enableReinitialize}
      initialValues={props.initialFormValues}
      onSubmit={(values: TFormModel | null, formikActions: FormikActions<TFormModel | null>) => {
        if (values) {
          makeRequest(props.mapValuesToRequestParameters(values))
            .then(result => {
              if (result && props.onSubmitComplete) {
                props.onSubmitComplete(result, formikActions);
              }
            })
            .finally(() => formikActions.setSubmitting(false));
        } else {
          formikActions.setSubmitting(false);
        }
      }}
      validate={values => (values ? props.validate(values) : [])}
      render={formikProps => (
        <Form>
          <Table {...props} formikProps={formikProps} />

          {props.additionalFormFields && (
            <StyledAdditionalChildrenContainer>
              {props.additionalFormFields(formikProps)}
            </StyledAdditionalChildrenContainer>
          )}

          {formikProps.errors && formikProps.touched && (
            <FormValidationErrorBox
              errors={formikProps.errors}
              touched={formikProps.touched}
              errorMessage={props.formValidationErrorMessage}
            />
          )}

          <StyledSubmitButton
            type="submit"
            loading={formikProps.isSubmitting}
            disabled={props.disabled}
          >
            {t('formikTable.submit')}
          </StyledSubmitButton>

          {apiError && <ApiErrorBox error={apiError} />}
        </Form>
      )}
    />
  );
};
