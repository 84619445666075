import { Id } from '../../models/id';
import { sitesUrl, Url } from '../../urls';

export const createSiteUrl = (): Url => `${sitesUrl()}/create`;

export const archivedSitesUrl = (): Url => `${sitesUrl()}/archived`;

export const editSiteUrl = (siteId: Id): Url => `${sitesUrl()}/edit/${siteId}`;

export const uploadSitesUrl = (): Url => `${sitesUrl()}/upload`;
