import { Id } from '../../models/id';
import { pmsRequestErrorsUrl } from '../../urls';

export const viewPmsRequestErrorUrl = (errorId: Id) => `${pmsRequestErrorsUrl()}/${errorId}`;

export const viewPmsRequestErrorsForItemBulkEditUrl = (itemBulkEditId: Id) =>
  `${pmsRequestErrorsUrl()}/item-bulk-edit/${itemBulkEditId}`;

export const viewPmsRequestErrorsForDeviationBulkEditUrl = (deviationBulkEditId: Id) =>
  `${pmsRequestErrorsUrl()}/deviation-bulk-edit/${deviationBulkEditId}`;

export const viewPmsRequestErrorsForTreatmentTypeDeviationBulkEditUrl = (
  treatmentTypeDeviationBulkEditId: Id,
) =>
  `${pmsRequestErrorsUrl()}/treatment-type-deviation-bulk-edit/${treatmentTypeDeviationBulkEditId}`;
