import * as React from 'react';
import { LoginUserResponse } from '../../feature/authentication/loginData/user';
import { UserContext } from '../../feature/authentication/loginData/userContext';

export type WithUserContextProps = {
  user: LoginUserResponse;
};

export const withUserContext = <P extends object>() => (
  WrappedComponent: React.ComponentType<P & WithUserContextProps>,
): React.FunctionComponent<P> => props => (
  <UserContext.Consumer>
    {({ user }) => <WrappedComponent {...props} user={user} />}
  </UserContext.Consumer>
);
