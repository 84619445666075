import { RouteComponentProps, Router } from '@reach/router';
import * as React from 'react';
import { PageNotFound } from '../PageNotFound';
import { CreateSite } from './CreateSite';
import { EditSite } from './EditSite';
import { ArchivedSites } from './SitesTables/ArchivedSites';
import { Sites } from './SitesTables/Sites';
import { UploadSites } from './UploadSites';

export const SitesSection = (props: RouteComponentProps) => (
  <Router primary={false}>
    <Sites path="/" />
    <ArchivedSites path="/archived" />
    <CreateSite path="create" />
    <EditSite path="edit/:siteId" />
    <UploadSites path="upload" />
    <PageNotFound default={true} />
  </Router>
);
