import * as React from 'react';
import { LoginUserResponse } from './user';

export type SiteSettings = {
  siteSettings?: {
    dashBoard?: {
      centralEdits: {
        pageSize: number;
      };
    };
  };
};

type UserContextProps = {
  user: LoginUserResponse & SiteSettings;
  updateUser: (user: LoginUserResponse & SiteSettings) => void;
};

export const UserContext = React.createContext<UserContextProps>(
  (null as unknown) as UserContextProps,
);
