import {
  faFileAlt,
  faInfoCircle,
  faPhotoVideo,
  faQuestionCircle,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HollowButton } from '../../shared/buttons/Button';
import { styled } from '../../styling/theme';
import { faqUrl } from '../../urls';
import { saveFileFromUrl } from '../../utils/fileDownload';
import { navigate } from '../../utils/routing';
import { UserContext } from '../authentication/loginData/userContext';

export const QuickLinksSection = () => {
  const { t } = useTranslation('dashboard');
  const { user } = useContext(UserContext);
  const onUserGuideClick = () => {
    saveFileFromUrl(
      `media/${user.organisationGroupId}/${t('quickLinks.userGuideFilename')}`,
      `${t('quickLinks.userGuideFilename')}`,
    );
  };

  const onCodeRequestDocumentsDownloadClick = async () => {
    saveFileFromUrl(
      `media/${user.organisationGroupId}/${t('quickLinks.codeRequestDocFileName')}`,
      `${t('quickLinks.codeRequestDocFileName')}`,
    );
  };

  const onFaqClick = () => navigate(faqUrl());

  const videoURL = `quickLinks.trainingVideoByOrg.${user.organisationGroupId}`;
  const onVideoClick = () => window.open(`${t(videoURL)}`, '_blank');

  return (
    <>
      <QuickLinksContainer>
        <QuickLink onClick={onUserGuideClick}>
          <LinkTitle icon={faInfoCircle} text={t('quickLinks.userGuide')} />
        </QuickLink>
        <QuickLink onClick={onVideoClick}>
          <LinkTitle icon={faPhotoVideo} text={t('quickLinks.video')} />
        </QuickLink>
        <QuickLink onClick={onCodeRequestDocumentsDownloadClick}>
          <LinkTitle icon={faFileAlt} text={t('quickLinks.codeRequestDoc')} />
        </QuickLink>
        <QuickLink onClick={onFaqClick}>
          <LinkTitle icon={faQuestionCircle} text={t('quickLinks.faq')} />
        </QuickLink>
      </QuickLinksContainer>
    </>
  );
};

const QuickLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.spacing.small}px;
`;

const QuickLink = styled(HollowButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-basis: 0;
  height: 80px;
  border: 1px solid ${props => props.theme.colours.border};
  border-radius: ${props => props.theme.spacing.extraTiny}px;
  background-color: ${props => props.theme.colours.componentBackground};
  font-weight: bold;
  color: ${props => props.theme.colours.primary};

  &:enabled {
    &:hover,
    &:focus {
      border: 1px solid ${props => props.theme.colours.buttons.primaryHighlight};
      border-radius: ${props => props.theme.spacing.extraTiny}px;
    }
  }
`;

type LinkTitleProps = {
  icon: IconDefinition;
  text: string;
};

const LinkTitle = (props: LinkTitleProps) => {
  return (
    <LinkTitleContainer>
      <FontAwesomeIcon icon={props.icon} />
      <LinkTitleText>{props.text}</LinkTitleText>
    </LinkTitleContainer>
  );
};

const LinkTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const LinkTitleText = styled.div`
  margin-left: ${props => props.theme.spacing.tiny}px;
`;
