import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { FileUploadForm } from '../../shared/files/FileUploadForm';
import { RequiresOneOfPermissions } from '../../shared/Permissions';
import { sitesUrl } from '../../urls';
import { assertIsDefined } from '../../utils/assertIsDefined';
import { permissions } from '../authentication/permissions';
import { UploadSitesResponse } from './site';
import { uploadSites } from '../../api/sitesApi';

export type UploadSitesFormModel = {};

export const UploadSites = (props: RouteComponentProps) => {
  const { t } = useTranslation('site');
  return (
    <RequiresOneOfPermissions permissions={[permissions.SuperAdmin, permissions.CentralAdmin]}>
      <Title>{t('upload.title')}</Title>
      <h1>{t('upload.heading')}</h1>
      <FileUploadForm<UploadSitesFormModel, UploadSitesResponse>
        initialValues={{}}
        validate={() => ({})}
        filePropertyName="file"
        fileFieldLabel={t('upload.labels.file')}
        request={uploadSites}
        onSuccess={() => {
          assertIsDefined(props.navigate)(sitesUrl());
        }}
      />
    </RequiresOneOfPermissions>
  );
};
