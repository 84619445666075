import { round } from 'lodash';
import { Locale } from '../../models/locale';
import { parseNumber } from '../numberUtils';

export const BeValidDecimal = (value: string, locale: Locale, maximumDecimalPlaces: number = 2) => {
  const parsedNumber = parseNumber(value, locale);
  const hasFewerThanMaxDecimalPlaces = round(parsedNumber, maximumDecimalPlaces) === parsedNumber;
  return !Number.isNaN(parsedNumber) && hasFewerThanMaxDecimalPlaces;
};

export const BeValidInteger = (value: string, locale: Locale) => BeValidDecimal(value, locale, 0);
