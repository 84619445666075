import { RouteComponentProps, Router } from '@reach/router';
import * as React from 'react';
import { PageNotFound } from '../../PageNotFound';
import { ReviewSupplierList } from './ReviewSupplierList';
import { SupplierListItemsDownload } from './SupplierListItemsDownload';
import { UploadSupplierList } from './UploadSupplierList';

export const SupplierListSection = (props: RouteComponentProps) => (
  <Router primary={false}>
    <UploadSupplierList path="/upload" />
    <ReviewSupplierList path="/review/:supplierListId" />
    <SupplierListItemsDownload path="/getSupplierListItemsDownload" />
    <PageNotFound default={true} />
  </Router>
);
