import { filter } from 'lodash';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { StylesConfig, ValueType } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ThemeContext } from 'styled-components';
import { styled, Theme } from '../../styling/theme';
import { getValidDropdownOptionsForDropdownValues } from '../dropdowns/dropdownHelpers';
import { FormField } from './FormField';

export const creatableMultiSelectTestId = 'creatable-multi-select';

export type CreatableMultiSelectOptionType = {
  label: string;
  value: string;
};

type CreatableMultiSelectComponentProps = {
  name: string;
  placeholder?: string;
  disabled?: boolean;
  invalid: boolean;
  options: Array<CreatableMultiSelectOptionType>;
  value: Array<string>;
  setFieldValue: (value: unknown) => void;
  setFieldTouched: () => void;
};

const Container = styled.div<React.HTMLAttributes<HTMLDivElement>>``;

const CreatableMultiSelectDropdownComponent = (props: CreatableMultiSelectComponentProps) => {
  const themeContext: Theme = useContext(ThemeContext);

  const [selectedItems, setSelectedItems] = useState<Array<CreatableMultiSelectOptionType>>(
    getValidDropdownOptionsForDropdownValues(props.options, props.value),
  );

  useEffect(() => {
    if (props.value == null) {
      setSelectedItems([]);
    } else {
      const existingItems = getValidDropdownOptionsForDropdownValues(props.options, props.value);
      const existingItemValues = existingItems.map(item => item.value);

      const newItemsValues = filter(props.value, item => !existingItemValues.includes(item));
      const newItems = newItemsValues.map(value => ({ label: value, value }));

      setSelectedItems([...existingItems, ...newItems]);
    }
  }, [props.options, props.value]);

  const onChange = (newlySelectedItems: ValueType<CreatableMultiSelectOptionType, true>) => {
    props.setFieldValue(newlySelectedItems.map(item => item.value));
  };

  return (
    <CreatableSelect
      isMulti={true}
      name={props.name}
      value={selectedItems}
      placeholder={props.placeholder}
      onChange={onChange}
      isDisabled={props.disabled}
      options={props.options}
      styles={creatableSelectStyles(props.invalid, themeContext)}
    />
  );
};

const creatableSelectStyles = (
  invalid: boolean,
  theme: Theme,
): StylesConfig<CreatableMultiSelectOptionType, true> => ({
  control: (provided, state) => {
    const borderWidthAndType = '1px solid';

    const focusStyles = state.isFocused
      ? {
          color: theme.colours.secondary,
          border: `${borderWidthAndType} ${theme.colours.secondary}`,
          backgroundColor: theme.colours.componentBackground,
        }
      : {};

    const invalidStyles = invalid
      ? {
          color: theme.colours.alertText,
          border: `${borderWidthAndType} ${theme.colours.alertText}`,
          backgroundColor: theme.colours.alertBackground,
        }
      : {};

    const disabledStyles = state.isDisabled
      ? {
          color: theme.colours.secondaryHighlight,
          border: `${borderWidthAndType} ${theme.colours.secondaryHighlight}`,
          backgroundColor: theme.colours.appBackground,
        }
      : {};

    return {
      ...provided,
      width: `${theme.formInputWidth.medium}px`,
      padding: '6px 0',
      border: `${borderWidthAndType} ${theme.colours.secondaryInactive}`,
      borderRadius: `${theme.spacing.tiny}px`,
      boxShadow: 'none',
      '&:hover': {}, // prevent default hover styles
      ...focusStyles,
      ...invalidStyles,
      ...disabledStyles,
    };
  },
  multiValue: (provided, state) => {
    return {
      ...provided,
      color: theme.colours.componentBackground,
      backgroundColor: theme.colours.secondaryHighlight,
      borderRadius: `${theme.spacing.tiny}px`,
      margin: `${theme.spacing.extraTiny}px ${theme.spacing.tiny}px ${theme.spacing.extraTiny}px 0`,
    };
  },
  multiValueLabel: (provided, state) => {
    return {
      ...provided,
      color: theme.colours.componentBackground,
    };
  },
});

type MultiSelectDropdownFieldProps = {
  name: string;
  label: string;
  placeholder?: string;
  options: Array<CreatableMultiSelectOptionType>;
  disabled?: boolean;
  infoText?: string;
};

export const CreatableMultiSelectDropdownField = (props: MultiSelectDropdownFieldProps) => {
  return (
    <FormField name={props.name} label={props.label} infoText={props.infoText}>
      {({ field, form, invalid }) => {
        const setFieldValue = (value: unknown) => form.setFieldValue(field.name, value);
        const setFieldTouched = () => form.setFieldTouched(field.name);

        const onBlur = (event: React.FocusEvent<HTMLElement>) => {
          setFieldTouched();
          field.onBlur(event);
        };

        return (
          <Container data-testid={creatableMultiSelectTestId} onBlur={onBlur}>
            <CreatableMultiSelectDropdownComponent
              {...field}
              placeholder={props.placeholder}
              invalid={invalid}
              disabled={props.disabled}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              options={props.options}
            />
          </Container>
        );
      }}
    </FormField>
  );
};
