import { FormikProps, withFormik } from 'formik';
import { TFunction } from 'i18next';
import { debounce, filter, flowRight, forEach, isEmpty, map, orderBy } from 'lodash';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { ById } from '../../models/id';
import { Locale } from '../../models/locale';
import { Accordion } from '../../shared/Accordion';
import { ButtonGroup, HollowButton, PrimaryButton } from '../../shared/buttons/Button';
import { InputField } from '../../shared/forms/InputField';
import { MultiSelectDropdownField } from '../../shared/forms/MultiSelectDropdownField';
import {
  withUserContext,
  WithUserContextProps,
} from '../../shared/higher-order-components/withUserContext';
import { styled } from '../../styling/theme';
import { TranslatableValidator } from '../../utils/validation/TranslatableValidator';
import { InputMaximumLength } from '../../utils/validation/validationConstants';
import { PracticeGroupResponse } from '../sites/practiceGroups/practiceGroup';
import { AccessibleSiteResponse } from '../sites/site';
import { UserFilterFormModel, UserFilterOptions } from './user';
import { FilterAccordionBar } from '../../shared/Filter';
import { CheckboxField } from '../../shared/forms/CheckboxField';
import { NavIcon } from '../layout/header/NavIcon';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { DropdownOption } from '../../shared/forms/DropdownField';
import { NameAndIdToDropdown } from '../deviations/DeviationsBase';
import { useApiRequest } from '../../shared/hooks/useApiRequest';
import { GetSitesByPracticeGroup } from '../../api/sitesApi';
import { NameAndId } from '../deviations/deviation';

type OwnProps = {
  sitesById: ById<AccessibleSiteResponse>;
  practiceGroupsById: ById<PracticeGroupResponse>;
  existingFilters?: UserFilterOptions;
  onApply: (response: UserFilterOptions) => void;
};
type Props = OwnProps & FormikProps<UserFilterFormModel>;

const FlexCheckboxField = styled(CheckboxField)`
  flex: 1 0 25%;
`;
const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: ${props => props.theme.spacing.medium}px;
  margin-bottom: 0;
`;

const FieldRow = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const InvisibleFieldSet = styled.fieldset<React.FieldsetHTMLAttributes<HTMLFieldSetElement>>`
  margin: 0;
  padding: 0;
  border: none;
`;

const SiteSpinnerWrapper = styled.div`
  position: relative;
`;

const SiteSpinner = styled.div`
  position: absolute;
  left: 50px;
`;

const UsersFilterFormComponent = (props: Props) => {
  const { t } = useTranslation(['user']);

  const [loading, setLoading] = useState<Boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<Array<number>>([]);
  const [sitesForDropdown, setSitesForDropdown] = useState<Array<DropdownOption<number>>>([]);

  const { makeRequest, inProgress, clearError, apiError } = useApiRequest(GetSitesByPracticeGroup);

  const clearFields = () => {
    props.setValues({
      searchText: null,
      siteIds: null,
      practiceGroupsIds: null,
      archived: false,
    });
  };

  const practiceGroupOptions = useMemo(
    () =>
      orderBy(
        map(props.practiceGroupsById, practiceGroup => ({
          displayText: practiceGroup.name,
          value: practiceGroup.practiceGroupId,
        })),
        'displayText',
      ),
    [],
  );

  /**
   * Collate the sites on form open instead of hooking into
   * the control data option
   */
  useEffect(() => {
    // Get the sites for the filter or show all
    if (props.existingFilters?.practiceGroupsIds) {
      setSelectedOptions(props.existingFilters?.practiceGroupsIds);
    } else {
      const siteOptions: Array<NameAndId> = map(
        props.sitesById,
        (site): NameAndId => ({
          name: site.siteName,
          id: site.siteId,
        }),
      );
      setSitesForDropdown(NameAndIdToDropdown(siteOptions));
    }
  }, []);

  /**
   * Use Effect to allow debouncing on the change event for the Practice Group DDL,
   * We dont want to hit the server everytime a option is selected as multiple options
   * might be wanted. So debounce for a second to allow the user to apply whatever
   * filter they want - and then query the API. The timeout might need to be adjusted
   * to fit correctly.
   */
  useEffect(() => {
    const fetchSites = async () => {
      makeRequest({
        PracticeGroups: selectedOptions,
      }).then(result => {
        if (result) {
          setSitesForDropdown(
            result.sites.map(m => {
              return {
                displayText: m.siteName,
                value: m.siteId,
              };
            }),
          );
        }
      });

      setLoading(false);
    };
    // Debounced API call
    const debouncedFetchSites = debounce(fetchSites, 1000); // Delay of 1 second
    debouncedFetchSites(); // Call API after the delay when options are selected

    // Cleanup function to cancel the debounce on unmount
    return () => debouncedFetchSites.cancel();
  }, [selectedOptions]);

  const handlePracticeGroupChange = (event: Array<number>) => {
    setLoading(true);
    setSelectedOptions(event);
  };

  return (
    <Accordion
      headerComponent={childProps => (
        <FilterAccordionBar label={t('usersFilter.filter')} isOpen={childProps.isOpen} />
      )}
    >
      {({ toggleAccordion, isOpen }) => {
        const onSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
          props.handleSubmit(e);
          if (isEmpty(props.errors)) {
            toggleAccordion();
          }
        };

        return (
          <form onSubmit={onSubmit} onReset={props.handleReset}>
            <InvisibleFieldSet disabled={!isOpen}>
              <InputField name="searchText" label={t('usersFilter.searchText')} />
              <FieldRow>
                <SiteSpinnerWrapper>
                  <SiteSpinner>
                    {loading && <NavIcon icon={faSpinner} className="fa-spin" />}
                  </SiteSpinner>
                  <MultiSelectDropdownField
                    name="siteIds"
                    label={t('usersFilter.site')}
                    options={sitesForDropdown}
                  />
                </SiteSpinnerWrapper>

                <MultiSelectDropdownField
                  name="practiceGroupsIds"
                  label={t('usersFilter.practiceGroup')}
                  options={practiceGroupOptions}
                  onChange={values => handlePracticeGroupChange(values as Array<number>)}
                />
              </FieldRow>
              <FlexCheckboxField name="archived" label={t('usersFilter.archivedUser')} />
              <StyledButtonGroup>
                <HollowButton onClick={clearFields} type="button" disabled={props.isSubmitting}>
                  {t('usersFilter.clearFieldsButton')}
                </HollowButton>
                <PrimaryButton type="submit" loading={props.isSubmitting}>
                  {t('usersFilter.applyButton')}
                </PrimaryButton>
              </StyledButtonGroup>
            </InvisibleFieldSet>
          </form>
        );
      }}
    </Accordion>
  );
};

const withUserContextEnhancer = withUserContext();

const withTranslationsEnhancer = withTranslation('user');

const withFormikEnhancer = withFormik<
  OwnProps & WithUserContextProps & WithTranslation,
  UserFilterFormModel
>({
  enableReinitialize: true,
  handleSubmit(values, { props, setSubmitting }) {
    props.onApply({
      searchText: values.searchText,
      siteIds: values.siteIds,
      practiceGroupsIds: values.practiceGroupsIds,
      archived: values.archived,
    });
    setSubmitting(false);
  },
  mapPropsToValues: props => {
    return {
      searchText: props.existingFilters?.searchText ?? null,
      siteIds: props.existingFilters?.siteIds ?? null,
      practiceGroupsIds: props.existingFilters?.practiceGroupsIds ?? null,
      archived: props.existingFilters?.archived ?? null,
    };
  },
  validate: (values, props) => {
    const validator = new UserFilterFormValidator(props.t, props.user.locale);
    return validator.validate(values);
  },
});

const enhance = flowRight(withUserContextEnhancer, withTranslationsEnhancer, withFormikEnhancer);

export const UsersFilterForm: React.ComponentType<OwnProps> = enhance(UsersFilterFormComponent);

class UserFilterFormValidator extends TranslatableValidator<UserFilterFormModel> {
  constructor(t: TFunction, locale: Locale) {
    super(t, locale);
    this.ruleFor('searchText')
      .maxLength(InputMaximumLength)
      .withMessage(t('usersFilter.validation.searchText.length'));
  }
}
