import { HubConnectionBuilder } from '@microsoft/signalr';
import * as React from 'react';
import { toast } from 'react-toastify';

type NotificationProps = {};

export const Notification = (props: NotificationProps) => {
  const [load, setLoad] = React.useState(false);
  React.useEffect(() => {
    if (!load) {
      setLoad(true);
      const hubConnection = new HubConnectionBuilder()
        .withUrl(window.location.origin + '/notificationHub')
        .withAutomaticReconnect()
        .build();

      hubConnection.start().then(() => {
        hubConnection.on('ReceiveNotification', (user, message) => {
          toast.info(message.summary, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      });
    }
  }, [load]);

  return <></>;
};
