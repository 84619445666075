import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../shared/buttons/Button';
import { styled } from '../styling/theme';
import { dashboardUrl } from '../urls';

export const pageNotFoundTestId = 'page-not-found';

const NotFoundHeader = styled.h1`
  color: ${props => props.theme.colours.primaryHighlight};
`;

const NotFoundMessage = styled.p`
  color: ${props => props.theme.colours.primary};
  font-size: ${props => props.theme.typography.headings.h4fontSize}px;
`;

const navigateToDashboard = (props: RouteComponentProps) => () =>
  props.navigate && props.navigate(dashboardUrl());

export const PageNotFound = (props: RouteComponentProps) => {
  const { t } = useTranslation('pageNotFound');
  return (
    <div data-testid={pageNotFoundTestId}>
      <NotFoundHeader>{t('header')}</NotFoundHeader>
      <NotFoundMessage>{t('message')}</NotFoundMessage>
      <PrimaryButton onClick={navigateToDashboard(props)}>{t('toDashboard')}</PrimaryButton>
    </div>
  );
};
