import * as React from 'react';
import { Context } from 'react';
import { AccessToken } from './accessToken';

export type AuthenticationContextValue = {
  getAccessToken: () => Promise<AccessToken>;
  logout: () => void;
};

export const AuthenticationContext: Context<AuthenticationContextValue> = React.createContext<
  AuthenticationContextValue
>({
  getAccessToken: () => Promise.reject('Authentication context not set'),
  logout: () => {
    throw new Error('Authentication context not set');
  },
});
