import { FormikErrors, FormikTouched, getIn } from 'formik';
import { some } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '../../styling/theme';
import { ErrorBox } from './ErrorBox';

type Props<TFormModel> = {
  errors: FormikErrors<TFormModel>;
  touched: FormikTouched<TFormModel>;
  errorMessage?: string;
};

const FormErrorBox = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  margin-top: ${props => props.theme.spacing.small}px;
`;

export const formValidationErrorBoxTestId = 'form-validation-error-box-test-id';
export const FormValidationErrorBox = <TFormModel extends unknown>(props: Props<TFormModel>) => {
  const { t } = useTranslation('component');

  const errorMessage: string = props.errorMessage || t('formValidationErrorBox.message');

  const hasError = some(props.errors, (value, key) => getIn(props.touched, key));

  return hasError ? (
    <FormErrorBox data-testid={formValidationErrorBoxTestId}>
      <ErrorBox errors={errorMessage} />
    </FormErrorBox>
  ) : null;
};
