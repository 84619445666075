import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faChevronLeft, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteComponentProps } from '@reach/router';
import { TFunction } from 'i18next';
import * as React from 'react';
import { useContext } from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { Id } from '../../models/id';
import { DataLoader } from '../../shared/DataLoader';
import { FieldAndValue } from '../../shared/FieldAndValue';
import {
  ApiFetchProps,
  fetchFromApiOnLoad,
} from '../../shared/higher-order-components/fetchFromApiOnLoad';
import { InfoBox } from '../../shared/info/InfoBox';
import { Link } from '../../shared/navigation/Link';
import { Panel, PanelsGroup } from '../../shared/panel/Panel';
import {
  IfUserHasOneOfPermissions,
  IfUserHasPermission,
  RequiresOneOfPermissions,
} from '../../shared/Permissions';
import { styled } from '../../styling/theme';
import { itemHIstory, itemsUrl } from '../../urls';
import { LoginUserResponse } from '../authentication/loginData/user';
import { UserContext } from '../authentication/loginData/userContext';
import { permissions } from '../authentication/permissions';
import { PageHeading } from '../layout/PageHeading';
import {
  CountrySpecificGrossValues,
  CountrySpecificItemDetailsResponse,
  ItemResponse,
} from './item';
import { getItem, getItemFieldsFromPms } from './itemsApi';
import { ItemBarcodes } from './ItemsTables/ItemBarcodes';
import { ItemDiscountCategories } from './ItemsTables/ItemDiscountCategories';
import { ItemDispensingLabels } from './ItemsTables/ItemDispensingLabels';
import { getSupplierProductForCountry } from './ItemSupplierProducts/SupplierAndSupplierProductFieldsLoader';
import { ItemSupplierProductResponse } from './ItemSupplierProducts/supplierProduct';
import { ItemNotes } from './ItemsTables/ItemNotes';
import { editItemUrl, viewItemBulkEditUrl } from './itemUrls';
import { ItemFieldsFromPmsResponse, itemPriceField, ItemType } from './PmsFields/pmsFields';
import { ReminderTypeGroupView } from './ReminderTypeGroupView';
import { CountryResponse } from '../authentication/loginData/metadata';
import { MetadataContext } from '../authentication/loginData/metadataContext';

type OwnProps = RouteComponentProps<{ itemId: string }> & {};
type Props = OwnProps & ApiFetchProps<ItemResponse>;

const ReturnLinkContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing.small}px;
`;

const ReturnText = styled.span`
  margin-left: ${props => props.theme.spacing.tiny}px;
`;

export const ReturnToItemsListLink = ({ text }: { text: string }) => (
  <ReturnLinkContainer>
    <Link to={itemsUrl()}>
      <FontAwesomeIcon icon={faChevronLeft} />
      <ReturnText>{text}</ReturnText>
    </Link>
  </ReturnLinkContainer>
);
const StyledItemFieldList: React.ComponentType = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledInfoSpan = styled.span``;

const MarginRightIcon = styled(FontAwesomeIcon)`
  margin-right: ${props => props.theme.spacing.tiny}px;
`;

const MarginRightInfoText = styled.span`
  margin-right: ${props => props.theme.spacing.tiny}px;
`;

type ScheduledForUpdateBarProps = {
  t: TFunction;
  scheduledItemBulkEditId: Id | null;
  message: string;
};
export const ScheduledForUpdateInfoBar = ({
  t,
  scheduledItemBulkEditId,
  message,
}: ScheduledForUpdateBarProps) => (
  <>
    {scheduledItemBulkEditId && (
      <InfoBox
        message={
          <StyledInfoSpan>
            <MarginRightIcon icon={faClock} />
            <MarginRightInfoText>{message}</MarginRightInfoText>
            <Link to={viewItemBulkEditUrl(scheduledItemBulkEditId)}>
              {t('view.viewBulkEditButton')}
            </Link>
          </StyledInfoSpan>
        }
      />
    )}
  </>
);

const SubSection = styled.div`
  margin-left: ${props => props.theme.spacing.medium}px;
`;

const StyledPanel = styled(Panel)`
  min-width: 500px;
`;

type ItemInfoPanelProps = {
  itemFields: ItemFieldsFromPmsResponse;
  item: ItemResponse;
  type: ItemType;
  t: TFunction;
};

const ItemInfoPanel = ({ itemFields, item, type, t }: ItemInfoPanelProps) => (
  <StyledPanel title={t('view.sectionTitle.itemInfo')}>
    <FieldAndValue
      fieldName={t('itemLabels.type')}
      value={item.type && t<string>(type.translationKey)}
      type="text"
      layout="vertical"
    />
    <FieldAndValue
      fieldName={t('itemLabels.description')}
      value={item.itemName}
      type="text"
      layout="vertical"
    />
    <FieldAndValue
      fieldName={t('itemLabels.category1')}
      value={item.category1Id && itemFields.category1Options[item.category1Id].name}
      type="text"
      layout="vertical"
    />
    <FieldAndValue
      fieldName={t('itemLabels.category2')}
      value={item.category2Id && itemFields.category2Options[item.category2Id].name}
      type="text"
      layout="vertical"
    />
    <FieldAndValue
      fieldName={t('itemLabels.category3')}
      value={item.category3Id && itemFields.category3Options[item.category3Id].name}
      type="text"
      layout="vertical"
    />
    <FieldAndValue
      fieldName={t('itemLabels.itemCode')}
      value={item.itemCode}
      type="text"
      layout="vertical"
    />
  </StyledPanel>
);

type GrossPriceFieldProps = {
  fieldName: keyof CountrySpecificGrossValues;
  item: ItemResponse;
  t: TFunction;
  userCountries: Array<CountryResponse>;
};

const GrossPriceField = ({ fieldName, item, t, userCountries }: GrossPriceFieldProps) => {
  const displayValues = item.countrySpecificItemDetailsResponseList
    ?.filter(csid => userCountries.map(c => c.code).includes(csid.country.code))
    .map(csid => csid[fieldName] as number);
  const currencies = userCountries.map(countries => countries.currency);

  return (
    <FieldAndValue
      fieldName={t(`view.field.${fieldName}`)}
      value={displayValues}
      type="currency"
      currency={currencies}
      layout="vertical"
    />
  );
};

type PricingDetailsPanelProps = {
  itemFields: ItemFieldsFromPmsResponse;
  item: ItemResponse;
  user: LoginUserResponse;
  t: TFunction;
  userCountries: Array<CountryResponse>;
};

const PricingDetailsPanel = ({
  itemFields,
  item,
  user,
  t,
  userCountries,
}: PricingDetailsPanelProps) => {
  const priceField = itemPriceField(item.type, item.category1Id, itemFields);

  return (
    <StyledPanel title={t('view.sectionTitle.pricingDetails')}>
      {item.type !== 'Service' && (
        <FieldAndValue
          fieldName={t('itemLabels.unitsPerPack')}
          value={item.unitsPerPack}
          type="number"
          layout="vertical"
        />
      )}
      {priceField === 'CentralMarkupPercentage' && (
        <FieldAndValue
          fieldName={t('view.field.centralMarkupPercentage')}
          value={item.centralMarkupPercentage}
          type="number"
          layout="vertical"
        />
      )}
      {item.type !== 'Service' &&
        (user.useGrossPrices ? (
          <GrossPriceField
            fieldName={'dispenseFeeGross'}
            item={item}
            t={t}
            userCountries={userCountries}
          />
        ) : (
          <FieldAndValue
            fieldName={t('view.field.dispenseFeeNet')}
            value={item.dispenseFeeNet}
            type="currency"
            currency={['GBP', 'EUR']}
            layout="vertical"
          />
        ))}
      {item.type !== 'Service' &&
        (user.useGrossPrices ? (
          <GrossPriceField
            fieldName={'minimumPriceGross'}
            item={item}
            t={t}
            userCountries={userCountries}
          />
        ) : (
          <FieldAndValue
            fieldName={t('view.field.minimumPriceNet')}
            value={item.minimumPriceNet}
            type="currency"
            currency={['GBP', 'EUR']}
            layout="vertical"
          />
        ))}
      {item.type !== 'Service' && (
        <>
          <FieldAndValue
            fieldName={t('view.field.netNetDiscount')}
            value={item.netNetDiscount}
            type="number"
            layout="vertical"
          />
          <FieldAndValue
            fieldName={t('view.field.netNetNetDiscount')}
            value={item.netNetNetDiscount}
            type="number"
            layout="vertical"
          />
        </>
      )}
      {priceField === 'RecommendedPrice' &&
        (user.useGrossPrices ? (
          <GrossPriceField
            fieldName={'recommendedPriceGross'}
            item={item}
            t={t}
            userCountries={userCountries}
          />
        ) : (
          <FieldAndValue
            fieldName={t('view.field.recommendedPriceNet')}
            value={item.recommendedPriceNet}
            type="currency"
            layout="vertical"
          />
        ))}
      {priceField === 'Discount' && item.percentageDiscount != null && (
        <FieldAndValue
          fieldName={t('view.field.percentageDiscount')}
          value={item.percentageDiscount}
          type="number"
          layout="vertical"
        />
      )}
      {priceField === 'Discount' &&
        item.absoluteDiscountNet != null &&
        (user.useGrossPrices ? (
          <GrossPriceField
            fieldName={'absoluteDiscountGross'}
            item={item}
            t={t}
            userCountries={userCountries}
          />
        ) : (
          <FieldAndValue
            fieldName={t('view.field.absoluteDiscountNet')}
            value={item.absoluteDiscountNet}
            type="currency"
            layout="vertical"
          />
        ))}
      <FieldAndValue
        fieldName={t('view.field.costType')}
        value={item.costTypeId && itemFields.costTypeOptions[item.costTypeId].name}
        type="text"
        layout="vertical"
      />
      <FieldAndValue
        fieldName={t('itemLabels.discountCategoryEnabled')}
        value={item.discountCategoryEnabled}
        type="boolean"
        layout="vertical"
      />
      <ItemDiscountCategories discountCategory={item.discountCategory} t={t} />
    </StyledPanel>
  );
};

type CountryDetailsPanelProps = {
  itemFields: ItemFieldsFromPmsResponse;
  item: ItemResponse;
  supplierProduct?: ItemSupplierProductResponse;
  user: LoginUserResponse;
  t: TFunction;
  country: CountryResponse;
  countrySpecificItemDetails: CountrySpecificItemDetailsResponse;
};

const CountryDetailsPanel = ({
  itemFields,
  item,
  supplierProduct,
  user,
  t,
  country,
  countrySpecificItemDetails,
}: CountryDetailsPanelProps) => {
  return (
    <StyledPanel title={t(`view.sectionTitle.countryDetails.${country.code}`)}>
      {!!supplierProduct && (
        <>
          <FieldAndValue
            fieldName={t('itemLabels.supplier')}
            value={supplierProduct.supplierName}
            type="text"
            layout="vertical"
          />
          <FieldAndValue
            fieldName={t('itemLabels.supplierCode')}
            value={supplierProduct.supplierCode}
            type="text"
            layout="vertical"
          />
          {user.useGrossPrices ? (
            <FieldAndValue
              fieldName={t('itemLabels.listPriceGross')}
              value={supplierProduct.listPriceGross}
              type="currency"
              currency={country.currency}
              layout="vertical"
            />
          ) : (
            <FieldAndValue
              fieldName={t('itemLabels.listPriceNet')}
              value={supplierProduct.listPrice}
              type="currency"
              currency={country.currency}
              layout="vertical"
            />
          )}
        </>
      )}
      <FieldAndValue
        fieldName={t('itemLabels.taxRate')}
        value={itemFields.taxRateOptions[countrySpecificItemDetails?.taxRateId].name}
        type="text"
        layout="vertical"
      />
      {item.type !== 'Service' && (
        <FieldAndValue
          fieldName={t('itemLabels.legalCategory')}
          value={
            countrySpecificItemDetails?.legalCategoryId
              ? itemFields.legalCategoryOptions[countrySpecificItemDetails.legalCategoryId].name
              : null
          }
          type="text"
          layout="vertical"
        />
      )}
      <FieldAndValue
        fieldName={t('itemLabels.overwrite')}
        value={countrySpecificItemDetails?.overwrite}
        type="boolean"
        layout="vertical"
      />
      {!!countrySpecificItemDetails.overwrite && (
        <FieldAndValue
          fieldName={t('itemLabels.overwriteAmount')}
          value={countrySpecificItemDetails.overwriteAmount}
          type="currency"
          currency={country.currency}
          layout="vertical"
        />
      )}
    </StyledPanel>
  );
};

type TreatmentDetailsPanelProps = {
  itemFields: ItemFieldsFromPmsResponse;
  item: ItemResponse;
  type: ItemType;
  t: TFunction;
};

const TreatmentDetailsPanel = ({ itemFields, item, type, t }: TreatmentDetailsPanelProps) => (
  <StyledPanel title={t('view.sectionTitle.treatmentDetails')}>
    <FieldAndValue
      fieldName={t('view.field.hcpGroup')}
      value={item.hcpGroupId && itemFields.hcpGroupOptions[item.hcpGroupId].name}
      type="text"
      layout="vertical"
    />
    <FieldAndValue
      fieldName={t('view.field.hcpMonthlyMultiplier')}
      value={item.hcpMonthlyMultiplier}
      type="number"
      layout="vertical"
    />
    <FieldAndValue
      fieldName={t('itemLabels.species')}
      value={item.speciesId && itemFields.speciesOptions[item.speciesId].name}
      type="text"
      layout="vertical"
    />
    <FieldAndValue
      fieldName={t('itemLabels.clientCategory')}
      value={item.clientCategoryId && itemFields.clientCategoryOptions[item.clientCategoryId].name}
      type="text"
      layout="vertical"
    />
    <FieldAndValue
      fieldName={t('itemLabels.labelRequired')}
      value={item.requestLabel}
      type="boolean"
      layout="vertical"
    />
    <SubSection>
      <FieldAndValue
        fieldName={t('itemLabels.labelCalcQuantity')}
        value={item.labelCalcQuantity}
        type="boolean"
        layout="vertical"
      />
      <FieldAndValue
        fieldName={t('itemLabels.labelAdditionalInfo')}
        value={item.labelAdditionalInfo}
        type="text"
        layout="vertical"
      />
      {item.type !== 'Service' && (
        <ItemDispensingLabels dispensingLabels={item.labelDispensingNote} t={t} />
      )}
    </SubSection>
    <FieldAndValue
      fieldName={t('view.field.internalInformation')}
      value={item.internalInformation}
      type="text"
      layout="vertical"
    />
    {/*
        Document Template code - Removed until MWI fix the API ZenDesk https://ivc.zendesk.com/hc/en-gb/requests/540771
    */}
    {item.type !== 'Service' && (
      <FieldAndValue
        fieldName={t('itemLabels.documentTemplate')}
        value={
          item.documentTemplateId
            ? itemFields.documentTemplateOptions[item.documentTemplateId].name
            : item.documentTemplateId
        }
        type="text"
        layout="vertical"
      />
    )}

    <ReminderTypeGroupView
      reminderDelta={item.reminderDelta ?? ''}
      reminderEnabled={item.reminderEnabled ?? false}
      reminderLength={item.reminderLength ?? 0}
      reminderTypeId={item.reminderTypeId ?? 0}
      reminderTypeOptions={itemFields.reminderTypeOptions}
    />

    {item.type !== 'Service' && (
      <FieldAndValue
        fieldName={t('view.field.manufacturer')}
        value={item.manufacturerId ? itemFields.manufacturerOptions[item.manufacturerId].name : ''}
        type="text"
        layout="vertical"
      />
    )}

    <FieldAndValue
      fieldName={t('view.field.sendTo')}
      value={item.sendTo?.map((item: number) => itemFields.sendToOptions[item].name).join(', ')}
      type="text"
      layout="vertical"
    />

    <FieldAndValue
      fieldName={t('view.field.notes')}
      value={item.notes}
      type="text"
      layout="vertical"
    />
  </StyledPanel>
);

type MiscDetailsPanelProps = {
  itemFields: ItemFieldsFromPmsResponse;
  item: ItemResponse;
  t: TFunction;
};

const MiscDetailsPanel = ({ itemFields, item, t }: MiscDetailsPanelProps) => (
  <StyledPanel includeSpaceForTitle={true}>
    {item.type !== 'Service' && (
      <FieldAndValue
        fieldName={t('itemLabels.shouldSellAtCost')}
        value={item.shouldSellAtCost}
        type="boolean"
      />
    )}
    <FieldAndValue
      fieldName={t('itemLabels.shouldRequestQuantity')}
      value={item.shouldRequestQuantity}
      type="boolean"
    />
    <FieldAndValue
      fieldName={t('itemLabels.isBatchRequest')}
      value={item.isBatchRequest}
      type="boolean"
    />
    <FieldAndValue
      fieldName={t('itemLabels.shouldPromptChipDetails')}
      value={item.shouldPromptChipDetails}
      type="boolean"
    />
    <FieldAndValue
      fieldName={t('itemLabels.shouldAlterSex')}
      value={item.shouldAlterSex}
      type="boolean"
    />
    <FieldAndValue fieldName={t('itemLabels.wormer')} value={item.wormer} type="boolean" />
    <FieldAndValue fieldName={t('itemLabels.flea')} value={item.flea} type="boolean" />
    <FieldAndValue
      fieldName={t('view.field.wholepackPriceEnabled')}
      value={item.wholepackPriceEnabled}
      type="boolean"
    />
    <FieldAndValue
      fieldName={t('view.field.wholepackExcludeDispFee')}
      value={item.wholepackExcludeDispFee}
      type="boolean"
    />
    <FieldAndValue
      fieldName={t('view.field.isMultiDispensingFee')}
      value={item.isMultiDispensingFee}
      type="boolean"
    />
    <FieldAndValue
      fieldName={t('itemLabels.repeatPrescription')}
      value={item.repeatPrescription}
      type="boolean"
    />
    <FieldAndValue
      fieldName={t('itemLabels.preventHide')}
      value={item.preventHide}
      type="boolean"
    />
    <FieldAndValue
      fieldName={t('itemLabels.isHiddenForAllPractices')}
      value={item.isHiddenForAllPractices}
      type="boolean"
    />
  </StyledPanel>
);

type ItemFieldsSectionProps = {
  itemFields: ItemFieldsFromPmsResponse;
  t: TFunction;
  item: ItemResponse;
};

const ItemFieldsSection = ({ itemFields, t, item }: ItemFieldsSectionProps) => {
  const { user } = useContext(UserContext);
  const { countries } = useContext(MetadataContext);
  const type = itemFields.typeOptions[item.type];
  const userCountries = countries.filter(country => user.countries.includes(country.code));

  return (
    <>
      {item.countrySpecificItemDetailsResponseList?.length === 0 &&
        type.category.requiresSupplierProducts && (
          <InfoBox
            message={
              <StyledInfoSpan>
                <MarginRightIcon icon={faExclamationTriangle} />
                <MarginRightInfoText>{t('view.hasNoSupplierProducts')}</MarginRightInfoText>
              </StyledInfoSpan>
            }
          />
        )}
      <ScheduledForUpdateInfoBar
        t={t}
        scheduledItemBulkEditId={item.flags.scheduledItemBulkEditId}
        message={t('view.scheduledForBulkEdit')}
      />
      <PanelsGroup>
        <ItemInfoPanel itemFields={itemFields} item={item} type={type} t={t} />
        <PricingDetailsPanel
          itemFields={itemFields}
          item={item}
          user={user}
          t={t}
          userCountries={userCountries}
        />
        {userCountries.map((country, index) => {
          const countrySpecificItemDetails = item.countrySpecificItemDetailsResponseList?.find(
            csd => csd.country.code === country.code,
          );
          return countrySpecificItemDetails && countrySpecificItemDetails.isEnabledForCountry ? (
            <CountryDetailsPanel
              key={index}
              itemFields={itemFields}
              item={item}
              supplierProduct={getSupplierProductForCountry(
                item.countrySpecificItemDetailsResponseList,
                country.code,
              )}
              user={user}
              t={t}
              country={country}
              countrySpecificItemDetails={countrySpecificItemDetails}
            />
          ) : null;
        })}
        <TreatmentDetailsPanel itemFields={itemFields} item={item} type={type} t={t} />
        <MiscDetailsPanel itemFields={itemFields} item={item} t={t} />
      </PanelsGroup>
      <StyledItemFieldList>
        {item.type !== 'Service' && <ItemBarcodes barcodes={item.barcode} t={t} />}
      </StyledItemFieldList>
      <StyledItemFieldList>
        <ItemNotes notes={item.noteDetails} t={t} locale={user.locale} />
      </StyledItemFieldList>
    </>
  );
};

const ViewItemComponent = (props: Props) => {
  const { t } = useTranslation(['item', 'libraries']);
  const item = props.response;

  return (
    <>
      <ReturnToItemsListLink text={t('view.returnToListButton')} />
      <Title>{t('view.title')}</Title>
      <PageHeading>
        <h1>
          {item.itemName}
          {item.isDisabled && ` ${t('view.disabled')}`}
        </h1>
        <div>
          <IfUserHasOneOfPermissions
            permissions={[permissions.SuperAdmin, permissions.CentralAdmin]}
          >
            <Link to={editItemUrl(item.itemId)}>{t('view.editButton')}</Link>
          </IfUserHasOneOfPermissions>
          <IfUserHasOneOfPermissions permissions={[permissions.IVCAdmin]}>
            <br />
            <Link to={itemHIstory(item.itemId)}>{t('view.itemHistoryButton')}</Link>
          </IfUserHasOneOfPermissions>
        </div>
      </PageHeading>

      <DataLoader apiRequest={getItemFieldsFromPms}>
        {itemFields => (
          <>
            <ItemFieldsSection itemFields={itemFields.response} t={t} item={item} />
          </>
        )}
      </DataLoader>
    </>
  );
};

const enhance = fetchFromApiOnLoad<OwnProps, ItemResponse, Id>(
  props => Number(props.itemId),
  getItem,
);

export const ViewItem = enhance(ViewItemComponent);
