import { RouteComponentProps } from '@reach/router';
import { flowRight } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { Id } from '../../models/id';
import { Button, PrimaryButton } from '../../shared/buttons/Button';
import { ApiErrorBox } from '../../shared/errors/ApiErrorBox';
import { FieldAndJsonValue, FieldAndValue } from '../../shared/FieldAndValue';
import {
  ApiFetchProps,
  fetchFromApiOnLoad,
} from '../../shared/higher-order-components/fetchFromApiOnLoad';
import { useApiRequest } from '../../shared/hooks/useApiRequest';
import { requiresOneOfPermissions } from '../../shared/Permissions';
import { styled } from '../../styling/theme';
import { dashboardUrl } from '../../urls';
import { navigate } from '../../utils/routing';
import { permissions } from '../authentication/permissions';
import { PageHeading } from '../layout/PageHeading';
import { GetPmsRequestErrorResponse } from './pmsRequestError';
import { clearPmsRequestError, getPmsRequestError } from './pmsRequestErrorApi';

type OwnProps = RouteComponentProps<{ errorId: string }> & {};
type Props = OwnProps & ApiFetchProps<GetPmsRequestErrorResponse>;

const StyledFieldList = styled.div`
  display: flex;
  flex-direction: column;
`;

const ViewPmsRequestErrorComponent = (props: Props) => {
  const { t } = useTranslation('pmsRequestError');
  const { makeRequest, apiError, inProgress } = useApiRequest(clearPmsRequestError);
  const [displayError, setDisplayError] = useState(false);
  const pmsError = props.response;

  const onClearButtonClick = () => {
    if (window.confirm(t('view.confirmClearButton'))) {
      makeRequest(Number(props.errorId)).then(() => {
        if (apiError === null) {
          navigate(dashboardUrl(), {
            suppressUserConfirmation: true,
            state: { pmsRequestErrorCleared: true },
          });
        }
      });
    }
  };

  return (
    <>
      <Title>{t('view.title')}</Title>
      <PageHeading>
        <h1>{t('view.heading')}</h1>
        {!pmsError.isCleared && (
          <PrimaryButton onClick={onClearButtonClick} loading={inProgress}>
            {t('view.clear')}
          </PrimaryButton>
        )}
      </PageHeading>
      <StyledFieldList>
        <FieldAndValue
          fieldName={t('view.fieldLabels.time')}
          value={pmsError.time}
          type={'datetime'}
        />
        <FieldAndValue
          fieldName={t('view.fieldLabels.requestSummary')}
          value={pmsError.requestSummary}
          type={'text'}
        />
        <FieldAndValue
          fieldName={t('view.fieldLabels.userEmail')}
          value={pmsError.userEmail}
          type={'text'}
          hideIfMissing={true}
        />
        <FieldAndValue
          fieldName={t('view.fieldLabels.pmsName')}
          value={`${pmsError.pmsName} [${pmsError.merlinGroupId ?? ''}]`}
          type={'text'}
          hideIfMissing={true}
        />
        <FieldAndValue
          fieldName={t('view.fieldLabels.url')}
          value={pmsError.url}
          type={'text'}
          hideIfMissing={true}
        />
        <FieldAndValue
          fieldName={t('view.fieldLabels.requestMethod')}
          value={pmsError.requestMethod}
          type={'text'}
          hideIfMissing={true}
        />
        <FieldAndValue
          fieldName={t('view.fieldLabels.errorMessage')}
          value={pmsError.errorMessage}
          type={'text'}
          hideIfMissing={true}
        />
        <Button
          style={{ backgroundColor: 'lightGray' }}
          onClick={() => {
            setDisplayError(!displayError);
          }}
        >
          {!displayError && 'Show error messages'}
          {displayError && 'Hide error messages'}
        </Button>
        {displayError && (
          <>
            <FieldAndJsonValue
              fieldName={t('view.fieldLabels.responseContent')}
              value={pmsError.responseContent}
              hideIfMissing={true}
            />
            <FieldAndJsonValue
              fieldName={t('view.fieldLabels.requestData')}
              value={pmsError.requestData}
              hideIfMissing={true}
            />
          </>
        )}
      </StyledFieldList>
      {apiError && <ApiErrorBox error={apiError} />}
    </>
  );
};

const fetchFromApiOnLoadEnhancer = fetchFromApiOnLoad<OwnProps, GetPmsRequestErrorResponse, Id>(
  props => Number(props.errorId),
  getPmsRequestError,
);

const enhance = flowRight(
  requiresOneOfPermissions(permissions.SuperAdmin, permissions.CentralAdmin),
  fetchFromApiOnLoadEnhancer,
);

export const ViewPmsRequestError = enhance(ViewPmsRequestErrorComponent);
