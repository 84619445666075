import { uniq } from 'lodash';
import {
  GenericGroupedTreatmentTypeDeviationRow,
  GroupedTreatmentTypeDeviationBulkEditRow,
  GroupedTreatmentTypeDeviationResponseRow,
  TreatmentTypeDeviationFilterDropdownOptionsResponse,
  TreatmentTypeDeviationResponse,
  TreatmentTypeDeviationsResponse,
  TreatmentTypeDeviationTableRow,
} from './treatmentTypeDeviations';
import { ItemFieldsFromPmsResponse } from '../../items/PmsFields/pmsFields';
import { PracticeGroupResponse } from '../../sites/practiceGroups/practiceGroup';
import { Id } from '../../../models/id';
import {
  TreatmentTypeDeviationBulkEditLineResponse,
  TreatmentTypeDeviationBulkEditResponse,
} from './bulkEdit/treatmentTypeDeviationBulkEdit';

export type TreatmentTypeDeviationsBaseProps = {
  filterDropdownOptions: TreatmentTypeDeviationFilterDropdownOptionsResponse;
  itemFieldsFromPms: ItemFieldsFromPmsResponse;
  practiceGroups: Array<PracticeGroupResponse>;
};

export const mapTreatmentTypeDeviationsResponseToGroupedTableData = (
  response: TreatmentTypeDeviationsResponse,
): Array<GroupedTreatmentTypeDeviationResponseRow> => {
  const deviations = response.deviationResponsesAndScheduledBulkEdits.deviationResponses;

  const practiceGroupIds = uniq(deviations.flatMap(deviation => deviation.practiceGroupIds));
  const filteredPracticeGroupIds = !!response.filterOptions?.practiceGroupIds?.length
    ? practiceGroupIds.filter(practiceGroupId =>
        response.filterOptions?.practiceGroupIds?.includes(practiceGroupId),
      )
    : practiceGroupIds;

  const treatmentTypeIds = uniq(deviations.map(deviation => deviation.treatmentTypeId));

  const groupedDeviationsWithPracticeGroups = filteredPracticeGroupIds.flatMap(practiceGroupId =>
    treatmentTypeIds.map(treatmentTypeId =>
      getGroupedTreatmentTypeDeviationRow(
        practiceGroupId,
        treatmentTypeId,
        deviations.filter(
          deviation =>
            deviation.practiceGroupIds.includes(practiceGroupId) &&
            deviation.treatmentTypeId === treatmentTypeId,
        ),
      ),
    ),
  );
  const deviationsWithoutPracticeGroups = deviations.filter(
    deviation => deviation.practiceGroupIds.length === 0,
  );

  if (deviationsWithoutPracticeGroups.length === 0) {
    return groupedDeviationsWithPracticeGroups.filter(
      group => group.deviations != null && group.deviations.length > 0,
    );
  } else {
    const groupedDeviationsWithoutPracticeGroups = treatmentTypeIds.map(treatmentTypeId =>
      getGroupedTreatmentTypeDeviationRow(
        undefined,
        treatmentTypeId,
        deviationsWithoutPracticeGroups.filter(
          deviation => deviation.treatmentTypeId === treatmentTypeId,
        ),
      ),
    );

    return groupedDeviationsWithPracticeGroups
      .concat(groupedDeviationsWithoutPracticeGroups)
      .filter(group => group.deviations != null && group.deviations.length > 0);
  }
};

const getGroupedTreatmentTypeDeviationRow = (
  practiceGroupId: Id | undefined,
  treatmentTypeId: Id | undefined,
  deviations: Array<TreatmentTypeDeviationResponse>,
): GroupedTreatmentTypeDeviationResponseRow => {
  return {
    practiceGroupId,
    treatmentTypeId,
    deviations,
  } as GroupedTreatmentTypeDeviationResponseRow;
};

export const getGroupedTreatmentTypeDeviationSubRows = <T extends TreatmentTypeDeviationTableRow>(
  groupedRow: GenericGroupedTreatmentTypeDeviationRow<T>,
): Array<GenericGroupedTreatmentTypeDeviationRow<T>> => groupedRow.deviations ?? [];

export const mapTreatmentTypeDeviationsBulkEditResponseToGroupedTableData = (
  response: TreatmentTypeDeviationBulkEditResponse,
): Array<GroupedTreatmentTypeDeviationBulkEditRow> => {
  const practiceGroupIds = uniq(
    response.treatmentTypeDeviationBulkEditLines.flatMap(editLine => editLine.practiceGroupIds),
  );

  const treatmentTypeIds = uniq(
    response.treatmentTypeDeviationBulkEditLines.map(editLine => editLine.treatmentTypeId),
  );

  const groupedDeviationsWithPracticeGroups = practiceGroupIds.flatMap(practiceGroupId =>
    treatmentTypeIds.map(treatmentTypeId =>
      getGroupedTreatmentTypeDeviationBulkEditRow(
        practiceGroupId,
        treatmentTypeId,
        response.treatmentTypeDeviationBulkEditLines.filter(
          editLine =>
            editLine.practiceGroupIds.includes(practiceGroupId) &&
            editLine.treatmentTypeId === treatmentTypeId,
        ),
      ),
    ),
  );

  const deviationsWithoutPracticeGroups = response.treatmentTypeDeviationBulkEditLines.filter(
    editLine => editLine.practiceGroupIds.length === 0,
  );

  if (deviationsWithoutPracticeGroups.length === 0) {
    return groupedDeviationsWithPracticeGroups.filter(
      group => group.deviations != null && group.deviations.length > 0,
    );
  } else {
    const groupedDeviationsWithoutPracticeGroups = treatmentTypeIds.map(treatmentTypeId =>
      getGroupedTreatmentTypeDeviationBulkEditRow(
        undefined,
        treatmentTypeId,
        deviationsWithoutPracticeGroups.filter(
          deviation => deviation.treatmentTypeId === treatmentTypeId,
        ),
      ),
    );

    return groupedDeviationsWithPracticeGroups
      .concat(groupedDeviationsWithoutPracticeGroups)
      .filter(group => group.deviations != null && group.deviations.length > 0);
  }
};

const getGroupedTreatmentTypeDeviationBulkEditRow = (
  practiceGroupId: Id | undefined,
  treatmentTypeId: Id | undefined,
  deviations: Array<TreatmentTypeDeviationBulkEditLineResponse>,
): GroupedTreatmentTypeDeviationBulkEditRow => {
  return {
    practiceGroupId,
    treatmentTypeId,
    deviations,
  } as GroupedTreatmentTypeDeviationBulkEditRow;
};
