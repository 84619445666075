import { FormikProps, withFormik } from 'formik';
import { TFunction } from 'i18next';
import { debounce, flowRight, isEmpty, union } from 'lodash';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { Locale } from '../../models/locale';
import { ButtonGroup, HollowButton, PrimaryButton } from '../../shared/buttons/Button';
import { Expandable } from '../../shared/Expandable';
import { CheckboxField } from '../../shared/forms/CheckboxField';
import { DropdownOption } from '../../shared/forms/DropdownField';
import { InputField } from '../../shared/forms/InputField';
import { MultiSelectDropdownField } from '../../shared/forms/MultiSelectDropdownField';
import {
  withUserContext,
  WithUserContextProps,
} from '../../shared/higher-order-components/withUserContext';
import { styled } from '../../styling/theme';
import { TranslatableValidator } from '../../utils/validation/TranslatableValidator';
import {
  ItemFieldsFromPmsResponse,
  filterCategory1OptionsByType,
  itemLibraryDropdownOptions,
} from '../items/PmsFields/pmsFields';
import {
  checkboxesToComparisonType,
  comparisonIsEqualTo,
  comparisonIsGreaterThan,
  comparisonIsLessThan,
} from './comparisonType';
import {
  DevationItemType,
  DevationSource,
  DeviationFilterDropdownOptionsResponse,
  DeviationFilterFormModel,
  DeviationFilterOptions,
  ItemDropdownItem,
} from './deviation';
import { NameAndIdToDropdown } from './DeviationsBase';
import { AdvancedFiltersButton, FilterAccordionBar, FilterForm } from '../../shared/Filter';
import { SingleSelectDropdownField } from '../../shared/forms/SingleSelectDropdownField';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { NavIcon } from '../layout/header/NavIcon';
import { GetSitesByPracticeGroup } from '../../api/sitesApi';
import { useApiRequest } from '../../shared/hooks/useApiRequest';

type OwnProps = {
  existingFilters?: DeviationFilterOptions;
  onApply: (response: DeviationFilterOptions) => void;
  deviationFilterDropdownOptions: DeviationFilterDropdownOptionsResponse;
  itemFieldsFromPms: ItemFieldsFromPmsResponse;
};
type Props = OwnProps & FormikProps<DeviationFilterFormModel>;

const FlexRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const FlexButtonGroup = styled(ButtonGroup)`
  flex: 1 0 100%;
  align-self: center;
  margin-bottom: 0;
  margin-top: ${props => props.theme.spacing.small}px;
`;

const FlexCheckboxField = styled(CheckboxField)`
  flex: 1 0 25%;
`;

const SiteSpinnerWrapper = styled.div`
  position: relative;
`;

const SiteSpinner = styled.div`
  position: absolute;
  left: 50px;
`;

const DeviationsFilterFormComponent = (props: Props) => {
  const maxItemsToDisplay = 1000;
  const { t } = useTranslation('deviations');
  const [loading, setLoading] = useState<Boolean>(false);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [selectedPracticeGroupsOptions, setSelectedPracticeGroupsOptions] = useState<Array<number>>(
    [],
  );
  const [selectedTreatmentTypeIds, setSelectedTreatmentTypeIds] = useState<Array<number>>([]);
  const [sitesForDropdown, setSitesForDropdown] = useState<Array<DropdownOption<number>>>(
    NameAndIdToDropdown(props.deviationFilterDropdownOptions.sites),
  );
  const [itemsForDropdown, setItemsForDropdown] = useState<Array<DropdownOption<number>>>([]);
  const [selectedItemsOption, setSelectedItemsOption] = useState<Array<number>>(
    props.existingFilters?.itemIds ?? [],
  );
  const [itemQuerySelection, setItemQuerySelection] = useState<string>('');
  const [isRecentlyAdded, setIsRecentlyAdded] = useState(
    props.existingFilters?.isRecentlyAdded ?? false,
  );

  const { makeRequest } = useApiRequest(GetSitesByPracticeGroup);

  /**
   * This will take the current item filter options and append it to the
   * selection of items to be rendered in the Items list
   * @param filtered
   * @returns
   */
  const applySelectedItemsToItemOptions = (filtered: Array<ItemDropdownItem>) => {
    // Retun a ItemDropdownItem array of selected items
    const selectedItems = props.deviationFilterDropdownOptions.items.filter(i =>
      selectedItemsOption.includes(i.id),
    );

    // Combine the two arrays and retun
    const filteredArr = union(filtered, selectedItems);
    return filteredArr;
  };

  /**
   * Jira - pp-996
   * Move the Memo function to a useState so that the downshift callback
   * can be used to populate the items drop down
   *
   * For performance; limit the items displayed to 1000
   */
  useMemo(() => {
    const itemOptionsForSelectedTreatmentTypes =
      selectedTreatmentTypeIds.length === 0
        ? applySelectedItemsToItemOptions(
            props.deviationFilterDropdownOptions.items.slice(0, maxItemsToDisplay),
          )
        : props.deviationFilterDropdownOptions.items.filter(
            item =>
              item.treatmentTypeId != null &&
              selectedTreatmentTypeIds.includes(item.treatmentTypeId),
          );

    setItemsForDropdown(NameAndIdToDropdown(itemOptionsForSelectedTreatmentTypes));
  }, [selectedTreatmentTypeIds, props.deviationFilterDropdownOptions.items]);

  const suppliersForDropdown = NameAndIdToDropdown(props.deviationFilterDropdownOptions.suppliers);
  const practiceGroupsForDropdown = NameAndIdToDropdown(
    props.deviationFilterDropdownOptions.practiceGroups,
  );

  const clearFields = () => {
    props.setValues({
      itemIds: null,
      siteIds: null,
      practiceGroupIds: null,
      isHidden: false,
      isNotHidden: false,
      isBothHiddenAndNotHidden: true,
      overwrite: null,
      notOverwrite: null,
      isBothOverwriteAndNotOverwrite: null,
      category1Ids: null,
      category2Ids: null,
      category3Ids: null,
      clientCategoryIds: null,
      isBelowCentralPrice: false,
      isSameAsCentralPrice: false,
      isAboveCentralPrice: false,
      isBelowCentralDispenseFee: false,
      isSameAsCentralDispenseFee: false,
      isAboveCentralDispenseFee: false,
      supplierProductCodeSearchString: null,
      supplierIds: null,
      priceDevaitionSourceId: null,
      dispenseFeeDevaitionSourceId: null,
      itemName: null,
      itemTypeId: null,
      isRecentlyAdded: null,
    });
    setSelectedTreatmentTypeIds([]);
  };

  const onSelectedTreatmentTypesChanged = (values: Array<number>) => {
    setSelectedTreatmentTypeIds(values);
    props.setFieldValue('itemIds', null);
  };

  const onSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    props.handleSubmit(e);
    if (isEmpty(props.errors)) {
      setShowAdvancedFilters(false);
    }
  };

  /**
   * Get the sites for the filter or show all
   */
  useEffect(() => {
    if (props.existingFilters?.practiceGroupIds) {
      setSelectedPracticeGroupsOptions(props.existingFilters?.practiceGroupIds);
    }
  }, []);

  /**
   * Use Effect to allow debouncing on the change event for the Practice Group DDL,
   * We dont want to hit the server everytime an option is selected as multiple options
   * might be wanted. So debounce for a second to allow the user to apply whatever
   * filter they want - and then query the API. The timeout might need to be adjusted
   * to fit correctly.
   */
  useEffect(() => {
    const fetchSites = async () => {
      makeRequest({
        PracticeGroups: selectedPracticeGroupsOptions,
      }).then(result => {
        if (result) {
          setSitesForDropdown(
            result.sites.map(m => {
              return {
                displayText: m.siteName,
                value: m.siteId,
              };
            }),
          );
        }
      });

      setLoading(false);
    };
    // Debounced API call
    const debouncedFetchSites = debounce(fetchSites, 1000); // Delay of 1 second
    debouncedFetchSites(); // Call API after the delay when options are selected

    // Cleanup function to cancel the debounce on unmount
    return () => debouncedFetchSites.cancel();
  }, [selectedPracticeGroupsOptions]);

  const handlePracticeGroupChange = (event: Array<number>) => {
    setLoading(true);
    setSelectedPracticeGroupsOptions(event);
  };

  /**
   * Jira - pp-996
   * Function to take the item from the props and filter by the value
   * entered by the user
   *
   * Jira pp-996
   * As we type in the item filter and the list updates,
   * ensure the existing selected items are persisted
   */
  const handleOnInputValueChange = (value: any) => {
    // ignore if we have a TX type selected
    if (selectedTreatmentTypeIds.length !== 0) return;

    setItemQuerySelection(value);

    /* 
      Cant find a better way to do this at the moment. When
      we select an item, we receive the complete name not the original
      filter. Wwe dont want to filter by the selected name. So as I cant
      filter out the 'Return or Selection' check to see if the query values
      have changed, if so this is not a keypress but a 'selected' option.
      Value -1 as this is state and we havent gone through the react process 
    */

    if (value.length - 1 != itemQuerySelection.length) return;

    const filteredItems = props.deviationFilterDropdownOptions.items.filter(m =>
      m.name.toLowerCase().includes(value.toLowerCase()),
    );

    // If we have any selected items in the list, ensure they are
    // added to the list after it (if it) regenerates.
    setItemsForDropdown(
      NameAndIdToDropdown(applySelectedItemsToItemOptions(filteredItems)).slice(
        0,
        maxItemsToDisplay,
      ),
    );
  };

  /**
   * Jira pp-996
   * When the item selection changes, persist the collection
   * @param values
   * @returns
   */
  const handleOnChange = (values: any) => {
    // ignore if we have a TX type selected
    if (selectedTreatmentTypeIds.length !== 0) return;
    setSelectedItemsOption(Object.values(values));
  };

  const getItemTypeName = (itemTypeId: DevationItemType | null): string | null => {
    switch (itemTypeId) {
      case DevationItemType.Stock:
        return 'Stock';
      case DevationItemType.Service:
        return 'Service';
      default:
        return null;
    }
  };

  const itemTypeName = getItemTypeName(props.values.itemTypeId);

  return (
    <>
      <FilterAccordionBar
        label={t('deviationsFilter.filter')}
        isOpen={showAdvancedFilters}
        onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
      />
      <FilterForm onSubmit={onSubmit} onReset={props.handleReset}>
        <FlexRowContainer>
          <MultiSelectDropdownField
            name="category1Ids"
            label={t('deviationsFilter.category1')}
            options={filterCategory1OptionsByType(
              props.itemFieldsFromPms.category1Options,
              itemTypeName,
            )}
            onChange={(values, _) => onSelectedTreatmentTypesChanged(values as Array<number>)}
          />
          <InputField name="itemName" label={t('deviationsFilter.itemsSearch')} />
          <MultiSelectDropdownField
            name="itemIds"
            label={t('deviationsFilter.items')}
            options={itemsForDropdown}
            onInputValueChange={handleOnInputValueChange}
            onChange={handleOnChange}
          />
          <MultiSelectDropdownField
            name="practiceGroupIds"
            label={t('deviationsFilter.practiceGroups')}
            options={practiceGroupsForDropdown}
            onChange={values => handlePracticeGroupChange(values as Array<number>)}
          />
          <SiteSpinnerWrapper>
            <SiteSpinner>{loading && <NavIcon icon={faSpinner} className="fa-spin" />}</SiteSpinner>
            <MultiSelectDropdownField
              name="siteIds"
              label={t('deviationsFilter.sites')}
              options={sitesForDropdown}
            />
          </SiteSpinnerWrapper>
          <CheckboxField
            name="isRecentlyAdded"
            label={t('deviationsFilter.isRecentlyAdded')}
            onChange={e => {
              setIsRecentlyAdded(e);
            }}
          />
        </FlexRowContainer>
        <Expandable isExpanded={showAdvancedFilters}>
          <FlexRowContainer>
            <SingleSelectDropdownField
              label={t('deviationsFilter.itemType')}
              name="itemTypeId"
              options={[
                {
                  displayText: t('deviationsFilter.Stock'),
                  value: DevationItemType.Stock,
                },
                {
                  displayText: t('deviationsFilter.Service'),
                  value: DevationItemType.Service,
                },
              ]}
            />
            <MultiSelectDropdownField
              name="category2Ids"
              label={t('deviationsFilter.category2')}
              options={itemLibraryDropdownOptions(props.itemFieldsFromPms.category2Options)}
            />
            <MultiSelectDropdownField
              name="category3Ids"
              label={t('deviationsFilter.category3')}
              options={itemLibraryDropdownOptions(props.itemFieldsFromPms.category3Options)}
            />
            <MultiSelectDropdownField
              name="clientCategoryIds"
              label={t('deviationsFilter.clientCategory')}
              options={itemLibraryDropdownOptions(props.itemFieldsFromPms.clientCategoryOptions)}
            />
            <MultiSelectDropdownField
              name="supplierIds"
              label={t('deviationsFilter.suppliers')}
              options={suppliersForDropdown}
            />
            <InputField
              name="supplierProductCodeSearchString"
              label={t('deviationsFilter.supplierProductCode')}
            />
          </FlexRowContainer>

          <FlexRowContainer>
            <div>
              <h4>{t('deviationsFilter.priceDevaitionSource')}</h4>
              <SingleSelectDropdownField
                name="priceDevaitionSourceId"
                options={[
                  {
                    displayText: t('deviationsFilter.deviationDropDownCodeSpecific'),
                    value: DevationSource.CodeSpecific,
                  },
                  {
                    displayText: t('deviationsFilter.deviationDropDownTreatmentType'),
                    value: DevationSource.TreatmentType,
                  },
                  {
                    displayText: t('deviationsFilter.deviationDropDownAll'),
                    value: DevationSource.All,
                  },
                ]}
              />
            </div>
            <div>
              <h4>{t('deviationsFilter.dispenseFeeDevaitionSource')}</h4>
              <SingleSelectDropdownField
                name="dispenseFeeDevaitionSourceId"
                options={[
                  {
                    displayText: t('deviationsFilter.deviationDropDownCodeSpecific'),
                    value: DevationSource.CodeSpecific,
                  },
                  {
                    displayText: t('deviationsFilter.deviationDropDownTreatmentType'),
                    value: DevationSource.TreatmentType,
                  },
                  {
                    displayText: t('deviationsFilter.deviationDropDownAll'),
                    value: DevationSource.All,
                  },
                ]}
              />
            </div>
          </FlexRowContainer>
          <h4>{t('deviationsFilter.aboveBelowSameAsCentralPriceTitle')}</h4>
          <FlexRowContainer>
            <FlexCheckboxField
              name="isAboveCentralPrice"
              label={t('deviationsFilter.aboveCentralPrice')}
            />
            <FlexCheckboxField
              name="isSameAsCentralPrice"
              label={t('deviationsFilter.sameAsCentralPrice')}
            />
            <FlexCheckboxField
              name="isBelowCentralPrice"
              label={t('deviationsFilter.belowCentralPrice')}
            />
          </FlexRowContainer>
          <h4>{t('deviationsFilter.aboveBelowSameAsCentralDispenseFeeTitle')}</h4>
          <FlexRowContainer>
            <FlexCheckboxField
              name="isAboveCentralDispenseFee"
              label={t('deviationsFilter.aboveCentralDispenseFee')}
            />
            <FlexCheckboxField
              name="isSameAsCentralDispenseFee"
              label={t('deviationsFilter.sameAsCentralDispenseFee')}
            />
            <FlexCheckboxField
              name="isBelowCentralDispenseFee"
              label={t('deviationsFilter.belowCentralDispenseFee')}
            />
          </FlexRowContainer>
          <h4>{t('deviationsFilter.isHiddenTitle')}</h4>
          <FlexRowContainer>
            <FlexCheckboxField
              name="isHidden"
              label={t('deviationsFilter.isHidden')}
              onChange={value => {
                value
                  ? props.setValues({
                      ...props.values,
                      isNotHidden: false,
                      isBothHiddenAndNotHidden: false,
                    })
                  : props.setValues({ ...props.values, isBothHiddenAndNotHidden: true });
              }}
            />
            <FlexCheckboxField
              name="isNotHidden"
              label={t('deviationsFilter.isNotHidden')}
              onChange={value => {
                value
                  ? props.setValues({
                      ...props.values,
                      isHidden: false,
                      isBothHiddenAndNotHidden: false,
                    })
                  : props.setValues({ ...props.values, isBothHiddenAndNotHidden: true });
              }}
            />
            <FlexCheckboxField
              name="isBothHiddenAndNotHidden"
              label={t('deviationsFilter.isBothHiddenAndNotHidden')}
              disabled={props.values.isBothHiddenAndNotHidden ?? false}
              onChange={value => {
                if (value) {
                  props.setValues({ ...props.values, isHidden: false, isNotHidden: false });
                }
              }}
            />
          </FlexRowContainer>
          <h4>{t('deviationsFilter.overwriteTitle')}</h4>
          <FlexRowContainer>
            <FlexCheckboxField
              name="overwrite"
              label={t('deviationsFilter.overwrite')}
              onChange={value => {
                value
                  ? props.setValues({
                      ...props.values,
                      notOverwrite: false,
                      isBothOverwriteAndNotOverwrite: false,
                    })
                  : props.setValues({ ...props.values, isBothOverwriteAndNotOverwrite: true });
              }}
            />
            <FlexCheckboxField
              name="notOverwrite"
              label={t('deviationsFilter.notOverwrite')}
              onChange={value => {
                value
                  ? props.setValues({
                      ...props.values,
                      overwrite: false,
                      isBothOverwriteAndNotOverwrite: false,
                    })
                  : props.setValues({ ...props.values, isBothOverwriteAndNotOverwrite: true });
              }}
            />
            <FlexCheckboxField
              name="isBothOverwriteAndNotOverwrite"
              label={t('deviationsFilter.isBothOverwriteAndNotOverwrite')}
              disabled={props.values.isBothOverwriteAndNotOverwrite ?? false}
              onChange={value => {
                if (value) {
                  props.setValues({ ...props.values, overwrite: false, notOverwrite: false });
                }
              }}
            />
          </FlexRowContainer>
        </Expandable>
        <FlexButtonGroup>
          <HollowButton onClick={clearFields} type="button" disabled={props.isSubmitting}>
            {t('deviationsFilter.clearFieldsButton')}
          </HollowButton>
          <PrimaryButton type="submit" loading={props.isSubmitting}>
            {t('deviationsFilter.applyButton')}
          </PrimaryButton>
          <AdvancedFiltersButton
            onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
            type="button"
            disabled={props.isSubmitting}
          >
            {showAdvancedFilters
              ? t('deviationsFilter.hideAdvancedFilters')
              : t('deviationsFilter.showAdvancedFilters')}
          </AdvancedFiltersButton>
        </FlexButtonGroup>
      </FilterForm>
    </>
  );
};

const withUserContextEnhancer = withUserContext();

const withTranslationsEnhancer = withTranslation('deviations');

const withFormikEnhancer = withFormik<
  OwnProps & WithUserContextProps & WithTranslation,
  DeviationFilterFormModel
>({
  enableReinitialize: true,
  handleSubmit: (values, { props, setSubmitting }) => {
    props.onApply({
      itemIds: values.itemIds ?? null,
      siteIds: values.siteIds ?? null,
      practiceGroupIds: values.practiceGroupIds ?? null,
      isHidden: values.isBothHiddenAndNotHidden ? null : values.isHidden,
      overwrite: values.isBothOverwriteAndNotOverwrite ? null : values.overwrite,
      category1Ids: values.category1Ids,
      category2Ids: values.category2Ids,
      category3Ids: values.category3Ids,
      centralPrice: checkboxesToComparisonType(
        values.isAboveCentralPrice,
        values.isSameAsCentralPrice,
        values.isBelowCentralPrice,
      ),
      dispenseFee: checkboxesToComparisonType(
        values.isAboveCentralDispenseFee,
        values.isSameAsCentralDispenseFee,
        values.isBelowCentralDispenseFee,
      ),
      supplierProductCodeSearchString: values.supplierProductCodeSearchString,
      supplierIds: values.supplierIds ?? null,
      priceDevaitionSourceId: values.priceDevaitionSourceId ?? null,
      dispenseFeeDevaitionSourceId: values.dispenseFeeDevaitionSourceId ?? null,
      itemName: values.itemName ?? null,
      itemTypeId: values.itemTypeId ?? null,
      clientCategoryIds: values.clientCategoryIds ?? null,
      isRecentlyAdded: values.isRecentlyAdded || null,
    });
    setSubmitting(false);
  },
  mapPropsToValues: props => {
    return {
      itemIds:
        props.existingFilters && props.existingFilters.itemIds
          ? props.existingFilters.itemIds
          : null,
      siteIds:
        props.existingFilters && props.existingFilters.siteIds
          ? props.existingFilters.siteIds
          : null,
      practiceGroupIds:
        props.existingFilters && props.existingFilters.practiceGroupIds
          ? props.existingFilters.practiceGroupIds
          : null,
      isHidden: props.existingFilters?.isHidden ?? false,
      isNotHidden:
        props.existingFilters == null
          ? false
          : props.existingFilters.isHidden == null
          ? false
          : !props.existingFilters.isHidden,
      isBothHiddenAndNotHidden: props.existingFilters
        ? props.existingFilters.isHidden == null
        : true,
      overwrite: props.existingFilters?.overwrite ?? false,
      notOverwrite:
        props.existingFilters == null
          ? false
          : props.existingFilters.overwrite == null
          ? false
          : !props.existingFilters.overwrite,
      isBothOverwriteAndNotOverwrite: props.existingFilters
        ? props.existingFilters.overwrite == null
        : true,
      category1Ids: props.existingFilters ? props.existingFilters.category1Ids : null,
      category2Ids: props.existingFilters ? props.existingFilters.category2Ids : null,
      category3Ids: props.existingFilters ? props.existingFilters.category3Ids : null,
      clientCategoryIds: props.existingFilters ? props.existingFilters.clientCategoryIds : null,
      isBelowCentralPrice: props.existingFilters
        ? comparisonIsLessThan(props.existingFilters.centralPrice)
        : null,
      isSameAsCentralPrice: props.existingFilters
        ? comparisonIsEqualTo(props.existingFilters.centralPrice)
        : null,
      isAboveCentralPrice: props.existingFilters
        ? comparisonIsGreaterThan(props.existingFilters.centralPrice)
        : null,
      isBelowCentralDispenseFee: props.existingFilters
        ? comparisonIsLessThan(props.existingFilters.dispenseFee)
        : null,
      isSameAsCentralDispenseFee: props.existingFilters
        ? comparisonIsEqualTo(props.existingFilters.dispenseFee)
        : null,
      isAboveCentralDispenseFee: props.existingFilters
        ? comparisonIsGreaterThan(props.existingFilters.dispenseFee)
        : null,
      supplierProductCodeSearchString: props.existingFilters
        ? props.existingFilters.supplierProductCodeSearchString
        : null,
      supplierIds:
        props.existingFilters && props.existingFilters.supplierIds
          ? props.existingFilters.supplierIds
          : null,
      priceDevaitionSourceId:
        props.existingFilters && props.existingFilters.priceDevaitionSourceId
          ? props.existingFilters.priceDevaitionSourceId
          : null,
      dispenseFeeDevaitionSourceId:
        props.existingFilters && props.existingFilters.dispenseFeeDevaitionSourceId
          ? props.existingFilters.dispenseFeeDevaitionSourceId
          : null,
      itemName:
        props.existingFilters && props.existingFilters.itemName
          ? props.existingFilters.itemName
          : null,
      itemTypeId:
        props.existingFilters && props.existingFilters.itemTypeId
          ? props.existingFilters.itemTypeId
          : null,
      isRecentlyAdded:
        props.existingFilters && props.existingFilters.isRecentlyAdded
          ? props.existingFilters.isRecentlyAdded
          : null,
    };
  },
  validate: (values, props) => {
    const validator = new DeviationsFilterFormValidator(props.t, props.user.locale);
    return validator.validate(values);
  },
});

const enhance = flowRight(withUserContextEnhancer, withTranslationsEnhancer, withFormikEnhancer);

export const DeviationsFilterForm: React.ComponentType<OwnProps> = enhance(
  DeviationsFilterFormComponent,
);

class DeviationsFilterFormValidator extends TranslatableValidator<DeviationFilterFormModel> {
  constructor(t: TFunction, locale: Locale) {
    super(t, locale);
  }
}
