import { RouteComponentProps } from '@reach/router';
import { useContext } from 'react';
import * as React from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../authentication/loginData/userContext';
import { UserForm } from './UserForm';

type Props = RouteComponentProps;

export const CreateUser = (props: Props) => {
  const { t } = useTranslation('user');
  const { user } = useContext(UserContext);

  return (
    <>
      <Title>{t('createForm.title')}</Title>
      <h1>{t('createForm.heading')}</h1>
      <UserForm existingUser={null} currentUser={user} />
    </>
  );
};
