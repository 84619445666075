import { RouteComponentProps, Router } from '@reach/router';
import * as React from 'react';
import { PageNotFound } from '../PageNotFound';
import { ViewPmsRequestError } from './ViewPmsRequestError';
import { ViewPmsRequestErrorsForDeviationBulkEdit } from './ViewPmsRequestErrorsForDeviationBulkEdit';
import { ViewPmsRequestErrorsForItemBulkEdit } from './ViewPmsRequestErrorsForItemBulkEdit';
import { ViewPmsRequestErrorsForTreatmentTypeDeviationBulkEdit } from './ViewPmsRequestErrorsForTreatmentTypeDeviationBulkEdit';

export const PmsRequestErrorsSection = (props: RouteComponentProps) => (
  <Router primary={false}>
    <ViewPmsRequestError path={'/:errorId'} />
    <ViewPmsRequestErrorsForItemBulkEdit path={'/item-bulk-edit/:itemBulkEditId'} />
    <ViewPmsRequestErrorsForDeviationBulkEdit path={'/deviation-bulk-edit/:deviationBulkEditId'} />
    <ViewPmsRequestErrorsForTreatmentTypeDeviationBulkEdit
      path={'/treatment-type-deviation-bulk-edit/:treatmentTypeDeviationBulkEditId'}
    />
    <PageNotFound default={true} />
  </Router>
);
