import { RouteComponentProps } from '@reach/router';
import { flowRight } from 'lodash';
import { useState } from 'react';
import * as React from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { DataLoader } from '../../shared/DataLoader';
import {
  ApiFetchProps,
  fetchFromApiOnLoad,
} from '../../shared/higher-order-components/fetchFromApiOnLoad';
import { Link } from '../../shared/navigation/Link';
import { requiresOneOfPermissions } from '../../shared/Permissions';
import { shouldShowSuccess } from '../../shared/success/SuccessBox';
import { permissions } from '../authentication/permissions';
import { PageHeading } from '../layout/PageHeading';
import { ItemResponse } from './item';
import { ItemForm } from './ItemForm';
import { getItem, getItemFieldsFromPms } from './itemsApi';
import { viewItemUrl } from './itemUrls';
import { ReturnToItemsListLink, ScheduledForUpdateInfoBar } from './ViewItem';

type OwnProps = RouteComponentProps<{ itemId: string }> & {};
type Props = OwnProps & ApiFetchProps<ItemResponse>;

const EditItemComponent = (props: Props) => {
  const { t } = useTranslation('item');
  const [existingItem, setExistingItem] = useState(props.response);

  const onSuccess = (response: ItemResponse) => setExistingItem(response);

  return (
    <>
      <ReturnToItemsListLink text={t('editForm.returnToListButton')} />
      <Title>{t('editForm.title')}</Title>
      <PageHeading>
        <h1>{t('editForm.heading')}</h1>
        <Link to={viewItemUrl(existingItem.itemId)}>{t('editForm.viewButton')}</Link>
      </PageHeading>
      <ScheduledForUpdateInfoBar
        t={t}
        scheduledItemBulkEditId={existingItem.flags.scheduledItemBulkEditId}
        message={t('editForm.scheduledForBulkEdit')}
      />
      <DataLoader apiRequest={getItemFieldsFromPms}>
        {itemFields => (
          <ItemForm
            onSuccess={onSuccess}
            showSuccess={shouldShowSuccess()}
            existingItem={existingItem}
            itemFieldsFromPms={itemFields.response}
          />
        )}
      </DataLoader>
    </>
  );
};

const enhance = flowRight(
  requiresOneOfPermissions(permissions.SuperAdmin, permissions.CentralAdmin),
  fetchFromApiOnLoad<OwnProps, ItemResponse, number>(props => Number(props.itemId), getItem),
);

export const EditItem = enhance(EditItemComponent);
