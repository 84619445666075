import { filter, map } from 'lodash';
import { Id } from '../../../models/id';
import { DropdownOption } from '../../../shared/forms/DropdownField';

export type PmsSupplier = {
  id: Id;
  name: string;
  isDisabled: boolean;
};

export type SuppliersFromPmsResponse = {
  supplierOptions: Array<PmsSupplier>;
};

export const pmsSupplierDropdownOptions = (
  supplierOptions: Array<PmsSupplier>,
): Array<DropdownOption<number>> =>
  map(
    filter(supplierOptions, supplier => !supplier.isDisabled),
    supplier => ({ displayText: supplier.name, value: supplier.id }),
  );
