import { useEffect } from 'react';
import { useContext } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps, Column } from 'react-table';
import { toShortDate } from '../../models/dates/dateTimeStamp';
import { ApiErrorBox } from '../../shared/errors/ApiErrorBox';
import { useApiRequest } from '../../shared/hooks/useApiRequest';
import { Link } from '../../shared/navigation/Link';
import { IfUserHasOneOfPermissions } from '../../shared/Permissions';
import { UserContext } from '../authentication/loginData/userContext';
import { permissions } from '../authentication/permissions';
import { DeviationBulkEditJobDetails } from '../deviations/bulkEdit/deviationBulkEdit';
import { getRecentDeviationBulkEdits } from '../deviations/deviationsApi';
import { viewDeviationBulkEditUrl } from '../deviations/deviationsUrls';
import { viewPmsRequestErrorsForDeviationBulkEditUrl } from '../pmsRequestErrors/pmsRequestErrorUrls';
import { MarginRightFalseIcon, TrueIcon } from '../../shared/StyledIcons';
import { SimpleTable } from '../../shared/tables/SimpleTable';
import { settings } from './Globals';
import { DashboardFilterSelection } from './models/DashboardFilterSelection';

type RecentDeviationBulkEditsDashboardSectionProps = {
  existingFilters?: DashboardFilterSelection;
};

export const RecentDeviationBulkEditsDashboardSection = (
  props: RecentDeviationBulkEditsDashboardSectionProps,
) => {
  const { t } = useTranslation('dashboard');
  const { user } = useContext(UserContext);
  const itmemsToDisplay = settings.recentItemsToDisplay;

  const { response, makeRequest, apiError, inProgress } = useApiRequest(
    getRecentDeviationBulkEdits,
  );

  useEffect(() => {
    makeRequest(props.existingFilters).then();
  }, [props.existingFilters]);

  const columns: Array<Column> = [
    {
      Header: t<string>('deviationBulkEdit.recentSection.table.scheduledDate'),
      accessor: 'scheduledDate',
      id: 'scheduledDate',
      Cell: ({ cell }: CellProps<DeviationBulkEditJobDetails>) => (
        <Link to={viewDeviationBulkEditUrl(cell.row.original.deviationBulkEditId)}>
          {toShortDate(cell.value, user.locale)}
        </Link>
      ),
    },
    {
      Header: t<string>('deviationBulkEdit.recentSection.table.name'),
      accessor: 'name',
      id: 'name',
    },
    {
      Header: t<string>('deviationBulkEdit.upcomingSection.table.creatorsName'),
      accessor: 'creatorsName',
      id: 'creatorsName',
    },
    {
      Header: t<string>('deviationBulkEdit.upcomingSection.table.affectedNames'),
      accessor: 'affectedSiteNames',
      id: 'affectedNames',
      Cell: ({ cell }: CellProps<DeviationBulkEditJobDetails>) => (
        <span>
          {cell.value.length > 1
            ? t('deviationBulkEdit.upcomingSection.table.affectedSites', {
                sitesCount: cell.value.length,
              })
            : t('deviationBulkEdit.upcomingSection.table.affectedSite', {
                site: cell.value[0],
              })}
        </span>
      ),
    },
    {
      Header: t<string>('deviationBulkEdit.recentSection.table.status'),
      accessor: 'hasErrored',
      id: 'hasErrored',
      Cell: ({ cell }: CellProps<DeviationBulkEditJobDetails>) => {
        if (cell.row.original.hasErrored) {
          return (
            <span>
              <MarginRightFalseIcon />
              {
                <IfUserHasOneOfPermissions
                  permissions={[permissions.SuperAdmin, permissions.CentralAdmin]}
                >
                  <Link
                    to={viewPmsRequestErrorsForDeviationBulkEditUrl(
                      cell.row.original.deviationBulkEditId,
                    )}
                  >
                    {t('deviationBulkEdit.recentSection.table.viewError')}
                  </Link>
                </IfUserHasOneOfPermissions>
              }
            </span>
          );
        }
        if (cell.row.original.allComplete) {
          return (
            <span>
              <TrueIcon />
            </span>
          );
        }
        return <span>{t('deviationBulkEdit.recentSection.table.inProgress')}</span>;
      },
    },
  ];

  return (
    /**
     * jira pp-1076 all users can see Deviation Bulk Edits
     */
    <>
      <h4>{t('deviationBulkEdit.recentSection.title')}</h4>
      <SimpleTable
        columns={columns}
        data={response?.deviationBulkEditJobs ?? []}
        emptyTableMessage={t('deviationBulkEdit.recentSection.table.emptyTableMessage')}
        loading={inProgress}
        itemsToDisplay={itmemsToDisplay}
      />
      {apiError && <ApiErrorBox error={apiError} />}
    </>
  );
};
