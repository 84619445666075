import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { DataLoader } from '../../shared/DataLoader';
import { RequiresOneOfPermissions } from '../../shared/Permissions';
import { MetadataContext } from '../authentication/loginData/metadataContext';
import { permissions } from '../authentication/permissions';
import { SiteForm } from './SiteForm';
import { getMerlinGroups, getPmsSites } from '../../api/sitesApi';

type OwnProps = RouteComponentProps & {};
type Props = OwnProps;

export const CreateSite = (props: Props) => {
  const { t } = useTranslation('site');
  return (
    <RequiresOneOfPermissions permissions={[permissions.SuperAdmin, permissions.CentralAdmin]}>
      <Title>{t('createForm.title')}</Title>
      <h1>{t('createForm.heading')}</h1>
      <MetadataContext.Consumer>
        {metadata => (
          <DataLoader apiRequest={getPmsSites}>
            {pmsSites => (
              <DataLoader apiRequest={getMerlinGroups}>
                {merlinGroups => (
                  <SiteForm
                    pmsTypes={metadata.pmsTypes}
                    pmsSites={pmsSites.response}
                    merlinGroups={merlinGroups.response.merlinGroups}
                  />
                )}
              </DataLoader>
            )}
          </DataLoader>
        )}
      </MetadataContext.Consumer>
    </RequiresOneOfPermissions>
  );
};
