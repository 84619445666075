import { RouteComponentProps } from '@reach/router';
import { TFunction } from 'i18next';
import * as React from 'react';
import { useContext } from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { BeTodayOrLater } from '../../models/dates/dateTimeStamp';
import { getLocalNow } from '../../models/dates/now';
import { Locale } from '../../models/locale';
import { FileUploadForm } from '../../shared/files/FileUploadForm';
import { CheckboxField } from '../../shared/forms/CheckboxField';
import { DateField } from '../../shared/forms/DateField';
import { InputField } from '../../shared/forms/InputField';
import { IfUserHasPermission, RequiresOneOfPermissions } from '../../shared/Permissions';
import { assertIsDefined } from '../../utils/assertIsDefined';
import { TranslatableValidator } from '../../utils/validation/TranslatableValidator';
import { InputMaximumLength } from '../../utils/validation/validationConstants';
import { UserContext } from '../authentication/loginData/userContext';
import { permissions } from '../authentication/permissions';
import { UploadItemsResponse } from './item';
import { uploadItemBulkEdit } from './itemsApi';
import { viewItemBulkEditUrl } from './itemUrls';

export type UploadItemBulkEditFormModel = {
  name: string | null;
  scheduledDateTime: string;
  forceUpload: boolean;
};

class Validator extends TranslatableValidator<UploadItemBulkEditFormModel> {
  constructor(t: TFunction, locale: Locale) {
    super(t, locale);
    this.ruleFor('name')
      .notNull()
      .withMessage(t('item:validation.bulkEditName.empty'))
      .notEmpty()
      .withMessage(t('item:validation.bulkEditName.empty'))
      .maxLength(InputMaximumLength)
      .withMessage(t('item:validation.bulkEditName.length'));
    this.ruleFor('scheduledDateTime')
      .must(date => BeTodayOrLater(date, locale))
      .withMessage(t('item:validation.scheduledDate.invalid'));
  }
}

export const UploadItemBulkEdit = (props: RouteComponentProps) => {
  const { t } = useTranslation('item');
  const { user } = useContext(UserContext);

  return (
    <RequiresOneOfPermissions permissions={[permissions.SuperAdmin, permissions.CentralAdmin]}>
      <Title>{t('uploadBulkEdit.title')}</Title>
      <h1>{t('uploadBulkEdit.heading')}</h1>
      <FileUploadForm<UploadItemBulkEditFormModel, UploadItemsResponse>
        initialValues={{
          name: null,
          scheduledDateTime: getLocalNow(user.locale).toISODate(),
          forceUpload: false,
        }}
        validate={values => {
          const validator = new Validator(t, user.locale);
          return validator.validate(values);
        }}
        filePropertyName="file"
        fileFieldLabel={t('uploadBulkEdit.labels.file')}
        request={uploadItemBulkEdit}
        onSuccess={response => {
          assertIsDefined(props.navigate)(
            viewItemBulkEditUrl(assertIsDefined(response.itemBulkEditId)),
          );
        }}
        additionalWarningText={t('uploadBulkEdit.processingWarning')}
        renderAdditionalFormFields={() => (
          <>
            <InputField name="name" label={t('uploadBulkEdit.bulkEditName')} />
            <DateField
              name="scheduledDateTime"
              label={t('uploadBulkEdit.scheduledDate.label')}
              infoText={t('uploadBulkEdit.scheduledDate.infoText')}
            />
            <IfUserHasPermission permission={permissions.SuperAdmin}>
              <CheckboxField
                name="forceUpload"
                label={t('uploadBulkEdit.forceUpload')}
                infoText={t('uploadBulkEdit.forceUploadMessage')}
              />
            </IfUserHasPermission>
          </>
        )}
      />
    </RequiresOneOfPermissions>
  );
};
