import { TFunction } from 'i18next';
import { Id } from '../../models/id';
import { Locale } from '../../models/locale';
import { PagedResponse } from '../../shared/tables/pagination/pagination';
import { TranslatableValidator } from '../../utils/validation/TranslatableValidator';
import { InputMaximumLength } from '../../utils/validation/validationConstants';

export type UserResponse = {
  id: Id;
  name: string;
  email: string;
  useGrossPrices: boolean;
  userPermissionCodes: Array<string>;
  isDisabled: boolean;
  userOrganisationGroupIds: Array<number>;
  currentOrganisationGroupId : number;
  userSiteIds: Array<number>;
  userPracticeGroupIds: Array<number>;
};

export type UsersResponse = {
  users: Array<UserResponse>;
  filterOptions: UserFilterOptions;
} & PagedResponse;

export type UserFilterOptions = {
  searchText: string | null;
  siteIds: Array<Id> | null;
  practiceGroupsIds: Array<Id> | null;
  //organisationGroupsIds: number | null;  
  archived: boolean | null;
};

export type UserFilterFormModel = {
  searchText: string | null;
  siteIds: Array<Id> | null;
  practiceGroupsIds: Array<Id> | null;
  archived: boolean | null;
};

export type CreateEditUserFormModel = {
  email: string;
  useGrossPrices: boolean;
  userIsCentralUserAdmin: boolean;
  userIsCentralAdmin: boolean;
  userIsCentralView: boolean;
  userIsSiteUserAdmin: boolean;
  userIsSitePriceAdmin: boolean;
  userIsSiteVisibilityAdmin: boolean;
  userIsDeviationBulkUploadAdmin: boolean;
  userIsSuperAdmin: boolean;
  userOrganisationGroupIds: Array<number>;
  userSiteIds: Array<number>;
  userPracticeGroupIds: Array<number>;
};

export type CreateUserCommand = {
  email: string;
  useGrossPrices: boolean;
  userPermissionCodes: Array<string>;
  userOrganisationGroupIds: Array<number>;
  currentOrganisationGroupId : number;
  userSiteIds: Array<number>;
  userPracticeGroupIds: Array<number>;
};

export type EditUserCommand = {
  userId: Id;
  email: string;
  useGrossPrices: boolean;
  userPermissionCodes: Array<string>;
  userOrganisationGroupIds: Array<number>;
  currentOrganisationGroupId : number;
  userSiteIds: Array<number>;
  userPracticeGroupIds: Array<number>;
};

export type CreateOrEditUserCommandWrapper = {
  command: CreateUserCommand | EditUserCommand;
  createOrEditTag: 'create' | 'edit';
};

export const isEditCommand = (
  command: CreateUserCommand | EditUserCommand,
  tag: 'create' | 'edit',
): command is EditUserCommand => tag === 'edit';

export type SetUserPriceModeCommand = {
  useGrossPrices: boolean;
};

export type DisableUserCommand = {
  userId: Id;
  userIsDisabled: boolean;
};

export type UpdateOrganisationIdCommand = {
  organisationId: number;
};

export class CreateEditUserFormModelValidator extends TranslatableValidator<
  CreateEditUserFormModel
> {
  constructor(t: TFunction, locale: Locale) {
    super(t, locale);
    this.ruleFor('email')
      .notEmpty()
      .withMessage(t('user:validation.email.empty'));
    this.ruleFor('email')
      .maxLength(InputMaximumLength)
      .withMessage(t('user:validation.email.length'));
  }
}
