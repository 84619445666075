import { Validator } from 'fluentvalidation-ts/dist';
import { TFunction } from 'i18next';
import { Locale } from '../../models/locale';

export class TranslatableValidator<TModel> extends Validator<TModel> {
  private t: TFunction;
  private locale: Locale | null;
  constructor(t: TFunction, locale: Locale | null = null) {
    super();
    this.t = t;
    this.locale = locale;
  }
}
