import { RouteComponentProps } from '@reach/router';
import { flowRight, noop } from 'lodash';
import * as React from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { HollowLinkButton } from '../../shared/buttons/LinkButton';
import {
  ApiFetchProps,
  fetchFromApiOnLoad,
} from '../../shared/higher-order-components/fetchFromApiOnLoad';
import { Link } from '../../shared/navigation/Link';
import { requiresPermission } from '../../shared/Permissions';
import { CustomColumn, Table } from '../../shared/tables/Table';
import { styled } from '../../styling/theme';
import { permissions } from '../authentication/permissions';
import { PageHeading } from '../layout/PageHeading';
import { OrganisationGroupResponse, OrganisationGroupsResponse } from './organisationGroup';
import { getOrganisationGroups } from './organisationGroupsApi';
import { createOrganisationUrl, editOrganisationUrl } from './organisationGroupUrls';

type OwnProps = RouteComponentProps & {};
type Props = OwnProps & ApiFetchProps<OrganisationGroupsResponse>;

const NameCellContainer = styled.div`
  min-width: ${props => props.theme.spacing.extraLarge}px;
`;

const TableContainer = styled.div`
  width: 400px;
  min-width: auto;
`;

const OrganisationGroupsComponent = (props: Props) => {
  const { t } = useTranslation('organisation');

  const columns: Array<CustomColumn<OrganisationGroupResponse>> = [
    {
      Header: t<string>('organisationList.nameColumnHeader'),
      accessor: (row: OrganisationGroupResponse) => row.organisationName,
      id: 'organisationName',
      Cell: ({ cell }: CellProps<OrganisationGroupResponse>) => (
        <NameCellContainer>
          <Link to={editOrganisationUrl(cell.row.original.organisationId)}>{cell.value}</Link>
        </NameCellContainer>
      ),
    },
  ];

  return (
    <>
      <Title>{t('organisationList.title')}</Title>
      <PageHeading>
        <h1>{t('organisationList.heading')}</h1>
        <HollowLinkButton to={createOrganisationUrl()}>
          {t('organisationList.createButton')}
        </HollowLinkButton>
      </PageHeading>
      <TableContainer>
        <Table
          columns={columns}
          data={props.response.organisationGroups}
          emptyTableMessage={t('organisationList.emptyTableMessage')}
        />
      </TableContainer>
    </>
  );
};

const fetchFromApiOnLoadEnhancer = fetchFromApiOnLoad<OwnProps, OrganisationGroupsResponse, void>(
  noop,
  getOrganisationGroups,
);

const enhance = flowRight(requiresPermission(permissions.SuperAdmin), fetchFromApiOnLoadEnhancer);

export const OrganisationGroups = enhance(OrganisationGroupsComponent);
