/**
 * These util functions are a refactor of the original Ghyston code, with the intention to improve readability, maintainability and add unit-tests.
 * While the code may have been refactored and tests added, the business-logic should remain the same.
 */
import { findLastIndex } from 'lodash';
import { GroupedDeviationResponseRow } from './bulkEdit/SharedDeviationBulkEditColumns';
import { DeviationResponse, DeviationsResponse } from './deviation';

/**
 * Group deviations by practiceGroupId and (or) itemId.
 *
 * - For deviations with a practiceGroupId, it groups by both practiceGroupId **and** itemId.
 * - For deviations without a practiceGroupId, it groups by itemId **only**.
 * - Groups with the same practiceGroupId are placed together in the resulting array.
 *
 * @param deviations These **MUST** be filtered deviations: either, all with a practiceGroupId, or all without a practiceGroupId
 */
export const getTableRowGroupsForDeviations = (
  deviations: Array<DeviationResponse>,
): Array<GroupedDeviationResponseRow> => {
  const rowGroups = deviations.reduce(
    (acc, currDeviation): any => {
      const { practiceGroupIds, itemId } = currDeviation;

      // practiceGroupIds will either be an array with a single element or an empty.
      // (At the DB level, in the SitePracticeGroups table, SiteId and PracticeGroupId are a composite primary-key, but, for some reason, DeviationResponse.practiceGroupIds is typed as an IEnumerable,
      // which maps to a JSON array.)
      const practiceGroupId = practiceGroupIds[0];

      const existingGroup = acc.find(e => {
        return e.practiceGroupId === practiceGroupId && e.itemId === itemId; // practiceGroupId may be undefined, which is fine, as it will compare undefined to undefined
      });

      // Add the current deviation to the existing group
      if (existingGroup) {
        existingGroup.deviations?.push(currDeviation);
        return acc;
      }

      // If the deviation contains a practiceGroupId and there is already a group with the same practiceGroupId, add this group after the existing group.
      if (practiceGroupId) {
        // (Once Node is upgraded to version >= 18, this Lodash function can be replaced with a vanilla JS, Array.prototype.findLastIndex().)
        const existingGroupWithSamePracticeGroupIdIndex = findLastIndex(
          acc,
          e => e.practiceGroupId === practiceGroupId,
        );

        if (existingGroupWithSamePracticeGroupIdIndex !== -1) {
          acc.splice(existingGroupWithSamePracticeGroupIdIndex + 1, 0, {
            practiceGroupId,
            itemId,
            deviations: [currDeviation],
          } as GroupedDeviationResponseRow);

          return acc;
        }
      }

      // Create a new group after any existing groups
      return [
        ...acc,
        { ...(practiceGroupId ? { practiceGroupId } : {}), itemId, deviations: [currDeviation] },
      ];
    },
    [] as Array<GroupedDeviationResponseRow>, // cast required with reduce, as TS doesn't currently support inference here
  );

  return rowGroups;
};

/**
 * Map the response from POST *api/deviations* to grouped deviations by practice-group and item.
 * The result can then be used by the table component, to display the grouped deviations as a single row,
 * with a dropdown to show the individual deviations for each site from the practice-group for the given item.
 *
 * - Deviations with a practiceGroupId are grouped by both the practiceGroupId and itemId
 * - Deviations without a practiceGroupId are grouped by the itemId only
 * - Groups with the same practiceGroupId are placed next to each other in the resulting array
 * - Groups without a practiceGroupId are placed after groups with a practiceGroupId
 *
 * Deviations are grouped in the order they are received. (Sorting and filtering is done via the API call on the backend.)
 *
 * @example
 * mapDeviationsResponseToTableRowGroups(deviationsResponse);
 *
 * // returns
 *   [
 *    { practiceGroupId: 1, itemId: 1, deviations:
 *        [
 *          {deviationId: 1, practiceGroupIds: [1], itemId: 1},
 *          {deviationId: 3, practiceGroupIds: [1], itemId: 1}
 *        ]
 *    },
 *     { practiceGroupId: 2, itemId: 1, deviations:
 *        [
 *          {deviationId: 2, practiceGroupIds: [2], itemId: 2}
 *        ]
 *      }
 *   ]
 */
export const mapDeviationsResponseToTableRowGroups = (data: DeviationsResponse) => {
  const { deviationResponses: deviations } = data.deviationResponsesAndScheduledBulkEditId;

  const deviationsWithPracticeGroupId = deviations.filter(
    ({ practiceGroupIds }) => practiceGroupIds.length > 0,
  );

  const deviationsWithoutPracticeGroup = deviations.filter(
    ({ practiceGroupIds }) => practiceGroupIds.length === 0,
  );

  // Row groupings by practiceGroupId AND itemId, for deviations WITH a practice-group id
  const rowGroupsForDeviationsWithPracticeGroupId = getTableRowGroupsForDeviations(
    deviationsWithPracticeGroupId,
  );

  // Row-groupings by itemId ONLY, for deviations WITHOUT a practice-group id
  const rowGroupsForDeviationsWithoutPracticeGroupId = getTableRowGroupsForDeviations(
    deviationsWithoutPracticeGroup,
  );

  return [
    ...rowGroupsForDeviationsWithPracticeGroupId,
    ...rowGroupsForDeviationsWithoutPracticeGroupId,
  ];
};
