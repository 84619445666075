import { DateTime } from 'luxon';
import { Locale } from '../locale';
import { getLocalNow } from './now';

export type DateTimeStamp = string;

export const toDateTime = (dateTimeStamp: DateTimeStamp, locale: Locale): DateTime =>
  DateTime.fromISO(dateTimeStamp, { locale });

export const toShortDate = (dateTimeStamp: DateTimeStamp, locale: Locale): string =>
  toDateTime(dateTimeStamp, locale).toLocaleString(DateTime.DATE_SHORT);

export const toShortDateTimeWithSeconds = (dateTimeStamp: DateTimeStamp, locale: Locale): string =>
  toDateTime(dateTimeStamp, locale).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);

export const BeTodayOrLater = (date: string, locale: Locale) => {
  const now = getLocalNow(locale);
  const dateToCheck = toDateTime(date, locale);

  return dateToCheck.startOf('day') >= now.startOf('day');
};
