import { Field, FieldProps } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryLinkStyledButton } from '../../../../shared/buttons/Button';
import { InputFieldProps } from '../../../../shared/forms/InputField';
import { useDebouncedFormikField } from '../../../../shared/hooks/useDebouncedFormikField';
import { styled } from '../../../../styling/theme';
import {
  areAllSelected,
  areAllUnselected,
  SelectedCodes,
  withAllCodesSelected,
  withAllCodesUnselected,
} from './selectedCodes';

export const selectAllButtonTestId = (fieldName: string) => `selectAll:${fieldName}`;
export const clearAllButtonTestId = (fieldName: string) => `clearAll:${fieldName}`;

type OwnProps = {
  codes?: Array<string>;
};

const LinkGroup = styled.div`
  > * {
    margin-right: ${props => props.theme.spacing.small}px;

    :last-child {
      margin-right: 0;
    }
  }

  display: flex;
  flex-flow: row nowrap;
  margin-bottom: ${props => props.theme.spacing.tiny}px;
`;

const InnerSelectAllClearAll = (props: OwnProps & InputFieldProps & FieldProps) => {
  const { t } = useTranslation('supplierList');

  const { value, setValue } = useDebouncedFormikField<SelectedCodes>({
    field: props.field,
    form: props.form,
    useDebouncing: false,
  });

  const allSelected = areAllSelected(value, props.codes);
  const allUnselected = areAllUnselected(value, props.codes);

  const selectAll = () => setValue(withAllCodesSelected(value, props.codes));
  const clearAll = () => setValue(withAllCodesUnselected(value, props.codes));

  return (
    <LinkGroup>
      <PrimaryLinkStyledButton
        type="button"
        disabled={allSelected}
        onClick={selectAll}
        data-testid={selectAllButtonTestId(props.name)}
      >
        {t('review.products.selectAll')}
      </PrimaryLinkStyledButton>
      <PrimaryLinkStyledButton
        type="button"
        disabled={allUnselected}
        onClick={clearAll}
        data-testid={clearAllButtonTestId(props.name)}
      >
        {t('review.products.clearAll')}
      </PrimaryLinkStyledButton>
    </LinkGroup>
  );
};

export const SelectAllClearAll = (props: OwnProps & InputFieldProps) => {
  return (
    <Field
      name={props.name}
      component={({ form, field }: FieldProps) => (
        <InnerSelectAllClearAll form={form} field={field} {...props} />
      )}
    />
  );
};
