import { treatmentTypeDeviationsUrl } from '../../../urls';
import { Id } from '../../../models/id';

export const createTreatmentTypeDeviationBulkEditUrl = (queryParams: string) =>
  `${treatmentTypeDeviationsUrl()}/bulk-edit${queryParams}`;

export const viewTreatmentTypeDeviationBulkEditUrl = (treatmentTypeDeviationBulkEditId: Id) =>
  `${treatmentTypeDeviationsUrl()}/bulk-edit/view/${treatmentTypeDeviationBulkEditId}`;

export const editTreatmentTypeDeviationBulkEditUrl = (treatmentTypeDeviationBulkEditId: Id) =>
  `${treatmentTypeDeviationsUrl()}/bulk-edit/edit/${treatmentTypeDeviationBulkEditId}`;

export const uploadTreatmentTypeDeviationBulkEditUrl = () =>
  `${treatmentTypeDeviationsUrl()}/bulk-edit/upload`;
