import { secureGet, securePost, securePostFile } from '../../../api/apiHttpClient';
import { AuthenticatedApiRequest } from '../../../models/apiRequest';
import { FileResponse } from '../../../models/fileResponse';
import { Id } from '../../../models/id';
import {
  ReviewSupplierListResponse,
  SupplierListApproval,
  SuppliersForSupplierList,
  SuppliersListItems,
  UploadSupplierListResponse,
} from './supplierList';

export const getSuppliersForSupplierList: AuthenticatedApiRequest<
  SuppliersForSupplierList,
  void
> = () => secureGet(`suppliers/supplier-list/suppliers`);

export const uploadSupplierList: AuthenticatedApiRequest<UploadSupplierListResponse, FormData> = (
  data: FormData,
) => securePostFile(`suppliers/supplier-list/upload`, data);

export const getSupplierListReview: AuthenticatedApiRequest<ReviewSupplierListResponse, Id> = (
  supplierListId: Id,
) => secureGet(`suppliers/supplier-list/${supplierListId}/review`);

export const getSupplierListReviewDownload: AuthenticatedApiRequest<FileResponse, Id> = (
  supplierListId: Id,
) => secureGet(`suppliers/supplier-list/${supplierListId}/downloadReview`);

export const approveSupplierList: AuthenticatedApiRequest<void, SupplierListApproval> = (
  approval: SupplierListApproval,
) => securePost(`suppliers/supplier-list/${approval.supplierListId}/approve`, approval);

export const getSupplierListReviewAuthenticatedApiRequest: AuthenticatedApiRequest<
  SuppliersListItems,
  Id
> = (supplierId: Id) =>
  secureGet(`suppliers/supplier-list/${supplierId}/getSupplierListItemsDownload`);
