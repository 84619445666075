export type CancellablePromise<TResponse> = Promise<TResponse> & {
  cancel: () => void;
};

export const makeCancellablePromise = <T>(
  promise: Promise<T>,
  cancel: () => void,
): CancellablePromise<T> => {
  const cancellablePromise = promise as CancellablePromise<T>;
  cancellablePromise.cancel = cancel;
  return cancellablePromise;
};
