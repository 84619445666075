import { TFunction } from 'i18next';
import * as React from 'react';
import { CustomColumn, Table } from '../../../shared/tables/Table';
import { styled } from '../../../styling/theme';
import { Notes } from '../item';

const Container = styled.div`
  margin-bottom: ${styleProps => styleProps.theme.spacing.small}px;
`;

type ItemNotesProps = {
  notes: Array<Notes> | null | undefined;
  t: TFunction;
  locale: string;
};

export const ItemNotes = (props: ItemNotesProps) => {
  const columns: Array<CustomColumn<Notes>> = [
    {
      Header: props.t<string>('itemList.tableHeadings.date'),
      accessor: (row: Notes) =>
        new Date(row.dateOfNote)
          ? new Date(row.dateOfNote).toLocaleDateString(props.locale)
          : row.dateOfNote,
      id: 'dateOfNote',
    },
    {
      Header: props.t<string>('itemList.tableHeadings.note'),
      accessor: (row: Notes) => row.note,
      id: 'note',
    },
    {
      Header: props.t<string>('itemList.tableHeadings.initials'),
      accessor: (row: Notes) => row.initials,
      id: 'initials',
    },
  ];
  return (
    <Container>
      <h4>{props.t('view.sectionTitle.noteDetails')}</h4>
      {props.notes && (
        <Table
          columns={columns}
          data={props.notes}
          emptyTableMessage={props.t('itemList.emptyNotesTableMessage')}
        />
      )}
    </Container>
  );
};
