import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';
import * as React from 'react';
import { ThemeContext } from 'styled-components';
import { styled, Theme } from '../styling/theme';

export const MarginLeftIcon = styled(FontAwesomeIcon)`
  margin-left: ${props => props.theme.spacing.tiny}px;
`;

export const MarginRightIcon = styled(FontAwesomeIcon)`
  margin-right: ${props => props.theme.spacing.tiny}px;
`;

export const getTrueOrFalseIcon = (value: boolean) => (value ? <TrueIcon /> : <FalseIcon />);

export const trueIconTestId = 'true-icon';
export const TrueIcon = ({ className }: { className?: string }) => {
  const themeContext: Theme = useContext(ThemeContext);
  return (
    <FontAwesomeIcon
      className={className}
      data-testid={trueIconTestId}
      color={themeContext.colours.successText}
      icon={faCheck}
    />
  );
};

export const falseIconTestId = 'false-icon';
export const FalseIcon = ({ className }: { className?: string }) => {
  const themeContext: Theme = useContext(ThemeContext);
  return (
    <FontAwesomeIcon
      className={className}
      data-testid={falseIconTestId}
      color={themeContext.colours.alertText}
      icon={faTimes}
    />
  );
};

export const MarginRightFalseIcon = styled(FalseIcon)`
  margin-right: ${props => props.theme.spacing.tiny}px;
`;
