import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MailTo } from '../../shared/MailTo/MailTo';
import { Panel } from '../../shared/panel/Panel';
import { styled } from '../../styling/theme';
import { UserContext } from '../authentication/loginData/userContext';
import DashboardSvg from './dashboard-illustration.svg';

export const WelcomeSection = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation('dashboard');
  const organisationSpecificLookUp = `dashboard.${user.organisationGroupId}`;
  return (
    <Container>
      <WelcomePanel>
        <CurrentOrg>{user.organisationGroupName}</CurrentOrg>
        <ContentContainer>
          <WelcomeTextContainer>
            <WelcomeTitle>
              <div
                dangerouslySetInnerHTML={{
                  __html: t(organisationSpecificLookUp + '.welcome.title', { username: user.name }),
                }}
              />
            </WelcomeTitle>
            <WelcomeMessage>
              <WelcomeMessageLine>
                {t(organisationSpecificLookUp + '.welcome.message.lineOne')}
              </WelcomeMessageLine>
              <WelcomeMessageLine>
                {t(organisationSpecificLookUp + '.welcome.message.lineTwo')}{' '}
                {MailTo(t(organisationSpecificLookUp + '.welcome.supportEmail'))}
              </WelcomeMessageLine>
              <WelcomeMessageLine>
                {t(organisationSpecificLookUp + '.welcome.message.lineThree')}
              </WelcomeMessageLine>
            </WelcomeMessage>
          </WelcomeTextContainer>
          <DashboardIllustration src={DashboardSvg} alt="Dashboard Illustration" />
        </ContentContainer>
      </WelcomePanel>

      {/*
        Replace with Logo image when we have one
       <TrainingVideoPlaceholder>Training Video Placeholder</TrainingVideoPlaceholder> 
       */}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: ${props => props.theme.spacing.small}px;
`;

const WelcomePanel = styled(Panel)`
  min-width: 700px;
  flex-grow: 1;
`;

const CurrentOrg = styled.span`
  flex-grow: 1;
  margin-right: ${props => props.theme.spacing.small}px;
  color: ${props => props.theme.colours.primary};
  font-weight: bold;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const WelcomeTextContainer = styled.div`
  flex-grow: 1;
  margin-right: ${props => props.theme.spacing.small}px;
`;

const WelcomeTitle = styled.h1`
  margin-bottom: ${props => props.theme.spacing.extraSmall}px;
`;

const WelcomeMessage = styled.div`
  display: flex;
  flex-direction: column;
`;

const WelcomeMessageLine = styled.div`
  margin-bottom: ${props => props.theme.spacing.extraSmall}px;
`;

const DashboardIllustration = styled.img<React.ImgHTMLAttributes<HTMLImageElement>>`
  height: 250px;
`;

const TrainingVideoPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  min-height: 250px;
  border: 1px solid ${props => props.theme.colours.border};
  border-radius: ${props => props.theme.spacing.extraTiny}px;
  background-color: ${props => props.theme.colours.componentBackground};
`;
