import { secureGet, securePost } from '../../../api/apiHttpClient';
import { AuthenticatedApiRequest } from '../../../models/apiRequest';
import { GetAccessiblePracticeGroupCollectionQuery } from './GetAccessiblePracticeGroupCollectionQuery';
import { PracticeGroupsResponse } from './practiceGroup';

export const getAllPracticeGroups: AuthenticatedApiRequest<
  PracticeGroupsResponse,
  number
> = organisationGroupId => secureGet(`practice-groups/for-organisation/${organisationGroupId}`);

export const getAccessiblePracticeGroups: AuthenticatedApiRequest<
  PracticeGroupsResponse,
  GetAccessiblePracticeGroupCollectionQuery
> = OrgIds => securePost(`practice-groups/accessible`, OrgIds);
