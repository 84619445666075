import { css, styled } from '../../styling/theme';
import { FormFieldContainer } from './FormField';
import { Input } from './InputField';
import { StyledInputContainer, StyledMultiSelectDropdown } from './MultiSelectDropdownField';
import { StyledInput, StyledSingleSelectDropdown } from './SingleSelectDropdownField';
import { LeftSymbolInput, RightSymbolInput, SymbolInputContainer } from './SymbolInput';
import { TextArea } from './TextAreaField';
import { ReadOnlyValue } from './ReadOnlyField';

export const FormFieldGroupContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const FormFieldGroupsWrapper = styled.div<
  React.HTMLAttributes<HTMLDivElement> & { minWidthInPx?: number }
>`
  display: flex;
  flex-flow: row wrap;
  gap: ${props => props.theme.spacing.small}px;
  margin: ${props => props.theme.spacing.small}px 0;
  ${props => {
    if (!!props.minWidthInPx) {
      return css`
        ${FormFieldGroupContainer} {
          min-width: ${props.minWidthInPx - 2 * props.theme.spacing.small}px;
          flex: 1 1 ${props.minWidthInPx - 2 * props.theme.spacing.small}px;
        }
      `;
    }
  }};
`;

export const FormGroupTitle = styled.h4`
  height: ${props =>
    props.theme.typography.headings.lineHeight * props.theme.typography.headings.h4fontSize}px;
`;

export const FormFieldGroup = styled.div`
  display: flex;
  flex-flow: column nowrap;
  border: 1px solid ${props => props.theme.colours.border};
  border-radius: ${props => props.theme.spacing.extraTiny}px;
  background-color: ${props => props.theme.colours.componentBackground};
  padding: ${props => props.theme.spacing.small}px;
  height: 100%;
  ${LeftSymbolInput} {
    width: 100%;
  }

  ${RightSymbolInput} {
    width: 100%;
  }

  ${Input} {
    width: 100%;
  }

  ${ReadOnlyValue} {
    width: 100%;
  }

  ${FormFieldContainer} {
    max-width: 100%;
    width: 100%;
  }

  ${SymbolInputContainer} {
    width: 100%;
  }

  ${StyledInput} {
    width: 100%;
  }

  ${StyledSingleSelectDropdown} {
    width: 100%;
  }

  ${TextArea} {
    width: 100%;
  }

  ${StyledInputContainer}, ${StyledMultiSelectDropdown} {
    width: 100%;
  }
`;

export const FormFieldSubSection = styled.div`
  margin-left: ${props => props.theme.spacing.medium}px;
`;
