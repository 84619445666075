import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Location } from '@reach/router';
import Downshift, { ControllerStateAndHelpers } from 'downshift';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonLabel } from '../../../shared/buttons/Button';
import {
  DownshiftChildrenContainer,
  DropdownItem,
  DropdownMenu,
  dropdownMenuBorderWidthPixels,
  DropdownMenuContainer,
} from '../../../shared/dropdowns/DropdownComponents';
import { styled } from '../../../styling/theme';
import { navigate } from '../../../utils/routing';
import { NavDropdownItem } from './Header';

const NavDiv = styled.div<React.HTMLAttributes<HTMLDivElement> & { isActive?: boolean }>`
  height: ${props => props.theme.navBar.height}px;
  background-color: ${props => props.theme.colours.componentBackground};
  border: none;
  margin-right: ${props => props.theme.spacing.small}px;
  border-bottom: 2px solid
    ${props => (props.isActive ? props.theme.colours.primaryHighlight : 'transparent')};
  display: flex;
  align-items: center;
  @media (max-width: ${props => props.theme.smallScreenMaxWidth}px) {
    font-size: ${props => props.theme.typography.subtext.fontSize}px;
    margin-right: ${props => props.theme.spacing.extraSmall}px;
    svg {
      display: none;
    }
  }
`;

const NavButton = styled.button<{ isActive?: boolean }>`
  background-color: ${props => props.theme.colours.componentBackground};
  border: none;
  padding: 0;
  color: ${props =>
    props.isActive ? props.theme.colours.primaryHighlight : props.theme.colours.primary};
  font-weight: bold;
  &:hover {
    color: ${props => props.theme.colours.primaryHighlight};
  }
`;

const DropdownIcon = styled(FontAwesomeIcon)`
  height: ${props => props.theme.navBar.iconHeight}px;
  margin-left: ${props => props.theme.spacing.extraSmall}px;
`;

const navDropdownItemWidthPixels = 250;

const NavDropdownItemContainer = styled(DropdownItem)`
  justify-content: left;
  width: ${navDropdownItemWidthPixels}px;
`;

const DropdownItemIcon = styled(FontAwesomeIcon)`
  min-width: 30px;
  height: ${props => props.theme.navBar.iconHeight}px;
  margin-right: ${props => props.theme.spacing.tiny}px;
`;

const NavDropdownMenu = styled(DropdownMenu)`
  width: ${navDropdownItemWidthPixels + 2 * dropdownMenuBorderWidthPixels}px;
`;

type NavDropdownProps<TItem> = {
  items: Array<TItem>;
  children: React.ReactNode;
};

export const NavDropdown = (props: NavDropdownProps<NavDropdownItem>) => {
  const { t } = useTranslation('layout');

  const buttonRef = React.useRef<HTMLButtonElement | null>(null);

  const itemToString = (item: NavDropdownItem | null) => (item ? item.value : '');

  const handleSelect = (selectedItem: NavDropdownItem | null) => {
    if (selectedItem != null) {
      navigate(selectedItem.url);
    }
    if (buttonRef.current) {
      buttonRef.current.blur();
    }
  };

  const renderNavDropdownToggleButton = (
    toggleProps: NavDropdownProps<NavDropdownItem>,
    downshift: ControllerStateAndHelpers<NavDropdownItem>,
  ) => {
    const isActive = toggleProps.items
      .map(i => i.url)
      .some(url => window.location.pathname.includes(url));

    return (
      <NavDiv isActive={isActive}>
        <NavButton {...downshift.getToggleButtonProps()} isActive={isActive} ref={buttonRef}>
          <ButtonLabel {...downshift.getLabelProps()}>
            {props.children}
            <DropdownIcon icon={downshift.isOpen ? faChevronUp : faChevronDown} />
          </ButtonLabel>
        </NavButton>
      </NavDiv>
    );
  };

  const renderNavDropdownItem = (
    item: NavDropdownItem,
    index: number,
    downshift: ControllerStateAndHelpers<NavDropdownItem>,
  ) => (
    <Location key={item.value}>
      {({ location }) => (
        <NavDropdownItemContainer
          {...downshift.getItemProps({
            item,
            index,
            key: item.value,
            isSelected: false,
          })}
          isHighlighted={downshift.highlightedIndex === index}
          isSelected={location.pathname.includes(item.url)}
        >
          <DropdownItemIcon icon={item.icon} />
          {t(`${item.translationKey}`)}
        </NavDropdownItemContainer>
      )}
    </Location>
  );

  return (
    <Downshift itemToString={itemToString} onSelect={handleSelect}>
      {downshift => (
        <DownshiftChildrenContainer {...downshift.getRootProps()} role={undefined}>
          {renderNavDropdownToggleButton(props, downshift)}
          <DropdownMenuContainer>
            {downshift.isOpen ? (
              <NavDropdownMenu {...downshift.getMenuProps()}>
                {props.items.map((item, index) => renderNavDropdownItem(item, index, downshift))}
              </NavDropdownMenu>
            ) : null}
          </DropdownMenuContainer>
        </DownshiftChildrenContainer>
      )}
    </Downshift>
  );
};
