import * as React from 'react';
import { ChangeEvent, useEffect, useRef } from 'react';
import { styled } from '../../styling/theme';
import { BaseInputProps, BaseInputStyling } from './BaseInputStyling';

type CheckboxProps = {
  checked: boolean;
  indeterminate?: boolean; // Sets the checkbox to the indeterminate state '-'
  onChange: (checked: boolean) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  label?: string;
  disabled?: boolean;
  autoComplete?: boolean;
};

type CheckboxInputProps = Omit<
  CheckboxProps,
  'onChange' | 'indeterminate' | 'label' | 'autoComplete'
> & {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  ref: React.RefObject<HTMLInputElement>;
  autoComplete?: 'on' | 'off';
  type?: string;
} & BaseInputProps;

export const StyledInput = styled.input<CheckboxInputProps>`
  ${BaseInputStyling};
  padding: ${props => props.theme.spacing.extraSmall}px;
  width: 0;
  height: 0;
  cursor: pointer;
  -webkit-appearance: none;
  position: relative;

  :focus {
    background-color: ${props => props.theme.colours.secondaryHighlight}33;
  }

  &:checked::after {
    content: '\\2714';
    position: absolute;
    top: 0;
    left: ${props => props.theme.spacing.extraSmall / 2}px;
  }
  &:indeterminate::after {
    content: '\\2013';
    position: absolute;
    top: 0;
    left: 7px;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
  }
`;

export const Checkbox = ({
  checked,
  indeterminate,
  disabled,
  autoComplete,
  label,
  onChange,
  onBlur,
  ...inputProps
}: CheckboxProps & BaseInputProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => onChange(event.target.checked);
  const checkboxRef = useRef<HTMLInputElement>(null);

  // The indeterminate state of a checkbox can only be set through javascript. It's impossible to do it with HTML alone
  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = !!indeterminate;
    }
  });

  return (
    <StyledInput
      aria-label={label}
      autoComplete={autoComplete ? 'on' : 'off'}
      disabled={disabled}
      type="checkbox"
      checked={checked || false}
      onChange={handleChange}
      onBlur={onBlur}
      ref={checkboxRef}
      {...inputProps}
    />
  );
};
