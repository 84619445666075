import { ApiErrorBox } from '../shared/errors/ApiErrorBox';
import { SortingIndicator } from '../shared/tables/sorting/SortingComponents';
import { css, styled } from './theme';

export {
  TableContainer,
  LoadingOverlay,
  StyledTable,
  StyledHeader,
  StyledTh,
  GroupedHeaderTh,
  StyledRow,
  StyledTd,
  StyledSortIndicator,
  StyledErrorBox,
};

const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  width: fit-content;
`;

const LoadingOverlay = styled.div<
  React.HTMLAttributes<HTMLDivElement> & { showLoadingOverlay: boolean }
>`
  background: ${props => props.theme.colours.loadingOverlay};
  display: ${props => (props.showLoadingOverlay ? 'initial' : 'none')};
  opacity: 0.5;
  z-index: 4;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const StyledTable = styled.table`
  background-color: ${props => props.theme.colours.componentBackground};
  border-collapse: collapse;
  border: 1px solid ${props => props.theme.colours.border};
`;

const StyledHeader = styled.thead`
  border: 1px solid ${props => props.theme.colours.border};
  font-weight: bold;
`;

const StyledTh = styled.th<
  React.TableHTMLAttributes<HTMLTableCellElement> & {
    isRightAligned?: boolean;
    fitContent?: boolean;
  }
>`
  ${props =>
    props.fitContent &&
    css`
      width: 0;
    `}

  padding: 0.5rem;
  text-align: ${props => (props.isRightAligned != null && props.isRightAligned ? 'right' : 'left')};
  background-color: ${props => props.theme.colours.componentBackground};
`;

const GroupedHeaderTh = styled(StyledTh)`
  text-align: center;
  border: 1px solid ${props => props.theme.colours.border};
  border-left: 1px solid ${props => props.theme.colours.border};
`;

const StyledSortIndicator = styled(SortingIndicator)`
  padding-left: ${props => props.theme.spacing.extraSmall}px;
`;

const StyledRow = styled.tr`
  border: 1px solid ${props => props.theme.colours.border};
`;

const StyledTd = styled.td<{ canExpand?: boolean; isRightAligned?: boolean }>`
  padding: 0.5rem;
  text-align: ${props => (props.isRightAligned != null && props.isRightAligned ? 'right' : 'left')};
  background-color: ${props =>
    props.canExpand
      ? props.theme.colours.expandableTableRow
      : props.theme.colours.componentBackground};
`;

const StyledErrorBox = styled(ApiErrorBox)`
  margin-top: ${props => props.theme.spacing.small}px;
`;
