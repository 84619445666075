import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faArchive, faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '../../../styling/theme';
import { ItemFlags } from '../item';

type Props = {
  itemFlags: ItemFlags;
};

const WarningIcon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.colours.warningText};
`;

const ErrorIcon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.colours.alertText};
`;

const FlagsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing.tiny}px;
`;

export const ViewItemFlags = (props: Props) => {
  const { t } = useTranslation('item');
  return (
    <FlagsContainer>
      {props.itemFlags.stockWithEmptySupplier && (
        <ErrorIcon icon={faTruck} title={t('itemList.flagsTitleText.stockWithEmptySupplier')} />
      )}
      {props.itemFlags.bulkEditScheduled && (
        <WarningIcon icon={faClock} title={t('itemList.flagsTitleText.bulkEditScheduled')} />
      )}
      {props.itemFlags.disabled && (
        <ErrorIcon icon={faArchive} title={t('itemList.flagsTitleText.disabled')} />
      )}
      {props.itemFlags.discontinuedProduct && (
        <WarningIcon icon={faTruck} title={t('itemList.flagsTitleText.discontinuedProduct')} />
      )}
    </FlagsContainer>
  );
};
