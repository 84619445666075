import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { navigationConfirmationHistory } from '../../utils/routing';

type Config = {
  message?: string;
  shouldConfirmNavigation: boolean;
};

export const useNavigateAwayWarning = ({ message, shouldConfirmNavigation }: Config) => {
  const removeCurrentConfirmationMessage = useRef<(() => void) | null>(null);
  const { t } = useTranslation('navigateAwayMessages');

  const setNavigationConfirmation = () => {
    if (shouldConfirmNavigation) {
      removeCurrentConfirmationMessage.current = navigationConfirmationHistory.addNavigationConfirmation(
        message || t('default'),
      );
    }
  };

  const removeNavigationConfirmation = () => {
    if (removeCurrentConfirmationMessage.current != null) {
      removeCurrentConfirmationMessage.current();
    }
  };

  useEffect(() => {
    removeNavigationConfirmation();
    if (shouldConfirmNavigation) {
      setNavigationConfirmation();
    }

    return removeNavigationConfirmation;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, shouldConfirmNavigation]);
};
