import * as React from 'react';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RequiresOneOfPermissions } from '../../shared/Permissions';
import { DataFetchTable } from '../../shared/tables/Table';
import { UserContext } from '../authentication/loginData/userContext';
import { permissions } from '../authentication/permissions';
import { clearPmsRequestErrors, getPmsRequestErrors } from './pmsRequestErrorApi';
import {
  mapPmsErrorsResponseToTableData,
  pmsRequestErrorsColumns,
} from './pmsRequestErrorTableShared';
import { PrimaryButton } from '../../shared/buttons/Button';
import { userHasOneOfPermissions } from '../authentication/loginData/user';
import { useApiRequest } from '../../shared/hooks/useApiRequest';
import produce from 'immer';
import { styled, theme } from '../../styling/theme';
import { navigate } from '../../utils/routing';
import { ApiErrorBox } from '../../shared/errors/ApiErrorBox';

const ClearButtonWrapper = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  display: flex;
  justify-content: flex-end;
  padding: ${theme.spacing.extraSmall}px;
`;

const ErrorBoxWrapper = styled.div`
  flex: 1;
`;

const ClearAllErrorsButton = styled(PrimaryButton)`
  border-radius: ${theme.spacing.extraTiny}px;
`;

export const ViewPmsRequestErrorsTable = () => {
  const { t } = useTranslation('pmsRequestError');
  const { user } = useContext(UserContext);
  const { makeRequest, apiError, inProgress } = useApiRequest(clearPmsRequestErrors);
  const [isClearAllErrorsButtonVisible, setIsClearAllErrorsButtonVisible] = React.useState(false);

  const handleClearAllErrors = async () => {
    if (window.confirm(t('viewErrors.buttons.clearAllErrorsConfirmation'))) {
      try {
        await makeRequest({});

        // To trigger a refetch of the original query, navigate to page 2.
        // (Page 2 should not exist, as all errors should be cleared, so it will redirect back to page 1, triggering a refetch.)
        // n.b. Once React Query is implemented, this can be removed and refetch() used instead.
        navigate('/?pageNumber=2');
      } catch (error) {
        // ApiErrorBox will display errors caught by axios: all others are currently unhandled.
        // (These will be displayed by toasts once flowbite is implemented.)
      }
    }
  };

  const columns = useMemo(() => pmsRequestErrorsColumns(t, user), [t, user]);

  const clearAllErrorsButtonTheme = produce(theme, draft => {
    draft.colours.buttons.primary = theme.colours.warningText;
    draft.colours.buttons.primaryHighlight = theme.colours.alertText;
    draft.colours.buttons.primaryTextColour = 'initial';
  });

  return (
    <RequiresOneOfPermissions permissions={[permissions.SuperAdmin, permissions.CentralAdmin]}>
      {userHasOneOfPermissions(user, [permissions.SuperAdmin]) && (
        <>
          <ClearButtonWrapper
            style={apiError ? { backgroundColor: theme.colours.alertBackground } : {}} // Must be added as in-line style, to avoid warning for creating a styled component within another component
          >
            {apiError && (
              <ErrorBoxWrapper>
                <ApiErrorBox error={apiError} />
              </ErrorBoxWrapper>
            )}

            {isClearAllErrorsButtonVisible && (
              <ClearAllErrorsButton
                onClick={handleClearAllErrors}
                loading={inProgress}
                theme={clearAllErrorsButtonTheme}
                aria-label="Clear All Errors"
              >
                {t('viewErrors.buttons.clearAllErrors')}
              </ClearAllErrorsButton>
            )}
          </ClearButtonWrapper>

          <DataFetchTable
            columns={columns}
            getApiData={getPmsRequestErrors}
            mapResponseToTableData={mapPmsErrorsResponseToTableData}
            pageSize={10}
            emptyTableMessage={t('viewErrors.emptyTableMessage')}
            disableStickyHeader={true}
            onDataFetchSuccess={data => {
              setIsClearAllErrorsButtonVisible(data.totalItems > 0);
            }}
          />
        </>
      )}
    </RequiresOneOfPermissions>
  );
};
