import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Match } from '@reach/router';
import { includes } from 'lodash';
import * as React from 'react';
import { Component } from 'react';
import { Link } from '../../../shared/navigation/Link';
import { styled } from '../../../styling/theme';
import { NavIcon } from './NavIcon';

export const linkWithIconTestId = 'link-with-icon';

// We need to strip out the isActive prop used for styling to avoid an error from Reach Router
const LinkWrapper = ({
  isActive,
  ...rest
}: {
  isActive?: boolean;
  to: string;
  children: React.ReactNode;
  onClick?: () => void;
}) => <Link {...rest} />;

const StyledLink = styled(LinkWrapper)<{ isActive?: boolean }>`
  color: ${props =>
    props.isActive ? props.theme.colours.primaryHighlight : props.theme.colours.primary};
  font-weight: bold;
  &:hover {
    color: ${props => props.theme.colours.primaryHighlight};
  }
`;

const LinkContainer = styled.div<React.HTMLAttributes<HTMLDivElement> & { isActive?: boolean }>`
  height: 100%;
  margin-right: ${props => props.theme.spacing.small}px;
  border-bottom: 2px solid
    ${props => (props.isActive ? props.theme.colours.primaryHighlight : 'transparent')};
  display: flex;
  align-items: center;
  @media (max-width: ${props => props.theme.smallScreenMaxWidth}px) {
    font-size: ${props => props.theme.typography.subtext.fontSize}px;
    margin-right: ${props => props.theme.spacing.extraSmall}px;
    svg {
      display: none;
    }
  }
`;

export const LinkWithIcon = (props: {
  isActive?: boolean;
  to: string;
  children: React.ReactNode;
  onClick?: () => void;
  icon: IconProp;
}) => {
  return (
    <LinkContainer isActive={props.isActive} data-testid={linkWithIconTestId}>
      <StyledLink to={props.to} isActive={props.isActive}>
        <NavIcon icon={props.icon} />
        {props.children}
      </StyledLink>
    </LinkContainer>
  );
};

type NavLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  to: string;
  children: React.ReactNode;
  icon: IconProp;
};

export class NavLink extends Component<NavLinkProps> {
  isActiveLink = (location: string) => {
    if (this.props.to === '/') {
      return this.props.to === location;
    } else {
      return this.props.to === location || includes(location, this.props.to);
    }
  };
  render() {
    return (
      <Match path={this.props.to}>
        {({ location }) => (
          <LinkWithIcon
            to={this.props.to}
            isActive={this.isActiveLink(location.pathname)}
            icon={this.props.icon}
          >
            {this.props.children}
          </LinkWithIcon>
        )}
      </Match>
    );
  }
}
