import { filter, find, reduce, some, sortBy } from 'lodash';
import { DropdownOptions } from '../forms/DropdownField';
import { BaseDropDownType } from './useMultiSelectDropdown';

export const dropdownItemTestId = <TFieldName, TValue>(fieldName: TFieldName, itemValue: TValue) =>
  `${fieldName}:${itemValue}`;

export const getDisplayTextForDropdownValueOrUndefined = <TValue>(
  dropdownOptions: DropdownOptions<TValue>,
  value: TValue,
) => {
  const foundItem = find(dropdownOptions, option => option.value === value);

  if (foundItem) {
    return foundItem.displayText;
  }

  return undefined;
};

export const getValidDropdownOptionsForDropdownValues = <
  TValue,
  TItem extends BaseDropDownType<TValue>
>(
  dropdownOptions: Array<TItem>,
  values: Array<TValue>,
  sortSelectedItems?: (item: TItem) => number | string,
): Array<TItem> => {
  const foundItems = reduce<TValue, Array<TItem>>(
    values,
    (array, value) => {
      const foundItem = find(dropdownOptions, option => option.value === value);
      if (foundItem) {
        array.push(foundItem);
      }

      return array;
    },
    [],
  );

  const filteredItems = filter(foundItems, item => item !== undefined);
  if (sortSelectedItems === undefined) {
    return filteredItems;
  }
  return sortBy(filteredItems, sortSelectedItems);
};

export const getValidDropdownValues = <TValue>(
  dropdownOptions: Array<BaseDropDownType<TValue>>,
  values: Array<TValue>,
): Array<TValue> => filter(values, option => some(dropdownOptions, o => o.value === option));
