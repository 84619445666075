import { TFunction } from 'i18next';
import { filter, map, sortBy } from 'lodash';
import { ById } from '../../../models/id';
import { DropdownOption } from '../../../shared/forms/DropdownField';
import { CountryCode } from '../../authentication/loginData/metadata';

export type PmsUsage = {
  isUsingMerlin: boolean;
};

export const reminderDeltaOptions = ['Day(s)', 'Week(s)', 'Month(s)'];

export type ItemFieldsFromPmsResponse = {
  typeOptions: { [code: string]: ItemType };
  discountCategoryOptions: ById<ItemLibrary>;
  dispensingLabelOptions: ById<ItemLibrary>;
  speciesOptions: ById<ItemLibrary>;
  category1Options: ById<ItemLibraryTreatmentType>;
  category2Options: ById<ItemLibrary>;
  category2OptionsByTypeCode: { [type: string]: Array<ItemLibrary> };
  category3Options: ById<ItemLibrary>;
  clientCategoryOptions: ById<ItemLibrary>;
  legalCategoryOptions: ById<ItemLibraryWithCountry>;
  taxRateOptions: ById<ItemLibraryWithCountry>;
  hcpGroupOptions: ById<ItemLibrary>;
  manufacturerOptions: ById<ItemLibrary>;
  organisationPmsUsage: PmsUsage;
  costTypeOptions: ById<ItemLibrary>;
  documentTemplateOptions: ById<ItemLibrary>;
  reminderTypeOptions: ById<ItemLibrary>;
  sendToOptions: ById<ItemLibrary>;
};

export type ItemPriceField = 'RecommendedPrice' | 'CentralMarkupPercentage' | 'Discount';

type ItemCategory = {
  defaultPriceField: ItemPriceField;
  requiresSupplierProducts: boolean;
  requiresUnitsPerPack: boolean;
};

export const itemPriceField = (
  itemType: string,
  category1Id: number | null,
  pmsFields: ItemFieldsFromPmsResponse,
): ItemPriceField => {
  const category1 = category1Id != null ? pmsFields.category1Options[category1Id] : null;
  if (category1 != null && category1.name === 'Discount') {
    return 'Discount';
  } else {
    const itemCategory = pmsFields.typeOptions[itemType].category;
    return itemCategory.defaultPriceField;
  }
};

export type ItemType = {
  code: string;
  translationKey: string;
  category: ItemCategory;
};

export type ItemLibrary = {
  id: number;
  name: string;
  isDisabled: boolean;
  treatTypeId?: number | null;
};

export type ItemLibraryWithCountry = ItemLibrary & {
  countryCode: CountryCode;
};

export type ItemLibraryTreatmentType = ItemLibrary & {
  stockItem: boolean;
};

export const itemLibraryDropdownOptions = (
  options: ById<ItemLibrary>,
): Array<DropdownOption<number>> =>
  map(
    sortBy(
      filter(options, option => !option.isDisabled),
      option => option.name,
    ),
    option => ({ displayText: option.name, value: option.id }),
  );

/**
 * Jira pp-931 - filter do not display service items on the treatment
 * type deviation form
 */
export const Category1OptionsNoDiscount = (
  options: ById<ItemLibraryTreatmentType>,
): Array<DropdownOption<number>> =>
  map(
    sortBy(
      filter(
        options,
        option => option.name != 'Discount' && !option.isDisabled && option.stockItem,
      ),
      option => option.name,
    ),
    option => ({ displayText: option.name, value: option.id }),
  );

export const itemLibraryDropdownOptionsForCountry = (
  options: ById<ItemLibraryWithCountry>,
  countryCode: CountryCode,
): Array<DropdownOption<number>> =>
  itemLibraryDropdownOptions(
    map(
      filter(options, option => option.countryCode === countryCode),
      option => ({ id: option.id, name: option.name, isDisabled: option.isDisabled }),
    ),
  );

export const itemTypeDropdownOptions = (
  pmsFields: ItemFieldsFromPmsResponse,
  t: TFunction,
): Array<DropdownOption<string>> => {
  const types = filter(pmsFields.typeOptions);
  return map(pmsFields.typeOptions, type => ({
    displayText: t(type.translationKey),
    value: type.code,
  }));
};

export const reminderItemLibraryDropdownOptions = (
  options: ById<ItemLibrary>,
  treatmentTypeId: number | null,
): Array<DropdownOption<number>> =>
  map(
    filter(
      options,
      option =>
        !option.isDisabled &&
        (option.treatTypeId == null || option.treatTypeId === treatmentTypeId),
    ).sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }

      if (a.name > b.name) {
        return 1;
      }

      return 0;
    }),
    option => ({ displayText: option.name, value: option.id }),
  );

export const stringToDropDownOptions = (options: Array<string>) => {
  const result: Array<DropdownOption<string>> = options.map(m => {
    return { displayText: m, value: m };
  });

  return result;
};

export const itemCategory2Options = (
  itemFieldsFromPms: ItemFieldsFromPmsResponse,
  type: string | null,
) => itemFieldsFromPms.category2Options;

/* pac-115: if either no type (stock or service) is selected
  or both are selected, then show all category1 items, 
  else only show items of the selected type
*/
export const filterCategory1OptionsByType = (
  options: ById<ItemLibraryTreatmentType>,
  types: string | Array<string> | null,
): Array<DropdownOption<number>> => {
  const optionsArray = Object.values(options);

  // Convert types to an array if it's a string
  const typesArray = Array.isArray(types) ? types : types ? [types] : null;

  const filteredOptions = optionsArray.filter(item => {
    if (item.isDisabled) return false;

    if (typesArray) {
      if (typesArray.includes('Stock') && !typesArray.includes('Service')) {
        return item.stockItem;
      } else if (!typesArray.includes('Stock') && typesArray.includes('Service')) {
        return !item.stockItem;
      }
    }

    return true;
  });

  return map(
    sortBy(filteredOptions, option => option.name),
    option => ({ displayText: option.name, value: option.id }),
  );
};
