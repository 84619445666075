import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import React from 'react';

let appInsights: ApplicationInsights | null = null;

export const setupApplicationInsights = () => {
  if (appInsights == null && window.pacmanConfig.applicationInsights.enableApplicationInsights) {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: window.pacmanConfig.applicationInsights.instrumentationKey,
        enableDebug: false,
      },
    });
    appInsights.loadAppInsights();
  }
};

export const setApplicationInsightsUser = (email: string) => {
  if (appInsights) {
    appInsights.setAuthenticatedUserContext(email);
  }
};

export const removeApplicationInsightUser = () => {
  if (appInsights) {
    appInsights.clearAuthenticatedUserContext();
  }
};

export const trackError = (error: Error, errorInfo: React.ErrorInfo) => {
  if (appInsights) {
    appInsights.trackException({
      exception: error,
      properties: {
        ...errorInfo,
      },
    });
  }
};

export const trackMissingTranslation = (
  // tslint:disable-next-line:array-type The other array type doesn't work with the readonly keyword
  languages: readonly string[],
  namespace: string,
  key: string,
) => {
  if (appInsights) {
    appInsights.trackEvent({
      name: 'Missing Translation',
      properties: {
        languages,
        namespace,
        key,
      },
    });
  }
};
