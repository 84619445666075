/*
    Shared Deviation functions for Deviation Edit and View pages
*/
import { DeviationResponse } from './deviation';

export const getDispenseFeePercentageDeviationFromDeviationResponse = (
  deviation: DeviationResponse,
): number => {
  return getDispenseFeePercentageDeviationFromValues(
    deviation.itemDeviationDispenseFeeOverride,
    deviation.treatmentTypeDeviationId,
    deviation.dispenseFeeDeviationPercentValue ? deviation.dispenseFeeDeviationPercentValue : 0,
    deviation.defaultDispenseFeeDeviationPercentValue,
  );
};

/**
 * jira pp-639 | The TX deviaton was showing if we had a TX with an ID and the TX was 0, in this case
 *               we need to show the deviation not the Tx dev.
 * Jira PP-1047 | Tx Deviation rules have chanhed, if a TX is 0 then it is '0'. A TX of '0' used
 *              to mean 'We have no TX - show the Deviation, now a TX of 0 means, it is 0
 * @param itemDeviationDispenseFeeOverride
 * @param treatmentTypeDeviationId
 * @param dispenseFeeDeviationPercentValue
 * @param defaultDispenseFeeDeviationPercentValue
 * @returns
 */
export const getDispenseFeePercentageDeviationFromValues = (
  itemDeviationDispenseFeeOverride: boolean,
  treatmentTypeDeviationId: number | null,
  dispenseFeeDeviationPercentValue: number,
  defaultDispenseFeeDeviationPercentValue: number,
): number => {
  if (itemDeviationDispenseFeeOverride || treatmentTypeDeviationId == null) {
    return dispenseFeeDeviationPercentValue == null ? 0 : dispenseFeeDeviationPercentValue;
  }

  return defaultDispenseFeeDeviationPercentValue;
};

/**
 * jira pp-639 | The TX deviaton was showing if we had a TX with an ID and the TX was 0, in this case
 *               we need to show the deviation not the Tx dev.
 * jira pp-1047 | This rule has now changed, if the tx is 0 we show it
 * @param itemDeviationPriceOverride
 * @param treatmentTypeDeviationId
 * @param priceDeviationPercentValue
 * @param defaultPriceDeviationPercentValue
 * @returns
 */
export const getPricePercentageDeviationFromValues = (
  itemDeviationPriceOverride: boolean,
  treatmentTypeDeviationId: number | null,
  priceDeviationPercentValue: number,
  defaultPriceDeviationPercentValue: number,
): number => {
  if (itemDeviationPriceOverride || treatmentTypeDeviationId == null) {
    return priceDeviationPercentValue == null ? 0 : priceDeviationPercentValue;
  }

  return defaultPriceDeviationPercentValue;
};

export const getPricePercentageDeviationFromDeviationResponse = (deviation: DeviationResponse) => {
  return getPricePercentageDeviationFromValues(
    deviation.itemDeviationPriceOverride,
    deviation.treatmentTypeDeviationId,
    deviation.priceDeviationPercentValue ? deviation.priceDeviationPercentValue : 0,
    deviation.defaultPriceDeviationPercentValue,
  );
};

/**
 * jira pp-639 | The TX deviaton was showing if we had a TX with an ID and the TX was 0, in this case
 *               we need to show the deviation not the Tx dev.
 * @param deviation
 * @param useGrossPrice
 * @returns
 */
export const getPriceValueDeviation = (deviation: DeviationResponse, useGrossPrice: Boolean) => {
  if (
    deviation.itemDeviationPriceOverride ||
    deviation.treatmentTypeDeviationId == null ||
    deviation.defaultPriceDeviationPercentValue === 0
  ) {
    if (useGrossPrice) {
      return deviation.localItemPriceGross == null ? 0 : deviation.localItemPriceGross;
    } else {
      return deviation.localItemPriceNet == null ? 0 : deviation.localItemPriceNet;
    }
  } else {
    if (useGrossPrice) {
      return deviation.defaultPriceGross == null ? 0 : deviation.defaultPriceGross;
    } else {
      return deviation.defaultPriceNet == null ? 0 : deviation.defaultPriceNet;
    }
  }
};

/**
 * jira pp-639 | The TX deviaton was showing if we had a TX with an ID and the TX was 0, in this case
 *               we need to show the deviation not the Tx dev.
 * @param deviation
 * @param useGrossPrice
 * @returns
 */
export const getDispenseFeeValueDeviation = (
  deviation: DeviationResponse,
  useGrossPrice: Boolean,
) => {
  if (
    deviation.itemDeviationDispenseFeeOverride ||
    deviation.treatmentTypeDeviationId == null ||
    deviation.defaultDispenseFeeDeviationPercentValue == 0
  ) {
    if (useGrossPrice) {
      return deviation.localItemDispenseFeeGross == null ? 0 : deviation.localItemDispenseFeeGross;
    } else {
      return deviation.localItemDispenseFeeNet == null ? 0 : deviation.localItemDispenseFeeNet;
    }
  } else {
    if (useGrossPrice) {
      return deviation.defaultDispenseFeeGross == null ? 0 : deviation.defaultDispenseFeeGross;
    } else {
      return deviation.defaultDispenseFeeNet == null ? 0 : deviation.defaultDispenseFeeNet;
    }
  }
};
