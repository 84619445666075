import * as React from 'react';
import { styled } from '../../styling/theme';

type Props = {
  className?: string;
  message: string;
  subMessage?: string;
  showSuccess?: boolean;
  noMinHeight?: boolean;
};

export const successBoxTestId = 'success-box-test-id';

const SuccessHeightPlaceholder = styled.div<
  React.HTMLAttributes<HTMLDivElement> & { noMinHeight?: boolean }
>`
  margin-top: ${props => (props.noMinHeight ? 0 : props.theme.spacing.small)}px;
  margin-bottom: ${props => (props.noMinHeight ? 0 : props.theme.spacing.small)}px;
  min-height: ${props => (props.noMinHeight ? 0 : 90)}px;
`;

const SuccessContainer = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  background-color: ${props => props.theme.colours.successBackground};
  color: ${props => props.theme.colours.successText};
  padding: ${props => props.theme.spacing.extraSmall}px ${props => props.theme.spacing.small}px;
`;

const SuccessMessage = styled.div`
  font-size: ${props => props.theme.typography.headings.h3fontSize};
  font-weight: bold;
  margin: 0;
`;

const SuccessSubMessage = styled.div`
  margin-bottom: 0;
`;

export const SuccessBox = (props: Props) => {
  return (
    <SuccessHeightPlaceholder className={props.className} noMinHeight={props.noMinHeight}>
      {(props.showSuccess === undefined || props.showSuccess) && (
        <SuccessContainer data-testid={successBoxTestId}>
          <SuccessMessage>{props.message}</SuccessMessage>
          {props.subMessage && <SuccessSubMessage>{props.subMessage}</SuccessSubMessage>}
        </SuccessContainer>
      )}
    </SuccessHeightPlaceholder>
  );
};

export const shouldShowSuccess = (): boolean => !!window.history.state?.success;
