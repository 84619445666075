import { RouteComponentProps } from '@reach/router';
import { useMemo } from 'react';
import { useCallback, useContext, useEffect } from 'react';
import * as React from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { HollowLinkButton } from '../../shared/buttons/LinkButton';
import { ApiErrorBox } from '../../shared/errors/ApiErrorBox';
import { FieldAndValue } from '../../shared/FieldAndValue';
import { useApiRequest } from '../../shared/hooks/useApiRequest';
import { RequiresOneOfPermissions } from '../../shared/Permissions';
import { DataFetchTable } from '../../shared/tables/Table';
import { UserContext } from '../authentication/loginData/userContext';
import { permissions } from '../authentication/permissions';
import { getDeviationBulkEdit } from '../deviations/deviationsApi';
import { viewDeviationBulkEditUrl } from '../deviations/deviationsUrls';
import { PageHeading } from '../layout/PageHeading';
import { getPmsRequestErrorsForDeviationBulkEdit } from './pmsRequestErrorApi';
import {
  mapPmsErrorsResponseToTableData,
  pmsRequestErrorsColumns,
} from './pmsRequestErrorTableShared';

type OwnProps = RouteComponentProps<{ deviationBulkEditId: string }> & {};
type Props = OwnProps;

export const ViewPmsRequestErrorsForDeviationBulkEdit = (props: Props) => {
  const { t } = useTranslation('pmsRequestError');
  const { user } = useContext(UserContext);
  const { makeRequest, response, apiError } = useApiRequest(
    getDeviationBulkEdit(Number(props.deviationBulkEditId)),
  );

  useEffect(() => {
    makeRequest({ pageSize: 1, pageNumber: 1 }).then();
  }, []);

  const columns = useMemo(() => pmsRequestErrorsColumns(t, user), [t, user]);
  const getErrors = useCallback(
    getPmsRequestErrorsForDeviationBulkEdit(Number(props.deviationBulkEditId)),
    [props.deviationBulkEditId],
  );

  return (
    <>
      <Title>{t('viewForDeviationBulkEdit.title')}</Title>
      <PageHeading>
        <h1>{t('viewForDeviationBulkEdit.heading')}</h1>
        <HollowLinkButton to={viewDeviationBulkEditUrl(Number(props.deviationBulkEditId))}>
          {t('viewForDeviationBulkEdit.viewBulkEditButton')}
        </HollowLinkButton>
      </PageHeading>
      {response && (
        <>
          <FieldAndValue
            fieldName={t('viewForDeviationBulkEdit.scheduledDate')}
            value={response.scheduledDate}
            type={'date'}
          />
          <FieldAndValue
            fieldName={t('viewForDeviationBulkEdit.name')}
            value={response.name}
            type={'text'}
          />
        </>
      )}
      {apiError && <ApiErrorBox error={apiError} />}
      <RequiresOneOfPermissions
        permissions={[permissions.SuperAdmin, permissions.CentralAdmin, permissions.SitePriceAdmin]}
      >
        <DataFetchTable
          columns={columns}
          pageSize={10}
          getApiData={getErrors}
          mapResponseToTableData={mapPmsErrorsResponseToTableData}
          emptyTableMessage={t('viewErrors.emptyTableMessage')}
        />
      </RequiresOneOfPermissions>
    </>
  );
};
