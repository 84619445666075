export type SortDirection = 'asc' | 'desc';

export const validSortDirectionOrUndefined = (input: string): SortDirection | undefined =>
  input === 'asc' || input === 'desc' ? input : undefined;

export type SortResponse<TColumnNames> = {
  sortBy?: TColumnNames | null; // If not specified, backend returns null
  direction?: SortDirection | null; // As above
};

export type SortRequest<TColumnNames> = {
  sortBy?: TColumnNames;
  direction?: SortDirection;
};
