import { FileResponse } from '../models/fileResponse';

export const saveFileFromFileResponse = (
  response: FileResponse,
  fileName: string,
  fileType: string,
) => {
  if (response) {
    const url = window.URL.createObjectURL(new Blob([response], { type: fileType }));
    saveFileFromUrl(url, fileName);
    window.URL.revokeObjectURL(url);
  }
};

export const saveFileFromUrl = (url: string, fileName: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.setAttribute('data-testid', 'download-link');
  link.click();
  link.remove();
};
