import { Id } from '../../models/id';
import { itemsUrl } from '../../urls';

export const editItemUrl = (itemId: Id) => `${itemsUrl()}/edit/${itemId}`;

export const viewItemUrl = (itemId: Id) => `${itemsUrl()}/view/${itemId}`;

export const createItemUrl = () => `${itemsUrl()}/create`;

export const uploadItemsUrl = () => `${itemsUrl()}/upload`;

export const viewItemBulkEditUrl = (itemBulkEditId: Id) =>
  `${itemsUrl()}/bulk-edit/view/${itemBulkEditId}`;

export const uploadItemBulkEditUrl = () => `${itemsUrl()}/bulk-edit/upload`;
