import { TFunction } from 'i18next';
import { Id } from '../../models/id';
import { Locale } from '../../models/locale';
import { TranslatableValidator } from '../../utils/validation/TranslatableValidator';

export type SupplierResponse = {
  supplierId: Id;
  pmsSupplierId: Id;
  supplierName: string;
  isArchived: boolean;
  uploadDate: string;
};

export type SuppliersResponse = {
  suppliers: Array<SupplierResponse>;
};

export type EditSupplierFormModel = {
  supplierId: Id | null;
  pmsSupplierId: Id | null;
};

export type EditSupplierCommand = {
  pmsSupplierId: Id;
  supplierId: Id;
};

export type EditSupplierCommandWrapper = {
  command: EditSupplierCommand;
};

export type Supplier = {
  id: Id;
  name: string;
};

export class EditSupplierFormModelValidator extends TranslatableValidator<EditSupplierFormModel> {
  constructor(t: TFunction, locale: Locale) {
    super(t, locale);
    this.ruleFor('pmsSupplierId')
      .notNull()
      .withMessage(t('supplier:validation.pmsSupplierId.empty'));
  }
}
