import { DateTime } from 'luxon';

/**
 *
 * Append a date-time to the given string in the format _yyyyMMddHHmmss.
 *
 * @example
 * appendDateToString(example) // returns "example_20240101123000"
 *
 */
export const appendDateTimeToString = (str: string): string => {
  const dateString = DateTime.now().toFormat('yyyyMMddHHmmss');
  return `${str}_${dateString}`;
};
