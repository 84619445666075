import XLSX from 'xlsx';
import { FileResponse } from '../models/fileResponse';
import * as CSV from 'csv-string';

/**
 * Convert the FileResponse to an Excel file and download
 * Reference: https://www.youtube.com/watch?v=vbzAnI1hvQU
 */
export const ExcelDownload = (
  response: Array<Array<any>>,
  filename: string,
  sheetName: string,
  options?: XLSX.WritingOptions,
) => {
  const workSheet = XLSX.utils.aoa_to_sheet(response);
  const workBook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
  XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });
  XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
  XLSX.writeFile(workBook, filename, options);
};

export const ExcelDownloadFromCsvFileResponse = (
  response: FileResponse,
  filename: string,
  sheetName: string,
  options?: XLSX.WritingOptions,
) => ExcelDownload(responseToArray(response), filename, sheetName, options);

/**
 * Response is a CSV output converted to string by the API
 *
 * Convert the string (CSV) to an Array for the XLSX plugin
 *
 * The double quotes character '"' will surround a data entry in the CSV when it
 * includes a separator character, so that the CSV parser knows that this is
 * part of the data string (e.g. "Anderson, Denesik and Stark"), and not a
 * data separator.
 *
 * @param response
 * @returns Array
 */
const responseToArray = (response: FileResponse): Array<Array<string>> => {
  const separator = ',';
  const quoteCharacter = '"';
  return CSV.parse(response as string, separator, quoteCharacter);
};
