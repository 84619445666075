import { FormikProps } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxField } from '../../shared/forms/CheckboxField';
import { CurrencyField } from '../../shared/forms/CurrencyField';
import {
  FormFieldGroup,
  FormFieldGroupContainer,
  FormFieldSubSection,
  FormGroupTitle,
} from '../../shared/forms/FormContainers';
import { SingleSelectDropdownField } from '../../shared/forms/SingleSelectDropdownField';
import { CreateOrEditItemFormModel, ItemResponse } from './item';
import { NewOrExistingSupplierProductFields } from './ItemSupplierProducts/NewOrExistingSupplierProductFields';
import {
  ItemFieldsFromPmsResponse,
  itemLibraryDropdownOptionsForCountry,
  ItemPriceField,
} from './PmsFields/pmsFields';
import { CountryResponse } from '../authentication/loginData/metadata';

export const CountrySpecificItemDetailsNamePrefix = (index: number): string =>
  `countrySpecificItemDetailsList.[${index}]`;

type CountryDetailsGroupProps = {
  country: CountryResponse;
  itemFieldsFromPms: ItemFieldsFromPmsResponse;
  existingItem?: ItemResponse;
  formikProps: FormikProps<CreateOrEditItemFormModel>;
  priceField: ItemPriceField | null;
  isServiceType: boolean;
  index: number;
};

export const CountryDetailsGroup = (props: CountryDetailsGroupProps) => {
  const { t } = useTranslation('item');
  const supplierProduct =
    props.existingItem?.countrySpecificItemDetailsResponseList?.find(
      csd => csd.country.code === props.country.code,
    )?.itemSupplierProductResponse ?? undefined;

  return (
    <FormFieldGroupContainer>
      <FormGroupTitle>
        {t(`itemLabels.CountryDetailsGroupTitles.${props.country.code}`)}
      </FormGroupTitle>
      <FormFieldGroup>
        <CheckboxField
          name={`${CountrySpecificItemDetailsNamePrefix(props.index)}.isEnabledForCountry`}
          label={t('itemLabels.isEnabledForCountry')}
          labelPosition={'right'}
          testId={`enabled-for-country-${props.country.code}`}
        />
        {props.formikProps.values.countrySpecificItemDetailsList?.[props.index]
          ?.isEnabledForCountry && (
          <>
            <NewOrExistingSupplierProductFields
              index={props.index}
              formikProps={props.formikProps}
              initialSupplierProduct={supplierProduct}
              itemIsServiceType={props.isServiceType}
              country={props.country}
            />
            <SingleSelectDropdownField
              name={`${CountrySpecificItemDetailsNamePrefix(props.index)}.taxRateId`}
              label={t('itemLabels.taxRate')}
              options={itemLibraryDropdownOptionsForCountry(
                props.itemFieldsFromPms.taxRateOptions,
                props.country.code,
              )}
              testId={`tax-rate-${props.country.code}`}
            />
            {!props.isServiceType && (
              <SingleSelectDropdownField
                name={`${CountrySpecificItemDetailsNamePrefix(props.index)}.legalCategoryId`}
                label={t('itemLabels.legalCategory')}
                options={itemLibraryDropdownOptionsForCountry(
                  props.itemFieldsFromPms.legalCategoryOptions,
                  props.country.code,
                )}
                deselectable={true}
                testId={`legal-category-${props.country.code}`}
              />
            )}
            {props.priceField !== 'Discount' && (
              <>
                <CheckboxField
                  name={`${CountrySpecificItemDetailsNamePrefix(props.index)}.overwrite`}
                  label={t('itemLabels.overwrite')}
                  labelPosition={'right'}
                  testId={`overwrite-${props.country.code}`}
                />
                {props.formikProps.values.countrySpecificItemDetailsList?.[props.index]
                  ?.overwrite && (
                  <FormFieldSubSection>
                    <CurrencyField
                      name={`${CountrySpecificItemDetailsNamePrefix(props.index)}.overwriteAmount`}
                      label={t('itemLabels.overwriteAmount')}
                      currency={props.country.currency}
                      testId={`overwrite-amount-${props.country}`}
                    />
                  </FormFieldSubSection>
                )}
              </>
            )}
          </>
        )}
      </FormFieldGroup>
    </FormFieldGroupContainer>
  );
};
