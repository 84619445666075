import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Link as RouterLink } from '@reach/router';
import * as React from 'react';
import { css, styled } from '../../styling/theme';
import { MarginLeftIcon } from '../StyledIcons';

const linkStyle = css`
  cursor: pointer;
  color: ${props => props.theme.colours.primary};
  text-decoration: none;
  font-weight: bold;

  &:hover {
    color: ${props => props.theme.colours.primaryHighlight};
  }
`;

export const Link = styled(RouterLink)`
  ${linkStyle}
`;

const StyledAnchorTag = styled.a<React.AnchorHTMLAttributes<HTMLAnchorElement>>`
  ${linkStyle}
`;

type ExternalLinkProps = {
  to: string;
  children: React.ReactNode;
};

export const ExternalLink = (props: ExternalLinkProps) => {
  return (
    <StyledAnchorTag href={props.to} target="_blank" rel="noopener noreferrer">
      {props.children}
      <MarginLeftIcon icon={faExternalLinkAlt} />
    </StyledAnchorTag>
  );
};
