import { RouteComponentProps, Router } from '@reach/router';
import * as React from 'react';
import { PageNotFound } from '../PageNotFound';
import { CreateOrganisationGroup } from './CreateOrganisationGroup';
import { EditOrganisationGroup } from './EditOrganisationGroup';
import { OrganisationGroups } from './OrganisationGroups';

export const OrganisationsSection = (props: RouteComponentProps) => (
  <Router primary={false}>
    <OrganisationGroups path="/" />
    <CreateOrganisationGroup path="create" />
    <EditOrganisationGroup path="edit/:organisationId" />
    <PageNotFound default={true} />
  </Router>
);
