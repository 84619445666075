import React, { Suspense, useContext } from 'react';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { styled, theme } from '../../styling/theme';
import { UserContext } from '../authentication/loginData/userContext';
import { Header } from './header/Header';

const PageContent = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  padding: ${props => props.theme.spacing.large}px;
  padding-top: ${props => props.theme.navBar.height + props.theme.spacing.medium}px;
  width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: ${props => props.color};
  @media (max-width: ${props => props.theme.smallScreenMaxWidth}px) {
    padding: ${props => props.theme.spacing.medium}px;
    padding-top: ${props => props.theme.navBar.height + props.theme.spacing.medium}px;
  }
`;

export const Layout = (props: { children: React.ReactNode }) => {
  const { user } = useContext(UserContext);
  const orgBackgroundColour = (): string => {
    switch (user.organisationGroupId) {
      case 10000: // UK
        return theme.colours.appBackground;
      case 10001: // Ireland
        return theme.colours.irelandAppBackGround;
      case 10002: // Farm
        return theme.colours.farmAppBackGround;
      default:
        return theme.colours.appBackground;
    }
  };

  return (
    <>
      <Header />
      <PageContent color={orgBackgroundColour()}>
        <Suspense fallback={<LoadingIndicator />}>{props.children}</Suspense>
      </PageContent>
    </>
  );
};
