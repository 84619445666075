import * as React from 'react';
import { FunctionComponent, useContext } from 'react';
import { UserContext } from '../feature/authentication/loginData/userContext';
import { toShortDate, toShortDateTimeWithSeconds } from '../models/dates/dateTimeStamp';
import { CurrencyCode } from '../models/locale';
import { styled } from '../styling/theme';
import { formatCurrency, formatMultipleCurrencyValues } from '../utils/currencyUtils';
import { formatNumber } from '../utils/numberUtils';
import { PrettyJson } from './json/PrettyJson';
import { getTrueOrFalseIcon } from './StyledIcons';

type LayoutDirection = 'horizontal' | 'vertical';

const Container = styled.div<React.HTMLAttributes<HTMLDivElement> & { layout: LayoutDirection }>`
  display: flex;
  flex-direction: ${props => (props.layout === 'horizontal' ? 'row' : 'column')};
  margin-bottom: ${props => props.theme.spacing.small}px;
`;

const Field = styled.div<React.HTMLAttributes<HTMLDivElement> & { layout: LayoutDirection }>`
  flex: ${props => (props.layout === 'horizontal' ? '0 400px' : undefined)};
  margin-bottom: ${props =>
    props.layout === 'vertical' ? `${props.theme.spacing.extraTiny}px` : undefined};
  font-weight: bold;
`;

const Value = styled.div`
  flex: 1;
`;

type FieldAndValueLayoutProps = {
  fieldName: string;
  layout?: LayoutDirection;
};
const FieldAndValueLayout: FunctionComponent<FieldAndValueLayoutProps> = ({
  fieldName,
  layout = 'horizontal',
  children,
}) => (
  <Container layout={layout}>
    <Field layout={layout}>{fieldName}</Field>
    <Value>{children}</Value>
  </Container>
);

type FieldAndJsonValueProps = {
  fieldName: string;
  value: string;
  hideIfMissing?: boolean;
  layout?: LayoutDirection;
};
export const FieldAndJsonValue = (props: FieldAndJsonValueProps) =>
  props.value || !props.hideIfMissing ? (
    <FieldAndValueLayout fieldName={props.fieldName} layout={props.layout}>
      <PrettyJson value={props.value} />
    </FieldAndValueLayout>
  ) : null;

type FieldAndValueProps = (
  | { type: 'currency'; currency?: CurrencyCode | Array<CurrencyCode> }
  | { type: 'text' | 'boolean' | 'number' | 'date' | 'datetime' }
) & {
  fieldName: string;
  value: string | number | boolean | undefined | null | Array<number>;
  layout?: LayoutDirection;
  hideIfMissing?: boolean;
};
export const FieldAndValue = (props: FieldAndValueProps) => {
  const { user } = useContext(UserContext);

  let displayValue: React.ReactNode = '';

  if (props.type === 'currency' && typeof props.value === 'number') {
    displayValue = formatCurrency(props.value, user.locale, props.currency ?? user.currency);
  } else if (props.type === 'currency' && Array.isArray(props.value)) {
    displayValue = formatMultipleCurrencyValues(
      props.value,
      user.locale,
      props.currency ?? user.currency,
    );
  } else if (props.type === 'date' && typeof props.value === 'string') {
    displayValue = toShortDate(props.value, user.locale);
  } else if (props.type === 'datetime' && typeof props.value === 'string') {
    displayValue = toShortDateTimeWithSeconds(props.value, user.locale);
  } else if (props.type === 'boolean') {
    displayValue = getTrueOrFalseIcon(props.value === true);
  } else if (props.type === 'number' && typeof props.value === 'number') {
    displayValue = formatNumber(props.value, user.locale);
  } else if (props.type === 'text' && props.value) {
    displayValue = props.value.toString();
  }

  return props.value || !props.hideIfMissing ? (
    <FieldAndValueLayout fieldName={props.fieldName} layout={props.layout}>
      {displayValue}
    </FieldAndValueLayout>
  ) : null;
};
