export const ItemCodeMaxLength = 40;
export const InternalInformationMaxLength = 255;

export const PriceFieldMaxPreDecimalDigits = 7;
export const PriceFieldExclusiveMaxValue = Math.pow(10, PriceFieldMaxPreDecimalDigits);
export const CentralMarkupPercentageMaxPreDecimalDigits = 8;
export const CentralMarkupPercentageMaxValue = Math.pow(
  10,
  CentralMarkupPercentageMaxPreDecimalDigits,
);
export const PercentageDiscountMaxPreDecimalDigits = 8;
export const PercentageDiscountMaxValue = Math.pow(10, PercentageDiscountMaxPreDecimalDigits);
export const DispenseFeeMaxPreDecimalDigits = 8;
export const DispenseFeeExclusiveMaxValue = Math.pow(10, DispenseFeeMaxPreDecimalDigits);
export const NETNETNET = 3;
