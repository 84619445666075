import React, { useContext, useMemo, useState } from 'react';
import { forEach, keyBy, some } from 'lodash';
import { RouteComponentProps } from '@reach/router';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { PageHeading } from '../../layout/PageHeading';
import { TreatmentTypeDeviationsFilterForm } from './TreatmentTypeDeviationsFilterForm';
import { PracticeGroupResponse } from '../../sites/practiceGroups/practiceGroup';
import {
  initialTreatmentTypeDeviationFilterOptions,
  useTreatmentTypeDeviationTableState,
} from './useTreatmentTypeDeviationTableState';
import {
  GroupedTreatmentTypeDeviationResponseRow,
  TreatmentTypeDeviationColumnTypes,
  treatmentTypeDeviationsPageSize,
  TreatmentTypeDeviationsResponse,
  validTreatmentTypeDeviationColumnTypeOrUndefined,
} from './treatmentTypeDeviations';
import { getItemFieldsFromPms } from '../../items/itemsApi';
import { getAllPracticeGroups } from '../../sites/practiceGroups/practiceGroupsApi';
import { DataLoader, DataLoaderWithParams } from '../../../shared/DataLoader';
import { UserContext } from '../../authentication/loginData/userContext';
import { CustomColumn, DataFetchTable } from '../../../shared/tables/Table';
import { TupleKeyDictionary } from '../../../utils/TupleKeyDictionary';
import { TFunction } from 'i18next';
import { Id } from '../../../models/id';
import {
  getGroupedTreatmentTypeDeviationSubRows,
  mapTreatmentTypeDeviationsResponseToGroupedTableData,
  TreatmentTypeDeviationsBaseProps,
} from './TreatmentTypeDeviationsBase';
import { DeviationsScheduledForUpdateInfoBar } from '../DeviationsBase';
import {
  getTreatmentTypeDeviationFilterDropdownOptions,
  getTreatmentTypeDeviationsDownload,
} from '../deviationsApi';
import { styled } from '../../../styling/theme';
import { DownloadExcelFromCsvResponseButton } from '../../../shared/buttons/DownloadExcelFromCsvResponseButton';
import { ButtonGroup } from '../../../shared/buttons/Button';
import { HollowLinkButton } from '../../../shared/buttons/LinkButton';
import { IfUserHasOneOfPermissions } from '../../../shared/Permissions';
import {
  createTreatmentTypeDeviationBulkEditUrl,
  uploadTreatmentTypeDeviationBulkEditUrl,
} from './treatmentTypeDeviationsUrls';
import {
  createEditTreatmentTypeDeviationsPermissions,
  uploadTreatmentTypeDeviationsPermissions,
} from './bulkEdit/treatmentTypeDeviationBulkEdit';
import {
  treatmentTypeDeviationExpandedColumn,
  treatmentTypeDeviationGroupedPracticeGroupOrSiteNameColumn,
  treatmentTypeDeviationTreatmentTypeNameColumn,
  treatmentTypeDispenseFeeDeviationPercentColumn,
  treatmentTypePriceDeviationPercentColumn,
  groupedWarningsColumnForViewPages,
} from './SharedTreatmentTypeDeviationBulkEditColumns';
import { SortResponse } from '../../../shared/tables/sorting/sorting';
import { appendDateTimeToString } from '../../../utils/stringUtils';

type OwnProps = {} & RouteComponentProps;

export const ViewTreatmentTypeDeviations = (props: OwnProps) => {
  const { user } = useContext(UserContext);
  return (
    <DataLoader apiRequest={getTreatmentTypeDeviationFilterDropdownOptions}>
      {filterDropdownOptions => (
        <DataLoader apiRequest={getItemFieldsFromPms}>
          {itemFields => (
            <DataLoaderWithParams
              apiRequest={getAllPracticeGroups}
              getParams={() => user.organisationGroupId}
            >
              {practiceGroupsRequest => (
                <ViewTreatmentTypeDeviationsTable
                  {...props}
                  filterDropdownOptions={filterDropdownOptions.response}
                  itemFieldsFromPms={itemFields.response}
                  practiceGroups={practiceGroupsRequest.response.practiceGroups}
                />
              )}
            </DataLoaderWithParams>
          )}
        </DataLoader>
      )}
    </DataLoader>
  );
};

const CreateBulkEditButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: ${props => props.theme.spacing.small}px;
`;

type TreatmentTypeDeviationsTableProps = TreatmentTypeDeviationsBaseProps & RouteComponentProps;

export const ViewTreatmentTypeDeviationsTable = (props: TreatmentTypeDeviationsTableProps) => {
  const { t } = useTranslation('treatmentTypeDeviations');
  const [
    scheduledBulkEditIdBySiteIdThenTreatmentTypeId,
    setScheduledBulkEditIdBySiteIdThenTreatmentTypeId,
  ] = useState<TupleKeyDictionary<Id, Id, Id> | null>(null);
  const [sortResponse, setSortResponse] = useState<SortResponse<TreatmentTypeDeviationColumnTypes>>(
    {
      sortBy: 'treatmentTypeName',
      direction: 'asc',
    },
  );

  const {
    getFilteredData,
    onFilteredResponse,
    filterOptions,
    setFilterOptions,
  } = useTreatmentTypeDeviationTableState(props);

  const practiceGroupsById = useMemo(
    () => keyBy(props.practiceGroups, practiceGroup => practiceGroup.practiceGroupId),
    [props.practiceGroups],
  );

  const columns: Array<CustomColumn<
    GroupedTreatmentTypeDeviationResponseRow,
    TreatmentTypeDeviationColumnTypes
  >> = useMemo(
    () => getColumns(t, scheduledBulkEditIdBySiteIdThenTreatmentTypeId, practiceGroupsById),
    [t, scheduledBulkEditIdBySiteIdThenTreatmentTypeId, practiceGroupsById],
  );

  const onDataFetchSuccess = (response: TreatmentTypeDeviationsResponse) => {
    onFilteredResponse(response);

    const { deviationResponsesAndScheduledBulkEdits, sortBy, direction } = response;
    setSortResponse({ sortBy, direction });

    const scheduledBulkEditIds = new TupleKeyDictionary<Id, Id, Id>();
    forEach(deviationResponsesAndScheduledBulkEdits.scheduledBulkEdits, schedule =>
      scheduledBulkEditIds.add(
        schedule.siteId,
        schedule.treatmentTypeId,
        schedule.scheduledDeviationBulkEditId,
      ),
    );

    setScheduledBulkEditIdBySiteIdThenTreatmentTypeId(scheduledBulkEditIds);
  };

  return (
    <>
      <Title>{t('view.title')}</Title>
      <PageHeading>
        <h1>{t('view.heading')}</h1>
        <ButtonGroup>
          <DownloadExcelFromCsvResponseButton
            request={getTreatmentTypeDeviationsDownload(
              filterOptions ? filterOptions : initialTreatmentTypeDeviationFilterOptions,
            )}
            requestParams={sortResponse}
            labelText={t('view.downloadButton')}
            fileName={appendDateTimeToString(t('view.downloadFileName'))}
            sheetName={t('view.downloadSheetName')}
          />
          <IfUserHasOneOfPermissions permissions={uploadTreatmentTypeDeviationsPermissions}>
            <HollowLinkButton to={uploadTreatmentTypeDeviationBulkEditUrl()}>
              {t('view.uploadButton')}
            </HollowLinkButton>
          </IfUserHasOneOfPermissions>
        </ButtonGroup>
      </PageHeading>
      {scheduledBulkEditIdBySiteIdThenTreatmentTypeId &&
        some(scheduledBulkEditIdBySiteIdThenTreatmentTypeId.dictionary) && (
          <DeviationsScheduledForUpdateInfoBar message={t('view.scheduledBulkEdit')} />
        )}
      <TreatmentTypeDeviationsFilterForm
        existingFilters={filterOptions}
        onApply={setFilterOptions}
        dropdownOptions={props.filterDropdownOptions}
        itemFieldsFromPms={props.itemFieldsFromPms}
      />
      <IfUserHasOneOfPermissions permissions={createEditTreatmentTypeDeviationsPermissions}>
        <CreateBulkEditButtonContainer>
          <HollowLinkButton to={createTreatmentTypeDeviationBulkEditUrl(window.location.search)}>
            {t('view.createBulkEdit')}
          </HollowLinkButton>
        </CreateBulkEditButtonContainer>
      </IfUserHasOneOfPermissions>
      <DataFetchTable
        columns={columns}
        pageSize={treatmentTypeDeviationsPageSize}
        getApiData={getFilteredData}
        validColumnTypeOrUndefined={validTreatmentTypeDeviationColumnTypeOrUndefined}
        mapResponseToTableData={mapTreatmentTypeDeviationsResponseToGroupedTableData}
        getSubRows={getGroupedTreatmentTypeDeviationSubRows}
        onDataFetchSuccess={onDataFetchSuccess}
        emptyTableMessage={t('view.noDeviations')}
      />
    </>
  );
};

const getColumns = (
  t: TFunction,
  scheduledBulkEditIdBySiteIdThenTreatmentTypeId: TupleKeyDictionary<Id, Id, Id> | null,
  practiceGroupsById: { [practiceGroupId: number]: PracticeGroupResponse },
): Array<CustomColumn<
  GroupedTreatmentTypeDeviationResponseRow,
  TreatmentTypeDeviationColumnTypes
>> => {
  return [
    treatmentTypeDeviationExpandedColumn(),
    {
      ...treatmentTypeDeviationGroupedPracticeGroupOrSiteNameColumn(
        t,
        scheduledBulkEditIdBySiteIdThenTreatmentTypeId,
        practiceGroupsById,
      ),
      disableSortBy: false,
    },
    {
      ...treatmentTypeDeviationTreatmentTypeNameColumn(t),
      disableSortBy: false,
    },
    { ...treatmentTypePriceDeviationPercentColumn(t), disableSortBy: false },
    { ...treatmentTypeDispenseFeeDeviationPercentColumn(t), disableSortBy: false },
    groupedWarningsColumnForViewPages(t),
  ];
};
