import { omit } from 'lodash';
import { secureGet, securePost } from '../../api/apiHttpClient';
import { AuthenticatedApiRequest } from '../../models/apiRequest';
import { PagedRequest } from '../../shared/tables/pagination/pagination';
import { LoginDataResponse } from '../authentication/loginData/loginData';
import { LoginUserResponse } from '../authentication/loginData/user';
import {
  CreateOrEditUserCommandWrapper,
  CreateUserCommand,
  DisableUserCommand,
  EditUserCommand,
  isEditCommand,
  SetUserPriceModeCommand,
  UpdateOrganisationIdCommand,
  UserFilterOptions,
  UserResponse,
  UsersResponse,
} from './user';

export const getUser: AuthenticatedApiRequest<UserResponse, number> = userId =>
  secureGet(`users/${userId}`);

export const getUsers = (
  filterOptions?: UserFilterOptions,
): AuthenticatedApiRequest<UsersResponse, PagedRequest> => pageRequest =>
  securePost('users', { ...pageRequest, filterOptions });

export const createUser: AuthenticatedApiRequest<UserResponse, CreateUserCommand> = userCommand =>
  securePost('users/create', userCommand);

export const editUser: AuthenticatedApiRequest<UserResponse, EditUserCommand> = userCommand =>
  securePost('users/edit', userCommand);

export const setUserPriceMode: AuthenticatedApiRequest<
  LoginUserResponse,
  SetUserPriceModeCommand
> = setUserPriceModeCommand => securePost('users/price-mode', setUserPriceModeCommand);

export const disableUser: AuthenticatedApiRequest<UserResponse, DisableUserCommand> = userCommand =>
  securePost('users/disable', userCommand);

export const UpdateOrganisation: AuthenticatedApiRequest<
  LoginDataResponse,
  UpdateOrganisationIdCommand
> = updateOrgCommand => securePost('users/updateOrganisation', updateOrgCommand);

export const createOrEditUser: AuthenticatedApiRequest<
  UserResponse,
  CreateOrEditUserCommandWrapper
> = userCommand => {
  const createOrEditCommand = userCommand.command;

  if (isEditCommand(createOrEditCommand, userCommand.createOrEditTag)) {
    return editUser(createOrEditCommand);
  } else {
    const createCommand = omit(createOrEditCommand, ['userId']) as CreateUserCommand;
    return createUser(createCommand);
  }
};
