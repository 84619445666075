import { Link } from '@reach/router';
import React, { useContext } from 'react';
import { styled } from '../../../styling/theme';
import { dashboardUrl } from '../../../urls';
import { UserContext } from '../../authentication/loginData/userContext';

const LogoContainer = styled(Link)`
  margin-right: ${props => props.theme.spacing.tiny}px;
  align-self: stretch;
`;
const Logo = styled.img<React.ImgHTMLAttributes<HTMLImageElement>>`
  max-width: initial;
  max-height: 100%;
`;

export const NavLogo = () => {
  const { user } = useContext(UserContext);
  return (
    <LogoContainer to={dashboardUrl()}>
      <Logo
        src={`${process.env.PUBLIC_URL}/media/${user.organisationGroupId}/logo.jpg`}
        alt={`${user.organisationGroupName} logo`}
      />
    </LogoContainer>
  );
};
