import { FieldArray, FieldArrayRenderProps } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { DragDropContext, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { ById } from '../../models/id';
import { PrimaryButton } from '../../shared/buttons/Button';
import Select, { ValueType } from 'react-select';
import { styled } from '../../styling/theme';
import { LabelDispensingNote } from './item';
import { Note } from './Note';
import { ItemLibrary } from './PmsFields/pmsFields';

type DispensingLabelEditProps = {
  availableNotes: ById<ItemLibrary>;
  itemsNotes: Array<LabelDispensingNote>;
};

type MyOptionType = { label: string; value: number };

const DispensingLabelEditContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing.small}px;
`;

const DragAndDropContainer = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  background-color: ${props => props.theme.colours.componentBackground};
  border: 1px solid ${props => props.theme.colours.secondaryInactive};
  border-radius: ${props => props.theme.spacing.tiny}px;
  line-height: 1.8;
  margin-top: ${props => props.theme.spacing.extraSmall}px;
`;

const Title = styled.div`
  padding: ${props => props.theme.spacing.tiny}px;
  color: ${props => props.theme.colours.secondary};
`;

const NotesList = styled.div<
  React.HTMLAttributes<HTMLDivElement> & { ref: (element: HTMLElement | null) => void }
>`
  padding: ${props => props.theme.spacing.tiny}px;
`;

export const addSelectionButtonTestId = 'addSelectionButton';
export const dragAndDropContainerTestId = 'dragAndDropContainer';
export const notesListTestId = 'notesList';

export const DispensingLabelEdit = (props: DispensingLabelEditProps) => {
  const [dispensingNotesForItemList, setDispensingNotesForItemList] = useState(props.itemsNotes);
  const [availableNotes] = useState(props.availableNotes);
  const selectedNotes = React.useRef(new Array<LabelDispensingNote>()); // keep state without rerender on change
  const { t } = useTranslation('item');

  const updateDispensingNotes = (
    arrayHelpers: FieldArrayRenderProps,
    notes: Array<LabelDispensingNote>,
  ) => {
    notes.forEach((note, index) => {
      note.viewOrder = index + 1;
    });
    setDispensingNotesForItemList(notes);
    arrayHelpers.form.values.labelDispensingNote = notes;
  };

  const onDragEnd = (result: DropResult, arrayHelpers: FieldArrayRenderProps) => {
    const { destination, source } = result;
    const newNotesItemList = Array.from(dispensingNotesForItemList);
    // not dropped in the notes column
    // Delete the note from the item
    if (!destination) {
      if (source.index > -1) {
        newNotesItemList.splice(source.index, 1);
        updateDispensingNotes(arrayHelpers, newNotesItemList);
      }
      return;
    }
    // dropped in the same place
    // Don't do anything
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    // Reorder the array position
    const element = dispensingNotesForItemList[source.index];
    newNotesItemList.splice(source.index, 1);
    newNotesItemList.splice(destination.index, 0, element);
    updateDispensingNotes(arrayHelpers, newNotesItemList);
  };

  const HandleSelectedNotes = (selectecNotesFromDropDown: ValueType<MyOptionType, true>) => {
    selectedNotes.current = selectecNotesFromDropDown.map((note, index) => {
      const selectedNote: LabelDispensingNote = {
        description: note.label,
        labelDispensingNoteOptionsId: note.value,
        viewOrder: index + 1,
        optionDescription: note.label,
      };
      return selectedNote;
    });
  };

  const AddSelectedNotesToDispensingNotesForItemList = (arrayHelpers: FieldArrayRenderProps) => {
    const dispensingNotesNotAlreadyAppliedToItem = selectedNotes.current.filter(
      value =>
        !dispensingNotesForItemList.some(
          x => x.labelDispensingNoteOptionsId === value.labelDispensingNoteOptionsId,
        ),
    );
    const newNotesItemList = [
      ...dispensingNotesForItemList,
      ...dispensingNotesNotAlreadyAppliedToItem,
    ];
    updateDispensingNotes(arrayHelpers, newNotesItemList);
  };

  const dispenseNoteOptions = (): Array<MyOptionType> => {
    const optionArray = Object.keys(availableNotes).map((key: string) => availableNotes[+key]);

    const dispenseOptions: Array<MyOptionType> = optionArray.map(x => ({
      value: x.id,
      label: x.name,
    }));
    return dispenseOptions;
  };

  return (
    <FieldArray
      name="labelDispensingNote"
      render={arrayHelpers => (
        <DispensingLabelEditContainer>
          <Select
            aria-label={t('itemLabels.dispensingNotes')}
            isSearchable={true}
            isMulti={true}
            options={dispenseNoteOptions()}
            onChange={HandleSelectedNotes}
          />
          <div>
            <PrimaryButton
              onClick={() => AddSelectedNotesToDispensingNotesForItemList(arrayHelpers)}
              type="button"
              data-testid={addSelectionButtonTestId}
            >
              {t('itemLabels.addSelection')}
            </PrimaryButton>
          </div>
          <DragDropContext onDragEnd={(result: DropResult) => onDragEnd(result, arrayHelpers)}>
            <DragAndDropContainer data-testid={dragAndDropContainerTestId}>
              <Title>{t('itemLabels.dispensingNoteLibrary')}</Title>
              <Droppable droppableId="NotesForDispenseLabels" key="NotesFields">
                {(provided: DroppableProvided) => (
                  <NotesList
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    key="notesList"
                    data-testid={notesListTestId}
                  >
                    {dispensingNotesForItemList?.map((note, indexNumber) => (
                      <Note
                        noteDetails={note}
                        index={indexNumber}
                        key={note?.labelDispensingNoteOptionsId ?? 0}
                      />
                    ))}
                    {provided.placeholder}
                  </NotesList>
                )}
              </Droppable>
            </DragAndDropContainer>
          </DragDropContext>
        </DispensingLabelEditContainer>
      )}
    />
  );
};
