import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond/dist/filepond.min.css';
import { ErrorMessage, Field, FieldProps, getIn } from 'formik';
import { TFunction } from 'i18next';
import React from 'react';
import { FilePond, FilePondProps, registerPlugin } from 'react-filepond';
import { Translation } from 'react-i18next';
import { styled } from '../../styling/theme';
import { getFieldErrorMessageTestId, SmallErrorMessage } from '../forms/FormField';

registerPlugin(FilePondPluginFileValidateSize);

const Container = styled.div`
  margin-bottom: ${props => props.theme.spacing.tiny}px;
`;

const FieldLabel = styled.div`
  color: ${props => props.theme.colours.secondary};
  margin-bottom: ${props => props.theme.spacing.extraSmall}px;
`;

// Styling of the FilePond component cannot be done directly to the FilePond component as although it accepts a
// className prop it does not refresh the class name if the prop changes, so the styling has to be done through
// FilePondContainer
const StyledFilePond = styled(FilePond)``;

type FilePondContainerProps = React.HTMLAttributes<HTMLDivElement> & { showingError: boolean };
const FilePondContainer = styled.div<FilePondContainerProps>`
  width: ${props => props.theme.formInputWidth.medium}px;
  border: 1px solid
    ${props =>
      props.showingError ? props.theme.colours.alertText : props.theme.colours.secondaryHighlight};
  margin-bottom: ${props =>
    props.theme.spacing.extraSmall +
    (props.showingError
      ? 0
      : props.theme.spacing.extraSmall +
        props.theme.typography.subtext
          .fontSize)}px; // Leave space for the error message and its margin

  ${StyledFilePond} {
    background-color: ${props =>
      props.showingError
        ? props.theme.colours.alertBackground
        : props.theme.colours.componentBackground};
    cursor: pointer;

    &.filepond--root {
      font-family: ${props => props.theme.typography.content.fontFamily};
      font-weight: ${props => props.theme.typography.content.fontWeight};
      font-size: ${props => props.theme.typography.content.fontSize}px;
      line-height: ${props => props.theme.typography.content.lineHeight};
      color: ${props =>
        props.showingError ? props.theme.colours.alertText : props.theme.colours.secondary};

      margin-bottom: 0;
    }

    .filepond--panel-root {
      background-color: ${props =>
        props.showingError
          ? props.theme.colours.alertBackground
          : props.theme.colours.componentBackground};
    }

    .filepond--drop-label {
      color: ${props =>
        props.showingError ? props.theme.colours.alertText : props.theme.colours.secondary};
    }

    .filepond--drop-label > * {
      cursor: pointer !important;
    }
  }

  :focus-within {
    color: ${props => props.theme.colours.secondary};
    border-color: ${props => props.theme.colours.primary};
    background-color: ${props => props.theme.colours.componentBackground};
  }
`;

export const fileInvalidStatus = 8;

export const validateFile = (filePond: FilePond, t: TFunction, fieldName: string) => {
  const hasFile = filePond.getFiles().length !== 0;

  if (!hasFile) {
    return {
      [fieldName]: t('validation.empty'),
    };
  }

  const file = filePond.getFiles()[0];
  if (file.status === fileInvalidStatus) {
    return {
      [fieldName]: t('validation.invalidFile'),
    };
  }

  return null;
};

type FilePickerProps = FilePondProps & {
  fieldLabel: string;
  fieldName: string;
};

export const FilePicker = React.forwardRef<FilePond, FilePickerProps>(
  (props: FilePickerProps, ref) => {
    return (
      <Translation ns="fileUpload">
        {t => (
          <Container>
            <FieldLabel>{props.fieldLabel}</FieldLabel>
            <Field>
              {(fieldProps: FieldProps) => {
                const touched = getIn(fieldProps.form.touched, props.fieldName);
                const error = getIn(fieldProps.form.errors, props.fieldName);
                const invalid = touched && !!error;

                return (
                  <FilePondContainer showingError={invalid}>
                    <StyledFilePond
                      maxFileSize="50MB"
                      labelIdle={t('labels.idle')}
                      labelMaxFileSizeExceeded={t('labels.fileSizeExceeded')}
                      labelMaxFileSize={t('labels.maxFileSize')}
                      onaddfile={() => {
                        fieldProps.form.setFieldTouched(props.fieldName);
                      }}
                      onremovefile={() => {
                        fieldProps.form.setFieldTouched(props.fieldName);
                      }}
                      name={props.fieldName}
                      ref={ref}
                      {...props}
                    />
                  </FilePondContainer>
                );
              }}
            </Field>
            <ErrorMessage name={props.fieldName}>
              {error => (
                <SmallErrorMessage data-testid={getFieldErrorMessageTestId(props.fieldName)}>
                  {error}
                </SmallErrorMessage>
              )}
            </ErrorMessage>
          </Container>
        )}
      </Translation>
    );
  },
);
