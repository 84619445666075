import { UserVisibleMessage } from './userVisibleMessage';

export type ApiError = Error & {
  userVisibleMessages: Array<UserVisibleMessage>;
};

export type ApiErrorJson = {
  message: string;
  userVisibleMessages: Array<UserVisibleMessage>;
};

export const toApiError = (errorJson: ApiErrorJson): ApiError => {
  return {
    name: 'Api Error',
    message: errorJson.message,
    userVisibleMessages: errorJson.userVisibleMessages,
  };
};

export const defaultApiError = (): ApiError => {
  return {
    name: 'Api Error',
    message: 'Unexpected error occurred',
    userVisibleMessages: [
      {
        key: 'error',
        message: 'Unexpected error occurred',
      },
    ],
  };
};

export const apiTimeoutError = (): ApiError => {
  return {
    name: 'Api Error',
    message: 'The request timed out',
    userVisibleMessages: [
      {
        key: 'timeoutError',
        message: 'The request timed out',
      },
    ],
  };
};
