type TupleKeyDictionaryType<TValue> = {
  [key1: string]: {
    [key2: string]: TValue;
  };
};

export class TupleKeyDictionary<
  TKey1 extends string | number,
  TKey2 extends string | number,
  TValue
> {
  dictionary: TupleKeyDictionaryType<TValue> = {};

  get = (key1: TKey1, key2: TKey2): TValue | null => {
    return (
      (this.dictionary &&
        this.dictionary[key1.toString()] &&
        this.dictionary[key1.toString()][key2.toString()]) ||
      null
    );
  };

  add = (key1: TKey1, key2: TKey2, value: TValue) => {
    if (this.dictionary[key1.toString()] === undefined) {
      this.dictionary[key1.toString()] = {};
    }

    this.dictionary[key1.toString()][key2.toString()] = value;
  };
}
