import { FieldArray, FieldArrayRenderProps } from 'formik';
import * as React from 'react';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../shared/buttons/Button';
import { InputField } from '../../shared/forms/InputField';
import { styled } from '../../styling/theme';
import { BarcodeDetails } from './item';

type BarcodesEditProps = {
  barcodes?: Array<BarcodeDetails>;
};

const BarcodesEditContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing.small}px;
`;

const BarcodeTable = styled.div`
  display: grid;
  width: 900px;
  grid-template-columns: auto auto auto;
`;

const RemoveButtonCell = styled(PrimaryButton)`
  margin: ${props => props.theme.spacing.tiny}px;
  margin-bottom: ${props => props.theme.spacing.extraSmall}px;
`;

export const barcodeFieldName = (index: number) => `barcode.${index}.barcode`;
export const manufacturerFieldName = (index: number) => `barcode.${index}.manufacturer`;
export const removeButtonTestId = (index: number) => `button:barcode.${index}.remove`;

export const addBarcodeButtonTestId = 'addBarcodeButton';

export const BarcodesEdit = (props: BarcodesEditProps) => {
  const { t } = useTranslation('item');

  const barcodeEntries = (
    arrayHelpers: FieldArrayRenderProps,
    barcodes?: Array<BarcodeDetails>,
  ) => {
    if (barcodes === null) {
      return;
    }
    return barcodes?.reduce(
      (inputFields: Array<ReactElement>, barcodeDetails, index, array) =>
        inputFields
          .concat([<InputField name={barcodeFieldName(index)} key={`barcode-${index}`} />])
          .concat([
            <InputField name={manufacturerFieldName(index)} key={`manufacturer-${index}`} />,
          ])
          .concat([
            <RemoveButtonCell
              onClick={() => arrayHelpers.remove(index)}
              type="button"
              size="tiny"
              key={`remove-${index}`}
              data-testid={removeButtonTestId(index)}
            >
              {t('itemLabels.removeBarcode')}
            </RemoveButtonCell>,
          ]),
      [],
    );
  };

  return (
    <FieldArray
      name="barcode"
      render={arrayHelpers => (
        <BarcodesEditContainer>
          <h4>{t('itemLabels.barcodes')}</h4>
          {(props.barcodes?.length ?? 0) !== 0 && (
            <BarcodeTable>
              <h5>{t('itemLabels.barcode')}</h5>
              <h5>{t('itemLabels.manufacturer')}</h5>
              <div />
              {barcodeEntries(arrayHelpers, props.barcodes)}
            </BarcodeTable>
          )}
          <PrimaryButton
            onClick={() => arrayHelpers.push({ barcode: '', manufacturer: '' })}
            type="button"
            data-testid={addBarcodeButtonTestId}
          >
            {t('itemLabels.addBarcode')}
          </PrimaryButton>
        </BarcodesEditContainer>
      )}
    />
  );
};
