import React, { useEffect, useRef } from 'react';
import { styled } from '../styling/theme';
import { usePrevious } from './hooks/usePrevious';

export const expandableTestId = 'expandable-test-id';

const ExpandableDiv = styled.div<
  React.HTMLAttributes<HTMLDivElement> & React.RefAttributes<HTMLDivElement>
>`
  transition: height 0.35s;
`;

type ExpandableProps = {
  children: React.ReactNode;
  isExpanded: boolean;
};

export const Expandable = (props: ExpandableProps) => {
  const { isExpanded, children } = props;
  const previousExpanded = usePrevious(isExpanded);
  const expandableRef = useRef<HTMLDivElement>(null);

  const animationFrameId = useRef<number | null>(null);

  useEffect(() => {
    if (expandableRef.current) {
      const element = expandableRef.current;
      const elementStyle = element.style;

      if (previousExpanded == null) {
        if (!isExpanded) {
          elementStyle.height = '0';
          elementStyle.overflowY = 'hidden';
        }
      } else {
        const contentHeight = expandableRef.current.scrollHeight;

        if (isExpanded) {
          elementStyle.height = `${contentHeight}px`;

          const removeExplicitHeight = () => {
            element.removeEventListener('transitionend', removeExplicitHeight);

            elementStyle.height = '';
            // We can't have overflow hidden when it's expanded, because it
            // causes a bug when dropdowns overhang the expandable element.
            elementStyle.overflowY = '';
          };

          element.addEventListener('transitionend', removeExplicitHeight);
          return removeExplicitHeight;
        } else {
          // Temporarily disable transitions
          const elementTransition = elementStyle.transition;
          elementStyle.transition = '';

          animationFrameId.current = requestAnimationFrame(() => {
            // Explicitly set the height so that it's no longer 'auto', and re-enable transitions
            elementStyle.height = `${contentHeight}px`;
            elementStyle.overflowY = 'hidden';
            elementStyle.transition = elementTransition;

            animationFrameId.current = requestAnimationFrame(() => {
              // Set the height to 0, triggering the transition
              elementStyle.height = '0';
            });
          });

          return () => animationFrameId.current && cancelAnimationFrame(animationFrameId.current);
        }
      }
    }
  }, [isExpanded]);

  return (
    <ExpandableDiv ref={expandableRef} aria-hidden={!isExpanded} data-testid={expandableTestId}>
      {children}
    </ExpandableDiv>
  );
};
