import { createHistory, History, HistorySource, WindowLocation } from '@reach/router';
import { remove, uniqueId } from 'lodash';

// IDs to identify each location in the history stack, which are stored in the history state during
// each navigation. Used during the navigation process to cancel navigation if required, to identify
// the locations of the current and previous locations in the history stack.
export type LocationId = string;

// Name prefixed with state to avoid potential collisions with other properties set in the state
type LocationIdState = { stateLocationId: LocationId };

const createLocationId = (): LocationId => uniqueId();

// tslint:disable-next-line:no-any history.state has type any
const withLocationId = (state: any, locationId: LocationId): LocationIdState => ({
  ...state,
  stateLocationId: locationId,
});

const getLocationId = (state: unknown): LocationId =>
  (state as LocationIdState)?.stateLocationId ?? 'initial';

type SuppressNavigationWarningState = { suppressNavigationWarning: boolean };

export const withSuppressNavigationWarningState = (
  state: any, // tslint:disable-line:no-any history.state has type any
  suppressNavigationWarning: boolean = true,
): SuppressNavigationWarningState => ({
  ...state,
  suppressNavigationWarning,
});

const getSuppressNavigationWarning = (state: unknown): boolean =>
  (state as SuppressNavigationWarningState)?.suppressNavigationWarning ?? false;

export type HistoryWithNavigationConfirmation = {
  history: History;
  addNavigationConfirmation: (message: string) => () => void;
  internalsForTestingDoNotUseInProduction: () => {
    currentLocationId: LocationId;
    locationIds: Array<LocationId>;
    getLocationId: (state: unknown) => LocationId;
    historySource: HistorySource;
  };
};

export const createHistoryWithNavigationConfirmation = (
  confirm: (message: string) => boolean = message => window.confirm(message),
): HistoryWithNavigationConfirmation => {
  let shouldConfirmNavigation = false;
  const navigationConfirmationMessages: Array<string> = [];

  const addNavigationConfirmation = (message: string): (() => void) => {
    navigationConfirmationMessages.push(message);
    shouldConfirmNavigation = true;
    window.onbeforeunload = () => true;

    return () => {
      const messageIndex = navigationConfirmationMessages.indexOf(message);
      if (messageIndex !== -1) {
        navigationConfirmationMessages.splice(messageIndex, 1);
        if (navigationConfirmationMessages.length === 0) {
          shouldConfirmNavigation = false;
          window.onbeforeunload = null;
        }
      }
    };
  };

  const shouldAllowTransition = (): boolean =>
    !shouldConfirmNavigation || confirm(navigationConfirmationMessages[0]);

  const initialLocationId = getLocationId(window.history.state);
  let currentLocationId = initialLocationId;
  let locationIds: Array<LocationId> = [initialLocationId];

  const pushState = (state: unknown, title: string, uri: string) => {
    if (getSuppressNavigationWarning(state) || shouldAllowTransition()) {
      const previousLocationId = currentLocationId;
      const newLocationId = createLocationId();
      const previousIndex = locationIds.indexOf(previousLocationId);
      const locationIdsBeforePrevious = locationIds.slice(
        0,
        previousIndex === -1 ? 0 : previousIndex + 1,
      );

      locationIds = [...locationIdsBeforePrevious, newLocationId];
      currentLocationId = newLocationId;
      window.history.pushState(withLocationId(state, currentLocationId), title, uri);
    }
  };

  const replaceState = (state: unknown, title: string, uri: string) => {
    if (getSuppressNavigationWarning(state) || shouldAllowTransition()) {
      window.history.replaceState(withLocationId(state, currentLocationId), title, uri);
    }
  };

  type PopStateListener = (event: Event) => void;
  const popStateListeners: Array<PopStateListener> = [];

  let skipConfirmationOnNextPop = false;

  const navigationConfirmationPopStateListener = (event: Event): void => {
    if (skipConfirmationOnNextPop || shouldAllowTransition()) {
      skipConfirmationOnNextPop = false;
      currentLocationId = getLocationId((event as PopStateEvent).state);
      popStateListeners.forEach(listener => listener(event));
    } else {
      revertPop();
    }
  };

  // Reverts the URL back to the original, during a popstate event. Note that the URL has already
  // updated by this point, so we need to explicitly set the URL back to the original page.
  // We cannot just directly set the URL to the intended URL (via replaceState / pushState), as this
  // will lose the history state object, and will place us in the wrong place in the history stack
  // - we want the end result of this to be as if navigation had never occurred.
  const revertPop = () => {
    const newLocationId = getLocationId(window.history.state);

    let newLocationIdIndex = locationIds.indexOf(newLocationId);
    if (newLocationIdIndex === -1) {
      newLocationIdIndex = 0;
    }

    let previousLocationIdIndex = locationIds.indexOf(currentLocationId);
    if (previousLocationIdIndex === -1) {
      previousLocationIdIndex = 0;
    }

    const delta = previousLocationIdIndex - newLocationIdIndex;

    if (delta) {
      skipConfirmationOnNextPop = true;
      window.history.go(delta);
    }
  };

  const addPopStateListener = (listener: PopStateListener) => {
    popStateListeners.push(listener);
  };

  const removePopStateListener = (listener: PopStateListener) => {
    remove(popStateListeners, l => l === listener);
  };

  window.addEventListener('popstate', navigationConfirmationPopStateListener);

  const reachRouterHistorySource: HistorySource = {
    addEventListener: (name: string, listener: (event: Event) => void) => {
      if (name === 'popstate') {
        addPopStateListener(listener);
      } else {
        window.addEventListener(name, listener);
      }
    },
    removeEventListener: (name: string, listener: (event: Event) => void) => {
      if (name === 'popstate') {
        removePopStateListener(listener);
      } else {
        window.addEventListener(name, listener);
      }
    },
    location: window.location as WindowLocation,
    history: {
      state: window.history.state,
      pushState,
      replaceState,
    },
  };

  const routerHistory = createHistory(reachRouterHistorySource);

  return {
    history: routerHistory,
    addNavigationConfirmation,
    internalsForTestingDoNotUseInProduction: () => ({
      currentLocationId,
      locationIds,
      getLocationId,
      historySource: reachRouterHistorySource,
    }),
  };
};
