import { Form, FormikProps } from 'formik';
import { flowRight, isEmpty, map } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, PrimaryButton } from '../../shared/buttons/Button';
import { HollowLinkButton } from '../../shared/buttons/LinkButton';
import { withFormSubmit } from '../../shared/higher-order-components/withFormSubmit';
import { SuccessBox } from '../../shared/success/SuccessBox';
import { usersUrl } from '../../urls';
import { navigate } from '../../utils/routing';
import { LoginUserResponse } from '../authentication/loginData/user';
import { permissions } from '../authentication/permissions';
import {
  CreateEditUserFormModel,
  CreateEditUserFormModelValidator,
  CreateOrEditUserCommandWrapper,
  UserResponse,
} from './user';
import { createOrEditUser } from './usersApi';
import { listUserUrl } from './userUrls';
import { FormFieldGroupsWrapper } from '../../shared/forms/FormContainers';
import { UserDetails } from './components/UserDetails';
import { UserRoles } from './components/UserRoles';
import { UserGroups } from './components/UserGroups';

type OwnProps = {
  existingUser: UserResponse | null;
  currentUser: LoginUserResponse;
  showSuccess?: boolean;
  onSuccess?: (response: UserResponse) => void;
};

type Props = OwnProps & FormikProps<CreateEditUserFormModel>;

const UserFormComponent = (props: Props) => {
  const { t } = useTranslation(['user']);

  return (
    <Form>
      <FormFieldGroupsWrapper minWidthInPx={500}>
        <UserDetails currentUser={props.currentUser} />
        <UserRoles currentUser={props.currentUser} />
        <UserGroups {...props} />
      </FormFieldGroupsWrapper>
      <ButtonGroup>
        <PrimaryButton type="submit" loading={props.isSubmitting}>
          {t('createOrEditForm.labels.saveButton')}
        </PrimaryButton>
        <HollowLinkButton to={usersUrl()}>
          {t('createOrEditForm.labels.cancelButton')}
        </HollowLinkButton>
      </ButtonGroup>
      <SuccessBox
        message={t('createOrEditForm.success')}
        showSuccess={
          !!props.showSuccess && !props.isSubmitting && !props.error && isEmpty(props.touched)
        }
      />
    </Form>
  );
};

const enhance = flowRight(
  withFormSubmit<OwnProps, CreateEditUserFormModel, UserResponse, CreateOrEditUserCommandWrapper>({
    enableReinitialize: true,
    request: createOrEditUser,
    onSubmitComplete: (props, response) => {
      if (props.onSuccess) {
        props.onSuccess(response);
      }
      navigate(listUserUrl(), {
        state: { success: true },
      });
    },
    mapPropsToValues: props => ({
      email: props.existingUser != null ? props.existingUser.email : '',
      useGrossPrices: props.existingUser != null && props.existingUser.useGrossPrices,
      userIsCentralUserAdmin:
        props.existingUser != null &&
        props.existingUser.userPermissionCodes.includes(permissions.CentralUserAdmin),
      userIsCentralAdmin:
        props.existingUser != null &&
        props.existingUser.userPermissionCodes.includes(permissions.CentralAdmin),
      userIsCentralView:
        props.existingUser != null &&
        props.existingUser.userPermissionCodes.includes(permissions.CentralView),
      userIsSiteUserAdmin:
        props.existingUser != null &&
        props.existingUser.userPermissionCodes.includes(permissions.SiteUserAdmin),
      userIsSitePriceAdmin:
        props.existingUser != null &&
        props.existingUser.userPermissionCodes.includes(permissions.SitePriceAdmin),
      userIsSiteVisibilityAdmin:
        props.existingUser != null &&
        props.existingUser.userPermissionCodes.includes(permissions.SiteVisibilityAdmin),
      userIsDeviationBulkUploadAdmin:
        props.existingUser != null &&
        props.existingUser.userPermissionCodes.includes(permissions.DeviationBulkUploadAdmin),
      userIsSuperAdmin:
        props.existingUser != null &&
        props.existingUser.userPermissionCodes.includes(permissions.SuperAdmin),
      userOrganisationGroupIds:
        props.existingUser != null ? props.existingUser.userOrganisationGroupIds : [],
      userSiteIds: props.existingUser != null ? props.existingUser.userSiteIds : [],
      userPracticeGroupIds:
        props.existingUser != null ? props.existingUser.userPracticeGroupIds : [],
    }),
    mapValuesToRequestParameters: (props: OwnProps, formModel: CreateEditUserFormModel) => {
      const formPermissions = [];

      if (formModel.userIsCentralUserAdmin) {
        formPermissions.push(permissions.CentralUserAdmin);
      }
      if (formModel.userIsCentralAdmin) {
        formPermissions.push(permissions.CentralAdmin);
      }
      if (formModel.userIsCentralView) {
        formPermissions.push(permissions.CentralView);
      }
      if (formModel.userIsSiteUserAdmin) {
        formPermissions.push(permissions.SiteUserAdmin);
      }
      if (formModel.userIsSitePriceAdmin) {
        formPermissions.push(permissions.SitePriceAdmin);
      }
      if (formModel.userIsSiteVisibilityAdmin) {
        formPermissions.push(permissions.SiteVisibilityAdmin);
      }
      if (formModel.userIsDeviationBulkUploadAdmin) {
        formPermissions.push(permissions.DeviationBulkUploadAdmin);
      }
      if (formModel.userIsSuperAdmin) {
        formPermissions.push(permissions.SuperAdmin);
      }

      return {
        command: {
          ...{
            userId: props.existingUser ? props.existingUser.id : undefined,
          },
          email: formModel.email,
          useGrossPrices: formModel.useGrossPrices,
          userPermissionCodes: formPermissions,
          userOrganisationGroupIds: formModel.userOrganisationGroupIds,
          userSiteIds: formModel.userSiteIds,
          userPracticeGroupIds: formModel.userPracticeGroupIds,
          currentOrganisationGroupId: formModel.userOrganisationGroupIds[0],
        },
        createOrEditTag: props.existingUser ? 'edit' : 'create',
      };
    },
    validationConfig: {
      translationNamespace: 'user',
      validator: CreateEditUserFormModelValidator,
    },
  }),
);

export const UserForm: React.FunctionComponent<OwnProps> = enhance(UserFormComponent);
