import React, { useEffect, useRef, useState } from 'react';
import {
  FormFieldGroupContainer,
  FormGroupTitle,
  FormFieldGroup,
} from '../../../shared/forms/FormContainers';
import { useTranslation } from 'react-i18next';
import { LoginUserResponse } from '../../authentication/loginData/user';
import { MultiSelectDropdownField } from '../../../shared/forms/MultiSelectDropdownField';
import { useApiRequest } from '../../../shared/hooks/useApiRequest';
import { DropdownOption } from '../../../shared/forms/DropdownField';
import { getAccessibleOrganisationGroups } from '../../groups/organisationGroupsApi';
import { PracticeGroupResponse } from '../../sites/practiceGroups/practiceGroup';
import { getAccessiblePracticeGroups } from '../../sites/practiceGroups/practiceGroupsApi';
import { AccessibleSiteResponse } from '../../sites/site';
import { GetSitesByPracticeGroup, getAccessibleSites } from '../../../api/sitesApi';
import { CreateEditUserFormModel, UserResponse } from '../user';
import { FormikProps } from 'formik';
import { NavIcon } from '../../layout/header/NavIcon';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
import { SiteSpinnerWrapper, SiteSpinner } from '../styles/UserStyles';

type OwnProps = {
  existingUser: UserResponse | null;
  currentUser: LoginUserResponse;
  showSuccess?: boolean;
  onSuccess?: (response: UserResponse) => void;
};

type Props = OwnProps & FormikProps<CreateEditUserFormModel>;

export const UserGroups = (props: Props) => {
  const { currentUser } = props;
  const { t } = useTranslation(['user']);

  const sitesByPracticeGroup = useApiRequest(GetSitesByPracticeGroup);
  const accessibleSitesRequest = useApiRequest(getAccessibleSites);
  const accessiblePracticeGroupsRequest = useApiRequest(getAccessiblePracticeGroups);
  const accessibleOrganisationGroupsRequest = useApiRequest(getAccessibleOrganisationGroups);

  const [sites, setSites] = useState<Array<DropdownOption<number>>>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [practiceGroups, setPracticeGroups] = useState<Array<DropdownOption<number>>>([]);
  const [selectedOptions, setSelectedOptions] = useState<Array<number>>([]);
  const [organisationGroups, setOrganisationGroups] = useState<Array<DropdownOption<number>>>([]);

  const accessibleSiteResponse = useRef<Array<AccessibleSiteResponse>>([]);
  const accessiblePracticeGroupResponse = useRef<Array<PracticeGroupResponse>>([]);

  /**
   * Fetch the Organisations that you are allowed to see, populate the
   * organisations drop down on completion
   */
  const fetchAccessibleOrganisations = async () => {
    try {
      const response = await accessibleOrganisationGroupsRequest.makeRequest({
        userId: props.existingUser?.id ?? 0,
      });
      const organisationOptions = response
        ? response.organisationGroups.map(m => ({
            displayText: m.organisationName,
            value: m.organisationId,
          }))
        : [];
      setOrganisationGroups(organisationOptions);
    } catch (error) {}
  };

  /**
   * Fetch all of the Sites that you are allowed to see, on
   * completion call the filter function to display the correct details
   */
  const fetchSites = async () => {
    try {
      const response = await accessibleSitesRequest.makeRequest({
        organisationGroupIds: props.values.userOrganisationGroupIds,
        userId: props.existingUser?.id ?? 0,
      });

      accessibleSiteResponse.current = response?.sites ?? new Array<AccessibleSiteResponse>();
      filterSites();
    } catch (error) {}
  };

  /**
   * Fetch all of the PracticeGroups that you are allowed to see, on
   * completion call the filter function to display the correct details
   */
  const fetchPracticeGroups = async () => {
    try {
      const response = await accessiblePracticeGroupsRequest.makeRequest({
        organisationGroupIds: props.values.userOrganisationGroupIds,
        userId: props.existingUser?.id,
      });

      accessiblePracticeGroupResponse.current =
        response?.practiceGroups ?? new Array<PracticeGroupResponse>();
      filterPracticeGroups();
    } catch (error) {}
  };

  /**
   * Calculate the Sites that should be displayed for the user / form
   */
  const filterSites = () => {
    // derive the new site and practice groups
    const selectedOrganisations = props.values.userOrganisationGroupIds;

    // Get a collection of sites based on the selected Organisation (filter sites by organisations ids)
    const newSiteSelection = accessibleSiteResponse.current.filter(m =>
      selectedOrganisations.includes(m.organisationGroupId),
    );

    // Sites is an Array of IDs, get a collection of Objects based on the id's you have selected
    const mySelection = accessibleSiteResponse.current.filter(m =>
      props.values.userSiteIds.includes(m.siteId),
    );

    // Using the Site Object collection, filter those sites based on the currently selected organisations
    const trimmed = mySelection.filter(m => selectedOrganisations.includes(m.organisationGroupId));

    // Set the props for the form to display the correct sites
    props.values.userSiteIds = trimmed.map(m => m.siteId);

    // Map the objects for display in the drop down list
    const options = newSiteSelection.map(m => ({
      displayText: m.siteName,
      value: m.siteId,
    }));

    // Populate the drop down with the relevant sites
    setSites(options);
  };

  /**
   * Calculate the PracticeGroups that should be displayed for the user / form
   */
  const filterPracticeGroups = () => {
    // derive the new site and practice groups
    const selectedOrganisations = props.values.userOrganisationGroupIds;

    // Get a collection of PracticeGroups based on the selected Organisation (filter pg's by organisations ids)
    const newPgSelection = accessiblePracticeGroupResponse.current.filter(m =>
      selectedOrganisations.includes(m.organisationGroupId),
    );

    // PracticeGroups is an Array of IDs, get a collection of Objects based on the id's you have selected
    const mySelection = accessiblePracticeGroupResponse.current.filter(m =>
      props.values.userPracticeGroupIds.includes(m.practiceGroupId),
    );

    // Using the Site Object collection, filter those sites based on the currently selected organisations
    const trimmed = mySelection.filter(m => selectedOrganisations.includes(m.organisationGroupId));

    // Set the props for the form to display the correct sites
    props.values.userPracticeGroupIds = trimmed.map(m => m.practiceGroupId);

    // Map the objects for display in the drop down list
    const options = newPgSelection.map(m => ({
      displayText: m.name,
      value: m.practiceGroupId,
    }));

    // Populate the drop down with the relevant practice groups
    setPracticeGroups(options);
  };

  useEffect(() => {
    fetchAccessibleOrganisations();
    fetchPracticeGroups();
    fetchSites();

    if (props.existingUser?.userPracticeGroupIds) {
      setSelectedOptions(props.existingUser?.userPracticeGroupIds);
    } else {
    }
  }, [props.values.userOrganisationGroupIds]);

  /**
   * Use Effect to allow debouncing on the change event for the Practice Group DDL,
   * We dont want to hit the server everytime a option is selected as multiple options
   * might be wanted. So debounce for a second to allow the user to apply whatever
   * filter they want - and then query the API. The timeout might need to be adjusted
   * to fit correctly.
   */
  useEffect(() => {
    const fetchSites = async () => {
      sitesByPracticeGroup
        .makeRequest({
          PracticeGroups: selectedOptions,
          OrganisationGroups: props.values.userOrganisationGroupIds,
        })
        .then(result => {
          if (result) {
            setSites(
              result.sites.map(m => {
                return {
                  displayText: m.siteName,
                  value: m.siteId,
                };
              }),
            );
          }
        });

      setLoading(false);
    };
    // Debounced API call
    const debouncedFetchSites = debounce(fetchSites, 1000); // Delay of 1 second
    debouncedFetchSites(); // Call API after the delay when options are selected

    // Cleanup function to cancel the debounce on unmount
    return () => debouncedFetchSites.cancel();
  }, [selectedOptions]);

  const handlePracticeGroupChange = (event: Array<number>) => {
    setLoading(true);
    setSelectedOptions(event);
  };

  return (
    <FormFieldGroupContainer>
      <FormGroupTitle>{t('createOrEditForm.headings.groups')}</FormGroupTitle>
      <FormFieldGroup>
        <MultiSelectDropdownField
          name="userOrganisationGroupIds"
          label={t('createOrEditForm.labels.organisationGroup')}
          options={organisationGroups}
        />

        <h3>{t('createOrEditForm.headings.practiceGroups')}</h3>
        <MultiSelectDropdownField
          name="userPracticeGroupIds"
          label={t('createOrEditForm.labels.practiceGroups')}
          options={practiceGroups}
          sortSelectedItems={(item: DropdownOption<number | string>) => item.displayText}
          disabled={accessiblePracticeGroupsRequest.inProgress}
          placeholder={
            accessiblePracticeGroupsRequest.inProgress
              ? t('createOrEditForm.labels.practiceGroupsLoading')
              : undefined
          }
          onChange={values => handlePracticeGroupChange(values as Array<number>)}
        />

        <SiteSpinnerWrapper>
          <SiteSpinner>{loading && <NavIcon icon={faSpinner} className="fa-spin" />}</SiteSpinner>
          <h3>{t('createOrEditForm.headings.sites')}</h3>
          <MultiSelectDropdownField
            name="userSiteIds"
            label={t('createOrEditForm.labels.sites')}
            options={sites}
            sortSelectedItems={(item: DropdownOption<number | string>) => item.displayText}
            disabled={accessibleSitesRequest.inProgress}
            placeholder={
              accessibleSitesRequest.inProgress
                ? t('createOrEditForm.labels.sitesLoading')
                : undefined
            }
          />
        </SiteSpinnerWrapper>
      </FormFieldGroup>
    </FormFieldGroupContainer>
  );
};
