import * as React from 'react';
import { ChangeEvent, ChangeEventHandler, useCallback, useEffect, useState } from 'react';

type Props = {
  initialValue?: string;
  children: (props: {
    value: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
  }) => React.ReactNode;
};

export const ControlledInput = (props: Props) => {
  const [currentValue, setCurrentValue] = useState(props.initialValue || '');

  useEffect(() => {
    setCurrentValue(props.initialValue || '');
  }, [props.initialValue]);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setCurrentValue(newValue);
    },
    [setCurrentValue],
  );

  return <>{props.children({ value: currentValue, onChange })}</>;
};
