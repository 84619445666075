import { RouteComponentProps, Router } from '@reach/router';
import * as React from 'react';
import { PageNotFound } from '../PageNotFound';
import { Faq } from './Faq';

export const FaqSection = (props: RouteComponentProps) => (
  <Router primary={false}>
    <Faq path="/" />
    <PageNotFound default={true} />
  </Router>
);
