import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../shared/buttons/Button';
import { ErrorBox } from '../../shared/errors/ErrorBox';
import { styled } from '../../styling/theme';
import { LoginOptions } from './Authentication';

type Props = {
  redirectToLogin: (options?: LoginOptions) => void;
};

export const AuthenticationError = (props: Props) => {
  const { t } = useTranslation('login');

  return (
    <AuthenticationErrorContainer>
      <ErrorBox errors={t('authenticationError')} />
      {/* If there's been an error, we force the user to explicitly sign in again when redirected
      to the login page - this avoids situations where the user is signed in to Identity Server, so
      are immediately redirected back here from Identity Server without being able to log in
      as a different user, and so get stuck in an infinite loop */}
      <RetryButton onClick={() => props.redirectToLogin({ forceLoginPrompt: true })}>
        {t('retry')}
      </RetryButton>
    </AuthenticationErrorContainer>
  );
};

const AuthenticationErrorContainer = styled.div``;

const RetryButton = styled(PrimaryButton)`
  margin-top: ${props => props.theme.spacing.small}px;
`;
