import * as React from 'react';
import { useContext } from 'react';
import { keyframes, ThemeContext } from 'styled-components';
import { styled, Theme } from '../styling/theme';

export const loadingIndicatorTestId = 'loading-indicator-id';

const loadingAnimation = keyframes`
  0%,80%,to {
      transform: scale(0)
  }

  40% {
      transform: scale(1)
  }
`;

type LoadingCircleProps = { delay: number; colour?: string };

export const LoadingCircle = styled.div<LoadingCircleProps>`
  background-color: ${props =>
    props.colour ? props.colour : props.theme.colours.buttons.primaryTextColour};
  width: 15px;
  height: 15px;
  border-radius: 100%;
  animation-duration: 1400ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-play-state: running;
  animation-name: ${loadingAnimation};
  animation-delay: ${props => props.delay}ms;
`;
const LoadingContainer = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  height: 100%;
  width: 100%;
  display: flex;
  padding: ${props => props.theme.spacing.large}px;
  align-items: center;
  justify-content: center;
  ${LoadingCircle} {
    width: 20px;
    height: 20px;
    margin-right: 10px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const LoadingIndicator = () => {
  const themeContext: Theme = useContext(ThemeContext);
  return (
    <LoadingContainer data-testid={loadingIndicatorTestId}>
      <LoadingCircle colour={themeContext.colours.primary} delay={0} />
      <LoadingCircle colour={themeContext.colours.primary} delay={160} />
      <LoadingCircle colour={themeContext.colours.primary} delay={320} />
    </LoadingContainer>
  );
};
