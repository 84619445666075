import { useContext, useEffect, useState } from 'react';
import { AuthenticationContext } from '../../feature/authentication/authenticationContext';
import { AuthenticatedApiRequest } from '../../models/apiRequest';
import { CancellablePromise } from '../../models/cancellablePromise';
import { ApiError, defaultApiError } from '../../models/error';

export type UseApiRequestResult<TResponse, TRequestParams> = {
  response: TResponse | null;
  inProgress: boolean;
  apiError: ApiError | null;
  makeRequest: (params: TRequestParams) => Promise<TResponse | null>;
  clearError: () => void;
};

let pending = false;

// Takes an API request function and will return:
// -  The request function (so you can add any handling to the promise you wish, an error will return a null result)
// -  The API response (when it is returned)
// -  Whether the request is in progress
// -  The error, if the API request fails
export const useApiRequest = <TResponse, TRequestParams>(
  request: AuthenticatedApiRequest<TResponse, TRequestParams>,
): UseApiRequestResult<TResponse, TRequestParams> => {
  const authenticationContext = useContext(AuthenticationContext);
  const [response, setResponse] = useState<TResponse | null>(null);
  const [inProgress, setInProgress] = useState(false);
  const [apiError, setApiError] = useState<ApiError | null>(null);

  const [activeRequest, setActiveRequest] = useState<CancellablePromise<TResponse> | null>(null);

  useEffect(() => {
    return () => {
      if (pending) {
        cancelRequest(activeRequest);
      }
    };
  }, [activeRequest]);

  const cancelRequest = (requestToCancel?: CancellablePromise<TResponse> | null) => {
    if (requestToCancel != null) {
      requestToCancel.cancel();
    }
  };

  const makeRequest = async (params: TRequestParams): Promise<TResponse | null> => {
    setInProgress(true);
    setApiError(null);

    try {
      const accessToken = await authenticationContext.getAccessToken();
      const currentRequest = request(params)(accessToken);
      setActiveRequest(currentRequest);
      pending = true;

      return currentRequest
        .then(result => {
          setResponse(result);
          setInProgress(false);
          setApiError(null);
          setActiveRequest(null);
          pending = false;

          return result;
        })
        .catch((error: ApiError) => {
          setResponse(null);
          setInProgress(false);
          setApiError(error);
          setActiveRequest(null);
          pending = false;

          return null;
        });
    } catch (error) {
      setInProgress(false);
      setApiError(defaultApiError());
      pending = false;

      return null;
    }
  };

  const clearError = () => {
    setApiError(null);
  };

  return {
    response,
    inProgress,
    apiError,
    makeRequest,
    clearError,
  };
};
