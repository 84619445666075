import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Downshift, {
  ControllerStateAndHelpers,
  DownshiftState,
  StateChangeOptions,
} from 'downshift';
import { FormikHandlers } from 'formik';
import { map } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css, styled } from '../../styling/theme';
import {
  DropdownItem,
  DropdownMenu,
  DropdownMenuContainer,
  MultiSelectRemoveSelectedButton,
  MultiSelectSelection,
} from '../dropdowns/DropdownComponents';
import {
  dropdownItemTestId,
  getValidDropdownOptionsForDropdownValues,
} from '../dropdowns/dropdownHelpers';
import { BaseInputProps, BaseInputStyling } from './BaseInputStyling';
import { DropdownOption } from './DropdownField';
import {
  ErrorMessageMarginProps,
  FormField,
  FormFieldLabel,
  FormFieldProps,
  WithErrorMessageMargin,
} from './FormField';

type OptionType = DropdownOption<string | number>;

type MultiSelectDropdownProps = {} & BaseInputProps;

/**
 * Jira - pp-996
 * Add the functon stub to the component props
 */
type MultiSelectComponentProps = {
  name: string;
  label?: string;
  hideLabel?: boolean;
  placeholder?: string;
  onChange: FormikHandlers['handleChange'];
  onBlur: FormikHandlers['handleBlur'];
  disabled?: boolean;
  invalid: boolean;
  options: Array<OptionType>;
  value: Array<string | number>;
  setFieldValue: (value: unknown) => void;
  setFieldTouched: () => void;
  sortSelectedItems?: (item: DropdownOption<number | string>) => number | string;
  onInputValueChange?: (value: unknown) => void;
};

/**
 * Jira - pp-996
 * Add the functon stub to the component props
 */
type MultiSelectDropdownFieldProps = {
  placeholder?: string;
  options: Array<OptionType>;
  sortSelectedItems?: (item: DropdownOption<number | string>) => number | string;
  disabled?: boolean;
  onInputValueChange?: (value: unknown) => void;
} & FormFieldProps;

export const StyledMultiSelectDropdown = styled.div<ErrorMessageMarginProps>`
  ${WithErrorMessageMargin};
`;

const StyledFormFieldLabel = styled(FormFieldLabel)`
  margin-bottom: ${props => props.theme.spacing.extraSmall}px;
`;

export const StyledInputContainer = styled.div<
  { disabled?: boolean } & MultiSelectDropdownProps & React.HTMLAttributes<HTMLDivElement>
>`
  ${BaseInputStyling};
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  max-height: 200px;
  overflow: auto;

  ${props =>
    props.disabled &&
    css`
      color: ${props.theme.colours.secondaryHighlight};
      border-color: ${props.theme.colours.secondaryHighlight};
      background-color: ${props.theme.colours.appBackground};
      cursor: default;
    `};
`;

const StyledSelectPlaceholder = styled.div`
  margin-right: ${props => props.theme.spacing.tiny}px;
`;

const StyledInput = styled.input`
  flex: 1;
  background-color: inherit;
  color: inherit;
  border: none;
  padding-left: ${props => props.theme.spacing.tiny}px;
  padding-right: ${props => props.theme.spacing.tiny}px;
`;

const MultiSelectComponent = (props: MultiSelectComponentProps) => {
  const { t } = useTranslation('component');
  const [selectedItems, setSelectedItems] = useState<Array<OptionType>>(
    getValidDropdownOptionsForDropdownValues(props.options, props.value, props.sortSelectedItems),
  );

  useEffect(() => {
    if (props.value === null) {
      setSelectedItems([]);
    } else {
      setSelectedItems(
        getValidDropdownOptionsForDropdownValues(
          props.options,
          props.value,
          props.sortSelectedItems,
        ),
      );
    }
  }, [props.options, props.value, props.sortSelectedItems]);

  const getFilteredOptions = (inputValue: string | null) =>
    inputValue
      ? props.options.filter(option =>
          option.displayText.toLowerCase().includes(inputValue.toLowerCase()),
        )
      : props.options;

  const itemToString = (item: OptionType | null) => (item ? item.displayText : '');

  const stateReducer = (
    state: DownshiftState<OptionType>,
    changes: StateChangeOptions<OptionType>,
  ): Partial<StateChangeOptions<OptionType>> => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          highlightedIndex: state.highlightedIndex,
          isOpen: true,
          inputValue: state.inputValue,
        };
      default:
        return changes;
    }
  };

  const handleSelection = (
    selectedItem: OptionType | null,
    downshift: ControllerStateAndHelpers<OptionType>,
  ) => {
    if (selectedItem != null) {
      selectedItems.includes(selectedItem) ? removeItem(selectedItem) : addItem(selectedItem);
    }
  };

  const removeItem = (itemToRemove: OptionType) => {
    const newlySelectedItems = selectedItems.filter(selectedItem => selectedItem !== itemToRemove);
    setSelectedItems(newlySelectedItems);
    props.setFieldValue(newlySelectedItems.map(item => item.value));
    props.setFieldTouched();
  };

  const addItem = (itemToAdd: OptionType) => {
    const newlySelectedItems = [...selectedItems, itemToAdd];
    setSelectedItems(newlySelectedItems);
    props.setFieldValue(newlySelectedItems.map(item => item.value));
    props.setFieldTouched();
  };

  const handleRemoveItem = (itemToRemove: OptionType) => (e: React.MouseEvent) => {
    removeItem(itemToRemove);
    e.stopPropagation();
  };

  const inputElement = React.createRef<HTMLInputElement>();
  const inputContainerClick = (downshift: ControllerStateAndHelpers<OptionType>) => () => {
    if (!props.disabled) {
      downshift.toggleMenu();
      if (!downshift.isOpen && inputElement.current) {
        inputElement.current.focus();
      }
    }
  };

  const handleBlur = (e: React.FocusEvent) => props.onBlur(e);

  /**
   * Jira - pp-996
   * Add a function to get the value from the input as you type,
   * using the inputValue prop provided by Downshift.
   */
  const handleInputValueChange = (inputValue: string) => {
    if (props.onInputValueChange) {
      props.onInputValueChange(inputValue);
    }
  };

  /**
   * Jira - pp-996
   * Add the prop and the callback to the main downshift function
   */
  return (
    <Downshift
      itemToString={itemToString}
      stateReducer={stateReducer}
      onSelect={handleSelection}
      selectedItem={null}
      onInputValueChange={handleInputValueChange} // Pass the callback here
    >
      {downshift => (
        <StyledMultiSelectDropdown
          {...downshift.getRootProps()}
          showingError={props.invalid}
          onBlur={handleBlur}
        >
          {!props.hideLabel && (
            <StyledFormFieldLabel {...downshift.getLabelProps()} showingError={props.invalid}>
              {props.label}
            </StyledFormFieldLabel>
          )}
          <StyledInputContainer
            onClick={inputContainerClick(downshift)}
            invalid={props.invalid}
            disabled={props.disabled}
          >
            {selectedItems.length > 0 ? (
              map(selectedItems, item => (
                <MultiSelectSelection key={item.value}>
                  <span>{item.displayText}</span>
                  {!props.disabled && (
                    <MultiSelectRemoveSelectedButton
                      aria-label={t('multiSelectDropdownField.remove', {
                        selection: item.displayText,
                      })}
                      onClick={handleRemoveItem(item)}
                      disabled={props.disabled}
                      name={props.name}
                      type="button"
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </MultiSelectRemoveSelectedButton>
                  )}
                </MultiSelectSelection>
              ))
            ) : (
              <StyledSelectPlaceholder>
                {props.placeholder || t('multiSelectDropdownField.select')}
              </StyledSelectPlaceholder>
            )}
            <StyledInput
              {...downshift.getInputProps({
                ref: inputElement,
                name: props.name,
                disabled: props.disabled,
              })}
              as="input"
              data-testid={`multiSelectDropdownField:${props.name}`}
            />
          </StyledInputContainer>
          <DropdownMenuContainer>
            <DropdownMenu {...downshift.getMenuProps({ disabled: props.disabled })}>
              {downshift.isOpen
                ? getFilteredOptions(downshift.inputValue).map(
                    (item: OptionType, index: number) => (
                      // tslint:disable-next-line:jsx-key Provided by getItemProps
                      <DropdownItem
                        {...downshift.getItemProps({
                          item,
                          index,
                          key: item.value,
                          isSelected: selectedItems.includes(item),
                        })}
                        isHighlighted={downshift.highlightedIndex === index}
                        data-testid={dropdownItemTestId(props.name, item.value)}
                      >
                        {downshift.itemToString(item)}
                        {selectedItems.includes(item) && <FontAwesomeIcon icon={faCheck} />}
                      </DropdownItem>
                    ),
                  )
                : null}
            </DropdownMenu>
          </DropdownMenuContainer>
        </StyledMultiSelectDropdown>
      )}
    </Downshift>
  );
};

/**
 * Jira - pp-996
 * Add the prop and the callback to the main downshift function
 */
export const MultiSelectDropdownField = (props: MultiSelectDropdownFieldProps) => {
  const { options, disabled, label, ...formFieldProps } = props;

  return (
    <FormField {...formFieldProps}>
      {({ field, form, valid, invalid }) => {
        const setFieldValue = (value: unknown) => {
          if (props.onChange) {
            props.onChange(value, form);
          }
          form.setFieldValue(field.name, value);
        };
        const setFieldTouched = () => form.setFieldTouched(field.name);

        return (
          <MultiSelectComponent
            {...field}
            label={props.label}
            hideLabel={props.hideLabel}
            placeholder={props.placeholder}
            invalid={invalid}
            disabled={props.disabled}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            options={props.options}
            sortSelectedItems={props.sortSelectedItems}
            onInputValueChange={props.onInputValueChange}
          />
        );
      }}
    </FormField>
  );
};
