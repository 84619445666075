import { client, secureGet } from '../../../api/apiHttpClient';
import { AuthenticatedApiRequest } from '../../../models/apiRequest';
import { Id } from '../../../models/id';
import { SupplierProductResponse } from '../../support/components/DeleteSupplierItems/DeleteSupplierItems.types';
import { UnassignedSupplierProductsResponse } from './supplierProduct';

export const getUnassignedProductsForSupplier: AuthenticatedApiRequest<
  UnassignedSupplierProductsResponse,
  Id
> = supplierId => secureGet(`supplierProducts/${supplierId}/unassigned`);

export const getSupplierProductNotOnCurrentSupplierList = async (
  itemCode: string,
  token: string,
  abortSignal?: AbortSignal,
): Promise<SupplierProductResponse> => {
  const { data } = await client.get<SupplierProductResponse>(
    `supplierproducts/delete/${encodeURIComponent(itemCode)}`,
    {
      headers: { Authorization: `Bearer ${token}` },
      ...(abortSignal ? { signal: abortSignal } : {}),
    },
  );

  return data;
};

export const deleteSupplierProductRequest = async (
  itemId: number,
  token: string,
  abortSignal?: AbortSignal,
) => {
  const { data } = await client.delete(`supplierproducts/delete/${itemId}`, {
    headers: { Authorization: `Bearer ${token}` },
    ...(abortSignal ? { signal: abortSignal } : {}),
  });

  return data;
};
