import { ThemeProps } from 'styled-components';
import { css, Theme } from '../../styling/theme';

export type InputSize = 'small' | 'medium';

export type BaseInputProps = {
  valid?: boolean;
  invalid?: boolean;
  warning?: boolean;
  bulkVariant?: boolean;
  inputSize?: InputSize;
  isRightAligned?: boolean;
};

const getInputMinWidth = (props: BaseInputProps & ThemeProps<Theme>) => {
  if (props.bulkVariant) {
    return `${props.theme.formInputWidth.tiny}px`;
  } else {
    return 'default';
  }
};

const getInputWidth = (props: BaseInputProps & ThemeProps<Theme>) => {
  if (props.bulkVariant) {
    return '100%';
  } else {
    return `${
      props.inputSize === 'small'
        ? props.theme.formInputWidth.small
        : props.theme.formInputWidth.medium
    }px`;
  }
};

const getVerticalPadding = (props: BaseInputProps & ThemeProps<Theme>) => {
  if (props.bulkVariant) {
    return 0;
  } else {
    return props.inputSize === 'small' ? 3 : props.theme.spacing.tiny;
  }
};

export const BaseInputStyling = css<BaseInputProps>`
  outline: none;
  line-height: 1.8;
  padding: ${getVerticalPadding}px ${props => props.theme.spacing.tiny}px;
  min-width: ${props => getInputMinWidth(props)};
  width: ${props => getInputWidth(props)};
  max-width: ${props => props.theme.formInputWidth.max}px;
  color: ${props =>
    props.bulkVariant ? props.theme.colours.secondary : props.theme.colours.secondaryHighlight};
  border: 1px solid ${props => props.theme.colours.secondaryInactive};
  border-radius: ${props => props.theme.spacing.tiny}px;
  background-color: ${props => props.theme.colours.componentBackground};

  :focus {
    color: ${props => props.theme.colours.secondary};
    border-style: ${props => props.bulkVariant && 'dashed'};
    border-color: ${props => props.theme.colours.secondary};
    background-color: ${props => props.theme.colours.componentBackground};
  }

  :disabled {
    color: ${props => props.theme.colours.secondaryInactive};
    border-color: ${props => props.theme.colours.secondaryInactive};
    border-style: ${props => props.bulkVariant && 'dashed'};
    background-color: ${props => props.theme.colours.appBackground};
    cursor: not-allowed;
  }

  ${props => {
    if (props.invalid) {
      return css`
        color: ${props.theme.colours.alertText};
        border-color: ${props.theme.colours.alertText};
        border-style: ${props.bulkVariant && 'dashed'};
        background-color: ${props.theme.colours.alertBackground};
      `;
    }

    if (props.warning) {
      return css`
        color: ${props.theme.colours.warningText};
        border-color: ${props.theme.colours.warningText};
        border-style: ${props.bulkVariant && 'dashed'};
        background-color: ${props.theme.colours.warningBackground};
      `;
    }
  }};
`;
