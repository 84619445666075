import { FormikProps } from 'formik';
import * as React from 'react';
import { useContext, useEffect } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Column,
  HeaderGroup,
  Row,
  useExpanded,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { ThemeContext } from 'styled-components';
import { AuthenticatedApiRequest } from '../../models/apiRequest';
import { css, styled, Theme } from '../../styling/theme';
import { LoadingSpinner } from '../buttons/Button';
import { ApiErrorBox } from '../errors/ApiErrorBox';
import { InfoBox } from '../info/InfoBox';
import { PagedRequest, PagedResponse } from './pagination/pagination';
import { PaginationControls } from './pagination/PaginationControls';
import { PaginationJumpToPage } from './pagination/PaginationJumpToPage';
import { SortRequest, SortResponse } from './sorting/sorting';
import { SortingIndicator } from './sorting/SortingComponents';
import { useManualTableHandling } from './useManualTableHandling';

export type TableDataFetchResponse<TColumnNames> = SortResponse<TColumnNames> & PagedResponse;

export type TableDataFetchRequest<TColumnNames> = SortRequest<TColumnNames> & PagedRequest;

type CommonTableProps<TRowData extends object, TColumnNames extends string, TFormModel> = {
  columns: ReadonlyArray<CustomColumn<TRowData, TColumnNames>>;
  className?: string;
  emptyTableMessage?: string;
  hiddenColumnNames?: Array<TColumnNames>;
  getSubRows?: (row: TRowData) => Array<TRowData>;
  formikProps?: FormikProps<TFormModel>;
  disableStickyHeader?: boolean;
};

export type TableProps<
  TRowData extends object,
  TColumnNames extends string,
  TFormModel
> = CommonTableProps<TRowData, TColumnNames, TFormModel> & {
  data: Array<TRowData>;
};

export type CustomColumn<TRowData extends object, TColumnNames extends string = string> = Column<
  TRowData
> & {
  id: TColumnNames;
  isRightAligned?: boolean;
  fitContent?: boolean;
  headerGroupName?: string;
};

export type DataFetchTableProps<
  TApiData extends TableDataFetchResponse<TColumnNames>,
  TRowData extends object,
  TColumnNames extends string,
  TFormModel
> = CommonTableProps<TRowData, TColumnNames, TFormModel> & {
  allowPagination?: boolean;
  pageSize: number;
  dynamicPageSize?: boolean;
  getApiData: AuthenticatedApiRequest<TApiData, TableDataFetchRequest<TColumnNames>>;
  validColumnTypeOrUndefined?: (input: string) => TColumnNames | undefined;
  onDataFetchSuccess?: (response: TApiData) => void;
  mapResponseToTableData: (response: TApiData) => Array<TRowData>;
  shouldRestrictFetching?: boolean;
};

const TableContainer = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  width: fit-content;
`;

const LoadingOverlay = styled.div<
  React.HTMLAttributes<HTMLDivElement> & { showLoadingOverlay: boolean }
>`
  background: ${props => props.theme.colours.loadingOverlay};
  display: ${props => (props.showLoadingOverlay ? 'initial' : 'none')};
  opacity: 0.5;
  z-index: 4;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const StyledTable = styled.table`
  background-color: ${props => props.theme.colours.componentBackground};
  border-collapse: collapse;
  border: 1px solid ${props => props.theme.colours.border};
`;

const StyledHeader = styled.thead<
  React.TableHTMLAttributes<HTMLTableCellElement> & { disableStickyHeader?: boolean }
>`
${props =>
  !props.disableStickyHeader &&
  css`
    position: sticky;
    top: 0;
    z-index: 1;
  `}

  border: 1px solid ${props => props.theme.colours.border};
  font-weight: bold;
`;

const StyledTh = styled.th<
  React.TableHTMLAttributes<HTMLTableCellElement> & {
    isRightAligned?: boolean;
    fitContent?: boolean;
  }
>`
  ${props =>
    props.fitContent &&
    css`
      width: 0;
    `}

  padding: 0.5rem;
  text-align: ${props => (props.isRightAligned != null && props.isRightAligned ? 'right' : 'left')};
  background-color: ${props => props.theme.colours.componentBackground};
`;

const GroupedHeaderTh = styled(StyledTh)<{ colSpan?: number }>`
  text-align: center;
  border: 1px solid ${props => props.theme.colours.border};
  border-left: 1px solid ${props => props.theme.colours.border};
`;

const StyledSortIndicator = styled(SortingIndicator)`
  padding-left: ${props => props.theme.spacing.extraSmall}px;
`;

const StyledRow = styled.tr`
  border: 1px solid ${props => props.theme.colours.border};
`;

const StyledTd = styled.td<
  React.ThHTMLAttributes<HTMLTableCellElement> & {
    canExpand?: boolean;
    isRightAligned?: boolean;
  }
>`
  padding: 0.5rem;
  text-align: ${props => (props.isRightAligned != null && props.isRightAligned ? 'right' : 'left')};
  background-color: ${props =>
    props.canExpand
      ? props.theme.colours.expandableTableRow
      : props.theme.colours.componentBackground};
`;

const StyledErrorBox = styled(ApiErrorBox)`
  margin-top: ${props => props.theme.spacing.small}px;
`;

const getHeaderGroupsRow = <TRowData extends object>(
  headerGroups: Array<HeaderGroup<TRowData>>,
): React.ReactNode => {
  const headers = headerGroups[0].headers;

  if (!headers.some(h => !!h.headerGroupName)) {
    return null;
  }

  let currentGroupIndex = 0;
  const groups: Array<{ name: string | undefined; count: number }> = [];

  headers.forEach(header => {
    if (!groups.length) {
      groups[currentGroupIndex] = { name: header.headerGroupName, count: 1 };
    } else if (groups[currentGroupIndex].name === header.headerGroupName) {
      groups[currentGroupIndex].count++;
    } else {
      currentGroupIndex++;
      groups[currentGroupIndex] = { name: header.headerGroupName, count: 1 };
    }
  });

  return (
    <tr>
      {groups.map((group, index) => (
        <GroupedHeaderTh key={index} colSpan={group.count}>
          {group.name}
        </GroupedHeaderTh>
      ))}
    </tr>
  );
};

export const DataFetchTable = <
  TApiData extends TableDataFetchResponse<TColumnNames>,
  TRowData extends object,
  TColumnNames extends string = string,
  TFormModel = never
>(
  props: DataFetchTableProps<TApiData, TRowData, TColumnNames, TFormModel>,
) => {
  const { t } = useTranslation('component');
  const {
    pagedApiData,
    pageCount,
    apiPageSize,
    urlSortDirection,
    urlSortByColumn,
    loading,
    canNextPage,
    canPrevPage,
    goToPage,
    totalItems,
    toggleSortBy,
    apiError,
    apiPageNumber,
  } = useManualTableHandling({
    pageSize: props.pageSize,
    dynamicPageSize: props.dynamicPageSize ? props.dynamicPageSize : false,
    getApiData: props.getApiData,
    onDataFetchSuccess: props.onDataFetchSuccess,
    validColumnTypeOrUndefined: props.validColumnTypeOrUndefined,
    shouldRestrictFetching: props.shouldRestrictFetching,
  });

  const tableData = useMemo(
    () => (pagedApiData ? props.mapResponseToTableData(pagedApiData) : []),
    [props.mapResponseToTableData, pagedApiData],
  );

  const { getTableProps, headerGroups, rows, prepareRow, setHiddenColumns } = useTable<TRowData>(
    {
      columns: props.columns,
      data: tableData,
      defaultColumn: { disableSortBy: true },
      manualPagination: true,
      pageCount,
      manualSortBy: true,
      disableMultiSort: true,
      initialState: {
        hiddenColumns: props.hiddenColumnNames || [],
      },
      getSubRows: props.getSubRows,
      formikProps: props.formikProps,
    },
    useSortBy,
    useExpanded,
    usePagination,
  );

  useEffect(() => {
    setHiddenColumns(props.hiddenColumnNames || []);
  }, [props.hiddenColumnNames]);

  const showPaginationControls = props.allowPagination === undefined || props.allowPagination;
  const themeContext: Theme = useContext(ThemeContext);

  const headerGroupsRow = useMemo(() => getHeaderGroupsRow(headerGroups), [headerGroups]);

  if (pagedApiData && totalItems === 0) {
    return <InfoBox message={props.emptyTableMessage} />;
  }

  return (
    <TableContainer className={props.className}>
      <LoadingOverlay showLoadingOverlay={loading}>
        <LoadingSpinner colour={themeContext.colours.primary} />
      </LoadingOverlay>
      {showPaginationControls && (
        <PaginationControls
          pageSize={apiPageSize}
          pageNumber={apiPageNumber}
          totalItems={totalItems}
          loading={loading}
          reactTablePagination={{
            canNextPage,
            canPreviousPage: canPrevPage,
            gotoPage: goToPage,
          }}
          atTop={true}
        />
      )}
      <StyledTable {...getTableProps()}>
        <StyledHeader disableStickyHeader={props.disableStickyHeader}>
          {headerGroups.map((headerGroup, index) => (
            <React.Fragment key={index}>
              {headerGroupsRow}
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  // tslint:disable-next-line:jsx-key (getHeaderProps returns key)
                  <StyledTh
                    isRightAligned={column.isRightAligned}
                    fitContent={column.fitContent}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    title={column.canSort ? t('table.toggleSort') : undefined}
                    onClick={column.canSort ? toggleSortBy(column.id) : undefined}
                  >
                    {column.render('Header')}
                    {column.canSort && (
                      <StyledSortIndicator
                        isSorted={urlSortByColumn === column.id}
                        isDescending={urlSortDirection === 'desc'}
                      />
                    )}
                  </StyledTh>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </StyledHeader>
        <tbody>
          {rows.map((row: Row<TRowData>) => {
            prepareRow(row);
            return (
              <StyledRow {...row.getRowProps()}>
                {row.cells.map(cell => (
                  // tslint:disable-next-line:jsx-key (getCellProps returns key)
                  <StyledTd {...cell.getCellProps()} canExpand={row.canExpand}>
                    {cell.render('Cell')}
                  </StyledTd>
                ))}
              </StyledRow>
            );
          })}
        </tbody>
      </StyledTable>
      {showPaginationControls && (
        <PaginationControls
          pageSize={apiPageSize}
          pageNumber={apiPageNumber}
          totalItems={totalItems}
          loading={loading}
          reactTablePagination={{
            canNextPage,
            canPreviousPage: canPrevPage,
            gotoPage: goToPage,
          }}
          atBottom={true}
        />
      )}
      {showPaginationControls && (
        <PaginationJumpToPage
          pageSize={apiPageSize}
          pageNumber={apiPageNumber}
          totalItems={totalItems}
          totalPages={pageCount}
          loading={loading}
          reactTablePagination={{
            canNextPage,
            canPreviousPage: canPrevPage,
            gotoPage: goToPage,
          }}
          atBottom={true}
        />
      )}
      {apiError && <StyledErrorBox error={apiError} />}
    </TableContainer>
  );
};

export const Table = <TRowData extends object, TColumnNames extends string, TFormModel = never>(
  props: TableProps<TRowData, TColumnNames, TFormModel>,
) => {
  const { t } = useTranslation('component');

  const { getTableProps, headerGroups, rows, prepareRow, setHiddenColumns } = useTable<TRowData>(
    {
      columns: props.columns,
      data: props.data,
      defaultColumn: { disableSortBy: true },
      autoResetPage: false,
      disableMultiSort: true,
      initialState: {
        hiddenColumns: props.hiddenColumnNames || [],
      },
      getSubRows: props.getSubRows,
      formikProps: props.formikProps,
    },
    useSortBy,
    useExpanded,
  );

  useEffect(() => {
    setHiddenColumns(props.hiddenColumnNames || []);
  }, [props.hiddenColumnNames]);

  const headerGroupsRow = useMemo(() => getHeaderGroupsRow(headerGroups), [headerGroups]);

  if (props.data.length === 0 && props.emptyTableMessage) {
    return <InfoBox message={props.emptyTableMessage} />;
  }

  return (
    <TableContainer className={props.className}>
      <StyledTable {...getTableProps()}>
        <StyledHeader>
          {headerGroups.map((headerGroup: HeaderGroup<TRowData>, index) => (
            <React.Fragment key={index}>
              {headerGroupsRow}
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  // tslint:disable-next-line:jsx-key (getHeaderProps returns key)
                  <StyledTh
                    isRightAligned={column.isRightAligned}
                    fitContent={column.fitContent}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    title={column.canSort ? t('table.toggleSort') : undefined}
                  >
                    {column.render('Header')}
                    {column.canSort && (
                      <StyledSortIndicator
                        isSorted={column.isSorted}
                        isDescending={column.isSortedDesc}
                      />
                    )}
                  </StyledTh>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </StyledHeader>
        <tbody>
          {rows.map((row: Row<TRowData>) => {
            prepareRow(row);
            return (
              <StyledRow {...row.getRowProps()}>
                {row.cells.map(cell => (
                  // tslint:disable-next-line:jsx-key (getCellProps returns key)
                  <StyledTd {...cell.getCellProps()} canExpand={row.canExpand}>
                    {cell.render('Cell')}
                  </StyledTd>
                ))}
              </StyledRow>
            );
          })}
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};
