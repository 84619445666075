import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { RequiresPermission } from '../../shared/Permissions';
import { MetadataContext } from '../authentication/loginData/metadataContext';
import { permissions } from '../authentication/permissions';
import { OrganisationGroupForm } from './OrganisationGroupForm';

type OwnProps = RouteComponentProps & {};
type Props = OwnProps;

export const CreateOrganisationGroup = (props: Props) => {
  const { t } = useTranslation('organisation');
  return (
    <RequiresPermission permission={permissions.SuperAdmin}>
      <Title>{t('createForm.title')}</Title>
      <h1>{t('createForm.heading')}</h1>
      <MetadataContext.Consumer>
        {metadata => <OrganisationGroupForm countries={metadata.countries} />}
      </MetadataContext.Consumer>
    </RequiresPermission>
  );
};
