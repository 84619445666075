import { Id } from '../../models/id';
import { Filterable } from '../../shared/tables/filtering/useFiltering';
import { PagedRequest } from '../../shared/tables/pagination/pagination';
import { TableDataFetchResponse } from '../../shared/tables/Table';
import { ItemTypeCode } from '../items/item';
import { ComparisonType } from './comparisonType';
import { CountryCode } from '../authentication/loginData/metadata';

export type DeviationFilterOptions = {
  itemIds: Array<number> | null;
  siteIds: Array<number> | null;
  practiceGroupIds: Array<number> | null;
  isHidden: boolean | null;
  category1Ids: Array<Id> | null;
  category2Ids: Array<Id> | null;
  category3Ids: Array<Id> | null;
  clientCategoryIds: Array<Id> | null;
  centralPrice: ComparisonType | null;
  dispenseFee: ComparisonType | null;
  supplierProductCodeSearchString: string | null;
  supplierIds: Array<number> | null;
  priceDevaitionSourceId: number | null;
  dispenseFeeDevaitionSourceId: number | null;
  itemName: string | null;
  overwrite: boolean | null;
  itemTypeId: number | null;
  isRecentlyAdded: boolean | null;
};

export type DeviationFilterFormModel = {
  itemIds: Array<number> | null;
  siteIds: Array<number> | null;
  practiceGroupIds: Array<number> | null;
  isHidden: boolean | null;
  isNotHidden: boolean | null;
  isBothHiddenAndNotHidden: boolean | null;
  category1Ids: Array<Id> | null;
  category2Ids: Array<Id> | null;
  category3Ids: Array<Id> | null;
  clientCategoryIds: Array<Id> | null;
  isAboveCentralPrice: boolean | null;
  isBelowCentralPrice: boolean | null;
  isSameAsCentralPrice: boolean | null;
  isAboveCentralDispenseFee: boolean | null;
  isBelowCentralDispenseFee: boolean | null;
  isSameAsCentralDispenseFee: boolean | null;
  supplierProductCodeSearchString: string | null;
  supplierIds: Array<number> | null;
  priceDevaitionSourceId: number | null;
  dispenseFeeDevaitionSourceId: number | null;
  itemName: string | null;
  itemTypeId: number | null;
  overwrite: boolean | null;
  notOverwrite: boolean | null;
  isBothOverwriteAndNotOverwrite: boolean | null;
  isRecentlyAdded: boolean | null;
};

export type DeviationTableRow = {
  deviationId: Id | null;
  treatmentTypeDeviationId: Id | null;
  itemId: Id;
  itemCode: string;
  itemName: string;
  itemType: ItemTypeCode;
  itemPreventHide: boolean;
  siteId: Id;
  siteName: string;
  siteCountry: CountryCode;
  centralItemPriceNet: number;
  centralItemPriceGross: number;
  centralItemDispenseFeeNet: number;
  centralItemDispenseFeeGross: number;
  practiceGroupIds: Array<number>;
  overwrite: boolean;
  itemDeviationPriceOverride: boolean;
  itemDeviationDispenseFeeOverride: boolean;
  category1Id: Number | null;
};

export type DeviationResponse = DeviationTableRow & {
  localItemPriceNet: number;
  localItemPriceGross: number;
  priceDeviationPercentValue: number | null;
  priceDeviationAbsoluteValueNet: number;
  priceDeviationAbsoluteValueGross: number;
  priceDeviationIsSetByPercent: boolean;
  defaultPriceNet: number;
  defaultPriceGross: number;
  defaultPriceDeviationPercentValue: number;
  defaultPriceDeviationAbsoluteValueNet: number;
  defaultPriceDeviationAbsoluteValueGross: number;
  defaultPriceDeviationIsSetByPercent: boolean;
  localItemDispenseFeeNet: number;
  localItemDispenseFeeGross: number;
  dispenseFeeDeviationPercentValue: number | null;
  dispenseFeeDeviationAbsoluteValueNet: number;
  dispenseFeeDeviationAbsoluteValueGross: number;
  dispenseFeeDeviationIsSetByPercent: boolean;
  defaultDispenseFeeNet: number;
  defaultDispenseFeeGross: number;
  defaultDispenseFeeDeviationPercentValue: number;
  defaultDispenseFeeDeviationAbsoluteValueNet: number;
  defaultDispenseFeeDeviationAbsoluteValueGross: number;
  defaultDispenseFeeDeviationIsSetByPercent: boolean;
  isHidden: boolean;
  speciesId?: number;
  supplierProductCode: string | null;
  supplierName: string | null;
  overwrite: boolean;
  stockItem: boolean;
};

export type ScheduledBulkEditIdWithSiteAndItemId = {
  itemId: number;
  siteId: number;
  scheduledDeviationBulkEditId: number;
};

export type DeviationResponsesAndScheduledBulkEditIds = {
  deviationResponses: Array<DeviationResponse>;
  scheduledBulkEditIdWithSiteAndItemIds: Array<ScheduledBulkEditIdWithSiteAndItemId>;
};

export type DeviationsResponse = {
  deviationResponsesAndScheduledBulkEditId: DeviationResponsesAndScheduledBulkEditIds;
  filterOptions: DeviationFilterOptions;
} & TableDataFetchResponse<DeviationColumnTypes>;

export type DeviationColumnTypes =
  | 'expanded'
  | 'itemName'
  | 'itemCode'
  | 'category1'
  | 'category2'
  | 'category3'
  | 'siteName'
  | 'centralItemPrice'
  | 'centralItemDispenseFee'
  | 'priceDeviationPercentValue'
  | 'priceDeviationAbsoluteValueNet'
  | 'priceDeviationAbsoluteValueGross'
  | 'dispenseFeeDeviationPercentValue'
  | 'dispenseFeeDeviationAbsoluteValueNet'
  | 'dispenseFeeDeviationAbsoluteValueGross'
  | 'deviatedPrice'
  | 'deviatedDispenseFee'
  | 'isHidden'
  | 'itemType'
  | 'supplierProductCode'
  | 'supplierName'
  | 'practiceGroupIds'
  | 'deviationWarnings'
  | 'setToDefault'
  | 'actions'
  | 'itemDeviationDispenseFeeOverride'
  | 'itemDeviationPriceOverride';

export const validDeviationColumnTypeOrUndefined = (
  input: string,
): DeviationColumnTypes | undefined =>
  input === 'itemName' ||
  input === 'itemCode' ||
  input === 'category1' ||
  input === 'category2' ||
  input === 'category3' ||
  input === 'siteName' ||
  input === 'centralItemPrice' ||
  input === 'centralItemDispenseFee' ||
  input === 'priceDeviationPercentValue' ||
  input === 'priceDeviationAbsoluteValueNet' ||
  input === 'priceDeviationAbsoluteValueGross' ||
  input === 'dispenseFeeDeviationPercentValue' ||
  input === 'dispenseFeeDeviationAbsoluteValueNet' ||
  input === 'dispenseFeeDeviationAbsoluteValueGross' ||
  input === 'deviatedPrice' ||
  input === 'deviatedDispenseFee' ||
  input === 'isHidden' ||
  input === 'itemType' ||
  input === 'supplierProductCode' ||
  input === 'supplierName' ||
  input === 'deviationWarnings' ||
  input === 'setToDefault' ||
  input === 'itemDeviationPriceOverride' ||
  input === 'itemDeviationDispenseFeeOverride'
    ? input
    : undefined;

export type GetDeviationsCommand = Filterable<DeviationFilterOptions> & PagedRequest;
export type GetDeviationsDownloadCommand = Filterable<DeviationFilterOptions>;

export type DeviationFilterDropdownOptionsResponse = {
  sites: Array<NameAndId>;
  items: Array<ItemDropdownItem>;
  suppliers: Array<NameAndId>;
  practiceGroups: Array<NameAndId>;
};

export type NameAndId = {
  name: string;
  id: Id;
};

export type ItemDropdownItem = NameAndId & { treatmentTypeId: number | null };

export type DeviationWarning =
  | 'NegativePriceDeviationWarning'
  | 'NegativeDispenseFeeDeviationWarning'
  | 'GroupDifferentPriceWarning'
  | 'GroupDifferentDispenseFeeWarning'
  | 'OverwritePriceSetWarning'
  | 'OverwritePriceSetPracticeGroupWarning';

export const deviationWarnings: { [deviationWarning in DeviationWarning]: DeviationWarning } = {
  NegativePriceDeviationWarning: 'NegativePriceDeviationWarning',
  NegativeDispenseFeeDeviationWarning: 'NegativeDispenseFeeDeviationWarning',
  GroupDifferentPriceWarning: 'GroupDifferentPriceWarning',
  GroupDifferentDispenseFeeWarning: 'GroupDifferentDispenseFeeWarning',
  OverwritePriceSetWarning: 'OverwritePriceSetWarning',
  OverwritePriceSetPracticeGroupWarning: 'OverwritePriceSetPracticeGroupWarning',
};

export const enum DevationSource {
  CodeSpecific = 1,
  TreatmentType = 2,
  All = 3,
}

export const enum DevationItemType {
  Stock = 1,
  Service = 2,
}
