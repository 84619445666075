import * as React from 'react';
import { ChangeEvent } from 'react';
import { styled } from '../../styling/theme';
import { useDebouncedFormikField } from '../hooks/useDebouncedFormikField';
import { BaseInputProps, BaseInputStyling } from './BaseInputStyling';
import { FormField, FormFieldChildProps, FormFieldProps } from './FormField';

type DateInputProps = {} & BaseInputProps & React.InputHTMLAttributes<HTMLInputElement>;

export type DateFieldProps = {
  disabled?: boolean;
  autoComplete?: boolean;
} & FormFieldProps<string>;

export const DateInput = styled.input<DateInputProps>`
  ${BaseInputStyling};

  &::-webkit-inner-spin-button {
    display: none;
  }
`;

const InnerDateInput = ({
  field,
  form,
  valid,
  invalid,
  ...dateFieldProps
}: FormFieldChildProps<string> & DateFieldProps) => {
  const { value, setValue, flushDebounce } = useDebouncedFormikField({
    field,
    form,
    useDebouncing: dateFieldProps.useDebouncing,
  });

  const onChange = (event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value);

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    flushDebounce();
    field.onBlur(e);
  };

  return (
    <DateInput
      {...field}
      type="date"
      value={value || ''}
      valid={valid}
      invalid={invalid}
      autoComplete={dateFieldProps.autoComplete ? 'on' : 'off'}
      disabled={dateFieldProps.disabled}
      aria-label={dateFieldProps.label}
      bulkVariant={dateFieldProps.bulkVariant}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export const DateField = (props: DateFieldProps) => {
  const { disabled, autoComplete, ...formFieldProps } = props;

  return (
    <FormField {...formFieldProps}>
      {formFieldChildProps => <InnerDateInput {...formFieldChildProps} {...props} />}
    </FormField>
  );
};
