import { permissions } from '../../../authentication/permissions';
import { Row } from 'react-table';
import { TreatmentTypeDeviationTableRow } from '../treatmentTypeDeviations';
import { TranslatableValidator } from '../../../../utils/validation/TranslatableValidator';
import { TFunction } from 'i18next';
import { Locale } from '../../../../models/locale';
import { InputMaximumLength } from '../../../../utils/validation/validationConstants';
import { BeTodayOrLater } from '../../../../models/dates/dateTimeStamp';
import { BeValidPercent } from '../../../../shared/forms/PercentField';
import { parseNumber } from '../../../../utils/numberUtils';
import { Id } from '../../../../models/id';
import { PagedResponse } from '../../../../shared/tables/pagination/pagination';
import { FileUploadResponse } from '../../../../shared/files/fileUploadResponse';

export const createEditTreatmentTypeDeviationsPermissions = [permissions.SuperAdmin];

export const uploadTreatmentTypeDeviationsPermissions = [permissions.SuperAdmin];

export type TreatmentTypeDeviationBulkEditsResponse = {
  treatmentTypeDeviationBulkEdits: Array<TreatmentTypeDeviationBulkEditDetailsResponse>;
};

export type TreatmentTypeDeviationBulkEditDetailsResponse = {
  treatmentTypeDeviationBulkEditId: Id;
  name: string | null;
  scheduledDate: string;
  creatorsName: string;
  affectedSiteNames: Array<string>;
};

export type TreatmentTypeDeviationBulkEditResponse = {
  treatmentTypeDeviationBulkEditId: Id;
  name: string | null;
  scheduledDate: string;
  canAccessAllSitesOnBulkEdit: boolean;
  isCsvUpload: boolean;
  runImmediately: boolean;
  creatorsName: string;
  treatmentTypeDeviationBulkEditLines: Array<TreatmentTypeDeviationBulkEditLineResponse>;
} & PagedResponse;

export type TreatmentTypeDeviationBulkEditLineResponse = TreatmentTypeDeviationTableRow & {
  percentagePriceDeviationFrom: number;
  percentagePriceDeviationTo: number;
  percentageDispenseFeeDeviationFrom: number;
  percentageDispenseFeeDeviationTo: number;
};

export type RecentTreatmentTypeDeviationBulkEditsResponse = {
  treatmentTypeDeviationBulkEditJobs: Array<TreatmentTypeDeviationBulkEditJobDetails>;
};

export type TreatmentTypeDeviationBulkEditJobDetails = {
  treatmentTypeDeviationBulkEditId: number;
  name: string | null;
  scheduledDate: string;
  hasErrored: boolean;
  allComplete: boolean;
  pmsRequestJobId: number;
  canAccessAllSitesOnBulkEdit: boolean;
  creatorsName: string;
  affectedSiteNames: Array<string>;
};

export type TreatmentTypeDeviationBulkEditLineFormModel = {
  deviationId: number | null; // This is not editable in the form, but is passed back to the api so tracked here
  percentagePriceDeviation: string;
  percentageDispenseFeeDeviation: string;
};

export type EditTreatmentTypeDeviationBulkEditFormModel = {
  name: string | null;
  deviations: {
    [siteId: number]: { [treatmentTypeId: number]: TreatmentTypeDeviationBulkEditLineFormModel };
  };
};

export type CreateTreatmentTypeDeviationBulkEditFormModel = EditTreatmentTypeDeviationBulkEditFormModel & {
  runImmediately: boolean;
  scheduledDate: string;
};

export const getTreatmentTypeDeviationFieldPrefix = <
  TRowData extends TreatmentTypeDeviationTableRow
>(
  row: Row<TRowData>,
) =>
  getTreatmentTypeDeviationFieldPrefixBySiteAndTreatmentType(
    row.original.siteId,
    row.original.treatmentTypeId,
  );

export const getTreatmentTypeDeviationFieldPrefixBySiteAndTreatmentType = (
  siteId: number,
  treatmentTypeId: number,
) => `deviations.${siteId}.${treatmentTypeId}`;

export type TreatmentTypeDeviationBulkEditCommandLine = {
  deviationId: number | null;
  siteId: number;
  treatmentTypeId: number;
  percentagePriceDeviation: number;
  percentageDispenseFeeDeviation: number;
};

export type CreateTreatmentTypeDeviationBulkEditCommand = {
  name: string;
  runImmediately: boolean;
  scheduledDate: string;
  deviations: Array<TreatmentTypeDeviationBulkEditCommandLine>;
};

export class CreateTreatmentTypeDeviationBulkEditValidator extends TranslatableValidator<
  CreateTreatmentTypeDeviationBulkEditFormModel
> {
  constructor(t: TFunction, locale: Locale) {
    super(t, locale);
    this.ruleFor('name')
      .notNull()
      .withMessage(t('validation.bulkEditName.empty'))
      .notEmpty()
      .withMessage(t('validation.bulkEditName.empty'))
      .maxLength(InputMaximumLength)
      .withMessage(t('validation.bulkEditName.length'));
    this.ruleFor('scheduledDate')
      .must(date => BeTodayOrLater(date, locale))
      .withMessage(t('validation.scheduledDate.invalid'))
      .when(form => !form.runImmediately);
  }
}

export type EditTreatmentTypeDeviationBulkEditCommand = {
  treatmentTypeDeviationBulkEditId: number;
  name: string | null;
  deviations: Array<TreatmentTypeDeviationBulkEditCommandLine>;
};

export class EditTreatmentTypeDeviationBulkEditValidator extends TranslatableValidator<
  EditTreatmentTypeDeviationBulkEditFormModel
> {
  constructor(t: TFunction, locale: Locale) {
    super(t, locale);
    this.ruleFor('name')
      .notNull()
      .withMessage(t('validation.bulkEditName.empty'))
      .notEmpty()
      .withMessage(t('validation.bulkEditName.empty'))
      .maxLength(InputMaximumLength)
      .withMessage(t('validation.bulkEditName.length'));
  }
}

export class TreatmentTypeDeviationBulkEditLineValidator extends TranslatableValidator<
  TreatmentTypeDeviationBulkEditLineFormModel
> {
  constructor(t: TFunction, locale: Locale) {
    super(t, locale);

    this.ruleFor('percentagePriceDeviation')
      .must(value => BeValidPercent(value, locale))
      .withMessage(t('validation.percentagePriceDeviation.invalidPercent'))
      .must(value => BeAValidDeviatedPercent(value, locale))
      .withMessage(t('validation.percentagePriceDeviation.invalidDeviatedPercent'))
      .must(value =>   value === null || CheckMerlinNegativeMimit(value, locale))
      .withMessage(t('validation.percentagePriceDeviation.greaterThanMinus100'))
      .must(value =>   value === null || CheckMerlinPositiveMimit(value, locale))
      .withMessage(t('validation.percentagePriceDeviation.LessThan9999'));       

    this.ruleFor('percentageDispenseFeeDeviation')
      .must(value => BeValidPercent(value, locale))
      .withMessage(t('validation.percentageDispenseFeeDeviation.invalidPercent'))
      .must(value => BeAValidDeviatedPercent(value, locale))
      .withMessage(t('validation.percentageDispenseFeeDeviation.invalidDeviatedPercent'))
      .must(value =>   value === null || CheckMerlinNegativeMimit(value, locale))
      .withMessage(t('validation.percentageDispenseFeeDeviation.greaterThanMinus100'))
      .must(value =>   value === null || CheckMerlinPositiveMimit(value, locale))
      .withMessage(t('validation.percentageDispenseFeeDeviation.LessThan9999'));       
  }
}

const CheckMerlinPositiveMimit = (value: string | number, locale: Locale) => {
  const parsedNumber = parseNumber(value, locale);
  return !Number.isNaN(parsedNumber) && parsedNumber <= 9999;
};

const CheckMerlinNegativeMimit = (value: string | number, locale: Locale) => {
  const parsedNumber = parseNumber(value, locale);
  return !Number.isNaN(parsedNumber) && parsedNumber >= -100;
};



const BeAValidDeviatedPercent = (value: string | number, locale: Locale) => {
  const parsedNumber = parseNumber(value, locale);
  return !Number.isNaN(parsedNumber) && parsedNumber >= -100;
};

export type UploadTreatmentTypeDeviationResponse = {
  treatmentTypeDeviationBulkEditId?: Id;
} & FileUploadResponse;
