import { IconMapping } from '../../shared/IconRow';
import {
  DeviationResponse,
  DeviationWarning,
  deviationWarnings,
} from './deviation';
import {
  faCoins,
  faCommentSlash,
  faExclamationCircle,
  faExclamationTriangle,
  faMoneyBill,
} from '@fortawesome/free-solid-svg-icons';
import {
  CreateDeviationBulkEditFormModel,
  DeviationBulkEditLineFormModel,
} from './bulkEdit/deviationBulkEdit';
import { Row } from 'react-table';
import { GenericGroupedDeviationRow } from './bulkEdit/SharedDeviationBulkEditColumns';
import { LoginUserResponse } from '../authentication/loginData/user';
import { isEmpty, uniq } from 'lodash';
import {
  rowHasOverwritePrice,
} from './DeviationsBase';
import { getDispenseFeePercentageDeviationFromDeviationResponse, getDispenseFeePercentageDeviationFromValues, getPricePercentageDeviationFromDeviationResponse, getPricePercentageDeviationFromValues} from './deviationCalculations';

export const deviationWarningIconsMapping: IconMapping<DeviationWarning> = {
  NegativePriceDeviationWarning: {
    icon: faExclamationTriangle,
    messageKey: 'groupedWarnings.priceWarning',
  },
  NegativeDispenseFeeDeviationWarning: {
    icon: faExclamationCircle,
    messageKey: 'groupedWarnings.dispenseFeeWarning',
  },
  GroupDifferentPriceWarning: {
    icon: faCoins,
    messageKey: 'groupedWarnings.practiceGroupDifferentPriceWarning',
  },
  GroupDifferentDispenseFeeWarning: {
    icon: faMoneyBill,
    messageKey: 'groupedWarnings.practiceGroupDifferentDispenseFeeWarning',
  },
  OverwritePriceSetWarning: {
    icon: faCommentSlash,
    messageKey: 'groupedWarnings.overwritePriceSetWarning',
  },
  OverwritePriceSetPracticeGroupWarning: {
    icon: faCommentSlash,
    messageKey: 'groupedWarnings.overwritePriceSetPracticeGroupWarning',
  },
};

export const getNegativeDeviationWarningsForEditableScreens = (
  deviations: DeviationBulkEditLineFormModel | null | undefined,
): Array<DeviationWarning> => {
  if (!!deviations) {
    return getNegativeDeviationWarnings(deviations.itemDeviationPriceOverride, 
                                        deviations.treatmentTypeDeviationId,
                                        deviations.percentagePriceDeviation ?  +deviations.percentagePriceDeviation : 0, 
                                        deviations.percentagePriceDeviation ?  +deviations.percentagePriceDeviation : 0,
                                        deviations.itemDeviationDispenseFeeOverride,
                                        deviations.percentageDispenseFeeDeviation ? +deviations.percentageDispenseFeeDeviation : 0,
                                        deviations.percentageDispenseFeeDeviation ? +deviations.percentageDispenseFeeDeviation : 0);
  } else {
    return [];
  }
};

export const getOverwritePriceWarnings = (row: Row<DeviationResponse>): Array<DeviationWarning> => {
  return !!row && rowHasOverwritePrice(row) ? [deviationWarnings.OverwritePriceSetWarning] : [];
};

export const getOverwritePriceWarningsForPracticeGroups = (
  row: Row<GenericGroupedDeviationRow<DeviationResponse>>,
): Array<DeviationWarning> => {
  return !!row && row.subRows.some(subRow => rowHasOverwritePrice(subRow))
    ? [deviationWarnings.OverwritePriceSetPracticeGroupWarning]
    : [];
};


export const getDeviationWarningsForPracticeGroups = (
  row: Row<GenericGroupedDeviationRow<DeviationResponse>>
): Array<DeviationWarning> => {
  return !!row ? row.subRows.flatMap(subRow =>  getNegativeDeviationWarningsFromDeviationResponse(subRow.original)) : [];
};


export const getViewPagePracticeGroupWarnings = (
  row: Row<GenericGroupedDeviationRow<DeviationResponse>>,
  user: LoginUserResponse,
): Array<DeviationWarning> => {
  return (
    Array.from(new Set(getDeviationWarningsForPracticeGroups(row).concat(
      getWarningsForPracticeGroupsWithDifferentPricesAcrossSites(row, user),
      getWarningsForPracticeGroupsWithDifferentDispenseFeesAcrossSites(row, user),
      getOverwritePriceWarningsForPracticeGroups(row),
  ))));
};

export const getNegativeDeviationWarnings = (
  itemDeviationPriceOverride : boolean,
  treatmentTypeDeviationId : number | null,
  priceDeviationPercentValue : number,
  defaultPriceDeviationPercentValue : number,
  itemDeviationDispenseFeeOverride : boolean,
  dispenseFeeDeviationPercentValue : number,
  defaulDispenseFeeDeviationPercentValue : number,
): Array<DeviationWarning> => {
  const deviationWarningsList = new Array<DeviationWarning>();

  if (getPricePercentageDeviationFromValues(itemDeviationPriceOverride, treatmentTypeDeviationId, priceDeviationPercentValue, defaultPriceDeviationPercentValue) < 0) {
    deviationWarningsList.push(deviationWarnings.NegativePriceDeviationWarning);
  }
  if (getDispenseFeePercentageDeviationFromValues(itemDeviationDispenseFeeOverride, treatmentTypeDeviationId, dispenseFeeDeviationPercentValue, defaulDispenseFeeDeviationPercentValue)< 0) {
    deviationWarningsList.push(deviationWarnings.NegativeDispenseFeeDeviationWarning);
  }

  return deviationWarningsList;
};

export const getNegativeDeviationWarningsFromDeviationResponse = (deviationDetails : DeviationResponse): Array<DeviationWarning> => {
  const deviationWarningsList = new Array<DeviationWarning>();

  if (getPricePercentageDeviationFromDeviationResponse(deviationDetails) < 0) {
    deviationWarningsList.push(deviationWarnings.NegativePriceDeviationWarning);
  }
  if (getDispenseFeePercentageDeviationFromDeviationResponse(deviationDetails)< 0) {
    deviationWarningsList.push(deviationWarnings.NegativeDispenseFeeDeviationWarning);
  }

  return deviationWarningsList;
};

export const getPracticeGroupWarningsForEditableScreens = (
  formikValues: CreateDeviationBulkEditFormModel | null,
  row: Row<GenericGroupedDeviationRow<DeviationResponse>>,
): Array<DeviationWarning> => {
  const deviations = formikValues?.deviations;

  if (!deviations || Object.keys(deviations).length === 0) {
    return [];
  }

  return uniq(
    row.original.deviations?.flatMap(deviationResponse => {
      return getNegativeDeviationWarningsForEditableScreens(
        deviations[deviationResponse.siteId][deviationResponse.itemId],
      );
    }),
  )
    .concat(
      getWarningsForPracticeGroupsWithDifferentPricesAcrossSitesWhenEditing(row, formikValues),
      getWarningsForPracticeGroupsWithDifferentDispenseFeesAcrossSitesWhenEditing(
        row,
        formikValues,
      ),
      getOverwritePriceWarningsForPracticeGroups(row),
    )
    .filter(warning => !!warning);
};

export const getWarningsForDeviationRowForEditableScreens = (
  formikValues: CreateDeviationBulkEditFormModel | null,
  row: Row<DeviationResponse>,
): Array<DeviationWarning> => {
  return getNegativeDeviationWarningsForEditableScreens(
    formikValues?.deviations[row.original.siteId][row.original.itemId],
  ).concat(getOverwritePriceWarnings(row));
};

export const getWarningsForDeviationRowForViewScreens = (
  row: Row<DeviationResponse>,
  user: LoginUserResponse,
): Array<DeviationWarning> => {
 

  return getNegativeDeviationWarningsFromDeviationResponse(row.original).concat(
    getOverwritePriceWarnings(row),
  );
};

export const getWarningsForPracticeGroupsWithDifferentPricesAcrossSites = (
  row: Row<DeviationResponse> | null,
  user: LoginUserResponse,
): Array<DeviationWarning> => {
  if (row) {
    const subRows = row.subRows;
    const itemIds = uniq(subRows.map(subRow => subRow.original.itemId));
    const itemIdsAndPriceDeviations = subRows.map(subRow => ({
      [subRow.original.itemId]: {
        percentDeviation: subRow.values.priceDeviationPercentValue,
        absoluteDeviation: user.useGrossPrices
          ? subRow.values.priceDeviationAbsoluteValueGross
          : subRow.values.priceDeviationAbsoluteValueNet,
      },
    }));
    const sitePricesByItemId = itemIds.map(itemId =>
      itemIdsAndPriceDeviations
        .map(siteItemPrice => siteItemPrice[itemId])
        .filter(price => !!price),
    );
    return sitePricesByItemId.every(itemDeviation =>
      itemDeviation.every(
        deviations =>
          deviations.absoluteDeviation === itemDeviation[0].absoluteDeviation &&
          deviations.percentDeviation === itemDeviation[0].percentDeviation,
      ),
    )
      ? []
      : [deviationWarnings.GroupDifferentPriceWarning];
  }
  return [];
};

export const getWarningsForPracticeGroupsWithDifferentDispenseFeesAcrossSites = (
  row: Row<DeviationResponse> | null,
  user: LoginUserResponse,
): Array<DeviationWarning> => {
  if (row) {
    const subRows = row.subRows;
    const itemIds = uniq(subRows.map(subRow => subRow.original.itemId));
    const itemIdsAndDispenseFeeDeviations = subRows.map(subRow => ({
      [subRow.original.itemId]: {
        percentDeviation: subRow.values.dispenseFeeDeviationPercentValue,
        absoluteDeviation: user.useGrossPrices
          ? subRow.values.dispenseFeeDeviationAbsoluteValueGross
          : subRow.values.dispenseFeeDeviationAbsoluteValueNet,
      },
    }));
    const siteDispenseFeesByItemId = itemIds.map(itemId =>
      itemIdsAndDispenseFeeDeviations
        .map(siteItemDispenseFee => siteItemDispenseFee[itemId])
        .filter(dispenseFee => !!dispenseFee),
    );
    return siteDispenseFeesByItemId.every(itemDeviation =>
      itemDeviation.every(
        deviations =>
          deviations.absoluteDeviation === itemDeviation[0].absoluteDeviation &&
          deviations.percentDeviation === itemDeviation[0].percentDeviation,
      ),
    )
      ? []
      : [deviationWarnings.GroupDifferentDispenseFeeWarning];
  }
  return [];
};

export const getWarningsForPracticeGroupsWithDifferentPricesAcrossSitesWhenEditing = (
  row: Row<DeviationResponse> | null,
  formikValues: CreateDeviationBulkEditFormModel | null,
): Array<DeviationWarning> => {
  if (row && !isEmpty(formikValues?.deviations)) {
    const siteIds = uniq(row.subRows.map(subRow => subRow.original.siteId));
    const itemIds = uniq(row.subRows.map(subRow => subRow.original.itemId));
    const deviatedPricesByItemId = itemIds.map(itemId =>
      siteIds
        .map(siteId => formikValues?.deviations[siteId][itemId]?.deviatedPrice)
        .filter(deviation => !!deviation),
    );
    return deviatedPricesByItemId.every(itemDeviation =>
      Object.values(itemDeviation).every(
        price => price?.toString() === itemDeviation[0]?.toString(),
      ),
    )
      ? []
      : [deviationWarnings.GroupDifferentPriceWarning];
  }
  return [];
};

export const getWarningsForPracticeGroupsWithDifferentDispenseFeesAcrossSitesWhenEditing = (
  row: Row<DeviationResponse> | null,
  formikValues: CreateDeviationBulkEditFormModel | null,
): Array<DeviationWarning> => {
  if (row && !isEmpty(formikValues?.deviations)) {
    const siteIds = uniq(row.subRows.map(subRow => subRow.original.siteId));
    const itemIds = uniq(row.subRows.map(subRow => subRow.original.itemId));
    const deviatedDispenseFeesByItemId = itemIds.map(itemId =>
      siteIds
        .map(siteId => formikValues?.deviations[siteId][itemId]?.deviatedDispenseFee)
        .filter(deviation => !!deviation),
    );
    return deviatedDispenseFeesByItemId.every(itemDeviation =>
      Object.values(itemDeviation).every(
        dispenseFee => dispenseFee?.toString() === itemDeviation[0]?.toString(),
      ),
    )
      ? []
      : [deviationWarnings.GroupDifferentDispenseFeeWarning];
  }
  return [];
};
