import { Id } from '../../models/id';
import { deviationsUrl } from '../../urls';

export const createDeviationBulkEditUrl = (queryParams: string) =>
  `${deviationsUrl()}/bulk-edit${queryParams}`;

export const viewDeviationBulkEditUrl = (deviationBulkEditId: Id) =>
  `${deviationsUrl()}/bulk-edit/view/${deviationBulkEditId}`;

export const editDeviationBulkEditUrl = (deviationBulkEditId: Id) =>
  `${deviationsUrl()}/bulk-edit/edit/${deviationBulkEditId}`;

export const uploadDeviationBulkEditUrl = () => `${deviationsUrl()}/bulk-edit/upload`;
