import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Downshift, { ControllerStateAndHelpers } from 'downshift';
import * as React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  DownshiftChildrenContainer,
  DropdownItemWidth,
  DropdownMenu,
  DropdownMenuContainer,
} from './DropdownComponents';

export type SingleSelectDropdownProps<TItem> = {
  label: string;
  icon?: IconProp;
  options: Array<TItem>;
  itemToString: (item: TItem | null) => string;
  onSelect: (selectedItem: TItem | null, downshift: ControllerStateAndHelpers<TItem>) => void;
  renderItem: (item: TItem, index: number, downshift: ControllerStateAndHelpers<TItem>) => void;
  renderToggleButton: (
    toggleProps: SingleSelectDropdownProps<TItem>,
    downshift: ControllerStateAndHelpers<TItem>,
  ) => JSX.Element | string;
};

export const SingleSelectDropdown = <TItem extends { value: string | number }>(
  props: SingleSelectDropdownProps<TItem>,
) => {
  const [menuLeftOffset, setMenuLeftOffset] = useState<number | undefined>(undefined);
  const menuContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      menuContainerRef.current &&
      menuContainerRef.current.getBoundingClientRect().right + DropdownItemWidth > window.innerWidth
    ) {
      setMenuLeftOffset(DropdownItemWidth - menuContainerRef.current.getBoundingClientRect().width);
    } else {
      setMenuLeftOffset(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuContainerRef.current && menuContainerRef.current.getBoundingClientRect()]);

  return (
    <Downshift itemToString={props.itemToString} onSelect={props.onSelect}>
      {downshift => (
        <DownshiftChildrenContainer {...downshift.getRootProps()} role={undefined}>
          {props.renderToggleButton(props, downshift)}
          <DropdownMenuContainer ref={menuContainerRef}>
            {downshift.isOpen ? (
              <DropdownMenu {...downshift.getMenuProps()} menuLeftOffset={menuLeftOffset}>
                {props.options.map((item, index) => props.renderItem(item, index, downshift))}
              </DropdownMenu>
            ) : null}
          </DropdownMenuContainer>
        </DownshiftChildrenContainer>
      )}
    </Downshift>
  );
};
