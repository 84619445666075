import React from 'react';
import { styled } from '../styling/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { PrimaryButton } from './buttons/Button';

type FilterAccordionBarProps = {
  label: string;
  isOpen: boolean;
  onClick?: () => void;
};

export const FilterAccordionBar = (props: FilterAccordionBarProps) => (
  <FullWidthFilterBar onClick={props.onClick}>
    <span>{props.label}</span>
    <FontAwesomeIcon icon={props.isOpen ? faChevronUp : faChevronDown} />
  </FullWidthFilterBar>
);

export const FullWidthFilterBar = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${styleProps => styleProps.theme.colours.primary};
  color: ${styleProps => styleProps.theme.colours.secondaryTextColour};
  padding: ${styleProps => styleProps.theme.spacing.extraSmall}px;
  font-weight: bold;
  cursor: pointer;
`;

export const FilterForm = styled.form<React.HTMLAttributes<HTMLFormElement>>`
  padding: ${props => props.theme.spacing.small}px;
  margin-bottom: ${props => props.theme.spacing.small}px;
  background-color: ${props => props.theme.colours.componentBackground};
`;

export const AdvancedFiltersButton = styled(PrimaryButton)`
  margin-left: auto;
`;
