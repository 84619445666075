import { flowRight } from 'lodash';
import * as React from 'react';
import { AuthenticatedApiRequest } from '../../models/apiRequest';
import { ApiError } from '../../models/error';
import { ApiErrorBox } from '../errors/ApiErrorBox';
import { LoadingIndicator } from '../LoadingIndicator';
import { makeRequestOnPropsChange, PropsToParamsMapper } from './makeRequestOnPropsChange';
import { ApiRequestProps, withApiRequest } from './withApiRequest';

export type ApiFetchProps<TResponse> = {
  response: TResponse;
};

// This will make an API request when the component is first mounted or when the props to the wrapped component change.
// It will show a loading indicator whilst the request is in progress, an error box if the request fails and the wrapped
// component when the request succeeds.
//
// Takes:
// -  propsToParamsMapper: This is a function that takes the props of the wrapped component and outputs the params for
//     the request
// -  request: API request function
//
// Passes to the wrapped component:
// -  response: The response of the request. As the Wrapped component is only shown when the request is completed then
//     the response object can be treated in the component as always being there.
export const fetchFromApiOnLoad = <TOwnProps, TResponse, TRequestParams>(
  propsToParamsMapper: PropsToParamsMapper<TOwnProps, TRequestParams>,
  request: AuthenticatedApiRequest<TResponse, TRequestParams>,
) => (WrappedComponent: React.ComponentType<TOwnProps & ApiFetchProps<TResponse>>) => {
  class FetchFromApiOnLoad extends React.Component<
    TOwnProps & ApiRequestProps<TResponse, TRequestParams>
  > {
    render() {
      if (this.props.inProgress) {
        return <LoadingIndicator />;
      } else if (this.props.apiError) {
        const error = this.props.apiError as ApiError;
        return <ApiErrorBox error={error} />;
      } else if (this.props.response) {
        const responseProps: ApiFetchProps<TResponse> = {
          response: this.props.response as TResponse,
        };

        const { apiError, makeRequest, inProgress, response, clearError, ...ownProps } = this.props;
        return <WrappedComponent {...(ownProps as TOwnProps)} {...responseProps} />;
      } else {
        return <LoadingIndicator />;
      }
    }
  }

  const enhance = flowRight(
    withApiRequest<TOwnProps, TResponse, TRequestParams>(request),
    makeRequestOnPropsChange<
      TOwnProps & ApiRequestProps<TResponse, TRequestParams>,
      TRequestParams
    >(propsToParamsMapper, (props, params) => props.makeRequest(params)),
  );

  return enhance(FetchFromApiOnLoad);
};
