import { isEqual } from 'lodash';
import { useCallback } from 'react';
import { JsonParam, useQueryParam } from 'use-query-params';

// tslint:disable-next-line:no-any
type CallbackFunction = (...args: Array<any>) => unknown;

export type Filterable<TFilterOptions> = { filterOptions?: TFilterOptions };

export type UseFilteringConfig<TFilterOptions, TGetData extends CallbackFunction> = {
  getData: (filterOptions?: TFilterOptions) => TGetData;
  initialUrlState: Partial<TFilterOptions>;
  urlStateToFilterOptions: (url?: Partial<TFilterOptions>) => TFilterOptions;
  filterOptionsToUrlState: (filterOptions?: TFilterOptions) => Partial<TFilterOptions>;
  pageName: string;
};

export type UseFilteringResult<TFilterOptions, TGetData extends CallbackFunction> = {
  getFilteredData: TGetData;
  onFilteredResponse: (response: Filterable<TFilterOptions>) => void;
  filterOptions?: TFilterOptions;
  setFilterOptions: (filterOptions?: TFilterOptions) => void;
};

export const localStorageFilterOptionsPrefix = 'filterOptions-';

export const useFiltering = <TFilterOptions, TGetData extends CallbackFunction>(
  config: UseFilteringConfig<TFilterOptions, TGetData>,
): UseFilteringResult<TFilterOptions, TGetData> => {
  const getInitialFilterOptions = useCallback<() => Partial<TFilterOptions>>(() => {
    const filterOptionsString = localStorage.getItem(
      `${localStorageFilterOptionsPrefix}${config.pageName}`,
    );
    return filterOptionsString ? JSON.parse(filterOptionsString) : config.initialUrlState;
  }, [config.pageName, config.initialUrlState]);

  const [filter = getInitialFilterOptions(), setFilter] = useQueryParam('filter', JsonParam);

  const getFilteredData = useCallback(config.getData(config.urlStateToFilterOptions(filter)), [
    JSON.stringify(filter),
  ]);

  const onFilteredResponse = (response: Filterable<TFilterOptions>) => {
    if (
      !isEqual(filter, response.filterOptions) &&
      // eslint-disable-next-line eqeqeq
      response.filterOptions != filter // tslint:disable-line:triple-equals
    ) {
      setFilter(config.filterOptionsToUrlState(response.filterOptions), 'replaceIn');
    }
  };

  const setFilterOptionsFunction = (filterOpts?: TFilterOptions) => {
    if (filterOpts) {
      const filterOptionsString = JSON.stringify(filterOpts);
      localStorage.setItem(
        `${localStorageFilterOptionsPrefix}${config.pageName}`,
        filterOptionsString,
      );
    } else {
      localStorage.removeItem(`${localStorageFilterOptionsPrefix}${config.pageName}`);
    }
    setFilter(config.filterOptionsToUrlState(filterOpts), 'pushIn');
  };

  return {
    getFilteredData,
    onFilteredResponse,
    filterOptions: filter,
    setFilterOptions: setFilterOptionsFunction,
  };
};
