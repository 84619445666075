import * as React from 'react';
import styled from 'styled-components';
import { ById } from '../../models/id';
import { DiscountCategory } from './item';
import { ItemLibrary } from './PmsFields/pmsFields';
import { values } from 'lodash';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../shared/buttons/Button';
import {
  SingleSelectDropdown,
  SingleSelectDropdownProps,
} from '../../shared/dropdowns/SingleSelectDropdown';
import { ControllerStateAndHelpers } from 'downshift';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { HollowButton } from '../../shared/buttons/Button';
import { useState, useRef } from 'react';
import { DropdownItem } from '../../shared/dropdowns/DropdownComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArray, FieldArrayRenderProps, ErrorMessage } from 'formik';
import { CheckboxField } from '../../shared/forms/CheckboxField';
import { filter } from 'lodash';

type DiscountCategoryEditProps = {
  discountCategoryOptions: ById<ItemLibrary>;
  itemsDiscountCategories: Array<DiscountCategory>;
  discountEnabled: boolean;
};

type childProp = {
  formikArrayHelper: FieldArrayRenderProps;
};

const NewDiscountContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 10px;
`;
const NewDiscountDiv = styled.div`
  flex-direction: column;
  justify-content: center;
`;
const NewDiscountAmountDiv = styled.div`
  padding-top: 10px;
`;
const NewDiscountLableDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const NewDiscountAmount = styled.input<
  React.HTMLAttributes<HTMLInputElement> & { type: string; name: string }
>`
  width: 40px;
  height: 35px;
  border: 1px solid ${props => props.theme.colours.border};
  border-radius: ${props => props.theme.spacing.extraTiny}px;
`;

const AddUpdateDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 28px;
`;

const CurrentDiscountCell = styled.td`
  text-align: center;
  border: 1px solid ${props => props.theme.colours.border};
  color: ${props => props.theme.colours.infoText};
  font-weight: normal;
`;

const DiscountCategoriesTable = styled.table`
  width: 100%;
  border: 1px solid ${props => props.theme.colours.border};
`;

const DiscountCategoryEditContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing.small}px;
`;

const DiscountErrorDiv = styled.div`
  background-color: #fcd5cd;
  height: 45px;
  color: #ee2b4e;
  border-color: #ee2b4e;
  border-radius: 8px;
  border: 1px solid;
  line-height: 1.8;
  text-align: center;
`;

type CategoryOption = {
  value: string;
  id: number;
};

export const DropdownIcon = styled(FontAwesomeIcon)`
  margin-left: ${props => props.theme.spacing.extraSmall}px;
`;

const CurrentCategoryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.theme.colours.border};
  border-radius: ${props => props.theme.spacing.tiny}px;
  background-color: ${props => props.theme.colours.componentBackground};
  font-weight: bold;
  color: ${props => props.theme.colours.primary};
`;

const SelectContainer = styled(HollowButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-basis: 0;
  height: 45px;
  width: 120px;
  border: 1px solid ${props => props.theme.colours.border};
  border-radius: ${props => props.theme.spacing.tiny}px;
  background-color: ${props => props.theme.colours.componentBackground};
  font-weight: normal;
  color: ${props => props.theme.colours.primary};
`;
const SelectLabel = styled.label`
  color: ${props => props.theme.colours.infoText};
`;

const HeaderLabel = styled.label`
  color: ${props => props.theme.colours.secondaryHighlight};
  padding-bottom: 5px;
`;

export const DiscountCategoryEdit = (props: DiscountCategoryEditProps) => {
  const { t } = useTranslation('item');

  const [isSelected, setSelected] = useState<CategoryOption>({ id: 0, value: '' });
  // const [amount, setAmount] = useState<number>(0);
  const AmountValue = useRef<number>(0);
  const [currentDiscountCategories, setCurrentDiscountCategories] = useState<
    Array<DiscountCategory>
  >(props.itemsDiscountCategories);
  const [discountEnabled, setDiscountEnabled] = useState(props.discountEnabled);

  const removeCategory = (id: number, arrayHelpers: FieldArrayRenderProps) => {
    const categoryArray = currentDiscountCategories.filter(x => x.discountCategoryOptionsId !== id);
    setCurrentDiscountCategories(categoryArray);
    arrayHelpers.form.values.discountCategory = categoryArray;
  };

  const updateItemsDiscountCategories = (arrayHelpers: FieldArrayRenderProps) => {
    const newSelection: DiscountCategory = {
      discountCategoryOptionsId: isSelected.id,
      amount: AmountValue.current,
      optionName: isSelected.value,
    };
    // update existing selection if it exists
    const categoryArray = currentDiscountCategories.filter(
      x => x.discountCategoryOptionsId !== newSelection.discountCategoryOptionsId,
    );
    categoryArray.push(newSelection);
    setCurrentDiscountCategories(categoryArray);
    arrayHelpers.form.values.discountCategory = categoryArray;
  };

  const discountCategoryOptions: Array<CategoryOption> = values(
    filter(props.discountCategoryOptions, x => !x.isDisabled),
  ).map(x => {
    return { value: x.name, id: x.id };
  });

  const handleSelection = (selectedItem: CategoryOption | null) => {
    if (selectedItem != null) {
      setSelected(selectedItem);
    }
  };

  const handleToggleButtonRender = (
    toggleProps: SingleSelectDropdownProps<CategoryOption>,
    downshift: ControllerStateAndHelpers<CategoryOption>,
  ) => (
    <SelectContainer {...downshift.getToggleButtonProps()}>
      <SelectLabel {...downshift.getLabelProps()}>
        {toggleProps.label.substring(0, 7)}
        <DropdownIcon icon={downshift.isOpen ? faChevronUp : faChevronDown} />
      </SelectLabel>
    </SelectContainer>
  );

  const handleItemRender = (
    item: CategoryOption,
    index: number,
    downshift: ControllerStateAndHelpers<CategoryOption>,
  ) => (
    <DropdownItem
      {...downshift.getItemProps({
        item,
        index,
        key: item.value,
      })}
      isHighlighted={downshift.highlightedIndex === index}
      isSelected={item === isSelected}
    >
      {item.value}
    </DropdownItem>
  );
  const CurrentDiscountCategories = (props: childProp) => {
    return (
      <CurrentCategoryContainer>
        <DiscountCategoriesTable>
          <thead>
            <tr>
              <th>
                <HeaderLabel>{t('itemLabels.discountCategory')}</HeaderLabel>
              </th>
              <th>
                <HeaderLabel>{t('itemLabels.discountAmount')}</HeaderLabel>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>{discountCategoryEntries(props.formikArrayHelper)}</tbody>
        </DiscountCategoriesTable>
      </CurrentCategoryContainer>
    );
  };

  const NewDiscountEntry = (props: childProp) => {
    return (
      <NewDiscountContainer>
        <NewDiscountDiv>
          <NewDiscountLableDiv>
            <HeaderLabel>{t('itemLabels.discountCategory')}</HeaderLabel>
          </NewDiscountLableDiv>
          <SingleSelectDropdown
            label={isSelected.value ? isSelected.value : t('itemLabels.discountCategorySelect')}
            options={discountCategoryOptions}
            itemToString={item => (item ? item.value : '')}
            onSelect={handleSelection}
            renderItem={handleItemRender}
            renderToggleButton={handleToggleButtonRender}
          />
        </NewDiscountDiv>
        <NewDiscountDiv>
          <NewDiscountLableDiv>
            <HeaderLabel>{t('itemLabels.discountAmount')}</HeaderLabel>
          </NewDiscountLableDiv>
          <NewDiscountAmountDiv>
            <NewDiscountAmount
              type="text"
              name="discountAmount"
              onChange={e => {
                AmountValue.current = +e.currentTarget.value;
              }}
            />
          </NewDiscountAmountDiv>
        </NewDiscountDiv>
        <AddUpdateDiv>
          <PrimaryButton
            onClick={() => {
              updateItemsDiscountCategories(props.formikArrayHelper);
            }}
          >
            {t('itemLabels.addUpdateSelection')}
          </PrimaryButton>
        </AddUpdateDiv>
      </NewDiscountContainer>
    );
  };

  const DiscountCategoryErrors = (props: { errorMessage: string }) => {
    return <DiscountErrorDiv>{props.errorMessage}</DiscountErrorDiv>;
  };

  const discountCategoryEntries = (arrayHelpers: FieldArrayRenderProps) => {
    return (
      <>
        {currentDiscountCategories.map((x, i) => {
          return (
            <tr key={'currentDiscountCategories' + i.toString()}>
              <CurrentDiscountCell>{x.optionName}</CurrentDiscountCell>
              <CurrentDiscountCell>{x.amount}</CurrentDiscountCell>
              <CurrentDiscountCell>
                <PrimaryButton
                  onClick={() => {
                    removeCategory(x.discountCategoryOptionsId, arrayHelpers);
                  }}
                >
                  {t('itemLabels.removeDiscountCategory')}
                </PrimaryButton>
              </CurrentDiscountCell>
            </tr>
          );
        })}
      </>
    );
  };

  return (
    <>
      <CheckboxField
        name="discountCategoryEnabled"
        label={t('itemLabels.discountCategoryEnabled')}
        labelPosition={'right'}
        onChange={e => {
          setDiscountEnabled(e.valueOf());
        }}
      />
      {discountEnabled && (
        <FieldArray
          name="discountCategory"
          render={arrayHelpers => (
            <DiscountCategoryEditContainer>
              <NewDiscountEntry formikArrayHelper={arrayHelpers} />
              <ErrorMessage name="discountCategory">
                {msg => <DiscountCategoryErrors errorMessage={msg} />}
              </ErrorMessage>
              {(currentDiscountCategories?.length ?? 0) !== 0 && (
                <CurrentDiscountCategories formikArrayHelper={arrayHelpers} />
              )}
            </DiscountCategoryEditContainer>
          )}
        />
      )}
    </>
  );
};
