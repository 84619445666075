import { FormikProps } from 'formik';
import { ReactElement, useContext } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxField } from '../../../shared/forms/CheckboxField';
import { InputField } from '../../../shared/forms/InputField';
import { UserContext } from '../../authentication/loginData/userContext';
import {
  SupplierAndSupplierProductFieldsLoader,
  supplierProductsNamePrefix,
} from './SupplierAndSupplierProductFieldsLoader';
import { ItemSupplierProductResponse } from './supplierProduct';
import { FormFieldSubSection } from '../../../shared/forms/FormContainers';
import { CountryResponse } from '../../authentication/loginData/metadata';
import { CountrySpecificItemDetailsList } from '../item';

type NewOrExistingSupplierProductFieldsProps = {
  formikProps: FormikProps<CountrySpecificItemDetailsList>;
  itemIsServiceType: boolean;
  initialSupplierProduct?: ItemSupplierProductResponse;
  country: CountryResponse;
  index: number;
};

export const NewOrExistingSupplierProductFields = (
  props: NewOrExistingSupplierProductFieldsProps,
) => {
  const { t } = useTranslation('item');
  const { user } = useContext(UserContext);

  const onManuallyEnterProductChange = () => {
    const fieldPrefix = supplierProductsNamePrefix(props.index);
    props.formikProps.setFieldValue(`${fieldPrefix}.supplierCode`, null);
    props.formikProps.setFieldValue(`${fieldPrefix}.supplierProductId`, null);
    props.formikProps.setFieldValue(`${fieldPrefix}.listPrice`, null);
  };

  return (
    <SupplierAndSupplierProductFieldsLoader
      formikProps={props.formikProps}
      initialSupplierProduct={props.initialSupplierProduct}
      itemIsServiceType={props.itemIsServiceType}
      country={props.country}
      index={props.index}
    >
      {(
        supplierField: ReactElement,
        supplierProductField: ReactElement,
        supplierProductListPriceField: ReactElement,
      ) => (
        <>
          {supplierField}
          <CheckboxField
            name={`${supplierProductsNamePrefix(props.index)}.manuallyEnterProduct`}
            label={t('supplierProducts.form.manuallyEnterProduct')}
            labelPosition="right"
            onChange={onManuallyEnterProductChange}
          />
          {props.formikProps.values.countrySpecificItemDetailsList?.[props.index]
            ?.supplierProductDetails?.manuallyEnterProduct ? (
            <FormFieldSubSection>
              <InputField
                name={`${supplierProductsNamePrefix(props.index)}.supplierCode`}
                label={t('supplierProducts.form.supplierCode')}
              />
              {supplierProductListPriceField}
            </FormFieldSubSection>
          ) : (
            <>
              {supplierProductField}
              {supplierProductListPriceField}
            </>
          )}
        </>
      )}
    </SupplierAndSupplierProductFieldsLoader>
  );
};
