import * as React from 'react';
import { useDebouncedFormikField } from '../hooks/useDebouncedFormikField';
import { Checkbox } from './Checkbox';
import { FormField, FormFieldChildProps, LabelPosition } from './FormField';
import { InputFieldProps } from './InputField';

type CheckboxFieldProps = {
  labelPosition?: LabelPosition;
  warning?: boolean;
  testId?: string;
} & InputFieldProps<boolean>;

const InnerCheckboxField = ({
  field,
  form,
  valid,
  invalid,
  warning,
  testId,
  ...inputFieldProps
}: FormFieldChildProps<boolean> & CheckboxFieldProps) => {
  const { value, setValue, flushDebounce } = useDebouncedFormikField({
    field,
    form,
    useDebouncing: inputFieldProps.useDebouncing,
  });

  const onChange = (checked: boolean) => {
    if (inputFieldProps.onChange) {
      inputFieldProps.onChange(checked, form);
    }
    setValue(checked);
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    flushDebounce();
    field.onBlur(e);
  };

  field.value = field.value ?? undefined;

  return (
    <Checkbox
      {...field}
      data-testid={testId}
      valid={valid}
      invalid={invalid}
      warning={warning}
      aria-label={inputFieldProps.label}
      autoComplete={inputFieldProps.autoComplete}
      disabled={inputFieldProps.disabled}
      checked={value || false}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export const CheckboxField = (props: CheckboxFieldProps) => {
  const { autoComplete, disabled, ...formFieldProps } = props;

  return (
    <FormField {...formFieldProps}>
      {formFieldChildProps => <InnerCheckboxField {...formFieldChildProps} {...props} />}
    </FormField>
  );
};
