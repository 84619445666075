import * as React from 'react';
import ItemAudit from './components/ItemAudit';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import { ItemAuditResponse } from './API/ItemAuditResponse';
import { useState } from 'react';
import { useApiRequest } from '../../shared/hooks/useApiRequest';
import { getItemAudit } from './API/supportApi';


type OwnProps = RouteComponentProps<{ itemId: number }> & {};;
type IItemHistoryProps = OwnProps;


export function ItemHistory (props: IItemHistoryProps) {
    const { t } = useTranslation(['support']);
    const [itemAuditData, setItemAuditData] = useState<ItemAuditResponse>({itemAmendments: [], itemDetails: {id: 0, code:'', name: ''}});
    const { makeRequest, inProgress } = useApiRequest(getItemAudit);
  
    const fetchData = () => {
      makeRequest(props.itemId ?? 0).then(result => {
        if (result) {
            setItemAuditData(result);
        }
      });
      
    };

    React.useEffect(() => {
      fetchData();
    }, [props.itemId])

  return (
    <div>
      {itemAuditData.itemDetails.id != 0 && <ItemAudit itemAuditData={itemAuditData}/>}
    </div>
  );
}
