import { omit } from 'lodash';
import { secureGet, securePost, securePostFile } from './apiHttpClient';
import { AuthenticatedApiRequest } from '../models/apiRequest';
import { PagedRequest } from '../shared/tables/pagination/pagination';
import { MerlinGroupsResponse } from '../feature/sites/merlinGroups/merlinGroup';
import { PmsSitesResponse } from '../feature/sites/pms/pmsSite';
import {
  AccessibleSitesResponse,
  ArchiveSiteCommand,
  CreateOrEditSiteCommandWrapper,
  CreateSiteCommand,
  EditSiteCommand,
  FetchSitesByPracticeGroupCommand,
  GetAccessibleSiteCollectionQuery,
  GetSitessDownloadCommand,
  IsEditCommand,
  MakeSiteLiveCommand,
  SiteFilterOptions,
  SiteResponse,
  SitesResponse,
  SyncDeviationsCommand,
  UploadSitesResponse,
} from '../feature/sites/site';
import { FileResponse } from '../models/fileResponse';

export const getSite: AuthenticatedApiRequest<SiteResponse, number> = siteId =>
  secureGet(`sites/${siteId}`);

export const getAccessibleSites: AuthenticatedApiRequest<
  AccessibleSitesResponse,
  GetAccessibleSiteCollectionQuery
> = getAccessibleSiteCollectionQuery =>
  securePost(`sites/accessible`, getAccessibleSiteCollectionQuery);

export const getSites = (
  filterOptions?: SiteFilterOptions,
): AuthenticatedApiRequest<SitesResponse, PagedRequest> => pageRequest =>
  securePost('sites', { ...pageRequest, filterOptions });

export const getArchivedSites: AuthenticatedApiRequest<SitesResponse, PagedRequest> = pageRequest =>
  securePost('sites/archived', pageRequest);

export const getPmsSites: AuthenticatedApiRequest<PmsSitesResponse, void> = () =>
  secureGet('sites/pms');

export const getMerlinGroups: AuthenticatedApiRequest<MerlinGroupsResponse, void> = () =>
  secureGet('sites/merlin-groups');

export const GetSitesByPracticeGroup: AuthenticatedApiRequest<
  SitesResponse,
  FetchSitesByPracticeGroupCommand
> = practiceGroups => securePost('sites/getSitesByPracticeGroup', practiceGroups);

export const createSite: AuthenticatedApiRequest<SiteResponse, CreateSiteCommand> = site =>
  securePost('sites/create', site);

export const editSite: AuthenticatedApiRequest<SiteResponse, EditSiteCommand> = site =>
  securePost('sites/edit', site);

export const archiveSite: AuthenticatedApiRequest<SiteResponse, ArchiveSiteCommand> = site =>
  securePost(`sites/archive`, site);

export const createOrEditSite: AuthenticatedApiRequest<
  SiteResponse,
  CreateOrEditSiteCommandWrapper
> = siteCommand => {
  const createOrEditCommand = siteCommand.command;
  if (IsEditCommand(createOrEditCommand, siteCommand.createOrEditTag)) {
    return editSite(createOrEditCommand);
  } else {
    const createCommand = omit(createOrEditCommand, ['siteId']) as CreateSiteCommand;
    return createSite(createCommand);
  }
};

export const uploadSites: AuthenticatedApiRequest<UploadSitesResponse, FormData> = (
  data: FormData,
) => securePostFile(`sites/upload`, data);

export const makeSiteLive: AuthenticatedApiRequest<void, MakeSiteLiveCommand> = site =>
  securePost('sites/make-live', site);

export const syncDeviations: AuthenticatedApiRequest<void, SyncDeviationsCommand> = site =>
  securePost('sites/sync-deviations', site);

export const getSitesDownload: AuthenticatedApiRequest<FileResponse, GetSitessDownloadCommand> = (
  command: GetSitessDownloadCommand,
) => securePost('sites/download', command.filterOptions || {});
