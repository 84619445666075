import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FixedSizeList } from 'react-window';
import { CurrencyCode } from '../../../models/locale';
import { styled } from '../../../styling/theme';
import { formatCurrency } from '../../../utils/currencyUtils';
import { getSelectedCodes, SelectedCodes } from './select-products/selectedCodes';
import { SupplierListChangeProduct } from './supplierList';
import './supplierlistitems.css';

type ApproveChangedSupplierListItemsProps = {
  changedProducts: Array<SupplierListChangeProduct>;
  approvedChangedProductCodes: SelectedCodes;
  locale: string;
  currency: CurrencyCode | Array<CurrencyCode>;
};

const DisplayValue = styled.span`
  padding-left: 10px;
`;

const WasStyle = styled.span`
  font-style: italic;
  font-size: ${styleProps => styleProps.theme.typography.subtext.fontSize}px;
  margin-top: 0;
  margin-bottom: ${styleProps => styleProps.theme.spacing.tiny}px;
  color: ${styleProps => styleProps.theme.colours.alertText};
  padding-left: 10px;
`;

const DisplayChange = (props: { oldValue: string; newValue: string }) => {
  const { t } = useTranslation('component');
  if (props.oldValue === props.newValue) {
    return <DisplayValue>{props.newValue}</DisplayValue>;
  } else {
    return (
      <DisplayValue>
        {props.newValue}
        <WasStyle>
          {t('changeCell.was', {
            previousValue: props.oldValue,
          })}
        </WasStyle>
      </DisplayValue>
    );
  }
};

export const ApproveChangedSupplierListItems = (props: ApproveChangedSupplierListItemsProps) => {
  const selectedItems = getSelectedCodes(props.approvedChangedProductCodes);
  const selectedChangedItems = props.changedProducts.filter((value, index) => {
    return selectedItems.includes(value.productCode);
  });
  const [change, setChange] = React.useState(false);

  const formatPrice = (price: number | undefined): string =>
    formatCurrency(price ? price : 0, props.locale, props.currency);

  const toDisplayValue = (value: string | number | null | undefined) =>
    value ? value.toString() : '';

  const handleInputChange = (index: number) => {
    props.approvedChangedProductCodes[selectedChangedItems[index].productCode] = !props
      .approvedChangedProductCodes[selectedChangedItems[index].productCode];
    if (index > -1) {
      selectedChangedItems.splice(index, 1);
    }
    setChange(!change);
  };

  const displayRow = ({ index, style }: { index: number; style: React.CSSProperties }) => {
    if (props.approvedChangedProductCodes[selectedChangedItems[index].productCode]) {
      return (
        <div style={style}>
          <input
            type="checkbox"
            checked={props.approvedChangedProductCodes[selectedChangedItems[index].productCode]}
            onChange={() => {
              handleInputChange(index);
            }}
          />
          <DisplayValue>{selectedChangedItems[index].productCode}</DisplayValue>
          <DisplayChange
            oldValue={toDisplayValue(selectedChangedItems[index].oldName)}
            newValue={toDisplayValue(selectedChangedItems[index].newName)}
          />
          <DisplayChange
            oldValue={formatPrice(selectedChangedItems[index].oldPrice)}
            newValue={formatPrice(selectedChangedItems[index].newPrice)}
          />
          <DisplayChange
            oldValue={toDisplayValue(selectedChangedItems[index].oldQuantity)}
            newValue={toDisplayValue(selectedChangedItems[index].newQuantity)}
          />
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <FixedSizeList
        className="virtualsupplierlist"
        height={250}
        itemCount={selectedChangedItems.length}
        itemSize={25}
        width={'100%'}
      >
        {displayRow}
      </FixedSizeList>
    </>
  );
};
