import styled from 'styled-components';

const SearchButtonPosition = styled.div`
  position: relative;
  top: -95px;
  left: 460px;
`;

const GuardWrapper = styled.div`
  margin-left: 200px;
`;

export { SearchButtonPosition, GuardWrapper };
