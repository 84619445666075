import { RouteComponentProps, Router } from '@reach/router';
import { PageNotFound } from '../PageNotFound';
import { DeleteSupplierItems } from '../support/components/DeleteSupplierItems/DeleteSupplierItems';

export const DeleteSupplierItemsSection = (props: RouteComponentProps) => (
  <Router primary={false}>
    <DeleteSupplierItems path="/" />
    <PageNotFound default={true} />
  </Router>
);
