import * as z from 'zod';
import { isValidItemCodesString } from './ResetFailedItems.utils';
import { ResetCreationRequestForm } from './ResetFailedItems.types';

export const schema = z.object({
  codes: z
    .string()
    .min(2, 'Item Codes must be at least 2 characters in length')
    .refine(
      isValidItemCodesString,
      'Items codes may only contain the characters: A-Z, 0-9, %, +, . (period), with codes separated by spaces and commas',
    ),
});

export const defaultValues: ResetCreationRequestForm = {
  codes: '',
};
