import {
  faCity,
  faCog,
  faExchangeAlt,
  faFileCircleExclamation,
  faHeadset,
  faHospital,
  faLayerGroup,
  faShoppingBag,
  faStethoscope,
  faSyringe,
  faTachometerAlt,
  faTrashAlt,
  faTruck,
  faUsers,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import React, { Suspense, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '../../../styling/theme';
import {
  dashboardUrl,
  deleteSupplierItemsUrl,
  deviationsUrl,
  itemsUrl,
  organisationsUrl,
  resetFailedItemsUrl,
  sitesUrl,
  suppliersUrl,
  supportUrl,
  treatmentTypeDeviationsUrl,
  Url,
  usersUrl,
} from '../../../urls';
import { userHasOneOfPermissions } from '../../authentication/loginData/user';
import { UserContext } from '../../authentication/loginData/userContext';
import { Permission, permissions } from '../../authentication/permissions';
import { NavLink } from './NavLink';
import { NavLogo } from './NavLogo';
import { UserInfo } from './UserInfo';
import { NavDropdown } from './NavDropdown';
import { NavIcon } from './NavIcon';

export const NavLinksContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 100%;
  margin-left: 30px;
`;

const Nav = styled.nav`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  justify-self: flex-start;
  align-items: center;
  flex-shrink: 0;
  height: ${props => props.theme.navBar.height}px;
  width: 100%;
  color: ${props => props.theme.colours.primary};
  background-color: ${props => props.theme.colours.componentBackground};
  padding: 0 ${props => props.theme.spacing.large}px;
  position: absolute;
  z-index: 2;
  @media (max-width: ${props => props.theme.smallScreenMaxWidth}px) {
    padding: 0 ${props => props.theme.spacing.medium}px;
  }
`;

const LogoAndNavLinksContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
`;

type NavItem = {
  translationKey: string;
  icon: IconDefinition;
  url: Url;
  oneOfPermissions?: Array<Permission>;
};

export type NavDropdownItem = NavItem & {
  value: string;
};

const NavLinks = () => {
  const { t } = useTranslation('layout');
  const { user } = useContext(UserContext);

  const navItems: Array<NavItem> = [
    {
      translationKey: 'dashboard',
      icon: faTachometerAlt,
      url: dashboardUrl(),
    },
    {
      translationKey: 'suppliers',
      icon: faTruck,
      url: suppliersUrl(),
      oneOfPermissions: [permissions.SuperAdmin, permissions.CentralAdmin, permissions.CentralView],
    },
    {
      translationKey: 'items',
      icon: faShoppingBag,
      url: itemsUrl(),
      oneOfPermissions: [
        permissions.SuperAdmin,
        permissions.CentralAdmin,
        permissions.CentralView,
        permissions.SitePriceAdmin,
        permissions.SiteVisibilityAdmin,
      ],
    },
  ].filter(
    navItem =>
      navItem.oneOfPermissions == null || userHasOneOfPermissions(user, navItem.oneOfPermissions),
  );

  const deviationsNavDropdownItems: Array<NavDropdownItem> = [
    {
      translationKey: 'itemDeviations',
      value: 'itemDeviations',
      icon: faShoppingBag,
      url: deviationsUrl(),
    },
    {
      translationKey: 'treatmentTypeDeviations',
      value: 'treatmentTypeDeviations',
      icon: faSyringe,
      url: treatmentTypeDeviationsUrl(),
    },
  ];

  const adminNavDropdownItems: Array<NavDropdownItem> = [
    {
      translationKey: 'organisations',
      value: 'organisations',
      icon: faCity,
      url: organisationsUrl(),
      oneOfPermissions: [permissions.SuperAdmin],
    },
    {
      translationKey: 'sites',
      value: 'sites',
      icon: faHospital,
      url: sitesUrl(),
      oneOfPermissions: [permissions.SuperAdmin, permissions.CentralAdmin, permissions.CentralView],
    },
    {
      translationKey: 'users',
      value: 'users',
      icon: faUsers,
      url: usersUrl(),
      oneOfPermissions: [
        permissions.SuperAdmin,
        permissions.CentralUserAdmin,
        permissions.SiteUserAdmin,
      ],
    },
  ].filter(
    adminNavDropdownItem =>
      adminNavDropdownItem.oneOfPermissions == null ||
      userHasOneOfPermissions(user, adminNavDropdownItem.oneOfPermissions),
  );

  const supportDropdownItems: Array<NavDropdownItem> = [
    {
      translationKey: 'supportOptions.pmsQueueStatus',
      value: 'supportOptions.pmsQueueStatus',
      icon: faStethoscope,
      url: supportUrl('status'),
      oneOfPermissions: [permissions.IVCAdmin],
    },
    {
      translationKey: 'supportOptions.hangfireJob',
      value: 'supportOptions.hangfireJob',
      icon: faLayerGroup,
      url: supportUrl('hangfire-job'),
      oneOfPermissions: [permissions.IVCAdmin],
    },
    {
      translationKey: 'supportOptions.deleteSupplierItems',
      value: 'deleteSupplierItems',
      icon: faTrashAlt,
      url: deleteSupplierItemsUrl(),
      oneOfPermissions: [permissions.IVCAdmin],
    },
    {
      translationKey: 'supportOptions.resetFailedItems',
      value: 'resetFailedItems',
      icon: faFileCircleExclamation,
      url: resetFailedItemsUrl(),
      oneOfPermissions: [permissions.IVCAdmin],
    },
    /*     {
      translationKey: 'supportOptions.itemHistory',
      value: 'supportOptions.itemHistory',
      icon: faHistory,
      url: supportUrl('itemHistory'),
      oneOfPermissions: [permissions.IVCAdmin],
    }, */
  ].filter(
    supportDropdownItems =>
      supportDropdownItems.oneOfPermissions == null ||
      userHasOneOfPermissions(user, supportDropdownItems.oneOfPermissions),
  );

  return (
    <NavLinksContainer>
      {navItems.map(navItem => (
        <NavLink to={navItem.url} icon={navItem.icon} key={navItem.url}>
          {t(navItem.translationKey)}
        </NavLink>
      ))}
      <NavDropdown items={deviationsNavDropdownItems}>
        <NavIcon icon={faExchangeAlt} />
        {t('deviations')}
      </NavDropdown>
      {adminNavDropdownItems === undefined || adminNavDropdownItems.length === 0 ? null : (
        <NavDropdown items={adminNavDropdownItems}>
          <NavIcon icon={faCog} />
          {t('admin')}
        </NavDropdown>
      )}
      {supportDropdownItems === undefined || supportDropdownItems.length === 0 ? null : (
        <NavDropdown items={supportDropdownItems}>
          <NavIcon icon={faHeadset} />
          {t('powerPac')}
        </NavDropdown>
      )}
    </NavLinksContainer>
  );
};

export const Header = () => {
  return (
    <Nav>
      <Suspense fallback={<></>}>
        <LogoAndNavLinksContainer>
          <NavLogo />
          <NavLinks />
        </LogoAndNavLinksContainer>
        <UserInfo />
      </Suspense>
    </Nav>
  );
};
