import baseStyled, {
  css as baseCss,
  ThemedCssFunction,
  ThemedStyledInterface,
} from 'styled-components/macro';

const palette = {
  green: '#3D9035',
  greenDarker: '#31732A',
  greenLighter: '#72C86A',
  greenLightest: '#EEF5ED',
  darkSlateBlue: '#065B6F',
  darkSlateBlueLighter: '#6F8899',
  darkSlateBlueLightest: '#E6EFF0',
  darkSlateBlueGrey: '#596F7A',
  lightGrey: '#DCDDDE',
  lightGreyLighter: '#F8F8F8',
  white: '#FFFFFF',
  lightOrangeLightest: '#FDF2ED',
  lightOrange: '#F07E43',
  raspberryRedLightest: '#FCD5CD',
  raspberryRed: '#EE2B4E',
  wireframeBlack: '#212529',
  wireframeBlackLighter: '#4d5154',
  irishGreen: '#EEF5ED',
  farmBlue: '#d7e5f2',
};

const fontFamilies = {
  theSansC5: 'Verdana, sans-serif',
};

export const theme = {
  colours: {
    primary: palette.green,
    primaryHighlight: palette.greenDarker,
    secondary: palette.darkSlateBlue,
    secondaryHighlight: palette.darkSlateBlueLighter,
    secondaryInactive: palette.lightGrey,
    componentBackground: palette.white,
    appBackground: palette.lightGreyLighter,
    irelandAppBackGround: palette.irishGreen,
    farmAppBackGround: palette.farmBlue,
    border: palette.lightGrey,
    infoBackground: palette.darkSlateBlueLightest,
    infoText: palette.darkSlateBlueGrey,
    successBackground: palette.greenLightest,
    successText: palette.greenDarker,
    warningBackground: palette.lightOrangeLightest,
    warningText: palette.lightOrange,
    alertBackground: palette.raspberryRedLightest,
    alertText: palette.raspberryRed,
    secondaryTextColour: palette.white,
    buttons: {
      primary: palette.wireframeBlack,
      primaryHighlight: palette.wireframeBlackLighter,
      primaryTextColour: palette.white,
      secondary: palette.lightGreyLighter,
      secondaryHighlight: palette.darkSlateBlueGrey,
      secondaryTextColour: palette.wireframeBlack,
      toggle: {
        selected: palette.green,
        selectedHighlight: palette.greenLighter,
        selectedTextColour: palette.white,
        unselected: palette.lightGrey,
        unselectedHighlight: palette.lightGreyLighter,
        unselectedTextColour: palette.darkSlateBlueLighter,
      },
    },
    loadingOverlay: palette.lightGreyLighter,
    expandableTableRow: palette.lightGreyLighter,
    deviatedValue: {
      calculated: palette.lightGrey,
    },
  },
  spacing: {
    extraTiny: 4,
    tiny: 8,
    extraSmall: 15,
    small: 30,
    medium: 50,
    large: 100,
    extraLarge: 200,
  },
  navBar: {
    height: 70,
    iconHeight: 16,
  },
  formInputWidth: {
    max: 450,
    medium: 450,
    small: 150,
    tiny: 90,
  },
  smallScreenMaxWidth: 1200,
  typography: {
    theSansC5FontFamily: fontFamilies.theSansC5,
    headings: {
      fontWeight: 'bold',
      fontFamily: fontFamilies.theSansC5,
      lineHeight: 1.4,
      marginBottom: '0.5em',
      h1fontSize: 22,
      h2fontSize: 20,
      h3fontSize: 19,
      h4fontSize: 18,
      h5fontSize: 17,
      h6fontSize: 16,
    },
    content: {
      fontWeight: 'normal',
      fontFamily: fontFamilies.theSansC5,
      lineHeight: 1.4,
      fontSize: 16,
    },
    subtext: {
      fontWeight: 'normal',
      fontFamily: fontFamilies.theSansC5,
      lineHeight: 1.4,
      fontSize: 14,
    },
    small: {
      fontWeight: 'normal',
      fontFamily: fontFamilies.theSansC5,
      lineHeight: 1.4,
      fontSize: 10,
    },
  },
};

export type Theme = typeof theme;

export const styled = baseStyled as ThemedStyledInterface<Theme> & extendedProps;
export const css = baseCss as ThemedCssFunction<Theme>;

type extendedProps = {
  children?: React.ReactElement<any>;
  src?: string;
  onSubmit?: (...args: any) => any;
  onReset?: (...args: any) => any;
};

// export const FilterForm = styled.form<{
//   children: React.ReactElement<any>;
//   onSubmit: (...args: any) => any;
//   onReset: (...args: any) => any;
// }>``;

// interface FilterFormProps {
//   children: React.ReactNode;
// }
