import { NameAndId } from '../deviation';
import { Id } from '../../../models/id';
import { ComparisonType } from '../comparisonType';
import { Filterable } from '../../../shared/tables/filtering/useFiltering';
import { PagedRequest } from '../../../shared/tables/pagination/pagination';
import { TableDataFetchResponse } from '../../../shared/tables/Table';
import { TreatmentTypeDeviationBulkEditLineResponse } from './bulkEdit/treatmentTypeDeviationBulkEdit';
import { TreatmentTypeDeviationWarning } from './TreatmentTypeDeviationsWarnings';

export const treatmentTypeDeviationsPageSize = 50;

export type TreatmentTypeDeviationFilterOptions = {
  siteIds: Array<Id> | null;
  practiceGroupIds: Array<Id> | null;
  treatmentTypeIds: Array<Id> | null;
  centralPrice: ComparisonType | null;
  dispenseFee: ComparisonType | null;
};

export type TreatmentTypeDeviationFilterFormModel = {
  siteIds: Array<Id> | null;
  practiceGroupIds: Array<Id> | null;
  treatmentTypeIds: Array<Id> | null;
  isAboveCentralPrice: boolean | null;
  isBelowCentralPrice: boolean | null;
  isSameAsCentralPrice: boolean | null;
  isAboveCentralDispenseFee: boolean | null;
  isBelowCentralDispenseFee: boolean | null;
  isSameAsCentralDispenseFee: boolean | null;
};

export type TreatmentTypeDeviationFilterDropdownOptionsResponse = {
  sites: Array<NameAndId>;
  practiceGroups: Array<NameAndId>;
};

export type TreatmentTypeDeviationTableRow = {
  deviationId: Id | null;
  treatmentTypeId: Id;
  treatmentTypeName: string;
  siteId: Id;
  siteName: string;
  practiceGroupIds: Array<Id>;
};

export type TreatmentTypeDeviationResponse = TreatmentTypeDeviationTableRow & {
  priceDeviationPercentValue: number;
  dispenseFeeDeviationPercentValue: number;
};

export type TreatmentTypeDeviationScheduledBulkEdit = {
  treatmentTypeId: number;
  siteId: number;
  scheduledDeviationBulkEditId: number;
};

export type TreatmentTypeDeviationResponsesAndScheduledBulkEdits = {
  deviationResponses: Array<TreatmentTypeDeviationResponse>;
  scheduledBulkEdits: Array<TreatmentTypeDeviationScheduledBulkEdit>;
};

export type TreatmentTypeDeviationsResponse = {
  deviationResponsesAndScheduledBulkEdits: TreatmentTypeDeviationResponsesAndScheduledBulkEdits;
  filterOptions: TreatmentTypeDeviationFilterOptions;
} & TableDataFetchResponse<TreatmentTypeDeviationColumnTypes>;

export type GetTreatmentTypeDeviationsCommand = Filterable<TreatmentTypeDeviationFilterOptions> &
  PagedRequest;

export type GetTreatmentTypeDeviationsDownloadCommand = Filterable<
  TreatmentTypeDeviationFilterOptions
>;

export type GroupedTreatmentTypeDeviationTableRow = GenericGroupedTreatmentTypeDeviationRow<
  TreatmentTypeDeviationTableRow
>;

export type GroupedTreatmentTypeDeviationResponseRow = GenericGroupedTreatmentTypeDeviationRow<
  TreatmentTypeDeviationResponse
>;

export type GroupedTreatmentTypeDeviationBulkEditRow = GenericGroupedTreatmentTypeDeviationRow<
  TreatmentTypeDeviationBulkEditLineResponse
>;

export type GenericGroupedTreatmentTypeDeviationRow<
  T extends TreatmentTypeDeviationTableRow
> = T & {
  practiceGroupId?: Id;
  treatmentTypeId?: Id;
  deviations?: Array<T>;
};

export type TreatmentTypeDeviationColumnTypes =
  | 'expanded'
  | 'treatmentTypeName'
  | 'siteName'
  | 'priceDeviationPercentValue'
  | 'dispenseFeeDeviationPercentValue'
  | 'warnings'
  | 'actions';

export const validTreatmentTypeDeviationColumnTypeOrUndefined = (
  input: string,
): TreatmentTypeDeviationColumnTypes | undefined =>
  input === 'treatmentTypeName' ||
  input === 'siteName' ||
  input === 'priceDeviationPercentValue' ||
  input === 'dispenseFeeDeviationPercentValue' ||
  input === 'warnings'
    ? input
    : undefined;
