import { z } from 'zod';
import { isValidSupplierProductCode } from './DeleteSupplierItems.utils';
import { DeleteSupplierProductForm } from './DeleteSupplierItems.types';

export const schema = z.object({
  code: z
    .string()
    .min(2, 'Supplier-product Code must be at least 2 characters in length')
    .refine(
      isValidSupplierProductCode,
      'Supplier-product codes must be between 2-55 characters in length and may only contain the characters: A-Z, a-z, 0-9, space, period, parentheses, hyphen, and single quote.',
    ),
});

export const defaultValues: DeleteSupplierProductForm = {
  code: '',
};
