import { RouteComponentProps } from '@reach/router';
import { flowRight } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { AlertButton } from '../../shared/buttons/Button';
import { DataLoader } from '../../shared/DataLoader';
import { ApiErrorBox } from '../../shared/errors/ApiErrorBox';
import {
  ApiFetchProps,
  fetchFromApiOnLoad,
} from '../../shared/higher-order-components/fetchFromApiOnLoad';
import { useApiRequest } from '../../shared/hooks/useApiRequest';
import { IfUserHasOneOfPermissions, requiresOneOfPermissions } from '../../shared/Permissions';
import { shouldShowSuccess } from '../../shared/success/SuccessBox';
import { suppliersUrl } from '../../urls';
import { navigate } from '../../utils/routing';
import { permissions } from '../authentication/permissions';
import { PageHeading } from '../layout/PageHeading';
import { SupplierResponse } from './supplier';
import { archiveSupplier, getSupplier, getSuppliersFromPms } from './supplierApi';
import { SupplierForm } from './SupplierForm';

type OwnProps = RouteComponentProps<{ supplierId: string }> & {};
type Props = OwnProps & ApiFetchProps<SupplierResponse>;

export const EditSupplierComponent = (props: Props) => {
  const { t } = useTranslation('supplier');
  const [existingSupplier, setExistingSupplier] = useState(props.response);
  const { makeRequest, inProgress, apiError } = useApiRequest(archiveSupplier);

  const onSuccess = (response: SupplierResponse) => setExistingSupplier(response);
  const onArchiveButtonClick = () => {
    if (window.confirm(t('editForm.confirmArchival'))) {
      makeRequest(existingSupplier.supplierId).then(result => {
        if (result) {
          navigate(suppliersUrl(), { state: { success: true } });
        }
      });
    }
  };

  return (
    <>
      <Title>{t('editForm.title')}</Title>
      <PageHeading>
        <h1>{t('editForm.heading')}</h1>
        <IfUserHasOneOfPermissions permissions={[permissions.SuperAdmin, permissions.CentralAdmin]}>
          {!existingSupplier.isArchived && (
            <AlertButton type="button" onClick={onArchiveButtonClick} loading={inProgress}>
              {t('editForm.archiveButton')}
            </AlertButton>
          )}
        </IfUserHasOneOfPermissions>
      </PageHeading>
      {apiError && <ApiErrorBox error={apiError} />}
      <DataLoader apiRequest={getSuppliersFromPms}>
        {suppliers => (
          <SupplierForm
            suppliersFromPms={suppliers.response}
            existingSupplier={existingSupplier}
            showSuccess={shouldShowSuccess()}
            onSuccess={onSuccess}
          />
        )}
      </DataLoader>
    </>
  );
};

const fetchFromApiOnLoadEnhancer = fetchFromApiOnLoad<OwnProps, SupplierResponse, number>(
  props => Number(props.supplierId),
  getSupplier,
);

const enhance = flowRight(
  requiresOneOfPermissions(permissions.SuperAdmin, permissions.CentralAdmin),
  fetchFromApiOnLoadEnhancer,
);

export const EditSupplier = enhance(EditSupplierComponent);
