import { RouteComponentProps, Router } from '@reach/router';
import { PageNotFound } from '../PageNotFound';
import { ResetFailedItems } from './ResetFailedItems/ResetFailedItems';

export const ResetFailedItemsSection = (props: RouteComponentProps) => (
  <Router primary={false}>
    <ResetFailedItems path="/" />
    <PageNotFound default={true} />
  </Router>
);
