import * as React from 'react';
import { Link } from '../navigation/Link';
import { ButtonProps, HollowButton, PrimaryButton } from './Button';

type LinkButtonProps = ButtonProps & { to: string };
export const PrimaryLinkButton = ({ to, ...props }: LinkButtonProps) => (
  <Link to={to} tabIndex={-1}>
    <PrimaryButton {...props} />
  </Link>
);

export const HollowLinkButton = ({ to, ...props }: LinkButtonProps) => (
  <Link to={to} tabIndex={-1}>
    <HollowButton {...props} />
  </Link>
);
