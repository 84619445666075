export type SelectedCodes = { [code: string]: boolean };

export const getAllCodesSelected = (codes: Array<string>): SelectedCodes =>
  getAllCodesWithValue(codes, true);

export const withAllCodesSelected = (
  selectedCodes: SelectedCodes,
  codes?: Array<string>,
): SelectedCodes => getCodesWithValue(selectedCodes, codes || Object.keys(selectedCodes), true);

export const withAllCodesUnselected = (
  selectedCodes: SelectedCodes,
  codes?: Array<string>,
): SelectedCodes => getCodesWithValue(selectedCodes, codes || Object.keys(selectedCodes), false);

const getAllCodesWithValue = (codes: Array<string>, value: boolean): SelectedCodes => {
  const selectedCodes: { [code: string]: boolean } = {};
  for (const code of codes) {
    selectedCodes[code] = value;
  }
  return selectedCodes;
};

export const getCodesWithValue = (
  selectedCodes: SelectedCodes,
  codes: Array<string>,
  value: boolean,
): SelectedCodes => {
  const newSelectedCodes: { [code: string]: boolean } = {};
  for (const code of Object.keys(selectedCodes)) {
    newSelectedCodes[code] = codes.indexOf(code) !== -1 ? value : selectedCodes[code];
  }
  return newSelectedCodes;
};

export const areAllSelected = (selectedCodes: SelectedCodes, codes?: Array<string>): boolean => {
  const codesToCheck = codes || Object.keys(selectedCodes);
  return codesToCheck.every(code => selectedCodes[code]);
};

export const areAllUnselected = (selectedCodes: SelectedCodes, codes?: Array<string>): boolean => {
  const codesToCheck = codes || Object.keys(selectedCodes);
  return codesToCheck.every(code => !selectedCodes[code]);
};

export const getSelectedCodes = (selectedCodes: SelectedCodes): Array<string> =>
  Object.keys(selectedCodes).filter(code => selectedCodes[code]);
