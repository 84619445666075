import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryLinkButton } from '../../shared/buttons/LinkButton';
import { styled } from '../../styling/theme';

const Container = styled.div`
  padding: ${props => props.theme.spacing.medium}px;
`;

const LoggedOutHeader = styled.h1`
  color: ${props => props.theme.colours.primary};
`;

const StyledButton = styled(PrimaryLinkButton)`
  display: inline-block;
  margin-top: ${props => props.theme.spacing.medium}px;
`;

export const LoggedOut = (props: RouteComponentProps) => {
  const { t } = useTranslation('loggedOut');
  return (
    <Container>
      <LoggedOutHeader>{t('header')}</LoggedOutHeader>
      <StyledButton to="/">{t('returnToSite')}</StyledButton>
    </Container>
  );
};
