import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { FileUploadForm } from '../../shared/files/FileUploadForm';
import { RequiresOneOfPermissions } from '../../shared/Permissions';
import { itemsUrl } from '../../urls';
import { assertIsDefined } from '../../utils/assertIsDefined';
import { permissions } from '../authentication/permissions';
import { UploadItemsResponse } from './item';
import { uploadItems } from './itemsApi';

export type UploadItemsFormModel = {};

export const UploadItems = (props: RouteComponentProps) => {
  const { t } = useTranslation('item');
  return (
    <RequiresOneOfPermissions permissions={[permissions.SuperAdmin, permissions.CentralAdmin]}>
      <Title>{t('upload.title')}</Title>
      <h1>{t('upload.heading')}</h1>
      <FileUploadForm<UploadItemsFormModel, UploadItemsResponse>
        initialValues={{}}
        validate={() => ({})}
        filePropertyName="file"
        fileFieldLabel={t('upload.labels.file')}
        request={uploadItems}
        onSuccess={() => {
          assertIsDefined(props.navigate)(itemsUrl());
        }}
      />
    </RequiresOneOfPermissions>
  );
};
