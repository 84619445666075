import { includes, map } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { DelimitedArrayParam, useQueryParam } from 'use-query-params';
// import { DelimitedNumericArrayParam } from 'use-query-params/lib/params';
import { DelimitedNumericArrayParam } from 'use-query-params';
import { getValidDropdownOptionsForDropdownValues } from './dropdownHelpers';

type UseMultiSelectDropdownConfig<TItem> = {
  validOptions: Array<TItem>;
  initiallySelected?: Array<TItem>;
  storeInUrl?: boolean;
};
export type UseMultiSelectDropdownResult<TItem, TValue> = {
  selectedOptions: Array<TItem>;
  deselectedValues: Array<TValue>;
  setSelectedOptions: (selectedOptions: Array<TItem>) => void;
  onOptionSelect: (selectedOptions: Array<TItem>) => void;
  isOptionSelected: (optionValue: TValue) => boolean;
};

export type BaseDropDownType<TValue> = {
  value: TValue;
};

const urlStateToSelectedOptions = <TValue, TItem extends BaseDropDownType<TValue>>(
  validOptions: Array<TItem>,
  urlState: Array<TValue>,
  initialSelectedItems: Array<TItem> = [],
): Array<TItem> => {
  if (urlState === undefined) {
    return initialSelectedItems;
  }

  return getValidDropdownOptionsForDropdownValues(validOptions, urlState);
};

export const useMultiSelectDropdown = <
  TItem extends BaseDropDownType<TValue>,
  TValue = string | number
>(
  config: UseMultiSelectDropdownConfig<TItem>,
): UseMultiSelectDropdownResult<TItem, TValue> => {
  const [selectedValues, setSelectedValues] = useQueryParam<Array<TValue>>(
    'selectedValues',
    typeof config.validOptions[0].value === 'number'
      ? (DelimitedNumericArrayParam as any)
      : DelimitedArrayParam,
  );

  const [selectedOptions, setSelectedOptions] = useState<Array<TItem>>(
    config.storeInUrl && selectedValues instanceof Array
      ? urlStateToSelectedOptions(config.validOptions, selectedValues, config.initiallySelected)
      : config.initiallySelected || [],
  );

  if (selectedValues === undefined && config.storeInUrl && selectedOptions.length) {
    setSelectedValues(
      map(selectedOptions, (item: TItem) => item.value),
      'replaceIn',
    );
  }

  const deselectedValues = useMemo(
    () =>
      config.validOptions
        .map(option => option.value)
        .filter(value => !includes(selectedValues, value)),
    [config.validOptions, selectedValues],
  );

  const onOptionSelect = useCallback((selectedItems: Array<TItem>): void => {
    setSelectedOptions(selectedItems);
    if (config.storeInUrl) {
      setSelectedValues(
        map(selectedItems, item => item.value),
        'replaceIn',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isOptionSelected = (optionValue: TValue): boolean =>
    selectedOptions.some((option: TItem) => option.value === optionValue);

  return {
    selectedOptions,
    deselectedValues,
    setSelectedOptions,
    isOptionSelected,
    onOptionSelect,
  };
};
