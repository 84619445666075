import * as React from 'react';
import { InputHTMLAttributes, useRef } from 'react';
import { BaseInputProps } from './BaseInputStyling';
import { RightSymbolInput, RightSymbolPanel, SymbolInputContainer } from './SymbolInput';

export type Props = BaseInputProps &
  InputHTMLAttributes<HTMLInputElement> & {
    isRightAligned?: boolean;
    onBlurIfChange?: React.FocusEventHandler<HTMLInputElement>;
  };

export const PercentInput = ({ value, onBlur, onFocus, onBlurIfChange, ...props }: Props) => {
  const currentVisitRef = useRef<null | string>(null);

  const onComponentFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    currentVisitRef.current = event.target.value;
    onFocus?.(event);
  };

  const onComponentBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value !== currentVisitRef.current) {
      onBlurIfChange?.(event);
    }
    onBlur?.(event);
    currentVisitRef.current = null;
  };

  return (
    <SymbolInputContainer bulkVariant={props.bulkVariant}>
      <RightSymbolInput
        {...props}
        onFocus={onComponentFocus}
        onBlur={onComponentBlur}
        value={value || ''}
        isRightAligned={props.isRightAligned}
        autoComplete={'off'}
      />
      <RightSymbolPanel
        bulkVariant={props.bulkVariant}
        disabled={props.disabled}
        invalid={props.invalid}
        warning={props.warning}
        inputSize={props.inputSize}
      >
        <span>%</span>
      </RightSymbolPanel>
    </SymbolInputContainer>
  );
};
