import { RouteComponentProps, Router } from '@reach/router';
import * as React from 'react';
import { PageNotFound } from '../PageNotFound';
import { CreateUser } from './CreateUser';
import { EditUser } from './EditUser';
import { Users } from './Users';

export const UsersSection = (props: RouteComponentProps) => (
  <Router primary={false}>
    <Users path="/" />
    <CreateUser path="create" />
    <EditUser path="edit/:userId" />
    <PageNotFound default={true} />
  </Router>
);
