import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { DataLoader } from '../../shared/DataLoader';
import { RequiresOneOfPermissions } from '../../shared/Permissions';
import { permissions } from '../authentication/permissions';
import { ItemForm } from './ItemForm';
import { getItemFieldsFromPms } from './itemsApi';
import { ReturnToItemsListLink } from './ViewItem';

type OwnProps = RouteComponentProps & {};
type Props = OwnProps;

export const CreateItem = (props: Props) => {
  const { t } = useTranslation('item');
  return (
    <RequiresOneOfPermissions permissions={[permissions.SuperAdmin, permissions.CentralAdmin]}>
      <ReturnToItemsListLink text={t('createForm.returnToListButton')} />
      <Title>{t('createForm.title')}</Title>
      <h1>{t('createForm.heading')}</h1>
      <DataLoader apiRequest={getItemFieldsFromPms}>
        {itemFields => <ItemForm itemFieldsFromPms={itemFields.response} />}
      </DataLoader>
    </RequiresOneOfPermissions>
  );
};
