import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../feature/authentication/loginData/userContext';
import { styled } from '../../../styling/theme';
import { formatNumber } from '../../../utils/numberUtils';
import { BaseInputStyling } from '../../forms/BaseInputStyling';

export const paginationJumpToPageTestId = 'pagination-jump-to-page-test-id';

type PaginationJumpToProps = {
  atTop?: boolean;
  atBottom?: boolean;
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
  loading: boolean;
  reactTablePagination: {
    gotoPage: (page: number) => void;
    canPreviousPage: boolean;
    canNextPage: boolean;
  };
};

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  valid?: boolean;
  invalid?: boolean;
  isLoading?: boolean;
};

const PaginationContainer = styled.div<
  React.HTMLAttributes<HTMLDivElement> & { atTop?: boolean; atBottom?: boolean }
>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-weight: normal;
  background-color: ${props => props.theme.colours.componentBackground};
  border-collapse: collapse;
  border: 1px solid ${props => props.theme.colours.border};
  padding: ${props => props.theme.spacing.tiny}px;

  border-bottom: ${props => props.atTop && `none`};
  border-top: ${props => props.atBottom && `none`};
`;

const JumpToPageText = styled.div`
  margin-right: ${props => props.theme.spacing.tiny}px;
  margin-left: ${props => props.theme.spacing.tiny}px;
  min-height: ${props => props.theme.typography.content.lineHeight}em;
`;

const JumpToPageInputContainer = styled.div<
  React.HTMLAttributes<HTMLDivElement> & { atTop?: boolean; atBottom?: boolean }
>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-weight: normal;
  background-color: ${props => props.theme.colours.componentBackground};
  border-collapse: collapse;

  border-bottom: ${props => props.atTop && `none`};
  border-top: ${props => props.atBottom && `none`};
`;

const JumpToPageInput = styled.input<InputProps>`
  ${BaseInputStyling};
  width: 64px;
  height: 16px;
  text-align: center;
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: button;
    margin: 0 ${props => -props.theme.spacing.extraSmall}px 0 0;
  }
  opacity: ${props =>
    props.isLoading &&
    0}; // Opacity is used instead of "display: none" to maintain focus after re-render
`;

export const PaginationJumpToPage = (props: PaginationJumpToProps) => {
  const { t } = useTranslation('component');
  const [pageToJumpTo, setPageToJumpTo] = useState('1');
  const { user } = useContext(UserContext);

  useEffect(() => setPageToJumpTo(props.pageNumber.toString()), [props.pageNumber]);

  const totalPages = props.totalPages;

  const handleJumpToPage = () => {
    if (pageToJumpTo === '') {
      return;
    }
    const page = parseInt(pageToJumpTo, 10);
    if (page < 1) {
      setPageToJumpTo('1');
      props.reactTablePagination.gotoPage(1);
    } else if (page > totalPages) {
      setPageToJumpTo(totalPages.toString());
      props.reactTablePagination.gotoPage(totalPages);
    } else {
      props.reactTablePagination.gotoPage(page);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageToJumpTo(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleJumpToPage();
    }
  };

  return (
    <PaginationContainer
      data-testid={paginationJumpToPageTestId}
      atBottom={props.atBottom}
      atTop={props.atTop}
    >
      <JumpToPageText>
        {!props.loading && `${t('component:paginationJumpToPage.jumpToPageText')} `}
      </JumpToPageText>

      <JumpToPageInputContainer>
        <JumpToPageInput
          type="number"
          aria-label={
            props.loading
              ? t('component:paginationJumpToPage.pageLoading')
              : t('component:paginationJumpToPage.jumpToPageField')
          }
          value={pageToJumpTo}
          placeholder={props.pageNumber.toString()}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          onBlur={handleJumpToPage}
          isLoading={props.loading}
        />
      </JumpToPageInputContainer>

      <JumpToPageText>
        {!props.loading &&
          t('component:paginationJumpToPage.ofTotalPages', {
            totalPages: formatNumber(totalPages, user.locale),
          })}
      </JumpToPageText>
    </PaginationContainer>
  );
};
