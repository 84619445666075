import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteComponentProps } from '@reach/router';
import { TFunction } from 'i18next';
import { map } from 'lodash';
import * as React from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { Id } from '../../../models/id';
import { DataLoader } from '../../../shared/DataLoader';
import { FileUploadForm } from '../../../shared/files/FileUploadForm';
import { SingleSelectDropdownField } from '../../../shared/forms/SingleSelectDropdownField';
import { RequiresOneOfPermissions } from '../../../shared/Permissions';
import { styled } from '../../../styling/theme';
import { assertIsDefined } from '../../../utils/assertIsDefined';
import { TranslatableValidator } from '../../../utils/validation/TranslatableValidator';
import { permissions } from '../../authentication/permissions';
import { UploadSupplierListResponse } from './supplierList';
import { getSuppliersForSupplierList, uploadSupplierList } from './supplierListApi';
import { reviewSupplierListUrl } from './supplierListUrls';

const InfoTextContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.colours.primary};
  font-size: ${props => props.theme.spacing.medium}px;
  margin-right: ${props => props.theme.spacing.extraSmall}px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
`;

const Text = styled.p`
  margin: 0;
`;

const InfoText = (props: { t: TFunction }) => (
  <InfoTextContainer>
    <StyledIcon icon={faInfoCircle} />
    <TextContainer>
      <Text>{props.t('upload.infoText')}</Text>
      <Text>{props.t('upload.infoTextOptional')}</Text>
    </TextContainer>
  </InfoTextContainer>
);

export type UploadSupplierListFormModel = {
  supplierId: Id | null;
};

class Validator extends TranslatableValidator<UploadSupplierListFormModel> {
  constructor(t: TFunction) {
    super(t);
    this.ruleFor('supplierId')
      .notNull()
      .withMessage(t('upload.validation.supplierId.empty'));
  }
}

export const UploadSupplierList = (props: RouteComponentProps) => {
  const { t } = useTranslation('supplierList');
  return (
    <RequiresOneOfPermissions permissions={[permissions.SuperAdmin, permissions.CentralAdmin]}>
      <DataLoader apiRequest={getSuppliersForSupplierList}>
        {supplierProps => (
          <>
            <Title>{t('upload.title')}</Title>
            <h1>{t('upload.heading')}</h1>
            <InfoText t={t} />
            <FileUploadForm<UploadSupplierListFormModel, UploadSupplierListResponse>
              initialValues={{ supplierId: null }}
              validate={values => {
                const validator = new Validator(t);
                return validator.validate(values);
              }}
              filePropertyName="supplierListFile"
              fileFieldLabel={t('upload.labels.file')}
              request={uploadSupplierList}
              onSuccess={response => {
                assertIsDefined(props.navigate)(reviewSupplierListUrl(response.supplierListId));
              }}
              renderAdditionalFormFields={formikBag => (
                <>
                  <SingleSelectDropdownField
                    name="supplierId"
                    label={t('upload.labels.supplier')}
                    options={map(supplierProps.response.suppliers, supplier => ({
                      displayText: supplier.supplierName,
                      value: supplier.supplierId,
                    }))}
                  />
                </>
              )}
            />
          </>
        )}
      </DataLoader>
    </RequiresOneOfPermissions>
  );
};
