import * as React from 'react';
import { AuthenticatedApiRequest } from '../../models/apiRequest';
import { useApiRequest, UseApiRequestResult } from '../hooks/useApiRequest';

export type ApiRequestProps<TResponse, TRequestParams> = UseApiRequestResult<
  TResponse,
  TRequestParams
>;

type ApiRequestWrapperProps<TResponse, TRequestParams> = {
  request: AuthenticatedApiRequest<TResponse, TRequestParams>;
  children: (props: ApiRequestProps<TResponse, TRequestParams>) => React.ReactNode;
};

export const ApiRequestWrapper = <TResponse, TRequestParams>(
  props: ApiRequestWrapperProps<TResponse, TRequestParams>,
) => {
  const requestProps = useApiRequest(props.request);

  return <>{props.children(requestProps)}</>;
};

// Takes an API request function and will pass through to the wrapped component:
// -  The request function (so you can add any handling to the promise you wish)
// -  The API response (when it is returned)
// -  Whether the request is in progress
// -  The error, if the API request fails
export const withApiRequest = <TOwnProps, TResponse, TRequestParams>(
  request: AuthenticatedApiRequest<TResponse, TRequestParams>,
) => (
  WrappedComponent: React.ComponentType<TOwnProps & ApiRequestProps<TResponse, TRequestParams>>,
) => (props: TOwnProps) => (
  <ApiRequestWrapper request={request}>
    {requestProps => <WrappedComponent {...props} {...requestProps} />}
  </ApiRequestWrapper>
);
