import { FormikProps } from 'formik';
import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrencyField } from '../../shared/forms/CurrencyField';
import {
  FormFieldGroup,
  FormFieldGroupContainer,
  FormGroupTitle,
} from '../../shared/forms/FormContainers';
import { InputField } from '../../shared/forms/InputField';
import { PercentField } from '../../shared/forms/PercentField';
import { SingleSelectDropdownField } from '../../shared/forms/SingleSelectDropdownField';
import { UserContext } from '../authentication/loginData/userContext';
import { CreateOrEditItemFormModel, ItemResponse } from './item';
import {
  ItemFieldsFromPmsResponse,
  itemLibraryDropdownOptions,
  ItemPriceField,
} from './PmsFields/pmsFields';
import { CountryResponse } from '../authentication/loginData/metadata';
import { DiscountCategoryEdit } from './DiscountCategoryEdit';

type PricingDetailsGroupProps = {
  itemFieldsFromPms: ItemFieldsFromPmsResponse;
  existingItem?: ItemResponse;
  formikProps: FormikProps<CreateOrEditItemFormModel>;
  isServiceType: boolean;
  priceField: ItemPriceField | null;
  userCountries: Array<CountryResponse>;
};

export const PricingDetailsGroup = (props: PricingDetailsGroupProps) => {
  const { t } = useTranslation('item');
  const { user } = useContext(UserContext);

  const getDisplayCurrencies = () =>
    user.useGrossPrices ? user.currency : props.userCountries.map(countries => countries.currency);

  return (
    <FormFieldGroupContainer>
      <FormGroupTitle>{t('itemLabels.pricingDetailsGroupTitle')}</FormGroupTitle>
      <FormFieldGroup>
        {props.priceField === 'RecommendedPrice' && (
          <CurrencyField
            name="recommendedPrice"
            label={
              user.useGrossPrices
                ? t('createAndEditForm.labels.recommendedPriceGross')
                : t('createAndEditForm.labels.recommendedPriceNet')
            }
            currency={getDisplayCurrencies()}
          />
        )}
        {!props.isServiceType && (
          <InputField name="unitsPerPack" label={t('itemLabels.unitsPerPack')} type="number" />
        )}
        {props.priceField === 'CentralMarkupPercentage' && (
          <InputField
            name="centralMarkupPercentage"
            label={t('createAndEditForm.labels.centralMarkupPercentage')}
            type="number"
          />
        )}
        {props.priceField === 'Discount' &&
          (props.existingItem == null || props.existingItem.percentageDiscount != null) && (
            <InputField
              name="percentageDiscount"
              label={t('createAndEditForm.labels.percentageDiscount')}
              type="number"
            />
          )}
        {props.priceField === 'Discount' &&
          (props.existingItem == null || props.existingItem.absoluteDiscountNet != null) && (
            <CurrencyField
              name="absoluteDiscount"
              label={
                user.useGrossPrices
                  ? t('createAndEditForm.labels.absoluteDiscountGross')
                  : t('createAndEditForm.labels.absoluteDiscountNet')
              }
              currency={getDisplayCurrencies()}
            />
          )}
        {!props.isServiceType && (
          <CurrencyField
            name="dispenseFee"
            label={
              user.useGrossPrices
                ? t('createAndEditForm.labels.dispenseFeeGross')
                : t('createAndEditForm.labels.dispenseFeeNet')
            }
            currency={getDisplayCurrencies()}
          />
        )}
        {!props.isServiceType && (
          <CurrencyField
            name="minimumPrice"
            label={
              user.useGrossPrices
                ? t('createAndEditForm.labels.minimumPriceGross')
                : t('createAndEditForm.labels.minimumPriceNet')
            }
            currency={getDisplayCurrencies()}
          />
        )}

        {!props.isServiceType && (
          <PercentField
            name="netNetDiscount"
            label={t('createAndEditForm.labels.netNetDiscount')}
          />
        )}

        {!props.isServiceType && (
          <PercentField
            name="netNetNetDiscount"
            label={t('createAndEditForm.labels.netNetNetDiscount')}
          />
        )}

        <SingleSelectDropdownField
          name="costTypeId"
          label={t('itemLabels.costType')}
          options={itemLibraryDropdownOptions(props.itemFieldsFromPms.costTypeOptions)}
        />
        <DiscountCategoryEdit
          discountEnabled={
            props.existingItem?.discountCategoryEnabled == null
              ? false
              : props.existingItem.discountCategoryEnabled
          }
          discountCategoryOptions={props.itemFieldsFromPms.discountCategoryOptions}
          itemsDiscountCategories={
            props.existingItem?.discountCategory == null ? [] : props.existingItem.discountCategory
          }
        />
      </FormFieldGroup>
    </FormFieldGroupContainer>
  );
};
