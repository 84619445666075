import React, { useContext, useEffect, useMemo, useState } from 'react';
import { debounce, flowRight, isEmpty } from 'lodash';
import {
  withUserContext,
  WithUserContextProps,
} from '../../../shared/higher-order-components/withUserContext';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { FormikProps, withFormik } from 'formik';
import {
  checkboxesToComparisonType,
  comparisonIsEqualTo,
  comparisonIsGreaterThan,
  comparisonIsLessThan,
} from '../comparisonType';
import {
  Category1OptionsNoDiscount,
  ItemFieldsFromPmsResponse,
} from '../../items/PmsFields/pmsFields';
import {
  TreatmentTypeDeviationFilterDropdownOptionsResponse,
  TreatmentTypeDeviationFilterFormModel,
  TreatmentTypeDeviationFilterOptions,
} from './treatmentTypeDeviations';
import { TranslatableValidator } from '../../../utils/validation/TranslatableValidator';
import { TFunction } from 'i18next';
import { Locale } from '../../../models/locale';
import { NameAndIdToDropdown } from '../DeviationsBase';
import { AdvancedFiltersButton, FilterAccordionBar, FilterForm } from '../../../shared/Filter';
import { styled } from '../../../styling/theme';
import { MultiSelectDropdownField } from '../../../shared/forms/MultiSelectDropdownField';
import { Expandable } from '../../../shared/Expandable';
import { CheckboxField } from '../../../shared/forms/CheckboxField';
import { ButtonGroup, HollowButton, PrimaryButton } from '../../../shared/buttons/Button';
import { DropdownOption } from '../../../shared/forms/DropdownField';
import { UserContext } from '../../authentication/loginData/userContext';
import { useApiRequest } from '../../../shared/hooks/useApiRequest';
import { GetSitesByPracticeGroup } from '../../../api/sitesApi';
import { NavIcon } from '../../layout/header/NavIcon';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const SiteSpinnerWrapper = styled.div`
  position: relative;
`;

const SiteSpinner = styled.div`
  position: absolute;
  left: 50px;
`;

type OwnProps = {
  existingFilters?: TreatmentTypeDeviationFilterOptions;
  onApply: (response: TreatmentTypeDeviationFilterOptions) => void;
  dropdownOptions: TreatmentTypeDeviationFilterDropdownOptionsResponse;
  itemFieldsFromPms: ItemFieldsFromPmsResponse;
};

type Props = OwnProps & FormikProps<TreatmentTypeDeviationFilterFormModel>;

const TreatmentTypeDeviationsFilterFormComponent = (props: Props) => {
  const { t } = useTranslation('treatmentTypeDeviations');
  const { user } = useContext(UserContext);

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [loading, setLoading] = useState<Boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<Array<number>>([]);
  const [sitesForDropdown, setSitesForDropdown] = useState<Array<DropdownOption<number>>>(
    NameAndIdToDropdown(props.dropdownOptions.sites),
  );

  const { makeRequest, inProgress, clearError, apiError } = useApiRequest(GetSitesByPracticeGroup);

  const clearFields = () => {
    props.setValues({
      siteIds: null,
      practiceGroupIds: null,
      treatmentTypeIds: null,
      isBelowCentralPrice: false,
      isSameAsCentralPrice: false,
      isAboveCentralPrice: false,
      isBelowCentralDispenseFee: false,
      isSameAsCentralDispenseFee: false,
      isAboveCentralDispenseFee: false,
    });
  };

  const practiceGroupsForDropdown = NameAndIdToDropdown(props.dropdownOptions.practiceGroups);

  const onSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    props.handleSubmit(e);
    if (isEmpty(props.errors)) {
      setShowAdvancedFilters(false);
    }
  };

  /**
   * Get the sites for the filter or show all
   */
  useEffect(() => {
    if (props.existingFilters?.practiceGroupIds) {
      setSelectedOptions(props.existingFilters?.practiceGroupIds);
    }
  }, []);

  /**
   * Use Effect to allow debouncing on the change event for the Practice Group DDL,
   * We dont want to hit the server everytime a option is selected as multiple options
   * might be wanted. So debounce for a second to allow the user to apply whatever
   * filter they want - and then query the API. The timeout might need to be adjusted
   * to fit correctly.
   */
  useEffect(() => {
    const fetchSites = async () => {
      makeRequest({
        PracticeGroups: selectedOptions,
      }).then(result => {
        if (result) {
          setSitesForDropdown(
            result.sites.map(m => {
              return {
                displayText: m.siteName,
                value: m.siteId,
              };
            }),
          );
        }
      });

      setLoading(false);
    };
    // Debounced API call
    const debouncedFetchSites = debounce(fetchSites, 1000); // Delay of 1 second
    debouncedFetchSites(); // Call API after the delay when options are selected

    // Cleanup function to cancel the debounce on unmount
    return () => debouncedFetchSites.cancel();
  }, [selectedOptions]);

  const handlePracticeGroupChange = (event: Array<number>) => {
    setLoading(true);
    setSelectedOptions(event);
  };

  return (
    <>
      <FilterAccordionBar
        label={t('view.filter.label')}
        isOpen={showAdvancedFilters}
        onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
      />
      <FilterForm onSubmit={onSubmit} onReset={props.handleReset}>
        <FlexRowContainer>
          <MultiSelectDropdownField
            name="treatmentTypeIds"
            label={t('view.filter.treatmentType')}
            options={Category1OptionsNoDiscount(props.itemFieldsFromPms.category1Options)}
          />
          <MultiSelectDropdownField
            name="practiceGroupIds"
            label={t('view.filter.practiceGroups')}
            options={practiceGroupsForDropdown}
            onChange={values => handlePracticeGroupChange(values as Array<number>)}
          />
          <SiteSpinnerWrapper>
            <SiteSpinner>{loading && <NavIcon icon={faSpinner} className="fa-spin" />}</SiteSpinner>
            <MultiSelectDropdownField
              name="siteIds"
              label={t('view.filter.sites')}
              options={sitesForDropdown}
            />
          </SiteSpinnerWrapper>
        </FlexRowContainer>
        <Expandable isExpanded={showAdvancedFilters}>
          <h4>{t('view.filter.aboveBelowSameAsCentralPriceTitle')}</h4>
          <FlexRowContainer>
            <FlexCheckboxField
              name="isAboveCentralPrice"
              label={t('view.filter.aboveCentralPrice')}
            />
            <FlexCheckboxField
              name="isSameAsCentralPrice"
              label={t('view.filter.sameAsCentralPrice')}
            />
            <FlexCheckboxField
              name="isBelowCentralPrice"
              label={t('view.filter.belowCentralPrice')}
            />
          </FlexRowContainer>
          <h4>{t('view.filter.aboveBelowSameAsCentralDispenseFeeTitle')}</h4>
          <FlexRowContainer>
            <FlexCheckboxField
              name="isAboveCentralDispenseFee"
              label={t('view.filter.aboveCentralDispenseFee')}
            />
            <FlexCheckboxField
              name="isSameAsCentralDispenseFee"
              label={t('view.filter.sameAsCentralDispenseFee')}
            />
            <FlexCheckboxField
              name="isBelowCentralDispenseFee"
              label={t('view.filter.belowCentralDispenseFee')}
            />
          </FlexRowContainer>
        </Expandable>
        <FlexButtonGroup>
          <HollowButton onClick={clearFields} type="button" disabled={props.isSubmitting}>
            {t('view.filter.clearFieldsButton')}
          </HollowButton>
          <PrimaryButton type="submit" loading={props.isSubmitting}>
            {t('view.filter.applyButton')}
          </PrimaryButton>
          <AdvancedFiltersButton
            onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
            type="button"
            disabled={props.isSubmitting}
          >
            {showAdvancedFilters
              ? t('view.filter.hideAdvancedFilters')
              : t('view.filter.showAdvancedFilters')}
          </AdvancedFiltersButton>
        </FlexButtonGroup>
      </FilterForm>
    </>
  );
};

const FlexRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const FlexButtonGroup = styled(ButtonGroup)`
  flex: 1 0 100%;
  align-self: center;
  margin-bottom: 0;
  margin-top: ${props => props.theme.spacing.small}px;
`;

const FlexCheckboxField = styled(CheckboxField)`
  flex: 1 0 25%;
`;

const withUserContextEnhancer = withUserContext();

const withTranslationsEnhancer = withTranslation('treatmentTypeDeviations');

const withFormikEnhancer = withFormik<
  OwnProps & WithUserContextProps & WithTranslation,
  TreatmentTypeDeviationFilterFormModel
>({
  enableReinitialize: true,
  handleSubmit: (values, { props, setSubmitting }) => {
    props.onApply({
      siteIds: values.siteIds ?? null,
      practiceGroupIds: values.practiceGroupIds ?? null,
      treatmentTypeIds: values.treatmentTypeIds,
      centralPrice: checkboxesToComparisonType(
        values.isAboveCentralPrice,
        values.isSameAsCentralPrice,
        values.isBelowCentralPrice,
      ),
      dispenseFee: checkboxesToComparisonType(
        values.isAboveCentralDispenseFee,
        values.isSameAsCentralDispenseFee,
        values.isBelowCentralDispenseFee,
      ),
    });
    setSubmitting(false);
  },
  mapPropsToValues: props => {
    return {
      siteIds:
        props.existingFilters && props.existingFilters.siteIds
          ? props.existingFilters.siteIds
          : null,
      practiceGroupIds:
        props.existingFilters && props.existingFilters.practiceGroupIds
          ? props.existingFilters.practiceGroupIds
          : null,
      treatmentTypeIds: props.existingFilters ? props.existingFilters.treatmentTypeIds : null,
      isBelowCentralPrice: props.existingFilters
        ? comparisonIsLessThan(props.existingFilters.centralPrice)
        : null,
      isSameAsCentralPrice: props.existingFilters
        ? comparisonIsEqualTo(props.existingFilters.centralPrice)
        : null,
      isAboveCentralPrice: props.existingFilters
        ? comparisonIsGreaterThan(props.existingFilters.centralPrice)
        : null,
      isBelowCentralDispenseFee: props.existingFilters
        ? comparisonIsLessThan(props.existingFilters.dispenseFee)
        : null,
      isSameAsCentralDispenseFee: props.existingFilters
        ? comparisonIsEqualTo(props.existingFilters.dispenseFee)
        : null,
      isAboveCentralDispenseFee: props.existingFilters
        ? comparisonIsGreaterThan(props.existingFilters.dispenseFee)
        : null,
    };
  },
  validate: (values, props) => {
    const validator = new TreatmentTypeDeviationsFilterFormValidator(props.t, props.user.locale);
    return validator.validate(values);
  },
});

const enhance = flowRight(withUserContextEnhancer, withTranslationsEnhancer, withFormikEnhancer);

export const TreatmentTypeDeviationsFilterForm: React.ComponentType<OwnProps> = enhance(
  TreatmentTypeDeviationsFilterFormComponent,
);

class TreatmentTypeDeviationsFilterFormValidator extends TranslatableValidator<
  TreatmentTypeDeviationFilterFormModel
> {
  constructor(t: TFunction, locale: Locale) {
    super(t, locale);
  }
}
