import { RouteComponentProps } from '@reach/router';
import { flowRight } from 'lodash';
import { useContext, useState } from 'react';
import * as React from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { AlertButton } from '../../shared/buttons/Button';
import {
  ApiFetchProps,
  fetchFromApiOnLoad,
} from '../../shared/higher-order-components/fetchFromApiOnLoad';
import { useApiRequest } from '../../shared/hooks/useApiRequest';
import { requiresOneOfPermissions } from '../../shared/Permissions';
import { shouldShowSuccess } from '../../shared/success/SuccessBox';
import { UserContext } from '../authentication/loginData/userContext';
import { permissions } from '../authentication/permissions';
import { PageHeading } from '../layout/PageHeading';
import { UserResponse } from './user';
import { UserForm } from './UserForm';
import { disableUser, getUser } from './usersApi';

type OwnProps = RouteComponentProps<{ userId: string }> & {};
type Props = OwnProps & ApiFetchProps<UserResponse>;

const EditUserComponent = (props: Props) => {
  const { t } = useTranslation('user');
  const [existingUser, setExistingUser] = useState(props.response);
  const { makeRequest, inProgress } = useApiRequest(disableUser);
  const { user } = useContext(UserContext);

  const onSuccess = (response: UserResponse) => setExistingUser(response);

  const onDisableButtonClick = () => {
    makeRequest({ userId: existingUser.id, userIsDisabled: !existingUser.isDisabled }).then(
      result => {
        if (result) {
          onSuccess(result);
        }
      },
    );
  };

  return (
    <>
      <Title>{t('editForm.title')}</Title>
      <PageHeading>
        <h1>
          {existingUser.name + ' ' + (existingUser.isDisabled ? t('editForm.userIsDisabled') : '')}
        </h1>
        <AlertButton
          type="button"
          onClick={onDisableButtonClick}
          name="userIsDisabled"
          loading={inProgress}
        >
          {existingUser.isDisabled
            ? t('editForm.labels.enableButton')
            : t('editForm.labels.disableButton')}
        </AlertButton>
      </PageHeading>
      <UserForm
        existingUser={existingUser}
        currentUser={user}
        showSuccess={shouldShowSuccess()}
        onSuccess={onSuccess}
      />
    </>
  );
};

const fetchFromApiOnLoadEnhancer = fetchFromApiOnLoad<OwnProps, UserResponse, number>(
  props => Number(props.userId),
  getUser,
);

const enhance = flowRight(
  requiresOneOfPermissions(
    permissions.SuperAdmin,
    permissions.CentralUserAdmin,
    permissions.SiteUserAdmin,
  ),
  fetchFromApiOnLoadEnhancer,
);

export const EditUser = enhance(EditUserComponent);
