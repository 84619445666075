import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { RouteComponentProps } from '@reach/router';
import { UserContext } from '../../../authentication/loginData/userContext';
import { DataLoaderWithParams } from '../../../../shared/DataLoader';
import { getAllPracticeGroups } from '../../../sites/practiceGroups/practiceGroupsApi';
import { PracticeGroupResponse } from '../../../sites/practiceGroups/practiceGroup';
import { useTranslation } from 'react-i18next';
import { useApiRequest } from '../../../../shared/hooks/useApiRequest';
import {
  cancelTreatmentTypeDeviationBulkEdit,
  editTreatmentTypeDeviationBulkEdit,
  getTreatmentTypeDeviationBulkEdit,
} from '../../deviationsApi';
import {
  GroupedTreatmentTypeDeviationBulkEditRow,
  TreatmentTypeDeviationColumnTypes,
  treatmentTypeDeviationsPageSize,
} from '../treatmentTypeDeviations';
import { useNavigateAwayWarning } from '../../../../shared/hooks/useNavigateAwayWarning';
import { flatMap, forEach, keyBy, map, mapValues, some } from 'lodash';
import { CustomColumn } from '../../../../shared/tables/Table';
import { TFunction } from 'i18next';
import { LoginUserResponse, userHasOneOfPermissions } from '../../../authentication/loginData/user';
import {
  getGroupedTreatmentTypeDeviationRowDispenseFeePercentageTestId,
  getGroupedTreatmentTypeDeviationRowPricePercentageTestId,
  getTreatmentTypeDeviationHeaderFieldProps,
  getTreatmentTypeDeviationRowDispenseFeePercentageTestId,
  getTreatmentTypeDeviationRowPricePercentageTestId,
  groupedActionsColumn,
  groupedWarningsColumnForEditablePages,
  treatmentTypeDeviationBulkEditValueHasChanged,
  treatmentTypeDeviationExpandedColumn,
  treatmentTypeDeviationGroupedPracticeGroupOrSiteNameColumn,
  treatmentTypeDeviationHeaderValueHasChanged,
  treatmentTypeDeviationTreatmentTypeNameColumn,
} from '../SharedTreatmentTypeDeviationBulkEditColumns';
import { CellProps } from 'react-table';
import { ControlledInput } from '../../../../shared/forms/ControlledInput';
import { PercentInput } from '../../../../shared/forms/PercentInput';
import {
  createEditTreatmentTypeDeviationsPermissions,
  EditTreatmentTypeDeviationBulkEditCommand,
  EditTreatmentTypeDeviationBulkEditFormModel,
  EditTreatmentTypeDeviationBulkEditValidator,
  getTreatmentTypeDeviationFieldPrefix,
  TreatmentTypeDeviationBulkEditCommandLine,
  TreatmentTypeDeviationBulkEditLineValidator,
  TreatmentTypeDeviationBulkEditResponse,
} from './treatmentTypeDeviationBulkEdit';
import { PercentField } from '../../../../shared/forms/PercentField';
import { formatNumberForFields, parseNumber } from '../../../../utils/numberUtils';
import { navigate } from '../../../../utils/routing';
import { viewTreatmentTypeDeviationBulkEditUrl } from '../treatmentTypeDeviationsUrls';
import { assertIsDefined } from '../../../../utils/assertIsDefined';
import { dashboardUrl } from '../../../../urls';
import { LoadingIndicator } from '../../../../shared/LoadingIndicator';
import { RequiresOneOfPermissions } from '../../../../shared/Permissions';
import { Title } from 'react-head';
import { PageHeading } from '../../../layout/PageHeading';
import { AlertButton } from '../../../../shared/buttons/Button';
import { FieldAndValue } from '../../../../shared/FieldAndValue';
import { styled } from '../../../../styling/theme';
import { HollowLinkButton } from '../../../../shared/buttons/LinkButton';
import {
  getGroupedTreatmentTypeDeviationSubRows,
  mapTreatmentTypeDeviationsBulkEditResponseToGroupedTableData,
} from '../TreatmentTypeDeviationsBase';
import { FormikBasicTable } from '../../../../shared/tables/FormikTable';
import { InputField } from '../../../../shared/forms/InputField';
import { ApiErrorBox } from '../../../../shared/errors/ApiErrorBox';

type EditTreatmentTypeDeviationBulkEditProps = RouteComponentProps<{
  treatmentTypeDeviationBulkEditId: string;
}>;

export const EditTreatmentTypeDeviationBulkEdit = (
  props: EditTreatmentTypeDeviationBulkEditProps,
) => {
  const { user } = useContext(UserContext);
  return (
    <DataLoaderWithParams
      apiRequest={getAllPracticeGroups}
      getParams={() => user.organisationGroupId}
    >
      {practiceGroupsRequest => (
        <EditTreatmentTypeDeviationBulkEditComponent
          {...props}
          practiceGroups={practiceGroupsRequest.response.practiceGroups}
        />
      )}
    </DataLoaderWithParams>
  );
};

const ViewButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: ${props => props.theme.spacing.small}px;
`;

type EditTreatmentTypeDeviationBulkEditComponentProps = EditTreatmentTypeDeviationBulkEditProps & {
  practiceGroups: Array<PracticeGroupResponse>;
};

const EditTreatmentTypeDeviationBulkEditComponent = (
  props: EditTreatmentTypeDeviationBulkEditComponentProps,
) => {
  const { t } = useTranslation(['treatmentTypeDeviations', 'navigateAwayMessages']);
  const { user } = useContext(UserContext);

  const {
    response: getRequestResponse,
    inProgress: getRequestInProgress,
    apiError: getRequestApiError,
    makeRequest: makeGetRequest,
  } = useApiRequest(
    getTreatmentTypeDeviationBulkEdit(Number(props.treatmentTypeDeviationBulkEditId)),
  );

  const {
    inProgress: cancelRequestInProgress,
    apiError: cancelRequestApiError,
    makeRequest: makeCancelRequest,
  } = useApiRequest(cancelTreatmentTypeDeviationBulkEdit);

  useEffect(() => {
    makeGetRequest({ pageNumber: 1, pageSize: treatmentTypeDeviationsPageSize }).then();
  }, []);

  useNavigateAwayWarning({
    shouldConfirmNavigation: true,
    message: t('navigateAwayMessages:bulkEditForm'),
  });

  const practiceGroupsById = useMemo(
    () => keyBy(props.practiceGroups, practiceGroup => practiceGroup.practiceGroupId),
    [props.practiceGroups],
  );

  const columns: Array<CustomColumn<
    GroupedTreatmentTypeDeviationBulkEditRow,
    TreatmentTypeDeviationColumnTypes
  >> = useMemo(() => getColumns(t, user, practiceGroupsById), [t, user, practiceGroupsById]);

  const tableData = useMemo(
    () =>
      getRequestResponse
        ? mapTreatmentTypeDeviationsBulkEditResponseToGroupedTableData(getRequestResponse)
        : null,
    [getRequestResponse],
  );

  const initialFormModel = useCallback(getInitialFormModel, [user]);

  const mapFormModelToEditBulkEditRequest = (
    formValues: EditTreatmentTypeDeviationBulkEditFormModel,
  ): EditTreatmentTypeDeviationBulkEditCommand => {
    const bulkEdit = assertIsDefined(getRequestResponse);

    const command = {
      treatmentTypeDeviationBulkEditId: bulkEdit.treatmentTypeDeviationBulkEditId,
      name: formValues.name,
      deviations: flatMap(formValues.deviations, (siteTreatmentTypes, siteId) =>
        map(
          siteTreatmentTypes,
          (value, treatmentTypeId): TreatmentTypeDeviationBulkEditCommandLine => ({
            deviationId: value.deviationId,
            siteId: Number(siteId),
            treatmentTypeId: Number(treatmentTypeId),
            percentagePriceDeviation: parseNumber(value.percentagePriceDeviation, user.locale),
            percentageDispenseFeeDeviation: parseNumber(
              value.percentageDispenseFeeDeviation,
              user.locale,
            ),
          }),
        ),
      ),
    };

    return command;
  };

  const validateBulkEditTable = (values: EditTreatmentTypeDeviationBulkEditFormModel) => {
    const validator = new EditTreatmentTypeDeviationBulkEditValidator(t, user.locale);

    const topLevelErrors = validator.validate(values);
    const perDeviationValidator = new TreatmentTypeDeviationBulkEditLineValidator(t, user.locale);
    const deviationErrors = mapValues(values.deviations, items =>
      mapValues(items, value => perDeviationValidator.validate(value)),
    );

    if (
      some(deviationErrors, errorsBySite => some(errorsBySite, rowErrors => some(rowErrors))) ||
      some(topLevelErrors)
    ) {
      return {
        ...topLevelErrors,
        deviations: deviationErrors,
      };
    }

    return {};
  };

  const onSubmitComplete = () => {
    navigate(
      viewTreatmentTypeDeviationBulkEditUrl(Number(props.treatmentTypeDeviationBulkEditId)),
      {
        state: { success: true },
        suppressUserConfirmation: true,
      },
    );
  };

  const onCancelButtonClick = () => {
    const bulkEdit = assertIsDefined(getRequestResponse);

    if (window.confirm(t('editBulkEdit.confirmCancelBulkEdit'))) {
      makeCancelRequest(bulkEdit.treatmentTypeDeviationBulkEditId).then(result => {
        if (result) {
          navigate(dashboardUrl(), {
            suppressUserConfirmation: true,
            state: { cancelledTreatmentTypeDeviationBulkEdit: true },
          });
        }
      });
    }
  };

  if (getRequestInProgress) {
    return <LoadingIndicator />;
  }

  return (
    <RequiresOneOfPermissions permissions={createEditTreatmentTypeDeviationsPermissions}>
      <Title>{t('editBulkEdit.title')}</Title>
      <PageHeading>
        <h1>{t('editBulkEdit.heading')}</h1>
        <AlertButton onClick={onCancelButtonClick} loading={cancelRequestInProgress}>
          {t('editBulkEdit.cancel')}
        </AlertButton>
      </PageHeading>
      {getRequestResponse && (
        <FieldAndValue
          fieldName={t('editBulkEdit.scheduledDate')}
          value={getRequestResponse.scheduledDate}
          type="date"
        />
      )}
      <ViewButtonContainer>
        <HollowLinkButton
          to={viewTreatmentTypeDeviationBulkEditUrl(Number(props.treatmentTypeDeviationBulkEditId))}
        >
          {t('editBulkEdit.viewBulkEditButton')}
        </HollowLinkButton>
      </ViewButtonContainer>
      {tableData && (
        <FormikBasicTable
          disabled={getRequestInProgress || cancelRequestInProgress}
          data={tableData}
          validate={validateBulkEditTable}
          enableReinitialize={true}
          submitRequest={editTreatmentTypeDeviationBulkEdit}
          mapValuesToRequestParameters={mapFormModelToEditBulkEditRequest}
          columns={columns}
          getSubRows={getGroupedTreatmentTypeDeviationSubRows}
          initialFormValues={initialFormModel(getRequestResponse, user)}
          additionalFormFields={() => (
            <InputField name="name" label={t('editBulkEdit.bulkEditName')} useDebouncing={true} />
          )}
          onSubmitComplete={onSubmitComplete}
        />
      )}
      {getRequestApiError && <ApiErrorBox error={getRequestApiError} />}
      {cancelRequestApiError && !getRequestApiError && (
        <ApiErrorBox error={cancelRequestApiError} />
      )}
    </RequiresOneOfPermissions>
  );
};

export const getColumns = (
  t: TFunction,
  user: LoginUserResponse,
  practiceGroupsById: { [practiceGroupId: number]: PracticeGroupResponse },
): Array<CustomColumn<
  GroupedTreatmentTypeDeviationBulkEditRow,
  TreatmentTypeDeviationColumnTypes
>> => [
  treatmentTypeDeviationExpandedColumn(),
  treatmentTypeDeviationGroupedPracticeGroupOrSiteNameColumn(t, null, practiceGroupsById),
  treatmentTypeDeviationTreatmentTypeNameColumn(t),
  {
    id: 'priceDeviationPercentValue',
    Header: t<string>('columnHeaders.priceDeviationPercentValue'),
    isRightAligned: false,
    Cell: ({ row, formikProps }: CellProps<GroupedTreatmentTypeDeviationBulkEditRow>) => {
      if (row.canExpand) {
        const { fieldValue, updateSubRowFields } = getTreatmentTypeDeviationHeaderFieldProps(
          row,
          formikProps,
          'percentagePriceDeviation',
        );
        return (
          <ControlledInput initialValue={fieldValue}>
            {({ value, onChange }) => (
              <PercentInput
                data-testid={getGroupedTreatmentTypeDeviationRowPricePercentageTestId(
                  row.original.practiceGroupId,
                  row.original.treatmentTypeId,
                )}
                value={value}
                onChange={onChange}
                bulkVariant={true}
                onBlurIfChange={updateSubRowFields}
                isRightAligned={true}
                disabled={
                  !userHasOneOfPermissions(user, createEditTreatmentTypeDeviationsPermissions)
                }
                warning={treatmentTypeDeviationHeaderValueHasChanged(
                  formikProps,
                  row,
                  'percentagePriceDeviation',
                  value,
                )}
              />
            )}
          </ControlledInput>
        );
      } else {
        return (
          <PercentField
            testId={getTreatmentTypeDeviationRowPricePercentageTestId(
              row.original.treatmentTypeId,
              row.original.siteId,
            )}
            bulkVariant={true}
            hideLabel={true}
            name={`${getTreatmentTypeDeviationFieldPrefix(row)}.percentagePriceDeviation`}
            label={t('columnHeaders.priceDeviationPercentValue')}
            useDebouncing={true}
            isRightAligned={true}
            disabled={!userHasOneOfPermissions(user, createEditTreatmentTypeDeviationsPermissions)}
            warning={treatmentTypeDeviationBulkEditValueHasChanged(
              formikProps,
              row,
              'percentagePriceDeviation',
            )}
          />
        );
      }
    },
  },
  {
    id: 'dispenseFeeDeviationPercentValue',
    Header: t<string>('columnHeaders.dispenseFeeDeviationPercentValue'),
    isRightAligned: false,
    Cell: ({ cell, row, formikProps }: CellProps<GroupedTreatmentTypeDeviationBulkEditRow>) => {
      if (row.canExpand) {
        const { fieldValue, updateSubRowFields } = getTreatmentTypeDeviationHeaderFieldProps(
          row,
          formikProps,
          'percentageDispenseFeeDeviation',
        );
        return (
          <ControlledInput initialValue={fieldValue}>
            {({ value, onChange }) => (
              <PercentInput
                data-testid={getGroupedTreatmentTypeDeviationRowDispenseFeePercentageTestId(
                  row.original.practiceGroupId,
                  row.original.treatmentTypeId,
                )}
                value={value}
                onChange={onChange}
                bulkVariant={true}
                onBlurIfChange={updateSubRowFields}
                isRightAligned={true}
                disabled={
                  !userHasOneOfPermissions(user, createEditTreatmentTypeDeviationsPermissions)
                }
                warning={treatmentTypeDeviationHeaderValueHasChanged(
                  formikProps,
                  row,
                  'percentageDispenseFeeDeviation',
                  value,
                )}
              />
            )}
          </ControlledInput>
        );
      } else {
        return (
          <PercentField
            testId={getTreatmentTypeDeviationRowDispenseFeePercentageTestId(
              row.original.treatmentTypeId,
              row.original.siteId,
            )}
            bulkVariant={true}
            hideLabel={true}
            name={`${getTreatmentTypeDeviationFieldPrefix(row)}.percentageDispenseFeeDeviation`}
            label={t('columnHeaders.dispenseFeeDeviationPercentValue')}
            useDebouncing={true}
            isRightAligned={true}
            disabled={!userHasOneOfPermissions(user, createEditTreatmentTypeDeviationsPermissions)}
            warning={treatmentTypeDeviationBulkEditValueHasChanged(
              formikProps,
              row,
              'percentageDispenseFeeDeviation',
            )}
          />
        );
      }
    },
  },
  groupedActionsColumn(t),
  groupedWarningsColumnForEditablePages(t),
];

const getInitialFormModel = (
  bulkEditResponse: TreatmentTypeDeviationBulkEditResponse | null,
  user: LoginUserResponse,
): EditTreatmentTypeDeviationBulkEditFormModel => {
  if (bulkEditResponse == null) {
    return {
      name: null,
      deviations: {},
    };
  }

  const formModel: EditTreatmentTypeDeviationBulkEditFormModel = {
    name: bulkEditResponse.name,
    deviations: {},
  };

  forEach(bulkEditResponse.treatmentTypeDeviationBulkEditLines, deviation => {
    if (formModel.deviations[deviation.siteId] == null) {
      formModel.deviations[deviation.siteId] = {};
    }

    formModel.deviations[deviation.siteId][deviation.treatmentTypeId] = {
      deviationId: deviation.deviationId,
      percentagePriceDeviation: formatNumberForFields(
        deviation.percentagePriceDeviationTo,
        user.locale,
      ),
      percentageDispenseFeeDeviation: formatNumberForFields(
        deviation.percentageDispenseFeeDeviationTo,
        user.locale,
      ),
    };
  });

  return formModel;
};
