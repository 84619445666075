export const getUniqueValueOrDefault = <T, V>(
  items: Array<T>,
  getter: (item: T) => V,
  defaultValue: V,
): V => {
  if (items.length === 0) {
    return defaultValue;
  }
  const value = getter(items[0]);
  for (let i = 1; i < items.length; i++) {
    if (getter(items[i]) !== value) {
      return defaultValue;
    }
  }
  return value;
};

/**
 * Simple function to iterate over an array comparing the property and returning a bool as to
 * weather all rows match
 */
export const checkPropertyEquality = <T>(array: Array<T>, propertyName: keyof T): boolean => {
  if (array.length === 0) {
    return true; // No items to compare, so return true
  }

  const firstValue = array[0][propertyName]; // Get the property value from the first item

  return array.every(item => item[propertyName] === firstValue);
};
