import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { RequiresOneOfPermissions } from '../../shared/Permissions';
import { permissions } from '../authentication/permissions';
import { PageHeading } from '../layout/PageHeading';
import { HangFireForm } from './components/HangFireForm';

type OwnProps = RouteComponentProps & {};
type Props = OwnProps;

export const HangfireJob = (props: Props) => {
  const { t } = useTranslation(['support']);
  return (
    <RequiresOneOfPermissions permissions={[permissions.IVCAdmin]}>
      <Title>{t('hangfireJob.title')}</Title>
      <PageHeading>
        <h1>{t('hangfireJob.title')}</h1>
      </PageHeading>

      <HangFireForm />
    </RequiresOneOfPermissions>
  );
};
