import React from 'react';
import { HangFireJobDetailsResponse } from '../API/HangFireJobDetailsResponse';
import { FieldAndValue } from '../../../shared/FieldAndValue';
import { Row, Column } from '../../../styling/Site';

interface HangFireDetailsProps {
  hangfireDetails: HangFireJobDetailsResponse;
}

export const HangFireDetails = (props: HangFireDetailsProps) => {
  return (
    props.hangfireDetails && (
      <>
        <Row>
          <Column>
            <FieldAndValue
              fieldName={'Id'}
              value={props.hangfireDetails?.hangFireJob.id}
              type="text"
              layout="vertical"
            />
            <FieldAndValue
              fieldName={'Type'}
              value={props.hangfireDetails?.hangFireJob.type}
              type="text"
              layout="vertical"
            />
            <FieldAndValue
              fieldName={'State'}
              value={props.hangfireDetails?.hangFireJob.stateName}
              type="text"
              layout="vertical"
            />
            <FieldAndValue
              fieldName={'Created'}
              value={props.hangfireDetails?.hangFireJob.createdAt}
              type="text"
              layout="vertical"
            />
          </Column>
          <Column>
            <FieldAndValue
              fieldName={'Owner'}
              value={props.hangfireDetails?.bulkEditJob.owner}
              type="text"
              layout="vertical"
            />
            <FieldAndValue
              fieldName={'hangfireScheduledTime'}
              value={props.hangfireDetails?.bulkEditJob.hangfireScheduledTime}
              type="text"
              layout="vertical"
            />
            <FieldAndValue
              fieldName={'isCsvUpload'}
              value={props.hangfireDetails?.bulkEditJob.isCsvUpload}
              type="text"
              layout="vertical"
            />
            <FieldAndValue
              fieldName={'name'}
              value={props.hangfireDetails?.bulkEditJob.name}
              type="text"
              layout="vertical"
            />
            <FieldAndValue
              fieldName={'organisationGroup'}
              value={props.hangfireDetails?.bulkEditJob.organisationGroup}
              type="text"
              layout="vertical"
            />
            <FieldAndValue
              fieldName={'runImmediately'}
              value={props.hangfireDetails?.bulkEditJob.runImmediately}
              type="text"
              layout="vertical"
            />
            <FieldAndValue
              fieldName={'status'}
              value={props.hangfireDetails?.bulkEditJob.status}
              type="text"
              layout="vertical"
            />
          </Column>
        </Row>
      </>
    )
  );
};
