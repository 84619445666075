import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
`;

const Row = styled.div<
  React.HTMLAttributes<HTMLDivElement> & { $paddingTop?: string; $paddingBottom?: string }
>`
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  ${({ $paddingTop }) => $paddingTop && `padding-top: ${$paddingTop}px;`}
  ${({ $paddingBottom }) => $paddingBottom && `padding-bottom: ${$paddingBottom}px;`}
`;

const Column = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  flex: 1;

  @media (min-width: 576px) {
    flex-basis: 50%;
  }

  @media (min-width: 768px) {
    flex-basis: 33.33333%;
  }

  @media (min-width: 992px) {
    flex-basis: 25%;
  }
`;

const Div = styled.div<
  React.HTMLAttributes<HTMLDivElement> & { $paddingTop?: string; $paddingBottom?: string }
>`
  margin-top: 15px;
  margin-bottom: 15px;
  ${({ $paddingTop }) => $paddingTop && `padding-top: ${$paddingTop}px;`}
  ${({ $paddingBottom }) =>
    $paddingBottom && `padding-bottom: ${$paddingBottom}px;`}
  display: block;
  clear: both; /* Add this line to force the Div to appear on a new line */
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
`;

const CardHeader = styled.div`
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #f7f7f9;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
`;

const CardBody = styled.div`
  flex: 1 1 auto;
  padding: 1.25rem;
`;

const CardFooter = styled.div`
  padding: 0.75rem 1.25rem;
  background-color: #f7f7f9;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
`;

const ListGroup = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
`;

const ListItem = styled.li`
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #f8f8f8;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);

  &:last-child {
    border-bottom: none;
  }
`;

// const ListGroupItem = styled(ListItem)`
//   background-color: ${(props) => (props.active ? '#f7f7f9' : '#fff')};

//   &:hover {
//     background-color: ${(props) => (props.active ? '#f7f7f9' : '#f2f2f5')};
//   }
// `;

export { Container, Row, Column, Div, Card, CardBody, CardHeader, CardFooter, ListGroup, ListItem };
