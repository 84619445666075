import { FormikProps, withFormik } from 'formik';
import { TFunction } from 'i18next';
import { flowRight, isEmpty, map, orderBy } from 'lodash';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { ById } from '../../../models/id';
import { Locale } from '../../../models/locale';
import { Accordion } from '../../../shared/Accordion';
import { ButtonGroup, HollowButton, PrimaryButton } from '../../../shared/buttons/Button';
import { InputField } from '../../../shared/forms/InputField';
import { MultiSelectDropdownField } from '../../../shared/forms/MultiSelectDropdownField';
import {
  withUserContext,
  WithUserContextProps,
} from '../../../shared/higher-order-components/withUserContext';
import { styled } from '../../../styling/theme';
import { TranslatableValidator } from '../../../utils/validation/TranslatableValidator';
import { InputMaximumLength } from '../../../utils/validation/validationConstants';
import {
  getPmsInstanceCodesFromFilterOptions,
  PmsInstanceByCode,
  pmsInstanceCodeToPmsCodeAndMerlinGroup,
} from '../pms/pmsSite';
import { PracticeGroupResponse } from '../practiceGroups/practiceGroup';
import { SiteFilterFormModel, SiteFilterOptions } from '../site';
import { FilterAccordionBar } from '../../../shared/Filter';

type OwnProps = {
  pmsInstancesByCode: PmsInstanceByCode;
  practiceGroupsById: ById<PracticeGroupResponse>;
  existingFilters?: SiteFilterOptions;
  onApply: (response: SiteFilterOptions) => void;
};
type Props = OwnProps & FormikProps<SiteFilterFormModel>;

const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: ${props => props.theme.spacing.medium}px;
  margin-bottom: 0;
`;

const FieldRow = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const InvisibleFieldSet = styled.fieldset<React.FieldsetHTMLAttributes<HTMLFieldSetElement>>`
  margin: 0;
  padding: 0;
  border: none;
`;

const SitesFilterFormComponent = (props: Props) => {
  const { t } = useTranslation(['site', 'metadata']);

  const pmsInstanceOptions = useMemo(
    () =>
      orderBy(
        map(props.pmsInstancesByCode, pmsInstance => ({
          displayText: pmsInstance.name,
          value: pmsInstance.code,
        })),
        'displayText',
      ),
    [],
  );

  const practiceGroupOptions = useMemo(
    () =>
      orderBy(
        map(props.practiceGroupsById, practiceGroup => ({
          displayText: practiceGroup.name,
          value: practiceGroup.practiceGroupId,
        })),
        'displayText',
      ),
    [],
  );

  const clearFields = () => {
    props.setValues({
      searchText: null,
      pmsInstanceCodes: null,
      practiceGroupsIds: null,
    });
  };

  return (
    <Accordion
      headerComponent={childProps => (
        <FilterAccordionBar label={t('sitesFilter.filter')} isOpen={childProps.isOpen} />
      )}
    >
      {({ toggleAccordion, isOpen }) => {
        const onSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
          props.handleSubmit(e);
          if (isEmpty(props.errors)) {
            toggleAccordion();
          }
        };

        return (
          <form onSubmit={onSubmit} onReset={props.handleReset}>
            <InvisibleFieldSet disabled={!isOpen}>
              <InputField name="searchText" label={t('sitesFilter.searchText')} />
              <FieldRow>
                <MultiSelectDropdownField
                  name="pmsInstanceCodes"
                  label={t('sitesFilter.pmsInstance')}
                  options={pmsInstanceOptions}
                />
                <MultiSelectDropdownField
                  name="practiceGroupsIds"
                  label={t('sitesFilter.practiceGroup')}
                  options={practiceGroupOptions}
                />
              </FieldRow>
              <StyledButtonGroup>
                <HollowButton onClick={clearFields} type="button" disabled={props.isSubmitting}>
                  {t('sitesFilter.clearFieldsButton')}
                </HollowButton>
                <PrimaryButton type="submit" loading={props.isSubmitting}>
                  {t('sitesFilter.applyButton')}
                </PrimaryButton>
              </StyledButtonGroup>
            </InvisibleFieldSet>
          </form>
        );
      }}
    </Accordion>
  );
};

const withUserContextEnhancer = withUserContext();

const withTranslationsEnhancer = withTranslation('site');

const withFormikEnhancer = withFormik<
  OwnProps & WithUserContextProps & WithTranslation,
  SiteFilterFormModel
>({
  enableReinitialize: true,
  handleSubmit: (values, { props, setSubmitting }) => {
    const pmsInstanceFilterOptions =
      values.pmsInstanceCodes?.map(s => pmsInstanceCodeToPmsCodeAndMerlinGroup(s)) || [];

    props.onApply({
      searchText: values.searchText,
      pmsInstances: pmsInstanceFilterOptions,
      practiceGroupsIds: values.practiceGroupsIds,
    });
    setSubmitting(false);
  },
  mapPropsToValues: props => {
    return {
      searchText: props.existingFilters ? props.existingFilters.searchText : null,
      pmsInstanceCodes: props.existingFilters
        ? getPmsInstanceCodesFromFilterOptions(props.existingFilters.pmsInstances)
        : null,
      practiceGroupsIds: props.existingFilters ? props.existingFilters.practiceGroupsIds : null,
    };
  },
  validate: (values, props) => {
    const validator = new SiteFilterFormValidator(props.t, props.user.locale);
    return validator.validate(values);
  },
});

const enhance = flowRight(withUserContextEnhancer, withTranslationsEnhancer, withFormikEnhancer);

export const SitesFilterForm: React.ComponentType<OwnProps> = enhance(SitesFilterFormComponent);

class SiteFilterFormValidator extends TranslatableValidator<SiteFilterFormModel> {
  constructor(t: TFunction, locale: Locale) {
    super(t, locale);
    this.ruleFor('searchText')
      .maxLength(InputMaximumLength)
      .withMessage(t('sitesFilter.validation.searchText.length'));
  }
}
