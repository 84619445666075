import { faClock } from '@fortawesome/free-regular-svg-icons';
import { RouteComponentProps } from '@reach/router';
import { ControllerStateAndHelpers } from 'downshift';
import { some } from 'lodash';
import * as React from 'react';
import { useContext, useState } from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { toShortDate } from '../../../models/dates/dateTimeStamp';
import { getLocalNow } from '../../../models/dates/now';
import { ButtonGroup, ButtonLabel, HollowButton } from '../../../shared/buttons/Button';
import { HollowLinkButton } from '../../../shared/buttons/LinkButton';
import { DataLoader } from '../../../shared/DataLoader';
import {
  MultiSelectDropdown,
  MultiSelectDropdownProps,
} from '../../../shared/dropdowns/MultiSelectDropdown';
import { DropdownOption } from '../../../shared/forms/DropdownField';
import { ExternalLink } from '../../../shared/navigation/Link';
import { IfUserHasOneOfPermissions } from '../../../shared/Permissions';
import { MarginLeftIcon } from '../../../shared/StyledIcons';
import { DataFetchTable } from '../../../shared/tables/Table';
import { styled } from '../../../styling/theme';
import { userHasOneOfPermissions } from '../../authentication/loginData/user';
import { UserContext } from '../../authentication/loginData/userContext';
import { permissions } from '../../authentication/permissions';
import { PageHeading } from '../../layout/PageHeading';
import {
  GetItemsDownloadCommand,
  ItemColumnTypes,
  ItemResponse,
  ItemsResponse,
  validItemColumnTypeOrUndefined,
} from '../item';
import { getItemFieldsFromPms, getItemsDownload } from '../itemsApi';
import {
  createItemUrl,
  editItemUrl,
  uploadItemBulkEditUrl,
  uploadItemsUrl,
  viewItemUrl,
} from '../itemUrls';
import {
  itemCategory1Column,
  itemCategory2Column,
  itemCategory3Column,
  itemCentralMarkupPercentageColumn,
  itemCentralPriceGrossColumn,
  itemCentralPriceNetColumn,
  itemCodeColumn,
  ItemColumn,
  itemFlagsColumn,
  itemLastUpdatedColumn,
  itemNameColumn,
  itemQuantityColumn,
  ItemsBaseProps,
  itemsPageSize,
  ItemsScheduledForUpdateInfoBar,
  itemTypeColumn,
  SpaceBetweenButtonGroup,
  useItemTableBaseState,
} from './ItemsBase';
import { ItemsFilterForm } from './ItemsFilterForm';
import { ViewItemFlags } from './ViewItemFlags';
import { MetadataContext } from '../../authentication/loginData/metadataContext';
import { DownloadExcelFromCsvResponseButton } from '../../../shared/buttons/DownloadExcelFromCsvResponseButton';
import { SortResponse } from '../../../shared/tables/sorting/sorting';
import { appendDateTimeToString } from '../../../utils/stringUtils';

type OwnProps = RouteComponentProps & {};
type Props = OwnProps;

type ItemTableProps = ItemsBaseProps & RouteComponentProps;

export const Items = (props: Props) => (
  <DataLoader apiRequest={getItemFieldsFromPms}>
    {itemFields => <ItemsTableComponent {...props} itemFieldsFromPms={itemFields.response} />}
  </DataLoader>
);

const MarginBottomlessButtonGroup = styled(ButtonGroup)`
  margin-bottom: 0;
`;

type ColumnDropdownType = DropdownOption<ItemColumnTypes>;

const renderToggleButton = (
  toggleProps: MultiSelectDropdownProps<ColumnDropdownType>,
  downshift: ControllerStateAndHelpers<ColumnDropdownType>,
) => (
  <HollowButton {...downshift.getToggleButtonProps()}>
    <ButtonLabel {...downshift.getLabelProps()}>{toggleProps.label}</ButtonLabel>
  </HollowButton>
);

const mapItemsResponseToTableData = (response: ItemsResponse) => response.items;
const ItemsTableComponent = (props: ItemTableProps) => {
  const { t } = useTranslation(['item', 'libraries']);
  const { user } = useContext(UserContext);
  const { countries } = useContext(MetadataContext);
  const [itemsResponse, setItemsResponse] = useState<ItemsResponse | null>(null);
  const [sortResponse, setSortResponse] = useState<SortResponse<ItemColumnTypes>>({});

  const userCountries = countries.filter(country => user.countries.includes(country.code));

  const {
    columnDropdownOptions,
    onOptionSelect,
    selectedOptions,
    deselectedValues,
    getFilteredData,
    onFilteredResponse,
    filterOptions,
    setFilterOptions,
  } = useItemTableBaseState(props);

  const columns: Array<ItemColumn> = [
    {
      ...itemFlagsColumn(t),
      Cell: ({ cell }: CellProps<ItemResponse>) => (
        <ViewItemFlags itemFlags={cell.row.original.flags} />
      ),
    },
    {
      ...itemCodeColumn(t),
      Cell: ({ cell }: CellProps<ItemResponse>) => (
        <span>
          {cell.value}
          {cell.row.original.flags.scheduledItemBulkEditId && <MarginLeftIcon icon={faClock} />}
        </span>
      ),
      disableSortBy: false,
    },
    {
      ...itemNameColumn(t),
      Cell: ({ cell }: CellProps<ItemResponse>) => (
        <span>
          <ExternalLink
            to={
              userHasOneOfPermissions(user, [permissions.SuperAdmin, permissions.CentralAdmin])
                ? editItemUrl(cell.row.original.itemId)
                : viewItemUrl(cell.row.original.itemId)
            }
          >
            {cell.value}
          </ExternalLink>
        </span>
      ),
      disableSortBy: false,
    },
    {
      ...itemLastUpdatedColumn(t),
      Cell: ({ cell }: CellProps<ItemResponse>) => (
        <span>{toShortDate(cell.value, user.locale)}</span>
      ),
      disableSortBy: false,
    },
    {
      ...itemCentralPriceNetColumn(t, user, userCountries),
      disableSortBy: false,
    },
    {
      ...itemCentralPriceGrossColumn(t, user, userCountries),
      disableSortBy: false,
    },
    {
      ...itemCentralMarkupPercentageColumn(t, user),
      disableSortBy: false,
    },
    {
      ...itemQuantityColumn(t, user),
      disableSortBy: false,
    },
    {
      ...itemTypeColumn(t, props.itemFieldsFromPms),
      disableSortBy: false,
    },
    {
      ...itemCategory1Column(t, props.itemFieldsFromPms),
      disableSortBy: false,
    },
    {
      ...itemCategory2Column(t, props.itemFieldsFromPms),
      disableSortBy: false,
    },
    {
      ...itemCategory3Column(t, props.itemFieldsFromPms),
      disableSortBy: false,
    },
  ];

  const hiddenColumnNames: Array<ItemColumnTypes> = [
    ...deselectedValues,
    user.useGrossPrices ? 'centralPriceNet' : 'centralPriceGross',
  ];

  const onDataFetchSuccess = (response: ItemsResponse) => {
    onFilteredResponse(response);

    const { sortBy, direction } = response;
    setSortResponse({ sortBy, direction });

    setItemsResponse(response);
  };

  const getItemsDownloadCommand: GetItemsDownloadCommand = {
    filterOptions,
    ...sortResponse,
  };

  return (
    <>
      <Title>{t('itemList.title')}</Title>
      <PageHeading>
        <h1>{t('itemList.heading')}</h1>
        <IfUserHasOneOfPermissions permissions={[permissions.SuperAdmin, permissions.CentralAdmin]}>
          <MarginBottomlessButtonGroup>
            <HollowLinkButton to={createItemUrl()}>{t('itemList.createButton')}</HollowLinkButton>
            <HollowLinkButton to={uploadItemsUrl()}>{t('itemList.uploadButton')}</HollowLinkButton>
          </MarginBottomlessButtonGroup>
        </IfUserHasOneOfPermissions>
      </PageHeading>
      {itemsResponse && some(itemsResponse.items, item => item.flags.bulkEditScheduled) && (
        <ItemsScheduledForUpdateInfoBar message={t('itemList.scheduledBulkEdit')} />
      )}
      <ItemsFilterForm
        itemFieldsFromPms={props.itemFieldsFromPms}
        existingFilters={filterOptions}
        onApply={setFilterOptions}
      />
      <SpaceBetweenButtonGroup>
        <MultiSelectDropdown<ColumnDropdownType>
          label={t('itemList.selectColumnsButtonLabel')}
          options={columnDropdownOptions}
          itemToString={item => (item ? item.displayText : '')}
          onOptionSelect={onOptionSelect}
          initiallySelectedOptions={selectedOptions}
          renderToggleButton={renderToggleButton}
        />
        <MarginBottomlessButtonGroup>
          <DownloadExcelFromCsvResponseButton
            request={getItemsDownload}
            requestParams={getItemsDownloadCommand}
            labelText={t('itemList.downloadButton')}
            fileName={appendDateTimeToString(
              t('itemList.downloadFileName', {
                organisation: user.organisationGroupName.replace(' ', '_'),
              }),
            )}
            sheetName={t('itemList.downloadSheetName')}
          />
          <IfUserHasOneOfPermissions
            permissions={[permissions.SuperAdmin, permissions.CentralAdmin]}
          >
            <HollowLinkButton to={uploadItemBulkEditUrl()}>
              {t('itemList.uploadBulkEditButton')}
            </HollowLinkButton>
          </IfUserHasOneOfPermissions>
        </MarginBottomlessButtonGroup>
      </SpaceBetweenButtonGroup>
      <DataFetchTable
        columns={columns}
        pageSize={itemsPageSize}
        getApiData={getFilteredData}
        validColumnTypeOrUndefined={validItemColumnTypeOrUndefined}
        mapResponseToTableData={mapItemsResponseToTableData}
        onDataFetchSuccess={onDataFetchSuccess}
        emptyTableMessage={t('itemList.emptyTableMessage')}
        hiddenColumnNames={hiddenColumnNames}
      />
    </>
  );
};
