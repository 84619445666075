import { createGlobalStyle } from 'styled-components/macro';
import { modernNormalize } from 'styled-modern-normalize';
import 'typeface-open-sans';
import 'typeface-roboto';
import { Theme } from './theme';

export const GlobalStyling = createGlobalStyle<{ theme: Theme }>`
  ${modernNormalize}
  html, body {
    font-family: ${props => props.theme.typography.content.fontFamily};
    font-size: ${props => props.theme.typography.content.fontSize}px;
    font-weight: ${props => props.theme.typography.content.fontWeight};
    line-height: ${props => props.theme.typography.content.lineHeight};
    background-color: ${props => props.theme.colours.appBackground};

    h1, h2, h3, h4, h5, h6 {
      font-family: ${props => props.theme.typography.headings.fontFamily};
      font-weight: ${props => props.theme.typography.headings.fontWeight};
      line-height: ${props => props.theme.typography.headings.lineHeight};
      margin-top: 0;
      margin-bottom: ${props => props.theme.typography.headings.marginBottom};
    }

    h1 {
      font-size: ${props => props.theme.typography.headings.h1fontSize}px;
    }

    h2 {
      font-size: ${props => props.theme.typography.headings.h2fontSize}px;
    }

    h3 {
      font-size: ${props => props.theme.typography.headings.h3fontSize}px;
    }

    h4 {
      font-size: ${props => props.theme.typography.headings.h4fontSize}px;
    }

    h5 {
      font-size: ${props => props.theme.typography.headings.h5fontSize}px;
    }

    h6 {
      font-size: ${props => props.theme.typography.headings.h6fontSize}px;
    }

    div.app-title strong {
      color: #3D9035;
      text-transform: uppercase;
    }

  }
`;
