import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { styled } from '../../styling/theme';
import { QuickLinksSection } from './QuickLinksSection';
import { SuccessBoxesSection } from './SuccessBoxesSection';
import { TabsSection } from './TabsSection';
import { WelcomeSection } from './WelcomeSection';
import { DashboadFilterForm } from './DashboardFilter';
import { useEffect, useState } from 'react';
import { DashboardFilterSelection } from './models/DashboardFilterSelection';
import { useApiRequest } from '../../shared/hooks/useApiRequest';
import { DashboardResponse, fetchDashboardOptions } from '../../api/dashboardApi';
import { IfUserHasPermission } from '../../shared/Permissions';
import { permissions } from '../authentication/permissions';

const DashboardSection = styled.div`
  margin-bottom: ${props => props.theme.spacing.small}px;
`;

export const Dashboard = (props: RouteComponentProps) => {
  const { t } = useTranslation('dashboard');

  const [filterOptions, setFilterOptions] = useState<DashboardFilterSelection>();
  const [dashboardOptions, setDashboardOptions] = useState<DashboardResponse>({
    users: [{ displayText: '', value: 0 }],
    sites: [{ displayText: '', value: 0 }],
  });

  const handleFilter = (filterData: DashboardFilterSelection) => {
    setFilterOptions(filterData);
  };

  const { makeRequest, apiError, response } = useApiRequest(fetchDashboardOptions);

  useEffect(() => {
    makeRequest().then(response => {
      if (apiError === null) {
        // render the error
        setDashboardOptions(response!);
      }
    });
  }, []);

  return (
    <div>
      <Title>{t('title')}</Title>
      <DashboardSection>
        <WelcomeSection />
      </DashboardSection>
      <DashboardSection>
        <QuickLinksSection />
      </DashboardSection>
      <IfUserHasPermission permission={permissions.SuperAdmin}>
        <DashboardSection>
          <DashboadFilterForm
            existingFilters={filterOptions}
            setFilter={handleFilter}
            dashboardOptions={dashboardOptions}
          />
        </DashboardSection>
      </IfUserHasPermission>
      <DashboardSection>
        <SuccessBoxesSection />
      </DashboardSection>
      <DashboardSection>
        <TabsSection existingFilters={filterOptions} />
      </DashboardSection>
    </div>
  );
};
