import { RouteComponentProps, Router } from '@reach/router';
import * as React from 'react';
import { PageNotFound } from '../PageNotFound';
import { CreateDeviationBulkEdit } from './bulkEdit/CreateDeviationBulkEdit';
import { EditDeviationBulkEdit } from './bulkEdit/EditDeviationBulkEdit';
import { UploadDeviationBulkEdit } from './bulkEdit/UploadDeviationBulkEdit';
import { ViewDeviationBulkEdit } from './bulkEdit/ViewDeviationBulkEdit';
import { Deviations } from './Deviations';

export const DeviationsSection = (props: RouteComponentProps) => (
  <Router primary={false}>
    <Deviations path="/" />
    <CreateDeviationBulkEdit path="bulk-edit" />
    <ViewDeviationBulkEdit path="bulk-edit/view/:deviationBulkEditId" />
    <EditDeviationBulkEdit path="bulk-edit/edit/:deviationBulkEditId" />
    <UploadDeviationBulkEdit path="bulk-edit/upload" />
    <PageNotFound default={true} />
  </Router>
);
