import Downshift, {
  ControllerStateAndHelpers,
  DownshiftProps,
  DownshiftState,
  StateChangeOptions,
} from 'downshift';
import { useEffect, useState } from 'react';
import * as React from 'react';

export type MultiSelectDropdownBaseChildrenProps<TItem> = {
  selectedItems: Array<TItem>;
} & ControllerStateAndHelpers<TItem>;

export type MultiSelectDropdownBaseProps<TItem> = {
  initiallySelectedItems?: Array<TItem>;
  onSelect?: (
    selectedItems: Array<TItem>,
    downshiftProps?: ControllerStateAndHelpers<TItem>,
  ) => void;
  onChange?: (
    selectedItems: Array<TItem>,
    downshiftProps?: ControllerStateAndHelpers<TItem>,
  ) => void;
  children: (props: MultiSelectDropdownBaseChildrenProps<TItem>) => React.ReactNode;
} & Omit<DownshiftProps<TItem>, 'children' | 'onSelect' | 'onChange'>; // We specifically need our version to access selectedItems

export const MultiSelectDropdownBase = <TItem extends { value: string | number }>({
  onSelect,
  onChange,
  ...props
}: MultiSelectDropdownBaseProps<TItem>) => {
  const [selectedItems, setSelectedItems] = useState<Array<TItem>>(
    props.initiallySelectedItems || [],
  );
  useEffect(() => {
    if (onSelect) {
      onSelect(selectedItems);
    }
    if (onChange) {
      onChange(selectedItems);
    }
  }, [selectedItems, onSelect, onChange]);

  const stateReducer = (
    state: DownshiftState<TItem>,
    changes: StateChangeOptions<TItem>,
  ): Partial<StateChangeOptions<TItem>> => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          highlightedIndex: state.highlightedIndex,
          isOpen: true,
          inputValue: state.inputValue,
        };
      default:
        return changes;
    }
  };

  const handleSelection = (
    selectedItem: TItem | null,
    downshift: ControllerStateAndHelpers<TItem>,
  ) => {
    if (selectedItem != null) {
      selectedItems.some(item => item.value === selectedItem.value)
        ? setSelectedItems(selectedItems.filter(item => item.value !== selectedItem.value))
        : setSelectedItems([...selectedItems, selectedItem]);
    }
  };

  return (
    <Downshift {...props} stateReducer={stateReducer} onSelect={handleSelection}>
      {downshift => props.children({ selectedItems, ...downshift })}
    </Downshift>
  );
};
