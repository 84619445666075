import * as React from 'react';
import { styled } from '../../styling/theme';

export const PanelsGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${props => props.theme.spacing.small}px;
  margin: ${props => props.theme.spacing.small}px 0;
`;

type PanelProps = {
  title?: string;
  includeSpaceForTitle?: boolean;
  className?: string;
  children: React.ReactNode;
};

export const Panel = ({ title, includeSpaceForTitle, className, children }: PanelProps) => (
  <PanelContainer className={className}>
    {!!title ? <h4>{title}</h4> : includeSpaceForTitle && <TitleSpace>Space Text</TitleSpace>}
    <PanelContent>{children}</PanelContent>
  </PanelContainer>
);

const PanelContainer = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  height: auto;
`;

const TitleSpace = styled.h4`
  color: transparent;
`;

const PanelContent = styled.div`
  height: 100%;
  padding: ${props => props.theme.spacing.small}px;
  border: 1px solid ${props => props.theme.colours.border};
  border-radius: ${props => props.theme.spacing.extraTiny}px;
  background-color: ${props => props.theme.colours.componentBackground};
`;
