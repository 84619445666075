import { isEmpty } from 'lodash';
import { IconMapping } from '../../../shared/IconRow';
import {
  faCoins,
  faExclamationCircle,
  faExclamationTriangle,
  faMoneyBill,
} from '@fortawesome/free-solid-svg-icons';
import {
  GenericGroupedTreatmentTypeDeviationRow,
  TreatmentTypeDeviationColumnTypes,
  TreatmentTypeDeviationTableRow,
} from './treatmentTypeDeviations';
import { Row } from 'react-table';
import { getUniqueValueOrDefault } from '../../../utils/arrayUtils';
import {
  EditTreatmentTypeDeviationBulkEditFormModel,
  TreatmentTypeDeviationBulkEditLineFormModel,
} from './bulkEdit/treatmentTypeDeviationBulkEdit';

export type TreatmentTypeDeviationWarning =
  | 'NegativePriceDeviation'
  | 'NegativePriceDeviationsInGroup'
  | 'NegativeDispenseFeeDeviation'
  | 'NegativeDispenseFeeDeviationsInGroup'
  | 'DifferentPricesInGroup'
  | 'DifferentDispenseFeesInGroup';

export const treatmentTypeDeviationWarnings: {
  [warning in TreatmentTypeDeviationWarning]: TreatmentTypeDeviationWarning;
} = {
  NegativePriceDeviation: 'NegativePriceDeviation',
  NegativePriceDeviationsInGroup: 'NegativePriceDeviationsInGroup',
  NegativeDispenseFeeDeviation: 'NegativeDispenseFeeDeviation',
  NegativeDispenseFeeDeviationsInGroup: 'NegativeDispenseFeeDeviationsInGroup',
  DifferentPricesInGroup: 'DifferentPricesInGroup',
  DifferentDispenseFeesInGroup: 'DifferentDispenseFeesInGroup',
};

export const treatmentTypeDeviationWarningIconsMapping: IconMapping<TreatmentTypeDeviationWarning> = {
  NegativePriceDeviation: {
    icon: faExclamationTriangle,
    messageKey: 'warnings.negativePriceDeviation',
  },
  NegativePriceDeviationsInGroup: {
    icon: faExclamationTriangle,
    messageKey: 'warnings.negativePriceDeviationsInGroup',
  },
  NegativeDispenseFeeDeviation: {
    icon: faExclamationCircle,
    messageKey: 'warnings.negativeDispenseFeeDeviation',
  },
  NegativeDispenseFeeDeviationsInGroup: {
    icon: faExclamationCircle,
    messageKey: 'warnings.negativeDispenseFeeDeviationsInGroup',
  },
  DifferentPricesInGroup: {
    icon: faCoins,
    messageKey: 'warnings.differentPricesInGroup',
  },
  DifferentDispenseFeesInGroup: {
    icon: faMoneyBill,
    messageKey: 'warnings.differentDispenseFeesInGroup',
  },
};

type TreatmentTypeDeviationTableRowValues = Record<TreatmentTypeDeviationColumnTypes, string>;

export const getGroupedRowTreatmentTypeDeviationsWarningsForViewPages = <
  T extends TreatmentTypeDeviationTableRow
>(
  groupedRow: Row<GenericGroupedTreatmentTypeDeviationRow<T>>,
): Array<TreatmentTypeDeviationWarning> => {
  const warnings: Array<TreatmentTypeDeviationWarning> = [];

  const hasSubRowWithNegativePriceDeviation = groupedRow.subRows.some(subRow =>
    tableRowHasNegativePriceDeviation(subRow.values as TreatmentTypeDeviationTableRowValues),
  );
  if (hasSubRowWithNegativePriceDeviation) {
    warnings.push(treatmentTypeDeviationWarnings.NegativePriceDeviationsInGroup);
  }

  const hasSubRowWithNegativeDispenseFeeDeviation = groupedRow.subRows.some(subRow =>
    tableRowHasNegativeDispenseFeeDeviation(subRow.values as TreatmentTypeDeviationTableRowValues),
  );
  if (hasSubRowWithNegativeDispenseFeeDeviation) {
    warnings.push(treatmentTypeDeviationWarnings.NegativeDispenseFeeDeviationsInGroup);
  }

  const containsSubRowsWithDifferentPriceDeviations =
    getUniqueValueOrDefault(
      groupedRow.subRows ?? [],
      subRow =>
        getPriceDeviationPercentFromTableRow(subRow.values as TreatmentTypeDeviationTableRowValues),
      null,
    ) == null;
  if (containsSubRowsWithDifferentPriceDeviations) {
    warnings.push(treatmentTypeDeviationWarnings.DifferentPricesInGroup);
  }

  const containsSubRowsWithDifferentDispenseFeeDeviations =
    getUniqueValueOrDefault(
      groupedRow.subRows ?? [],
      subRow =>
        getDispenseFeeDeviationPercentFromTableRow(
          subRow.values as TreatmentTypeDeviationTableRowValues,
        ),
      null,
    ) == null;
  if (containsSubRowsWithDifferentDispenseFeeDeviations) {
    warnings.push(treatmentTypeDeviationWarnings.DifferentDispenseFeesInGroup);
  }

  return warnings;
};

export const getRowTreatmentTypeDeviationsWarningsForViewPages = <
  T extends TreatmentTypeDeviationTableRow
>(
  row: Row<GenericGroupedTreatmentTypeDeviationRow<T>>,
): Array<TreatmentTypeDeviationWarning> => {
  const warnings: Array<TreatmentTypeDeviationWarning> = [];

  if (tableRowHasNegativePriceDeviation(row.values as TreatmentTypeDeviationTableRowValues)) {
    warnings.push(treatmentTypeDeviationWarnings.NegativePriceDeviation);
  }

  if (tableRowHasNegativeDispenseFeeDeviation(row.values as TreatmentTypeDeviationTableRowValues)) {
    warnings.push(treatmentTypeDeviationWarnings.NegativeDispenseFeeDeviation);
  }

  return warnings;
};

const tableRowHasNegativePriceDeviation = (values: TreatmentTypeDeviationTableRowValues): boolean =>
  getPriceDeviationPercentFromTableRow(values) < 0;

const tableRowHasNegativeDispenseFeeDeviation = (
  values: TreatmentTypeDeviationTableRowValues,
): boolean => getDispenseFeeDeviationPercentFromTableRow(values) < 0;

const getPriceDeviationPercentFromTableRow = (
  values: TreatmentTypeDeviationTableRowValues,
): number => parseFloat(values.priceDeviationPercentValue ?? '');

const getDispenseFeeDeviationPercentFromTableRow = (
  values: TreatmentTypeDeviationTableRowValues,
): number => parseFloat(values.dispenseFeeDeviationPercentValue ?? '');

export const getGroupedRowTreatmentTypeDeviationsWarningsForEditablePages = <
  TFormModel extends EditTreatmentTypeDeviationBulkEditFormModel,
  TRow extends TreatmentTypeDeviationTableRow
>(
  formikValues: TFormModel | null,
  groupedRow: Row<GenericGroupedTreatmentTypeDeviationRow<TRow>>,
): Array<TreatmentTypeDeviationWarning> => {
  const deviations = formikValues?.deviations;

  if (!deviations || isEmpty(deviations)) {
    return [];
  }

  const warnings: Array<TreatmentTypeDeviationWarning> = [];

  const hasSubRowWithNegativePriceDeviation = groupedRow.original.deviations?.some(deviation =>
    bulkEditLineFormModelHasNegativePriceDeviation(
      deviations[deviation.siteId][deviation.treatmentTypeId],
    ),
  );
  if (hasSubRowWithNegativePriceDeviation) {
    warnings.push(treatmentTypeDeviationWarnings.NegativePriceDeviationsInGroup);
  }

  const hasSubRowWithNegativeDispenseFeeDeviation = groupedRow.original.deviations?.some(
    deviation =>
      bulkEditLineFormModelHasNegativeDispenseFeeDeviation(
        deviations[deviation.siteId][deviation.treatmentTypeId],
      ),
  );
  if (hasSubRowWithNegativeDispenseFeeDeviation) {
    warnings.push(treatmentTypeDeviationWarnings.NegativeDispenseFeeDeviationsInGroup);
  }

  const containsSubRowsWithDifferentPriceDeviations =
    getUniqueValueOrDefault(
      groupedRow.original.deviations ?? [],
      deviation =>
        getPriceDeviationPercentFromBulkEditLineFormModel(
          deviations[deviation.siteId][deviation.treatmentTypeId],
        ),
      null,
    ) == null;
  if (containsSubRowsWithDifferentPriceDeviations) {
    warnings.push(treatmentTypeDeviationWarnings.DifferentPricesInGroup);
  }

  const containsSubRowsWithDifferentDispenseFeeDeviations =
    getUniqueValueOrDefault(
      groupedRow.original.deviations ?? [],
      deviation =>
        getDispenseFeeDeviationPercentFromBulkEditLineFormModel(
          deviations[deviation.siteId][deviation.treatmentTypeId],
        ),
      null,
    ) == null;
  if (containsSubRowsWithDifferentDispenseFeeDeviations) {
    warnings.push(treatmentTypeDeviationWarnings.DifferentDispenseFeesInGroup);
  }

  return warnings;
};

export const getRowTreatmentTypeDeviationsWarningsForEditablePages = <
  TFormModel extends EditTreatmentTypeDeviationBulkEditFormModel,
  TRow extends TreatmentTypeDeviationTableRow
>(
  formikValues: TFormModel | null,
  row: Row<GenericGroupedTreatmentTypeDeviationRow<TRow>>,
): Array<TreatmentTypeDeviationWarning> => {
  const deviations = formikValues?.deviations;

  if (!deviations || isEmpty(deviations)) {
    return [];
  }

  const warnings: Array<TreatmentTypeDeviationWarning> = [];

  if (
    bulkEditLineFormModelHasNegativePriceDeviation(
      deviations[row.original.siteId][row.original.treatmentTypeId],
    )
  ) {
    warnings.push(treatmentTypeDeviationWarnings.NegativePriceDeviation);
  }

  if (
    bulkEditLineFormModelHasNegativeDispenseFeeDeviation(
      deviations[row.original.siteId][row.original.treatmentTypeId],
    )
  ) {
    warnings.push(treatmentTypeDeviationWarnings.NegativeDispenseFeeDeviation);
  }

  return warnings;
};

const bulkEditLineFormModelHasNegativePriceDeviation = (
  formModel: TreatmentTypeDeviationBulkEditLineFormModel,
): boolean => getPriceDeviationPercentFromBulkEditLineFormModel(formModel) < 0;

const getPriceDeviationPercentFromBulkEditLineFormModel = (
  formModel: TreatmentTypeDeviationBulkEditLineFormModel,
): number => parseFloat(formModel.percentagePriceDeviation ?? '');

const bulkEditLineFormModelHasNegativeDispenseFeeDeviation = (
  formModel: TreatmentTypeDeviationBulkEditLineFormModel,
): boolean => getDispenseFeeDeviationPercentFromBulkEditLineFormModel(formModel) < 0;

const getDispenseFeeDeviationPercentFromBulkEditLineFormModel = (
  formModel: TreatmentTypeDeviationBulkEditLineFormModel,
): number => parseFloat(formModel.percentageDispenseFeeDeviation ?? '');
