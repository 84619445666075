import { TFunction } from 'i18next';
import { keyBy, map } from 'lodash';
import { Id } from '../../../models/id';
import { DropdownOption } from '../../../shared/forms/DropdownField';
import { PmsCode, PmsTypeResponse } from '../../authentication/loginData/metadata';
import { PmsUsage } from '../../items/PmsFields/pmsFields';
import { MerlinGroup } from '../merlinGroups/merlinGroup';

type PmsSite = {
  id: Id;
  name: string;
};

export type MerlinSite = PmsSite & {
  merlinGroupId: number;
};

export type PmsSitesResponse = {
  merlinSites: Array<MerlinSite>;
};

export const pmsSiteDropdownOptions = (sites: Array<PmsSite>): Array<DropdownOption<number>> =>
  map(sites, site => ({ displayText: site.name, value: site.id }));

export const merlinSiteDropdownOptions = (sites: Array<MerlinSite>, merlinGroupId: number | null) =>
  pmsSiteDropdownOptions(sites.filter(site => site.merlinGroupId === merlinGroupId));

// In some cases (e.g. the PMS filter dropdown on the sites page) we need
// separate options for each Merlin group. We do this by setting unique values
// for the Merlin instances (e.g. Merlin-25, Merlin-40, etc.) These values will
// be known as PMS Instances. The display names will be taken from the config.
export type PmsInstance = {
  name: string;
  code: string;
};
export type PmsInstanceByCode = { [code: string]: PmsInstance };

export type PmsInstanceFilterOption = {
  pmsTypeCode: PmsCode;
  merlinGroupId: number | null;
};

export const getPmsInstanceOptions = (
  merlinGroups: Array<MerlinGroup>,
  pmsTypes: Array<PmsTypeResponse>,
  pmsUsage: PmsUsage,
  t: TFunction,
): Array<DropdownOption<string>> =>
  getPmsInstances(merlinGroups, pmsTypes, pmsUsage, t).map(instance => ({
    displayText: instance.name,
    value: instance.code,
  }));

export const getPmsInstancesByCode = (
  merlinGroups: Array<MerlinGroup>,
  pmsTypes: Array<PmsTypeResponse>,
  pmsUsage: PmsUsage,
  t: TFunction,
): PmsInstanceByCode => keyBy(getPmsInstances(merlinGroups, pmsTypes, pmsUsage, t), 'code');

export const getPmsInstances = (
  merlinGroups: Array<MerlinGroup>,
  pmsTypes: Array<PmsTypeResponse>,
  pmsUsage: PmsUsage,
  t: TFunction,
): Array<PmsInstance> =>
  merlinGroups.map(merlinGroup => ({
    name: merlinGroup.name,
    code: pmsCodeAndMerlinGroupToPmsInstanceCode('Merlin', merlinGroup.groupId),
  }));

export const getPmsInstanceCodesFromFilterOptions = (
  filterOptions: Array<PmsInstanceFilterOption> | null,
): Array<string> | null =>
  filterOptions?.map(option => getPmsInstanceCodeFromFilterOption(option)) ?? null;

export const getPmsInstanceCodeFromFilterOption = (filterOption: PmsInstanceFilterOption) =>
  pmsCodeAndMerlinGroupToPmsInstanceCode(filterOption.pmsTypeCode, filterOption.merlinGroupId);

export const pmsCodeAndMerlinGroupToPmsInstanceCode = (
  pmsTypeCode: PmsCode,
  merlinGroupId: number | null,
): string => `${pmsTypeCode}-${merlinGroupId}`;

export const pmsInstanceCodeToPmsCodeAndMerlinGroup = (
  pmsType: string,
): { pmsTypeCode: PmsCode; merlinGroupId: number | null } => {
  const parts = pmsType.split('-');
  const merlinGroupId = parts.length > 1 ? Number(parts[1]) : null;
  return { pmsTypeCode: parts[0] as PmsCode, merlinGroupId };
};
