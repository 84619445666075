const baseAuthUrl = '/auth';

export const loggedOutUrl = `${baseAuthUrl}/logged-out`;
export const signInRedirectUrl = `${baseAuthUrl}/signin-redirect`;
export const silentTokenRefreshUrl = `${baseAuthUrl}/token-refresh`;

export const oidc_config = {
  issuer: 'https://login.microsoftonline.com/8a6cf78f-ef5b-41d5-b3d3-f2f952598362/v2.0',
  authorization_endpoint:
    'https://login.microsoftonline.com/8a6cf78f-ef5b-41d5-b3d3-f2f952598362/oauth2/v2.0/authorize',
  token_endpoint:
    'https://login.microsoftonline.com/8a6cf78f-ef5b-41d5-b3d3-f2f952598362/oauth2/v2.0/token',
  end_session_endpoint:
    'https://login.microsoftonline.com/8a6cf78f-ef5b-41d5-b3d3-f2f952598362/oauth2/logout',
  authority: 'https://login.microsoftonline.com/8a6cf78f-ef5b-41d5-b3d3-f2f952598362',
  client_id: 'b9a16ead-1460-45eb-bd8f-e6cc44521614',
};
