import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticatedApiRequest } from '../../models/apiRequest';
import { FileResponse } from '../../models/fileResponse';
import { styled } from '../../styling/theme';
import { ApiRequestWrapper } from '../higher-order-components/withApiRequest';
import { ButtonProps, HollowButton } from './Button';
import { ExcelDownloadFromCsvFileResponse } from '../../utils/ExcelDownload';

const IconWithLeftMargin = styled(FontAwesomeIcon)`
  margin-left: ${styleProps => styleProps.theme.spacing.tiny}px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ErrorText = styled.p<React.HTMLAttributes<HTMLParagraphElement>>`
  color: ${styleProps => styleProps.theme.colours.alertText};
  transition: height 2s;
  margin-top: ${styleProps => styleProps.theme.spacing.tiny}px;
  margin-bottom: ${styleProps => styleProps.theme.spacing.tiny}px;
`;

const HollowButtonFitContents = styled(HollowButton)`
  width: fit-content;
`;

type OwnProps<TRequestParams> = {
  request: AuthenticatedApiRequest<FileResponse, TRequestParams>;
  requestParams: TRequestParams;
  labelText: string;
  fileName: string;
  sheetName: string;
};

export const DownloadExcelFromCsvResponseButton = <TRequestParams extends object | number>(
  props: OwnProps<TRequestParams> & ButtonProps,
) => {
  const { t } = useTranslation(['serverErrors', 'serverValidation']);

  return (
    <>
      <ApiRequestWrapper request={props.request}>
        {apiProps => (
          <ButtonContainer>
            <HollowButtonFitContents
              onClick={() => {
                apiProps.makeRequest(props.requestParams).then((response: FileResponse) => {
                  if (response != null) {
                    ExcelDownloadFromCsvFileResponse(
                      response,
                      `${props.fileName}.xlsx`,
                      props.sheetName ?? props.fileName,
                    );
                  }
                });
                return true;
              }}
              loading={apiProps.inProgress}
            >
              <span>{props.labelText}</span>
              <IconWithLeftMargin icon={faDownload} />
            </HollowButtonFitContents>
            {apiProps.apiError &&
              map(apiProps.apiError.userVisibleMessages, error => (
                <ErrorText data-testid={'download-error'} key={error.key}>
                  {t(error.key, error.params)}
                </ErrorText>
              ))}
          </ButtonContainer>
        )}
      </ApiRequestWrapper>
    </>
  );
};
