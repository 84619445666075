import { round } from 'lodash';
import { deviationItemIsServiceType } from '../DeviationsBase';
import { DeviationBulkEditLineResponse } from './deviationBulkEdit';

export const getDeviatedValue = (
  recommendedValue: number,
  percentDeviation: number | null,
  absoluteDeviation: number | null,
  isDeviatedByPercent: boolean,
) => {
  if (isDeviatedByPercent) {
    return round(
      percentDeviation ? recommendedValue * (1 + percentDeviation / 100) : recommendedValue,
      4,
    );
  }

  return round(absoluteDeviation ? recommendedValue + absoluteDeviation : recommendedValue, 4);
};

export const getNetDeviatedPriceFrom = (deviation: DeviationBulkEditLineResponse): number =>
  deviation.overwrite
    ? deviation.centralItemPriceNet
    : getDeviatedValue(
        deviation.centralItemPriceNet,
        deviation.percentagePriceDeviationFrom,
        deviation.absolutePriceDeviationNetFrom,
        deviation.priceDeviationIsSetByPercentageFrom,
      );

export const getGrossDeviatedPriceFrom = (deviation: DeviationBulkEditLineResponse): number =>
  deviation.overwrite
    ? deviation.centralItemPriceGross
    : getDeviatedValue(
        deviation.centralItemPriceGross,
        deviation.percentagePriceDeviationFrom,
        deviation.absolutePriceDeviationGrossFrom,
        deviation.priceDeviationIsSetByPercentageFrom,
      );

export const getNetDeviatedPriceTo = (deviation: DeviationBulkEditLineResponse): number =>
  deviation.overwrite
    ? deviation.centralItemPriceNet
    : getDeviatedValue(
        deviation.centralItemPriceNet,
        deviation.percentagePriceDeviationTo,
        deviation.absolutePriceDeviationNetTo,
        deviation.priceDeviationIsSetByPercentageTo,
      );

export const getGrossDeviatedPriceTo = (deviation: DeviationBulkEditLineResponse): number =>
  deviation.overwrite
    ? deviation.centralItemPriceGross
    : getDeviatedValue(
        deviation.centralItemPriceGross,
        deviation.percentagePriceDeviationTo,
        deviation.absolutePriceDeviationGrossTo,
        deviation.priceDeviationIsSetByPercentageTo,
      );

export const getNetDeviatedDispenseFeeFrom = (deviation: DeviationBulkEditLineResponse): number =>
  deviation.overwrite || deviationItemIsServiceType(deviation)
    ? deviation.centralItemDispenseFeeNet
    : getDeviatedValue(
        deviation.centralItemDispenseFeeNet,
        deviation.percentageDispenseFeeDeviationFrom,
        deviation.absoluteDispenseFeeDeviationNetFrom,
        deviation.dispenseFeeDeviationIsSetByPercentageFrom,
      );

export const getGrossDeviatedDispenseFeeFrom = (deviation: DeviationBulkEditLineResponse): number =>
  deviation.overwrite || deviationItemIsServiceType(deviation)
    ? deviation.centralItemDispenseFeeGross
    : getDeviatedValue(
        deviation.centralItemDispenseFeeGross,
        deviation.percentageDispenseFeeDeviationFrom,
        deviation.absoluteDispenseFeeDeviationGrossFrom,
        deviation.dispenseFeeDeviationIsSetByPercentageFrom,
      );

export const getNetDeviatedDispenseFeeTo = (deviation: DeviationBulkEditLineResponse): number =>
  deviation.overwrite || deviationItemIsServiceType(deviation)
    ? deviation.centralItemDispenseFeeNet
    : getDeviatedValue(
        deviation.centralItemDispenseFeeNet,
        deviation.percentageDispenseFeeDeviationTo,
        deviation.absoluteDispenseFeeDeviationNetTo,
        deviation.dispenseFeeDeviationIsSetByPercentageTo,
      );

export const getGrossDeviatedDispenseFeeTo = (deviation: DeviationBulkEditLineResponse): number =>
  deviation.overwrite || deviationItemIsServiceType(deviation)
    ? deviation.centralItemDispenseFeeGross
    : getDeviatedValue(
        deviation.centralItemDispenseFeeGross,
        deviation.percentageDispenseFeeDeviationTo,
        deviation.absoluteDispenseFeeDeviationGrossTo,
        deviation.dispenseFeeDeviationIsSetByPercentageTo,
      );
