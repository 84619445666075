import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ById } from '../../models/id';
import { FieldAndValue } from '../../shared/FieldAndValue';
import { ItemLibrary } from './PmsFields/pmsFields';

export type ReminderTypeGroupViewProps = {
  reminderEnabled: boolean;
  reminderTypeId: number;
  reminderLength: number;
  reminderDelta: string;
  reminderTypeOptions: ById<ItemLibrary>;
};

// Setup the component classes
const ReminderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  gap: ${props => props.theme.spacing.small}px;
`;

// Component Template
export const ReminderTypeGroupView = (props: ReminderTypeGroupViewProps) => {
  const { t } = useTranslation('item');

  return (
    <>
      <FieldAndValue
        fieldName={t('view.field.reminderEnabled')}
        value={props.reminderEnabled}
        type="boolean"
      />

      {props.reminderEnabled && (
        <ReminderContainer>
          <FieldAndValue
            fieldName={t('view.field.reminderTypeId')}
            value={props.reminderTypeOptions[Number(props.reminderTypeId)].name}
            type="text"
            layout="vertical"
          />
          <FieldAndValue
            fieldName={t('view.field.reminderLength')}
            value={props.reminderLength}
            type="text"
            layout="vertical"
          />
          <FieldAndValue
            fieldName={t('view.field.reminderDelta')}
            value={props.reminderDelta}
            type="text"
            layout="vertical"
          />
        </ReminderContainer>
      )}
    </>
  );
};
