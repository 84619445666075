import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../feature/authentication/loginData/userContext';
import { displayCalculatedValueOrSpan } from '../../feature/deviations/DeviationColumns';
import { styled } from '../../styling/theme';
import { formatCurrency, formatNullableCurrencies } from '../../utils/currencyUtils';
import { formatNumber } from '../../utils/numberUtils';
import { CurrencyCode } from '../../models/locale';
import { isEqual } from 'lodash';

export const fieldIsEmptyValue = '-';

const ChangedAttributeTextStyle = styled.p`
  font-weight: bold;
  margin-bottom: 0;
  margin-top: ${styleProps => styleProps.theme.spacing.tiny}px;
  color: ${styleProps => styleProps.theme.colours.infoText};
`;

type OldAttributeTextStyleProps = React.HTMLAttributes<HTMLParagraphElement> & {
  floatRight?: boolean;
};
const OldAttributeTextStyle = styled.p<OldAttributeTextStyleProps>`
  font-style: italic;
  font-size: ${styleProps => styleProps.theme.typography.subtext.fontSize}px;
  margin-top: 0;
  margin-bottom: ${styleProps => styleProps.theme.spacing.tiny}px;
  color: ${styleProps => styleProps.theme.colours.alertText};
  ${props =>
    props.floatRight &&
    `
    display: flex;
    justify-content: flex-end;
  `}
`;

type ChangeCellProps = {
  oldValue: number | string | null;
  newValue?: number | string | null;
  calculatedOld?: boolean;
  calculatedNew?: boolean;
} & OldAttributeTextStyleProps;

const toDisplayValue = (
  value: string | number | null,
  calculated?: boolean | undefined,
  floatRight: boolean = true,
) =>
  value
    ? displayCalculatedValueOrSpan(value.toString(), calculated, floatRight, false)
    : fieldIsEmptyValue;

export const ChangeCell = ({
  oldValue,
  newValue,
  calculatedNew,
  calculatedOld,
  floatRight,
}: ChangeCellProps) => {
  const { t } = useTranslation('component');

  if (emptyNullOrUndefined(oldValue) && emptyNullOrUndefined(newValue)) {
    return displayCalculatedValueOrSpan(oldValue, calculatedNew, floatRight || false, false);
  }

  return (
    <>
      {oldValue === newValue &&
        displayCalculatedValueOrSpan(oldValue, calculatedNew, floatRight || false, false)}
      {oldValue !== newValue && (
        <div>
          <ChangedAttributeTextStyle>
            {newValue == null
              ? fieldIsEmptyValue
              : toDisplayValue(newValue, calculatedNew, floatRight)}
          </ChangedAttributeTextStyle>
          <OldAttributeTextStyle floatRight={floatRight}>
            {t('changeCell.was', {
              previousValue: calculatedOld
                ? fieldIsEmptyValue
                : oldValue
                ? oldValue.toString()
                : fieldIsEmptyValue,
            })}
          </OldAttributeTextStyle>
        </div>
      )}
    </>
  );
};
type ChangeBooleanCellProps = {
  oldValue: boolean;
  newValue?: boolean;
};
export const ChangeBooleanCell = ({ oldValue, newValue }: ChangeBooleanCellProps) => {
  const { t } = useTranslation('component');

  return (
    <>
      {oldValue === newValue && (
        <span>{newValue ? t('fieldAndValue.yes') : t('fieldAndValue.no')}</span>
      )}
      {oldValue !== newValue && (
        <div>
          <ChangedAttributeTextStyle>
            {newValue == null
              ? fieldIsEmptyValue
              : newValue
              ? t('fieldAndValue.yes')
              : t('fieldAndValue.no')}
          </ChangedAttributeTextStyle>
          <OldAttributeTextStyle>
            {t('changeCell.was', {
              previousValue: oldValue ? t('fieldAndValue.yes') : t('fieldAndValue.no'),
            })}
          </OldAttributeTextStyle>
        </div>
      )}
    </>
  );
};

const emptyNullOrUndefined = (value: string | number | undefined | null) =>
  value === undefined || value === null || (typeof value === 'string' && value === '');

type ChangeNumberCellProps = {
  oldValue: number | null;
  newValue?: number | null;
};
export const ChangeNumberCell = ({ oldValue, newValue }: ChangeNumberCellProps) => {
  const { t } = useTranslation('component');
  const { user } = useContext(UserContext);

  return (
    <>
      {oldValue === newValue && (
        <span>{newValue != null && formatNumber(newValue, user.locale)}</span>
      )}
      {oldValue !== newValue && (
        <div>
          <ChangedAttributeTextStyle>
            {newValue == null ? fieldIsEmptyValue : formatNumber(newValue, user.locale)}
          </ChangedAttributeTextStyle>
          <OldAttributeTextStyle>
            {t('changeCell.was', {
              previousValue:
                oldValue != null ? formatNumber(oldValue, user.locale) : fieldIsEmptyValue,
            })}
          </OldAttributeTextStyle>
        </div>
      )}
    </>
  );
};

type ChangePriceCellProps = {
  oldValueNet: number | null;
  oldValueGross: number | Array<number | null> | null;
  newValueNet?: number | null;
  newValueGross?: number | Array<number | null> | null;
  calculatedOld?: boolean;
  calculatedNew?: boolean;
  currency?: Array<CurrencyCode> | CurrencyCode;
} & OldAttributeTextStyleProps;

export const ChangePriceCell = ({
  oldValueNet,
  oldValueGross,
  newValueNet,
  newValueGross,
  calculatedOld,
  calculatedNew,
  floatRight,
  currency,
}: ChangePriceCellProps) => {
  const { user } = useContext(UserContext);
  const oldValue = user.useGrossPrices
    ? Array.isArray(oldValueGross)
      ? oldValueGross
      : [oldValueGross]
    : [oldValueNet];
  const newValue = user.useGrossPrices
    ? Array.isArray(newValueGross)
      ? newValueGross
      : [newValueGross]
    : [newValueNet];
  const currencies = currency ? (Array.isArray(currency) ? currency : [currency]) : undefined;

  return (
    <UntaxedChangePriceCell
      oldValue={oldValue}
      newValue={newValue}
      calculatedOld={calculatedOld}
      calculatedNew={calculatedNew}
      floatRight={floatRight}
      currencies={currencies}
    />
  );
};

type UntaxedChangePriceCellProps = {
  oldValue: Array<number | null> | null;
  newValue?: Array<number | null | undefined> | null;
  calculatedOld?: boolean;
  calculatedNew?: boolean;
  currencies?: Array<CurrencyCode>;
} & OldAttributeTextStyleProps;

const UntaxedChangePriceCell = ({
  oldValue,
  newValue,
  calculatedOld,
  calculatedNew,
  floatRight,
  currencies,
}: UntaxedChangePriceCellProps) => {
  const { t } = useTranslation('component');
  const { user } = useContext(UserContext);
  const currencyToUse = currencies ?? [user.currency];

  return (
    <>
      {isEqual(oldValue, newValue) &&
        oldValue != null &&
        oldValue.length > 0 &&
        displayCalculatedValueOrSpan(
          formatNullableCurrencies(oldValue, user.locale, currencyToUse),
          calculatedNew,
          true,
          false,
        )}
      {!isEqual(oldValue, newValue) && (
        <div>
          <ChangedAttributeTextStyle>
            {newValue == null || newValue.every(value => value == null)
              ? fieldIsEmptyValue
              : displayCalculatedValueOrSpan(
                  formatNullableCurrencies(newValue, user.locale, currencyToUse),
                  calculatedNew,
                  true,
                  false,
                )}
          </ChangedAttributeTextStyle>
          <OldAttributeTextStyle floatRight={floatRight}>
            {t('changeCell.was', {
              previousValue:
                calculatedOld || oldValue == null || oldValue.every(value => value == null)
                  ? fieldIsEmptyValue
                  : formatNullableCurrencies(oldValue, user.locale, currencyToUse),
            })}
          </OldAttributeTextStyle>
        </div>
      )}
    </>
  );
};

const InlineChangedAttributeTextStyle = styled(ChangedAttributeTextStyle)`
  font-size: ${styleProps => styleProps.theme.typography.subtext.fontSize}px;
`;

type InlineChangeDisplayProps = {
  newValue: string;
  fieldName: string;
};

const InlineChangeDisplay = ({ newValue, fieldName }: InlineChangeDisplayProps) => {
  const { t } = useTranslation('component');

  return (
    <InlineChangedAttributeTextStyle>
      {t('changeCell.inlineChange', {
        fieldName,
        newValue,
      })}
    </InlineChangedAttributeTextStyle>
  );
};

type InlineOriginalDisplayProps = {
  oldValue: string | number | null;
};
const InlineOriginalDisplay = ({ oldValue }: InlineOriginalDisplayProps) => {
  const { t } = useTranslation('component');

  return (
    <OldAttributeTextStyle>
      {t('changeCell.was', {
        previousValue: oldValue,
      })}
    </OldAttributeTextStyle>
  );
};

type InlineChangeProps = {
  oldValue: string | number | null;
  newValue: string | number | null;
  fieldName: string;
};
export const InlineChange = ({ oldValue, newValue, fieldName }: InlineChangeProps) => {
  if (oldValue === newValue) {
    return null;
  }

  return (
    <div>
      <InlineChangeDisplay
        newValue={newValue ? newValue.toString() : fieldIsEmptyValue}
        fieldName={fieldName}
      />
      <InlineOriginalDisplay oldValue={oldValue?.toString() ?? fieldIsEmptyValue} />
    </div>
  );
};

type InlineNumberChangeProps = {
  oldValue: number | null;
  newValue: number | null;
  fieldName: string;
};
export const InlineNumberChange = ({ oldValue, newValue, fieldName }: InlineNumberChangeProps) => {
  const { user } = useContext(UserContext);

  if (oldValue === newValue) {
    return null;
  }

  if (newValue == null) {
    return <InlineChangeDisplay newValue={fieldIsEmptyValue} fieldName={fieldName} />;
  }

  return (
    <InlineChangeDisplay newValue={formatNumber(newValue, user.locale)} fieldName={fieldName} />
  );
};

type InlinePriceChangeProps = {
  oldValue: number | null;
  newValue: number | null;
  fieldName: string;
};
export const InlinePriceChange = ({ oldValue, newValue, fieldName }: InlinePriceChangeProps) => {
  const { user } = useContext(UserContext);

  if (oldValue === newValue) {
    return null;
  }

  if (newValue == null) {
    return <InlineChangeDisplay newValue={fieldIsEmptyValue} fieldName={fieldName} />;
  }

  return (
    <InlineChangeDisplay
      newValue={formatCurrency(newValue, user.locale, user.currency)}
      fieldName={fieldName}
    />
  );
};

type InlineGrossPriceChangeProps = {
  oldValues: Array<number | null> | null;
  newValues: Array<number | null> | null;
  fieldName: string;
  currencies: Array<CurrencyCode>;
};
export const InlineGrossPriceChange = ({
  oldValues,
  newValues,
  fieldName,
  currencies,
}: InlineGrossPriceChangeProps) => {
  const { user } = useContext(UserContext);

  if (isEqual(oldValues, newValues)) {
    return null;
  }

  if (newValues == null || newValues.every(value => value === null)) {
    return <InlineChangeDisplay newValue={fieldIsEmptyValue} fieldName={fieldName} />;
  }

  return (
    <InlineChangeDisplay
      newValue={formatNullableCurrencies(newValues, user.locale, currencies)}
      fieldName={fieldName}
    />
  );
};

type InlineBooleanChangeProps = {
  oldValue: boolean | null;
  newValue: boolean | null;
  fieldName: string;
};
export const InlineBooleanChange = ({
  oldValue,
  newValue,
  fieldName,
}: InlineBooleanChangeProps) => {
  const { t } = useTranslation('component');

  if (oldValue === newValue) {
    return null;
  }

  if (newValue == null) {
    return <InlineChangeDisplay newValue={fieldIsEmptyValue} fieldName={fieldName} />;
  }

  return (
    <InlineChangeDisplay
      newValue={newValue ? t('fieldAndValue.yes') : t('fieldAndValue.no')}
      fieldName={fieldName}
    />
  );
};
