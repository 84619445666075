import { FormikProps } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormFieldGroup,
  FormFieldGroupContainer,
  FormGroupTitle,
} from '../../shared/forms/FormContainers';
import { InputField } from '../../shared/forms/InputField';
import { SingleSelectDropdownField } from '../../shared/forms/SingleSelectDropdownField';
import { CreateOrEditItemFormModel, ItemResponse } from './item';
import {
  itemCategory2Options,
  ItemFieldsFromPmsResponse,
  itemLibraryDropdownOptions,
  itemTypeDropdownOptions,
  filterCategory1OptionsByType,
} from './PmsFields/pmsFields';

type ItemInformationGroupProps = {
  itemFieldsFromPms: ItemFieldsFromPmsResponse;
  existingItem?: ItemResponse;
  formikProps: FormikProps<CreateOrEditItemFormModel>;
  setIsServiceType: (value: boolean) => void;
};

export const ItemInformationGroup = (props: ItemInformationGroupProps) => {
  const { t } = useTranslation('item');

  const onTypeChange = (value: unknown) => {
    props.setIsServiceType(value === 'Service');
  };

  const getCategory2Options = (formikProps: FormikProps<CreateOrEditItemFormModel>) =>
    itemCategory2Options(props.itemFieldsFromPms, formikProps.values.type);

  return (
    <FormFieldGroupContainer>
      <FormGroupTitle>{t('itemLabels.itemInformationGroupTitle')}</FormGroupTitle>
      <FormFieldGroup>
        <SingleSelectDropdownField
          name="type"
          label={t('itemLabels.type')}
          options={itemTypeDropdownOptions(props.itemFieldsFromPms, t)}
          disabled={!!props.existingItem}
          onChange={onTypeChange}
        />
        <InputField name="itemName" label={t('itemLabels.itemName')} />
        <SingleSelectDropdownField
          name="category1Id"
          label={t('itemLabels.category1')}
          options={filterCategory1OptionsByType(
            props.itemFieldsFromPms.category1Options,
            props.formikProps.values.type,
          )}
          disabled={!!props.existingItem}
        />
        <SingleSelectDropdownField
          name="category2Id"
          label={t('itemLabels.category2')}
          options={itemLibraryDropdownOptions(getCategory2Options(props.formikProps))}
        />
        <SingleSelectDropdownField
          name="category3Id"
          label={t('itemLabels.category3')}
          options={itemLibraryDropdownOptions(props.itemFieldsFromPms.category3Options)}
        />
        <InputField
          name="itemCode"
          label={t('itemLabels.itemCode')}
          disabled={!!props.existingItem}
        />
      </FormFieldGroup>
    </FormFieldGroupContainer>
  );
};
