import { FormikProps } from 'formik';
import { filter, find, first, flowRight, map, union } from 'lodash';
import { ReactElement, useEffect, useState, useContext } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Id } from '../../../models/id';
import { DataLoader } from '../../../shared/DataLoader';
import { DropdownOption } from '../../../shared/forms/DropdownField';
import { SingleSelectDropdownField } from '../../../shared/forms/SingleSelectDropdownField';
import {
  ApiRequestProps,
  withApiRequest,
} from '../../../shared/higher-order-components/withApiRequest';
import { getSuppliersForSupplierList } from '../../suppliers/supplier-list/supplierListApi';
import { CountrySpecificItemDetailsResponse, CreateOrEditItemFormModel } from '../item';
import { getUnassignedProductsForSupplier } from './itemSupplierProductsApi';
import {
  ItemSupplierProductResponse,
  UnassignedSupplierProductResponse,
  UnassignedSupplierProductsResponse,
} from './supplierProduct';
import { CountryCode, CountryResponse } from '../../authentication/loginData/metadata';
import { CountrySpecificItemDetailsNamePrefix } from '../CountryDetailsGroup';
import { CurrencyField } from '../../../shared/forms/CurrencyField';
import { UserContext } from '../../authentication/loginData/userContext';

type OwnProps = {
  children: (
    supplierField?: ReactElement,
    supplierProductField?: ReactElement,
    supplierProductListPriceField?: ReactElement,
  ) => React.ReactNode;
  itemIsServiceType: boolean;
  formikProps?: FormikProps<CreateOrEditItemFormModel>;
  initialSupplierProduct?: ItemSupplierProductResponse;
  country: CountryResponse;
  index: number;
};
type SupplierAndSupplierProductFieldsLoaderProps = OwnProps &
  ApiRequestProps<UnassignedSupplierProductsResponse, Id>;

export const getSupplierProductForCountry = (
  countrySpecificItemDetailsList: Array<CountrySpecificItemDetailsResponse>,
  country: CountryCode,
): ItemSupplierProductResponse | undefined =>
  countrySpecificItemDetailsList?.find(csd => csd.country.code === country)
    ?.itemSupplierProductResponse;

export const getSupplierProductOptionDisplayText = (
  supplierProduct: UnassignedSupplierProductResponse,
) => `${supplierProduct.supplierCode}: ${supplierProduct.productName}`;

export const getAllSupplierProductsMatchingSupplierId = (
  supplierProducts: Array<UnassignedSupplierProductResponse>,
  supplierId: number,
) => supplierProducts.filter(supplierProduct => supplierProduct.supplierId === supplierId);

export const supplierProductsNamePrefix = (index: number): string =>
  `${CountrySpecificItemDetailsNamePrefix(index)}.supplierProductDetails`;

const supplierProductToDropdownOption = (supplierProduct: UnassignedSupplierProductResponse) => ({
  displayText: getSupplierProductOptionDisplayText(supplierProduct),
  value: supplierProduct.supplierProductId,
});

const supplierProductsResponseToDropdownOptions = (
  supplierProducts: Array<UnassignedSupplierProductResponse>,
): Array<DropdownOption<number>> =>
  map(supplierProducts, sp => supplierProductToDropdownOption(sp));

const SupplierAndSupplierProductFieldsLoaderComponent = (
  props: SupplierAndSupplierProductFieldsLoaderProps,
) => {
  const maxItemsToDisplay = 1000;
  const { t } = useTranslation('item');
  const { user } = useContext(UserContext);

  const [supplierProductsForSelectedSupplier, setSupplierProductsForSelectedSupplier] = useState<
    Array<UnassignedSupplierProductResponse>
  >([]);

  const [
    supplierProductsOptionsForSelectedSupplier,
    setSupplierProductsOptionsForSelectedSupplier,
  ] = useState<Array<DropdownOption<number | null>>>([]);

  const [filteredSupplierProductsOptions, setFilteredSupplierProductsOptions] = useState<
    Array<DropdownOption<number | null>>
  >([]);

  const [isProductDropdownDisabled, setIsProductDropdownDisabled] = useState(true);

  /**
   * Jira - pp-997
   * When fetching the items for the supplier, create a clone that
   * we can filter and then use that for the suppluier product display
   *
   * @param supplierId
   * @param selectedItem
   * @returns
   */
  const updateSupplierProductOptions = (supplierId: number, selectedItem?: number) => {
    setIsProductDropdownDisabled(true);
    setSupplierProductsForSelectedSupplier([]);
    setSupplierProductsOptionsForSelectedSupplier([
      { displayText: t('supplierProducts.form.loading'), value: null },
    ]);

    return props.makeRequest(supplierId).then(response => {
      const supplierProducts = (response?.supplierProducts ?? []).concat(
        props.initialSupplierProduct ? [props.initialSupplierProduct] : [],
      );

      const productOptions = supplierProductsResponseToDropdownOptions(supplierProducts);
      setSupplierProductsForSelectedSupplier(supplierProducts);

      if (productOptions.length === 0) {
        setSupplierProductsOptionsForSelectedSupplier([
          { displayText: t('supplierProducts.form.noProducts'), value: null },
        ]);
      } else {
        setSupplierProductsOptionsForSelectedSupplier(productOptions);
        setFilteredSupplierProductsOptions(filterProductsForDisplay(productOptions));
        setIsProductDropdownDisabled(false);
      }
    });
  };

  /**
   * Return a filterd list for display, include the selected item
   * if there is one
   * @param productOptions
   * @returns
   */
  const filterProductsForDisplay = (productOptions: Array<DropdownOption<number>>) => {
    if (props.initialSupplierProduct?.supplierProductId) {
      const selected = productOptions.filter(
        m => m.value === props.initialSupplierProduct!.supplierProductId,
      );
      return union(productOptions.slice(0, maxItemsToDisplay), selected);
    }

    return productOptions.slice(0, maxItemsToDisplay);
  };

  const onSupplierChange = (supplierId: number) => {
    updateSupplierProductOptions(supplierId).then();
  };

  const supplierFieldName = `${supplierProductsNamePrefix(props.index)}.supplierId`;
  const SupplierField = (
    <DataLoader apiRequest={getSuppliersForSupplierList}>
      {supplierProps => (
        <SingleSelectDropdownField
          name={supplierFieldName}
          label={t('supplierProducts.form.supplier')}
          options={map(
            filter(supplierProps.response.suppliers, s => s.supplierCountry === props.country.code),
            supplier => ({
              displayText: supplier.supplierName,
              value: supplier.supplierId,
            }),
          )}
          onChange={value => onSupplierChange(Number(value))}
          deselectable={props.itemIsServiceType}
        />
      )}
    </DataLoader>
  );

  const onSupplierProductChange = (newSupplierProductId: number | null) => {
    const supplierProduct =
      supplierProductsForSelectedSupplier.find(
        sp => sp.supplierProductId === newSupplierProductId,
      ) ?? null;

    props.formikProps?.setFieldValue(supplierProductListPriceFieldName, supplierProduct?.listPrice);
  };

  /**
   * Jira pp-996
   * When the item selection changes, filter the supplier list
   * by the user selection
   * @param value
   */
  const handleOnInputValueChange = (value: string) => {
    const lowered = value.toLocaleLowerCase();
    setFilteredSupplierProductsOptions(
      supplierProductsOptionsForSelectedSupplier
        .filter(m => m.displayText.toLocaleLowerCase().includes(lowered))
        .slice(0, maxItemsToDisplay),
    );
  };

  const supplierProductFieldName = `${supplierProductsNamePrefix(props.index)}.supplierProductId`;
  const SupplierProductField = (
    <SingleSelectDropdownField
      name={supplierProductFieldName}
      label={t('supplierProducts.form.supplierCode')}
      options={filteredSupplierProductsOptions}
      disabled={isProductDropdownDisabled}
      onChange={onSupplierProductChange}
      onInputValueChange={handleOnInputValueChange}
      showCopyToClipboardButton={true}
    />
  );

  const supplierProductListPriceFieldName = `${supplierProductsNamePrefix(props.index)}.listPrice`;
  const SupplierProductListPriceField = (
    <CurrencyField
      name={supplierProductListPriceFieldName}
      label={
        user.useGrossPrices
          ? t('supplierProducts.form.listPriceGross')
          : t('supplierProducts.form.listPriceNet')
      }
      currency={props.country.currency}
      onChange={(value, form) => {
        form.setFieldValue(supplierProductListPriceFieldName, value);
      }}
    />
  );

  useEffect(() => {
    const supplierProduct = props.initialSupplierProduct ?? null;
    if (props.formikProps && supplierProduct) {
      props.formikProps?.setFieldValue(supplierFieldName, supplierProduct.supplierId);
      updateSupplierProductOptions(
        supplierProduct.supplierId,
        supplierProduct.supplierProductId,
      ).then(() => {
        props.formikProps?.setFieldValue(
          supplierProductFieldName,
          supplierProduct.supplierProductId,
        );

        const listPrice = user.useGrossPrices
          ? supplierProduct.listPriceGross
          : supplierProduct.listPrice;

        props.formikProps?.setFieldValue(supplierProductListPriceFieldName, listPrice);
      });
    }
  }, []);

  return props.children(SupplierField, SupplierProductField, SupplierProductListPriceField);
};

const enhance = flowRight(
  withApiRequest<OwnProps, UnassignedSupplierProductsResponse, Id>(
    getUnassignedProductsForSupplier,
  ),
);

export const SupplierAndSupplierProductFieldsLoader = enhance(
  SupplierAndSupplierProductFieldsLoaderComponent,
);
