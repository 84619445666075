import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxField } from '../../shared/forms/CheckboxField';
import { SingleSelectDropdownField } from '../../shared/forms/SingleSelectDropdownField';
import { ById } from '../../models/id';
import {
  ItemLibrary,
  reminderItemLibraryDropdownOptions,
  stringToDropDownOptions,
} from './PmsFields/pmsFields';
import styled from 'styled-components';
import { InputField } from '../../shared/forms/InputField';
import { FormikProps } from 'formik';
import { CreateOrEditItemFormModel } from './item';

export type ReminderTypeGroupProps = {
  reminderTypeOptions: ById<ItemLibrary>;
  reminderDeltaOptions: Array<string>;
  formikProps: FormikProps<CreateOrEditItemFormModel>;
};

// Setup the component classes
const ReminderContainer = styled.div`
  display: inline-flex;
`;

// Component Template
export const ReminderTypeGroup = (props: ReminderTypeGroupProps) => {
  const { t } = useTranslation('item');

  return (
    <>
      <CheckboxField
        name="reminderEnabled"
        label={t('createAndEditForm.labels.reminderEnabled')}
        labelPosition={'right'}
      />

      {props.formikProps.values.reminderEnabled && (
        <ReminderContainer>
          <SingleSelectDropdownField
            name="reminderTypeId"
            label={t('createAndEditForm.labels.reminderTypeId')}
            options={reminderItemLibraryDropdownOptions(
              props.reminderTypeOptions,
              props.formikProps.values.category1Id,
            )}
          />

          <InputField
            name="reminderLength"
            label={t('createAndEditForm.labels.reminderLength')}
            type="number"
          />

          <SingleSelectDropdownField
            name="reminderDelta"
            label={t('createAndEditForm.labels.reminderDelta')}
            options={stringToDropDownOptions(props.reminderDeltaOptions)}
          />
        </ReminderContainer>
      )}
    </>
  );
};
