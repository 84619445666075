import * as React from 'react';
import { useContext } from 'react';
import { ThemeProps } from 'styled-components';
import { ThemeContext } from 'styled-components/macro';
import { styled, Theme } from '../../styling/theme';
import { LoadingCircle } from '../LoadingIndicator';

export const buttonBorderWidth = 2;
const buttonHorizontalPadding = 16;
const tinyButtonHorizontalPadding = 10;
const buttonLineHeight = 1.4;

type ButtonSize = 'tiny' | 'medium';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  loadingColour?: string;
  size?: ButtonSize;
  color?: string;
  backgroundColor?: string;
};

const getVerticalPaddingSizePixels = (props: { size?: ButtonSize } & ThemeProps<Theme>) => {
  if (props.size === 'tiny') {
    return props.theme.spacing.extraTiny;
  } else {
    return props.theme.spacing.tiny;
  }
};

const getVerticalPaddingSize = (props: { size?: ButtonSize } & ThemeProps<Theme>) =>
  `${getVerticalPaddingSizePixels(props)}px`;

const getHorizontalPaddingSizePixels = (props: { size?: ButtonSize } & ThemeProps<Theme>) =>
  props.size === 'tiny' ? tinyButtonHorizontalPadding : buttonHorizontalPadding;

const getHorizontalPaddingSize = (props: { size?: ButtonSize } & ThemeProps<Theme>) =>
  `${getHorizontalPaddingSizePixels(props)}px`;

const LoadingSpinnerContainer = styled.div<
  React.HTMLAttributes<HTMLDivElement> & { size?: ButtonSize }
>`
  height: ${buttonLineHeight}em;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${LoadingCircle} {
    margin-right: 2px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const VisibleIfNotLoading = styled.span<
  React.HTMLAttributes<HTMLSpanElement> & { isLoading?: boolean }
>`
  visibility: ${props => (props.isLoading ? 'hidden' : 'visible')};
`;

type LoadingSpinnerProps = { colour?: string; size?: ButtonSize };
export const LoadingSpinner = (props: LoadingSpinnerProps) => (
  <LoadingSpinnerContainer size={props.size}>
    <LoadingCircle colour={props.colour} delay={0} />
    <LoadingCircle colour={props.colour} delay={160} />
    <LoadingCircle colour={props.colour} delay={320} />
  </LoadingSpinnerContainer>
);

const ButtonComponent = ({
  loading,
  loadingColour,
  color,
  backgroundColor,
  ...buttonProps
}: ButtonProps) => (
  <button {...buttonProps} disabled={buttonProps.disabled || loading}>
    <VisibleIfNotLoading isLoading={loading}>{buttonProps.children}</VisibleIfNotLoading>
    {loading && <LoadingSpinner colour={loadingColour} size={buttonProps.size} />}
  </button>
);

export const Button = styled(ButtonComponent)`
  cursor: pointer;
  border: none;
  font-weight: bold;
  font-size: ${props =>
    props.size === 'tiny'
      ? props.theme.typography.small.fontSize
      : props.theme.typography.content.fontSize}px;
  line-height: ${buttonLineHeight};
  position: relative;
  color: ${props => props.color || 'inherit'}; // Apply the color prop
  background-color: ${props => props.backgroundColor || 'inherit'}; // Apply the color prop

  padding: ${getVerticalPaddingSize} ${getHorizontalPaddingSize};

  &:disabled {
    cursor: default;
    opacity: 0.6;
  }
`;

export const PrimaryButton = styled(Button)`
  background-color: ${props => props.theme.colours.buttons.primary};
  color: ${props => props.theme.colours.buttons.primaryTextColour};

  &:enabled {
    &:hover,
    &:focus {
      background-color: ${props => props.theme.colours.buttons.primaryHighlight};
    }
  }
`;

export const SecondaryButton = styled(Button)`
  color: ${props => props.theme.colours.buttons.secondaryTextColour};

  &:enabled {
    &:hover,
    &:focus {
      background-color: ${props => props.theme.colours.buttons.secondaryHighlight};
      color: ${props => props.theme.colours.buttons.primaryTextColour};
    }
  }
`;

export const AlertButton = styled(Button)`
  background-color: ${props => props.theme.colours.alertBackground};
  color: ${props => props.theme.colours.alertText};

  &:enabled {
    &:hover,
    &:focus {
      background-color: ${props => props.theme.colours.alertText};
      color: ${props => props.theme.colours.alertBackground};
    }
  }
`;

const HollowButtonComponent = styled(Button)`
  background-color: transparent;
  color: ${props => props.theme.colours.buttons.primary};
  border: ${buttonBorderWidth}px solid ${props => props.theme.colours.buttons.primary};
  padding: ${props => getVerticalPaddingSizePixels(props) - buttonBorderWidth}px
    ${props => getHorizontalPaddingSizePixels(props) - buttonBorderWidth}px;

  &:enabled {
    &:hover,
    &:focus {
      color: ${props => props.theme.colours.buttons.primaryHighlight};
      border: ${buttonBorderWidth}px solid ${props => props.theme.colours.buttons.primaryHighlight};
    }
  }
`;

export const HollowButton = (props: ButtonProps) => {
  const themeContext: Theme = useContext(ThemeContext);
  return <HollowButtonComponent {...props} loadingColour={themeContext.colours.buttons.primary} />;
};

const LinkStyledButton = styled.button`
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  border: none;
  background: none;
  padding: 0;
`;

export const PrimaryLinkStyledButton = styled(LinkStyledButton)<
  React.ButtonHTMLAttributes<HTMLButtonElement>
>`
  color: ${props => (props.disabled ? null : props.theme.colours.primary)};

  &:hover {
    color: ${props => (props.disabled ? null : props.theme.colours.primaryHighlight)};
  }
`;

export const SecondaryLinkStyledButton = styled(LinkStyledButton)<
  React.ButtonHTMLAttributes<HTMLButtonElement>
>`
  color: ${props => props.theme.colours.secondary};

  &:hover {
    color: ${props => props.theme.colours.secondaryHighlight};
  }
`;

export const ButtonGroup = styled.div`
  > * {
    margin-right: ${props => props.theme.spacing.extraSmall}px;

    :last-child {
      margin-right: 0;
    }
  }

  display: flex;
  flex-flow: row nowrap;
  margin-bottom: ${props => props.theme.spacing.medium}px;
`;

export const ButtonLabel = styled.label`
  cursor: pointer;
`;
