import { TFunction } from 'i18next';
import { DateTimeStamp } from '../../models/dates/dateTimeStamp';
import { Id } from '../../models/id';
import { Locale } from '../../models/locale';
import { FileUploadResponse } from '../../shared/files/fileUploadResponse';
import { BeLessThan, BeValidCurrency } from '../../shared/forms/CurrencyField';
import { Filterable } from '../../shared/tables/filtering/useFiltering';
import { PagedRequest } from '../../shared/tables/pagination/pagination';
import { TableDataFetchResponse } from '../../shared/tables/Table';
import { isCSharpInteger } from '../../utils/numberUtils';
import {
  CentralMarkupPercentageMaxValue,
  DispenseFeeExclusiveMaxValue,
  InternalInformationMaxLength,
  ItemCodeMaxLength,
  PercentageDiscountMaxValue,
  PriceFieldExclusiveMaxValue,
} from '../../utils/validation/itemValidationConstants';
import { BeValidDecimal, BeValidInteger } from '../../utils/validation/numberValidation';
import { TranslatableValidator } from '../../utils/validation/TranslatableValidator';
import {
  InputMaximumLength,
  MaximumAdditionaInfoLength,
  MaxPercentage,
  TextAreaMaximumLength,
} from '../../utils/validation/validationConstants';
import {
  SupplierProductCommand,
  SupplierProductDetailsFormModel,
  ItemSupplierProductResponse,
  NewOrExistingSupplierProductFormModel,
} from './ItemSupplierProducts/supplierProduct';
import { NotesValidator } from './NotesEditValidator';
import { ItemPriceField, ItemType, PmsUsage } from './PmsFields/pmsFields';
import { CountryCode, CountryResponse } from '../authentication/loginData/metadata';
import { SortResponse } from '../../shared/tables/sorting/sorting';
import { BarcodeValidator } from './BarcodeValidator';

export type ItemColumnTypes =
  | 'flags'
  | 'itemName'
  | 'itemCode'
  | 'lastUpdated'
  | 'centralPriceNet'
  | 'centralPriceGross'
  | 'centralMarkupPercentage'
  | 'unitsPerPack'
  | 'type'
  | 'species'
  | 'category1'
  | 'category2'
  | 'category3';

export const validItemColumnTypeOrUndefined = (input: string): ItemColumnTypes | undefined =>
  input === 'flags' ||
  input === 'itemName' ||
  input === 'itemCode' ||
  input === 'lastUpdated' ||
  input === 'centralPriceNet' ||
  input === 'centralPriceGross' ||
  input === 'centralMarkupPercentage' ||
  input === 'unitsPerPack' ||
  input === 'type' ||
  input === 'species' ||
  input === 'category1' ||
  input === 'category2' ||
  input === 'category3'
    ? input
    : undefined;

export type ItemFlags = {
  stockWithEmptySupplier: boolean;
  bulkEditScheduled: boolean;
  disabled: boolean;
  discontinuedProduct: boolean;
  scheduledItemBulkEditId: Id | null;
};

type BaseItemResponse = {
  flags: ItemFlags;
  itemId: Id;
  itemName: string;
  itemCode: string;
  type: ItemTypeCode;
  labelAdditionalInfo: string | null;
  speciesId: number | null;
  category1Id: number | null;
  category2Id: number;
  unitsPerPack: number | null;
  dispenseFeeNet: number | null;
  minimumPriceNet: number | null;
  requestLabel: boolean;
  labelCalcQuantity: boolean;
  barcode: Array<BarcodeDetails> | null;
  shouldAlterSex: boolean;
  internalInformation: string | null;
  notes: string | null;
  recommendedPriceNet: number | null;
  centralMarkupPercentage: number | null;
  percentageDiscount: number | null;
  absoluteDiscountNet: number | null;
  isBatchRequest: boolean;
  isDisabled: boolean;
  lastUpdated: DateTimeStamp;

  category3Id: number | null;
  clientCategoryId: number | null;
  shouldPromptChipDetails: boolean | null;
  shouldRequestQuantity: boolean | null;
  isHiddenForAllPractices: boolean | null;
  shouldSellAtCost: boolean | null;
  hcpGroupId: number | null;
  hcpMonthlyMultiplier: number | null;
  manufacturerId: number | null;

  preventHide: boolean | null;
  wormer: boolean | null;
  flea: boolean | null;
  repeatPrescription: boolean | null;
  labelDispensingNote: Array<LabelDispensingNote> | null;
  wholepackPriceEnabled: boolean | null;
  wholepackExcludeDispFee: boolean | null;
  isMultiDispensingFee: boolean | null;
  netNetDiscount: number | null;
  netNetNetDiscount: number | null;
  costTypeId: number | null;
  discountCategory: Array<DiscountCategory> | null;
  discountCategoryEnabled: boolean | null;
  documentTemplateId: number | null;
  reminderEnabled: boolean | null;
  reminderTypeId: number | null;
  reminderLength: number | null;
  reminderDelta: string | null;
  sendTo: Array<number> | null;
  countrySpecificItemDetailsResponseList: Array<CountrySpecificItemDetailsResponse>;
  noteDetails: Array<Notes> | null;
};

export type CountrySpecificGrossValues = {
  dispenseFeeGross: number | null;
  minimumPriceGross: number | null;
  absoluteDiscountGross: number | null;
  centralPriceGross: number | null;
  recommendedPriceGross: number | null;
};

export type CountrySpecificItemDetailsResponse = {
  country: CountryResponse;
  itemSupplierProductResponse: ItemSupplierProductResponse;
  taxRateId: number;
  legalCategoryId: number | null;
  overwrite: boolean | null;
  overwriteAmount: number | null;
  isEnabledForCountry: boolean;
  centralPriceNet: number | null;
} & CountrySpecificGrossValues;

export type ItemResponse = BaseItemResponse;

export type ItemsResponse = {
  items: Array<ItemResponse>;
  filterOptions: ItemFilterOptions;
} & TableDataFetchResponse<ItemColumnTypes>;

export type ItemTypeCode = string;
export type BarcodeDetails = {
  barcodeId: number | null;
  barcode: string | null;
  manufacturer: string | null;
};

export type DiscountCategory = {
  discountCategoryOptionsId: number;
  amount: number;
  optionName: string;
};

export type Notes = {
  dateOfNote: Date;
  note: string;
  initials: string;
};

export type LabelDispensingNote = {
  labelDispensingNoteOptionsId: number;
  description: string;
  viewOrder: number;
  optionDescription: string;
};

export type CountrySpecificFilterOptions = {
  countryCode: CountryCode;
  isEnabled: boolean | null;
};

export type ItemFilterOptions = {
  searchText: string | null;
  types: Array<ItemTypeCode> | null;
  speciesIds: Array<Id> | null;
  category1Ids: Array<Id> | null;
  category2Ids: Array<Id> | null;
  category3Ids: Array<Id> | null;
  supplierIds: Array<Id> | null;
  clientCategoryIds: Array<Id> | null;
  supplierCode: string | null;
  centralPriceMin: number | null;
  centralPriceMax: number | null;
  centralMarkupPercentageMin: number | null;
  centralMarkupPercentageMax: number | null;
  isDisabled: boolean | null;
  stockWithEmptySupplier: boolean | null;
  bulkEditScheduled: boolean | null;
  discontinuedProduct: boolean | null;
  countrySpecificFilterOptions: Array<CountrySpecificFilterOptions>;
};

export type CountrySpecificFilterFormModel = {
  countryCode: CountryCode;
  isEnabled: boolean | null;
  isDisabled: boolean | null;
  isEither: boolean | null;
};

export type ItemFilterFormModel = {
  searchText: string | null;
  type: Array<ItemTypeCode> | null;
  species: Array<Id> | null;
  category1: Array<Id> | null;
  category2: Array<Id> | null;
  category3: Array<Id> | null;
  suppliers: Array<Id> | null;
  clientCategory: Array<Id> | null;
  supplierCode: string | null;
  centralPriceMin: string | null;
  centralPriceMax: string | null;
  centralMarkupPercentageMin: string | null;
  centralMarkupPercentageMax: string | null;
  isDisabled: boolean | null;
  stockWithEmptySupplier: boolean | null;
  bulkEditScheduled: boolean | null;
  discontinuedProduct: boolean | null;
  countrySpecificFilters: Array<CountrySpecificFilterFormModel>;
};

export type GetItemsCommand = Filterable<ItemFilterOptions> & PagedRequest;
export type GetItemsDownloadCommand = Filterable<ItemFilterOptions> & SortResponse<ItemColumnTypes>;

export type CreateOrEditItemFormModel = PrincipalEditableItemFields &
  CountrySpecificItemDetailsList & {
    itemName: string;
    itemCode: string;
    category1Id: number | null;
    type: ItemTypeCode | null;
    labelAdditionalInfo: string | null;
    dispenseFee: string | null;
    minimumPrice: string | null;
    requestLabel: boolean;
    labelCalcQuantity: boolean;
    barcode: Array<BarcodeDetails> | null;
    shouldAlterSex: boolean;
    internalInformation: string | null;
    notes: string | null;
    isDisabled: boolean;
    isBatchRequest: boolean;
    percentageDiscount: string;
    absoluteDiscount: string;

    clientCategoryId: number | null;
    shouldPromptChipDetails: boolean | null;
    shouldRequestQuantity: boolean | null;
    isHiddenForAllPractices: boolean | null;
    shouldSellAtCost: boolean | null;
    hcpGroupId: number | null;
    hcpMonthlyMultiplier: string | null;
    manufacturerId: number | null;

    preventHide: boolean | null;
    wormer: boolean | null;
    flea: boolean | null;
    repeatPrescription: boolean | null;
    labelDispensingNote: Array<LabelDispensingNote> | null;
    wholepackPriceEnabled: boolean | null;
    wholepackExcludeDispFee: boolean | null;
    isMultiDispensingFee: boolean | null;
    netNetDiscount: string | null;
    netNetNetDiscount: string | null;
    costTypeId: number | null;
    discountCategoryEnabled: boolean | null;
    discountCategory: Array<DiscountCategory> | null;
    documentTemplateId: number | null;
    reminderEnabled: boolean | null;
    reminderTypeId: number | null;
    reminderLength: number | null;
    reminderDelta: string | null;
    sendTo: Array<number> | null;
    noteDetails: Array<Notes> | null;
  };

export type CountrySpecificItemDetailsFormModel = SupplierProductDetailsFormModel & {
  countryCode: CountryCode;
  legalCategoryId: number | null;
  taxRateId: number | null;
  isEnabledForCountry: boolean;
  overwrite: boolean | null;
  overwriteAmount: string | null;
};

export type CountrySpecificItemDetailsList = {
  countrySpecificItemDetailsList: Array<CountrySpecificItemDetailsFormModel> | null;
};

type CreateEditItemBaseCommand = {
  itemName: string;
  labelAdditionalInfo: string | null;
  speciesId: number | null;
  category2Id: number;
  unitsPerPack: number | null;
  dispenseFee: number | null;
  minimumPrice: number | null;
  requestLabel: boolean;
  labelCalcQuantity: boolean;
  barcode: Array<BarcodeDetails> | null;
  shouldAlterSex: boolean;
  internalInformation: string | null;
  notes: string | null;
  recommendedPrice: number | null;
  centralMarkupPercentage: number | null;
  percentageDiscount: number | null;
  absoluteDiscount: number | null;
  isBatchRequest: boolean;

  category3Id: number | null;
  clientCategoryId: number | null;
  shouldPromptChipDetails: boolean | null;
  shouldRequestQuantity: boolean | null;
  shouldSellAtCost: boolean | null;
  hcpGroupId: number | null;
  hcpMonthlyMultiplier: number | null;
  manufacturerId: number | null;

  preventHide: boolean | null;
  wormer: boolean | null;
  flea: boolean | null;
  repeatPrescription: boolean | null;
  labelDispensingNote: Array<LabelDispensingNote>;
  wholepackPriceEnabled: boolean | null;
  wholepackExcludeDispFee: boolean | null;
  isMultiDispensingFee: boolean | null;
  netNetDiscount: number | null;
  netNetNetDiscount: number | null;
  costTypeId: number | null;
  discountCategoryEnabled: boolean | null;
  discountCategory: Array<DiscountCategory> | null;
  documentTemplateId: number | null;
  reminderEnabled: boolean | null;
  reminderTypeId: number | null;
  reminderLength: number | null;
  reminderDelta: string | null;
  sendTo: Array<number> | null;
  countrySpecificItemDetailsList: Array<CreateOrEditItemCountrySpecificItemDetails>;
  noteDetails: Array<Notes> | null;
};

export type CreateOrEditItemCountrySpecificItemDetails = {
  countryCode: CountryCode;
  supplierProduct: SupplierProductCommand | null;
  legalCategoryId: number | null;
  taxRateId: number;
  overwrite: boolean | null;
  overwriteAmount: number | null;
  isEnabledForCountry: boolean;
};

export type CreateItemCommand = {
  itemCode: string;
  type: ItemTypeCode;
  category1Id: number | null;
  isHiddenForAllPractices: boolean | null;
} & CreateEditItemBaseCommand;

export type EditItemCommand = {
  itemId: Id;
  isDisabled: boolean;
} & CreateEditItemBaseCommand;

export type CreateOrEditItemCommandWrapper = {
  command: CreateItemCommand | EditItemCommand;
  createOrEditTag: 'create' | 'edit';
};

export const IsEditCommand = (
  command: CreateItemCommand | EditItemCommand,
  tag: 'create' | 'edit',
): command is EditItemCommand => tag === 'edit';

export interface PrincipalEditableItemFields {
  recommendedPrice: string;
  centralMarkupPercentage: string;
  unitsPerPack: string;
  speciesId: number | null;
  category2Id: number | null;
  category3Id: number | null;
}

export class PrincipalEditableItemFieldsValidator<
  T extends PrincipalEditableItemFields
> extends TranslatableValidator<T> {
  constructor(
    t: TFunction,
    locale: Locale,
    pmsUsage: PmsUsage,
    type: ItemType | null,
    priceField: ItemPriceField | null,
    isService: boolean,
  ) {
    super(t, locale);

    this.ruleFor('category2Id')
      .notNull()
      .withMessage(t('item:validation.category2.empty'));

    if (!isService) {
      if (type && type.category.requiresUnitsPerPack) {
        this.ruleFor('unitsPerPack')
          .must(units => BeValidInteger(units, locale))
          .withMessage(t('item:validation.unitsPerPack.empty'))
          .must(units => Number(units) > 0)
          .withMessage(t('item:validation.unitsPerPack.greaterThanZero'))
          .must(unitsPerPack => isCSharpInteger(Number(unitsPerPack)))
          .withMessage(t('item:validation.unitsPerPack.integer'));
      } else {
        this.ruleFor('unitsPerPack')
          .must(
            unitsPerPack =>
              unitsPerPack == null ||
              unitsPerPack === '' ||
              (BeValidInteger(unitsPerPack, locale) && isCSharpInteger(Number(unitsPerPack))),
          )
          .withMessage(t('item:validation.unitsPerPack.integer'))
          .must(units => units == null || units === '' || Number(units) >= 0)
          .withMessage(t('item:validation.unitsPerPack.notNegative'));
      }
    }

    if (priceField === 'RecommendedPrice') {
      this.ruleFor('recommendedPrice')
        .must(price => BeValidCurrency(price, locale))
        .withMessage(t('item:validation.recommendedPrice.validCurrency'))
        .must(fee => BeLessThan(fee, PriceFieldExclusiveMaxValue, locale))
        .withMessage(t('item:validation.recommendedPrice.tooLarge'));
    }

    if (priceField === 'CentralMarkupPercentage') {
      this.ruleFor('centralMarkupPercentage')
        .must(markup => markup == null || markup === '' || BeValidDecimal(markup, locale, 2))
        .withMessage(t('item:validation.centralMarkupPercentage.invalid'))
        .must(markup => markup == null || markup === '' || parseFloat(markup) >= 0)
        .withMessage(t('item:validation.centralMarkupPercentage.zeroOrGreater'))
        .must(
          markup =>
            markup == null ||
            markup === '' ||
            parseFloat(markup) <= CentralMarkupPercentageMaxValue,
        )
        .withMessage(t('item:validation.centralMarkupPercentage.tooLarge'));
    }

    if (pmsUsage.isUsingMerlin) {
      this.ruleFor('category3Id')
        .notNull()
        .withMessage(t('item:validation.category3.empty'));
    }
  }
}
export class CreateOrEditItemFormModelValidator extends PrincipalEditableItemFieldsValidator<
  CreateOrEditItemFormModel
> {
  constructor(
    t: TFunction,
    locale: Locale,
    pmsUsage: PmsUsage,
    type: ItemType | null,
    priceField: ItemPriceField | null,
    isService: boolean,
  ) {
    super(t, locale, pmsUsage, type, priceField, isService);
    const validatorForNotes = new NotesValidator(t, locale);
    this.ruleForEach('noteDetails').setValidator(() => validatorForNotes);
    this.ruleFor('requestLabel')
      .must(x => Boolean(x) === true)
      .withMessage(t('item:validation.repeatPrescription.notMatchedWithRequestLabel'))
      .when(x => x.repeatPrescription ?? false);

    this.ruleForEach('discountCategory')
      .must(x => x.amount > 0)
      .withMessage(t('item:validation.discountCategory.negative'))
      .must(x => x.amount <= 100)
      .withMessage(t('item:validation.discountCategory.over100'))
      .must(x => x.discountCategoryOptionsId > 0)
      .withMessage(t('item:validation.discountCategory.selectCategory'));

    this.ruleFor('reminderLength')
      .notNull()
      .withMessage(t('item:validation.reminderLength.empty'))
      .when(form => form.reminderEnabled === true);

    this.ruleFor('reminderTypeId')
      .notNull()
      .withMessage(t('item:validation.reminderType.empty'))
      .when(form => form.reminderEnabled === true);

    this.ruleFor('reminderDelta')
      .notNull()
      .withMessage(t('item:validation.reminderDelta.empty'))
      .when(form => form.reminderEnabled === true);

    this.ruleFor('labelCalcQuantity')
      .must((calc, model) => (calc ? model.requestLabel : true))
      .withMessage(t('item:validation.requestLabel.required'));
    this.ruleFor('labelAdditionalInfo')
      .maxLength(MaximumAdditionaInfoLength)
      .withMessage(t('item:validation.name.length'))
      .must((value: string | null, model: CreateOrEditItemFormModel) =>
        String(value).length > 0 && String(value) !== '.' ? model.requestLabel : true,
      )
      .withMessage(t('item:validation.requestLabel.required'));
    this.ruleFor('itemName')
      .notEmpty()
      .withMessage(t('item:validation.name.empty'))
      .maxLength(InputMaximumLength)
      .withMessage(t('item:validation.name.length'));
    this.ruleFor('category1Id')
      .notNull()
      .withMessage(t('item:validation.category1.empty'));
    this.ruleFor('itemCode')
      .notEmpty()
      .withMessage(t('item:validation.code.empty'))
      .maxLength(ItemCodeMaxLength)
      .withMessage(t('item:validation.code.length'));
    this.ruleFor('type')
      .notNull()
      .withMessage(t('item:validation.type.empty'));

    if (!isService) {
      const validatorForBarcodes = new BarcodeValidator(t, locale);
      this.ruleForEach('barcode').setValidator(() => validatorForBarcodes);

      this.ruleFor('dispenseFee')
        .must(fee => fee == null || fee === '' || BeValidCurrency(fee, locale))
        .withMessage(t('item:validation.dispenseFee.validCurrency'))
        .must(
          fee => fee == null || fee === '' || BeLessThan(fee, DispenseFeeExclusiveMaxValue, locale),
        )
        .withMessage(t('item:validation.dispenseFee.tooLarge'));

      this.ruleFor('minimumPrice')
        .must(minPrice => minPrice === null || minPrice === '' || BeValidCurrency(minPrice, locale))
        .withMessage(t('item:validation.minimumPrice.validCurrency'))
        .must(
          minPrice =>
            minPrice === null ||
            minPrice === '' ||
            BeLessThan(minPrice, PriceFieldExclusiveMaxValue, locale),
        )
        .withMessage(t('item:validation.minimumPrice.tooLarge'));

      this.ruleFor('netNetDiscount')
        .must(value => value == null || value === '' || BeValidDecimal(value.toString(), locale, 2))
        .withMessage(t('item:validation.netNetDiscount.invalid'))
        .must(value => value == null || value === '' || parseFloat(value.toString()) >= 0)
        .withMessage(t('item:validation.netNetDiscount.zeroOrGreater'))
        .must(
          value => value == null || value === '' || parseFloat(value.toString()) <= MaxPercentage,
        )
        .withMessage(t('item:validation.netNetDiscount.tooLarge'));

      this.ruleFor('netNetNetDiscount')
        .must(value => value == null || value === '' || BeValidDecimal(value.toString(), locale, 2))
        .withMessage(t('item:validation.netNetNetDiscount.invalid'))
        .must(value => value == null || value === '' || parseFloat(value.toString()) >= 0)
        .withMessage(t('item:validation.netNetNetDiscount.zeroOrGreater'))
        .must(
          value => value == null || value === '' || parseFloat(value.toString()) <= MaxPercentage,
        )
        .withMessage(t('item:validation.netNetNetDiscount.tooLarge'));
    }
    this.ruleFor('internalInformation')
      .maxLength(InternalInformationMaxLength)
      .withMessage(t('item:validation.internalInformation.length'));
    this.ruleFor('notes')
      .maxLength(TextAreaMaximumLength)
      .withMessage(t('item:validation.notes.length'));

    this.ruleForEach('countrySpecificItemDetailsList')
      .notNull()
      .setValidator(() => new CountrySpecificItemDetailsFormModelValidator(t, locale, isService));

    if (priceField === 'Discount') {
      this.ruleFor('percentageDiscount')
        .must(
          (discount, item) =>
            (discount != null && discount !== '') ||
            (item.absoluteDiscount != null && item.absoluteDiscount !== ''),
        )
        .withMessage(t('item:validation.discount.empty'))
        .must(
          (discount, item) =>
            discount == null ||
            discount === '' ||
            item.absoluteDiscount == null ||
            item.absoluteDiscount === '',
        )
        .withMessage(t('item:validation.discount.bothSet'))
        .must(
          discount => discount == null || discount === '' || BeValidDecimal(discount, locale, 2),
        )
        .withMessage(t('item:validation.percentageDiscount.invalid'))
        .must(discount => discount == null || discount === '' || parseFloat(discount) >= 0)
        .withMessage(t('item:validation.percentageDiscount.zeroOrGreater'))
        .must(
          discount =>
            discount == null ||
            discount === '' ||
            parseFloat(discount) <= PercentageDiscountMaxValue,
        )
        .withMessage(t('item:validation.percentageDiscount.tooLarge'));

      this.ruleFor('absoluteDiscount')
        .must(
          (discount, item) =>
            (discount != null && discount !== '') ||
            (item.percentageDiscount != null && item.percentageDiscount !== ''),
        )
        .withMessage(t('item:validation.discount.empty'))
        .must(
          (discount, item) =>
            discount == null ||
            discount === '' ||
            item.percentageDiscount == null ||
            item.percentageDiscount === '',
        )
        .withMessage(t('item:validation.discount.bothSet'))
        .must(
          discount => discount == null || discount === '' || BeValidDecimal(discount, locale, 4),
        )
        .withMessage(t('item:validation.absoluteDiscount.invalid'))
        .must(discount => discount == null || discount === '' || parseFloat(discount) >= 0)
        .withMessage(t('item:validation.absoluteDiscount.zeroOrGreater'))
        .must(
          discount =>
            discount == null ||
            discount === '' ||
            parseFloat(discount) <= PriceFieldExclusiveMaxValue,
        )
        .withMessage(t('item:validation.absoluteDiscount.tooLarge'));
    }

    if (pmsUsage.isUsingMerlin) {
      this.ruleFor('clientCategoryId')
        .notNull()
        .withMessage(t('item:validation.clientCategory.empty'));
      this.ruleFor('hcpMonthlyMultiplier')
        .must((hcpMonthlyMultiplier, itemModel) =>
          itemModel.hcpGroupId !== null
            ? hcpMonthlyMultiplier === null ||
              hcpMonthlyMultiplier === '' ||
              Number(hcpMonthlyMultiplier) > 0
            : hcpMonthlyMultiplier === null || hcpMonthlyMultiplier === '',
        )
        .withMessage(t('item:validation.hcpMonthlyMultiplier.invalid'))
        .must(
          hcpMonthlyMultiplier =>
            hcpMonthlyMultiplier == null ||
            hcpMonthlyMultiplier === '' ||
            isCSharpInteger(Number(hcpMonthlyMultiplier)),
        )
        .withMessage(t('item:validation.hcpMonthlyMultiplier.integer'));
    }

    this.ruleFor('costTypeId')
      .notNull()
      .withMessage(t('item:validation.clientCategory.empty'));

    this.ruleFor('manufacturerId')
      .notNull()
      .withMessage(t('item:validation.netNetDiscount.noManufacturer'))
      .when(m => (parseFloat(m.netNetNetDiscount ?? '0') ?? 0) > 0);
  }
}

class CountrySpecificItemDetailsFormModelValidator extends TranslatableValidator<
  CountrySpecificItemDetailsFormModel
> {
  constructor(t: TFunction, locale: Locale, isService: boolean) {
    super(t, locale);

    this.ruleFor('overwrite')
      .must(
        (value: boolean | null, model: CountrySpecificItemDetailsFormModel) =>
          !value || (parseFloat(model.overwriteAmount ?? '0') ?? 0) > 0,
      )
      .withMessage(t('item:validation.overwrite.invalid'))
      .when(form => form.isEnabledForCountry);

    this.ruleFor('taxRateId')
      .notNull()
      .withMessage(t('item:validation.taxRate.empty'))
      .when(form => form.isEnabledForCountry);

    this.ruleFor('supplierProductDetails')
      .notNull()
      .setValidator(() => new SupplierProductDetailsFormModelValidator(t, locale, isService))
      .when(form => form.isEnabledForCountry);
  }
}

class SupplierProductDetailsFormModelValidator extends TranslatableValidator<
  NewOrExistingSupplierProductFormModel
> {
  constructor(t: TFunction, locale: Locale, isService: boolean) {
    super(t, locale);

    this.ruleFor('supplierId')
      .notNull()
      .withMessage(t('item:supplierProducts.validation.supplier.empty'))
      .when(form => form.manuallyEnterProduct);

    this.ruleFor('supplierCode')
      .notNull()
      .withMessage(t('item:supplierProducts.validation.supplierCode.empty'))
      .when(form => form.manuallyEnterProduct)
      .notEmpty()
      .withMessage(t('item:supplierProducts.validation.supplierCode.empty'))
      .when(form => form.manuallyEnterProduct);

    this.ruleFor('supplierCode')
      .must(supCode => !supCode?.includes('&'))
      .withMessage(t('item:supplierProducts.validation.supplierCode.noAmpersand'));

    this.ruleFor('listPrice')
      .must(val => val !== null && BeValidCurrency(val, locale))
      .withMessage(t('item:supplierProducts.validation.listPrice.validCurrency'))
      .when(form => form.manuallyEnterProduct);

    if (!isService) {
      this.ruleFor('supplierId')
        .notNull()
        .withMessage(t('item:supplierProducts.validation.supplier.empty'));

      this.ruleFor('supplierProductId')
        .notNull()
        .withMessage(t('item:supplierProducts.validation.product.empty'))
        .when(form => !form.manuallyEnterProduct);
    }
  }
}

export type UploadItemsResponse = { itemBulkEditId?: Id } & FileUploadResponse;

export type UploadItemBulkEditResponse = FileUploadResponse;

export type FilteredItem = {
  name: string;
  itemCode: string;
  species: string;
  category1: string;
  category2: string;
  category3: string;
  clientCategory: string;
  legalCategory: string;
  taxRate: string;
  hcpGroup: string;
  manufacturer: string;
  dispenseFeeNet: string;
  dispenseFeeGross: string;
  unitsPerPack: string;
  type: string;
  minimumPriceNet: string;
  minimumPriceGross: string;
  requestLabel: string;
  labelCalcQuantity: string;
  barcode: string;
  labelDispensingNote: string;
  shouldAlterSex: string;
  internalInformation: string;
  notes: string;
  centralPriceNet: string;
  centralPriceGross: string;
  centralMarkupPercentage: string;
  percentageDiscount: string;
  absoluteDiscountNet: string;
  absoluteDiscountGross: string;
  isBatchRequest: string;
  isDisabled: string;
  overwrite: string;
  overwriteAmount: string;
  preventHide: string;
  wormer: string;
  flea: string;
  repeatPrescription: string;
  labelAdditionalInfo: string;
  shouldPromptChipDetails: string;
  shouldRequestQuantity: string;
  isHiddenForAllPractices: string;
  shouldSellAtCost: string;
  hcpMonthlyMultiplier: string;
  supplier: string;
  supplierCode: string;
  supplierPrice: string;
  wholepackPriceEnabled: string;
  wholepackExcludeDispFee: string;
  isMultiDispensingFee: string;
  netNetDiscount: string;
  netNetNetDiscount: string;
  costType: string;
  discountCategoryEnabled: boolean;
  discountCategory: string;
  documentTemplate: string;
  reminderEnabled: string;
  reminderType: string;
  reminderLength: string;
  reminderDelta: string;
  sendTo: string;
};

export type FilteredItems = {
  itemsRequested: Array<FilteredItem>;
};
