import { round } from 'lodash';
import numbro from 'numbro';
import languages from 'numbro/dist/languages.min';
import { Locale } from '../models/locale';
Object.values(languages).forEach(l => numbro.registerLanguage(l));

const numberFormatCache: {
  [cacheKey: string]: Intl.NumberFormat;
} = {};

// Creating Intl.NumberFormat is an expensive operation, so we want to cache and reuse instances
const getNumberFormat = (locale: Locale, decimalPlaces?: number): Intl.NumberFormat => {
  const cacheKey = `locale:${locale}-decimals:${decimalPlaces}`;
  return (
    numberFormatCache[cacheKey] ||
    (numberFormatCache[cacheKey] = !!decimalPlaces
      ? new Intl.NumberFormat(locale, {
          maximumFractionDigits: decimalPlaces,
          minimumFractionDigits: decimalPlaces,
        })
      : new Intl.NumberFormat(locale))
  );
};

export const formatNumber = (value: number, locale: Locale, decimalPlaces?: number): string => {
  const numberFormat = getNumberFormat(locale, decimalPlaces);
  return numberFormat.format(value);
};

export const MaxCSharpInt = 2147483647;
export const MinCSharpInt = -2147483648;

export const isCSharpInteger = (value: number) =>
  value <= MaxCSharpInt && value >= MinCSharpInt && Number.isInteger(value);

export const parseNumber = (valueToParse: string | number, locale: Locale): number => {
  numbro.setLanguage(locale);

  const unformatted = numbro.unformat(valueToParse.toString());

  return unformatted === undefined ? Number.NaN : unformatted;
};

export const formatNumberForFields = (
  value: number,
  locale: Locale,
  decimalPlaces: number = 4,
): string => {
  numbro.setLanguage(locale);
  return numbro(round(value, decimalPlaces)).format({ thousandSeparated: true });
};
