import { TFunction } from 'i18next';
import { Row } from 'react-table';
import { BeTodayOrLater } from '../../../models/dates/dateTimeStamp';
import { Id } from '../../../models/id';
import { Locale } from '../../../models/locale';
import { FileUploadResponse } from '../../../shared/files/fileUploadResponse';
import { BeValidCurrency } from '../../../shared/forms/CurrencyField';
import { BeValidPercent } from '../../../shared/forms/PercentField';
import { PagedResponse } from '../../../shared/tables/pagination/pagination';
import { parseNumber } from '../../../utils/numberUtils';
import { TranslatableValidator } from '../../../utils/validation/TranslatableValidator';
import { InputMaximumLength } from '../../../utils/validation/validationConstants';
import { Permission, permissions } from '../../authentication/permissions';
import { DeviationTableRow } from '../deviation';

const createEditPagePermissions = [
  permissions.SuperAdmin,
  permissions.CentralAdmin,
  permissions.SitePriceAdmin,
  permissions.SiteVisibilityAdmin,
];

const deviatePricesPermissions = [
  permissions.SuperAdmin,
  permissions.CentralAdmin,
  permissions.SitePriceAdmin,
];

const hideItemsPermissions = [...deviatePricesPermissions, permissions.SiteVisibilityAdmin];

export type EditDeviationBulkEditPermissions = {
  page: Array<Permission>;
  deviatePrices: Array<Permission>;
  hideItems: Array<Permission>;
};

export const editCreateDeviationBulkEditPermissions: EditDeviationBulkEditPermissions = {
  page: createEditPagePermissions,
  deviatePrices: deviatePricesPermissions,
  hideItems: hideItemsPermissions,
};

export type DeviationBulkEditResponse = {
  deviationBulkEditId: Id;
  name: string | null;
  scheduledDate: string;
  canAccessAllSitesOnBulkEdit: boolean;
  creatorsName: string;
  isCsvUpload: boolean;
  runImmediately: boolean;
  deviationBulkEditLines: Array<DeviationBulkEditLineResponse>;
} & PagedResponse;

export type DeviationBulkEditLineResponse = DeviationTableRow & {
  isHiddenFrom: boolean;
  isHiddenTo: boolean;
  absolutePriceDeviationNetFrom: number;
  absolutePriceDeviationNetTo: number;
  absolutePriceDeviationGrossFrom: number;
  absolutePriceDeviationGrossTo: number;
  percentagePriceDeviationFrom: number | null;
  percentagePriceDeviationTo: number | null;
  priceDeviationIsSetByPercentageFrom: boolean;
  priceDeviationIsSetByPercentageTo: boolean;
  absoluteDispenseFeeDeviationNetFrom: number;
  absoluteDispenseFeeDeviationNetTo: number;
  absoluteDispenseFeeDeviationGrossFrom: number;
  absoluteDispenseFeeDeviationGrossTo: number;
  percentageDispenseFeeDeviationFrom: number | null;
  percentageDispenseFeeDeviationTo: number | null;
  dispenseFeeDeviationIsSetByPercentageFrom: boolean;
  dispenseFeeDeviationIsSetByPercentageTo: boolean;
};

export type DeviationBulkEditsResponse = {
  deviationBulkEdits: Array<DeviationBulkEditDetailsResponse>;
};

export type DeviationBulkEditDetailsResponse = {
  deviationBulkEditId: Id;
  name: string | null;
  scheduledDate: string;
  creatorsName: string;
  affectedSiteNames: Array<string>;
};

export type DeviationBulkEditLineFormModel = {
  deviationId: number | null; // This is not editable in the form, but is passed back to the api so tracked here
  treatmentTypeDeviationId: number | null;
  itemType: string;
  isHidden: boolean;
  absolutePriceDeviation: string | null;
  percentagePriceDeviation: string | null;
  deviatedPrice: string;
  absoluteDispenseFeeDeviation: string | null;
  percentageDispenseFeeDeviation: string | null;
  deviatedDispenseFee: string;
  itemDeviationPriceOverride: boolean;
  itemDeviationDispenseFeeOverride: boolean;
  overwrite: boolean;
};

export type EditDeviationBulkEditFormModel = {
  name: string | null;
  deviations: { [siteId: number]: { [itemId: number]: DeviationBulkEditLineFormModel } };
};

export type CreateDeviationBulkEditFormModel = EditDeviationBulkEditFormModel & {
  runImmediately: boolean;
  scheduledDate: string;
};

export const getDeviationFieldPrefix = <TRowData extends DeviationTableRow>(row: Row<TRowData>) =>
  getDeviationFieldPrefixBySiteAndItem(row.original.siteId, row.original.itemId);

export const getDeviationFieldPrefixBySiteAndItem = (siteId: number, itemId: number) =>
  `deviations.${siteId}.${itemId}`;

export const getDeviationFieldByFieldName = (siteId: number, itemId: number) => (
  fieldName: keyof DeviationBulkEditLineFormModel,
) => `${getDeviationFieldPrefixBySiteAndItem(siteId, itemId)}.${fieldName}`;

export type DeviationBulkEditCommandLine = {
  itemType: string;
  treatmentTypeDeviationId: number | null;
  deviationId: number | null;
  siteId: number;
  itemId: number;
  isHidden: boolean;

  absolutePriceDeviation: number | null;
  percentagePriceDeviation: number | null;
  priceDeviationIsSetByPercentage: boolean;

  absoluteDispenseFeeDeviation: number | null;
  percentageDispenseFeeDeviation: number | null;
  dispenseFeeDeviationIsSetByPercentage: boolean;
  itemDeviationPriceOverride: boolean | null;
  itemDeviationDispenseFeeOverride: boolean | null;
  overwrite: boolean;
};

export type CreateDeviationBulkEditCommand = {
  name: string;
  runImmediately: boolean;
  scheduledDate: string;
  deviations: Array<DeviationBulkEditCommandLine>;
};

export class CreateDeviationBulkEditValidator extends TranslatableValidator<
  CreateDeviationBulkEditFormModel
> {
  constructor(t: TFunction, locale: Locale) {
    super(t, locale);
    this.ruleFor('name')
      .maxLength(InputMaximumLength)
      .withMessage(t('deviations:validation.bulkEditName.length'));
    this.ruleFor('scheduledDate')
      .must(date => BeTodayOrLater(date, locale))
      .withMessage(t('deviations:validation.scheduledDate.invalid'))
      .when(form => !form.runImmediately);
  }
}

export type EditDeviationBulkEditCommand = {
  deviationBulkEditId: number;
  name: string | null;
  deviations: Array<DeviationBulkEditCommandLine>;
};

export class EditDeviationBulkEditValidator extends TranslatableValidator<
  EditDeviationBulkEditFormModel
> {
  constructor(t: TFunction, locale: Locale) {
    super(t, locale);
    this.ruleFor('name')
      .notNull()
      .withMessage(t('deviations:validation.bulkEditName.empty'))
      .notEmpty()
      .withMessage(t('deviations:validation.bulkEditName.empty'))
      .maxLength(InputMaximumLength)
      .withMessage(t('deviations:validation.bulkEditName.length'));
  }
}

export class DeviationBulkEditLineValidator extends TranslatableValidator<
  DeviationBulkEditLineFormModel
> {
  constructor(t: TFunction, locale: Locale) {
    super(t, locale);

    this.ruleFor('percentagePriceDeviation')
      .must(value => value === null || BeValidPercent(value, locale))
      .withMessage(t('deviations:validation.percentagePriceDeviation.invalid'))
      .must(
        (value, formModel) =>
          value === null || DeviationMustBeNonNegative(formModel.deviatedPrice, locale),
      )
      .withMessage(t('deviations:validation.percentagePriceDeviation.negativeDeviatedPrice'))
      .must(value => value === null || CheckMerlinNegativeMimit(value, locale))
      .withMessage(t('deviations:validation.absoluteDispenseFeeDeviation.greaterThanMinus100'))
      .must(value => value === null || CheckMerlinPositiveMimit(value, locale))
      .withMessage(t('deviations:validation.absoluteDispenseFeeDeviation.LessThan9999'));

    this.ruleFor('absolutePriceDeviation')
      .must(value => value === null || BeValidCurrency(value, locale, Number.MIN_SAFE_INTEGER))
      .withMessage(t('deviations:validation.absolutePriceDeviation.invalid'))
      .must(
        (value, formModel) =>
          value === null || DeviationMustBeNonNegative(formModel.deviatedPrice, locale),
      )
      .withMessage(t('deviations:validation.absolutePriceDeviation.negativeDeviatedPrice'));

    this.ruleFor('percentageDispenseFeeDeviation')

      .must(value => value === null || BeValidPercent(value, locale))
      .withMessage(t('deviations:validation.percentageDispenseFeeDeviation.invalid'))
      .must(
        (value, formModel) =>
          value === null || DeviationMustBeNonNegative(formModel.deviatedDispenseFee, locale),
      )
      .withMessage(
        t('deviations:validation.percentageDispenseFeeDeviation.negativeDeviatedDispenseFee'),
      )
      .must(value => value === null || CheckMerlinNegativeMimit(value, locale))
      .withMessage(t('deviations:validation.absoluteDispenseFeeDeviation.greaterThanMinus100'))
      .must(value => value === null || CheckMerlinPositiveMimit(value, locale))
      .withMessage(t('deviations:validation.absoluteDispenseFeeDeviation.LessThan9999'));

    this.ruleFor('absoluteDispenseFeeDeviation')
      .must(value => value === null || BeValidCurrency(value, locale, Number.MIN_SAFE_INTEGER))
      .withMessage(t('deviations:validation.absoluteDispenseFeeDeviation.invalid'))
      .must(
        (value, formModel) =>
          value === null || DeviationMustBeNonNegative(formModel.deviatedDispenseFee, locale),
      )
      .withMessage(
        t('deviations:validation.absoluteDispenseFeeDeviation.negativeDeviatedDispenseFee'),
      );

      this.ruleFor('deviatedPrice')
      .must(value => value === null || BeValidCurrency(value, locale, Number.MIN_SAFE_INTEGER))
      .withMessage(t('deviations:validation.deviatedPrice.invalid'))
      .must(
        (value, formModel) =>
          value === null || DeviationMustBeNonNegative(formModel.deviatedPrice, locale),
      )
      .withMessage(t('deviations:validation.deviatedPrice.negativeDeviatedPrice'))
      .must((value, model) => DeviatedPriceMustBeLessThan9999PercentOfCentralPrice(value, model))
      .withMessage(t('deviations:validation.deviatedPrice.valueTooHigh'))

      this.ruleFor('deviatedDispenseFee')
      .must(value => value === null || BeValidCurrency(value, locale, Number.MIN_SAFE_INTEGER))
      .withMessage(t('deviations:validation.deviatedDispenseFee.invalid'))
      .must(
        (value, formModel) =>
          value === null || DeviationMustBeNonNegative(formModel.deviatedDispenseFee, locale),
      )
      .withMessage(t('deviations:validation.deviatedDispenseFee.negativeDeviatedDispenseFee'))
      .must((value, model) => DeviatedDispenseFeeMustBeLessThan9999PercentOfCentralPrice(value, model))
      .withMessage(t('deviations:validation.deviatedDispenseFee.valueTooHigh'))
  }
}


const DeviatedDispenseFeeMustBeLessThan9999PercentOfCentralPrice = (value : string | number, model : DeviationBulkEditLineFormModel) => {
  var absoluteDispenseFeeDeviation = model.absoluteDispenseFeeDeviation ? +model.absoluteDispenseFeeDeviation : 0;
  var deviatedDispenseFee = value ? +value : 0;
  var centralDispenseFee = (absoluteDispenseFeeDeviation * -1) + deviatedDispenseFee;
  if(centralDispenseFee==0){return true;}
  var result = deviatedDispenseFee/centralDispenseFee;
  return result < 101;
}

const DeviatedPriceMustBeLessThan9999PercentOfCentralPrice = (value : string | number, model : DeviationBulkEditLineFormModel) => {
  var absolutePriceDeviation = model.absolutePriceDeviation ? +model.absolutePriceDeviation : 0;
  var deviatedPrice = value ? +value : 0;
  var centralPrice = (absolutePriceDeviation * -1) + deviatedPrice;
  if(centralPrice==0){return true;}
  var result = deviatedPrice/centralPrice;
  return result < 101;
}

const DeviationMustBeNonNegative = (value: string | number, locale: Locale) => {
  const parsedNumber = parseNumber(value, locale);

  return !Number.isNaN(parsedNumber) && parsedNumber >= 0;
};

const CheckMerlinPositiveMimit = (value: string | number, locale: Locale) => {
  const parsedNumber = parseNumber(value, locale);
  return !Number.isNaN(parsedNumber) && parsedNumber <= 9999;
};

const CheckMerlinNegativeMimit = (value: string | number, locale: Locale) => {
  const parsedNumber = parseNumber(value, locale);
  return !Number.isNaN(parsedNumber) && parsedNumber >= -100;
};

export type DeviationBulkEditJobDetails = {
  deviationBulkEditId: number;
  name: string | null;
  scheduledDate: string;
  hasErrored: boolean;
  allComplete: boolean;
  pmsRequestJobId: number;
  canAccessAllSitesOnBulkEdit: boolean;
  creatorsName: string;
  affectedSiteNames: Array<string>;
};

export type RecentDeviationBulkEditsResponse = {
  deviationBulkEditJobs: Array<DeviationBulkEditJobDetails>;
};

export type UploadDeviationResponse = { deviationBulkEditId?: Id } & FileUploadResponse;
