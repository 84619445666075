import { map } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '../../styling/theme';
import { FileUploadResponse } from './fileUploadResponse';

type Props = {
  fileUploadResponse: FileUploadResponse;
  className?: string;
};

export const fileUploadErrorBoxTestId = 'file-upload-error-box-test-id';

const ErrorContainer = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  background-color: ${props => props.theme.colours.alertBackground};
  color: ${props => props.theme.colours.alertText};
  padding: ${props => props.theme.spacing.small}px;
`;

const ErrorHeader = styled.div`
  font-size: ${props => props.theme.typography.headings.h3fontSize}px;
  font-weight: bold;
  margin: 0;
`;

const ErrorSubHeader = styled.div`
  font-size: ${props => props.theme.typography.content.fontSize}px;
  margin: 0;
`;

const ErrorList = styled.ul`
  margin-bottom: 0;
`;

const ErrorListItem = styled.li<React.HTMLAttributes<HTMLLIElement>>`
  list-style-type: none;
`;

export const FileUploadErrorBox = (props: Props) => {
  const { t } = useTranslation(['serverErrors', 'serverValidation', 'fileUpload']);
  return (
    <ErrorContainer data-testid={fileUploadErrorBoxTestId} className={props.className}>
      <ErrorHeader>{t('fileUpload:error.header')}</ErrorHeader>
      <ErrorSubHeader>{t('fileUpload:error.subHeader')}</ErrorSubHeader>
      <ErrorList>
        {map(props.fileUploadResponse.validationMessagesByRowNumber, (messages, rowNumber) => (
          <ErrorListItem key={rowNumber}>
            <div>{t('fileUpload:error.rowLabel', { rowNumber })}</div>
            <ErrorList>
              {map(messages, (message, index) => (
                <ErrorListItem key={message.key + index}>
                  {t(message.key, message.params)}
                </ErrorListItem>
              ))}
            </ErrorList>
          </ErrorListItem>
        ))}
      </ErrorList>
    </ErrorContainer>
  );
};
