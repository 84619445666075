export type PagedResponse = {
  pageNumber: number;
  pageSize: number;
  totalItems: number;
};

export type PagedRequest = {
  pageNumber: number;
  pageSize: number;
};

export type PaginationDetails = PagedResponse & { totalPages: number };

export const getPaginationDetailsFromPagedResponse = (
  pagedResponse: PagedResponse,
): PaginationDetails => {
  return { ...pagedResponse, totalPages: getTotalPagesFromPagedResponse(pagedResponse) };
};
export const getTotalPagesFromPagedResponse = (pagedResponse: PagedResponse): number =>
  getTotalPages(pagedResponse.totalItems, pagedResponse.pageSize);

export const getTotalPages = (totalItems: number, pageSize: number): number =>
  Math.ceil(totalItems / pageSize);
