import { RouteComponentProps } from '@reach/router';
import { ControllerStateAndHelpers } from 'downshift';
import { filter } from 'lodash';
import { useCallback, useContext, useMemo, useState } from 'react';
import * as React from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { AlertButton, ButtonLabel, HollowButton } from '../../../shared/buttons/Button';
import { DataLoader } from '../../../shared/DataLoader';
import {
  MultiSelectDropdown,
  MultiSelectDropdownProps,
} from '../../../shared/dropdowns/MultiSelectDropdown';
import { useMultiSelectDropdown } from '../../../shared/dropdowns/useMultiSelectDropdown';
import { ApiErrorBox } from '../../../shared/errors/ApiErrorBox';
import { FieldAndValue } from '../../../shared/FieldAndValue';
import { DropdownOption, DropdownOptions } from '../../../shared/forms/DropdownField';
import { useApiRequest } from '../../../shared/hooks/useApiRequest';
import { Link } from '../../../shared/navigation/Link';
import { IfUserHasOneOfPermissions } from '../../../shared/Permissions';
import { shouldShowSuccess, SuccessBox } from '../../../shared/success/SuccessBox';
import {
  ChangeCell,
  ChangeNumberCell,
  ChangePriceCell,
  InlineChange,
  InlineGrossPriceChange,
  InlineNumberChange,
  InlinePriceChange,
} from '../../../shared/tables/ChangeTableComponents';
import { CustomColumn, DataFetchTable } from '../../../shared/tables/Table';
import { dashboardUrl } from '../../../urls';
import { navigate } from '../../../utils/routing';
import { UserContext } from '../../authentication/loginData/userContext';
import { permissions } from '../../authentication/permissions';
import { PageHeading } from '../../layout/PageHeading';
import { ItemBulkEditLineResponse, ItemBulkEditResponse } from '../itemBulkEdit';
import { cancelItemBulkEdit, getItemBulkEdit, getItemFieldsFromPms } from '../itemsApi';
import { viewItemUrl } from '../itemUrls';
import { ItemFieldsFromPmsResponse } from '../PmsFields/pmsFields';
import { itemsPageSize, SpaceBetweenButtonGroup } from './ItemsBase';
import { MetadataContext } from '../../authentication/loginData/metadataContext';
import { CountryCode } from '../../authentication/loginData/metadata';

type OwnProps = RouteComponentProps<{ itemBulkEditId: string }> & {};
type Props = OwnProps;

export type ItemBulkEditColumnTypes =
  | 'itemName'
  | 'recommendedPrice'
  | 'centralMarkupPercentage'
  | 'unitsPerPack'
  | 'type'
  | 'species'
  | 'category1'
  | 'category2'
  | 'category3'
  | 'otherChanges';

export type ItemBulkEditColumnDropdownType = DropdownOption<ItemBulkEditColumnTypes>;

export const ViewItemBulkEdit = (props: Props) => (
  <DataLoader apiRequest={getItemFieldsFromPms}>
    {itemFields => <ViewItemBulkEditComponent {...props} itemFieldsFromPms={itemFields.response} />}
  </DataLoader>
);

const renderToggleButton = (
  toggleProps: MultiSelectDropdownProps<ItemBulkEditColumnDropdownType>,
  downshift: ControllerStateAndHelpers<ItemBulkEditColumnDropdownType>,
) => (
  <HollowButton {...downshift.getToggleButtonProps()}>
    <ButtonLabel {...downshift.getLabelProps()}>{toggleProps.label}</ButtonLabel>
  </HollowButton>
);

type OwnViewItemBulkEditComponentProps = {
  itemFieldsFromPms: ItemFieldsFromPmsResponse;
} & RouteComponentProps<{ itemBulkEditId: string }>;

type ViewItemBulkEditComponentProps = OwnViewItemBulkEditComponentProps;

const ViewItemBulkEditComponent = (props: ViewItemBulkEditComponentProps) => {
  const { t } = useTranslation(['item', 'libraries']);
  const { itemFieldsFromPms } = props;
  const { user } = useContext(UserContext);
  const { countries } = useContext(MetadataContext);
  const userCountries = countries.filter(country => user.countries.includes(country.code));

  const allColumns: DropdownOptions<ItemBulkEditColumnTypes> = [
    { displayText: t('viewItemBulkEdit.columnHeaders.name'), value: 'itemName' },
    {
      displayText: user.useGrossPrices
        ? t('viewItemBulkEdit.columnHeaders.recommendedPriceGross')
        : t('viewItemBulkEdit.columnHeaders.recommendedPriceNet'),
      value: 'recommendedPrice',
    },
    {
      displayText: t('viewItemBulkEdit.columnHeaders.centralMarkupPercentage'),
      value: 'centralMarkupPercentage',
    },
    { displayText: t('viewItemBulkEdit.columnHeaders.unitsPerPack'), value: 'unitsPerPack' },
    { displayText: t('viewItemBulkEdit.columnHeaders.type'), value: 'type' },
    { displayText: t('viewItemBulkEdit.columnHeaders.category1'), value: 'category1' },
    { displayText: t('viewItemBulkEdit.columnHeaders.category2'), value: 'category2' },
    { displayText: t('viewItemBulkEdit.columnHeaders.category3'), value: 'category3' },
    { displayText: t('viewItemBulkEdit.columnHeaders.otherChanges'), value: 'otherChanges' },
  ];

  const columnDropdownOptions: DropdownOptions<ItemBulkEditColumnTypes> = useMemo(
    () =>
      filter(allColumns, column => column.value !== 'category1' && column.value !== 'category3'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.itemFieldsFromPms],
  );

  const { selectedOptions, onOptionSelect, deselectedValues } = useMultiSelectDropdown<
    ItemBulkEditColumnDropdownType,
    ItemBulkEditColumnTypes
  >({
    validOptions: columnDropdownOptions,
    initiallySelected: columnDropdownOptions.filter(colOption => colOption.value === 'itemName'),
    storeInUrl: true,
  });

  const getItemBulkEditCallback = useCallback(getItemBulkEdit(Number(props.itemBulkEditId)), [
    props.itemBulkEditId,
  ]);
  const [itemBulkEditResponse, setItemBulkEditResponse] = useState<ItemBulkEditResponse | null>(
    null,
  );
  const { makeRequest, apiError, inProgress } = useApiRequest(cancelItemBulkEdit);
  const onCancelButtonClick = () => {
    if (window.confirm(t('viewItemBulkEdit.confirmCancelBulkEdit'))) {
      makeRequest(Number(props.itemBulkEditId)).then(result => {
        if (result) {
          navigate(dashboardUrl(), { suppressUserConfirmation: true, state: { cancelled: true } });
        }
      });
    }
  };
  const onDataFetchSuccess = (response: ItemBulkEditResponse) => {
    setItemBulkEditResponse(response);
  };
  const mapItemsResponseToTableData = (response: ItemBulkEditResponse) =>
    response.itemBulkEditLines;

  const createCountrySpecificFieldName = (field: string, countryCode: CountryCode) =>
    `${t(`viewItemBulkEdit.otherChangesFields.countrySpecific.${field}`)} - ${t(
      `viewItemBulkEdit.country.${countryCode}`,
    )}`;

  const columns: Array<CustomColumn<ItemBulkEditLineResponse>> = [
    {
      Header: t<string>('viewItemBulkEdit.columnHeaders.code'),
      accessor: row => row.itemCode,
      id: 'itemCode',
      Cell: ({ cell }: CellProps<ItemBulkEditLineResponse>) => (
        <Link to={viewItemUrl(cell.row.original.itemId)}>{cell.value}</Link>
      ),
    },
    {
      Header: t<string>('viewItemBulkEdit.columnHeaders.name'),
      accessor: row => row.nameFrom,
      id: 'nameFrom',
      Cell: ({ cell }: CellProps<ItemBulkEditLineResponse>) => (
        <ChangeCell
          oldValue={cell.row.original.nameFrom}
          newValue={cell.row.original.nameTo}
          floatRight={false}
        />
      ),
    },
    {
      Header: user.useGrossPrices
        ? t<string>('viewItemBulkEdit.columnHeaders.recommendedPriceGross')
        : t<string>('viewItemBulkEdit.columnHeaders.recommendedPriceNet'),
      accessor: row => row.recommendedPriceNetFrom,
      id: 'recommendedPrice',
      Cell: ({ cell }: CellProps<ItemBulkEditLineResponse>) => (
        <ChangePriceCell
          oldValueNet={cell.row.original.recommendedPriceNetFrom}
          oldValueGross={cell.row.original.countrySpecificDetails.map(
            csd => csd.recommendedPriceGrossFrom,
          )}
          newValueNet={cell.row.original.recommendedPriceNetTo}
          newValueGross={cell.row.original.countrySpecificDetails.map(
            csd => csd.recommendedPriceGrossTo,
          )}
          currency={userCountries.map(country => country.currency)}
        />
      ),
    },
    {
      Header: t<string>('viewItemBulkEdit.columnHeaders.centralMarkupPercentage'),
      accessor: row => row.centralMarkupPercentageFrom,
      id: 'centralMarkupPercentageFrom',
      Cell: ({ cell }: CellProps<ItemBulkEditLineResponse>) => (
        <ChangeNumberCell
          oldValue={cell.row.original.centralMarkupPercentageFrom}
          newValue={cell.row.original.centralMarkupPercentageTo}
        />
      ),
    },
    {
      Header: t<string>('viewItemBulkEdit.columnHeaders.unitsPerPack'),
      accessor: row => row.unitsPerPackFrom,
      id: 'unitsPerPack',
      Cell: ({ cell }: CellProps<ItemBulkEditLineResponse>) => (
        <ChangeNumberCell
          oldValue={cell.row.original.unitsPerPackFrom}
          newValue={cell.row.original.unitsPerPackTo}
        />
      ),
    },
    {
      Header: t<string>('viewItemBulkEdit.columnHeaders.type'),
      accessor: row => row.type,
      id: 'type',
    },
    {
      Header: t<string>('viewItemBulkEdit.columnHeaders.species'),
      accessor: row => row.category1From,
      id: 'species',
      Cell: ({ cell }: CellProps<ItemBulkEditLineResponse>) => (
        <ChangeCell
          oldValue={
            cell.row.original.speciesFrom &&
            itemFieldsFromPms.speciesOptions[cell.row.original.speciesFrom].name
          }
          newValue={
            cell.row.original.speciesTo &&
            itemFieldsFromPms.speciesOptions[cell.row.original.speciesTo].name
          }
          floatRight={false}
        />
      ),
    },
    {
      Header: t<string>('viewItemBulkEdit.columnHeaders.category1'),
      accessor: row => row.category1From,
      id: 'category1',
      Cell: ({ cell }: CellProps<ItemBulkEditLineResponse>) => (
        <ChangeCell
          oldValue={
            cell.row.original.category1From &&
            itemFieldsFromPms.category1Options[cell.row.original.category1From].name
          }
          newValue={
            cell.row.original.category1To &&
            itemFieldsFromPms.category1Options[cell.row.original.category1To].name
          }
          floatRight={false}
        />
      ),
    },
    {
      Header: t<string>('viewItemBulkEdit.columnHeaders.category2'),
      accessor: row => row.category2From,
      id: 'category2',
      Cell: ({ cell }: CellProps<ItemBulkEditLineResponse>) => (
        <ChangeCell
          oldValue={
            cell.row.original.category2From &&
            itemFieldsFromPms.category2Options[cell.row.original.category2From].name
          }
          newValue={
            cell.row.original.category2To &&
            itemFieldsFromPms.category2Options[cell.row.original.category2To].name
          }
          floatRight={false}
        />
      ),
    },
    {
      Header: t<string>('viewItemBulkEdit.columnHeaders.category3'),
      accessor: row => row.category3From,
      id: 'category3',
      Cell: ({ cell }: CellProps<ItemBulkEditLineResponse>) => (
        <ChangeCell
          oldValue={
            cell.row.original.category3From &&
            itemFieldsFromPms.category3Options[cell.row.original.category3From].name
          }
          newValue={
            cell.row.original.category3To &&
            itemFieldsFromPms.category3Options[cell.row.original.category3To].name
          }
          floatRight={false}
        />
      ),
    },
    {
      Header: t<string>('viewItemBulkEdit.columnHeaders.otherChanges'),
      accessor: row => row.internalInformationFrom,
      id: 'otherChanges',
      Cell: ({ cell }: CellProps<ItemBulkEditLineResponse>) => (
        <div>
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.notedetails')}
            newValue={cell.row.original.noteDetailsTo}
            oldValue={cell.row.original.noteDetailsFrom}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.percentageDiscount')}
            newValue={cell.row.original.percentageDiscountTo}
            oldValue={cell.row.original.percentageDiscountFrom}
          />
          {user.useGrossPrices ? (
            <InlineGrossPriceChange
              fieldName={t('viewItemBulkEdit.otherChangesFields.absoluteDiscountGross')}
              newValues={cell.row.original.countrySpecificDetails.map(
                csd => csd.absoluteDiscountGrossTo,
              )}
              oldValues={cell.row.original.countrySpecificDetails.map(
                csd => csd.absoluteDiscountGrossFrom,
              )}
              currencies={userCountries.map(country => country.currency)}
            />
          ) : (
            <InlineChange
              fieldName={t('viewItemBulkEdit.otherChangesFields.absoluteDiscountNet')}
              newValue={cell.row.original.absoluteDiscountNetTo}
              oldValue={cell.row.original.absoluteDiscountNetFrom}
            />
          )}
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.repeatPrescription')}
            newValue={cell.row.original.repeatPrescriptionTo ?? 0 ? 'Enabled' : 'Disabled'}
            oldValue={cell.row.original.repeatPrescriptionFrom ?? 0 ? 'Enabled' : 'Disabled'}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.flea')}
            newValue={cell.row.original.fleaTo ?? 0 ? 'Enabled' : 'Disabled'}
            oldValue={cell.row.original.fleaFrom ?? 0 ? 'Enabled' : 'Disabled'}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.preventHide')}
            newValue={cell.row.original.preventHideTo ?? 0 ? 'Enabled' : 'Disabled'}
            oldValue={cell.row.original.preventHideFrom ?? 0 ? 'Enabled' : 'Disabled'}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.wormer')}
            newValue={cell.row.original.wormerTo ?? 0 ? 'Enabled' : 'Disabled'}
            oldValue={cell.row.original.wormerFrom ?? 0 ? 'Enabled' : 'Disabled'}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.labelAdditionalInfo')}
            newValue={cell.row.original.labelAdditionalInfoTo}
            oldValue={cell.row.original.labelAdditionalInfoFrom}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.requiredLabel')}
            newValue={cell.row.original.requiredLabelTo ?? 0 ? 'Enabled' : 'Disabled'}
            oldValue={cell.row.original.requiredLabelFrom ?? 0 ? 'Enabled' : 'Disabled'}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.labelCalcQty')}
            newValue={cell.row.original.labelCalcQuantityTo ?? 0 ? 'Enabled' : 'Disabled'}
            oldValue={cell.row.original.labelCalcQuantityFrom ?? 0 ? 'Enabled' : 'Disabled'}
          />
          {user.useGrossPrices ? (
            <>
              <InlineGrossPriceChange
                fieldName={t('viewItemBulkEdit.otherChangesFields.dispenseFeeGross')}
                newValues={cell.row.original.countrySpecificDetails.map(
                  csd => csd.dispenseFeeGrossTo,
                )}
                oldValues={cell.row.original.countrySpecificDetails.map(
                  csd => csd.dispenseFeeGrossFrom,
                )}
                currencies={userCountries.map(country => country.currency)}
              />
              <InlineGrossPriceChange
                fieldName={t('viewItemBulkEdit.otherChangesFields.minimumPriceGross')}
                newValues={cell.row.original.countrySpecificDetails.map(
                  csd => csd.minimumPriceGrossTo,
                )}
                oldValues={cell.row.original.countrySpecificDetails.map(
                  csd => csd.minimumPriceGrossFrom,
                )}
                currencies={userCountries.map(country => country.currency)}
              />
            </>
          ) : (
            <>
              <InlinePriceChange
                fieldName={t('viewItemBulkEdit.otherChangesFields.dispenseFeeNet')}
                newValue={cell.row.original.dispenseFeeNetTo}
                oldValue={cell.row.original.dispenseFeeNetFrom}
              />
              <InlinePriceChange
                fieldName={t('viewItemBulkEdit.otherChangesFields.minimumPriceNet')}
                newValue={cell.row.original.minimumPriceNetTo}
                oldValue={cell.row.original.minimumPriceNetFrom}
              />
            </>
          )}
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.barcode')}
            newValue={cell.row.original.barcodeToString}
            oldValue={cell.row.original.barcodeFromString}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.dispensingNote')}
            newValue={cell.row.original.dispensingNoteToString}
            oldValue={cell.row.original.dispensingNoteFromString}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.discountCategory')}
            newValue={cell.row.original.discountCategoryToString}
            oldValue={cell.row.original.discountCategoryFromString}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.discountCategoryEnabled')}
            newValue={cell.row.original.discountCategoryEnabledTo ?? 0 ? 'Enabled' : 'Disabled'}
            oldValue={cell.row.original.discountCategoryEnabledFrom ?? 0 ? 'Enabled' : 'Disabled'}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.internalInformation')}
            newValue={cell.row.original.internalInformationTo}
            oldValue={cell.row.original.internalInformationFrom}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.notes')}
            newValue={cell.row.original.notesTo}
            oldValue={cell.row.original.notesFrom}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.manufacturer')}
            newValue={
              cell.row.original.manufacturerTo &&
              itemFieldsFromPms.manufacturerOptions[cell.row.original.manufacturerTo].name
            }
            oldValue={
              cell.row.original.manufacturerFrom &&
              itemFieldsFromPms.manufacturerOptions[cell.row.original.manufacturerFrom].name
            }
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.shouldAlterSex')}
            newValue={cell.row.original.shouldAlterSexTo ?? 0 ? 'Enabled' : 'Disabled'}
            oldValue={cell.row.original.shouldAlterSexFrom ?? 0 ? 'Enabled' : 'Disabled'}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.isDisabled')}
            newValue={cell.row.original.isDisabledTo ?? 0 ? 'Enabled' : 'Disabled'}
            oldValue={cell.row.original.isDisabledFrom ?? 0 ? 'Enabled' : 'Disabled'}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.isBatchRequest')}
            newValue={cell.row.original.isBatchRequestTo ?? 0 ? 'Enabled' : 'Disabled'}
            oldValue={cell.row.original.isBatchRequestFrom ?? 0 ? 'Enabled' : 'Disabled'}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.clientCategory')}
            newValue={
              cell.row.original.clientCategoryTo &&
              itemFieldsFromPms.clientCategoryOptions[cell.row.original.clientCategoryTo].name
            }
            oldValue={
              cell.row.original.clientCategoryFrom &&
              itemFieldsFromPms.clientCategoryOptions[cell.row.original.clientCategoryFrom].name
            }
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.shouldPromptChipDetails')}
            newValue={cell.row.original.shouldPromptChipDetailsTo ?? 0 ? 'Enabled' : 'Disabled'}
            oldValue={cell.row.original.shouldPromptChipDetailsFrom ?? 0 ? 'Enabled' : 'Disabled'}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.shouldRequestQuantity')}
            newValue={cell.row.original.shouldRequestQuantityTo ?? 0 ? 'Enabled' : 'Disabled'}
            oldValue={cell.row.original.shouldRequestQuantityFrom ?? 0 ? 'Enabled' : 'Disabled'}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.shouldSellAtCost')}
            newValue={cell.row.original.shouldSellAtCostTo ?? 0 ? 'Enabled' : 'Disabled'}
            oldValue={cell.row.original.shouldSellAtCostFrom ?? 0 ? 'Enabled' : 'Disabled'}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.hcpGroup')}
            newValue={
              cell.row.original.hcpGroupTo &&
              itemFieldsFromPms.hcpGroupOptions[cell.row.original.hcpGroupTo].name
            }
            oldValue={
              cell.row.original.hcpGroupFrom &&
              itemFieldsFromPms.hcpGroupOptions[cell.row.original.hcpGroupFrom].name
            }
          />
          <InlineNumberChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.hcpMonthlyMultiplier')}
            newValue={cell.row.original.hcpMonthlyMultiplierTo}
            oldValue={cell.row.original.hcpMonthlyMultiplierFrom}
          />

          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.wholepackPriceEnabled')}
            newValue={cell.row.original.wholepackPriceEnabledTo ?? 0 ? 'Enabled' : 'Disabled'}
            oldValue={cell.row.original.wholepackPriceEnabledFrom ?? 0 ? 'Enabled' : 'Disabled'}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.wholepackExcludeDispFee')}
            newValue={cell.row.original.wholepackExcludeDispFeeTo ?? 0 ? 'Enabled' : 'Disabled'}
            oldValue={cell.row.original.wholepackExcludeDispFeeFrom ?? 0 ? 'Enabled' : 'Disabled'}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.isMultiDispensingFee')}
            newValue={cell.row.original.isMultiDispensingFeeTo ?? 0 ? 'Enabled' : 'Disabled'}
            oldValue={cell.row.original.isMultiDispensingFeeFrom ?? 0 ? 'Enabled' : 'Disabled'}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.netNetDiscount')}
            newValue={cell.row.original.netNetDiscountTo}
            oldValue={cell.row.original.netNetDiscountFrom}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.netNetNetDiscount')}
            newValue={cell.row.original.netNetNetDiscountTo}
            oldValue={cell.row.original.netNetNetDiscountFrom}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.costType')}
            newValue={
              cell.row.original.costTypeTo &&
              itemFieldsFromPms.costTypeOptions[cell.row.original.costTypeTo].name
            }
            oldValue={
              cell.row.original.costTypeFrom &&
              itemFieldsFromPms.costTypeOptions[cell.row.original.costTypeFrom].name
            }
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.documentTemplate')}
            newValue={
              cell.row.original.documentTemplateTo &&
              itemFieldsFromPms.documentTemplateOptions[cell.row.original.documentTemplateTo].name
            }
            oldValue={
              cell.row.original.documentTemplateFrom &&
              itemFieldsFromPms.documentTemplateOptions[cell.row.original.documentTemplateFrom].name
            }
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.reminderEnabled')}
            newValue={cell.row.original.reminderEnabledTo ? 'Yes' : 'No'}
            oldValue={cell.row.original.reminderEnabledFrom ? 'Yes' : 'No'}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.reminderTypeId')}
            newValue={
              cell.row.original.reminderTypeIdTo &&
              itemFieldsFromPms.reminderTypeOptions[cell.row.original.reminderTypeIdTo].name
            }
            oldValue={
              cell.row.original.reminderTypeIdFrom &&
              itemFieldsFromPms.reminderTypeOptions[cell.row.original.reminderTypeIdFrom].name
            }
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.reminderLength')}
            newValue={cell.row.original.reminderLengthTo}
            oldValue={cell.row.original.reminderLengthFrom}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.reminderDelta')}
            newValue={cell.row.original.reminderDeltaTo}
            oldValue={cell.row.original.reminderDeltaFrom}
          />
          <InlineChange
            fieldName={t('viewItemBulkEdit.otherChangesFields.sendTo')}
            newValue={cell.row.original.sendToTo}
            oldValue={cell.row.original.sendToFrom}
          />

          {userCountries.map(country => {
            const countryDetails = cell.row.original.countrySpecificDetails.find(
              c => c.countryCode === country.code,
            );
            if (countryDetails) {
              return (
                <React.Fragment key={country.code}>
                  <InlineChange
                    fieldName={createCountrySpecificFieldName('overwrite', country.code)}
                    newValue={countryDetails.overwriteTo ?? 0 ? 'Enabled' : 'Disabled'}
                    oldValue={countryDetails.overwriteFrom ?? 0 ? 'Enabled' : 'Disabled'}
                  />
                  <InlineChange
                    fieldName={createCountrySpecificFieldName('overwriteAmount', country.code)}
                    newValue={countryDetails?.overwriteAmountTo || null}
                    oldValue={countryDetails?.overwriteAmountFrom || null}
                  />
                  <InlineChange
                    fieldName={createCountrySpecificFieldName('taxRate', country.code)}
                    newValue={
                      countryDetails?.taxRateTo
                        ? itemFieldsFromPms.taxRateOptions[countryDetails.taxRateTo].name
                        : null
                    }
                    oldValue={
                      countryDetails?.taxRateFrom
                        ? itemFieldsFromPms.taxRateOptions[countryDetails.taxRateFrom].name
                        : null
                    }
                  />
                  <InlineChange
                    fieldName={createCountrySpecificFieldName('legalCategory', country.code)}
                    newValue={
                      countryDetails?.legalCategoryTo
                        ? itemFieldsFromPms.legalCategoryOptions[countryDetails.legalCategoryTo]
                            .name
                        : null
                    }
                    oldValue={
                      countryDetails?.legalCategoryFrom
                        ? itemFieldsFromPms.legalCategoryOptions[countryDetails.legalCategoryFrom]
                            .name
                        : null
                    }
                  />
                  <InlineChange
                    fieldName={createCountrySpecificFieldName('supplierName', country.code)}
                    newValue={countryDetails?.supplierNameTo || null}
                    oldValue={countryDetails?.supplierNameFrom || null}
                  />
                  <InlineChange
                    fieldName={createCountrySpecificFieldName('supplierCode', country.code)}
                    newValue={countryDetails?.supplierCodeTo || null}
                    oldValue={countryDetails?.supplierCodeFrom || null}
                  />
                  {user.useGrossPrices ? (
                    <InlineGrossPriceChange
                      fieldName={createCountrySpecificFieldName('supplierPriceGross', country.code)}
                      newValues={[countryDetails?.supplierPriceGrossTo || null]}
                      oldValues={[countryDetails?.supplierPriceGrossFrom || null]}
                      currencies={[country.currency]}
                    />
                  ) : (
                    <InlineChange
                      fieldName={createCountrySpecificFieldName('supplierPriceNet', country.code)}
                      newValue={countryDetails?.supplierPriceNetTo || null}
                      oldValue={countryDetails?.supplierPriceNetFrom || null}
                    />
                  )}
                </React.Fragment>
              );
            } else {
              return <></>;
            }
          })}
        </div>
      ),
    },
  ];

  return (
    <>
      <Title>{t('viewItemBulkEdit.title')}</Title>
      <PageHeading>
        <h1>{t('viewItemBulkEdit.heading')}</h1>
        {itemBulkEditResponse && (
          <IfUserHasOneOfPermissions
            permissions={[permissions.SuperAdmin, permissions.CentralAdmin]}
          >
            <AlertButton onClick={onCancelButtonClick} loading={inProgress}>
              {t('viewItemBulkEdit.cancel')}
            </AlertButton>
          </IfUserHasOneOfPermissions>
        )}
      </PageHeading>

      <SuccessBox
        message={t('viewItemBulkEdit.success')}
        showSuccess={shouldShowSuccess()}
        noMinHeight={true}
      />
      {itemBulkEditResponse && (
        <>
          <FieldAndValue
            fieldName={t('viewItemBulkEdit.scheduledDate')}
            value={itemBulkEditResponse.scheduledDate}
            type={'date'}
          />
          <FieldAndValue
            fieldName={t('viewItemBulkEdit.name')}
            value={itemBulkEditResponse.name}
            type={'text'}
          />
        </>
      )}
      <SpaceBetweenButtonGroup>
        <MultiSelectDropdown<ItemBulkEditColumnDropdownType>
          label={t('viewItemBulkEdit.selectColumnsButtonLabel')}
          options={columnDropdownOptions}
          itemToString={item => (item ? item.displayText : '')}
          onOptionSelect={onOptionSelect}
          initiallySelectedOptions={selectedOptions}
          renderToggleButton={renderToggleButton}
        />
      </SpaceBetweenButtonGroup>
      {apiError && <ApiErrorBox error={apiError} />}
      <DataFetchTable
        columns={columns}
        pageSize={itemsPageSize}
        onDataFetchSuccess={onDataFetchSuccess}
        getApiData={getItemBulkEditCallback}
        mapResponseToTableData={mapItemsResponseToTableData}
        emptyTableMessage={t('viewItemBulkEdit.emptyTableMessage')}
        hiddenColumnNames={deselectedValues}
      />
    </>
  );
};
