import i18n from 'i18next';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import XHR from 'i18next-xhr-backend';
import preval from 'preval.macro';
import { initReactI18next } from 'react-i18next';
import { trackMissingTranslation } from './applicationInsights';
import { getBrowserLanguage } from './utils/languageDetector';

// Inject build time during webpack bundle step
const buildTime = preval`module.exports = new Date().getTime()`;
// Don't cache translations in development
const cacheExpirationTime = process.env.NODE_ENV === 'production' ? 365 * 24 * 60 * 60 * 1000 : 0;

i18n
  .use(initReactI18next)
  .use(Backend)
  .init(
    {
      lng: getBrowserLanguage(),
      supportedLngs: ['en-GB', 'sv-SE', 'fi', 'da', 'nb'],
      ns: ['authenticationError'],
      debug: false,
      interpolation: {
        escapeValue: false, // Not needed for React as it escapes by default
      },
      saveMissing: true,
      saveMissingTo: 'current',
      missingKeyHandler: (languages, namespace, key, fallbackValues) => {
        trackMissingTranslation(languages, namespace, key);
      },
      backend: {
        backends: [LocalStorageBackend, XHR],
        backendOptions: [
          {
            prefix: 'i18next_res_',
            expirationTime: cacheExpirationTime,
            versions: {
              'en-GB': `v${buildTime}`,
              'sv-SE': `v${buildTime}`,
              fi: `v${buildTime}`,
              da: `v${buildTime}`,
              nb: `v${buildTime}`,
            },
          },
          {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
            customHeaders: {
              'Cache-Control': 'no-cache, no-store',
            },
          },
        ],
      },
    },
    undefined,
  );

i18n.on('languageChanged', language => {
  document.documentElement.setAttribute('lang', language);
});

export default i18n;
