import { values } from 'lodash';

// Changes here should also be made to Permission.cs in the API
export type Permission =
  | `CentralUserAdmin`
  | `CentralAdmin`
  | `CentralView`
  | `SiteUserAdmin`
  | `SitePriceAdmin`
  | `SiteVisibilityAdmin`
  | 'DeviationBulkUploadAdmin'
  | `SuperAdmin`
  | `IVCAdmin`;

export const permissions: { [permission in Permission]: Permission } = {
  CentralUserAdmin: 'CentralUserAdmin',
  CentralAdmin: 'CentralAdmin',
  CentralView: 'CentralView',
  SiteUserAdmin: 'SiteUserAdmin',
  SitePriceAdmin: 'SitePriceAdmin',
  SiteVisibilityAdmin: 'SiteVisibilityAdmin',
  DeviationBulkUploadAdmin: 'DeviationBulkUploadAdmin',
  SuperAdmin: 'SuperAdmin',
  IVCAdmin: 'IVCAdmin',
};

export const getAllPermissions = (): Array<Permission> => values(permissions);
