import { RouteComponentProps } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { Title } from 'react-head';
import { PageHeading } from '../layout/PageHeading';
import { useTranslation } from 'react-i18next';
import { Column, Container, Div, Row } from '../../styling/Site';
import { useApiRequest } from '../../shared/hooks/useApiRequest';
import { getPmsRequestQueueStatus } from './API/supportApi';
import { PmsRequestQueueStatus } from './API/PmsRequestQueueStatusResponse';
import { RequiresOneOfPermissions } from '../../shared/Permissions';
import { permissions } from '../authentication/permissions';
import PmsQueueDetails from './components/PmsQueueDetails';
import { HollowButton } from '../../shared/buttons/Button';

type OwnProps = RouteComponentProps & {};
type Props = OwnProps;

export const PmsQueueStatus = (props: Props) => {
  const { t } = useTranslation(['support']);
  const [queueStatus, setQueueStatus] = useState<Array<PmsRequestQueueStatus>>([]);

  const { makeRequest, inProgress } = useApiRequest(getPmsRequestQueueStatus);

  const fetchQueueStatistics = () => {
    makeRequest().then(result => {
      if (result) {
        setQueueStatus(result);
      }
    });
  };

  useEffect(() => {
    fetchQueueStatistics();

    const timer = setInterval(fetchQueueStatistics, 5000); // Fetch every 5 seconds (adjust as needed)

    return () => {
      clearInterval(timer); // Cleanup the timer when the component unmounts
    };
  }, []);

  return (
    <RequiresOneOfPermissions permissions={[permissions.IVCAdmin]}>
      <Title>{t('pmsQueueStatus.title')}</Title>
      <PageHeading>
        <h1>{t('pmsQueueStatus.title')}</h1>
      </PageHeading>

      <Container>
        <Row>
          <Column>
            <PmsQueueDetails queueStatus={queueStatus} />
          </Column>
          <Column>
            {/**
             *
             * Other columns for hangfire / resets ... TBD
             *
             */}
            <Div>
              The Queue Statistics will refresh every 5 seconds
              {/* , you can refresh manually or just watch the screen. */}
            </Div>

            {/* Add a toggle to show manual refresh or have a auto refresh and no manual */}

            {/* <HollowButton loading={inProgress} onClick={fetchQueueStatistics}>
              {t('pmsQueueStatus.refreshButton')}
            </HollowButton> */}
          </Column>
        </Row>
      </Container>
    </RequiresOneOfPermissions>
  );
};
