import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../feature/authentication/loginData/userContext';
import { styled } from '../../../styling/theme';
import { formatNumber } from '../../../utils/numberUtils';
import { IconButton } from '../../buttons/IconButton';
import { getTotalPages } from './pagination';

export const paginationControlsTestId = 'pagination-controls-test-id';

type PaginationControlsProps = {
  atTop?: boolean;
  atBottom?: boolean;
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  loading: boolean;
  reactTablePagination: {
    gotoPage: (page: number) => void;
    canPreviousPage: boolean;
    canNextPage: boolean;
  };
};

const PaginationContainer = styled.div<
  React.HTMLAttributes<HTMLDivElement> & { atTop?: boolean; atBottom?: boolean }
>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-weight: normal;
  background-color: ${props => props.theme.colours.componentBackground};
  border-collapse: collapse;
  border: 1px solid ${props => props.theme.colours.border};
  padding: ${props => props.theme.spacing.tiny}px;

  border-bottom: ${props => props.atTop && `none`};
  border-top: ${props => props.atBottom && `none`};
`;

const PageDetails = styled.div`
  margin-right: ${props => props.theme.spacing.extraSmall}px;
  min-height: ${props => props.theme.typography.content.lineHeight}em;
`;

export const PaginationControls = (props: PaginationControlsProps) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation('component');

  const startItem = (props.pageNumber - 1) * props.pageSize + 1;
  const endItem = props.pageNumber * props.pageSize;
  const totalItems = props.totalItems;
  const totalPages = getTotalPages(totalItems, props.pageSize);

  const firstPage = () => !props.loading && props.reactTablePagination.gotoPage(1);
  const nextPage = () =>
    !props.loading && props.reactTablePagination.gotoPage(props.pageNumber + 1);
  const previousPage = () =>
    !props.loading && props.reactTablePagination.gotoPage(props.pageNumber - 1);
  const lastPage = () => !props.loading && props.reactTablePagination.gotoPage(totalPages);

  const startIndex = formatNumber(totalItems === 0 ? 0 : startItem, user.locale);
  const endIndex = formatNumber(endItem > totalItems ? totalItems : endItem, user.locale);

  return (
    <PaginationContainer
      data-testid={paginationControlsTestId}
      atBottom={props.atBottom}
      atTop={props.atTop}
    >
      <PageDetails>
        {!props.loading &&
          t('paginationControls.pageDetails', {
            startIndex,
            endIndex,
            totalItems: formatNumber(totalItems, user.locale),
          })}
      </PageDetails>
      <IconButton
        aria-label={t('component:paginationControls.firstPage')}
        onClick={firstPage}
        disabled={!props.reactTablePagination.canPreviousPage}
        aria-disabled={props.loading}
        faIcon={faAngleDoubleLeft}
      />
      <IconButton
        aria-label={t('component:paginationControls.previousPage')}
        onClick={previousPage}
        disabled={!props.reactTablePagination.canPreviousPage}
        aria-disabled={props.loading}
        faIcon={faAngleLeft}
      />
      <IconButton
        aria-label={t('component:paginationControls.nextPage')}
        onClick={nextPage}
        disabled={!props.reactTablePagination.canNextPage}
        aria-disabled={props.loading}
        faIcon={faAngleRight}
      />
      <IconButton
        aria-label={t('component:paginationControls.lastPage')}
        onClick={lastPage}
        disabled={!props.reactTablePagination.canNextPage}
        aria-disabled={props.loading}
        faIcon={faAngleDoubleRight}
      />
    </PaginationContainer>
  );
};
