import { TFunction } from 'i18next';
import { Id } from '../../models/id';
import { Locale } from '../../models/locale';
import { TranslatableValidator } from '../../utils/validation/TranslatableValidator';
import { InputMaximumLength } from '../../utils/validation/validationConstants';

export type AccessibleOrganisationGroupResponse = {
  organisationGroups: Array<OrganisationGroupResponse>;
};

export type OrganisationGroupResponse = {
  organisationId: Id;
  organisationName: string;
  organisationCountryCode: string;
  organisationCountryTwoCode: string | null;
  merlinConnectionName: string | null;
  azureAdTenantName: string;
};

export type OrganisationGroupsResponse = {
  organisationGroups: Array<OrganisationGroupResponse>;
};

export type GetAccessibleOrganisationGroupsCollectionQuery = {
  userId: number | undefined;
};

export type CreateOrEditOrganisationGroupFormModel = {
  organisationId: Id | null;
  organisationName: string;
  organisationCountryCode: string;
  merlinConnectionName: string | null;
  azureAdTenantName: string;
};

export type CreateOrganisationGroupCommand = {
  organisationName: string;
  organisationCountryCode: string;
  merlinConnectionName: string | null;
  azureAdTenantName: string;
};

export type EditOrganisationGroupCommand = {
  organisationId: Id;
  organisationName: string;
  organisationCountryCode: string;
  merlinConnectionName: string | null;
  azureAdTenantName: string;
};

export type CreateOrEditOrganisationGroupCommandWrapper = {
  command: CreateOrganisationGroupCommand | EditOrganisationGroupCommand;
  createOrEditTag: 'create' | 'edit';
};

export const IsEditOrganisationGroupCommand = (
  command: CreateOrganisationGroupCommand | EditOrganisationGroupCommand,
  tag: 'create' | 'edit',
): command is EditOrganisationGroupCommand => tag === 'edit';

export class CreateOrEditOrganisationGroupFormModelValidator extends TranslatableValidator<
  CreateOrEditOrganisationGroupFormModel
> {
  constructor(t: TFunction, locale: Locale) {
    super(t, locale);
    this.ruleFor('organisationName')
      .notEmpty()
      .withMessage(t('organisation:validation.organisationName.empty'));

    this.ruleFor('organisationName')
      .maxLength(InputMaximumLength)
      .withMessage(t('organisation:validation.organisationName.length'));

    this.ruleFor('organisationCountryCode')
      .notEmpty()
      .withMessage(t('organisation:validation.organisationCountryCode.empty'));

    this.ruleFor('merlinConnectionName')
      .maxLength(InputMaximumLength)
      .withMessage(t('organisation:validation.merlinConnectionName.length'));

    this.ruleFor('azureAdTenantName')
      .notEmpty()
      .withMessage(t('organisation:validation.azureAdTenantName.empty'));

    this.ruleFor('azureAdTenantName')
      .maxLength(InputMaximumLength)
      .withMessage(t('organisation:validation.azureAdTenantName.length'));
  }
}
