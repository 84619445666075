import { RouteComponentProps, Router } from '@reach/router';
import * as React from 'react';
import { PageNotFound } from '../../PageNotFound';
import { ViewTreatmentTypeDeviations } from './ViewTreatmentTypeDeviations';
import { CreateTreatmentTypeDeviationBulkEdit } from './bulkEdit/CreateTreatmentTypeDeviationBulkEdit';
import { ViewTreatmentTypeDeviationBulkEdit } from './bulkEdit/ViewTreatmentTypeDeviationBulkEdit';
import { EditTreatmentTypeDeviationBulkEdit } from './bulkEdit/EditTreatmentTypeDeviationBulkEdit';
import { UploadTreatmentTypeDeviationBulkEdit } from './bulkEdit/UploadTreatmentTypeDeviationBulkEdit';

export const TreatmentTypeDeviationsSection = (props: RouteComponentProps) => (
  <Router primary={false}>
    <ViewTreatmentTypeDeviations path="/" />
    <CreateTreatmentTypeDeviationBulkEdit path="bulk-edit" />
    <ViewTreatmentTypeDeviationBulkEdit path="bulk-edit/view/:treatmentTypeDeviationBulkEditId" />
    <EditTreatmentTypeDeviationBulkEdit path="bulk-edit/edit/:treatmentTypeDeviationBulkEditId" />
    <UploadTreatmentTypeDeviationBulkEdit path="bulk-edit/upload" />
    <PageNotFound default={true} />
  </Router>
);
