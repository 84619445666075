import { RouteComponentProps } from '@reach/router';
import { UserManager } from 'oidc-client';
import { useEffect } from 'react';
import { authenticationConfig } from './Authentication';

export const SilentTokenRefresh = (props: RouteComponentProps) => {
  useEffect(() => {
    // console.log('Handling silent token refresh callback');
    const userManager = new UserManager(authenticationConfig);
    userManager.signinSilentCallback();
  });

  return null;
};
