import { Form, FormikProps } from 'formik';
import { some } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../shared/buttons/Button';
import { FormValidationErrorBox } from '../../shared/errors/FormValidationErrorBox';
import { CheckboxField } from '../../shared/forms/CheckboxField';
import { SingleSelectDropdownField } from '../../shared/forms/SingleSelectDropdownField';
import { withFormSubmit } from '../../shared/higher-order-components/withFormSubmit';
import { SuccessBox } from '../../shared/success/SuccessBox';
import { assertIsDefined } from '../../utils/assertIsDefined';
import { navigate } from '../../utils/routing';
import { pmsSupplierDropdownOptions, SuppliersFromPmsResponse } from './pms/suppliersFromPms';
import {
  EditSupplierCommandWrapper,
  EditSupplierFormModel,
  EditSupplierFormModelValidator,
  SupplierResponse,
} from './supplier';
import { editSupplier } from './supplierApi';
import { editSupplierUrl } from './supplierUrls';

type OwnProps = {
  existingSupplier?: SupplierResponse;
  suppliersFromPms: SuppliersFromPmsResponse;
  showSuccess?: boolean;
  onSuccess?: (response: SupplierResponse) => void;
};
type Props = OwnProps & FormikProps<EditSupplierFormModel>;

const SupplierFormComponent = (props: Props) => {
  const { t } = useTranslation('supplier');

  return (
    <Form>
      <SingleSelectDropdownField
        name="pmsSupplierId"
        label={t('createAndEditForm.labels.name')}
        options={pmsSupplierDropdownOptions(props.suppliersFromPms.supplierOptions)}
      />
      <PrimaryButton type="submit" disabled={props.isSubmitting}>
        {t('createAndEditForm.labels.button')}
      </PrimaryButton>
      <FormValidationErrorBox errors={props.errors} touched={props.touched} />
      {props.showSuccess && !some(props.touched) && (
        <SuccessBox message={t('createAndEditForm.success')} />
      )}
    </Form>
  );
};

const enhance = withFormSubmit<
  OwnProps,
  EditSupplierFormModel,
  SupplierResponse,
  EditSupplierCommandWrapper
>({
  enableReinitialize: true,
  request: editSupplier,
  onSubmitComplete: (props, response) => {
    if (props.onSuccess) {
      props.onSuccess(response);
    }
    navigate(editSupplierUrl(response.supplierId), {
      state: { success: true },
    });
  },
  mapPropsToValues: props => ({
    supplierId: props.existingSupplier ? props.existingSupplier.supplierId : null,
    pmsSupplierId: props.existingSupplier ? props.existingSupplier.pmsSupplierId : null,
  }),
  mapValuesToRequestParameters: (props, formModel) => ({
    command: {
      supplierId: assertIsDefined(formModel.supplierId),
      pmsSupplierId: assertIsDefined(formModel.pmsSupplierId),
    },
    createOrEditTag: props.existingSupplier ? 'edit' : 'create',
  }),
  validationConfig: {
    translationNamespace: 'supplier',
    validator: EditSupplierFormModelValidator,
  },
});

export const SupplierForm: React.FunctionComponent<OwnProps> = enhance(SupplierFormComponent);
